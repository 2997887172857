import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import { useSelector } from 'react-redux'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { setLocaleLanguage } from '@helpers/dates'
// components
import Icon from '@components/Icon'
import CanView from '@components/perms/CanView'
import SuperField from '@components/forms/SuperField'
import SpinnerSegment from '@components/SpinnerSegment'
import ModalCancel from '@components/buttons/ModalCancel'
import ConfirmModal from '@components/modals/ConfrimModal'
import { Segment, Header, Divider, TabPane, Tab, Grid, Table, Form, Popup, Button, Label } from 'semantic-ui-react'

const PayrollDetails = ({
    payrollID,
    profileID,
    contractID,
    setContracts,
    setData,
    setTotal,
    dateFrom,
    dateTo,
    onClose,
}) => {
    const { t } = useTranslation()

    const dateFormat = useSelector((state) => state.date_format)

    const [payroll, setPayroll] = useState(null)
    // eslint-disable-next-line
    const [processing, setProcessing] = useState(false)
    const [canPerformCorrections, setCanPerformCorrections] = useState(false)
    const [loading, setLoading] = useState(true)
    const [restoring, setRestoring] = useState(false)
    const [movedHours, setMovedHours] = useState(0)
    const [contractBonuses, setContractBonus] = useState([])

    const fetchPreferences = async () => {
        const request = await requests.get(
            API.PREFERENCES + 'attendance_preferences__allow_attendance_record_correction/'
        )
        if (request.status === 200) {
            setCanPerformCorrections(request.response.value)
        }
    }

    useEffect(() => {
        async function fetchPayrollDetails() {
            setLoading(true)
            await fetchPreferences()

            if (payrollID) {
                // if exists
                const request = await requests.get(API.PAYROLLS + payrollID + '/?include_lists=true')
                if (request.status === 200)
                    setPayroll({
                        ...request.response,
                        absences: request.response?.absences ? JSON.parse(request.response.absences) : [],
                    })
            } else {
                // if doesnt exists then get recommendation data
                const requestPrefill = await requests.get(
                    API.PAYROLLS + `bulk_prefill/?contract_params=${contractID}&date_from=${dateFrom}&date_to=${dateTo}`
                )

                if (requestPrefill.status === 200) {
                    setPayroll(requestPrefill.response[0])
                    setContractBonus(requestPrefill.response[0].contract.bonuses)
                }
            }
            setLoading(false)
        }

        fetchPayrollDetails()

        // eslint-disable-next-line
    }, [])

    const isValidHours = () => {
        if (movedHours === '') return true
        if (isNaN(movedHours)) return false
        if (parseFloat(movedHours) < 0) return false
        if (parseFloat(movedHours) > parseFloat(payroll.contract.bank_overtime || 0)) return false
        return true
    }

    const handleSubmit = async () => {
        setProcessing(true)
        if (!payrollID) {
            let bonuses = 0
            let rewards = 0
            let functional_surcharges = 0
            let bonuses_to_add = []
            const contractBonuses = payroll.contract.bonuses
            for (let i = 0; i < contractBonuses.length; i++) {
                const bonus = contractBonuses[i]
                const requestBonusPayment = await requests.post(API.PAYROLLS + 'bonus_payments/', {
                    note: bonus.title + '; ' + bonus.note,
                    is_approved: true,
                    amount: bonus.amount,
                    currency: bonus.currency,
                    bonus_type: bonus?.bonus_type?.id || null,
                    billing_month: moment(dateTo).format('MM'),
                    billing_year: moment(dateTo).format('YYYY'),
                    contract: contractID,
                    type: 5,
                    billing_period: dateTo,
                })

                if (requestBonusPayment.status === 201) {
                    functional_surcharges += parseFloat(bonus.amount || 0)
                    bonuses_to_add.push(requestBonusPayment.response.id)
                }
            }

            for (let i = 0; i < payroll.bonuses.length; i++) {
                if (payroll.bonuses[i].type === 3) {
                    bonuses += parseFloat(payroll.bonuses[i].amount || 0)
                } else if (payroll.bonuses[i].type === 4) {
                    rewards += parseFloat(payroll.bonuses[i].amount || 0)
                }

                bonuses_to_add.push(payroll.bonuses[i].id)
            }

            let deductions = 0
            let deductions_to_add = []
            for (let i = 0; i < payroll.deductions.length; i++) {
                deductions += parseFloat(payroll.deductions[i].amount)
                deductions_to_add.push(payroll.deductions[i].id)
            }

            let advances = 0
            let advances_to_add = []
            for (let i = 0; i < payroll.advances.length; i++) {
                advances += parseFloat(payroll.advances[i].amount)
                advances_to_add.push(payroll.advances[i].id)
            }

            const data = {
                contract: contractID,
                date_from: dateFrom,
                date_to: dateTo,
                hours_total: payroll?.hours_total || 0,
                total_days: payroll?.total_days || 0,
                total_piece_work_count: payroll?.total_piece_work_count || 0,

                // hours metrics
                night_hours: payroll?.night_hours || 0,
                saturday_hours: payroll?.overall_saturday_hours || 0,
                sunday_hours: payroll?.overall_sunday_hours || 0,
                overtime_hours: payroll?.overtime_hours || 0,
                holiday_hours: payroll?.overall_holiday_hours || 0,

                // days metrics
                total_saturdays: payroll?.total_saturdays || 0,
                total_sundays: payroll?.total_sundays || 0,
                total_holiday: payroll?.total_holiday || 0,
                total_lunch_voucher: payroll?.total_lunch_voucher || 0,

                // banks - as bonus
                overtime_hours_as_bonus: renderOvertimeHoursAsBonus(), // overtime_hours (12) - moved_hours (8) = 4
                sunday_hours_as_bonus: payroll?.contract?.bank_sunday || 0,
                night_hours_as_bonus: payroll?.contract?.bank_night || 0,
                saturday_hours_as_bonus: payroll?.contract?.bank_saturday || 0,
                holiday_hours_as_bonus: payroll?.contract?.bank_holiday || 0,

                // sums
                absences: JSON.stringify(payroll.absences),
                deductions: deductions,
                advances: advances,
                bonuses: bonuses,
                rewards: rewards,
                functional_surcharges: functional_surcharges,
                advance_ids: advances_to_add,
                bonus_ids: bonuses_to_add,
                deduction_ids: deductions_to_add,
            }

            const request = await requests.post(API.PAYROLLS + 'bulk_create/', [data])

            if (request.status === 400) {
                const response = request.response?.[0]
                if (response !== undefined) {
                    if (response?.errors?.contract) {
                        toast({
                            type: 'error',
                            icon: 'warning',
                            title: response?.errors?.contract?.[0] || '',
                            animation: 'pulse',
                            time: 5000,
                        })
                    }
                }
            }

            if (request.status === 201) {
                // update contract bank + create history
                let latestContractVersion = null
                if (parseFloat(data.overtime_hours) > 0) {
                    const createHistoryOvertimeRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                        type: 1, // overtime
                        operation: 2, // decrease
                        amount: parseFloat(data.overtime_hours),
                        contract: contractID,
                        note: moment(dateTo).format('MM') + '/' + moment(dateTo).format('YYYY'),
                    })

                    if (createHistoryOvertimeRequest.status === 201) {
                        latestContractVersion = createHistoryOvertimeRequest.response.contract
                    }
                }

                if (parseFloat(data.holiday_hours_as_bonus) > 0) {
                    const createHistoryHolidayRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                        type: 2, // holiday
                        operation: 2, // decrease
                        amount: parseFloat(data.holiday_hours_as_bonus),
                        contract: contractID,
                        note: moment(dateTo).format('MM') + '/' + moment(dateTo).format('YYYY'),
                    })

                    if (createHistoryHolidayRequest.status === 201) {
                        latestContractVersion = createHistoryHolidayRequest.response.contract
                    }
                }

                if (parseFloat(data.saturday_hours_as_bonus) > 0) {
                    const createHistorySaturdayRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                        type: 3, // saturday
                        operation: 2, // decrease
                        amount: parseFloat(data.saturday_hours_as_bonus),
                        contract: contractID,
                        note: moment(dateTo).format('MM') + '/' + moment(dateTo).format('YYYY'),
                    })

                    if (createHistorySaturdayRequest.status === 201) {
                        latestContractVersion = createHistorySaturdayRequest.response.contract
                    }
                }

                if (parseFloat(data.sunday_hours_as_bonus) > 0) {
                    const createHistorySundayRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                        type: 4, // sunday
                        operation: 2, // decrease
                        amount: parseFloat(data.sunday_hours_as_bonus),
                        contract: contractID,
                        note: moment(dateTo).format('MM') + '/' + moment(dateTo).format('YYYY'),
                    })

                    if (createHistorySundayRequest.status === 201) {
                        latestContractVersion = createHistorySundayRequest.response.contract
                    }
                }

                if (parseFloat(data.night_hours_as_bonus) > 0) {
                    const createHistoryNightRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                        type: 5, // night
                        operation: 2, // decrease
                        amount: parseFloat(data.night_hours_as_bonus),
                        contract: contractID,
                        note: moment(dateTo).format('MM') + '/' + moment(dateTo).format('YYYY'),
                    })

                    if (createHistoryNightRequest.status === 201) {
                        latestContractVersion = createHistoryNightRequest.response.contract
                    }
                }

                // konto
                if (parseFloat(movedHours) > 0) {
                    const createHistoryBankHoursRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                        type: 6, // bank_hours
                        operation: 1, // increase
                        amount: parseFloat(movedHours),
                        contract: contractID,
                        note: moment(dateTo).format('MM') + '/' + moment(dateTo).format('YYYY'),
                    })

                    if (createHistoryBankHoursRequest.status === 201) {
                        latestContractVersion = createHistoryBankHoursRequest.response.contract
                    }
                }

                // update contract banks
                if (latestContractVersion !== null && setContracts) {
                    setContracts((prev) =>
                        prev.filter((item) => {
                            if (item.id === latestContractVersion.id) {
                                item['bank_hours'] = latestContractVersion.bank_hours
                                item['bank_overtime'] = latestContractVersion.bank_overtime
                                item['bank_holiday'] = latestContractVersion.bank_holiday
                                item['bank_saturday'] = latestContractVersion.bank_saturday
                                item['bank_sunday'] = latestContractVersion.bank_sunday
                                item['bank_night'] = latestContractVersion.bank_night
                                item['payroll'] = [request.response[0].id]
                            }
                            return item
                        })
                    )
                }

                if (setData) {
                    // payroll listing + setTotal
                    setTotal((prev) => prev + 1)
                    setData((prev) => [request.response[0], ...prev])
                }

                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('payroll_generated'),
                    animation: 'pulse',
                    time: 2000,
                })

                onClose()
            }
        }
        setProcessing(false)
    }

    const sumContractBonuses = () => {
        let sum = 0

        for (let i = 0; i < contractBonuses.length; i++) {
            sum += parseFloat(contractBonuses[i].amount)
        }

        return parseFloat(sum || 0)
    }

    const getMovedHours = () => {
        let hours = 0

        hours = parseFloat(payroll.overtime_hours || 0) - parseFloat(payroll.overtime_hours_as_bonus || 0)
        if (isNaN(hours) || parseFloat(hours) < 0) {
            hours = 0
        }

        return parseFloat(0)
    }

    const renderOvertimeHoursAsBonus = () => {
        let hours = payroll.overtime_hours

        if (movedHours !== '') {
            hours = parseFloat(payroll.overtime_hours || 0) - parseFloat(movedHours || 0)
        }

        return parseFloat(hours || 0)
    }

    const handlePayrollRemove = async () => {
        setRestoring(true)
        // step 1. payroll record
        const request = await requests.del(API.PAYROLLS + payroll.id + '/')
        if (request.status === 204) {
            // step 2. remove bonusPayments based on contract bonuses if exists for this period
            const functionalSurcharges = payroll.bonuses.filter((item) => item.type === 5)
            for (let i = 0; i < functionalSurcharges.length; i++) {
                // remove functional surcharge (REQUIRE PERM: payrolls.c_delete_all_bonus_payments)
                await requests.del(API.PAYROLLS + 'bonus_payments/' + functionalSurcharges[i].id + '/')
            }

            // step 3. restore banks if needed and create history
            let latestContractVersion = null
            if (parseFloat(payroll.overtime_hours) > 0) {
                // restore bank_overtime
                const overtimeRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                    type: 1, // overtime
                    operation: 1, // increase
                    amount: parseFloat(payroll.overtime_hours),
                    contract: payroll.contract.id,
                    note: moment(payroll.date_to).format('MM') + '/' + moment(payroll.date_to).format('YYYY'),
                })

                if (overtimeRequest.status === 201) {
                    latestContractVersion = overtimeRequest.response.contract
                }
            }

            if (parseFloat(payroll.holiday_hours_as_bonus) > 0) {
                const holidayRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                    type: 2, // holiday
                    operation: 1, // increase
                    amount: parseFloat(payroll.holiday_hours_as_bonus),
                    contract: payroll.contract.id,
                    note: moment(payroll.date_to).format('MM') + '/' + moment(payroll.date_to).format('YYYY'),
                })

                if (holidayRequest.status === 201) {
                    latestContractVersion = holidayRequest.response.contract
                }
            }

            if (parseFloat(payroll.saturday_hours_as_bonus) > 0) {
                const saturdayRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                    type: 3, // saturday
                    operation: 1, // increase
                    amount: parseFloat(payroll.saturday_hours_as_bonus),
                    contract: payroll.contract.id,
                    note: moment(payroll.date_to).format('MM') + '/' + moment(payroll.date_to).format('YYYY'),
                })

                if (saturdayRequest.status === 201) {
                    latestContractVersion = saturdayRequest.response.contract
                }
            }

            if (parseFloat(payroll.sunday_hours_as_bonus) > 0) {
                const sundayRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                    type: 4, // sunday
                    operation: 1, // increase
                    amount: parseFloat(payroll.sunday_hours_as_bonus),
                    contract: payroll.contract.id,
                    note: moment(payroll.date_to).format('MM') + '/' + moment(payroll.date_to).format('YYYY'),
                })

                if (sundayRequest.status === 201) {
                    latestContractVersion = sundayRequest.response.contract
                }
            }

            if (parseFloat(payroll.night_hours_as_bonus) > 0) {
                const nightRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                    type: 5, // night
                    operation: 1, // increase
                    amount: parseFloat(payroll.night_hours_as_bonus),
                    contract: payroll.contract.id,
                    note: moment(payroll.date_to).format('MM') + '/' + moment(payroll.date_to).format('YYYY'),
                })

                if (nightRequest.status === 201) {
                    latestContractVersion = nightRequest.response.contract
                }
            }

            // konto
            if (parseFloat(getMovedHours()) > 0) {
                const bankHoursRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                    type: 6, // bank_hours
                    operation: 2, // increase
                    amount: parseFloat(getMovedHours()),
                    contract: payroll.contract.id,
                    note: moment(payroll.date_to).format('MM') + '/' + moment(payroll.date_to).format('YYYY'),
                })

                if (bankHoursRequest.status === 201) {
                    latestContractVersion = bankHoursRequest.response.contract
                }
            }

            // update contract banks
            if (latestContractVersion !== null && setContracts) {
                setContracts((prev) =>
                    prev.filter((item) => {
                        if (item.id === latestContractVersion.id) {
                            item['bank_hours'] = latestContractVersion.bank_hours
                            item['bank_overtime'] = latestContractVersion.bank_overtime
                            item['bank_holiday'] = latestContractVersion.bank_holiday
                            item['bank_saturday'] = latestContractVersion.bank_saturday
                            item['bank_sunday'] = latestContractVersion.bank_sunday
                            item['bank_night'] = latestContractVersion.bank_night
                            item['payroll'] = []
                        }
                        return item
                    })
                )
            }

            if (setData) {
                // payroll listing + setTotal
                setTotal((prev) => prev - 1)
                setData((prev) => prev.filter((item) => item.id !== payroll.id))
            }

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('payroll_removed'),
                animation: 'pulse',
                time: 5000,
            })
            onClose()
        }
        setRestoring(false)
    }

    const calculateSum = (data) => {
        let sum = 0

        for (let i = 0; i < data.length; i++) {
            sum += parseFloat(data[i].amount)
        }

        return sum
    }

    return (
        <SpinnerSegment
            loadingMessage={
                restoring ? t('removing_payroll_and_restoring_previous_state') : t('loading_payroll_details')
            }
            loading={loading || restoring}
        >
            {!loading && payroll !== null ? (
                <>
                    <Segment>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <Header
                                        as="h3"
                                        content={payroll.contract.employee.fullname_with_titles}
                                        style={{ marginBottom: 0 }}
                                    />
                                    <span style={{ opacity: '0.8' }}>
                                        {payroll.contract.employee.custom_prefix_and_id !== ''
                                            ? payroll.contract.employee.custom_prefix_and_id + ' · '
                                            : ''}
                                        {payroll.contract.work_position.title}
                                    </span>
                                </Grid.Column>
                                <Grid.Column
                                    style={{
                                        textAlign: 'right',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'end',
                                    }}
                                >
                                    <div>
                                        <Header
                                            as="h3"
                                            style={{ marginBottom: 0 }}
                                            content={
                                                <strong
                                                    style={{
                                                        fontSize: '1.2rem',
                                                        fontWeight: 'bold',
                                                        textTransform: 'capitalize',
                                                    }}
                                                >
                                                    {moment(payroll?.date_from || dateFrom)
                                                        .locale(setLocaleLanguage())
                                                        .format('MMMM')}{' '}
                                                    {moment(payroll?.date_from || dateFrom).format('YYYY')}
                                                </strong>
                                            }
                                        />
                                        <span style={{ opacity: '0.8' }}>
                                            {t('payroll_record')} -{' '}
                                            {payrollID ? '#' + payrollID : t('draft').toLocaleLowerCase()}
                                        </span>
                                    </div>
                                    {payrollID && (
                                        <div style={{ marginLeft: '2rem' }}>
                                            {!payroll.is_closed ? (
                                                <Label color="green">{t('open')}</Label>
                                            ) : (
                                                <Label color="red">{t('closed')}</Label>
                                            )}

                                            {!payroll.is_closed && !profileID && (
                                                <CanView permissions={['payrolls.c_delete_all_payrolls']}>
                                                    <ConfirmModal
                                                        description={t('payroll_removal_message')}
                                                        onConfirm={() => handlePayrollRemove(payrollID)}
                                                        button={
                                                            <Label
                                                                style={{
                                                                    background: 'var(--danger)',
                                                                    color: 'var(--light)',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                <Icon
                                                                    name="trash"
                                                                    style={{
                                                                        marginRight: '0.5rem',
                                                                        position: 'relative',
                                                                        top: '0.1rem',
                                                                    }}
                                                                />
                                                                <span>{t('remove').toUpperCase()}</span>
                                                            </Label>
                                                        }
                                                    />
                                                </CanView>
                                            )}
                                        </div>
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>

                    <Tab
                        menu={{ secondary: true, pointing: true }}
                        panes={[
                            {
                                menuItem: t('overview'),
                                render: () => (
                                    <TabPane attached={false}>
                                        <Grid>
                                            <Grid.Row columns="2" style={{ padding: 0 }}>
                                                <Grid.Column style={{ paddingLeft: 0 }}>
                                                    <Table size="tiny">
                                                        <Table.Row>
                                                            <Table.Cell style={{ fontWeight: 'bold' }}>
                                                                {t('date_from')}
                                                            </Table.Cell>
                                                            <Table.Cell style={{ width: '100px' }}>
                                                                {moment(payroll.date_from).format(dateFormat)}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell style={{ fontWeight: 'bold' }}>
                                                                {t('date_to')}
                                                            </Table.Cell>
                                                            <Table.Cell style={{ width: '100px' }}>
                                                                {moment(payroll.date_to).format(dateFormat)}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell style={{ fontWeight: 'bold' }}>
                                                                {t('total_work_days')}
                                                            </Table.Cell>
                                                            <Table.Cell style={{ width: '100px' }}>
                                                                {payroll.total_days || 0}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell style={{ fontWeight: 'bold' }}>
                                                                {t('total_work_saturdays')}
                                                            </Table.Cell>
                                                            <Table.Cell style={{ width: '100px' }}>
                                                                {payroll.total_saturdays || 0}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell style={{ fontWeight: 'bold' }}>
                                                                {t('total_work_sundays')}
                                                            </Table.Cell>
                                                            <Table.Cell style={{ width: '100px' }}>
                                                                {payroll.total_sundays || 0}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell style={{ fontWeight: 'bold' }}>
                                                                {t('total_work_holidays')}
                                                            </Table.Cell>
                                                            <Table.Cell style={{ width: '100px' }}>
                                                                {payroll.total_holiday || 0}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    </Table>
                                                </Grid.Column>
                                                <Grid.Column style={{ paddingRight: 0 }}>
                                                    <Table size="tiny">
                                                        <Table.Row>
                                                            <Table.Cell style={{ fontWeight: 'bold' }}>
                                                                {t('work_hours')}
                                                            </Table.Cell>
                                                            <Table.Cell style={{ width: '100px' }}>
                                                                {payroll.hours_total || 0}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell style={{ fontWeight: 'bold' }}>
                                                                {t('overtime_hours')}
                                                            </Table.Cell>
                                                            <Table.Cell style={{ width: '100px' }}>
                                                                {payroll.overtime_hours || 0}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell style={{ fontWeight: 'bold' }}>
                                                                {t('saturday_hours')}
                                                            </Table.Cell>
                                                            <Table.Cell style={{ width: '100px' }}>
                                                                {payroll.overall_saturday_hours ||
                                                                    payroll.saturday_hours ||
                                                                    0}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell style={{ fontWeight: 'bold' }}>
                                                                {t('sunday_hours')}
                                                            </Table.Cell>
                                                            <Table.Cell style={{ width: '100px' }}>
                                                                {payroll.overall_sunday_hours ||
                                                                    payroll.sunday_hours ||
                                                                    0}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell style={{ fontWeight: 'bold' }}>
                                                                {t('holiday_hours')}
                                                            </Table.Cell>
                                                            <Table.Cell style={{ width: '100px' }}>
                                                                {payroll.overall_holiday_hours ||
                                                                    payroll.holiday_hours ||
                                                                    0}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell style={{ fontWeight: 'bold' }}>
                                                                {t('night_hours')}
                                                            </Table.Cell>
                                                            <Table.Cell style={{ width: '100px' }}>
                                                                {payroll.night_hours || 0}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    </Table>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>

                                        <Divider />

                                        <Grid>
                                            <Grid.Row columns="2" style={{ padding: 0 }}>
                                                <Grid.Column style={{ paddingLeft: 0 }}>
                                                    <Header as="h3" content={t('others')} />
                                                    <Table size="tiny">
                                                        <Table.Row>
                                                            <Table.Cell style={{ fontWeight: 'bold' }}>
                                                                {t('bonuses_and_rewards')}
                                                            </Table.Cell>
                                                            <Table.Cell style={{ width: '100px' }}>
                                                                {payrollID
                                                                    ? parseFloat(
                                                                          (payroll.functional_surcharges || 0) +
                                                                              (payroll.rewards || 0)
                                                                      )
                                                                    : parseFloat(payroll.bonus_sum || 0) +
                                                                      sumContractBonuses()}{' '}
                                                                {payrollID
                                                                    ? payroll.currency
                                                                    : payroll.contract.currency}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell style={{ fontWeight: 'bold' }}>
                                                                {t('advances')}
                                                            </Table.Cell>
                                                            <Table.Cell style={{ width: '100px' }}>
                                                                {payrollID
                                                                    ? calculateSum(payroll?.advances || [])
                                                                    : parseFloat(payroll.advance_sum || 0)}{' '}
                                                                {payrollID
                                                                    ? payroll.currency
                                                                    : payroll.contract.currency}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell style={{ fontWeight: 'bold' }}>
                                                                {t('deductions')}
                                                            </Table.Cell>
                                                            <Table.Cell style={{ width: '100px' }}>
                                                                {payrollID
                                                                    ? calculateSum(payroll?.deductions || [])
                                                                    : parseFloat(payroll.deduction_sum || 0)}{' '}
                                                                {payrollID
                                                                    ? payroll.currency
                                                                    : payroll.contract.currency}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    </Table>
                                                </Grid.Column>
                                                {canPerformCorrections && (
                                                    <Grid.Column style={{ paddingRight: 0 }}>
                                                        <Header as="h3" content={t('bank_hours_as_bonus')} />
                                                        <Table size="tiny">
                                                            <Table.Row>
                                                                <Table.Cell style={{ fontWeight: 'bold' }}>
                                                                    {t('overtime')}
                                                                </Table.Cell>
                                                                <Table.Cell style={{ width: '150px' }}>
                                                                    {!payrollID
                                                                        ? renderOvertimeHoursAsBonus()
                                                                        : payroll.overtime_hours_as_bonus}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell style={{ fontWeight: 'bold' }}>
                                                                    {t('saturday')}
                                                                </Table.Cell>
                                                                <Table.Cell style={{ width: '150px' }}>
                                                                    {!payrollID
                                                                        ? payroll.contract.bank_saturday
                                                                        : payroll.saturday_hours_as_bonus}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell style={{ fontWeight: 'bold' }}>
                                                                    {t('sunday')}
                                                                </Table.Cell>
                                                                <Table.Cell style={{ width: '150px' }}>
                                                                    {!payrollID
                                                                        ? payroll.contract.bank_sunday
                                                                        : payroll.sunday_hours_as_bonus}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell style={{ fontWeight: 'bold' }}>
                                                                    {t('holiday')}
                                                                </Table.Cell>
                                                                <Table.Cell style={{ width: '150px' }}>
                                                                    {!payrollID
                                                                        ? payroll.contract.bank_holiday
                                                                        : payroll.holiday_hours_as_bonus}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell style={{ fontWeight: 'bold' }}>
                                                                    {t('night')}
                                                                </Table.Cell>
                                                                <Table.Cell style={{ width: '150px' }}>
                                                                    {!payrollID
                                                                        ? payroll.contract.bank_night
                                                                        : payroll.night_hours_as_bonus}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            {!payrollID && (
                                                                <CanView
                                                                    permissions={[
                                                                        'attendance.c_can_create_corrections',
                                                                    ]}
                                                                >
                                                                    <Table.Row>
                                                                        <Table.Cell
                                                                            singleLine
                                                                            style={{ fontWeight: 'bold' }}
                                                                        >
                                                                            <span>{t('moved_hours')}</span>
                                                                            <Popup
                                                                                position="left center"
                                                                                trigger={
                                                                                    <Icon
                                                                                        name="information-circle-outline"
                                                                                        style={{ marginLeft: '0.5rem' }}
                                                                                    />
                                                                                }
                                                                                content={t(
                                                                                    'allow_you_to_move_extra_overtime_hours_to_the_next_month'
                                                                                )}
                                                                            />
                                                                        </Table.Cell>
                                                                        <Table.Cell style={{ width: '150px' }}>
                                                                            <Form onSubmit={(e) => e.preventDefault()}>
                                                                                <SuperField
                                                                                    error={
                                                                                        isValidHours()
                                                                                            ? false
                                                                                            : t('invalid_entry')
                                                                                    }
                                                                                    as="input"
                                                                                    style={{
                                                                                        width: '150px',
                                                                                    }}
                                                                                    value={movedHours}
                                                                                    onChange={(e, { value }) =>
                                                                                        setMovedHours(
                                                                                            value.replace(',', '.')
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Form>
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                </CanView>
                                                            )}
                                                        </Table>

                                                        {payrollID && (
                                                            <p>
                                                                <strong>{t('moved_hours')}: </strong>
                                                                {parseFloat(payroll.overtime_hours || 0) -
                                                                    parseFloat(payroll.overtime_hours_as_bonus || 0)}
                                                            </p>
                                                        )}
                                                    </Grid.Column>
                                                )}
                                            </Grid.Row>
                                        </Grid>
                                    </TabPane>
                                ),
                            },
                            {
                                menuItem: t('absences'),
                                render: () => (
                                    <TabPane attached={false}>
                                        <Table size="small">
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>{t('absence_type')}</Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        {t('hours').toLocaleLowerCase()}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>{t('days')}</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {(payroll.absences?.length === 0 ||
                                                    payroll.absences === undefined ||
                                                    payroll.absences === null) && (
                                                    <Table.Row>
                                                        <Table.Cell
                                                            colSpan="3"
                                                            style={{ textAlign: 'center', fontWeight: 'bold' }}
                                                        >
                                                            {t('no_data')}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )}
                                                {payroll.absences.map((absence, index) => (
                                                    <Table.Row key={index}>
                                                        <Table.Cell style={{ fontWeight: 'bold' }}>
                                                            {absence.type}
                                                        </Table.Cell>
                                                        <Table.Cell>{absence.value}</Table.Cell>
                                                        <Table.Cell>{absence.days_value}</Table.Cell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Table>
                                    </TabPane>
                                ),
                            },
                            {
                                menuItem: t('bonuses') + ' (+)',
                                render: () => (
                                    <TabPane attached={false}>
                                        <Header as="h3" content={t('assigned_bonuses')} />
                                        <Table size="small">
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>{t('type')}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t('date')}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t('sum')}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t('note')}</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {payroll.bonuses.length === 0 && (
                                                    <Table.Row>
                                                        <Table.Cell
                                                            colSpan="4"
                                                            style={{ textAlign: 'center', fontWeight: 'bold' }}
                                                        >
                                                            {t('no_data')}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )}
                                                {payroll.bonuses.map((bonus, index) => (
                                                    <Table.Row key={index}>
                                                        <Table.Cell>{bonus.bonus_type?.title}</Table.Cell>
                                                        <Table.Cell>
                                                            {moment(bonus.billing_period).format(dateFormat)}
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {bonus.amount} {bonus.currency}
                                                        </Table.Cell>
                                                        <Table.Cell>{bonus.note}</Table.Cell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Table>

                                        {contractBonuses.length > 0 && (
                                            <>
                                                <Header as="h3" content={t('contract_bonuses')} />
                                                <Table size="small">
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell>{t('title')}</Table.HeaderCell>
                                                            <Table.HeaderCell>{t('sum')}</Table.HeaderCell>
                                                            <Table.HeaderCell>{t('note')}</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        {contractBonuses.map((bonus, index) => (
                                                            <Table.Row key={index}>
                                                                <Table.Cell>{bonus.title}</Table.Cell>
                                                                <Table.Cell>
                                                                    {bonus.amount} {bonus.currency}
                                                                </Table.Cell>
                                                                <Table.Cell>{bonus.note}</Table.Cell>
                                                            </Table.Row>
                                                        ))}
                                                    </Table.Body>
                                                </Table>
                                            </>
                                        )}
                                    </TabPane>
                                ),
                            },
                            {
                                menuItem: t('advances') + ' (-)',
                                render: () => (
                                    <TabPane attached={false}>
                                        <Table size="small">
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>{t('date')}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t('sum')}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t('note')}</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {payroll.advances.length === 0 && (
                                                    <Table.Row>
                                                        <Table.Cell
                                                            colSpan="3"
                                                            style={{ textAlign: 'center', fontWeight: 'bold' }}
                                                        >
                                                            {t('no_data')}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )}
                                                {payroll.advances.map((advance, index) => (
                                                    <Table.Row key={index}>
                                                        <Table.Cell>
                                                            {moment(advance.billing_period).format(dateFormat)}
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {advance.amount} {advance.currency}
                                                        </Table.Cell>
                                                        <Table.Cell>{advance.note}</Table.Cell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Table>
                                    </TabPane>
                                ),
                            },
                            {
                                menuItem: t('deductions') + ' (-)',
                                render: () => (
                                    <TabPane attached={false}>
                                        <Table size="small">
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>{t('date')}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t('sum')}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t('note')}</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {payroll.deductions.length === 0 && (
                                                    <Table.Row>
                                                        <Table.Cell
                                                            colSpan="3"
                                                            style={{ textAlign: 'center', fontWeight: 'bold' }}
                                                        >
                                                            {t('no_data')}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )}
                                                {payroll.deductions.map((deduction, index) => (
                                                    <Table.Row key={index}>
                                                        <Table.Cell>
                                                            {moment(deduction.billing_period).format(dateFormat)}
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {deduction.amount} {deduction.currency}
                                                        </Table.Cell>
                                                        <Table.Cell>{deduction.note}</Table.Cell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Table>
                                    </TabPane>
                                ),
                            },
                        ]}
                    />

                    {!payrollID && (
                        <>
                            <Divider />
                            <Form.Field style={{ textAlign: 'right' }}>
                                <ModalCancel onClose={onClose} />
                                <Button
                                    primary
                                    content={t('confirm')}
                                    loading={processing}
                                    disabled={processing || !isValidHours()}
                                    onClick={() => handleSubmit()}
                                />
                            </Form.Field>
                        </>
                    )}
                </>
            ) : (
                <Header as="h3" style={{ textAlign: 'center', fontWeight: 'bold', marginTop: '1rem' }}>
                    {t('payroll_details_can_not_be_found')}.
                </Header>
            )}
        </SpinnerSegment>
    )
}

export default PayrollDetails
