import React from 'react'
//store
import { useHasPermissions } from '@helpers/hooks'
//components
import { Tab, Grid } from 'semantic-ui-react'
import PersonalInfoSection from './personal-tab/PersonalInfoSection'
// import ProfileInfoSection from './personal-tab/ProfileInfoSection';
import OtherInfoSection from './personal-tab/OtherInfoSection'
import BusinessDetailSection from './personal-tab/BusinessDetailSection'
import ExperienceSection from './personal-tab/ExperienceSection'
import EducationSection from './personal-tab/EducationSection'
import SkillSection from './personal-tab/SkillSection'
import LanguageSection from './personal-tab/LanguageSection'
import InterestSection from './personal-tab/InterestSection'
import FamilySection from './personal-tab/FamilySection'

const ProfileCardInfoTab = ({ data, setData, patchEndpoint, canManage }) => {
    const isManageable = useHasPermissions(['common.c_manage_user_employees', 'common.c_manage_all_employees'])

    return (
        <Tab.Pane style={{ border: 'none', boxShadow: 'none' }}>
            <Grid>
                <Grid.Row>
                    <Grid.Column computer={16} table={16} mobile={16}>
                        {/* <ProfileInfoSection data={data} setData={setData} patchEndpoint={patchEndpoint} /> */}
                        <PersonalInfoSection
                            data={data}
                            setData={setData}
                            patchEndpoint={patchEndpoint}
                            canManage={canManage}
                        />
                        <OtherInfoSection
                            data={data}
                            setData={setData}
                            patchEndpoint={patchEndpoint}
                            canManage={canManage}
                        />
                        <BusinessDetailSection
                            data={data}
                            setData={setData}
                            patchEndpoint={patchEndpoint}
                            canManage={canManage}
                        />
                        <FamilySection
                            result={data}
                            setResult={setData}
                            patchEndpoint={patchEndpoint}
                            canManage={canManage}
                        />
                        <ExperienceSection
                            result={data}
                            setResult={setData}
                            isManageable={isManageable}
                            patchEndpoint={patchEndpoint}
                            canManage={canManage}
                        />
                        <EducationSection
                            result={data}
                            setResult={setData}
                            isManageable={isManageable}
                            patchEndpoint={patchEndpoint}
                            canManage={canManage}
                        />
                        <SkillSection
                            result={data}
                            setResult={setData}
                            isManageable={canManage}
                            patchEndpoint={patchEndpoint}
                            canManage={canManage}
                        />
                        <LanguageSection
                            result={data}
                            setResult={setData}
                            isManageable={canManage}
                            patchEndpoint={patchEndpoint}
                            canManage={canManage}
                        />
                        <InterestSection
                            result={data}
                            setResult={setData}
                            isManageable={canManage}
                            patchEndpoint={patchEndpoint}
                            canManage={canManage}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    )
}

export default ProfileCardInfoTab
