import React, { useState, useEffect} from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from "react-i18next"
// store
import { API } from '@store/config';
import { routes } from '@store/routes';
import { icons } from '@store/icons';
import { getRequest } from '@services/ServiceCommon';
// components
import { Grid } from 'semantic-ui-react';
import CanView from '@components/perms/CanView';
import Loading from '@components/general/Loading';
import BreadcrumbNav from '@components/BreadcrumbNav';
import SuperDuperModal from "@components/modals/SuperDuperModal"
import PlusCardButton from '@components/buttons/PlusCardButton';
// module specific components
import TeamCard from '../components/TeamCard';
import TeamForm from "../components/forms/TeamForm";

const TeamsList = () => {
    const { t } = useTranslation();

    const [teams, setTeams] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const history = useHistory()

    useEffect(() => {
        async function fetchData() {
            const result = await getRequest(API.TEAMS + "?only_basic_info=True");
            if (result.status === 200) {
                setTeams(result.response);
            }

            setIsLoading(false);
        };

        fetchData();
    }, []);

    const handleTeamCreation = (response) => {
        history.push(routes.TEAMS_DETAIL + response.id)
    }

    return(

        <CanView permissions={["teams.c_view_user_teams", "teams.c_view_all_teams"]} redirect>
            <BreadcrumbNav items={[
                { 'name': t('teams'), 'icon': icons.TEAMS, 'href': '' }
            ]}/>
            { isLoading ? <Loading /> :
                <Grid centered stretched columns={3} style={{ margin: "1rem" }}>
                    <CanView permissions={["teams.c_manage_all_teams"]}>
                        <Grid.Column mobile={16} tablet={8} computer={5}>
                            <SuperDuperModal
                                trigger={
                                    <PlusCardButton text={t('add_team')} to={null}/>
                                }
                                header={t('add_team')}
                                size="small"
                                content={
                                    <TeamForm
                                        btnName={t('create')}
                                        onConfirm={(response) => handleTeamCreation(response)}
                                    />
                                }
                            />
                        </Grid.Column>
                    </CanView>

                    {teams.map(team => (
                        <Grid.Column key={team.id} mobile={16} tablet={8} computer={5}>
                            <TeamCard 
                                id={team.id}
                                name={team.name}
                                description={team.description}
                            />
                        </Grid.Column>
                    ))}
                </Grid>
            }
        </CanView>
    );

}

export default TeamsList;