import React from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
// components
import ListView from '@components/ListView'
import SuperField from '@components/forms/SuperField'
import { Form } from 'semantic-ui-react'
// custom components
import MilestoneForm from '../../forms/MilestoneForm'
import ProjectMilestoneCard from './ProjectMilestoneCard'

const ProjectMilestonesTab = ({ project }) => {
    const { t } = useTranslation()

    return (
        <ListView
            as="card"
            allowSearch
            itemsPerRow={4}
            endpoint={API.PROJECT_MILESTONES}
            initialFilters={{
                project: project.id,
                from_date: '',
                due_date: '',
                status: '',
            }}
            actions={[
                {
                    as: 'modal',
                    type: 'add',
                    name: t('create_milestone'),
                    modalSize: 'small',
                    permissions: ['projects.c_manage_milestones'],
                    modal: <MilestoneForm project={project} />,
                },
            ]}
            renderFilterFields={(filters, setFilters) => (
                <>
                    <Form.Group widths={'equal'}>
                        <SuperField
                            as="datepicker"
                            label={t('date_from')}
                            value={filters.from_date}
                            onChange={(e, { value }) => setFilters({ ...filters, from_date: value })}
                        />
                        <SuperField
                            as="datepicker"
                            label={t('date_to')}
                            value={filters.to_date}
                            onChange={(e, { value }) => setFilters({ ...filters, to_date: value })}
                        />
                    </Form.Group>
                    <SuperField
                        as="choice"
                        type="milestone_statuses"
                        label={t('status')}
                        value={filters.status}
                        onChange={(e, { value }) => setFilters({ ...filters, status: value })}
                    />
                </>
            )}
            renderCard={(data, setData) =>
                data.map((milestone) => (
                    <ProjectMilestoneCard
                        key={milestone.id}
                        milestone={milestone}
                        setData={setData}
                        project={project}
                    />
                ))
            }
        />
    )
}

export default ProjectMilestonesTab
