import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// components
import Icon from '@components/Icon'
import TimesheetViewGraph from '@components/widgets/statistics/TimesheetViewGraph'
import { Grid, Button, Divider } from 'semantic-ui-react'

const TimesheetStatistic = ({ employee, params, dateFrom, dateTo }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)
    const [view, setView] = useState('hours')

    return (
        <>
            {params !== undefined && (
                <>
                    <Divider />
                    <Grid stackable>
                        <Grid.Row columns={2} style={{ paddingTop: 0 }}>
                            <Grid.Column>
                                <Button.Group size="tiny" basic className="custom-filter-button-group">
                                    <Button active={view === 'hours'} onClick={() => setView('hours')}>
                                        {t('hours')}
                                    </Button>
                                    <Button active={view === 'activities'} onClick={() => setView('activities')}>
                                        {t('activities')}
                                    </Button>
                                    <Button active={view === 'clients'} onClick={() => setView('clients')}>
                                        {t('clients')}
                                    </Button>
                                    <Button active={view === 'orders'} onClick={() => setView('orders')}>
                                        {t('orders')}
                                    </Button>
                                    <Button active={view === 'projects'} onClick={() => setView('projects')}>
                                        {t('projects')}
                                    </Button>
                                </Button.Group>
                            </Grid.Column>
                            <Grid.Column style={{ textAlign: 'right', paddingTop: '0.8rem' }}>
                                <Icon name="calendar-outline" style={{ marginRight: '0.5rem', fontSize: '1.2rem' }} />
                                <span style={{ position: 'relative', top: '-0.2rem' }}>
                                    {`${dateFrom.format(dateFormat)} - ${dateTo.format(dateFormat)}`}
                                </span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row verticalAlignment="middle" columns={1}>
                            <Grid.Column>
                                <TimesheetViewGraph
                                    as="segment"
                                    view={view}
                                    employee={employee}
                                    dateFrom={dateFrom}
                                    dateTo={dateTo}
                                    params={params}
                                    legendPosition="bottom"
                                />
                            </Grid.Column>
                            {/* <Grid.Column></Grid.Column> */}
                        </Grid.Row>
                    </Grid>
                </>
            )}
        </>
    )
}

export default TimesheetStatistic
