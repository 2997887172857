import React from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
// components
import SuperField from '@components/forms/SuperField';
import { Form, Divider, Button, Grid, Icon, Header } from 'semantic-ui-react';

const StoredItemFilterForm = ({ filters, setFilters, setVisible, onFilter }) => {
    const { t } = useTranslation();

    return (
        <Form style={{ paddingLeft: '1rem', paddingRight: '1rem', textAlign: 'left' }} onSubmit={() => onFilter()}>
            <Grid>
                <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
                    <Grid.Column>
                        <Header
                            as="h2"
                            content={t('filters')}
                            style={{ marginTop: '1rem', paddingLeft: '1rem', textAlign: 'left' }}
                        />
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Icon
                            name="close"
                            style={{ cursor: 'pointer', color: 'var(--danger)', marginTop: '1rem' }}
                            onClick={() => setVisible(false)}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider />

            <SuperField
                as="choice"
                search
                label={t('category')}
                text="title"
                endpoint={API.ASSET_CATEGORIES + '?query={id, title}'}
                value={filters.category}
                onChange={(e, { value }) => setFilters({ ...filters, category: value })}
            />

            <SuperField
                as="choice"
                search
                label={t('warehouse')}
                text="title"
                endpoint={API.ASSET_WAREHOUSES + '?query={id, title}'}
                value={filters.warehouse}
                onChange={(e, { value }) => setFilters({ ...filters, warehouse: value })}
            />

            <SuperField
                as="checkbox"
                label={t('only_vehicles')}
                checked={filters.is_vehicle}
                onChange={() => setFilters({ ...filters, is_vehicle: !filters.is_vehicle })}
            />

            <SuperField
                as="checkbox"
                label={t('only_safety_equipments')}
                checked={filters.is_safety_equipment}
                onChange={() => setFilters({ ...filters, is_safety_equipment: !filters.is_safety_equipment })}
            />

            <SuperField
                as="checkbox"
                label={t('only_grouped_items')}
                checked={filters.only_groups}
                onChange={() => setFilters({ ...filters, only_groups: !filters.only_groups })}
            />

            <SuperField
                as="checkbox"
                label={t('show_only_stored_items')}
                checked={filters.show_only_stored_items}
                onChange={() => setFilters({ ...filters, show_only_stored_items: !filters.show_only_stored_items })}
            />

            <SuperField
                as="checkbox"
                label={t('show_non_returned_items')}
                checked={filters.non_returned_items}
                onChange={() =>
                    setFilters({ ...filters, non_returned_items: filters.non_returned_items === true ? '' : true })
                }
            />

            <SuperField
                as="checkbox"
                label={t('show_only_archived_items')}
                checked={filters.show_inactive}
                onChange={() => setFilters({ ...filters, show_inactive: !filters.show_inactive })}
            />

            <Divider />
            <Form.Field>
                <Button.Group>
                    <Button size="small" primary>
                        {' '}
                        {t('apply')}{' '}
                    </Button>
                </Button.Group>
            </Form.Field>
        </Form>
    );
};

export default StoredItemFilterForm;
