import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
// store 
import { API } from '@store/config';
import { deleteRequest, patchRequest } from '@services/ServiceCommon';
// components
import SuperDuperModal from '@components/modals/SuperDuperModal';
import CanView from '@components/perms/CanView';
import { FlexRow, FlexItem } from '@components/tables/FlexTable'
import { Button, Grid, Header, Icon, Label, Divider } from 'semantic-ui-react';
import MilestoneForm from '../forms/MilestoneForm';
import ConfrimModal from '@components/modals/ConfrimModal';
import moment from 'moment';

const Milestones = ({ milestones, setStats }) => {
    const { t } = useTranslation();

    const onAdd = async (response) => {
        setStats((prevState) => {
            return {
                ...prevState,
                data: {
                    ...prevState.data,
                    milestones: [...prevState.data.milestones, response]
                }
            }
        })
 
    }

    const onUpdate = async (response) => {
        setStats((prevState) => {
            return {
                ...prevState,
                data: {
                    ...prevState.data,
                    milestones: prevState.data.milestones.filter(milestone => {
                        if (milestone.id === response.id) {
                            milestone.is_active = response.is_active
                            milestone.from_date = response.from_date
                            milestone.due_date = response.due_date
                            milestone.name = response.name
                            milestone.description = response.description
                        }
    
                        return milestone
                    })
                }
            }
        })
    }

    const onDelete = async (id) => {
        const request = await deleteRequest(API.PROJECT_MILESTONES + id + "/");

        if (request.status === 204) {
            setStats((prevState) => {
                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        milestones: prevState.data.milestones.filter(milestone => milestone.id !== id)
                    }
                }
            })
        }
    }

    const onStateChange = async (id, status) => {
        const request = await patchRequest(API.PROJECT_MILESTONES + id + "/", { 
            is_active: status
        })

        if( request.status === 200 ){
            setStats((prevState) => {
                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        milestones: prevState.data.milestones.filter(milestone => {
                            if (milestone.id === id) {
                                milestone.is_active = status
                            }
        
                            return milestone
                        })
                    }
                }
            })
        }
    }

    const MilestoneView = ({ milestone, onDelete, onUpdate, onStateChange }) => {
        const [showActions, setShowActions] = useState(false)

        return (
            <FlexRow 
                borders
                padding="0.4rem"
                background="transparent"
                onMouseOver={ () => setShowActions(true) }
                onMouseLeave={ () => setShowActions(false) }
            >
                <FlexItem basis="400%">
                    <span style={{ fontSize: "1.5rem", fontWeight: "bold"}}>{milestone.name}</span><br/><br/>
                    <Label basic color={milestone.is_active ? "green" : "red"}>{milestone.is_active ? "Active" : "Closed"}</Label>
                    {milestone.from_date ? <Label basic>{moment(milestone.from_date).format("DD.MM.YYYY")}</Label> : milestone.from_date}
                    {milestone.due_date ? <Label basic>{moment(milestone.due_date).format("DD.MM.YYYY")}</Label> : milestone.due_date}
                </FlexItem>
                { showActions && 
                    <CanView permissions={["projects.c_manage_milestones"]}>
                        <FlexRow padding="0" background="transparent">
                            <FlexItem>
                                { !milestone.is_active 
                                    ? 
                                    <Icon
                                        title="Activate"
                                        color='green'
                                        name='check circle outline'
                                        style={{ cursor: "pointer"}}
                                        onClick={ () => onStateChange(milestone.id, true) }
                                        />
                                        :
                                        <Icon
                                        title="Deactivate"
                                        color='red'
                                        name='ban'
                                        style={{ cursor: "pointer"}}
                                        onClick={ () => onStateChange(milestone.id, false) }
                                    />
                                }
                            </FlexItem>
                            <FlexItem textAlign="center">
                                <SuperDuperModal
                                    trigger={
                                        <Icon name="pencil" style={{ marginRight: 0, color: "var(--dark)", cursor: "pointer" }} />
                                    }
                                    size='small'
                                    header={t("update_milestone")}
                                    content={
                                        <MilestoneForm
                                            milestoneItem={milestone}
                                            onConfirm={(response) => onUpdate(response)}
                                        />
                                    }
                                />
                            </FlexItem>
                            <FlexItem textAlign="center">
                                <ConfrimModal
                                    onConfirm={() => onDelete(milestone.id)}
                                    description={t("delete_milestone")}
                                    button={
                                        <Icon name="remove" style={{ marginRight: 0, color: "var(--danger)", cursor: "pointer" }} />
                                    }
                                />
                            </FlexItem>
                        </FlexRow>
                    </CanView>
                }
            </FlexRow>
        )
    }

    return (
        <Grid fluid>
            <Grid.Row>
                <Grid.Column>
                    <Header as='h2' style={{ marginBottom: 0}}>
                        {t("milestones")}
                        <CanView permissions={["projects.c_manage_labels"]}>
                            <SuperDuperModal
                                size='small'
                                header={t("create_milestone")}
                                trigger={
                                    <Button primary style={{ marginRight: 0, float: "right"}} size="tiny">
                                        <Icon name="plus" style={{ marginRight: 0 }} /> Create milestone
                                    </Button>
                                }
                                content={ <MilestoneForm onConfirm={(response) => { onAdd(response) }} /> }
                            />
                        </CanView>
                    </Header>   
                    <Divider /> 

                        {milestones?.map((milestone, index) =>
                            <MilestoneView 
                                key={milestone.id} 
                                milestone={milestone} 
                                onDelete={onDelete} 
                                onUpdate={onUpdate}
                                onStateChange={onStateChange}
                            />
                        )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default Milestones;