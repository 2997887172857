import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import { useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import moment from 'moment'
// store
import { API } from '@store/config'
import { routes } from '@store/routes'
import { icons } from '@store/icons'
// import { tzDateTime } from '@helpers/dates';
import { requests } from '@helpers/requests'
import { useHasPermissions, useHasEveryPermissions } from '@helpers/hooks'
import { renderShortLongAddress } from '@helpers/functions'
import { thousandsSeparators, removeURLParameter } from '@helpers/functions'
// components
import Icon from '@components/Icon'
import ListView from '@components/ListView'
import CanView from '@components/perms/CanView'
import AvatarList from '@components/AvatarList'
import Attachments from '@components/Attachments'
import LabelsList from '@components/lists/LabelsList'
import BreadcrumbNav from '@components/BreadcrumbNav'
import GenerateDocuments from '@components/GenerateDocuments'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Container, Button, Checkbox, Label, Icon as SemanticIcon, Popup } from 'semantic-ui-react'
// component specific
import FilterForm from './components/FilterForm'
// import LogisticForm from './components/LogisticForm';
import DeliveryDateForm from './components/DeliveryDateForm'
import StoreCommodityForm from './components/StoreCommodityForm'
import LogisticRecordForm from './components/LogisticRecordForm'
import LogisticRecordDetail from './components/LogisticRecordDetail'
import AssignLogisticRecordAsFulfillment from './components/AssignLogisticRecordAsFulfillment'
// order module component
import OrderDetail from '../orders/shared/OrderDetail'
// import Statistics from './components/Statistics'
// import Transshipments from './components/Transshipments'

const Logistics = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dateFormat = useSelector((state) => state.date_format)
    const viewFromRedux = useSelector((state) => state?.module_preferences?.['logistics']?.filters?.type)

    const isParkoInstance = ['localhost', 'www.parkogroup.pulsawork.com', 'parkogroup.pulsawork.com'].includes(
        window.location.hostname
    )
    const hasRequiredPerms = useHasEveryPermissions([
        'orders.c_manage_order',
        'orders.c_manage_catalogue',
        /*'orders.c_manage_order_fulfillment',*/ 'logistics.c_manage_all_transports',
    ])
    const canViewOrder = useHasEveryPermissions(['orders.c_view_order'])

    const order_status_choices = useSelector((state) => state.choices.order_status_choices)
    const statuses = Object.entries(order_status_choices).map((choice, idx) => ({
        key: idx,
        text: choice[1],
        value: choice[0],
    }))

    const canManage = useHasPermissions(['logistics.c_manage_all_transports', 'logistics.c_manage_user_transports'])

    // eslint-disable-next-line
    const today = moment().format('YYYY-MM-DD')
    const accessPerms = ['logistics.c_view_all_transports', 'logistics.c_view_user_transports']

    const [attachmentsOpen, setAttachmentsOpen] = useState(null)
    const [view, setView] = useState(viewFromRedux || 1)

    // status modal change
    const [open, setOpen] = useState(null)
    const [action, setAction] = useState(null)

    const orderingQuery = `&ordering=-estimated_delivery_date&ordering=-reference_number`

    const onStatusChange = async (status, record, filters, setData, setTotal) => {
        const request = await requests.patch(API.LOGISTICS + 'records/' + record.id + '/', {
            status: parseInt(status),
        })
        if (request.status === 200) {
            if (filters.status.includes(status) || filters.status.length === 0) {
                setData((prev) =>
                    prev.filter((item) => {
                        if (item.id === record.id) {
                            item.status = request.response.status
                            item.status_display = request.response.status_display
                        }

                        return item
                    })
                )
            } else {
                setData((prev) => prev.filter((item) => item.id !== record.id))
                setTotal((prev) => prev - 1)
            }

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('record_status_changed'),
                animation: 'pulse',
                time: 2000,
            })
        }
    }

    const determinateStatusColor = (record) => {
        let iconColor = 'var(--dark)'

        if (record?.status === 1) iconColor = 'var(--primary)'
        if (record?.status === 2) iconColor = 'var(--info)'
        if (record?.status === 3) iconColor = 'grey'
        if (record?.status === 4) iconColor = 'green'
        if (record?.status === 5) iconColor = 'var(--warning)'
        if (record?.status === 6) iconColor = 'var(--danger)'

        return iconColor
    }

    return (
        <CanView permissions={accessPerms} redirect>
            <BreadcrumbNav items={[{ name: t('logistics'), icon: icons.LOGISTICS, href: '' }]} />

            <Container fluid>
                <Button.Group basic size="small" style={{ borderRadius: '0' }}>
                    <Button
                        active={view === 1}
                        onClick={() => {
                            history.push(routes.LOGISTICS)
                            setView(1)
                        }}
                    >
                        <Icon name={icons.LOGISTICS} style={{ marginRight: '0.5rem' }} />
                        <span style={{ position: 'relative', top: '-0.1rem' }}>{t('buy')}</span>
                    </Button>
                    <Button
                        active={view === 2}
                        onClick={() => {
                            history.push(routes.LOGISTICS)
                            setView(2)
                        }}
                    >
                        <Icon name={icons.LOGISTICS} style={{ marginRight: '0.5rem' }} />
                        <span style={{ position: 'relative', top: '-0.1rem' }}>{t('sell')}</span>
                    </Button>
                </Button.Group>
            </Container>

            {view !== 0 && view !== 3 && (
                <>
                    <ListView
                        as="table"
                        key={view}
                        allowSearch
                        allowSelection
                        // module="logistics"
                        // showStatistic
                        isSoftDeleteAllowed
                        endpoint={API.LOGISTICS + 'records/'}
                        actionsCellWidth="2"
                        initialFilters={{
                            type: view,
                            labels: [],
                            assigned_to: [],
                            account: '',
                            order: '',
                            commodity: '',
                            carrier: '',
                            transport_info: '',
                            group: '',
                            type_of_wagon: '',
                            type_of_transport: '',
                            is_archived: false,
                            is_fragile: '',
                            status: [],
                            ordering: ['-estimated_delivery_date', '-reference_number'],
                            date_from: '',
                            date_to: '',
                            has_stocking_history: '',
                            has_fulfillment_history: '',
                        }}
                        enforceFilters={{
                            type: view,
                        }}
                        customActionRenderer={(filters, setFilters, fetchData, params) => (
                            <>
                                <Checkbox
                                    checked={filters.date_from === today && filters.date_to === today}
                                    onClick={async () => {
                                        if (filters.date_from === today && filters.date_to === today) {
                                            let customParams = removeURLParameter(params, 'date_from')
                                            customParams = removeURLParameter(customParams, 'date_to')
                                            await fetchData('', true, customParams + orderingQuery, {
                                                date_from: '',
                                                date_to: '',
                                            })
                                        } else {
                                            let dates = `&date_from=${today}&date_to=${today}`
                                            let customParams = removeURLParameter(params, 'date_from')
                                            customParams = removeURLParameter(customParams, 'date_to')
                                            await fetchData('', true, customParams + dates + orderingQuery, {
                                                date_from: today,
                                                date_to: today,
                                            })
                                        }
                                    }}
                                    style={{ marginLeft: '0.5rem' }}
                                    label={t('show_todays_delivery')}
                                />
                                <Checkbox
                                    checked={JSON.stringify(filters.status) === JSON.stringify(['1', '2', '3'])}
                                    onClick={async () => {
                                        if (JSON.stringify(filters.status) === JSON.stringify(['1', '2', '3'])) {
                                            let customParams = removeURLParameter(params, 'status')
                                            await fetchData('', true, customParams + orderingQuery, { status: [] })
                                        } else {
                                            let customParams = removeURLParameter(params, 'status')
                                            let statuses = `&status=1&status=2&status=3`
                                            await fetchData('', true, customParams + statuses + orderingQuery, {
                                                status: ['1', '2', '3'],
                                            })
                                        }
                                    }}
                                    style={{ marginLeft: '1rem' }}
                                    label={t('show_only_not_delivered')}
                                />
                            </>
                        )}
                        renderFilterFields={(filters, setFilters) => (
                            <FilterForm
                                filters={filters}
                                setFilters={setFilters}
                                view={view}
                            />
                        )}
                        bulkActions={(selected) => [
                            {
                                as: 'modal',
                                name: t('generate_documents'),
                                icon: 'document-text-outline',
                                modal: <GenerateDocuments source="logistics.LogisticRecord" selected={selected} />,
                            },
                        ]}
                        imports={{
                            as: 'link',
                            canView: isParkoInstance && hasRequiredPerms,
                            route: routes.LOGISTICS + 'bulk-imports/',
                        }}
                        exports={[
                            {
                                endpoint: API.EXPORTS + 'dynamic_logistic_records/',
                                permissions: ['logistics.c_view_all_transports', 'logistics.c_view_user_transports'],
                                apply_filters: true,
                                type: 'xlsx',
                                filename: 'logistic_records',
                            },
                        ]}
                        // renderStatisticView={(
                        //     filters,
                        //     data,
                        //     total,
                        //     queryParams,
                        //     setFilters,
                        //     setData,
                        //     setTotal,
                        //     fetchData,
                        //     visibleStats
                        // ) => <Statistics filters={filters} visibleStats={visibleStats} />}
                        actions={[
                            {
                                as: 'modal',
                                type: 'add',
                                modalSize: 'tiny',
                                modalCentered: false,
                                name: t('create_logistic_records'),
                                modal: <LogisticRecordForm type={view}/>,
                                permissions: [
                                    'logistics.c_manage_all_transports',
                                    'logistics.c_manage_user_transports',
                                ],
                            },
                            {
                                as: 'modal',
                                type: 'custom',
                                modalSize: 'large',
                                icon: 'eye-outline',
                                name: t('detail'),
                                customIconColor: 'var(--primary)',
                                modal: (item) => <LogisticRecordDetail record={item} />,
                                permissions: ['logistics.c_view_all_transports', 'logistics.c_view_user_transports'],
                            },
                            {
                                as: 'modal',
                                type: 'custom',
                                modalSize: 'tiny',
                                icon: 'cube-outline',
                                name: t('store_commodity'),
                                customIconColor: 'var(--blue)',
                                disabled: (item) => item.type === 2,
                                modal: <StoreCommodityForm />,
                                matchEveryPermission: true,
                                permissions: [
                                    'assets.c_view_warehouses',
                                    'assets.c_manage_warehouses',
                                    'assets.c_view_items',
                                    'assets.c_manage_items',
                                    'orders.c_view_catalogue',
                                    'assets.c_view_warehouse_processes',
                                    'assets.c_manage_warehouse_processes',
                                ],
                            },
                            {
                                as: 'modal',
                                type: 'edit',
                                modalCentered: false,
                                name: t('edit'),
                                permissions: [
                                    'logistics.c_manage_all_transports',
                                    'logistics.c_manage_user_transports',
                                ],
                                modal: (item, setData, setTotal) => (
                                    <LogisticRecordForm
                                        record={item}
                                        setData={setData}
                                        setTotal={setTotal}
                                        initialOrder={item?.order || undefined}
                                        type={view}
                                    />
                                ),
                            },
                            {
                                name: t('delete'),
                                type: 'delete',
                                as: 'delete',
                                text: t('are_you_sure_that_you_want_to_remove_this_logistic_record'),
                                permissions: ['logistics.c_delete_all_transports'],
                            },
                        ]}
                        tableHeaders={[
                            { title: t('reference') },
                            { title: t('dates') },
                            { title: t('commodity') },
                            // { title: t('order') },
                            { title: view === 1 ? t('supplier') : t('account') },
                            { title: t('weight') + '/' + t('packages') },
                            { title: t('paid_price') },
                            { title: t('transport_information') },
                            { title: t('labels') },
                            { title: t('responsible_persons') },
                            { title: t('attachments') },
                            { title: t('status') },
                        ]}
                        renderCells={(record, setData, setTotal, idx, filters) => [
                            {
                                content: record.reference_number || '--',
                            },
                            {
                                content: (
                                    <>
                                        {t('delivery_date_expected_logistic')} / {t('delivery_date_real_logistic')}:
                                        <br />
                                        <strong style={{ fontSize: '0.95rem' }}>
                                            {moment(record?.estimated_delivery_date).format(dateFormat)}
                                        </strong>
                                        {' / '}
                                        <strong style={{ fontSize: '0.95rem' }}>
                                            {record?.delivery_date
                                                ? moment(record?.delivery_date).format(dateFormat)
                                                : '--'}
                                        </strong>
                                        <br />
                                        {t('loading_date_logistic')}:{' '}
                                        <strong>
                                            {record?.loading_date
                                                ? moment(record?.loading_date).format(dateFormat)
                                                : '--'}
                                        </strong>{' '}
                                        <br />
                                    </>
                                ),
                            },

                            {
                                content: (
                                    <>
                                        <strong style={{ fontSize: '0.95rem' }}>
                                            {record.order_commodity
                                                ? `${
                                                      record.order_commodity?.item?.code
                                                          ? record.order_commodity?.item?.code
                                                          : ''
                                                  } ${record.order_commodity?.item?.title}`
                                                : '--'}
                                        </strong>{' '}
                                        <br />
                                        {t('order_number')}
                                        {': '}
                                        {![true, undefined].includes(record?.order?.is_deleted) ? (
                                            canViewOrder ? (
                                                <SuperDuperModal
                                                    size="large"
                                                    trigger={
                                                        <span className="ref-link" style={{ color: 'var(--primary)' }}>
                                                            {record?.order?.order_number ||
                                                                record?.order?.original_order_number}
                                                        </span>
                                                    }
                                                    content={<OrderDetail id={record?.order?.id} />}
                                                />
                                            ) : (
                                                <span>
                                                    {record?.order?.order_number ||
                                                        record?.order?.original_order_number}
                                                </span>
                                            )
                                        ) : (
                                            <span style={{ color: 'var(--danger)' }}> {t('order_was_deleted')} </span>
                                        )}
                                    </>
                                ),
                            },
                            {
                                content: (
                                    <>
                                        <strong style={{ fontSize: '0.95rem' }}>
                                            <Link
                                                target="_blank"
                                                to={
                                                    (view === 1 ? routes.SUPPLIERS : routes.ACCOUNTS) +
                                                    record?.account?.id
                                                }
                                            >
                                                <span className="ref-link" style={{ color: 'var(--primary)' }}>
                                                    {record?.account?.name}
                                                </span>
                                            </Link>
                                        </strong>
                                        <br />
                                        {record?.carrier?.name ? (
                                            <>
                                                {t('carrier') + ': '}
                                                <Link target="_blank" to={routes.SUPPLIERS + record?.carrier?.id}>
                                                    <span className="ref-link" style={{ color: 'var(--primary)' }}>
                                                        {record?.carrier?.name}
                                                    </span>
                                                </Link>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                ),
                            },

                            {
                                content: (
                                    <>
                                        {record?.weight_total
                                            ? record?.weight_total +
                                              ' ' +
                                              (record.measure_unit ? record.measure_unit?.abbreviation : '')
                                            : '--'}
                                        {' / '}
                                        {record?.package_total || '--'}
                                    </>
                                ),
                            },
                            {
                                content: thousandsSeparators(record.price_paid || 0) + ' ' + record.currency,
                            },
                            {
                                content: (
                                    <>
                                        {record.type_of_transport?.title || '--'}: <br />
                                        {record.transport_info ? (
                                            <strong>
                                                {record.transport_info ? record.transport_info : '--'}
                                                {record.type_of_wagon ? ' / ' + record.type_of_wagon : ''}
                                                {record.group ? ' / ' + record.group : ''}
                                            </strong>
                                        ) : (
                                            ''
                                        )}{' '}
                                        <br />
                                        {t('loading_address_slash_destination_address')}: <br />
                                        <strong>
                                            {renderShortLongAddress(record.loading_address)} /{' '}
                                            {renderShortLongAddress(record.destination_address)}
                                        </strong>
                                    </>
                                ),
                            },
                            // {
                            //     content:
                            //     <>
                            //         { t('transport_type') }: <strong>{ record.type_of_transport?.title || "--" }</strong> <br/>
                            //         { t('trans_number') }: <strong>{ record.transport_info }</strong> <br/>
                            //         { record.type_of_wagon ? <> { t('type') }: <strong>{ record.type_of_wagon }</strong> <br/> </> : "" }
                            //         { record.group ? <> { t('group') } / { t('RWB') }: <strong>{ record.group }</strong> <br/> </> : "" }
                            //         { t('loading_address') }: <strong>{ renderShortLongAddress(record.loading_address) }</strong><br/>
                            //         { t('destination_address') }: <strong>{ renderShortLongAddress(record.destination_address) }</strong><br/>
                            //     </>
                            // },
                            {
                                content: (
                                    <LabelsList
                                        key={record.labels}
                                        rowID={record.id}
                                        label_key="labels"
                                        labels={record.labels}
                                        endpoint={API.LOGISTICS + 'records/'}
                                        endpointLabels={API.LOGISTICS + 'labels/'}
                                    />
                                ),
                            },
                            {
                                content: (
                                    <AvatarList
                                        size="25"
                                        alignDirection="start"
                                        persons={record.assigned_to.map((item) => ({
                                            id: item.id,
                                            name: item.fullname,
                                            avatar: item.profile_picture,
                                            displayName: item?.fullname_with_titles || item.fullname,
                                        }))}
                                    />
                                ),
                            },
                            {
                                content: (
                                    <Label basic>
                                        <Popup
                                            style={{ zIndex: '1000', minWidth: '450px' }}
                                            open={attachmentsOpen === record.id}
                                            onOpen={() => setAttachmentsOpen(record.id)}
                                            hoverable
                                            position="left center"
                                            trigger={
                                                <div style={{ cursor: 'pointer' }}>
                                                    <Icon name="reader-outline" style={{ marginRight: '0.5rem' }} />
                                                    <span style={{ position: 'relative', top: '-0.1rem' }}>
                                                        {record.attachments.length}
                                                    </span>
                                                </div>
                                            }
                                            content={
                                                <Attachments
                                                    viewOnly={!canManage}
                                                    record={record}
                                                    setData={setData}
                                                    sourceEndpoint={API.LOGISTICS + 'records/'}
                                                    closeView={() => setAttachmentsOpen(null)}
                                                />
                                            }
                                        />
                                    </Label>
                                ),
                            },
                            {
                                content: (
                                    <>
                                        {canManage ? (
                                            <Popup
                                                basic
                                                hoverable
                                                position="bottom center"
                                                style={{ padding: 0 }}
                                                open={open === record?.id}
                                                onOpen={() => setOpen(record?.id)}
                                                onClose={() => {
                                                    if (open === record?.id && action === 'edit_status') {
                                                        setOpen(record?.id)
                                                    } else {
                                                        setOpen(null)
                                                        setAction(null)
                                                    }
                                                }}
                                                trigger={
                                                    <div>
                                                        <SemanticIcon
                                                            size="small"
                                                            name="circle"
                                                            style={{
                                                                color: determinateStatusColor(record),
                                                                position: 'relative',
                                                                top: '-0.1rem',
                                                            }}
                                                        />
                                                        {record.status_display}
                                                        <Icon
                                                            name="chevron-down"
                                                            style={{
                                                                marginLeft: '0.4rem',
                                                                position: 'relative',
                                                                top: '0.2rem',
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                content={
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            textAlign: 'left',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        {statuses.map((item) =>
                                                            item.value === '4' ? (
                                                                <SuperDuperModal
                                                                    key={item.key}
                                                                    size="mini"
                                                                    header={t('add_delivery_date')}
                                                                    trigger={
                                                                        <Button
                                                                            basic
                                                                            size="tiny"
                                                                            type="button"
                                                                            onClick={() => setAction('edit_status')}
                                                                            style={{
                                                                                marginRight: 0,
                                                                                textAlign: 'left',
                                                                            }}
                                                                            active={
                                                                                parseInt(item.value) ===
                                                                                parseInt(record.status)
                                                                            }
                                                                            content={
                                                                                <>
                                                                                    <SemanticIcon
                                                                                        size="small"
                                                                                        name="circle"
                                                                                        style={{
                                                                                            color: determinateStatusColor(
                                                                                                {
                                                                                                    status: parseInt(
                                                                                                        item.value
                                                                                                    ),
                                                                                                }
                                                                                            ),
                                                                                            position: 'relative',
                                                                                            top: '-0.1rem',
                                                                                        }}
                                                                                    />
                                                                                    {item.text}
                                                                                </>
                                                                            }
                                                                        />
                                                                    }
                                                                    content={
                                                                        <DeliveryDateForm
                                                                            setAction={setAction}
                                                                            record={record}
                                                                            setData={setData}
                                                                            setTotal={setTotal}
                                                                            filters={filters}
                                                                        />
                                                                    }
                                                                />
                                                            ) : (
                                                                <Button
                                                                    basic
                                                                    size="tiny"
                                                                    type="button"
                                                                    style={{ marginRight: 0 }}
                                                                    active={
                                                                        parseInt(item.value) === parseInt(record.status)
                                                                    }
                                                                    key={item.key}
                                                                    content={
                                                                        <>
                                                                            <SemanticIcon
                                                                                size="small"
                                                                                name="circle"
                                                                                style={{
                                                                                    color: determinateStatusColor({
                                                                                        status: parseInt(item.value),
                                                                                    }),
                                                                                    position: 'relative',
                                                                                    top: '-0.1rem',
                                                                                }}
                                                                            />
                                                                            {item.text}
                                                                        </>
                                                                    }
                                                                    onClick={() =>
                                                                        onStatusChange(
                                                                            item.value,
                                                                            record,
                                                                            filters,
                                                                            setData,
                                                                            setTotal
                                                                        )
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </div>
                                                }
                                            />
                                        ) : (
                                            <Label
                                                style={{
                                                    color: 'var(--light)',
                                                    background: determinateStatusColor(record),
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <span style={{ position: 'relative', top: '0.1rem' }}>
                                                    {record.status_display}
                                                </span>
                                            </Label>
                                        )}

                                        {record.stored_item_history.length > 0 && (
                                            <div style={{ fontWeight: 'bold', color: 'green' }}>
                                                <Icon name="cube-outline" /> {t('stocked')}
                                            </div>
                                        )}

                                        {record.order_commodity ? (
                                            record.order_commodity?.fulfillments?.filter(
                                                (item) => item.logistic_record === record.id
                                            ).length > 0 ? (
                                                <div style={{ fontWeight: 'bold', color: 'var(--blue)' }}>
                                                    <Icon
                                                        style={{ position: 'relative', top: '0.1rem' }}
                                                        name="checkmark-circle-outline"
                                                    />{' '}
                                                    {t('assigned_fulfillment')}
                                                </div>
                                            ) : (
                                                <CanView permissions={['orders.c_manage_order_fulfillment']}>
                                                    <SuperDuperModal
                                                        size="small"
                                                        header={t('assign_as_fulfillment_to_order')}
                                                        trigger={
                                                            <div
                                                                className="ref-link"
                                                                style={{ color: 'var(--primary)', marginTop: '0.5rem' }}
                                                            >
                                                                {t('assign_as_fulfillment_to_order')}
                                                            </div>
                                                        }
                                                        content={
                                                            <AssignLogisticRecordAsFulfillment
                                                                record={record}
                                                                setData={setData}
                                                            />
                                                        }
                                                    />
                                                </CanView>
                                            )
                                        ) : null}
                                    </>
                                ),
                            },
                        ]}
                    />
                </>
            )}
            {/* {view === 3 && <Transshipments />} */}
        </CanView>
    )
}

export default Logistics
