import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { getRequest, createRequest } from '@services/ServiceCommon';
// components
import { Header, Form, Button, Divider, Grid, Card, Icon } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';


const StoreItemForm = ({ setResponse, onClose }) => {
    const storedItemInitValues = {
        id: 0,
        item: null,
        warehouse: null,
        sn: null,
        code: null,
        note: "",
        critical_amount: null,
        quantity: 0
    }

    const [step, setStep] = useState(null)
    const [result, setResult] = useState([])
    const [storedItem, setStoredItem] = useState(storedItemInitValues)

    // verify warehouse-item component
    const VerifyWarehouseItem = () => {
        const { t } = useTranslation();
        const [warehouse, setWarehouse] = useState(null)
        const [item, setItem] = useState(null)
        const [isProcessing, setIsProcessing] = useState(false)


        const handleVerify = async () => {
            setIsProcessing(true)
            const params = "?item=" + item + "&warehouse="+warehouse
            const request = await getRequest(API.ASSET_STORED_ITEMS + params)

            if( request.status === 200 ){
                setStoredItem({
                    ...storedItem,
                    item: item,
                    warehouse: warehouse
                })

                setResult(request.response)
                setStep(1)
            }
            setIsProcessing(false)
        }

        return (
            <>
                <Header as="h3" content={ t('select_warehouse_and_item_to_store') } textAlign="center"/>
                <Divider/>
                <Form onSubmit={handleVerify}>
                    <Form.Group widths="equal">
                        <SuperField as="choice"
                            size="small"
                            search
                            label={t("item")}
                            endpoint={API.ASSET_ITEMS + "?query={id, title}"}
                            text="title"
                            value={item}
                            onChange={(e, { value }) => setItem(value)}
                        />
                        <SuperField as="choice"
                            size="small"
                            search
                            label={t("warehouse")}
                            endpoint={API.ASSET_WAREHOUSES + "?query={id, title}"}
                            text="title"
                            value={warehouse}
                            onChange={(e, { value }) => setWarehouse(value)}
                        />
                    </Form.Group>
                    <Form.Field>
                        <Button 
                            fluid
                            primary
                            loading={isProcessing}
                            disabled={item === null || warehouse === null || isProcessing}
                        >
                            {t('verify')}
                        </Button>
                    </Form.Field>
                </Form>
            </>
        )
    }

    const ChooseItemOrCreate = () => {
        const { t } = useTranslation();

        const isUnique = (item) => {
            return item.sn !== "" && item.sn !== null
        }

        return (
            <>
                <Header as="h3" content={t('available_items')} textAlign="center"/>
                <Divider/>
                <Grid>
                    <Grid.Row columns={1} style={{ padding: 0 }}>
                        { result.length === 0 
                            ?
                            <Grid.Column>
                                <Header as="h4" textAlign="center">
                                    {t('item_not_present_on_this_warehouse')}<br/> {t('would_you_like_to_store_current_item')} <br/>
                                    <Divider/>
                                    <Button.Group fluid size="small">
                                        <Button 
                                            primary 
                                            content={t('yes')}
                                            onClick={ () => setStep(2) }
                                        />
                                        <Button 
                                            style={{ backgroundColor: "var(--variant5)" }}
                                            content={t('no')}
                                            onClick={ () => {
                                                setStep(null)
                                                let initValues = storedItem
                                                setStoredItem({
                                                    ...storedItemInitValues,
                                                    warehouse: initValues.warehouse,
                                                    item: initValues.item,
                                                })
                                                setResult([])
                                            }}
                                        />
                                    </Button.Group>
                                </Header>
                            </Grid.Column>
                            :
                            <Grid.Column style={{ marginBottom: "1rem" }}>
                                <Button 
                                    fluid 
                                    primary 
                                    content={t('store_new_item')}
                                    onClick={ () => setStep(2) }
                                />
                            </Grid.Column>
                        }


                        { result.map(item => (
                            <Grid.Column key={item.id} 
                                className="toggle-button"
                                style={{ 
                                    marginBottom: "0.5rem", 
                                    cursor: !isUnique(item) ? "pointer" : "not-allowed", 
                                    opacity: !isUnique(item) ? "1" : "0.5" 
                                }}
                                onClick={ !isUnique(item) ? () => {
                                    setStoredItem({
                                        ...storedItem,
                                        id: item.id,
                                        sn: item.sn,
                                        quantity: item.quantity
                                    })
                                    setStep(2)
                                } : null}
                            >
                                <Card fluid style={{ borderRadius: 0, background: item.id === storedItem.id ? "var(--light)" : "none" }}>
                                    <Card.Content as="h2">
                                        <FlexRow padding="0" background="transparent" fontSite="1rem">
                                            <FlexItem>
                                                <strong>{ item.item.title } </strong>
                                                { item?.sn ? <small><br/>SN: {item.sn} | { t('unique') }</small> : "" }
                                                { item.code !== null && <small> <br/> {t('code')}: {item.code}</small> }
                                            </FlexItem>
                                            <FlexItem textAlign="right" content={<><small>{ t('quantity') }:</small><br/> <strong>{item.quantity}</strong></>}/>
                                        </FlexRow>
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                        )) }
                    </Grid.Row>
                </Grid>
            </>
        )
    }

    const StoredItemModify = () => {
        const { t } = useTranslation();

        const [alreadyStored, setAlreadyStored] = useState(false)
        const [storedItemToModify, setStoredItemToModify] = useState({
            ...storedItem,
            quantity: 0
        })

        const [criticalAmount, setCriticalAmount] = useState(0)
        // const [reason, setReason] = useState("Stock increase")

        const [isProcessing, setIsProcessing] = useState(false)
        const handleSubmit = async () => {
            setAlreadyStored(false)
            setIsProcessing(true)
            let request = undefined;
            if( storedItem.id > 0 ){ // correction - to increase stock
                request = await createRequest(API.ASSET_CORRECTIONS, {
                    stored_item: storedItem.id,
                    reason: t('stock_increase'),
                    quantity: parseInt(storedItemToModify.quantity)
                })
            } else { // create
                request = await createRequest(API.ASSET_STORED_ITEMS, {
                    ...storedItemToModify,
                    critical_amount: criticalAmount
                })
            }

            if( request !== undefined ){
                if( request.status === 201 && storedItem.id === 0 ){ // onCreate
                    setResponse({
                        type: "stored_create",
                        result: request.response
                    })
                    onClose()
                }
                
                if( request.status === 201 && storedItem.id !== 0 ){
                    setResponse({
                        type: "stored_update",
                        result: {
                            id: storedItem.id,
                            sn: storedItemToModify.sn,
                            quantity: request.response.stored_item.quantity
                        }
                    })
                    onClose()
                }

                if( request.status === 409 ){ // conflict - already stored in this warehouse  
                    setAlreadyStored(true)
                }

            }

            setIsProcessing(false)
        }

        return (
            <Form onSubmit={handleSubmit}>
                <Header as="h3" content={ storedItem.id !== 0 ? t('stock_increase') : t('store_item') } textAlign="center"/>
                <Divider/>
                { (storedItem.id === 0) && 
                    <>
                        <Form.Group widths="equal">
                            <SuperField as="input"
                                style={{ marginBottom: 0 }}
                                label={t('serial_number')}
                                value={storedItemToModify.sn}
                                help={t('hint_unique_items')}
                                onChange={(e, { value }) => {
                                    if( value !== "" ){
                                        setStoredItemToModify({ ...storedItemToModify, sn: value, quantity: 1 })
                                    } else {
                                        setStoredItemToModify({ ...storedItemToModify, sn: value, quantity: 0 })
                                    }
                                }}
                            />

                            <SuperField as="input"
                                type="number"
                                min="0"
                                label={t("minimum_stock_limit")}
                                help={t('hint_system_will_send_notification_when_limit_is_reached')}
                                value={criticalAmount}
                                disabled={storedItemToModify.sn !== null && storedItemToModify.sn !== ""}
                                error={ ((storedItemToModify.sn !== "" && storedItemToModify.sn !== null) && criticalAmount > 0) ? "Serial number is specified, limit can't be set!" : null }
                                onChange={(e, { value }) => setCriticalAmount(value)}
                            />
                        </Form.Group>
                        <SuperField as="input"
                            label={t("code")}
                            value={storedItemToModify.code}
                            onChange={(e, { value }) => setStoredItemToModify({ ...storedItemToModify, code: value })}
                        />
                        <SuperField as="textarea"
                            label={t("note")}
                            value={storedItemToModify.note}
                            onChange={(e, { value }) => setStoredItemToModify({ ...storedItemToModify, note: value })}
                        />
                    </>
                }

                <SuperField as="input"
                    autoFocus
                    required
                    type="number"
                    min="0"
                    label={t('quantity')}
                    disabled={storedItemToModify.sn !== null && storedItemToModify.sn !== ""}
                    value={storedItemToModify.quantity}
                    onChange={(e, { value }) => setStoredItemToModify({ ...storedItemToModify, quantity: value })}
                />
                <Form.Field>
                    <strong>{t('current_stock')}:</strong> { storedItem.quantity } 
                    { storedItemToModify.quantity > 0 
                        ? <span style={{ color: "var(--success)" }}> {" "}(+{storedItemToModify.quantity}) </span> 
                        : "" 
                    }
                </Form.Field>

                
                <Divider />

                { alreadyStored && 
                    <Form.Field style={{ textAlign: "left", fontWeight: "bold", color: "var(--danger)"}}>
                        <Icon name="warning"/> {t('validation_already_stored_to_this_warehouse')}
                    </Form.Field>
                }
                <Form.Field style={{ textAlign: "right" }}>
                    <ModalCancel onClose={() => {
                        let initValues = storedItem
                        setStoredItem({
                            ...storedItemInitValues,
                            warehouse: initValues.warehouse,
                            item: initValues.item,
                        })
                        setStep(1)
                    }} />
                    <ModalSubmit
                        loading={isProcessing}
                        disabled={isProcessing || storedItemToModify.quantity <= 0 || (storedItemToModify.sn !== "" && storedItemToModify.sn !== null && storedItemToModify.quantity > 1 && criticalAmount > 1)}
                        text={t('save')} />
                </Form.Field>
            </Form>
        )
    }

    return (
        <>
            { step === null && <VerifyWarehouseItem/> }
            { step === 1 && <ChooseItemOrCreate/> }
            { step === 2 && <StoredItemModify/> }
        </>
    );
};

export default StoreItemForm;