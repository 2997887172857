import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import {
    PieChart,
    Pie,
    Sector,
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    Line,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    BarChart,
    LineChart,
} from 'recharts'
import { Grid, Header } from 'semantic-ui-react'

const StatisticView = ({
    bar,
    line,
    pie,
    endpoint,
    params,
    customDataset,
    allowTrendLine,
    valuesCount,
    groupsCount,
    categoriesCount,
    valueWithGroup,
    groupWithValue,
    groupWithCategory,
    valueWithCategory,
    categoryWithValue,
    categoryWithGroup,
    header,
}) => {
    const { t } = useTranslation()

    const [metric, setMetric] = useState('')
    const [valueField, setValueField] = useState('')

    const [barChartData, setBarChartData] = useState([])
    const [pieChartData, setPieChartData] = useState([])
    const [barLineChartData, setBarLineChartData] = useState([])
    const [activeIndex, setActiveIndex] = useState(0)
    const [loading, setLoading] = useState(false)

    const [values, setValues] = useState([])
    const [groups, setGroups] = useState([])
    const [categories, setCategories] = useState([])

    const getColorById = (id) => {
        const letters = '0123456789ABCDEF'
        let color = '#'
        for (let i = 0; i < 6; i++) {
            const charIndex = (id + i * 13) % 16
            color += letters[charIndex]
        }
        return color
    }

    const countValues = (arrayOfObjects) => {
        const valueMap = arrayOfObjects.reduce((acc, obj) => {
            if (acc[obj.value_id]) {
                acc[obj.value_id].value += obj.value
            } else {
                acc[obj.value_id] = { ...obj }
            }
            return acc
        }, {})

        const resultArray = Object.values(valueMap)
        return resultArray
    }

    const countGroups = (arrayOfObjects) => {
        const groupMap = arrayOfObjects.reduce((acc, obj) => {
            if (acc[obj.group_id]) {
                acc[obj.group_id].value += obj.value
            } else {
                acc[obj.group_id] = { ...obj }
            }
            return acc
        }, {})

        const resultArray = Object.values(groupMap)
        return resultArray
    }

    const countCategories = (arrayOfObjects) => {
        const valueMap = arrayOfObjects.reduce((acc, obj) => {
            if (acc[obj.category_id]) {
                acc[obj.category_id].value += obj.value
            } else {
                acc[obj.category_id] = { ...obj }
            }
            return acc
        }, {})

        const resultArray = Object.values(valueMap)
        return resultArray
    }

    const groupByValue = (arrayOfObjects) => {
        let groupsIDS = []
        const grouped = arrayOfObjects.reduce((acc, obj) => {
            const key = obj.value_id

            if (!acc[key]) {
                acc[key] = { value_id: key }
            }

            acc[key][obj.group_id] = obj.value
            groupsIDS = [...groupsIDS, obj.group_id]
            return acc
        }, {})

        const uniqueArray = [...new Set(groupsIDS)]
        setGroups(uniqueArray)

        for (let key in grouped) {
            uniqueArray.forEach((groupID) => {
                if (!(groupID in grouped[key])) {
                    grouped[key][groupID] = 0
                }
            })
        }

        const resultArray = Object.values(grouped)
        return resultArray
    }

    const groupByCategory = (arrayOfObjects) => {
        let groupsIDS = []
        const grouped = arrayOfObjects.reduce((acc, obj) => {
            const key = obj.category_id

            if (!acc[key]) {
                acc[key] = { category_id: key }
            }

            acc[key][obj.group_id] = obj.value
            groupsIDS = [...groupsIDS, obj.group_id]
            return acc
        }, {})

        const uniqueArray = [...new Set(groupsIDS)]
        setGroups(uniqueArray)

        for (let key in grouped) {
            uniqueArray.forEach((groupID) => {
                if (!(groupID in grouped[key])) {
                    grouped[key][groupID] = 0
                }
            })
        }

        const resultArray = Object.values(grouped)
        return resultArray
    }

    const categoryByValue = (arrayOfObjects) => {
        let groupsIDS = []
        const grouped = arrayOfObjects.reduce((acc, obj) => {
            const key = obj.value_id

            if (!acc[key]) {
                acc[key] = { value_id: key }
            }

            acc[key][obj.category_id] = obj.value
            groupsIDS = [...groupsIDS, obj.category_id]
            return acc
        }, {})

        const uniqueArray = [...new Set(groupsIDS)]
        setCategories(uniqueArray)

        for (let key in grouped) {
            uniqueArray.forEach((groupID) => {
                if (!(groupID in grouped[key])) {
                    grouped[key][groupID] = 0
                }
            })
        }

        const resultArray = Object.values(grouped)
        return resultArray
    }

    const valueByGroup = (arrayOfObjects) => {
        let valueIDS = []
        const grouped = arrayOfObjects.reduce((acc, obj) => {
            const key = obj.group_id

            if (!acc[key]) {
                acc[key] = { group_id: key }
            }

            acc[key][obj.value_id] = obj.value
            valueIDS = [...valueIDS, obj.value_id]
            return acc
        }, {})

        const uniqueArray = [...new Set(valueIDS)]
        setValues(uniqueArray)

        for (let key in grouped) {
            uniqueArray.forEach((groupID) => {
                if (!(groupID in grouped[key])) {
                    grouped[key][groupID] = 0
                }
            })
        }

        const resultArray = Object.values(grouped)
        return resultArray
    }

    const valueByCategory = (arrayOfObjects) => {
        let valueIDS = []
        const grouped = arrayOfObjects.reduce((acc, obj) => {
            const key = obj.category_id

            if (!acc[key]) {
                acc[key] = { category_id: key }
            }

            acc[key][obj.value_id] = obj.value
            valueIDS = [...valueIDS, obj.value_id]
            return acc
        }, {})
        const uniqueArray = [...new Set(valueIDS)]
        setValues(uniqueArray)

        for (let key in grouped) {
            uniqueArray.forEach((groupID) => {
                if (!(groupID in grouped[key])) {
                    grouped[key][groupID] = 0
                }
            })
        }

        const resultArray = Object.values(grouped)
        return resultArray
    }

    const categoryByGroup = (arrayOfObjects) => {
        let valueIDS = []
        const grouped = arrayOfObjects.reduce((acc, obj) => {
            const key = obj.group_id

            if (!acc[key]) {
                acc[key] = { group_id: key }
            }

            acc[key][obj.category_id] = obj.value
            valueIDS = [...valueIDS, obj.category_id]
            return acc
        }, {})

        const uniqueArray = [...new Set(valueIDS)]
        setCategories(uniqueArray)

        for (let key in grouped) {
            uniqueArray.forEach((groupID) => {
                if (!(groupID in grouped[key])) {
                    grouped[key][groupID] = 0
                }
            })
        }

        const resultArray = Object.values(grouped)
        return resultArray
    }

    const sumValues = (obj) => {
        const idData = valueWithGroup ? 'value_id' : 'group_id'
        const sum = Object.entries(obj)
            .filter(([key, value]) => key !== idData)
            .reduce((acc, [key, value]) => acc + value, 0)

        return sum
    }

    async function fetchStatistics() {
        setLoading(true)
        const idParam =
            valueWithGroup || valuesCount || valueWithCategory
                ? 'value_id'
                : groupWithValue || groupsCount || groupWithCategory
                ? 'group_id'
                : 'category_id'

        let request = null
        if (endpoint) request = await requests.get(endpoint)

        if (request?.status === 200 || customDataset) {
            setMetric(request?.response[0]?.metric || customDataset[0]?.metric)
            setValueField(request?.response[0]?.value_field || customDataset[0]?.value_field)
            //const result = request.response.slice(1);

            const result = request?.response?.slice(1) || customDataset?.slice(1)

            //let dataCharts = request.response.slice(1)
            let dataCharts = result
            if (valuesCount) dataCharts = countValues(result)
            if (groupsCount) dataCharts = countGroups(result)
            if (categoriesCount) dataCharts = countCategories(result)
            if (valueWithGroup) dataCharts = groupByValue(result)
            if (groupWithValue) dataCharts = valueByGroup(result)
            if (categoryWithValue) dataCharts = valueByCategory(result)
            if (valueWithCategory) dataCharts = categoryByValue(result)
            if (categoryWithGroup) dataCharts = groupByCategory(result)
            if (groupWithCategory) dataCharts = categoryByGroup(result)

            setBarChartData(dataCharts)

            if (valuesCount || groupsCount || categoriesCount) {
                setPieChartData(
                    dataCharts.map((item, index) => ({
                        name: item[idParam],
                        value: item.value,
                        fill: getColorById((item[idParam] * 1234) % 33),
                    }))
                )
            } else {
                setPieChartData(
                    dataCharts.map((item, index) => ({
                        name: item[idParam],
                        value: sumValues(item),
                        //fill: getColorById((item[idParam] * 1234) % 33),
                    }))
                )
            }

            // if (allowTrendLine) {
            //     setBarLineChartData(
            //         dataCharts.map((item, index) => ({
            //             line: item[idParam],
            //             value: sumValues(item),
            //         }))
            //     )
            // }
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchStatistics()
        // eslint-disable-next-line
    }, [])

    const calcPercent = (value, total) => {
        let percentage = 0
        if (value > 0) {
            percentage = parseFloat((value / total) * 100).toFixed(2)
        } else {
            percentage = 0
        }
        return percentage
    }

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } =
            props
        const sin = Math.sin(-RADIAN * midAngle)
        const cos = Math.cos(-RADIAN * midAngle)
        const sx = cx + (outerRadius + 10) * cos
        const sy = cy + (outerRadius + 10) * sin
        const mx = cx + (outerRadius + 30) * cos
        const my = cy + (outerRadius + 30) * sin
        const ex = mx + (cos >= 0 ? 1 : -1) * 22
        const ey = my
        const textAnchor = cos >= 0 ? 'start' : 'end'

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {`(${(percent * 100).toFixed(2)}%)`}
                </text>
            </g>
        )
    }

    const onPieEnter = (_, index) => {
        setActiveIndex(index)
    }

    const onColumnClick = (_, index) => {
        const selected = _?.activeLabel
        if (selected === undefined) return

        const idData =
            valueWithGroup || valueWithCategory
                ? 'value_id'
                : groupWithCategory || groupWithValue
                ? 'group_id'
                : 'category_id'
        const barData = barChartData.find((item) => item[idData] === selected)

        if (barData) {
            const keys = Object.keys(barData).filter((key) => key !== idData)
            const mappedData = keys.map((key) => ({
                name: key,
                value: barData[key],
                fill: getColorById((key * 1234) % 33),
            }))
            setPieChartData(mappedData)
        }
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div
                    style={{
                        padding: '1rem',
                        background: 'var(--white)',
                        border: '0.5px solid var(--dark)',
                        textAlign: 'left',
                    }}
                >
                    <p style={{ fontWeight: 'bold', paddingBottom: 0 }}>
                        {payload[0].payload.name} {payload[0].payload.year}
                    </p>
                    {payload.map((item, idx) => (
                        <span key={idx}>
                            <p
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'left',
                                    justifyContent: 'left center',
                                    textAlign: 'left',
                                }}
                            >
                                <div
                                    style={{
                                        width: '10px',
                                        height: '10px',
                                        background: item?.stroke || item?.fill,
                                        marginRight: '0.25rem',
                                    }}
                                ></div>
                                <div style={{ position: 'relative', top: '-0.35rem' }}>
                                    <span>{item.name}</span>:{' '}
                                    <span>
                                        <strong>{item.value}</strong> (
                                        {calcPercent(item.value, item.payload.issued_all)}%)
                                    </span>
                                </div>
                            </p>
                        </span>
                    ))}
                </div>
            )
        }

        return null
    }

    const CustomLegend = ({ payload }) => {
        return (
            <div
                style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {payload.map((item, index) => (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'left center',
                            marginRight: '0.5rem',
                        }}
                    >
                        <div
                            style={{ width: '10px', height: '10px', background: item.color, marginRight: '0.25rem' }}
                        ></div>
                        <div style={{ position: 'relative', top: '-0.35rem' }}>{item.value}</div>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <>
            {header && <Header as="h3" content={header} />}
            <Grid stackable>
                <Grid.Row columns="2" verticalAlign="middle" style={{ padding: 0 }}>
                    {line && (
                        <Grid.Column>
                            <ResponsiveContainer width="100%" height={400}>
                                <LineChart
                                    width={500}
                                    height={300}
                                    data={barChartData}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey={
                                            groupsCount || groupWithValue || groupWithCategory
                                                ? 'group_id'
                                                : valuesCount || valueWithGroup || valueWithCategory
                                                ? 'value_id'
                                                : 'category_id'
                                        }
                                    />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    {!valueWithGroup &&
                                        !groupWithValue &&
                                        !valueWithCategory &&
                                        !categoryWithValue &&
                                        !groupWithCategory &&
                                        !categoryWithGroup && (
                                            <Line dataKey={'value'} type="monotone" stroke={'var(--primary)'} />
                                        )}
                                    {valueWithGroup &&
                                        groups?.map((item, idx) => (
                                            <Line
                                                dataKey={item}
                                                type="monotone"
                                                stroke={getColorById((item * 1234) % 33)}
                                            />
                                        ))}
                                    {groupWithValue &&
                                        values?.map((item, idx) => (
                                            <Line
                                                dataKey={item}
                                                type="monotone"
                                                stroke={getColorById((item * 1234) % 33)}
                                            />
                                        ))}
                                    {valueWithCategory &&
                                        categories?.map((item, idx) => (
                                            <Line
                                                dataKey={item}
                                                type="monotone"
                                                stroke={getColorById((item * 1234) % 33)}
                                            />
                                        ))}
                                    {categoryWithValue &&
                                        values?.map((item, idx) => (
                                            <Line
                                                dataKey={item}
                                                type="monotone"
                                                stroke={getColorById((item * 1234) % 33)}
                                            />
                                        ))}

                                    {groupWithCategory &&
                                        categories?.map((item, idx) => (
                                            <Line
                                                dataKey={item}
                                                type="monotone"
                                                stroke={getColorById((item * 1234) % 33)}
                                            />
                                        ))}
                                    {categoryWithGroup &&
                                        groups?.map((item, idx) => (
                                            <Line
                                                dataKey={item}
                                                type="monotone"
                                                stroke={getColorById((item * 1234) % 33)}
                                            />
                                        ))}
                                </LineChart>
                            </ResponsiveContainer>
                        </Grid.Column>
                    )}
                    {bar && (
                        <Grid.Column>
                            <ResponsiveContainer width="100%" height={400}>
                                <ComposedChart
                                    data={barChartData}
                                    onClick={onColumnClick}
                                    margin={{
                                        top: 10,
                                        right: 0,
                                        left: 0,
                                        bottom: 10,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="1 1" />
                                    <XAxis
                                        dataKey={
                                            groupsCount || groupWithValue
                                                ? 'group_id'
                                                : valuesCount || valueWithGroup || valueWithCategory
                                                ? 'value_id'
                                                : 'category_id'
                                        }
                                    />
                                    <YAxis />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend content={<CustomLegend />} height={36} />
                                    {!valueWithGroup &&
                                        !groupWithValue &&
                                        !valueWithCategory &&
                                        !categoryWithValue &&
                                        !groupWithCategory &&
                                        !categoryWithGroup && (
                                            <Bar
                                                dataKey={'value'}
                                                name={valueField}
                                                stackId="a"
                                                fill={'var(--primary)'}
                                            />
                                        )}
                                    {valueWithGroup &&
                                        groups?.map((item, idx) => (
                                            <Bar dataKey={item} stackId="a" fill={getColorById((item * 1234) % 33)} />
                                        ))}
                                    {groupWithValue &&
                                        values?.map((item, idx) => (
                                            <Bar dataKey={item} stackId="a" fill={getColorById((item * 1234) % 33)} />
                                        ))}
                                    {valueWithCategory &&
                                        categories?.map((item, idx) => (
                                            <Bar dataKey={item} stackId="a" fill={getColorById((item * 1234) % 33)} />
                                        ))}

                                    {categoryWithValue &&
                                        values?.map((item, idx) => (
                                            <Bar dataKey={item} stackId="a" fill={getColorById((item * 1234) % 33)} />
                                        ))}

                                    {groupWithCategory &&
                                        categories?.map((item, idx) => (
                                            <Bar dataKey={item} stackId="a" fill={getColorById((item * 1234) % 33)} />
                                        ))}
                                    {categoryWithGroup &&
                                        groups?.map((item, idx) => (
                                            <Bar dataKey={item} stackId="a" fill={getColorById((item * 1234) % 33)} />
                                        ))}
                                    {/* {allowTrendLine && <Line type="monotone" dataKey={item} stroke="#ff7300" />} */}
                                </ComposedChart>
                            </ResponsiveContainer>
                        </Grid.Column>
                    )}
                    {pie && (
                        <Grid.Column>
                            <ResponsiveContainer width="100%" height={350}>
                                <PieChart>
                                    <Pie
                                        activeIndex={activeIndex}
                                        activeShape={renderActiveShape}
                                        data={pieChartData}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={100}
                                        fill="#8884d8"
                                        dataKey="value"
                                        onMouseEnter={onPieEnter}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                        </Grid.Column>
                    )}
                </Grid.Row>
            </Grid>
        </>
    )
}

export default StatisticView
