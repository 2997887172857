import { APIRequest, URLManager } from './helpers/requests';
import { API } from "../config";


export const projectsService = {
    getProjects,
    getProjectStats,
    createProject,
    getMilestones,
    getIssues,
    getIssue,
    getProject,
    deleteProject,
    updateProject,
    deleteIssue,
    updateIssue,
    addIssue,

    // ***** Create
    createMilestone,
    createLabel,

    // ***** Delete
    deleteMilestone,
    deleteLabel,

    // ***** Update
    updateMilestone,
    updateLabel,
};


async function getProjects(params) {
    params = params || "";
    return await APIRequest({
        url: API.PROJECTS + params,
        method: 'GET',
        data: {},
        private: true
    });
}

async function getIssue(id, params) {
    params = params || "";
    return await APIRequest({
        url: API.PROJECT_ISSUES + id + "/" + params,
        method: 'GET',
        data: {},
        private: true
    });
}

async function getIssues(params) {
    params = params || "";
    return await APIRequest({
        url: API.PROJECT_ISSUES + params,
        method: 'GET',
        data: {},
        private: true
    });
}

async function updateIssue(data, id) {
    return await APIRequest({
        url: API.PROJECT_ISSUES + id + '/',
        method: 'PATCH',
        data: data,
        private: true
    });
}

async function addIssue(data) {
    return await APIRequest({
        url: API.PROJECT_ISSUES,
        method: 'POST',
        data: data,
        private: true
    });
}

async function deleteIssue(id) {
    return await APIRequest({
        url: API.PROJECT_ISSUES + id + "/",
        method: 'DELETE',
        private: true
    });
}

async function updateLabel(data, id) {
    return await APIRequest({
        url: API.PROJECT_LABELS + id + '/',
        method: 'PATCH',
        data: data,
        private: true
    });
}

async function deleteLabel(id) {
    return await APIRequest({
        url: API.PROJECT_LABELS + id + "/",
        method: 'DELETE',
        private: true
    });
}

async function getProjectStats(id) {
    return await APIRequest({
        url: API.PROJECTS_STATS + id + "/stats/",
        method: 'GET',
        data: {},
        private: true
    });
}

async function createProject(data) {
    return await APIRequest({
        url: API.PROJECTS,
        method: 'POST',
        data: data,
        private: true
    });
}

async function getMilestones(params) {
    params = params || "";
    return await APIRequest({
        url: API.PROJECTS + "milestones/" + params,
        method: 'GET',
        data: {},
        private: true
    });
}

async function getProject(id, params) {
    params = params || ""
    return await APIRequest({
        url: API.PROJECTS + id + "/" + params,
        method: 'GET',
        data: {},
        private: true
    });
}

async function deleteProject(id) {
    return await APIRequest({
        url: API.PROJECTS + id + "/",
        method: 'DELETE',
        private: true
    });
}

async function updateProject(data, id) {
    return await APIRequest({
        url: API.PROJECTS + id + "/",
        method: 'PATCH',
        data: data,
        private: true
    });
}

// ****** Create
async function createMilestone(data) {
    return await APIRequest({
        url: API.PROJECT_MILESTONES,
        method: 'POST',
        data: data,
        private: true
    });
}

async function createLabel(data) {
    return await APIRequest({
        url: API.PROJECT_LABELS,
        method: 'POST',
        data: data,
        private: true
    });
}

// ****** Delete
async function deleteMilestone(id) {
    return await APIRequest({
        url: URLManager.url_with_id(API.PROJECT_MILESTONES, id),
        method: 'DELETE',
        data: {},
        private: true
    });
}


// ****** Update
async function updateMilestone(id, data) {
    return await APIRequest({
        url: URLManager.url_with_id(API.PROJECT_MILESTONES, id),
        method: 'PATCH',
        data: data,
        private: true
    });
}