import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'; 
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
// components
import { Form } from 'semantic-ui-react';
import DatePresets from '@components/DatePresets';
import SuperField from '@components/forms/SuperField';

const FilterForm = ({ filters, reduxFilters, view, setFilters, businessDetails, carriers }) => {
    const { t } = useTranslation()
    const user = useSelector(state => state.user)

    useEffect(() => {
        function fetchFilters (){
            if(reduxFilters) setFilters(reduxFilters)
        }

        fetchFilters()
        // eslint-disable-next-line
    }, [view])

    return (
        <>
            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    size="small"
                    label={t('date_from')}
                    value={filters.date_from}
                    style={{ height: "2.75rem" }}
                    onChange={(e, { value }) => setFilters({ ...filters, date_from: value })}
                />
                <SuperField as="datepicker"
                    size="small"
                    label={t('date_to')}
                    value={filters.date_to}
                    style={{ height: "2.75rem" }}
                    onChange={(e, { value }) => setFilters({ ...filters, date_to: value })}
                />
            </Form.Group>
            <DatePresets
                from={filters.date_from}
                to={filters.date_to}
                onSelection={ (firstMonthDay, lastMonthDay, year) => {
                    setFilters(prev => ({
                        ...prev,
                        date_from: firstMonthDay,
                        date_to: lastMonthDay,
                    }))
                }}
            />

            <Form.Group widths="equal">
                <SuperField as="choice-select"
                    search
                    label={t('order')}
                    value={filters.order}
                    endpoint={API.ORDERS}
                    text={(item) => `${item.order_number} - ${item.name}`}
                    onChange={(e, { value }) => setFilters(prev => ({...prev, order: value}))}
                    additionalFilters={"&only_basic_info=true&is_active=true&resource_type=saleorder" + (view === 1 ? "&is_purchase_order=true" :"&is_purchase_order=false")}
                    
                />  
                <SuperField as="choice-select"
                    search
                    text={(item) => `${item.code ? item.code + ' - ' : ''}${item.title}`}
                    label={t('commodity')}
                    value={filters.commodity}
                    
                    endpoint={API.ORDERS + 'catalogue_items/'}
                    additionalFilters={"&query={id, code, title}"}
                    onChange={(e, { value }) => setFilters(prev => ({...prev, commodity: value}))}
                />  
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField as="choice-select"
                    search
                    text="title"
                    label={t('transport_type')}
                    value={filters.type_of_transport}
                    endpoint={API.LOGISTICS + "transport_types/"}
                    onChange={(e, { value }) => setFilters(prev => ({...prev, type_of_transport: value}))}
                />   
            </Form.Group>   

            <Form.Group widths="equal">
                <SuperField as="choice-select"
                    search
                    label={t('supplier') + "/" + t("account")}
                    value={filters.account}
                    endpoint={API.BUSINESS_DETAIL}
                    text={(item) => `${item.name} - ${item.identification_number}`}
                    additionalFilters={"&query={id, name, identification_number}&filter_by_entities=supplier,account"}
                    onChange={(e, { value }) => setFilters(prev => ({...prev, account: value}))}
                />  

                <SuperField as="choice-select"
                    search
                    label={t('carrier')}
                    value={filters.carrier}
                    //customOptions={carriers}
                    endpoint={API.BUSINESS_DETAIL}
                    text={(item) => `${item.name} - ${item.identification_number}`}
                    additionalFilters={"&query={id, name, identification_number}&filter_by_entities=supplier,employer"}
                    onChange={(e, { value }) => setFilters(prev => ({...prev, carrier: value}))}
                />
            </Form.Group> 

            <SuperField as="input"
                label={t('transport_number')}
                value={filters.transport_info}
                onChange={(e, { value }) => setFilters(prev => ({...prev, transport_info: value}))}
            />
            <Form.Group widths="equal">
                <SuperField as="input"
                    label={t('type_of_wagon_containers')}
                    value={filters.type_of_wagon}
                    onChange={(e, { value }) => setFilters(prev => ({...prev, type_of_wagon: value}))}
                />
                <SuperField as="input"
                    label={t('rwb__group')}
                    value={filters.group}
                    onChange={(e, { value }) => setFilters(prev => ({...prev, group: value}))}
                />
            </Form.Group>

            <SuperField as="choice-select"
                search
                multiple
                endpoint={API.EMPLOYEES}
                text="fullname_with_titles"
                value={filters.assigned_to}
                label={t('responsible_persons')}
                additionalFilters={"&only_basic_info=true&is_active=true&has_user=true"}
                onChange={(e, { value }) => setFilters(prev => ({...prev, assigned_to: value}))}
            />  

            <SuperField as="choice-select"
                search
                multiple
                text="name"
                label={t('labels')}
                value={filters.labels}
                endpoint={API.LOGISTICS + 'labels/'}
                additionalFilters={"&query={id, name}"}
                onChange={(e, { value }) => setFilters(prev => ({...prev, labels: value}))}
            />

            <SuperField as="choice"
                search
                multiple
                label={t('status')}
                value={filters.status}
                type="order_status_choices"
                onChange={(e, { value }) => setFilters(prev => ({...prev, status: value}))}
            />

            <Form.Group widths="equal">
                <SuperField as="choice"
                    label={t('stocking_status')}
                    value={filters.has_stocking_history}
                    customOptions={[
                        { key: 1, value: true, text: t('stocked') }, 
                        { key: 2, value: false, text: t('not_stocked') }, 
                    ]}
                    onChange={(e, { value }) => setFilters(prev => ({...prev, has_stocking_history: value}))}
                />

                <SuperField as="choice"
                    label={t('fulfillment_status')}
                    value={filters.has_fulfillment_history}
                    customOptions={[
                        { key: 1, value: true, text: t('assigned_fulfillment') }, 
                        { key: 2, value: false, text: t('not_assigned_fulfillment') }, 
                    ]}
                    onChange={(e, { value }) => setFilters(prev => ({...prev, has_fulfillment_history: value}))}
                />
            </Form.Group>

            <SuperField as="checkbox"
                label={t('fragile')}
                checked={filters.is_fragile === true}
                onChange={() => setFilters(prev => ({...prev, is_fragile: filters.is_fragile === true ? "" : true}))}
            />

            <SuperField as="checkbox"
                label={t('archived')}
                checked={filters.is_archived === true}
                onChange={() => setFilters(prev => ({...prev, is_archived: !filters.is_archived}))}
            />

            { user?.profile?.id && 
                <SuperField as="checkbox"
                    checked={filters.assigned_to.includes(user.profile?.id)}
                    onChange={async () => {
                        if (!filters.assigned_to.includes(user.profile?.id)) {
                            setFilters(prev => ({
                                ...prev,
                                assigned_to: [user.profile?.id]
                            }))
                        } else {
                            setFilters(prev => ({
                                ...prev,
                                assigned_to: []
                            }))
                        }
                    }}
                    label={t('show_assigned_to_me')}
                />
            }
        </>
    );
};

export default FilterForm;