import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { icons } from '@icons'
// components
import Icon from '@components/Icon'
import CanView from '@components/perms/CanView'
import BreadcrumbNav from '@components/BreadcrumbNav'
import { Container, Button } from 'semantic-ui-react'
// custom
import VehiclesList from './views/VehiclesList'
import FuelCardsList from './views/FuelCardsList'

const Vehicles = () => {
    const { t } = useTranslation()

    const [view, setView] = useState(1)

    return (
        <CanView permissions={['vehicles.c_view_all_vehicles', 'vehicles.c_view_assigned_vehicles']} redirect>
            <BreadcrumbNav
                style={{ marginBottom: 0 }}
                items={[{ name: t('vehicles'), icon: icons.VEHICLES, href: '' }]}
            />
            <Container fluid>
                <Button.Group basic size="small" style={{ borderRadius: '0' }}>
                    <Button
                        active={view === 1}
                        onClick={() => {
                            setView(1)
                        }}
                    >
                        <Icon name={icons.VEHICLES} style={{ marginRight: '0.5rem' }} />
                        <span style={{ position: 'relative', top: '-0.1rem' }}>{t('vehicles')}</span>
                    </Button>
                    <Button
                        active={view === 2}
                        onClick={() => {
                            setView(2)
                        }}
                    >
                        <Icon name={icons.CARDS} style={{ marginRight: '0.5rem' }} />
                        <span style={{ position: 'relative', top: '-0.1rem' }}>{t('fuel_cards')}</span>
                    </Button>
                </Button.Group>
            </Container>

            {view === 1 && <VehiclesList />}
            {view === 2 && <FuelCardsList />}
        </CanView>
    )
}

export default Vehicles
