import React, { useState, useEffect } from 'react'
import { Form } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
// components
import { Divider } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const RejectBlackListForm = ({ type, setData, onPerformAction, selectedCandidates, onClose, header, setHeader }) => {
    const { t } = useTranslation()
    const [processing, setProcessing] = useState(false)
    const [note, setNote] = useState('')

    const handleSubmit = async () => {
        setProcessing(true)
        await onPerformAction(type, note, setData, selectedCandidates)
        onClose()
        setProcessing(false)
    }

    useEffect(() => {
        if (header) {
            setHeader(header)
        }
    }, [header])

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField
                as="textarea"
                autoFocus
                value={note}
                label={t('reason')}
                onChange={(e, { value }) => setNote(value)}
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit text={t('confirm')} loading={processing} disabled={processing} />
            </Form.Field>
        </Form>
    )
}

export default RejectBlackListForm
