import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// store
import { addChoices, addLanguage } from "@actions";
import { languageOptions } from "@services/helpers/settings";
import { userService } from "@services/ServiceUser";
import { commonService } from "@services/ServiceCommon";
// components
import { /*Flag,*/ Header, Dropdown, Button } from 'semantic-ui-react';

const LanguageSelector = () => {
    const { t } = useTranslation()
    const { i18n } = useTranslation();
    let dispatch = useDispatch();
    const language = useSelector((state) => state.language);

    // eslint-disable-next-line
    let currentLanguage = language === "en-us" ? "en" : language

    // eslint-disable-next-line
    const [initLang, setInitLang] = useState(language);

    const changeLanguage = async (value)=> {
        setInitLang(value);
        let language = value;

        if( value === "en" ){
            language = "en-us"
        } else if (value === "cz") {
            language = "cs"
        }

        const request = await userService.setPreference('language__user_language_preferences', language)

        if( request.status === 200 ){
            dispatch(addLanguage(value))
            i18n.changeLanguage(value)

            // fetch new choices based on selected language
            const choices = await commonService.getChoices()
            if( choices.status === 200 ){
                dispatch(addChoices(choices.response))
            } 
        }

        document.getElementById('verify-input').focus()
    }


    return (
        <div style={{ textAlign: "left", marginTop: "2rem", marginLeft: "2rem" }}>
            <Header as="h4" content={t('select_interface_language')} style={{ marginBottom: "0.5rem" }}/>
            <Dropdown
                basic
                as={Button}
                floating
                direction="left"
                options={languageOptions}
                value={initLang}
                onChange={(e, {value}) => changeLanguage(value)}
            />
        </div>
    );
};

/*
<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
    { languageOptions.map(language => (
        <div style={{ cursor: "pointer", textAlign: "center", width: "100px", opacity: currentLanguage === language.value ? "1" : "0.7" }} onClick={() => changeLanguage(language.value)}>
            <Flag name={language.flag} style={{ marginRight: 0 }}/>
            <div style={{ fontWeight: "bold" }}>{ language.text }</div>          
        </div>
    )) }
</div>
*/

export default LanguageSelector;