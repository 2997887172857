import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import { Form, Divider, Header, Segment, Table } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import SpinnerSegment from '@components/SpinnerSegment'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const BankTransfer = ({ onClose, setContracts, contract, config }) => {
    const { t } = useTranslation()
    const profile_id = contract.profile_id

    let transfer = t('bank_transfer')

    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(true)
    const [selectedContract, setSelectedContract] = useState(null)
    const [profileContracts, setProfileContracts] = useState([])

    const [note, setNote] = useState(transfer + " - " + config.month+"/"+config.year)
    const [hours, setHours] = useState({
        bank_hours: contract.bank_hours || 0,
        bank_overtime: contract.bank_overtime || 0,
        bank_saturday: contract.bank_saturday || 0,
        bank_sunday: contract.bank_sunday || 0,
        bank_holiday: contract.bank_holiday || 0,
        bank_night: contract.bank_night || 0,
    })

    useEffect(() => {
        async function fetchContracts() {
            setLoading(true)
            const lastDay = moment(config.year + '-' + config.month + '-01')
                .endOf('month')
                .format('YYYY-MM-DD')

            let currentDay = moment()
            if (currentDay.isAfter(moment(lastDay))) {
                currentDay = moment(lastDay)
            }

            const previousMonth = currentDay.clone().subtract(1, 'months')
            const contract_date_to = currentDay.format('YYYY-MM-DD')
            const contract_date_from = previousMonth.clone().startOf('month').format('YYYY-MM-DD')

            const request = await requests.get(
                API.CONTRACTS +
                    `?only_basic_info=true&employee=${profile_id}&valid_contract_based_on_date_from=${contract_date_from}&valid_contract_based_on_date_to=${contract_date_to}`
            )

            if (request.status === 200) {
                const availableContracts = request?.response?.results?.filter(item => item.id !== contract.id)
                if (availableContracts.length === 1) {
                    setSelectedContract(availableContracts[0])
                }
                setProfileContracts(availableContracts || [])
            }
            setLoading(false)
        }

        fetchContracts()
        // eslint-disable-next-line
    }, [contract])

    const handleSubmit = async () => {
        setProcessing(true)

        // perform transfer from contract to another selected contract
        await createHistory("bank_hours", 1, selectedContract.id) // increase bank_hours from defined hours to selected contract
        await createHistory("bank_hours", 2, contract.id) // decrease bank_hours from defined hours to contract

        await createHistory("bank_overtime", 1, selectedContract.id) // increase bank_overtime from defined hours to selected contract
        await createHistory("bank_overtime", 2, contract.id) // decrease bank_overtime from defined hours to contract

        await createHistory("bank_saturday", 1, selectedContract.id) // increase bank_saturday from defined hours to selected contract
        await createHistory("bank_saturday", 2, contract.id) // decrease bank_saturday from defined hours to contract

        await createHistory("bank_sunday", 1, selectedContract.id) // increase bank_sunday from defined hours to selected contract
        await createHistory("bank_sunday", 2, contract.id) // decrease bank_sunday from defined hours to contract

        await createHistory("bank_holiday", 1, selectedContract.id) // increase bank_holiday from defined hours to selected contract
        await createHistory("bank_holiday", 2, contract.id) // decrease bank_holiday from defined hours to contract

        await createHistory("bank_night", 1, selectedContract.id) // increase bank_night from defined hours to selected contract
        await createHistory("bank_night", 2, contract.id) // decrease bank_night from defined hours to contract

        // fetch current contract state
        const request = await requests.get(API.CONTRACTS + contract.id + "/?only_basic_info=true")
        if (request.status === 200) {
            setContracts(prev => prev.map(item => {
                if (item.id === contract.id) {
                    item.bank_hours = request.response.bank_hours                    
                    item.bank_overtime = request.response.bank_overtime                    
                    item.bank_saturday = request.response.bank_saturday                    
                    item.bank_sunday = request.response.bank_sunday                    
                    item.bank_holiday = request.response.bank_holiday                    
                    item.bank_night = request.response.bank_night                    
                }

                return item
            }))


            toast({
                type: 'success',
                icon: 'check circle',
                title: t('transfer_performed_successfully'),
                animation: 'pulse',
                time: 2000,
            })
            onClose()
        }
        
        setProcessing(false)
    }

    const calculateNewBankState = (bank) => {
        let current = parseFloat(selectedContract[bank])
        let transfer_hours = parseFloat(hours[bank])

        if (transfer_hours > parseFloat(contract[bank])) {
            transfer_hours = 0
        }

        return parseFloat(current) + transfer_hours
    }

    const transformValue = (value, bank) => {
        let inputValue = parseFloat(value) 
        if (isNaN(inputValue)) return 0
        if (inputValue < 0) return 0
        if (inputValue === "") return 0
        if (inputValue > parseFloat(contract[bank])) return parseFloat(contract[bank])

        return value.replace(",", ".")
    }

    const determinateType = (source) => {
        if (source === 'bank_overtime') return 1
        if (source === 'bank_holiday') return 2
        if (source === 'bank_saturday') return 3
        if (source === 'bank_sunday') return 4
        if (source === 'bank_night') return 5
        if (source === 'bank_hours') return 6
    }

    const createHistory = async (bank, operation, contract) => {
        if (parseFloat(hours[bank]) > 0) {
            await requests.post(API.CONTRACTS + 'bank_history/', {
                type: determinateType(bank),
                operation: operation,
                amount: parseFloat(hours[bank]),
                contract: contract,
                note: note
            })
        }
    }

    return (
        <SpinnerSegment loading={loading} loadingMessage={t('searching_for_other_available_contracts')}>
            { !loading &&
                <div>
                    { (selectedContract === null && profileContracts.length === 0) && <p style={{ textAlign: "center", fontWeight: "bold", fontSize: "1.2rem" }}> { t('there_is_no_other_contract_to_transfer') }. </p> }
                    { (selectedContract === null && profileContracts.length > 0) &&
                        <div>
                            <Header as="h3" content={t("select_from_available_contracts")}/>
                            { profileContracts.map(item => (
                                <Segment onClick={() => setSelectedContract(item)} style={{ fontSize: "1.3rem", cursor: "pointer" }}>
                                    { item.custom_id } • { item.employee.fullname } • { item.work_position.title }
                                </Segment>
                            )) }
                        </div>
                    }

                    { selectedContract !== null && 
                        <Form onSubmit={handleSubmit}>
                            <div style={{ marginBottom: "0.5rem" }}>
                                <strong>{t('selected_contract')}:</strong> { selectedContract.custom_id } • { selectedContract.employee.fullname } • { selectedContract.work_position.title }
                            </div>
                            <Table compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{ width: "130px", padding: "0.5rem" }} rowSpan="2">{t('bank_name')}</Table.HeaderCell>
                                        <Table.HeaderCell colSpan="2" style={{ textAlign: "center", padding: "0.5rem", background: "var(--light)" }}>{t('transfer_from')}</Table.HeaderCell>
                                        <Table.HeaderCell colSpan="2" style={{ textAlign: "center", padding: "0.5rem", background: "var(--light-grey)" }}>{t('transfer_to')}</Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.HeaderCell style={{ fontSize: "0.8rem", padding: "0.5rem", textAlign: "center", background: "var(--light)" }}>{t('current_hours')}</Table.HeaderCell>
                                        <Table.HeaderCell style={{ fontSize: "0.8rem", padding: "0.5rem", textAlign: "center", background: "var(--light)" }}>{t('transfer_hours')}</Table.HeaderCell>
                                        <Table.HeaderCell style={{ fontSize: "0.8rem", padding: "0.5rem", textAlign: "center", background: "var(--light-grey)" }}>{t('current_hours')}</Table.HeaderCell>
                                        <Table.HeaderCell style={{ fontSize: "0.8rem", padding: "0.5rem", textAlign: "center", background: "var(--light-grey)" }}>{t('new_hours')}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell style={{ width: "100px", fontWeight: "bold" }}>{t('bank_hours')}</Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light)" }}>{ contract.bank_hours }</Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light)", width: "120px" }}>
                                            <SuperField as="input"
                                                size="small"
                                                disabled={processing}
                                                value={hours.bank_hours}
                                                style={{ width: "100px", textAlign: "center" }}
                                                onChange={(e, {value}) => setHours(prev => ({...prev, bank_hours: transformValue(value, "bank_hours")}))}
                                            />
                                        </Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light-grey)" }}>{ selectedContract.bank_hours }</Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light-grey)", fontWeight: "bold" }}>{calculateNewBankState("bank_hours")}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell style={{ width: "100px", fontWeight: "bold" }}>{t('bank_overtime')}</Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light)" }}>{ contract.bank_overtime }</Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light)", width: "120px" }}>
                                            <SuperField as="input"
                                                size="small"
                                                disabled={processing}
                                                value={hours.bank_overtime}
                                                style={{ width: "100px", textAlign: "center" }}
                                                onChange={(e, {value}) => setHours(prev => ({...prev, bank_overtime: transformValue(value, "bank_overtime")}))}
                                            />
                                        </Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light-grey)" }}>{ selectedContract.bank_overtime }</Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light-grey)", fontWeight: "bold" }}>{calculateNewBankState("bank_overtime")}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell style={{ width: "100px", fontWeight: "bold" }}>{t('bank_saturday')}</Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light)" }}>{ contract.bank_saturday }</Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light)", width: "120px" }}>
                                            <SuperField as="input"
                                                size="small"
                                                disabled={processing}
                                                value={hours.bank_saturday}
                                                style={{ width: "100px", textAlign: "center" }}
                                                onChange={(e, {value}) => setHours(prev => ({...prev, bank_saturday: transformValue(value, "bank_saturday")}))}
                                            />
                                        </Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light-grey)" }}>{ selectedContract.bank_saturday }</Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light-grey)", fontWeight: "bold" }}>{calculateNewBankState("bank_saturday")}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell style={{ width: "100px", fontWeight: "bold" }}>{t('bank_sunday')}</Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light)" }}>{ contract.bank_sunday }</Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light)", width: "120px" }}>
                                            <SuperField as="input"
                                                size="small"
                                                disabled={processing}
                                                value={hours.bank_sunday}
                                                style={{ width: "100px", textAlign: "center" }}
                                                onChange={(e, {value}) => setHours(prev => ({...prev, bank_sunday: transformValue(value, "bank_sunday")}))}
                                            />
                                        </Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light-grey)" }}>{ selectedContract.bank_sunday }</Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light-grey)", fontWeight: "bold" }}>{calculateNewBankState("bank_sunday")}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell style={{ width: "100px", fontWeight: "bold" }}>{t('bank_holiday')}</Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light)" }}>{ contract.bank_holiday }</Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light)", width: "120px" }}>
                                            <SuperField as="input"
                                                size="small"
                                                disabled={processing}
                                                value={hours.bank_holiday}
                                                style={{ width: "100px", textAlign: "center" }}
                                                onChange={(e, {value}) => setHours(prev => ({...prev, bank_holiday: transformValue(value, "bank_holiday")}))}
                                            />
                                        </Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light-grey)" }}>{ selectedContract.bank_holiday }</Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light-grey)", fontWeight: "bold" }}>{calculateNewBankState("bank_holiday")}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell style={{ width: "100px", fontWeight: "bold" }}>{t('bank_night')}</Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light)" }}>{ contract.bank_night }</Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light)", width: "120px" }}>
                                            <SuperField as="input"
                                                size="small"
                                                disabled={processing}
                                                value={hours.bank_night}
                                                style={{ width: "100px", textAlign: "center" }}
                                                onChange={(e, {value}) => setHours(prev => ({...prev, bank_night: transformValue(value, "bank_night")}))}
                                            />
                                        </Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light-grey)" }}>{ selectedContract.bank_night }</Table.Cell>
                                        <Table.Cell style={{ textAlign: "center", background: "var(--light-grey)", fontWeight: "bold" }}>{calculateNewBankState("bank_night")}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>

                            <SuperField as="input"
                                required
                                value={note}
                                label={t('transfer_note')}
                                onChange={(e, {value}) => setNote(value)}
                            />

                            <Divider/>
                            <Form.Field style={{ textAlign: "right" }}>
                                <ModalCancel onClose={onClose} disabled={processing}/>
                                <ModalSubmit loading={processing} disabled={processing || note === ""} text={t('confirm_transfer')}/>
                            </Form.Field>
                        </Form>
                    }
                </div>        
            }
        </SpinnerSegment>
    );
};

export default BankTransfer;