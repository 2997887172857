import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// store
import { routes } from '@store/routes/index';
// components
import Icon from '@components/Icon';
import { Button, Header, Card } from 'semantic-ui-react'
import ConfrimModal from '@components/modals/ConfrimModal'

const Team = (props) => {
    const {t}=useTranslation()
    return (
        <Card style={styles.container} >
            <Card.Content style={{ borderTop: "none" }} >
                <ConfrimModal
                    description={t('delete_this')}
                    onConfirm={() => props.onDelete()}
                    button={
                        <Button floated='right' style={{ backgroundColor: 'transparent', position: 'absolute', top: 10, right: 10 }}>
                            <Icon name="close-outline" style={{ float: "right", color: "var(--danger)", fontSize: "1.5rem" }} />
                        </Button>
                    } />
            </Card.Content>
            <Card.Content style={{ borderTop: "none" }} as={Link} to={routes.TEAMS_DETAIL + props.data.id}>
                <Header as='h2' icon textAlign='center'>
                    <Icon name='people-circle-outline' style={{ fontSize: "5rem" }}/>
                    <Header.Content>{props.data.name}</Header.Content>
                </Header>
            </Card.Content>
        </Card>
    )
}
const styles = {
    container: {
        backgroundColor: "var(--default)",
        border: '2px solid var(--light-grey)',
        textAlign: "right",

        // fontSize: '3rem',
        padding: "1rem",
    },
    content: {
        padding: "1rem"
    },
}

export default Team
