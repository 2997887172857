import React from 'react';
//components
import Icon from '@components/Icon';
import { Divider, Header } from 'semantic-ui-react';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import CanView from '@components/perms/CanView';


const ToggleSection = ({ title, visible, setVisible, addButton, addForm, addButtonPerms, content }) => {

    return (
        <>
            <div
                style={{ marginBottom: "0.5rem",fontWeight: "bold", fontSize: "1.3rem" }}

            >
                <span style={{ cursor: "pointer" }} onClick={ () => setVisible(!visible)}>
                    <Icon name={ !visible ? "chevron-forward-outline" : "chevron-down-outline"} style={{ marginRight: "0.5rem" }}/> 
                    <span fontSize="1.3rem" style={{ position: "relative", top: "-0.2rem" }}> {title} </span>
                </span>
                {/* <span style={{ fontSize: "0.9rem", paddingTop: "0.6rem" }}>
                    <Icon name="tag"/> 0
                </span> */}
                { visible && addButton &&
                <CanView permissions={addButtonPerms}>
                    <SuperDuperModal
                        header={addButton}
                        trigger={
                            <Header style={{ padding: 0, cursor: "pointer", paddingTop: "0.5rem" }} floated="right" as="h4">
                                <Icon name="add-outline" style={{ fontSize: "1rem", marginRight: "0.5rem" }} />
                                <span style={{ position: "relative", top: "-0.2rem" }}> {addButton} </span>
                            </Header>
                        }
                        content={
                            addForm
                        }
                    />
                </CanView>
                }
        </div>
        <Divider/>
        { visible &&
            <div>
                {content}
            </div>
        }

       </>
    )
}
export default ToggleSection;

