import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
import '@store/languages'
// store
import { API } from '@store/config'
import { useHasPermission } from '@helpers/hooks'
import { tzDateTime, setLocaleLanguage } from '@helpers/dates'
// components
import ListView from '@components/ListView'
import CanView from '@components/perms/CanView'
import SuperField from '@components/forms/SuperField'
import { Form } from 'semantic-ui-react'
import DayRecordForm from '../components/DayRecordForm'

const DayOverview = () => {
    const { t } = useTranslation()
    const profile_id = useSelector((state) => state?.user?.profile_id || null)
    const dateFormat = useSelector((state) => state.date_format)

    const canManageOnlyAssignedEmployees = useHasPermission(
        'attendance.c_can_manage_only_employees_as_responsible_person'
    )

    const [day, setDay] = useState(moment().format('YYYY-MM-DD'))
    const currentTime = moment.utc().format('YYYY-MM-DD HH:mm:ss')

    const calculateTimeIRL = (start, end, as) => {
        let time = 0
        as = as || 'hours'
        let start_time = start ? tzDateTime(moment(start).format('YYYY-MM-DD HH:mm'), true) : null
        let end_time = end ? tzDateTime(moment(end).format('YYYY-MM-DD HH:mm'), true) : null

        if (start_time !== null && end_time !== null) {
            // Calculate the difference
            if (as === 'minutes') {
                time = moment.duration(end_time.diff(start_time)).asMinutes()
            } else {
                time = moment.duration(end_time.diff(start_time)).asHours()
            }

            if (time < 0) {
                time = 0
            }
        }

        return as === 'hours' ? time.toFixed(2) : time
    }

    return (
        <CanView permissions={['attendance.c_view_all_records']} redirect>
            <ListView
                as="table"
                allowSearch
                isSoftDeleteAllowed
                endpoint={API.ATTENDANCE}
                actionsCellWidth="2"
                noContainer={true}
                initialFilters={{
                    date_from: day,
                    date_to: day,
                    unit: "",
                    responsible_person: canManageOnlyAssignedEmployees && profile_id ? profile_id : '',
                    ignore_attendance_correction_preference: true,
                }}
                setTableRowBackground={(item) =>
                    item.require_manual_correction || (item.end === null && item.closed) ? '#FF4C7844' : 'transparent'
                }
                listAdditionActions={(data, setData, total, setTotal, response) => [
                    {
                        as: 'filter',
                        index: 0,
                        name: t('all'),
                        filters: {
                            require_manual_correction: '',
                        },
                    },
                    {
                        as: 'filter',
                        index: 1,
                        name: t('require_manual_correction'),
                        filters: {
                            require_manual_correction: true,
                        },
                    },
                ]}
                renderFilterFields={(filters, setFilters) => (
                    <Form.Group widths={'equal'}>
                        <SuperField
                            as="choice-select"
                            search
                            value={filters.unit}
                            label={t('department')}
                            endpoint={API.UNITS}
                            text="name"
                            additionalFilters={`&query={id, name}&only_basic_info=true&only_from_active_company=true${(canManageOnlyAssignedEmployees && profile_id ? '&responsible_persons=' + profile_id : "")}`}
                            onChange={(e, { value }) => {
                                setFilters({
                                    ...filters,
                                    unit: value,
                                })
                            }}
                        />
                        <SuperField
                            as="datepicker"
                            label={t('day')}
                            value={filters.date_from}
                            onChange={(e, { value }) => {
                                setFilters({
                                    ...filters,
                                    date_from: value,
                                    date_to: value,
                                })
                                setDay(value)
                            }}
                        />
                    </Form.Group>
                )}
                actions={[
                    {
                        as: 'modal',
                        type: 'edit',
                        name: t('edit'),
                        modalSize: 'tiny',
                        permissions: ['attendance.c_manage_all_records', 'attendance.c_manage_user_records'],
                        modal: (item, setData) => <DayRecordForm record={item} setData={setData} />,
                    },
                    {
                        name: t('delete'),
                        type: 'delete',
                        as: 'delete',
                        permissions: ['attendance.c_delete_all_records'],
                        text: t('delete'),
                    },
                ]}
                title={
                    <>
                        {moment(day).format(dateFormat)} {' - '}
                        <span style={{ textTransform: 'capitalize' }}>
                            {moment(day).locale(setLocaleLanguage()).format('dddd')}
                        </span>
                    </>
                }
                tableHeaders={[
                    { title: t('employee') },
                    { title: t('department') },
                    { title: t('work_shift') },
                    { title: t('arrival') },
                    { title: t('leave') },
                    { title: t('pause') },
                    { title: t('working_hours') },
                    { title: t('note') },
                ]}
                renderCells={(record) => [
                    {
                        content: (
                            <span>
                                <strong style={{ fontSize: '1rem' }}>
                                    {record?.contract?.employee?.fullname_with_titles}
                                </strong>
                                <br />
                                <small style={{ opacity: '0.8' }}>
                                    {record?.contract?.employee?.custom_prefix_and_id !== ''
                                        ? record?.contract?.employee?.custom_prefix_and_id + ' · '
                                        : ''}
                                    {record?.contract?.work_position?.title}
                                </small>
                                <br />
                                <small>{}</small>
                            </span>
                        ),
                    },
                    { content: record?.employee?.unit?.name || t('unspecified') },
                    { content: record?.shift?.title || t('unspecified') },
                    {
                        content: record?.start && (
                            <>{moment(tzDateTime(record?.start)).format(dateFormat + ' HH:mm')}</>
                        ),
                    },
                    {
                        content: record?.end ? (
                            <>{moment(tzDateTime(record?.end)).format(dateFormat + ' HH:mm')}</>
                        ) : (
                            '--:--'
                        ),
                    },
                    {
                        content: record?.pause && (
                            <span>
                                1. {record?.start_pause ? tzDateTime(record?.start_pause).format('HH:mm') : '--:--'} /{' '}
                                {record?.end_pause ? tzDateTime(record?.end_pause).format('HH:mm') : '--:--'}
                                {record?.second_start_pause === null ||
                                (record?.shift === null && record?.shift?.second_pause_length === null) ? null : (
                                    <>
                                        <br />
                                        2.{' '}
                                        {record?.second_start_pause
                                            ? tzDateTime(record?.second_start_pause).format('HH:mm')
                                            : '--:--'}{' '}
                                        /{' '}
                                        {record?.second_end_pause
                                            ? tzDateTime(record?.second_end_pause).format('HH:mm')
                                            : '--:--'}
                                    </>
                                )}
                                <br />
                            </span>
                        ),
                    },
                    {
                        content: record?.closed ? (
                            <span style={{ fontSize: '1rem' }}>{(record.work_hours || 0) + ' h'}</span>
                        ) : (
                            <span style={{ fontSize: '1rem' }}>
                                {calculateTimeIRL(record?.start, currentTime) + ' h'}
                            </span>
                        ),
                    },
                    { content: record?.note },
                ]}
            />
        </CanView>
    )
}

export default DayOverview
