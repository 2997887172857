import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import { useSelector } from 'react-redux'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { useHasPermission } from '@helpers/hooks'
import { tzDateTime } from '@helpers/dates'
import { thousandsSeparators, setDotSeparator, isValidNumber, isDescendantElement } from '@helpers/functions'
// components
import Icon from '@components/Icon'
import CanView from '@components/perms/CanView'
import EmptyRow from '@components/tables/EmptyRow'
import SuperField from '@components/forms/SuperField'
import SpinnerSegment from '@components/SpinnerSegment'
import NonFieldErrors from '@components/NonFieldErrors'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Form, Divider, Message, Dropdown, Label, Button, Header, Input, Icon as SemanticIcon } from 'semantic-ui-react'
// others
import WarehousePositionForm from '../../../core/warehousing/components/WarehousePositionForm'

const StoreCommodityForm = ({ record, setData, onClose, setSize, setHeader }) => {
    const { t } = useTranslation()

    const dateFormat = useSelector((state) => state.date_format)
    const canManageWarehouses = useHasPermission('assets.c_manage_warehouses')

    const commodity = record.order_commodity
    const [warehouses, setWarehouses] = useState([])
    const [loading, setLoading] = useState(true)
    const [processing, setProcessing] = useState(false)
    const [storedItem, setStoredItem] = useState(null)
    const [form, setForm] = useState({
        warehouse: '',
        item: commodity.item?.id,
        delivery_date: record?.delivery_date || moment().format('YYYY-MM-DD'),
    })

    useEffect(() => {
        async function fetchWarehouses() {
            setLoading(true)
            const request = await requests.get(API.ASSET_WAREHOUSES + '?query={id, title}')
            if (request.status === 200) {
                setWarehouses(request.response)
                if (request.response.length === 1) {
                    setForm((prev) => ({ ...prev, warehouse: request.response[0].id }))
                }
            }

            setHeader(t('store_commodity'))
            setLoading(false)
        }

        fetchWarehouses()
        // eslint-disable-next-line
    }, [])

    const handleSubmit = async () => {
        setProcessing(true)

        // update delivery_date if needed
        if (record?.delivery_date === null) {
            const request = await requests.patch(API.LOGISTICS + 'records/' + record.id + '/', {
                delivery_date: form.delivery_date,
                status: 4,
            })

            if (request.status === 200) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('record_status_changed'),
                    animation: 'pulse',
                    time: 2000,
                })
            }
        }

        // find storedItem or create new one for this warehouse
        const requestItem = await requests.get(
            API.ASSET_STORED_ITEMS + '?catalogue_item=' + form.item + '&warehouse=' + form.warehouse
        )
        if (requestItem.status === 200) {
            if (requestItem.response.length > 0) {
                setStoredItem(requestItem.response[0])
            } else {
                // create storedItem
                const requestItem = await requests.post(API.ASSET_STORED_ITEMS, {
                    catalogue_item: form.item,
                    code: commodity?.item?.code || '',
                    item: null,
                    warehouse: form.warehouse,
                    weight_is_primary_measurement: commodity?.item?.weight_is_primary_measurement || false,
                    quantity: 0,
                    is_active: true,
                })

                if (requestItem.status === 201) {
                    setStoredItem(requestItem.response)
                }
            }
        }

        setProcessing(false)
    }

    const isFormValid = () => {
        if (form.delivery_date === '') return false
        if (form.warehouse === '') return false

        return true
    }

    const handleWarehouseAdd = async (item) => {
        const request = await requests.post(API.ASSET_WAREHOUSES, { title: item })
        if (request.status === 201) {
            setWarehouses((prev) => [request.response, ...prev])
            setForm((prev) => ({ ...prev, warehouse: request.response.id }))

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('new_warehouse_has_been_created'),
                animation: 'pulse',
                time: 2000,
            })
        }
    }

    return (
        <SpinnerSegment loading={loading}>
            {record.stored_item_history.length > 0 && (
                <Message
                    info
                    visible
                    content={
                        <strong>
                            <Icon
                                name="information-circle"
                                style={{
                                    marginRight: '0.5rem',
                                    fontSize: '1.2rem',
                                    position: 'relative',
                                    top: '0.2rem',
                                }}
                            />
                            {t('record_containing_stocking_history')}:
                            <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                {record.stored_item_history.map((item) => (
                                    <li key={item.id}>
                                        {tzDateTime(item.created_on).format(dateFormat + ' HH:mm')} | {t('quantity')}:{' '}
                                        {item.quantity}
                                    </li>
                                ))}
                            </ul>
                        </strong>
                    }
                />
            )}
            {storedItem === null && (
                <Form onSubmit={handleSubmit}>
                    {!record?.delivery_date && (
                        <>
                            <Message
                                info
                                visible
                                content={
                                    <strong>
                                        <Icon
                                            name="information-circle"
                                            style={{
                                                marginRight: '0.5rem',
                                                fontSize: '1.2rem',
                                                position: 'relative',
                                                top: '0.2rem',
                                            }}
                                        />
                                        {t('logistic_record_delivery_date_hint')}
                                    </strong>
                                }
                            />

                            <Divider />
                            <Form.Group widths="equal">
                                <SuperField
                                    as="datepicker"
                                    required
                                    label={t('delivery_date')}
                                    value={form.delivery_date}
                                    help={t('delivery_date_hint_storing_to_warehouse')}
                                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, delivery_date: value }))}
                                />
                            </Form.Group>
                        </>
                    )}

                    <SuperField
                        as="choice"
                        search
                        required
                        label={t('warehouse')}
                        allowAdditions={canManageWarehouses}
                        onAddItem={(e, { value }) => handleWarehouseAdd(value)}
                        help={canManageWarehouses ? t('warehouse_and_hit_enter_to_create_hint') : undefined}
                        value={form.warehouse}
                        customOptions={warehouses.map((item) => ({
                            key: item.id,
                            value: item.id,
                            text: item.title,
                        }))}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, warehouse: value }))}
                    />

                    {form.warehouse !== '' && (
                        <p>
                            <Icon
                                name="information-circle"
                                style={{
                                    marginRight: '0.5rem',
                                    fontSize: '1.2rem',
                                    position: 'relative',
                                    top: '0.2rem',
                                }}
                            />
                            {t('in_the_next_step_we_will_verify_if_there_is_any_available_warehouse_position')}
                        </p>
                    )}

                    <Divider />
                    <Form.Field style={{ textAlign: 'right' }}>
                        <ModalCancel onClose={onClose} />
                        <ModalSubmit
                            content={t('confirm')}
                            loading={processing}
                            disabled={!isFormValid() || processing}
                        />
                    </Form.Field>
                </Form>
            )}

            {storedItem !== null && (
                <StockForm
                    onClose={onClose}
                    record={record}
                    storedItem={storedItem}
                    commodity={commodity}
                    setData={setData}
                    deliveryDate={form.delivery_date}
                />
            )}
        </SpinnerSegment>
    )
}

const StockForm = ({ storedItem, commodity, setData, record, deliveryDate, onClose }) => {
    const { t } = useTranslation()
    const dropdownRef = useRef(null)
    const profile = useSelector((state) => state.user?.profile?.id || null)

    const [errors, setErrors] = useState(null)
    const [focus, setFocus] = useState(false)
    const [showAdditionalInfo, setShowAdditionalInfo] = useState(false)

    const [loading, setLoading] = useState(true)
    const [processing, setProcessing] = useState(false)
    const [search, setSearch] = useState('')
    const [searching, setSearching] = useState(false)
    const [positions, setPositions] = useState([])
    // eslint-disable-next-line
    const [quantityType, setQuantityType] = useState(commodity?.item?.weight_is_primary_measurement === true ? 1 : 0)
    const [allowPartialStore, setAllowPartialStore] = useState(false)
    const [form, setForm] = useState({
        quantity: record?.quantity || 1,
        note: record?.note || '',
        average_weight: record?.average_package_weight || '',
        weight: record?.weight_total || '',
        total_price: record?.price_paid || '',
        unit_price: commodity?.item?.price_without_tax || '',
        currency: record?.currency || 'EUR',
        stored_item: storedItem.id,
        supplier: record?.account?.id || '',
        warehouse_position: '',
        stock_number: record.stock_number || '',
    })

    const selectedPosition = positions.find((item) => item.id === form.warehouse_position)
    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)

        const request = await requests.post(API.ASSETS + 'warehouse_process_operations/', {
            quantity: form.quantity,
            note: form.note,
            operation: 5,
            average_weight: isValidNumber(form.average_weight) ? form.average_weight : null,
            weight: isValidNumber(form.weight) ? form.weight : null,
            total_price: isValidNumber(form.total_price) ? form.total_price : null,
            unit_price: isValidNumber(form.unit_price) ? form.unit_price : null,
            currency: form.currency || 'EUR',
            stored_item: storedItem.id,
            supplier: form.supplier || null,
            warehouse_position: form.warehouse_position || null,
            performed_by: profile,
            logistic_record: record?.id || null,
            weight_is_primary_measurement: quantityType === 1,
            stock_number: form.stock_number,
            delivery_date: deliveryDate || null,
        })

        if (request.status === 201) {
            const requestLogisticRecord = await requests.get(API.LOGISTICS + 'records/' + record.id + '/')
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === record.id && requestLogisticRecord.status === 200) {
                            item = requestLogisticRecord.response
                        }
                        return item
                    })
                )
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('commodity_stored_successfully'),
                    animation: 'pulse',
                    time: 2000,
                })
                onClose()
            }
        }

        setProcessing(false)
    }

    const isFormValid = () => {
        if (!isValidNumber(form.quantity)) return false
        if (!isValidNumber(form.unit_price, true)) return false
        if (!isValidNumber(form.average_weight, true)) return false
        if (!isValidNumber(form.weight, true)) return false
        if (form.stock_number === '') return false

        return true
    }

    const handleGlobalClick = (event) => {
        if (
            dropdownRef.current?.ref?.current !== event.target &&
            !isDescendantElement(dropdownRef.current?.ref?.current, event.target)
        ) {
            setFocus(false)
        }
    }

    async function fetchAvailablePositions(search, forceSearch) {
        let searchFilter = ''
        if (search !== '' || forceSearch) {
            setSearching(true)
            searchFilter += '&search=' + search
        } else {
            setLoading(true)
        }
        const request = await requests.get(
            API.ASSETS +
                'warehouse_available_positions/?stored_item=' +
                storedItem.id +
                '&warehouse=' +
                storedItem.warehouse?.id +
                searchFilter
        )
        if (request.status === 200) {
            setPositions(request.response)
            if (request.response.length === 1) {
                setForm((prev) => ({ ...prev, warehouse_position: request.response[0].id }))
            }
        }

        setLoading(false)
        setSearching(false)
    }

    useEffect(() => {
        // Attach the global click event listener when the component mounts
        document.addEventListener('mousedown', handleGlobalClick)

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleGlobalClick)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const handleKeyDown = async (event) => {
            if (event.key === 'Enter') {
                await fetchAvailablePositions(search, true)
            }
        }

        document.addEventListener('keydown', handleKeyDown)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
        // eslint-disable-next-line
    }, [search])

    useEffect(() => {
        fetchAvailablePositions(search)
        // eslint-disable-next-line
    }, [])

    // eslint-disable-next-line
    const calculateUnitPrice = (quantity, totalPrice) => {
        if (quantity !== 0) {
            return parseFloat(totalPrice / quantity).toFixed(2)
        } else {
            return ''
        }
    }

    useEffect(() => {
        if (quantityType === 1) {
            // set weight
            setForm((prev) => ({
                ...prev,
                unit_price: calculateUnitPrice(form.quantity || 0, form.total_price),
            }))
        } else {
            setForm((prev) => ({
                ...prev,
                unit_price: calculateUnitPrice(form.quantity, form.total_price),
            }))
        }

        // eslint-disable-next-line
    }, [quantityType])

    return (
        <SpinnerSegment loading={loading} loadingMessage={t('loading_available_positions')}>
            {!loading && (
                <Form onSubmit={handleSubmit}>
                    <Message
                        info
                        visible
                        content={
                            <div>
                                <Header as="h4">
                                    <strong>{t('currently_stored_item')}:</strong>
                                </Header>
                                {t('name')}:{' '}
                                <strong>
                                    {storedItem.catalogue_item?.code ? storedItem.catalogue_item?.code + ' - ' : ''}
                                    {storedItem.catalogue_item.title}
                                </strong>
                                <br />
                                {t('warehouse')}: <strong>{storedItem.warehouse.title}</strong>
                                <br />
                                {/* { t('selected_position') }: <strong>{ form.warehouse_position... }</strong><br/> */}
                            </div>
                        }
                    />

                    <NonFieldErrors errors={errors} />
                    <Divider />
                    <Form.Field>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginBottom: '0.2rem',
                            }}
                        >
                            <label style={{ fontWeight: 'bold' }}>
                                {t('select_position_to_store')} <sup style={{ color: 'var(--danger)' }}>*</sup>
                            </label>
                            {form.warehouse_position !== '' && (
                                <div
                                    className="ref-link"
                                    style={{ color: 'var(--primary)' }}
                                    onClick={() => {
                                        setForm((prev) => ({ ...prev, warehouse_position: '' }))
                                        setFocus(false)
                                        setShowAdditionalInfo(false)
                                    }}
                                >
                                    {t('clear_selection')}
                                </div>
                            )}
                        </div>
                        <Dropdown
                            fluid
                            basic
                            scrolling
                            open={focus}
                            onOpen={() => setFocus(true)}
                            ref={dropdownRef}
                            as={Button}
                            type="button"
                            style={{ height: '40px' }}
                            text={
                                selectedPosition
                                    ? selectedPosition.title +
                                      (selectedPosition.section && ' / ' + selectedPosition.section) +
                                      (selectedPosition.row && ' / ' + selectedPosition.row)
                                    : t('select_an_option')
                            }
                        >
                            <Dropdown.Menu style={{ borderRadius: '0' }}>
                                <Input
                                    loading={searching}
                                    size="small"
                                    icon={
                                        <SemanticIcon
                                            link
                                            name="search"
                                            onClick={() => fetchAvailablePositions(search, true)}
                                        />
                                    }
                                    style={{ margin: '1rem', height: '40px' }}
                                    value={search}
                                    placeholder={t('search')}
                                    onChange={(e, { value }) => setSearch(value)}
                                />
                                <CanView permissions={['assets.c_manage_warehouses']}>
                                    <Dropdown.Divider style={{ marginBottom: 0 }} />
                                    <SuperDuperModal
                                        header={t('add_new_warehouse_position')}
                                        trigger={
                                            <Dropdown.Item
                                                onClick={() => setFocus(true)}
                                                style={{ background: 'var(--primary)', color: 'var(--light)' }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Icon name="add-outline" style={{ marginRight: '0.5rem' }} />
                                                    <div> {t('add_new_warehouse_position')} </div>
                                                </div>
                                            </Dropdown.Item>
                                        }
                                        content={
                                            <WarehousePositionForm
                                                warehouse={storedItem?.warehouse}
                                                setPositions={setPositions}
                                                setForm={setForm}
                                            />
                                        }
                                    />
                                    <Dropdown.Divider style={{ marginTop: 0, marginBottom: 0 }} />
                                </CanView>
                                <EmptyRow
                                    length={positions.length}
                                    align="left"
                                    message={t('there_is_no_available_position_to_store')}
                                />
                                {positions.filter((item) => item.is_partially_occupied).length > 0 && (
                                    <>
                                        <Dropdown.Divider />
                                        <Dropdown.Header
                                            style={{ textTransform: 'capitalize', opacity: '0.7' }}
                                            content={
                                                t('partially_occupied_positions') +
                                                ' (' +
                                                positions.filter((item) => item.is_partially_occupied).length +
                                                ') '
                                            }
                                        />
                                        <Dropdown.Divider />
                                    </>
                                )}

                                {positions
                                    .filter((item) => item.is_partially_occupied)
                                    .map((item) => (
                                        <Dropdown.Item
                                            active={form.warehouse_position === item.id}
                                            onClick={() => {
                                                setForm((prev) => ({ ...prev, warehouse_position: item.id }))
                                                setFocus(false)
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div style={{ fontWeight: 'bold' }}>
                                                    {item.title} {item.section && ' / ' + item.section}{' '}
                                                    {item.row && ' / ' + item.row}
                                                </div>
                                                <Label basic style={{ opacity: '0.7' }}>
                                                    {' '}
                                                    {item.occupied_amount || 0} /{' '}
                                                    {item.quantity_limit || t('unlimited')}{' '}
                                                </Label>
                                            </div>
                                        </Dropdown.Item>
                                    ))}

                                {positions.filter((item) => !item.is_partially_occupied).length > 0 && (
                                    <>
                                        <Dropdown.Divider />
                                        <Dropdown.Header
                                            style={{ textTransform: 'capitalize', opacity: '0.7' }}
                                            content={
                                                t('free_positions') +
                                                ' (' +
                                                positions.filter((item) => !item.is_partially_occupied).length +
                                                ') '
                                            }
                                        />
                                        <Dropdown.Divider />
                                    </>
                                )}

                                {positions
                                    .filter((item) => !item.is_partially_occupied)
                                    .map((item) => (
                                        <Dropdown.Item
                                            active={form.warehouse_position === item.id}
                                            onClick={() => {
                                                setForm((prev) => ({ ...prev, warehouse_position: item.id }))
                                                setFocus(false)
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div style={{ fontWeight: 'bold' }}>
                                                    {item.title} {item.section && ' / ' + item.section}{' '}
                                                    {item.row && ' / ' + item.row}
                                                </div>
                                                <Label basic style={{ opacity: '0.7' }}>
                                                    {' '}
                                                    {item.occupied_amount || 0} /{' '}
                                                    {item.quantity_limit || t('unlimited')}{' '}
                                                </Label>
                                            </div>
                                        </Dropdown.Item>
                                    ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Form.Field>
                    {form.warehouse_position !== '' && (
                        <>
                            <SuperField
                                as="input"
                                required
                                label={t('stock_number')}
                                value={form.stock_number}
                                onChange={(e, { value }) => setForm((prev) => ({ ...prev, stock_number: value }))}
                            />
                            <SuperField
                                as="input"
                                key={form.warehouse_position}
                                required={allowPartialStore}
                                autoFocus={allowPartialStore}
                                readOnly={!allowPartialStore}
                                help={!allowPartialStore ? t('value_is_in_read_only_mode') : undefined}
                                label={t('quantity')}
                                value={form.quantity}
                                error={
                                    errors !== null
                                        ? errors?.[0]?.quantity
                                        : !isValidNumber(form.quantity)
                                        ? t('invalid_quantity')
                                        : false
                                }
                                onChange={(e, { value }) =>
                                    setForm((prev) => ({
                                        ...prev,
                                        quantity: setDotSeparator(value),
                                    }))
                                }
                            />
                            <p style={{ marginTop: 0 }}>
                                {t('stock_on_selected_position')}:{' '}
                                <Label basic>
                                    {selectedPosition.occupied_amount || 0}{' '}
                                    <span style={{ opacity: selectedPosition.quantity_limit ? 1 : 0.5 }}>
                                        / {selectedPosition.quantity_limit || t('unlimited')}
                                    </span>
                                </Label>
                            </p>

                            <SuperField
                                as="checkbox"
                                checked={allowPartialStore}
                                label={t('allow_partial_storage')}
                                help={t('allow_partial_storage_hint')}
                                onChange={() => {
                                    if (!allowPartialStore === true) {
                                        setShowAdditionalInfo(true)
                                    }

                                    if (!allowPartialStore === false) {
                                        setForm((prev) => ({
                                            ...prev,
                                            quantity: quantityType === 1 ? record?.weight_total : record?.package_total,
                                        }))
                                    }

                                    setAllowPartialStore(!allowPartialStore)
                                }}
                            />

                            <Message
                                warning
                                visible={allowPartialStore}
                                content={
                                    <strong>
                                        <Icon
                                            name="warning-circle"
                                            style={{
                                                marginRight: '0.5rem',
                                                fontSize: '1.2rem',
                                                position: 'relative',
                                                top: '0.2rem',
                                            }}
                                        />
                                        {t('store_item__calculate_manually_hint')}
                                    </strong>
                                }
                            />
                            <Divider />

                            <Header
                                as="h3"
                                style={{
                                    marginTop: '0.5rem',
                                    marginBottom: '0.5rem',
                                    cursor: 'pointer',
                                    opacity: '0.8',
                                }}
                                onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
                            >
                                <Icon
                                    name={`chevron-${showAdditionalInfo ? 'down' : 'forward'}-outline`}
                                    style={{ marginRight: '0.5rem', position: 'relative', top: '0.2rem' }}
                                />
                                <span>{t('additional_information')}</span>
                            </Header>

                            {showAdditionalInfo && (
                                <>
                                    <SuperField
                                        as="choice"
                                        search
                                        text="name"
                                        value={form.supplier}
                                        label={t('supplier')}
                                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, supplier: value }))}
                                        endpoint={
                                            API.ACCOUNTS +
                                            'business_details/?is_active=true&is_supplier=true&query={id, name}'
                                        }
                                    />

                                    <Form.Group widths="equal">
                                        <SuperField
                                            as="input"
                                            value={form.unit_price}
                                            label={t('unit_price')}
                                            error={
                                                !isValidNumber(form.unit_price) && form.unit_price !== ''
                                                    ? t('not_a_number')
                                                    : false
                                            }
                                            onChange={(e, { value }) =>
                                                setForm((prev) => ({
                                                    ...prev,
                                                    unit_price: setDotSeparator(value),
                                                }))
                                            }
                                        />
                                        <SuperField
                                            as="choice"
                                            type="currency_codes"
                                            value={form.currency}
                                            label={t('currency')}
                                            onChange={(e, { value }) =>
                                                setForm((prev) => ({ ...prev, currency: value }))
                                            }
                                        />
                                    </Form.Group>

                                    {!allowPartialStore ? (
                                        <p>
                                            {t('total_price')}: {thousandsSeparators(form.total_price)} {form.currency}
                                        </p>
                                    ) : (
                                        <SuperField
                                            as="input"
                                            value={form.total_price}
                                            label={t('total_price')}
                                            error={
                                                !isValidNumber(form.total_price) && form.total_price !== ''
                                                    ? t('not_a_number')
                                                    : false
                                            }
                                            onChange={(e, { value }) =>
                                                setForm((prev) => ({
                                                    ...prev,
                                                    total_price: setDotSeparator(value),
                                                }))
                                            }
                                        />
                                    )}

                                    <Form.Group widths="equal">
                                        <SuperField
                                            as="input"
                                            value={form.average_weight}
                                            error={
                                                !isValidNumber(form.average_weight) && form.average_weight !== ''
                                                    ? t('not_a_number')
                                                    : false
                                            }
                                            label={
                                                t('average_weight') +
                                                (storedItem.catalogue_item.measure_unit?.abbreviation
                                                    ? ' ( ' +
                                                      storedItem.catalogue_item.measure_unit?.abbreviation +
                                                      ' )'
                                                    : '')
                                            }
                                            onChange={(e, { value }) =>
                                                setForm((prev) => ({
                                                    ...prev,
                                                    average_weight: setDotSeparator(value),
                                                }))
                                            }
                                        />
                                        <SuperField
                                            as="input"
                                            value={form.weight}
                                            error={
                                                !isValidNumber(form.weight) && form.weight !== ''
                                                    ? t('not_a_number')
                                                    : false
                                            }
                                            label={
                                                t('total_weight') +
                                                (storedItem.catalogue_item.measure_unit?.abbreviation
                                                    ? ' ( ' +
                                                      storedItem.catalogue_item.measure_unit?.abbreviation +
                                                      ' )'
                                                    : '')
                                            }
                                            onChange={(e, { value }) =>
                                                setForm((prev) => ({
                                                    ...prev,
                                                    weight: setDotSeparator(value),
                                                }))
                                            }
                                        />
                                    </Form.Group>

                                    <SuperField
                                        as="textarea"
                                        value={form.note}
                                        label={t('note')}
                                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, note: value }))}
                                    />
                                </>
                            )}
                        </>
                    )}

                    <Divider />
                    <Form.Field style={{ textAlign: 'right' }}>
                        <ModalCancel onClose={onClose} />
                        <Button
                            primary
                            loading={processing}
                            content={t('confirm')}
                            disabled={!isFormValid() && form.quantity !== ''}
                        />
                    </Form.Field>
                </Form>
            )}
        </SpinnerSegment>
    )
}

export default StoreCommodityForm
