import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
//store
import { useHasPermissions } from '@helpers/hooks'
//components
import CanView from '@components/perms/CanView'
import { Tab, Grid } from 'semantic-ui-react'
import BenefitSection from './work-tab/BenefitSection'
import AssetsSection from './work-tab/AssetsSection'
import TimesheetSection from './work-tab/TimesheetSection'
import ContractsSection from './work-tab/ContractsSection'
import CoursesSection from './work-tab/CoursesSection'
import ToggleSection from '@components/general/ToggleSection'
import EmployeeBenefitsForm from './forms/EmployeeBenefitsForm'
import Payrolls from '../../../pages/dashboard/hr/payrolls'

const ProfileCardWorkTab = ({ data, setData, patchEndpoint, isCandidate }) => {
    const { t } = useTranslation()
    const isManageable = useHasPermissions(['common.c_manage_user_employees', 'common.c_manage_all_employees'])
    const [visibleBenefit, setVisibleBenefit] = useState(false)
    const [visibleAssets, setVisibleAssets] = useState(false)
    const [visibleTimesheets, setVisibleTimesheets] = useState(false)
    const [visibleContracts, setVisibleContracts] = useState(false)
    const [visiblePayrolls, setVisiblePayrolls] = useState(false)
    const [visibleCourses, setVisibleCourses] = useState(false)

    return (
        <Tab.Pane style={{ border: 'none', boxShadow: 'none' }}>
            <Grid>
                <Grid.Row>
                    <Grid.Column computer={16} table={16} mobile={16}>
                        <CanView permissions={['contracts.c_view_all_contracts', 'contracts.c_view_user_contracts']}>
                            <ToggleSection
                                title={t('contracts')}
                                visible={visibleContracts}
                                setVisible={setVisibleContracts}
                                content={<ContractsSection employee={data.id} />}
                            />
                        </CanView>
                        <CanView
                            permissions={[
                                'payrolls.c_view_all_payrolls',
                                'payrolls.c_view_user_payrolls',
                                'payrolls.c_view_own_payrolls',
                                'payrolls.c_view_all_advance_payments',
                                'payrolls.c_view_user_advance_payments',
                                'payrolls.c_view_all_bonus_payments',
                                'payrolls.c_view_user_bonus_payments',
                                'payrolls.c_view_all_deduction_payments',
                                'payrolls.c_view_user_deduction_payments',
                            ]}
                        >
                            <ToggleSection
                                title={t('payrolls')}
                                visible={visiblePayrolls}
                                setVisible={setVisiblePayrolls}
                                content={<Payrolls profileID={data.id} />}
                            />
                        </CanView>
                        {isCandidate !== true && (
                            <>
                                <CanView
                                    permissions={['timesheets.c_view_all_records', 'timesheets.c_view_user_records']}
                                >
                                    <ToggleSection
                                        title={t('timesheets')}
                                        visible={visibleTimesheets}
                                        setVisible={setVisibleTimesheets}
                                        content={<TimesheetSection data={data} />}
                                    />
                                </CanView>
                                <CanView
                                    permissions={['benefits.c_view_all_benefits', 'benefits.c_view_user_benefits']}
                                >
                                    <ToggleSection
                                        title={t('benefits')}
                                        visible={visibleBenefit}
                                        setVisible={setVisibleBenefit}
                                        addButton={t('add_benefits')}
                                        addButtonPerms={['benefits.c_manage_all_benefits']}
                                        addForm={
                                            <EmployeeBenefitsForm
                                                excludeBenefits={data?.benefits?.map((item) => item.id)}
                                                employeeID={data.id}
                                                setData={setData}
                                            />
                                        }
                                        content={
                                            <BenefitSection
                                                data={data}
                                                setData={setData}
                                                patchEndpoint={patchEndpoint}
                                                isManageable={isManageable}
                                            />
                                        }
                                    />
                                </CanView>
                                <CanView
                                    permissions={['assets.c_view_all_issued_items', 'assets.c_view_user_issued_items']}
                                >
                                    <ToggleSection
                                        title={t('assets')}
                                        visible={visibleAssets}
                                        setVisible={setVisibleAssets}
                                        content={
                                            <AssetsSection
                                                data={data}
                                                setData={setData}
                                                patchEndpoint={patchEndpoint}
                                                isManageable={isManageable}
                                            />
                                        }
                                    />
                                </CanView>
                                <CanView permissions={['courses.c_view_all_courses', 'courses.c_view_user_courses']}>
                                    <ToggleSection
                                        title={t('courses')}
                                        visible={visibleCourses}
                                        setVisible={setVisibleCourses}
                                        content={<CoursesSection data={data} />}
                                    />
                                </CanView>
                            </>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    )
}

export default ProfileCardWorkTab
