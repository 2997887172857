import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { createRequest, updateRequest } from '@services/ServiceCommon';
// components
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const WarehouseForm = ({ setResponse, onClose, warehouse, setWarehouses  }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [formData, setFormData] = useState({
        title: warehouse?.title ? warehouse.title : "",
        unit: warehouse?.unit ? warehouse.unit?.id : "",
        responsible_person: warehouse?.responsible_person ? warehouse?.responsible_person?.id: null,
        address: null // TODO: implement custom address (ask backend about RestQL support)
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        if( warehouse?.id ){ // update
            const request = await updateRequest(API.ASSET_WAREHOUSES + warehouse.id + "/", formData)

            if( request.status === 200 ){
                setWarehouses(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        results: prev.data.results.filter(filteredWarehouses => {
                            if( filteredWarehouses.id === warehouse.id ){
                                filteredWarehouses.title = request.response.title;
                                filteredWarehouses.unit = request.response.unit;
                                filteredWarehouses.address = request.response.address;
                                filteredWarehouses.responsible_person = request.response.responsible_person;
                            }
                            
                            return filteredWarehouses;
                        })
                    }
                }))
                onClose()
            }
        } else { // create
            const request = await createRequest(API.ASSET_WAREHOUSES, formData)

            if(request.status === 201){
                setResponse({
                    type: "warehouse_create",
                    result: request.response
                })

                onClose()
            }
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="input"
                required
                autoFocus
                label={t('title')}
                value={formData.title}
                onChange={ (e, { value }) => setFormData({
                    ...formData,
                    title: value
                }) }
            />
            <SuperField as="choice"
                search
                required
                label={ t('responsible_person') }
                endpoint={API.EMPLOYEES + "?query={id, fullname}"}
                text="fullname"
                value={formData.responsible_person}
                onChange={ (e, { value }) => setFormData({
                    ...formData,
                    responsible_person: value
                })}
            />

            <SuperField as="choice"
                label={t('unit')}
                search
                endpoint={API.UNITS + "?query={id, name}&only_from_active_company=true"}
                text="name"
                value={formData.unit}
                onChange={ (e, { value }) => setFormData({
                    ...formData,
                    unit: value
                }) }
            />

            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || formData.title === "" || formData.responsible_person === ""}
                    text={t('save')} />
            </Form.Field>
        </Form>
    );
};

export default WarehouseForm;