import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { requests } from '@helpers/requests'
import { API } from '@store/config'
// components
import { Divider, Form } from 'semantic-ui-react'
import SuperLabelField from '@components/forms/SuperLabelField';
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const LabelsBulk = ({ selected, setSelected, setData, onClose }) => {
    const { t } = useTranslation()
    
    const [isProcessing, setIsProcessing] = useState(false)
    const [labels, setLabels] = useState([])

    const onSubmit = async () => {
        setIsProcessing(true)
        let updated = []

        for(let i = 0; i < selected.length; i++){
            const request = await requests.patch(API.FOREIGNERS + "processes/" + selected[i].id + "/", {
                labels: {
                    add: labels,
                },
            })
            if (request.status === 200) {
                updated.push(request.response) 
            }
        }

        setData(prev => prev.filter(item => {
            const itemToUpdate = updated.find(updatedItem => updatedItem.id === item.id)
            if (item.id === itemToUpdate?.id) {
                item.labels = itemToUpdate.labels
            }
            return item
        }))

        onClose()
        setSelected([])
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={onSubmit}>
            <SuperLabelField
                value={labels}
                onChange={(labels) => setLabels(labels)}
                endpoint={API.FOREIGNERS_LABELS}
            />
            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    disabled={isProcessing}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    )
}

export default LabelsBulk