import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import { Form, Divider, Message } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import SpinnerSegment from '@components/SpinnerSegment'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const ImportContractVacationStatus = ({ onClose, fetchData }) => {
    const { t } = useTranslation()
    const [file, setFile] = useState(null)
    const [uploading, setUploading] = useState(false)

    const handleFileFormSubmit = async () => {
        setUploading(true)
        const formData = new FormData()
        formData.append('file', file)
        const request = await requests.post(API.IMPORTS + 'contract_vacation_statuses/', formData)
        if (request.status === 200) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('vacation_statuses_updated'),
                animation: 'bounce',
                time: 3000,
            })
            onClose()
            await fetchData()
        }
        setUploading(false)
    }

    return (
        <SpinnerSegment loading={uploading} loadingMessage={t('uploading_vacation_statuses_please_wait')}>
            <Message info visible>
                {t('export_current_vacation_statuses_using_export_feature_and_then_upload_new_vacation_statuses')}.
                <br />
                <br />
                <strong>{t('important')}:</strong> {t('change_only_column_with_the_name_of')}:{' '}
                <strong>"available_vacation_fond"</strong>. {t('make_sure_to_not_change_value_in_column')}{' '}
                <strong>"contract_id"</strong>
            </Message>
            <Form onSubmit={handleFileFormSubmit}>
                <SuperField
                    as="input"
                    type="file"
                    required
                    label={t('upload_file')}
                    id="fileInput"
                    onChange={(event) => {
                        if (event.target?.files?.length > 0) {
                            setFile(event.target.files[0])
                        }
                    }}
                />

                <Divider />
                <Form.Field style={{ textAlign: 'right' }}>
                    <ModalCancel onClose={onClose} />
                    <ModalSubmit disabled={file === null} />
                </Form.Field>
            </Form>
        </SpinnerSegment>
    )
}

export default ImportContractVacationStatus
