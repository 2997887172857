import React from 'react'
// components
import { Grid } from 'semantic-ui-react';
import TreeGraph from '@components/general/TreeGraph'

const CompanyDetailUnits = ({ unitData, fetchData }) => {

    return ( 
        <Grid centered divided="vertically" style={styles.container}>
            <Grid.Row style={styles.tree}>
                {unitData
                    ? <TreeGraph data={unitData} rerender={fetchData} />
                    : <div style={styles.warning}>Company has no child unit yet.</div>
                }
            </Grid.Row>
        </Grid>
    )
}
const styles = {
    container: { padding: "2rem", paddingTop:"0rem" },
    warning: { fontSize: 28 },
    tree: { justifyContent: "center" }
}

export default CompanyDetailUnits;