import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { isEmpty } from '@services/helpers/validation'
// components
import { Form, Button, Message } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'

function SkillForm({ skill, onConfirm, onClose }) {
    const { t } = useTranslation()

    const [data, setData] = useState({
        name: skill?.name || '',
        seniority_level: skill?.seniority_level || null,
    })

    const [errors, setErrors] = useState([])
    const [isProcessing, setIsProcessing] = useState(false)

    const handleSuccess = (response) => {
        onConfirm(response)
        onClose()
    }

    const handleSubmit = async () => {
        setErrors([])
        setIsProcessing(true)

        let dataset = {
            name: data.name,
            seniority_level: data.seniority_level,
        }

        const request = await requests.post(API.SKILLS, dataset)
        if (request.status === 201) {
            handleSuccess(request.response)
        } else {
            setErrors(request.response)
        }

        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors?.non_field_errors?.length > 0}
                header={t('error_submission_message')}
                list={errors?.non_field_errors}
            />

            <Form.Group widths="equal">
                <SuperField
                    as="input"
                    autoFocus
                    required
                    label={t('name')}
                    placeholder={t('name')}
                    error={errors?.name?.[0] || false}
                    value={data?.name}
                    onChange={(e) =>
                        setData({
                            ...data,
                            name: e.target.value,
                        })
                    }
                />

                <SuperField
                    as="choice"
                    search
                    value={data?.seniority_level}
                    type="seniority_level_types"
                    label={t('seniority_level')}
                    error={errors?.seniority_level?.[0] || false}
                    onChange={(e, { value }) =>
                        setData({
                            ...data,
                            seniority_level: value,
                        })
                    }
                />
            </Form.Group>
            <Form.Field style={{ textAlign: 'right' }}>
                <Button basic type="button" onClick={onClose}>
                    {t('cancel')}
                </Button>
                <Button primary loading={isProcessing} disabled={isProcessing || isEmpty(data?.name)}>
                    {t('confirm')}
                </Button>
            </Form.Field>
        </Form>
    )
}

export default SkillForm
