import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { useHasPermission } from '@helpers/hooks'
// components
import CanView from '@components/perms/CanView'
import Address from '@components/forms/Address'
import SuperField from '@components/forms/SuperField'
import NonFieldErrors from '@components/NonFieldErrors'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import { Form, Divider, Header } from 'semantic-ui-react'

const EmploymentOrderForm = ({ customSubmitHandler, record, setData, setTotal, onClose }) => {
    const { t } = useTranslation()
    const user = useSelector((state) => state.user)
    const canManageCC = useHasPermission('cost_centers.c_manage_cost_centers')

    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [loading, setLoading] = useState(false)
    const [costCenters, setCostCenters] = useState([])
    const [form, setForm] = useState({
        name: record?.name || '',
        fee_per_hour: record?.fee_per_hour || '',
        currency: record?.currency || 'EUR',
        order_number: record?.order_number || '',
        amount: record?.amount || '1',
        business_detail: record?.business_detail?.id || '',
        order_manager: record?.order_manager?.id || '',
        order_type: record?.order_type || 2,
        owner: record?.owner?.id || '',
        order_status: record?.order_status || 1,
        is_active: record?.is_active !== undefined ? record?.is_active : true,
        cost_center: record?.cost_center?.id || '',
        assigned_to: record?.assigned_to?.map((item) => item.id) || [],
    })

    const [workplace, setWorkplace] = useState({
        street: record?.workplace_address?.street || '',
        city: record?.workplace_address?.city || '',
        postcode: record?.workplace_address?.postcode || '',
        country: record?.workplace_address?.country || 'SK',
        address_line: record?.workplace_address?.address_line || '',
        state: record?.workplace_address?.state || '',
        number: record?.workplace_address?.number || '',
        orientation_number: record?.workplace_address?.orientation_number || '',
    })

    const isWorkplaceFilled = () => {
        if (workplace.street === '' || workplace.city === '' || workplace.postcode === '' || workplace.country === '') {
            return false
        }
        return true
    }

    const handleSubmit = async () => {
        setErrors([])
        setProcessing(true)

        let data = {
            name: form.name,
            order_number: form.order_number,
            amount: form.amount || 1,
            order_type: form.order_type,
            resourcetype: 'EmploymentOrder',
            order_manager: form.order_manager !== '' ? form.order_manager : null,
            // project: form.project !== "" ? form.project : null,
            business_detail: form.business_detail !== '' ? form.business_detail : null,
            cost_center: form.cost_center !== '' ? form.cost_center : null,
            is_active: form.is_active,
            order_status: form.order_status,
            owner: form.owner !== '' ? form.owner : null,
            workplace_address: isWorkplaceFilled() ? workplace : null,
            fee_per_hour: form.fee_per_hour !== '' ? form.fee_per_hour : null,
            currency: form.currency,
        }

        if (record?.id === undefined) {
            // create
            if (form.assigned_to.length > 0) data['assigned_to'] = { add: form.assigned_to }
            const request = await requests.post(API.ORDERS, data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                if (customSubmitHandler) {
                    customSubmitHandler(request.response)
                } else {
                    setTotal((prev) => prev + 1)
                    setData((prev) => [request.response, ...prev])
                    onClose()
                }
            }
        } else {
            // its update request
            data['assigned_to'] = { remove: record.assigned_to.map((item) => item.id), add: form.assigned_to }

            const request = await requests.patch(API.ORDERS + record.id + '/', data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                if (customSubmitHandler) {
                    customSubmitHandler(request.response)
                } else {
                    setData((prev) =>
                        prev.map((item) => {
                            if (item.id === record.id) {
                                item = request.response
                            }
                            return item
                        })
                    )
                    onClose()
                }
            }
        }
        setProcessing(false)
    }

    useEffect(() => {
        async function fetchCostCenters() {
            setLoading(true)
            const request = await requests.get(API.COST_CENTERS + '?query={id, title}')
            if (request.status === 200) setCostCenters(request.response)
            setLoading(false)
        }

        fetchCostCenters()
    }, [])

    const handleAddCostCenter = async (value) => {
        setLoading(true)
        const request = await requests.post(API.COST_CENTERS, {
            title: value,
            unit: form.owner?.id || null,
        })

        if (request.status === 201) {
            setForm((prev) => ({ ...prev, cost_center: request.response.id }))
            setCostCenters((prev) => [...prev, request.response])
        }
        setLoading(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            {record !== undefined && (
                <>
                    <Header as="h3" content={t('update_order')} />
                    <Divider />
                </>
            )}

            <NonFieldErrors errors={errors} />

            <Form.Group>
                <SuperField
                    as="input"
                    required
                    autoFocus
                    width="12"
                    label={t('name')}
                    value={form.name}
                    error={errors?.name?.[0] || false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, name: value }))}
                />
                <SuperField
                    as="input"
                    width="4"
                    label={t('order_number')}
                    value={form.order_number}
                    error={errors?.order_number?.[0] || false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, order_number: value }))}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="choice-select"
                    search
                    required
                    text="name"
                    value={form.owner}
                    label={t('supplier')}
                    endpoint={API.UNITS}
                    additionalFilters="&only_from_active_company=true&is_business=true&query={id, name}"
                    error={errors?.owner?.[0] || false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, owner: value }))}
                    initialOptions={{
                        attribute: 'name',
                        source: record?.owner,
                    }}
                />
                <SuperField
                    as="choice-select"
                    search
                    required
                    text="name"
                    label={t('account')}
                    value={form.business_detail}
                    error={errors?.business_detail?.[0] || false}
                    endpoint={API.ACCOUNTS + 'business_details/'}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, business_detail: value }))}
                    additionalFilters={'&query={id, name}&is_account=true&is_active=true&exclude_unit=true'}
                    initialOptions={{
                        attribute: 'name',
                        source: record?.business_detail,
                    }}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="input"
                    required
                    type="number"
                    label={t('fee_per_hour')}
                    value={form.fee_per_hour}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, fee_per_hour: value }))}
                />
                <SuperField
                    as="choice-select"
                    search
                    clearable={false}
                    type="currency_codes"
                    label={t('currency')}
                    value={form.currency}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, currency: value }))}
                />
                <SuperField
                    as="input"
                    type="number"
                    width="5"
                    label={t('target')}
                    value={form.amount}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, amount: value }))}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <CanView permissions={['orders.c_assign_order_manager']}>
                    <Form.Field>
                        <SuperField
                            as="choice-select"
                            search
                            label={t('order_manager')}
                            value={form.order_manager}
                            text="fullname"
                            endpoint={API.EMPLOYEES}
                            additionalFilters="&only_basic_info=true&is_active=true"
                            error={errors?.order_manager?.[0] || false}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, order_manager: value }))}
                            initialOptions={{
                                attribute: 'fullname',
                                source: record?.order_manager,
                            }}
                        />
                        {user.profile?.id && (
                            <SuperField
                                as="checkbox"
                                style={{ marginTop: '1rem' }}
                                label={t('assign_me_as_order_manager')}
                                checked={form.order_manager === user.profile?.id}
                                onChange={() => {
                                    if (form.order_manager !== user.profile?.id) {
                                        setForm((prev) => ({
                                            ...prev,
                                            order_manager: user.profile?.id || '',
                                        }))
                                    } else {
                                        setForm((prev) => ({ ...prev, order_manager: '' }))
                                    }
                                }}
                            />
                        )}
                    </Form.Field>
                </CanView>
                <SuperField
                    as="choice"
                    search
                    loading={loading}
                    label={t('cost_center')}
                    value={form.cost_center}
                    disabled={loading}
                    allowAdditions={canManageCC}
                    onAddItem={(e, { value }) => handleAddCostCenter(value)}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, cost_center: value }))}
                    customOptions={costCenters.map((item) => ({ key: item.id, value: item.id, text: item.title }))}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="choice-select"
                    search
                    multiple
                    label={t('responsible_persons')}
                    value={form.assigned_to}
                    text="fullname"
                    endpoint={API.EMPLOYEES}
                    additionalFilters="&only_basic_info=true&is_active=true"
                    onChange={(e, { value }) => setForm({ ...form, assigned_to: value })}
                    initialOptions={{
                        attribute: 'fullname',
                        source: record?.assigned_to,
                    }}
                />
            </Form.Group>

            <Header as="h3" content={t('workplace')} style={{ marginBottom: '0rem' }} />
            <Divider />
            <Address isForm address={workplace} setAddress={setWorkplace} />

            <Divider />

            <Form.Group widths="equal">
                <SuperField
                    as="choice"
                    label={t('state')}
                    disabled={record?.id === undefined || [undefined, null, ''].includes(record?.confirmed_on)}
                    type="order_status_choices"
                    value={form.order_status?.toString() || '1'}
                    onChange={(e, { value }) => setForm({ ...form, order_status: value })}
                />
                <SuperField
                    as="choice"
                    label={t('status')}
                    value={form.is_active}
                    customOptions={[
                        { key: 0, value: true, text: t('active_order') },
                        { key: 1, value: false, text: t('closed_order') },
                    ]}
                    onChange={(e, { value }) => setForm({ ...form, is_active: value })}
                />
            </Form.Group>

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    text={t('confirm')}
                    loading={processing}
                    disabled={processing || form.name === '' || form.fee_per_hour === '' || form.fee_per_hour < 0}
                />
            </Form.Field>
        </Form>
    )
}

export default EmploymentOrderForm
