import React from 'react';
// components
import { Grid, Header, Icon } from 'semantic-ui-react'

const EmptyList = ({ isEmpty, icon, text, content }) => {
    return (
        <>
            { isEmpty && 
                <Grid centered>
                    { icon && 
                        <Grid.Row>
                            <Icon name={icon} size="huge"/>
                        </Grid.Row>
                    }
                    <Grid.Row style={{ paddingTop: "1rem", paddingBottom: "2rem" }}>
                        <Header as="h2">
                            { text ? text : "List is empty..." }
                        </Header>
                    </Grid.Row>
                    { content && 
                        <Grid.Row>
                            { content }
                        </Grid.Row>
                    }
                </Grid>
            }
        </>
    );
};

export default EmptyList;