import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests, fileDownload } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import { Form, Divider, Header, Segment, Label, Checkbox } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const GenerateDocuments = ({ onClose, selected, source }) => {
    const { t } = useTranslation()
    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(true)
    const [templates, setTemplates] = useState([])
    const [mode, setMode] = useState(1) // single doc per template
    const [paging, setPaging] = useState(false) // allow to generate page numbers
    const [landscape, setLandscape] = useState(false) // allow to generate page orientation as landscape instead of portrait
    const [selectedTags, setSelectedTags] = useState([])
    const [tags, setTags] = useState([])

    async function fetchTemplates() {
        setLoading(true)
        const request = await requests.get(
            API.TEMPLATING + `?source=${source}&query={id, title, labels}&is_archived=false&ordering=title`
        )
        if (request.status === 200) {
            let template_list = request.response.map((item) => ({ checked: false, active: true, ...item }))
            if (template_list.length === 1) {
                template_list[0].checked = true
            }

            let labels = []
            for (let i = 0; i < request.response.length; i++) {
                for (let y = 0; y < request.response[i].labels.length; y++) {
                    if (labels.find((item) => item.id === request.response[i].labels[y].id) === undefined) {
                        labels.push(request.response[i].labels[y])
                    }
                }
            }

            setTags(labels)
            setTemplates(template_list)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (selectedTags.length > 0) {
            setTemplates((prev) =>
                prev.filter((item) => {
                    if (!item.labels.some((arr) => selectedTags.find((tag) => tag.id === arr.id))) {
                        item.active = false
                        item.checked = false
                    } else {
                        item.active = true
                        item.checked = false
                    }
                    return item
                })
            )
        } else {
            setTemplates((prev) =>
                prev.filter((item) => {
                    item.active = true
                    return item
                })
            )
        }
    }, [selectedTags])

    async function generate_merged_pdf(e) {
        setProcessing(true)
        let content = ''
        for (let i = 0; i < templates.length; i++) {
            if (templates[i].checked === true) {
                for (let j = 0; j < selected.length; j++) {
                    const request = await requests.get(
                        API.TEMPLATING + `${templates[i].id}/fill/${source}/${selected[j].id}`
                    )
                    if (request.status === 200) {
                        // if (i === templates.length - 1){
                        //     content += request.response.html
                        // } else {
                        content += request.response.html + '<div><pdf:nextpage /></div>'
                        // }
                    }
                }
            }
        }

        let allowPageNumbers = paging ? '&include_pagination=true' : ''
        let allowLandscape = landscape ? '&allow_landscape=true' : ''
        await fileDownload(
            'POST',
            API.EXPORTS_HTML_TO_PDF + '?q=' + allowPageNumbers + allowLandscape,
            'application/pdf',
            `documents.pdf`,
            {
                content: content,
            }
        )
        setProcessing(false)
        onClose()
    }

    async function generate_template_per_selected_item(e) {
        setProcessing(true)
        for (let i = 0; i < templates.length; i++) {
            if (templates[i].checked === true) {
                for (let j = 0; j < selected.length; j++) {
                    const request = await requests.get(
                        API.TEMPLATING + `${templates[i].id}/fill/${source}/${selected[j].id}`
                    )
                    if (request.status === 200) {
                        let allowPageNumbers = paging ? '&include_pagination=true' : ''
                        let allowLandscape = landscape ? '&allow_landscape=true' : ''
                        await fileDownload(
                            'POST',
                            API.EXPORTS_HTML_TO_PDF + '?q=' + allowPageNumbers + allowLandscape,
                            'application/pdf',
                            `${templates[i].title}-${i + 1}.pdf`,
                            {
                                content: request.response.html,
                            }
                        )
                    }
                }
            }
        }
        setProcessing(false)
        onClose()
    }

    async function generate_merged_pdf_per_selected_item(e) {
        setProcessing(true)
        for (let j = 0; j < selected.length; j++) {
            let content = ''
            for (let i = 0; i < templates.length; i++) {
                if (templates[i].checked === true) {
                    const request = await requests.get(
                        API.TEMPLATING + `${templates[i].id}/fill/${source}/${selected[j].id}`
                    )
                    if (request.status === 200) {
                        if (i === templates.length - 1) {
                            content += request.response.html
                        } else {
                            content += request.response.html + '<div><pdf:nextpage /></div>'
                        }
                    }
                }
            }

            let allowPageNumbers = paging ? '&include_pagination=true' : ''
            let allowLandscape = landscape ? '&allow_landscape=true' : ''
            await fileDownload(
                'POST',
                API.EXPORTS_HTML_TO_PDF + '?q=' + allowPageNumbers + allowLandscape,
                'application/pdf',
                `${selected[j].id}.pdf`,
                {
                    content: content,
                }
            )
            content = ''
        }
        setProcessing(false)
        onClose()
    }

    useEffect(() => {
        fetchTemplates()
        // eslint-disable-next-line
    }, [])

    const determinateFunction = (e) => {
        e.preventDefault()
        toast({
            type: 'success',
            icon: 'check circle',
            title: t('generating_documents') + '...',
            description: t('wait_a_moment_until_its_finished') + '.',
            animation: 'bounce',
            time: 5000,
        })
        if (mode === 1) return generate_merged_pdf_per_selected_item()
        if (mode === 2) return generate_merged_pdf()
        if (mode === 3) return generate_template_per_selected_item()

        return null
    }

    return (
        <Form onSubmit={determinateFunction}>
            {tags.length > 0 && (
                <>
                    <div style={{ fontWeight: 'bold', marginTop: '0', marginBottom: '0.5rem' }}>
                        {t('filter_by_tags')}:
                    </div>
                    <Label.Group size="tiny">
                        {tags.map((tag) => (
                            <Label
                                content={tag.name}
                                style={{
                                    cursor: 'pointer',
                                    color: 'var(--dark)',
                                    background: tag.color + '80',
                                    borderBottom:
                                        selectedTags.find((item) => item.id === tag.id) !== undefined
                                            ? '2px solid var(--dark)'
                                            : '2px solid var(--light)',
                                    opacity: selectedTags.find((item) => item.id === tag.id) !== undefined ? 1 : 0.8,
                                    marginBottom: 0,
                                }}
                                onClick={() => {
                                    if (selectedTags.find((item) => item.id === tag.id) === undefined) {
                                        setSelectedTags((prev) => [...prev, tag])
                                    } else {
                                        // remove
                                        setSelectedTags((prev) => prev.filter((item) => item.id !== tag.id))
                                    }
                                }}
                            />
                        ))}
                    </Label.Group>
                    <Divider />
                </>
            )}

            <Segment
                loading={loading}
                style={{
                    padding: 0,
                    margin: 0,
                    backgroundColor: 'transparent',
                    minHeight: '5rem',
                    border: 'none',
                    boxShadow: 'none',
                    marginTop: '0.5rem',
                }}
            >
                {loading && (
                    <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '8rem' }}>
                        {' '}
                        {t('loading_templates')}{' '}
                    </p>
                )}
                {!loading && (
                    <>
                        {templates.length > 0 && (
                            <div
                                style={{
                                    display: 'block',
                                    cursor: 'pointer',
                                    color: 'var(--primary',
                                    marginBottom: '1rem',
                                }}
                                className="ref-link"
                                onClick={() => {
                                    templates.filter((item) => item.active && !item.checked).length > 0
                                        ? setTemplates((prev) =>
                                              prev.filter((item) => {
                                                  if (item.active) {
                                                      item.checked = true
                                                  }

                                                  return item
                                              })
                                          )
                                        : setTemplates((prev) =>
                                              prev.filter((item) => {
                                                  if (item.active) {
                                                      item.checked = false
                                                  }

                                                  return item
                                              })
                                          )
                                }}
                            >
                                {templates.filter((item) => item.active && !item.checked).length > 0
                                    ? t('select_all')
                                    : t('clear_selection')}
                            </div>
                        )}

                        {templates.length === 0 && (
                            <p style={{ textAlign: 'left' }}>
                                <strong> {t('no_data')} </strong>
                            </p>
                        )}

                        {templates.map((template) => (
                            <Form.Field
                                key={template.id}
                                style={{
                                    display: template.active ? 'flex' : 'none',
                                    fontWeight: template.checked ? 'bold' : 'normal',
                                    cursor: 'pointer',
                                }}
                                onClick={() =>
                                    setTemplates((prev) =>
                                        prev.filter((item) => {
                                            if (item.id === template.id) {
                                                item.checked = !template.checked
                                            }

                                            return item
                                        })
                                    )
                                }
                            >
                                <Checkbox checked={template.checked} />
                                <div style={{ marginLeft: '0.5rem' }}>
                                    {template.title}
                                    {template.labels.length > 0 && (
                                        <sup>
                                            <small style={{ marginLeft: '0.25rem' }}>
                                                {template.labels.map((label) => (
                                                    <Icon name="ellipse" style={{ color: label.color + '80' }} />
                                                ))}
                                            </small>
                                        </sup>
                                    )}
                                </div>
                            </Form.Field>
                        ))}

                        <Header as="h4">{t('choose_mode')}:</Header>

                        <SuperField
                            as="checkbox"
                            label={t('generate_merged_documents_per_selected_item')}
                            help={t('generate_merged_documents_per_selected_item_hint')}
                            checked={mode === 1}
                            onChange={() => setMode(1)}
                        />
                        <SuperField
                            as="checkbox"
                            label={t('merge_documents_into_one_pdf')}
                            help={t('merge_documents_into_one_pdf_hint')}
                            checked={mode === 2}
                            onChange={() => setMode(2)}
                        />
                        <SuperField
                            as="checkbox"
                            label={
                                t('single_document_per_selected_item') +
                                ' - ' +
                                selected.length * templates.filter((item) => item.checked).length +
                                'x ' +
                                t('document')
                            }
                            help={t('single_document_per_selected_item_hint')}
                            checked={mode === 3}
                            onChange={() => setMode(3)}
                        />

                        <Divider />
                        <SuperField
                            as="checkbox"
                            label={t('allow_to_generate_page_numbers')}
                            checked={paging}
                            onChange={() => setPaging(paging === true ? '' : true)}
                        />
                        <SuperField
                            as="checkbox"
                            label={t('allow_landscape_page_orientation')}
                            checked={landscape}
                            onChange={() => setLandscape(landscape === true ? '' : true)}
                        />

                        <div>
                            {t('number_of_selected_items_from_table')}: <strong>{selected.length}</strong>
                        </div>
                    </>
                )}
            </Segment>
            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    disabled={processing || templates.filter((item) => item.checked).length === 0}
                    loading={processing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    )
}

export default GenerateDocuments
