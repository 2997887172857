import React, { useState, useEffect } from 'react';
import FilterCard from '@components/cards/FilterCard';
import { useTranslation } from "react-i18next"
import { Segment, Grid, Divider } from 'semantic-ui-react';
// store
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';


const CallReportsHeader = () => {
    const [isLoading, setIsLoading] = useState(true)
    const { t } = useTranslation()
    const [statsData, setStatsData] = useState([])

    useEffect(() => {
        async function fetchStats() {
            setIsLoading(true)
                const request = await getRequest(API.REPORTS + '3cx/call_reports/stats/')
                if (request.status === 200) {
                    setStatsData(
                        request.response
                    )
                }
            setIsLoading(false)

        }
        fetchStats()
        // eslint-disable-next-line
    }, [])


    return (
    <Segment loading={isLoading} style={{ padding: 0, margin: 0, backgroundColor: "transparent", minHeight: "5rem", border: "none", boxShadow: "none" }}>
        <Grid centered>
            <Grid.Row>
                <FilterCard
                    color='olive'
                    header={t("total")}
                    icon='phone square'
                    description={statsData?.total_count}
                />
                <FilterCard
                    color='green'
                    header={t("inbound")}
                    icon='phone volume'
                    description={statsData?.inbound_count}
                />
                <FilterCard
                    color='yellow'
                    header={t("outbound")}
                    icon='assistive listening systems'
                    description={statsData?.outbound_count}
                />
                <FilterCard
                    color='orange'
                    header={t("not_answered")}
                    icon='phone'
                    description={statsData?.not_answered_count}
                />
                <FilterCard
                    color='red'
                    header={t("missed")}
                    icon='deaf'
                    description={statsData?.missed_count}
                />
            </Grid.Row>
        </Grid>
    <Divider />
    </Segment>



    );
};

export default CallReportsHeader;