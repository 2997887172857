import React, { Fragment } from "react";

import IconsView from "./IconsView";
import SidebarWidgetsView from "./SidebarWidgetsView";


function Layout(props) {
    return (
        <Fragment>
            { props.viewType === 1 
                ? <IconsView changeViewType={props.changeViewType}/> 
                : <SidebarWidgetsView changeViewType={props.changeViewType}/> 
            }
        </Fragment>
    );
}

export default Layout;
