import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
// import { toast } from 'react-semantic-toasts'
// store
// import { API } from '@store/config'
import { tzDateTime } from '@helpers/dates'
// import { requests } from '@helpers/requests'
// components
// import ConfirmModal from '@components/modals/ConfrimModal'
import Icon from '@components/Icon'
import { Table, Popup, Button, Modal, Divider } from 'semantic-ui-react'
import SuperDuperModal from '@components/modals/SuperDuperModal'
// import PlannerRecordForm from '../attendance-compare-view/PlannerRecordForm'
import AttendanceRecordForm from '../attendance-compare-view/AttendanceRecordForm'
// import AttendanceRecordView from '../attendance-compare-view/AttendanceRecordView'
import CorrectionForm from './CorrectionForm'

const CellEvent = ({
    records,
    absenceRecords,
    planRecords,
    attendanceRecords,
    attendanceAbsenceRecords,
    setAttendanceAbsenceRecords,
    shifts,
    showActionEmptyCell,
    setRecords,
    setContracts,
    setAttendanceRecords,
    config,
    setModal,
    index,
    contract,
    day,
}) => {
    // eslint-disable-next-line
    const { t } = useTranslation()

    // const planner_record_types = useSelector((state) => state.choices?.attendance_record_planner_types)

    const planRecord = planRecords.find(
        (item) => item.contract === contract.id && item.employee === contract.profile_id && item.date === day.date
    )

    const record = records.find(
        (item) => item.contract === contract.id && item.employee === contract.profile_id && item.date === day.date
    )

    const absenceRecordItems = absenceRecords.filter((item) => item.contract === contract.id && item.date === day.date)

    const attendanceRecord = attendanceRecords.find(
        (item) => item.contract === contract.id && item.employee === contract.profile_id && item.date === day.date
    )

    const attendanceAbsenceRecordItems = attendanceAbsenceRecords.filter(
        (item) => item.contract === contract.id && item.date === day.date
    )

    return (
        <Table.Cell
            key={index}
            verticalAlign="middle"
            style={{
                padding: 0,
                minWidth: '50px',
                background: !config.highlightedWeeks.includes(day.week) ? 'transparent' : 'var(--light)',
            }}
        >
            <OverlayCellAction
                planRecord={planRecord}
                record={record}
                shifts={shifts}
                day={day}
                contract={contract}
                setRecords={setRecords}
                setContracts={setContracts}
                absenceRecordItems={absenceRecordItems}
                absenceRecords={absenceRecords}
                attendanceAbsenceRecordItems={attendanceAbsenceRecordItems}
                attendanceAbsenceRecords={attendanceAbsenceRecords}
                attendanceRecord={attendanceRecord}
                attendanceRecords={attendanceRecords}
                setAttendanceRecords={setAttendanceRecords}
                setAttendanceAbsenceRecords={setAttendanceAbsenceRecords}
                showActionEmptyCell={showActionEmptyCell}
            />
        </Table.Cell>
    )
}

const OverlayCellAction = ({
    record,
    planRecord,
    day,
    contract,
    setRecords,
    setContracts,
    shifts,
    attendanceRecord,
    attendanceRecords,
    absenceRecordItems,
    absenceRecords,
    attendanceAbsenceRecordItems,
    attendanceAbsenceRecords,
    setAttendanceAbsenceRecords,
    setAttendanceRecords,
    showActionEmptyCell,
}) => {
    const { t } = useTranslation()

    const isCorrect = (plan, reality) => {
        if (plan === undefined) return false
        if (reality === undefined) {
            return false
        }

        // its workday and its less or equal then plan
        if (plan?.type === 3 && parseFloat(reality?.duration) > parseFloat(plan?.duration)) return false

        // its doctor visit and you have at least one of those filled
        if (
            plan?.type === 2 &&
            reality?.absention === null &&
            reality?.paragraph === null &&
            reality?.sick_day === null &&
            reality?.care_for_a_family_member === null &&
            reality?.family_member_paragraph === null
        )
            return false

        // its vacation and its marked as vacation
        if ((plan?.type === 1 && parseFloat(reality?.vacation) === 0) || reality?.vacation === null) return false

        return true
    }

    const determinateAbsenceType = (absenceType) => {
        if (absenceType === null) return ''
        if (absenceType.source === 'vacation') return t('vacation')
        if (absenceType.source === 'paragraph') return t('paragraph')
        if (absenceType.source === 'absence') return t('absence')
        if (absenceType.source === 'sick_day') return t('sickday_shortcut')
        if (absenceType.source === 'care_for_a_family_member') return t('care_for_a_family_member_shortcut')
        if (absenceType.source === 'family_member_paragraph') return t('family_member_paragraph')
    }

    return (
        <div style={{ padding: '0.5rem' }}>
            <Button.Group
                size="tiny"
                basic
                fluid
                style={
                    record ||
                    attendanceRecord ||
                    absenceRecordItems.length > 0 ||
                    attendanceAbsenceRecordItems.length > 0
                        ? {}
                        : showActionEmptyCell
                        ? {}
                        : { display: 'none' }
                }
            >
                {record || absenceRecordItems.length > 0 ? (
                    <CellContent
                        record={record}
                        absences={absenceRecordItems}
                        buttonStyles={{
                            fontSize: '11px',
                            alignItems: 'center',
                            borderBottom: '3px solid var(--variant4)',
                        }}
                        determinateAbsenceType={determinateAbsenceType}
                        data={{
                            day: day,
                            contract: contract,
                        }}
                        attendanceRecords={attendanceRecords}
                        setAttendanceRecords={setAttendanceRecords}
                        setAttendanceAbsenceRecords={setAttendanceAbsenceRecords}
                        setContracts={setContracts}
                        isCorrect={isCorrect}
                    />
                ) : (
                    <Button disabled style={{ borderBottom: '3px solid var(--variant4)', minWidth: '80px' }}>
                        --
                    </Button>
                )}

                {attendanceRecord || attendanceAbsenceRecordItems.length > 0 ? (
                    <CellContent
                        allowCorrections
                        record={attendanceRecord}
                        absences={attendanceAbsenceRecordItems}
                        buttonStyles={{
                            borderBottom: `3px solid ${
                                !attendanceRecord?.closed && attendanceRecord?.duration > 0
                                    ? 'var(--danger)'
                                    : planRecord !== undefined && !isCorrect(planRecord, attendanceRecord)
                                    ? '#EBEA03'
                                    : planRecord === undefined &&
                                      attendanceRecord?.id !== undefined &&
                                      !attendanceRecord?.is_corrected &&
                                      attendanceRecord?.original_record === null
                                    ? '#EBEA03'
                                    : 'var(--success)'
                            }`,
                            fontSize: '11px',
                            alignItems: 'center',
                        }}
                        determinateAbsenceType={determinateAbsenceType}
                        data={{
                            day: day,
                            contract: contract,
                        }}
                        attendanceRecords={attendanceRecords}
                        setAttendanceRecords={setAttendanceRecords}
                        setAttendanceAbsenceRecords={setAttendanceAbsenceRecords}
                        setContracts={setContracts}
                        planRecord={planRecord}
                        isCorrect={isCorrect}
                    />
                ) : (
                    <SuperDuperModal
                        header={t('add_correction_record')}
                        trigger={
                            <Button style={{ borderBottom: '3px solid var(--primary)' }}>
                                <Icon name="add-outline" style={{ fontSize: '1.2rem' }} />
                            </Button>
                        }
                        content={
                            <AttendanceRecordForm
                                shifts={shifts}
                                setAttendanceRecords={setAttendanceRecords}
                                setAttendanceAbsenceRecords={setAttendanceAbsenceRecords}
                                setContracts={setContracts}
                                data={{
                                    day: day,
                                    contract: contract,
                                }}
                            />
                        }
                    />
                )}
            </Button.Group>
        </div>
    )
}

const CellContent = ({
    allowCorrections,
    data,
    absences,
    attendanceRecords,
    setAttendanceRecords,
    setAttendanceAbsenceRecords,
    setContracts,
    buttonStyles,
    record,
    isCorrect,
    planRecord,
    determinateAbsenceType,
}) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    let title = ''
    if (record?.original_record?.id || record?.is_corrected || isCorrect(planRecord, record)) {
        title = t('record_looks_fine')
    }

    if (planRecord !== undefined && !isCorrect(planRecord, record)) {
        title = t('record_containing_differences_compare_to_plan')
    }

    if (
        planRecord === undefined &&
        record?.id !== undefined &&
        !record?.is_corrected &&
        record?.original_record === null
    ) {
        title = t('missing_plan')
    }

    const [correctionView, setCorrectionView] = useState({
        isOpen: false,
        record: null,
        planRecord: null,
        absences: [],
        size: 'tiny',
    })

    const calculateHours = (absences) => {
        let total = 0

        for (let i = 0; i < absences.length; i++) {
            total += parseFloat(absences[i].hours)
        }

        return total
    }

    return (
        <>
            <Popup
                style={{ zIndex: 1899 }}
                hoverable
                position="top center"
                trigger={
                    <Button
                        id={allowCorrections && record?.night_work_hours > 0 ? 'night-shift-cell' : ''}
                        style={{
                            ...buttonStyles,
                            padding: '0.5rem',
                        }}
                    >
                        <div>
                            <div style={{ fontSize: '12px', fontWeight: 'bold' }}>{record?.duration || 0}h. </div>
                            <small>
                                {' '}
                                {record?.start ? tzDateTime(record?.start).format('HH:mm') : '--:--'} -{' '}
                                {record?.end ? tzDateTime(record?.end).format('HH:mm') : '--:--'}{' '}
                            </small>
                        </div>
                        {absences.length > 0 && (
                            <>
                                <Divider style={{ marginBottom: '0.25rem', marginTop: '0.25rem' }} />
                                <div>
                                    <div style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        {calculateHours(absences) || 0} h.{' '}
                                    </div>
                                    <small>
                                        {t('work_absence_short')} ({absences.length})
                                    </small>
                                </div>
                            </>
                        )}
                    </Button>
                }
                content={
                    <div>
                        <strong>{t('day_overview')}:</strong>

                        {record && (
                            <>
                                <Divider style={{ marginBottom: '0.25rem', marginTop: '0.25rem' }} />
                                {record?.is_corrected || record?.original_record !== null ? (
                                    <>
                                        {t('type')}:{' '}
                                        <strong>
                                            {t('corrected_record')}
                                            <br />
                                        </strong>
                                    </>
                                ) : (
                                    <>
                                        {t('type')}:{' '}
                                        <strong>
                                            {t('original_record')}
                                            <br />
                                        </strong>
                                    </>
                                )}
                                {t('arrival')}:{' '}
                                {record?.start ? tzDateTime(record?.start).format(dateFormat + ' HH:mm') : '--:--'}
                                <br />
                                {t('departure')}:{' '}
                                {record.end ? tzDateTime(record.end).format(dateFormat + ' HH:mm') : '--:--'}
                                <br />
                                {t('working_hours')}: {record.duration} {t('hours_shortcut')}.
                                <br />
                                {record.night_work_hours > 0 && (
                                    <>
                                        {t('night_hours')}: {record.night_work_hours} {t('hours_shortcut')}.
                                        <br />
                                    </>
                                )}
                            </>
                        )}

                        {absences.length > 0 && (
                            <>
                                <Divider style={{ marginBottom: '0.25rem', marginTop: '0.25rem' }} />
                                {absences.map((absence) => (
                                    <div key={absence.id}>
                                        {absence.absence_type.title}: {absence.hours} {t('hours_shortcut')}.
                                    </div>
                                ))}
                            </>
                        )}

                        {title && allowCorrections ? (
                            <>
                                <Divider />
                                <div style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '1rem' }}>{title}</div>
                            </>
                        ) : null}

                        {allowCorrections && (
                            <>
                                <Divider />
                                <div
                                    onClick={() =>
                                        setCorrectionView((prev) => ({
                                            ...prev,
                                            isOpen: true,
                                            record: record,
                                            absences: absences,
                                            planRecord: planRecord,
                                        }))
                                    }
                                    className="ref-link"
                                    style={{
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        color: 'var(--primary',
                                        marginTop: '0.3rem',
                                        marginBottom: '0.5rem',
                                    }}
                                >
                                    {t('take_action')}
                                </div>
                            </>
                        )}
                    </div>
                }
            />

            <Modal
                closeIcon
                // centered={false}
                size={correctionView.size || 'tiny'}
                open={correctionView.isOpen}
                onClose={() =>
                    setCorrectionView((prev) => ({
                        ...prev,
                        isOpen: false,
                        record: null,
                        planRecord: null,
                        absences: [],
                    }))
                }
            >
                <Modal.Content>
                    <CorrectionForm
                        data={data}
                        title={title}
                        onClose={() =>
                            setCorrectionView({
                                isOpen: false,
                                record: null,
                                planRecord: null,
                                absences: [],
                            })
                        }
                        attendanceRecords={attendanceRecords}
                        setSize={(size) => setCorrectionView((prev) => ({ ...prev, size: size }))}
                        setAttendanceRecords={setAttendanceRecords}
                        setAttendanceAbsenceRecords={setAttendanceAbsenceRecords}
                        setContracts={setContracts}
                        record={correctionView.record}
                        absences={correctionView.absences}
                        planRecord={correctionView.planRecord}
                    />
                </Modal.Content>
            </Modal>
        </>
    )
}

export default CellEvent
