import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { tzDateTime } from '@helpers/dates';
// components
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const DeliveryDateForm = ({ record, setAction, setData, setTotal, filters, onClose }) => {
    const { t } = useTranslation()
    const today = moment()

    const [date, setDate] = useState(tzDateTime(today).format("YYYY-MM-DD"))
    const [processing, setProcessing] = useState(false)
    // eslint-disable-next-line
    const [time, setTime] = useState("")

    const handleSubmit = async () => {
        setProcessing(true)
        // const datetime = moment()

        const request = await requests.patch(API.LOGISTICS + "records/" + record.id + "/", { 
            status: 4,
            delivery_date: date,
            delivery_time: time === "" ? null : time
        })

        if (request.status === 200) {
            if (filters.status.includes("4") || filters.status.length === 0) {
                setData(prev => prev.filter(item => {
                    if (item.id === record.id) {
                        item.status = request.response.status
                        item.status_display = request.response.status_display
                        item.delivery_date = request.response.delivery_date
                    }

                    return item
                }))
            } else {
                setData(prev => prev.filter(item => item.id !== record.id))
                setTotal(prev => prev - 1)
            }

            onClose()
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('record_status_changed'),
                animation: 'pulse',
                time: 2000,
            })
        }

        setAction(null)
        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    required
                    value={date}
                    label={t("date")}
                    onChange={(e, { value }) => setDate(value)}
                />
            </Form.Group>
            {/* <SuperField as="timepicker"
                value={time}
                label={t("time")}
                onChange={(e, { value }) => setTime(value)}
            /> */}

            <Divider/>

            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit 
                    text={t("confirm")}
                    loading={processing}
                    disabled={processing || date === ""}
                />
            </Form.Field>
        </Form>
    );
};

export default DeliveryDateForm;