import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { handleTimeInput, isTimeValid, tzDateTime } from '@helpers/dates'
// components
import { Form, Divider, Message } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const UpdateTimesForCorrectionRecord = ({ day, record, setAttendanceRecords, onClose }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [form, setForm] = useState({
        arrival_time: '',
        departure_time: '',
    })

    const handleSubmit = async () => {
        setProcessing(true)
        let start = moment(day.date + ' ' + form.arrival_time).format('YYYY-MM-DD HH:mm')
        let end = moment(day.date + ' ' + form.departure_time).format('YYYY-MM-DD HH:mm')

        // local to UTC
        start = tzDateTime(start, true)
        end = tzDateTime(end, true)

        if (start.isAfter(end)) {
            end = moment(end).add(1, 'days')
        }

        const request = await requests.patch(API.ATTENDANCE_BASE + 'records/' + record.id + '/?is_manager_edit=true', {
            start: start.format('YYYY-MM-DD HH:mm'),
            end: end.format('YYYY-MM-DD HH:mm'),
        })

        if (request.status === 200) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('record_updated'),
                animation: 'pulse',
                time: 2000,
            })

            setAttendanceRecords((prev) =>
                prev.map((item) => {
                    if (item.id === record.id) {
                        item = {
                            id: request.response.id,
                            employee: request.response.employee?.id,
                            contract: request.response.contract?.id,
                            date: request.response.date,
                            start: request.response.start,
                            end: request.response.end,
                            night_work_hours: request.response?.night_work_hours || 0,
                            duration: request.response?.work_hours || 0,
                            duration_real: request.response?.duration || 0,
                            closed: request.response.closed,
                            vacation: request.response.vacation,
                            vacation_hours: request.response.vacation_hours,
                            paragraph: request.response.paragraph,
                            sick_day: request.response.sick_day,
                            absention: request.response.absention,
                            care_for_a_family_member: request.response.care_for_a_family_member,
                            family_member_paragraph: request.response.family_member_paragraph,
                            absence_type: request.response.absence_type,
                            original_record: request.response.original_record,
                            correction: request.response.correction,
                            is_corrected: request.response.is_corrected,
                            is_saturday: request.response.is_saturday,
                            is_sunday: request.response.is_sunday,
                            is_holiday: request.response.is_holiday,
                            week: moment(request.response.date).isoWeek(),
                        }
                    }
                    return item
                })
            )
            onClose()
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message visible info>
                <strong>
                    {t(
                        'this_action_will_only_change_time_of_arrival_and_time_of_departure_make_sure_to_correct_banks_manually_if_needed'
                    )}
                </strong>
            </Message>
            <Form.Group widths="equal">
                <SuperField
                    as="timepicker"
                    required
                    label={t('arrival')}
                    value={form.arrival_time}
                    error={!isTimeValid(form.arrival_time) ? true : false}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({
                            ...prev,
                            arrival_time: handleTimeInput(value),
                        }))
                    }
                />
                <SuperField
                    as="timepicker"
                    required
                    label={t('departure')}
                    value={form.departure_time}
                    error={!isTimeValid(form.departure_time) ? true : false}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({
                            ...prev,
                            departure_time: handleTimeInput(value),
                        }))
                    }
                />
            </Form.Group>

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={processing}
                    disabled={
                        processing ||
                        form.arrival_time === '' ||
                        form.departure_time === '' ||
                        !isTimeValid(form.arrival_time) ||
                        !isTimeValid(form.departure_time)
                    }
                />
            </Form.Field>
        </Form>
    )
}

export default UpdateTimesForCorrectionRecord
