import React from 'react';
// components
import { Button } from 'semantic-ui-react';
import { useTranslation } from "react-i18next"

const ModalCancel = ({text, onClose, disabled}) => {
    const { t } = useTranslation();
    /*
        ** text is used for text definition for button ("Cancel" text is used by default if not rext property is present)
        ** onClose is used to close active modal dialog using this button (its a function for closing Modal)
        ** disabled is button state that can not be clicked / is not clickable
    */
    return (
        <Button
            type="button"
            className="modal-cancel"
            onClick={onClose}
            disabled={disabled}>
            { text ? text : t('cancel') }
        </Button>
    );
};

export default ModalCancel;