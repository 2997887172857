const trackerReducer = (tracker = {}, action) => {
    switch (action.type) {
        case "ADD_TRACKER_DATA":
            return action.tracker;
        default:
            return tracker;
    }
};

export default trackerReducer;
