import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
//store
import { requests } from '@helpers/requests';
//other
import Address from '@components/forms/Address';
import SuperField from '@components/forms/SuperField';
import { Form, Button, Message, Grid } from 'semantic-ui-react';

function PersonalInfoForm({data, setView, onConfirm, patchEndpoint}) {
    const { t } = useTranslation();

    const [permanentAddress, setPermanentAddress] = useState({
        street: data?.permanent_address?.street  || "",
        city: data?.permanent_address?.city  || "",
        number: data?.permanent_address?.number  || "",
        orientation_number: data?.permanent_address?.orientation_number  || "",
        postcode: data?.permanent_address?.postcode  || "",
        country: data?.permanent_address?.country  || null,
        state: data?.permanent_address?.state  || "",
        address_line: data?.permanent_address?.address_line  || ""
    })

    const [temporaryAddress, setTemporaryAddress] = useState({
        street: data?.temporary_address?.street  || "",
        city: data?.temporary_address?.city  || "",
        number: data?.temporary_address?.number  || "",
        orientation_number: data?.temporary_address?.orientation_number  || "",
        postcode: data?.temporary_address?.postcode  || "",
        country: data?.temporary_address?.country  || null,
        state: data?.temporary_address?.state  || "",
        address_line: data?.temporary_address?.address_line  || ""
    })

    const [form, setForm] = useState({
        firstname: data?.firstname || "",
        lastname: data?.lastname || "",
        middlename: data?.middlename || "",
        titles_before: data?.titles_before || "",
        titles_after: data?.titles_after || "",
        gender: data?.gender || 3,
        date_of_birth: data?.date_of_birth  || null,
        place_of_birth: data?.place_of_birth  || "",
        name_at_birth: data?.name_at_birth  || "",
        marital_status: data?.marital_status || null,
        personal_number: data?.personal_number || "",
        emergency_name: data?.emergency_name || "",
        emergency_phone: data?.emergency_phone || "",
        emergency_email: data?.emergency_email || ""
    })

    const [errors, setErrors] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async () => {
        let dataset = form

        setIsProcessing(true);
        if (data.personal_number === form.personal_number) {
            delete dataset.personal_number
        }

        dataset['permanent_address'] = permanentAddress
        dataset['temporary_address'] = temporaryAddress

        const request = await requests.patch(patchEndpoint + `${data?.id}/`, dataset)

        if (request.status === 200) {
            onConfirm(request.response)
            setView(0)
        } else if( request.status === 400 ) {
            setErrors(request.response)
        }

        setIsProcessing(false);
    }

    return (

        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors?.non_field_errors?.length > 0}
                header={t("error_submission_message")}
                list={errors?.non_field_errors}
            />

            <Form.Group widths={5} style={{ paddingTop: "0.6rem", paddingBottom: "0.6rem"}}>
                <SuperField as="input"
                    required
                    autoFocus
                    label={t('firstname')}
                    placeholder={t('firstname')}
                    error={ errors?.firstname?.[0] || false }
                    value={form?.firstname}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            firstname: value
                        }))
                    }
                />
                <SuperField as="input"
                    required
                    label={t('lastname')}
                    placeholder={t('lastname')}
                    error={ errors?.lastname?.[0] || false }
                    value={form?.lastname}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            lastname: value
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('middlename')}
                    placeholder={t('middlename')}
                    error={ errors?.middlename?.[0] || false }
                    value={form?.middlename}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            middlename: value
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('titles_before')}
                    placeholder={t('titles_before')}
                    error={ errors?.titles_before?.[0] || false }
                    value={form?.titles_before}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            titles_before: value
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('titles_after')}
                    placeholder={t('titles_after')}
                    error={ errors?.titles_after?.[0] || false }
                    value={form?.titles_after}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            titles_after: value
                        }))
                    }
                />
            </Form.Group>
            <Form.Group widths={5} style={{ paddingTop: "0.6rem", paddingBottom: "0.6rem"}}>
                <SuperField as="datepicker"
                    label={ t('date_of_birth') }
                    error={ errors?.date_of_birth?.[0] || false }
                    value={form?.date_of_birth}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            date_of_birth: value
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('place_of_birth')}
                    placeholder={t('place_of_birth')}
                    error={ errors?.place_of_birth?.[0] || false }
                    value={form?.place_of_birth}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            place_of_birth: value
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('name_at_birth')}
                    placeholder={t('name_at_birth')}
                    error={ errors?.name_at_birth?.[0] || false }
                    value={form?.name_at_birth}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            name_at_birth: value
                        }))
                    }
                />
                <SuperField as="choice-select"
                    type="genders"
                    label={t('gender')}
                    error={ errors?.gender?.[0] || false }
                    value={form?.gender?.toString() || ""}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            gender: value
                        }))
                    }
                    initialOptions={{
                        attribute: "gender",
                        as_display: true,
                        source: data,
                    }} 
                />
                <SuperField as="choice-select"
                    search
                    type="marital_statuses"
                    label={t('marital_status')}
                    error={ errors?.marital_status?.[0] || false }
                    value={form?.marital_status?.toString() || ""}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            marital_status: value
                        }))
                    }
                    initialOptions={{
                        attribute: "marital_status",
                        as_display: true,
                        source: data,
                    }} 
                />

            </Form.Group>

            <Form.Group widths={5} style={{ paddingTop: "0.6rem", paddingBottom: "0.6rem"}}>
                <SuperField as="input"
                    label={t('personal_number')}
                    placeholder={t('personal_number')}
                    error={ errors?.personal_number?.[0] || false }
                    value={form?.personal_number}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_number: value
                        }))
                    }
                />

                <SuperField as="input"
                    label={t('emergency_contact_name')}
                    placeholder={t('emergency_contact_name')}
                    error={ errors?.emergency_name?.[0] || false }
                    value={form?.emergency_name}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            emergency_name: value
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('emergency_contact_phone')}
                    placeholder={t('emergency_contact_phone')}
                    error={ errors?.emergency_phone?.[0] || false }
                    value={form?.emergency_phone}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            emergency_phone: value
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('emergency_contact_email')}
                    placeholder={t('emergency_contact_email')}
                    error={ errors?.emergency_email?.[0] || false }
                    value={form?.emergency_email}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            emergency_email: value
                        }))
                    }
                />
               

            </Form.Group>

            <Grid style={{ padding: 0 }}>
                <Grid.Row style={{ paddingTop: "1rem", padding: 0 }} columns={1} stackable>
                    <Grid.Column style={{ padding: 0 }}>
                        <div style={{ marginBottom: "0.5rem", fontWeight: "bold" }}>{t('permanent_address')}</div>
                        <Address 
                            isForm 
                            slashPushFromTop="0.5rem"
                            address={permanentAddress}
                            setAddress={setPermanentAddress}
                        />
                    </Grid.Column>
                    <Grid.Column style={{ padding: 0 }}>
                        <div style={{ marginBottom: "0.5rem", marginTop: "1rem", fontWeight: "bold" }}>{t('temporary_address')}</div>
                        <Address 
                            isForm
                            slashPushFromTop="0.5rem"
                            address={temporaryAddress}
                            setAddress={setTemporaryAddress}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Form.Field style={{ textAlign: "right", marginTop: "0.5rem" }}>
                <Button
                    primary
                    loading={isProcessing}
                    type="submit"
                    disabled={isProcessing}
                >
                    {t('confirm')}
                </Button>
            </Form.Field>
        </Form>
    )
}

export default PersonalInfoForm