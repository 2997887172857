// react
import React from "react";
// styles
import { Button, Header, Container, Icon } from "semantic-ui-react";

// component
function ActivationErrorMessage(props) {
    // template
    return (
        <Container text>
            <Icon
                primary
                name="warning"
                size="huge"
                circular
                align="center"
            />
            <Header
                as="h3"
                style={{
                    marginTop: "0.5em",
                }}
                content="Account activation error"
            />
            <Header
                as="h4"
                content="You account wasn't activated properly, try again please."
            />
            <Button primary fluid size="big" onClick={props.tryAgain}>
                Try Again
            </Button>
        </Container>
    );
}

export default ActivationErrorMessage;
