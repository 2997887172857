import React from 'react'
import { useTranslation } from 'react-i18next'
// components
import { Segment } from 'semantic-ui-react'

const SpinnerSegment = ({ loading, loadingMessage, children, marginBottom }) => {
    const { t } = useTranslation()

    return (
        <Segment
            loading={loading}
            style={{
                padding: 0,
                margin: 0,
                background: 'transparent',
                minHeight: 'auto',
                marginBottom: marginBottom || '1rem',
                border: 'none',
                boxShadow: 'none',
            }}
        >
            {loading && (
                <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '8rem' }}>
                    {' '}
                    {loadingMessage || t('loading')}{' '}
                </p>
            )}
            {!loading && children}
        </Segment>
    )
}

export default SpinnerSegment
