import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { isEmpty } from '@helpers/validation';
// components
import { Form, Divider, Image, Grid, Header } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const JournalForm = ({ journal, connection, setRefetch, onClose }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [form, setForm] = useState({
        name: journal.name,
        check_account_id: journal.check_account_id,
        domestic_currency: journal.domestic_currency,
        numbering_preference: journal.numbering_preference
    })

    const [imgErrors, setImgErrors] = useState(null)
    
    // eslint-disable-next-line
    const [errors, setErrors] = useState(null)
    const [logoImage, setLogoImage] = useState(null)
    const [signatureImage, setSignatureImage] = useState(null)
    const [images, setImages] = useState({
        logo: journal?.logo || null,
        signature: journal?.signature || null
    })

    useEffect(() => {
        if (logoImage !== null) {
            handlePreview(logoImage, "logo_img")
        }

        if (signatureImage !== null) {
            handlePreview(signatureImage, "signature_img")
        }
    }, [logoImage, signatureImage])

    const handleSubmit = async () => {
        setIsProcessing(true)
        setImgErrors(null)

        let data = form
        if (logoImage === null && images.logo === null) data.logo = null
        if (signatureImage === null && images.signature === null) data.signature = null

        const request = await requests.patch(API.INVOICES + "invoice_accounts/" + journal.id  + "/?connection=" + connection.connection_id, form)

        if (request.status === 200) {
            // manage logo upload if exists
            if (logoImage !== null) {
                const formDataLogo = new FormData()
                formDataLogo.append("logo", logoImage)

                const request_upload = await requests.post(API.INVOICES + "invoice_accounts/" + journal.id  + "/logo/upload/?connection=" + connection.connection_id, formDataLogo)
                if (request_upload.status === 400) {
                    setImgErrors(prev => ({...prev, ...request_upload.response}))
                    setIsProcessing(false)
                    return
                }
            }
            
            // manage signature upload if exists 
            if (signatureImage !== null) {
                const formDataSignature = new FormData()
                formDataSignature.append("signature", signatureImage)

                const request_upload = await requests.post(API.INVOICES + "invoice_accounts/" + journal.id  + "/signature/upload/?connection=" + connection.connection_id, formDataSignature)
                if (request_upload.status === 400) {
                    setImgErrors(prev => ({...prev, ...request_upload.response}))
                    setIsProcessing(false)
                    return
                }
            }

            setRefetch(true)
        }

        setIsProcessing(false)
    }

    const hiddenLogoFileInput = React.useRef(null)
    const hiddenSignatureFileInput = React.useRef(null)

    const handleLogoClick = event => {
        hiddenLogoFileInput.current.click();
    }

    const handleSignatureClick = event => {
        hiddenSignatureFileInput.current.click();
    }

    const handleLogoChange = function loadFile(event) {
        if (event.target.files.length > 0) {
            setLogoImage(event.target.files[0])
        }
    }

    const handleSignatureChange = function loadFile(event) {
        if (event.target.files.length > 0) {
            setSignatureImage(event.target.files[0])
        }
    }

    const handlePreview = (file, imageID) => {
        let reader = new FileReader()
        reader.readAsDataURL(file);
        reader.onload = function (){
            const image = document.getElementById(imageID)
            image.src = reader.result
        }
    }

    const onRemove = (type) => {
        if (type === "logo") {
            document.getElementById('logo_image').value = null
            setImages(prev => ({...prev, logo: null}))
            setLogoImage(null)
        } else {
            document.getElementById('signature_image').value = null
            setImages(prev => ({...prev, signature: null}))
            setSignatureImage(null)
        }
    }
    
    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="input"
                autoFocus
                required
                label={t('name')}
                value={form.name}
                onChange={(e, { value }) => setForm(prev => ({...prev, name: value}))}
                />

            <Form.Group widths="equal">
                <SuperField as="input"
                    label={t('numbering_preference')}
                    value={form.numbering_preference}
                    onChange={(e, { value }) => setForm(prev => ({...prev, numbering_preference: value}))}
                    />

                <SuperField as="choice"
                    search
                    clearable={false}
                    type="currency_codes"
                    label={t('domestic_currency')}
                    value={form.domestic_currency}
                    onChange={(e, { value }) => setForm(prev => ({...prev, domestic_currency: value}))}
                />
            </Form.Group>

            <SuperField as="checkbox"
                label={t('allow_to_strictly_validate_id_number')}
                checked={form.check_account_id}
                onChange={() => setForm(prev => ({...prev, check_account_id: !form.check_account_id }))}
            />

            <Header style={{ display: "none" }} as="h4" content={t('manage_logo_and_signature')}/>
            <Grid stackable style={{ display: "none" }}>
                <Grid.Row columns={2} verticalAlign="top" textAlign="center">
                    <Grid.Column>
                        <input
                            type="file"
                            id="logo_image"
                            name="logo_image"
                            ref={hiddenLogoFileInput}
                            onChange={handleLogoChange}
                            style={{ display: "none" }}
                        />
                        { logoImage === null && images.logo === null
                            ? 
                            <div 
                                style={{ 
                                    padding: "2.5rem",
                                    cursor: "pointer",
                                    borderRadius: "5px",
                                    border: "1px dotted black",
                                }}
                                onClick={handleLogoClick}
                            >
                                <Header as="h4" content={t('logo')} style={{ marginTop: 0 }}/>
                            </div>
                            : 
                            <div>
                                <div style={{ display: "flex", fontWeight: "bold", marginTop: "0.5rem" }}>
                                    <p style={{ width: "50%", textAlign: "left" }}>{t('logo')}</p>
                                    <p style={{ width: "50%", textAlign: "right", color: "var(--danger)", cursor: "pointer" }}>
                                        <span onClick={() => onRemove("logo")}>
                                            {t('remove')}
                                        </span>
                                    </p>
                                </div>
                                <Image size="small" id="logo_img" style={{ width: "100%", height: "auto" }} src={images.logo}/>
                            </div>
                        }
                    </Grid.Column>
                    <Grid.Column>
                        <input
                            type="file"
                            id="signature_image"
                            name="signature_image"
                            ref={hiddenSignatureFileInput}
                            onChange={handleSignatureChange}
                            style={{ display: "none" }}
                        />
                        { signatureImage === null && images.signature === null
                            ? 
                            <div 
                                style={{
                                    padding: "2.5rem",
                                    cursor: "pointer",
                                    borderRadius: "5px",
                                    border: "1px dotted black",
                                }}
                                onClick={handleSignatureClick}
                            >
                                <Header as="h4" content={t('signature')} style={{ marginTop: 0 }}/>
                            </div>
                            : 
                            <div>
                                <div style={{ display: "flex", fontWeight: "bold", marginTop: "0.5rem" }}>
                                    <p style={{ width: "50%", textAlign: "left" }}>{t('signature')}</p>
                                    <p style={{ width: "50%", textAlign: "right", color: "var(--danger)", cursor: "pointer" }}>
                                        <span onClick={() => onRemove("signature")}>
                                            {t('remove')}
                                        </span>
                                    </p>
                                </div>
                                <Image size="small" id="signature_img" style={{ width: "100%", height: "auto" }} src={images.signature}/>
                            </div>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {/* <NonFieldErrors errors={imgErrors} /> */}
            { imgErrors?.file && <p style={{ color: "var(--danger)" }}> { imgErrors?.file } </p> }
            { imgErrors?.logo?.[0] && <p style={{ color: "var(--danger)" }}> { imgErrors?.logo?.[0] } </p> }
            { imgErrors?.signature?.[0] && <p style={{ color: "var(--danger)" }}> { imgErrors?.signature?.[0] } </p> }

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    disabled={isProcessing || isEmpty(form.name)}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default JournalForm;