import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { getRandomColor } from '@helpers/functions'
// components
import { Divider, Form } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const LabelForm = ({ onClose, onAdd, setProject, label }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        name: label?.name || '',
        color: label?.color || getRandomColor(),
    })

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(true)

        if (label?.id) {
            const request = await requests.patch(API.PROJECTS + 'labels/' + label.id + '/', form)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                setProject((prev) => ({
                    ...prev,
                    labels: prev.labels.map((item) => {
                        if (item.id === label.id) item = request.response

                        return item
                    }),
                }))
                onClose()
            }
        } else {
            const request = await requests.post(API.PROJECTS + 'labels/', form)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                onAdd(request.response)
                onClose()
            }
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField
                as="input"
                required
                autoFocus
                label={t('name')}
                placeholder={t('name')}
                error={errors?.name || false}
                value={form.name}
                onChange={(e, { value }) => setForm({ ...form, name: value })}
            />

            <SuperField
                as="colorpicker"
                label={t('color')}
                help={t('color_to_improve_visual_in_graphs')}
                color={form.color}
                setColor={(value) => setForm((prev) => ({ ...prev, color: value }))}
            />
            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit loading={processing} disabled={processing} />
            </Form.Field>
        </Form>
    )
}

export default LabelForm
