import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { useFetchData, useQueryPage } from '@helpers/hooks'
import { requests } from '@helpers/requests'
// components
import Paginator from '@components/Paginator'
import CanView from '@components/perms/CanView'
import Filters from '../components/history/Filters'
import HistoryTable from '../components/history/HistoryTable'
import { Segment } from 'semantic-ui-react'

const AssetsHistoryView = () => {
    const { t } = useTranslation()
    let page = useQueryPage()
    const location = useLocation()
    const query =
        '?query={created_on,created_by,note,id,quantity,operation_display,updated_on,stored_item{item{title},warehouse{title}}}&exclude_catalogue_item=true'

    const [limit, setLimit] = useState(10)
    const [assetsHistory, setAssetsHistory] = useFetchData(
        API.ASSET_HISTORY + query + '&paginate=true&page=' + page + '&limit=' + limit
    )

    const initFilterValues = {
        operation: '',
        storedItem: '',
        employee: '',
    }

    const [filterValues, setFilterValues] = useState(initFilterValues)

    const onFilter = async (params, isFiltered) => {
        setAssetsHistory((prev) => ({ ...prev, isLoading: true }))

        if (isFiltered) {
            page = 1
        } else {
            let searchParams = new URLSearchParams(location.search)
            const param = searchParams.get('page')
            page = param !== null ? param : 1
        }

        let limitParam = limit
        if (params.includes('limit')) {
            let searchParams = new URLSearchParams(params)
            const retrievedLimit = searchParams.get('limit')
            setLimit(retrievedLimit)
            limitParam = retrievedLimit
        }

        if (!params.includes('page')) {
            params += '&page=' + page
        }

        if (!params.includes('limit')) {
            params += '&limit=' + limitParam
        }

        if (filterValues.operation) params += '&operation=' + filterValues.operation
        if (filterValues.storedItem) params += '&stored_item=' + filterValues.storedItem
        if (filterValues.employee) params += '&employee=' + filterValues.employee

        const request = await requests.get(API.ASSET_HISTORY + query + '&paginate=true' + params)
        if (request.status === 200) {
            setAssetsHistory({
                isLoading: false,
                data: request.response,
            })
        } else {
            setAssetsHistory((prev) => ({ ...prev, isLoading: false }))
        }
    }

    return (
        <CanView permissions={['assets.c_view_item_history']} redirect>
            <Filters
                filterValues={filterValues}
                assetsHistory={assetsHistory}
                onFilter={onFilter}
                setFilterValues={setFilterValues}
            />
            <Segment
                loading={assetsHistory.isLoading}
                style={{
                    padding: 0,
                    background: 'transparent',
                    boxShadow: 'none',
                    border: 'none',
                    marginBottom: '1rem',
                }}
            >
                {assetsHistory.isLoading && (
                    <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '8rem' }}>{t('loading')} </p>
                )}
                {!assetsHistory.isLoading && (
                    <>
                        <HistoryTable assetsHistory={assetsHistory} />
                        <Paginator
                            forcePage={page || 1}
                            limit={limit}
                            setLimit={setLimit}
                            length={assetsHistory?.data?.count || 0}
                            onChange={(params) => onFilter(params)}
                        />
                    </>
                )}
            </Segment>
        </CanView>
    )
}

export default AssetsHistoryView
