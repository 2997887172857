import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useQueryPage, useIsMount } from '@helpers/hooks'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { tzDateTime } from '@helpers/dates'
// components
import {
    Menu,
    Label,
    //Popup,
    Grid,
    Header,
    Tab,
    Divider,
    MenuItem,
    Feed,
    FeedEvent,
    Modal,
    Container,
} from 'semantic-ui-react'
import Icon from '@components/Icon'
import SidebarPanel from '@components/SidebarPanel'
import Paginator from '@components/Paginator'
import SpinnerSegment from '@components/SpinnerSegment'

function Notifications({ smallMenu }) {
    const { t } = useTranslation()
    const isMount = useIsMount()
    let page = useQueryPage()
    page = isMount ? 1 : page

    const [limit, setLimit] = useState(10)
    const currentUser = useSelector((state) => state.user.id)
    const language = useSelector((state) => state.language)
    const dateFormat = useSelector((state) => state?.date_format || 'YYYY-MM-DD')

    const [visible, setVisible] = useState(false)
    const [visibleShaking, setVisibleShaking] = useState(false)
    const [loadingNew, setLoadingNew] = useState(true)
    const [loading, setLoading] = useState(true)
    const [readActive, setReadActive] = useState(false)

    const [openedDetail, setOpenedDetail] = useState([])
    const [newNotifications, setNewNotifications] = useState([])
    const [oldNotifications, setOldNotifications] = useState([])
    //const [choices, setChoices] = useState([])

    const [activeLabel, setActiveLabel] = useState('all')
    const [notifications, setNotifications] = useState(newNotifications)
    const [changedLanguage, setChangedLanguage] = useState(language)

    const types = ['all', ...new Set(newNotifications.map((item) => item.action_object_type))]

    const openDetail = (id) => {
        setOpenedDetail((prevState) => [...prevState, id])
    }

    const closeDetail = (id, read) => {
        setOpenedDetail((prevState) => prevState.filter((openedId) => openedId !== id))

        if (!read) {
            setTimeout(() => {
                markAsRead(id, read)
            }, 15000)
        }
    }

    useEffect(() => {
        async function getNewNotifications() {
            setLoadingNew(true)

            setVisibleShaking(false)
            const request = await requests.get(API.NOTIFICATIONS + `?recipient=${currentUser}&unread=true`)

            if (request.status === 200) {
                const newItems = request.response.filter(
                    (item) => !newNotifications.some((notification) => notification.id === item.id)
                )
                setNewNotifications([...newNotifications, ...newItems])
            }

            setLoadingNew(false)
        }
        const intervalId = setInterval(getNewNotifications, 10000)

        getNewNotifications()
        return () => clearInterval(intervalId)
    }, [changedLanguage])

    /*useEffect(() => {
        async function getChoices() {
            const request = await requests.get(API.NOTIFICATIONS + `choices/`)

            if (request.status === 200) {
                setChoices(request.response)
            }
        }

        getChoices()
    }, [])*/

    useEffect(() => {
        if (language !== changedLanguage) {
            setNewNotifications([])
            setOldNotifications([])
            setChangedLanguage(language)
        }
    }, [language])

    async function getOldNotifications() {
        setLoading(true)

        const request = await requests.get(
            API.NOTIFICATIONS + `?recipient=${currentUser}&unread=false&page=${page}&limit=${limit}`
        )

        if (request.status === 200) {
            setOldNotifications(request.response)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (readActive) getOldNotifications()
    }, [readActive, changedLanguage])

    const markAsRead = async (id, read) => {
        setLoading(true)

        const request = await requests.patch(API.NOTIFICATIONS + id + '/', { unread: read ? true : false })

        if (request.status === 200) {
            if (read) {
                setNewNotifications((prev) => [request.response, ...prev])
                setOldNotifications((prev) => prev.filter((item) => item.id !== id))
            } else {
                setOldNotifications((prev) => [request.response, ...prev])
                setNewNotifications((prev) => prev.filter((item) => item.id !== id))
            }
        }
        setLoading(false)
    }

    const deleteNotification = async (id, read) => {
        setLoading(true)

        const request = await requests.del(API.NOTIFICATIONS + id + '/')

        if (request.status === 204) {
            setOldNotifications((prev) => prev.filter((item) => item.id !== id))
        }
        setLoading(false)
    }

    const NotificationDetail = ({ item, read }) => {
        return (
            <Modal
                closeIcon
                size={'tiny'}
                open={openedDetail.includes(item.id)}
                onClose={() => closeDetail(item.id, read)}
            >
                <Modal.Content>{item.action_object?.title || item.action_object?.name}</Modal.Content>
            </Modal>
        )
    }

    // const getNotificationText = (verb) => {
    //     if (verb === 'case_assign') return t('assigned_you_to_the_case')
    //     if (verb === 'issue_remove') return t('removed_issue')

    //     return ''
    // }

    const isClickable = (item) => {
        if (item?.action_object_type === 'case') return true
    }

    const Notification = ({ item, read }) => {
        const [isHovered, setIsHovered] = useState(false)
        const [canClick] = useState(false) //useState(isClickable(item))

        const handleMouseEnter = () => {
            setIsHovered(true)
        }

        const handleMouseLeave = () => {
            setIsHovered(false)
        }

        const onClickItem = (item) => {
            if (canClick) openDetail(item.id)
        }

        return (
            <>
                <FeedEvent
                    style={{
                        borderLeft: `2px solid ${
                            item.level === 'info'
                                ? 'blue'
                                : item.level === 'error'
                                ? 'red'
                                : item.level === 'warning'
                                ? 'yellow'
                                : 'green'
                        }`,
                        backgroundColor: isHovered ? 'white' : 'var(--light)',
                        padding: '0.3rem',
                        margin: 0,
                        marginBottom: '0.3rem',
                        borderRadius: '5px',
                        borderBottomLeftRadius: '0',
                        borderTopLeftRadius: '0',
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <Feed.Content style={{ paddingLeft: '0.5rem', paddingTop: '0.5rem' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Feed.Date style={{ marginLeft: 0, fontSize: '0.8rem' }}>
                                {' ' + tzDateTime(item.timestamp).format(dateFormat + ' HH:mm')}
                            </Feed.Date>
                            <Feed.Label style={{ marginTop: 0, marginBottom: 0 }}>
                                <Label
                                    size="mini"
                                    basic
                                    content={t(item.action_object_type)}
                                    style={{ position: 'relative', top: '-0.3rem' }}
                                />
                            </Feed.Label>
                        </div>
                        <Feed.Summary style={{ cursor: canClick ? 'pointer' : '' }} onClick={() => onClickItem(item)}>
                            {item.converted_verb}
                        </Feed.Summary>
                        <Feed.Meta>
                            <Feed.Like
                                onClick={() => {
                                    markAsRead(item.id, read)
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                <Icon
                                    name={read ? 'mail-unread' : 'mail-open-outline'}
                                    style={{ marginRight: '0.4rem' }}
                                />
                                <span style={{ position: 'relative', top: '-0.1rem' }}>
                                    {read ? t('mark_as_unread') : t('mark_as_read')}
                                </span>
                            </Feed.Like>
                            {read && (
                                <Feed.Like
                                    position="right"
                                    onClick={() => {
                                        deleteNotification(item.id)
                                    }}
                                    style={{ paddingLeft: '1rem', cursor: 'pointer', float: 'right' }}
                                >
                                    <span style={{ color: 'var(--danger)' }}>{t('remove')}</span>
                                </Feed.Like>
                            )}
                        </Feed.Meta>
                    </Feed.Content>
                </FeedEvent>

                {openedDetail && <NotificationDetail item={item} read={read} />}
            </>
        )
    }

    const AdditionalFilters = () => {
        return types.map((item, index) => (
            <Label
                size="tiny"
                key={index}
                onClick={() => {
                    setActiveLabel(item)
                    setNotifications(
                        item === 'all'
                            ? newNotifications
                            : newNotifications.filter((itm) => itm.action_object_type === item)
                    )
                }}
                style={{
                    fontWeight: 'normal',
                    fontSize: '0.9rem',
                    marginTop: '0.5rem',
                    // padding: '0.9rem',
                    cursor: 'pointer',
                    border: 'none',
                    backgroundColor: activeLabel === item ? 'var(--dark)' : 'transparent',
                    color: activeLabel === item ? 'var(--light)' : 'var(--dark)',
                }}
            >
                {t(item)}
            </Label>
        ))
    }

    const panes = [
        {
            menuItem: (
                <MenuItem
                    key="unread"
                    onClick={() => setReadActive(false)}
                    style={{ fontWeight: 'bold', padding: '0.5rem', width: '50%' }}
                >
                    <div style={{ padding: 0, textAlign: 'center', width: '100%' }}>
                        {t('unread') + ' '}
                        <Label basic size="mini" style={{ marginLeft: '0.5rem', position: 'relative', top: '-0.1rem' }}>
                            {newNotifications?.length || 0}
                        </Label>
                    </div>
                </MenuItem>
            ),
            render: () => (
                <Tab.Pane style={{ height: '93%', padding: 0 }}>
                    <Feed style={{ margin: '0.3rem' }}>
                        {newNotifications?.length > 0 ? (
                            <>
                                <Grid>
                                    <Grid.Row columns={1} style={{ padding: 0, paddingBottom: '0.7rem' }}>
                                        <Grid.Column textAlign="left" style={{ paddingLeft: '0.5rem' }}>
                                            {!smallMenu && <AdditionalFilters />}
                                        </Grid.Column>

                                        {/* <Grid.Column textAlign="right">
                                            <div
                                                style={{
                                                    textAlign: 'right',
                                                    color: 'var(--primary)',
                                                    paddingBottom: '1rem',
                                                    paddingTop: '0.7rem',
                                                    fontSize: '0.8rem',
                                                }}
                                            >
                                                <span style={{ cursor: 'pointer' }}>{t('mark_all_as_read')}</span>
                                            </div>
                                        </Grid.Column> */}
                                    </Grid.Row>
                                </Grid>
                                <Divider style={{ margin: 0 }} />
                                {activeLabel === 'all'
                                    ? newNotifications?.map((item) => <Notification item={item} read={false} />)
                                    : notifications?.map((item) => <Notification item={item} read={false} />)}
                            </>
                        ) : (
                            <div style={{ padding: '1rem', textAlign: 'center' }}>{t('no_data')}</div>
                        )}
                    </Feed>
                </Tab.Pane>
            ),
        },
        {
            menuItem: (
                <MenuItem
                    key="read"
                    onClick={() => setReadActive(true)}
                    style={{ fontWeight: 'bold', padding: '1rem', width: '50%' }}
                >
                    <div style={{ padding: 0, textAlign: 'center', width: '100%' }}>{t('read')}</div>

                    {/* <Label>{oldNotifications?.length || 0}</Label> */}
                </MenuItem>
            ),
            render: () => (
                <SpinnerSegment loading={loading}>
                    {loading ? (
                        <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '8rem' }}>{t('loading')}</p>
                    ) : (
                        <>
                            <Tab.Pane style={{ height: '73%', padding: 0 }}>
                                <Feed style={{ margin: '0.3rem' }}>
                                    {oldNotifications?.length > 0 ? (
                                        <>
                                            {oldNotifications?.map((item) => (
                                                <Notification item={item} read={true} />
                                            ))}
                                        </>
                                    ) : (
                                        <div style={{ padding: '1rem', textAlign: 'center' }}>{t('no_data')}</div>
                                    )}
                                </Feed>
                            </Tab.Pane>
                            {oldNotifications?.length > 10 && (
                                <Paginator
                                    forcePage={page || 1}
                                    noLimit
                                    limit={limit}
                                    setLimit={setLimit}
                                    length={oldNotifications?.length || 0}
                                    onChange={() => getOldNotifications()}
                                />
                            )}
                        </>
                    )}
                </SpinnerSegment>
            ),
        },
    ]

    return (
        <SidebarPanel
            width={smallMenu ? '90%' : '600px'}
            trigger={
                <Menu.Item>
                    {newNotifications && newNotifications?.length > 0 ? (
                        <>
                            <Icon name="notifications-outline" onClick={() => setVisible(!visible)} />
                            <Label
                                size="tiny"
                                color="red"
                                circular
                                style={{ marginBottom: '1rem', marginLeft: 0, padding: '0.5rem' }}
                            >
                                {newNotifications?.length}
                            </Label>
                        </>
                    ) : (
                        <Icon name="notifications-outline" onClick={() => setVisible(!visible)} />
                    )}
                </Menu.Item>
            }
            content={
                <Container style={{ paddingTop: '0.5rem' }}>
                    {smallMenu ? (
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column textAlign="left">
                                    <Header as="h3" content={t('notifications')} style={{ paddingTop: '0.5rem' }} />
                                </Grid.Column>
                                <Grid.Column textAlign="right">
                                    <Icon
                                        name="close"
                                        size="1.3rem"
                                        onClick={() => setVisible(false)}
                                        style={{ color: 'var(--danger)', marginTop: '0.5rem' }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    ) : (
                        <Header as="h3" content={t('notifications')} style={{ paddingTop: '0.5rem' }} />
                    )}

                    <Divider style={{ margin: 0 }} />
                    <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
                </Container>
            }
        />
    )
}

export default Notifications
