import React, { useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
// components
import SuperField from '@components/forms/SuperField';

const PersonalNumber = ({ setData }) => {
    const { t } = useTranslation();
    const [personalNumber, setPersonalNumber] = useState("")

    useEffect( () => {
        setData(prevState => ({
            ...prevState,
            personal_number: personalNumber || null
        }))

    }, [personalNumber, setData])

    return (
        <SuperField as="input"
            label={ t('personal_number') }
            width="8"
            placeholder={ t('enter_personal_number') }
            value={ personalNumber }
            onChange={ (e) => setPersonalNumber(e.target.value) }
        />
    );
};

export default PersonalNumber;