import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { toast } from 'react-semantic-toasts'
// store
import { routes } from '@routes'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { tzDateTime } from '@helpers/dates'
// components
import Icon from '@components/Icon'
import { Form } from 'semantic-ui-react'
import AvatarList from '@components/AvatarList'
import SuperField from '@components/forms/SuperField'
import ListView from '@components/ListView'
import IssueDetail from './IssueDetail'
import LabelsList from '@components/lists/LabelsList'
import IssueCreateForm from '../forms/IssueCreateForm'
import CustomFormDivider from '@components/general/CustomFormDivider'

const IssueTableView = ({ project }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    const [isAll, setIsAll] = useState(false)
    const [forceModalOpen, setForceModalOpen] = useState(0)

    const onSubDelete = async (id, setSubData, setData, parentID) => {
        const request = await requests.del(API.PROJECT_ISSUES + id + '/')
        if (request.status === 204) {
            //setData((prev) => prev.filter((item) => item.id !== id))
            setData((prev) =>
                prev.map((item) => {
                    if (item.id === parentID) {
                        item.sub_issues.filter((subItem) => subItem.id !== id)
                    }
                    return item
                })
            )
            setSubData((prev) => prev.filter((item) => item.id !== id))
        }
    }

    const SubIssue = ({ data, setData, parentID }) => {
        return (
            <span>
                <ListView
                    as="table"
                    isSoftDeleteAllowed
                    key={data}
                    noContainer
                    externalData={data}
                    actionsCellWidth="2"
                    mBottom="0"
                    isRowToggleAllowed
                    rowToggleDisabled={(item) => true}
                    rowToggleContent={(item, setData, setTotal) => <div />}
                    actions={[
                        {
                            as: 'modal',
                            type: 'custom',
                            icon: 'add-outline',
                            name: t('create_subissue'),
                            modalSize: 'large',
                            isHidden: () => true,
                            modal: (item) => <IssueCreateForm projectParams={project} parent={item.id} />,
                        },
                        {
                            as: 'modal',
                            type: 'custom',
                            icon: 'eye-outline',
                            name: t('view_detail'),
                            modalSize: 'large',
                            customIconColor: 'var(--primary)',
                            forceModalOpen: forceModalOpen,
                            setForceModalOpen: setForceModalOpen,
                            modal: (item, setData) => (
                                <IssueDetail issue={item} setIssues={setData} project={project} />
                            ),
                        },
                        {
                            as: 'confirm',
                            type: 'custom',
                            icon: (item) => (item.state === 2 ? 'lock-closed' : 'lock-open-outline'),
                            name: (item) => (item.state === 2 ? t('open_issue') : t('close_issue')),
                            text: (item) => (item.state === 2 ? t('open_issue') : t('close_issue')),
                            customIconColor: 'var(-dark)',
                            onClick: async (item, setSubData) =>
                                changeSubState(
                                    item.id,
                                    item.state === 2 ? 1 : 2,
                                    setData,
                                    data.id,
                                    setSubData,
                                    item.sub_issues
                                ),
                        },
                        {
                            name: t('delete'),
                            type: 'delete',
                            as: 'delete',
                            text: t('delete'),
                            customDelete: (item, setSubData) =>
                                onSubDelete(item.id, setSubData, setData, parentID, item),
                            permissions: ['projects.c_delete_issues'],
                        },
                        // {
                        //     name: t('delete'),
                        //     type: 'delete',
                        //     as: 'delete',
                        //     text: t('delete'),
                        // },
                    ]}
                    // tableHeaders={[
                    //     { title: t('name'), orderBy: 'name' },
                    //     { title: t('labels') },
                    //     { title: t('due_date'), orderBy: 'due_date' },
                    //     { title: t('milestone'), orderBy: 'milestone_title' },
                    //     { title: t('responsible_persons') },
                    // ]}
                    renderCells={(issue, setData) => [
                        {
                            customWidth: 3,
                            content: (
                                <>
                                    <strong style={{ fontSize: '1.2rem' }}>{issue?.name}</strong>
                                    <span style={{ paddingLeft: '1rem', fontSize: '0.8rem', color: 'gray' }}>
                                        {issue?.sub_issues?.length > 0 &&
                                            issue.sub_issues.filter((issue) => issue.state === 2)?.length +
                                                ' / ' +
                                                issue.sub_issues.length +
                                                ' (' +
                                                getProgressPercent(issue.sub_issues) +
                                                ' %)'}
                                    </span>{' '}
                                    <br />
                                    <span style={{ opacity: 0.7 }}>
                                        <strong> # {issue?.number}</strong> {t('created_by')}{' '}
                                    </span>
                                    <Link
                                        style={{ opacity: 0.7 }}
                                        to={routes.EMPLYOEES_DETAIL + issue?.created_by?.profile_id}
                                        target="_blank"
                                    >
                                        <strong>{issue?.created_by?.name}</strong>
                                    </Link>
                                    <span style={{ opacity: 0.7 }}>
                                        {' '}
                                        {t('at')}{' '}
                                        <strong>{tzDateTime(issue?.created_on).format(dateFormat + ' HH:mm')}</strong>{' '}
                                    </span>
                                    {issue?.attachments?.length > 0 && (
                                        <>
                                            <span style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>•</span>
                                            <span style={{ fontSize: '1.2rem' }}>
                                                <Icon
                                                    name="attach-outline"
                                                    style={{ position: 'relative', top: '0.2rem' }}
                                                />{' '}
                                                {issue?.attachments?.length}
                                            </span>
                                        </>
                                    )}
                                </>
                            ),
                        },
                        {
                            content: (
                                <LabelsList
                                    key={issue.id}
                                    rowID={issue.id}
                                    labels={issue?.labels}
                                    project={project}
                                    projectPatch
                                    endpoint={API.PROJECT_ISSUES}
                                    endpointLabels={API.PROJECT_LABELS + `?project=${project}`}
                                    label_key="labels"
                                    setDataLabels={setData}
                                />
                            ),
                        },
                        { content: issue.due_date ? moment(issue.due_date).format(dateFormat) : '' },
                        { content: issue?.milestone?.name || '' },
                        {
                            content:
                                issue?.assigned?.length > 0 ? (
                                    <AvatarList
                                        size="25"
                                        allowAdditions
                                        alignDirection="start"
                                        persons={issue?.assigned?.map((item) => ({
                                            ID: item?.id,
                                            id: item?.id,
                                            name: item?.fullname,
                                            avatar: item?.profile_picture,
                                            displayName: item?.fullname,
                                        }))}
                                    />
                                ) : (
                                    ''
                                ),
                        },
                    ]}
                />
            </span>
        )
    }

    const getProgressPercent = (data) => {
        let total = data.length
        let completed = data.filter((item) => item.state === 2).length
        let percentage = 0

        if (total > 0) {
            percentage = (completed / total) * 100
        }

        percentage = Math.round(percentage)

        return percentage
    }

    const changeSubState = async (id, value, setData, subID, setSubData, subIssues) => {
        const canBeClosed = subIssues && subIssues.every((subIssue) => subIssue.state === 2)
        if (canBeClosed) {
            const request = await requests.patch(API.PROJECT_ISSUES + id + '/', { state: value })

            if (request.status === 200) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('state_changed'),
                    animation: 'pulse',
                    time: 2000,
                })
                setSubData((prev) =>
                    prev.map((item) => {
                        if (item.id === id) {
                            item.state = value
                        }
                        return item
                    })
                )
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === subID) {
                            item.sub_issues.map((subItem) => {
                                if (subItem.id === id) subItem.state = value
                            })
                        }
                        return item
                    })
                )
            }
        } else {
            toast({
                type: 'error',
                icon: 'warning circle',
                title: t('not_every_sub_issue_is_closed'),
                animation: 'pulse',
                time: 2000,
            })
        }
    }

    const changeState = async (id, value, setData, setTotal, subIssues) => {
        const canBeClosed = subIssues && subIssues.every((subIssue) => subIssue.state === 2)
        if (canBeClosed) {
            const request = await requests.patch(API.PROJECT_ISSUES + id + '/', { state: value })

            if (request.status === 200) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('state_changed'),
                    animation: 'pulse',
                    time: 2000,
                })

                if (isAll) {
                    setData((prev) =>
                        prev.map((item) => {
                            if (item.id === id) {
                                item.state = value
                            }
                            return item
                        })
                    )
                } else {
                    setData((prev) => prev.filter((data) => data.id !== id))
                    setTotal((prev) => prev - 1)
                }
            }
        } else {
            toast({
                type: 'error',
                icon: 'warning circle',
                title: t('not_every_sub_issue_is_closed'),
                animation: 'pulse',
                time: 2000,
            })
        }
    }

    return (
        <ListView
            as="table"
            allowSearch
            // noContainer
            isSoftDeleteAllowed
            endpoint={API.PROJECT_ISSUES}
            query={`&project=${project}&is_template=false`}
            actionsCellWidth="2"
            initialFilters={{
                ordering: ['-created_on'],
                state: 1,
                milestone: '',
                stage: '',
                group: '',
                labels: '',
                only_current_user: '',
                assigned: '',
                start_date: '',
                end_date: '',
            }}
            isRowToggleAllowed
            rowToggleContent={(item, setData, setTotal) => (
                <SubIssue data={item.sub_issues} setData={setData} setTotal={setTotal} parentID={item.id} />
            )}
            rowToggleDisabled={(item) => item?.sub_issues?.length === 0}
            onResponse={(response, filters) => {
                if (filters.state === '') setIsAll(true)
                if (filters.state !== '') setIsAll(false)
            }}
            listAdditionActions={() => [
                {
                    as: 'filter',
                    index: 0,
                    name: t('open'),
                    filters: {
                        state: 1,
                    },
                },
                {
                    as: 'filter',
                    index: 1,
                    name: t('closed'),
                    filters: {
                        state: 2,
                    },
                },
                {
                    as: 'filter',
                    index: 2,
                    name: t('all'),
                    filters: {
                        state_all: true,
                    },
                },
            ]}
            renderFilterFields={(filters, setFilters) => (
                <>
                    <CustomFormDivider
                        content={
                            <Form.Group widths="equal">
                                <SuperField
                                    as="datepicker"
                                    label={t('start_date')}
                                    value={filters.startDate}
                                    onChange={(e, { value }) => setFilters({ ...filters, startDate: value })}
                                />
                                <SuperField
                                    as="datepicker"
                                    label={t('end_date')}
                                    value={filters.endDate}
                                    onChange={(e, { value }) => setFilters({ ...filters, endDate: value })}
                                />
                            </Form.Group>
                        }
                    />
                    <SuperField
                        as="choice"
                        search
                        text="name"
                        label={t('milestone')}
                        value={filters.milestone}
                        onChange={(e, { value }) => setFilters({ ...filters, milestone: value })}
                        endpoint={API.PROJECT_MILESTONES + `?project=${project}`}
                    />

                    <Form.Group widths="equal">
                        <SuperField
                            as="choice"
                            search
                            text="title"
                            label={t('stage')}
                            value={filters.stage}
                            onChange={(e, { value }) => setFilters({ ...filters, stage: value })}
                            endpoint={API.PROJECT_STAGES + `?project=${project}`}
                        />
                        <SuperField
                            as="choice"
                            search
                            text="title"
                            label={t('group')}
                            value={filters.group}
                            onChange={(e, { value }) => setFilters({ ...filters, group: value })}
                            endpoint={API.PROJECT_GROUPS + `?project=${project}`}
                        />
                    </Form.Group>

                    <SuperField
                        as="choice"
                        search
                        multiple
                        value={filters.labels}
                        label={t('labels')}
                        endpoint={API.PROJECT_LABELS}
                        text="name"
                        onChange={(e, { value }) =>
                            setFilters({
                                ...filters,
                                labels: value,
                            })
                        }
                    />
                    <CustomFormDivider
                        header={t('assigned_users')}
                        divider={true}
                        description={t('my_issues_message')}
                        content={
                            <>
                                <SuperField
                                    as="checkbox"
                                    label={t('my_issues')}
                                    width={10}
                                    checked={filters.only_current_user === true}
                                    onChange={() =>
                                        setFilters({
                                            ...filters,
                                            only_current_user: filters.only_current_user ? '' : true,
                                        })
                                    }
                                />
                            </>
                        }
                    />
                    <SuperField
                        as="choice"
                        search
                        multiple
                        value={filters.assigned}
                        label={t('assigned')}
                        endpoint={API.EMPLOYEES + '?only_basic_info=true'}
                        text="fullname"
                        onChange={(e, { value }) =>
                            setFilters({
                                ...filters,
                                assigned: value,
                            })
                        }
                    />
                </>
            )}
            actions={[
                {
                    as: 'modal',
                    type: 'add',
                    name: t('create_issue'),
                    modalSize: 'large',
                    modal: <IssueCreateForm projectParams={project} />,
                },
                {
                    as: 'modal',
                    type: 'custom',
                    icon: 'add-outline',
                    name: t('create_subissue'),
                    modalSize: 'large',
                    modal: (item) => <IssueCreateForm projectParams={project} parent={item.id} />,
                },
                {
                    as: 'modal',
                    type: 'custom',
                    icon: 'eye-outline',
                    name: t('view_detail'),
                    modalSize: 'large',
                    customIconColor: 'var(--primary)',
                    forceModalOpen: forceModalOpen,
                    setForceModalOpen: setForceModalOpen,
                    modal: (item, setData) => <IssueDetail issue={item} setIssues={setData} project={project} />,
                },
                {
                    as: 'confirm',
                    type: 'custom',
                    icon: (item) => (item.state === 2 ? 'lock-closed' : 'lock-open-outline'),
                    name: (item) => (item.state === 2 ? t('open_issue') : t('close_issue')),
                    text: (item) => (item.state === 2 ? t('open_issue') : t('close_issue')),
                    customIconColor: 'var(-dark)',
                    onClick: async (item, setData, setTotal) =>
                        changeState(item.id, item.state === 2 ? 1 : 2, setData, setTotal, item.sub_issues),
                },
                {
                    name: t('delete'),
                    type: 'delete',
                    as: 'delete',
                    text: t('delete'),
                },
            ]}
            tableHeaders={[
                { title: t('name'), orderBy: 'name' },
                //{ title: t('issues') },
                { title: t('labels') },
                { title: t('due_date'), orderBy: 'due_date' },
                { title: t('milestone'), orderBy: 'milestone_title' },
                { title: t('responsible_persons') },
            ]}
            renderCells={(issue, setData) => [
                {
                    customWidth: 3,
                    content: (
                        <>
                            <strong style={{ fontSize: '1.2rem' }}>{issue?.name}</strong>
                            <span style={{ paddingLeft: '1rem', fontSize: '0.8rem' }}>
                                {issue?.sub_issues?.length > 0 &&
                                    issue.sub_issues.filter((issue) => issue.state === 2)?.length +
                                        ' / ' +
                                        issue.sub_issues.length}
                            </span>{' '}
                            <br />
                            <span style={{ opacity: 0.7 }}>
                                <strong> # {issue?.number}</strong> {t('created_by')}{' '}
                            </span>
                            <Link
                                style={{ opacity: 0.7 }}
                                to={routes.EMPLYOEES_DETAIL + issue?.created_by?.profile_id}
                                target="_blank"
                            >
                                <strong>{issue?.created_by?.name}</strong>
                            </Link>
                            <span style={{ opacity: 0.7 }}>
                                {' '}
                                {t('at')} <strong>{tzDateTime(issue?.created_on).format(dateFormat + ' HH:mm')}</strong>{' '}
                            </span>
                            {issue?.attachments?.length > 0 && (
                                <>
                                    <span style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>•</span>
                                    <span style={{ fontSize: '1.2rem' }}>
                                        <Icon name="attach-outline" style={{ position: 'relative', top: '0.2rem' }} />{' '}
                                        {issue.attachments.length}
                                    </span>
                                </>
                            )}
                        </>
                    ),
                },
                {
                    width: 2,
                    content: (
                        <LabelsList
                            key={issue.id}
                            rowID={issue.id}
                            labels={issue?.labels}
                            project={project}
                            projectPatch
                            endpoint={API.PROJECT_ISSUES}
                            endpointLabels={API.PROJECT_LABELS + `?project=${project}`}
                            label_key="labels"
                            setDataLabels={setData}
                        />
                    ),
                },
                { width: 1, content: issue.due_date ? moment(issue.due_date).format(dateFormat) : '' },
                { width: 1, content: issue?.milestone?.name },
                {
                    width: 2,
                    content: issue?.assigned?.length > 0 && (
                        <AvatarList
                            size="25"
                            allowAdditions
                            alignDirection="start"
                            persons={issue?.assigned?.map((item) => ({
                                ID: item?.id,
                                id: item?.id,
                                name: item?.fullname,
                                avatar: item?.profile_picture,
                                displayName: item?.fullname,
                            }))}
                        />
                    ),
                },
            ]}
        />
    )
}

export default IssueTableView
