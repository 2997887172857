import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import ListView from '@components/ListView';
import CanView from '@components/perms/CanView';
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const Warehouses = () => {
    const { t } = useTranslation()

    return (
        <CanView permissions={['assets.c_view_warehouses']} redirect>
            <div style={{ marginTop: "1rem" }}></div>
            <ListView as="table"
                allowSearch
                isSoftDeleteAllowed
                endpoint={API.ASSET_WAREHOUSES}
                tableHeaders={[
                    { title: t('title'), orderBy: "title" },
                    { title: t('responsible_person') },
                    { title: t('unit') },
                ]}
                renderCells={(warehouse) => ([
                    { content: warehouse.title },
                    { content: 
                        warehouse.responsible_person?.id 
                            ? <Link className="ref-link" to={routes.EMPLYOEES_DETAIL + warehouse?.responsible_person?.id} target="_blank">{ warehouse?.responsible_person?.fullname }</Link>
                            : "--"
                    },
                    { content: warehouse.unit?.name || ""},
                ])}
                actions={[
                    {
                        as: "modal",
                        type: "add",
                        modalSize: "tiny",
                        name: t('create_warehouse_location'),
                        modal: <WarehouseForm/>,
                        permissions: ['assets.c_manage_warehouses'],
                    },
                    {
                        as: "modal",
                        type: "edit",
                        name: t('edit'),
                        modalSize: "tiny",
                        permissions: ['assets.c_manage_warehouses'],
                        modal: <WarehouseForm/>
                    },
                    {
                        as: "delete",
                        name: t('delete'),
                        type: "delete",
                        text: t('delete_warehouse_location'),
                        permissions: ['assets.c_manage_warehouses'],
                    },
                ]}
            />
        </CanView>
    );
};




const WarehouseForm = ({ onClose, record, setData, setTotal }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [formData, setFormData] = useState({
        title: record?.title ? record.title : "",
        unit: record?.unit ? record.unit?.id : "",
        responsible_person: record?.responsible_person ? record?.responsible_person?.id : null,
        address: null // TODO: implement custom address
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        if( record?.id ){ // update
            const request = await requests.patch(API.ASSET_WAREHOUSES + record.id + "/", {
                title: formData.title,
                unit: formData.unit || null,
                responsible_person: formData.responsible_person || null,
            })
            if( request.status === 200 ){
                setData(prev => prev.filter(warehouse => {
                    if( warehouse.id === record.id ){
                        warehouse.title = request.response.title;
                        warehouse.unit = request.response.unit;
                        warehouse.address = request.response.address;
                        warehouse.responsible_person = request.response.responsible_person;
                    }
                    
                    return warehouse;
                }))

                onClose()
            }
        } else { // create
            const request = await requests.post(API.ASSET_WAREHOUSES, {
                title: formData.title,
                unit: formData.unit || null,
                responsible_person: formData.responsible_person || null,
            })
            if(request.status === 201){
                setData(prev => [...prev, request.response])
                setTotal(count => count + 1)
                onClose()
            }
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="input"
                required
                autoFocus
                label={t('title')}
                value={formData.title}
                onChange={ (e, { value }) => setFormData({
                    ...formData,
                    title: value
                }) }
            />
            <SuperField as="choice"
                search
                label={ t('responsible_person') }
                endpoint={API.EMPLOYEES + "?query={id, fullname}"}
                text="fullname"
                value={formData.responsible_person}
                onChange={ (e, { value }) => setFormData({
                    ...formData,
                    responsible_person: value
                })}
            />

            <SuperField as="choice"
                label={t('unit')}
                search
                endpoint={API.UNITS + "?query={id, name}&only_from_active_company=true"}
                text="name"
                value={formData.unit}
                onChange={ (e, { value }) => setFormData({
                    ...formData,
                    unit: value
                }) }
            />

            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || formData.title === ""}
                    text={t('save')} />
            </Form.Field>
        </Form>
    );
};

export default Warehouses;