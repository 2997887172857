import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
// components
import SuperField from '@components/forms/SuperField';
import { Segment, Header, Grid } from 'semantic-ui-react';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import BarGraph from '@components/graphs/BarGraph';

const PublicationSources = ({ room }) => {
    const { t } = useTranslation()
    const colors = useSelector(state => state.global_pref.colors)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState({
        created_on_after: "",
        created_on_before: "",
        is_rejected: false,
        publishers: [1, 2, 3, 4, 5, 6],
        recruitment: room ? room : ""
    })

    const publishers = {
        1: "Profesia.sk",
        2: "Profesia.cz",
        3: "Kariera.sk",
        4: "Rabota.ua",
        5: "Work.ua",
        6: "Proffesion.hu",
    }

    useEffect(() => {
        async function fetchHiringStats() {
            if (filters.recruitment !== "") {
                setLoading(true)
                let labels = []
                let dataCount = []

                for (let publisher of filters.publishers) {
                    let request = await getRequest(`${API.PUBLISHERS_STATS}?recruitment=${filters.recruitment}&publishers=${publisher}&created_on_after=${filters.created_on_after}&created_on_before=${filters.created_on_before}&is_rejected=${filters.is_rejected ? 'true' : 'false'}`)
                    if (request.status === 200) {
                        labels.push(publishers[publisher])
                        dataCount.push(request.response.responses_count)
                    }
                }

                setData({
                    labels: labels,
                    datasets: [{
                        label: "Publication count",
                        data: dataCount,
                        backgroundColor: colors.primary
                    }]
                })

                setLoading(false)
            }
        }
        fetchHiringStats()
        // eslint-disable-next-line
    }, [filters])

    return (
        <>
            <FlexRow padding="1rem">
                <FlexItem>
                    <Header as="h2" textAlign="left" content={ t('publication_count') } />
                </FlexItem>
                <FlexItem textAlign="right" basis="50%">
                    <SuperField
                        as='datepicker'
                        placeholder={ t('date_from') }
                        value={filters.created_on_after}
                        onChange={(e, { value }) => setFilters({ ...filters, created_on_after: value })}
                    />
                </FlexItem>
                <FlexItem textAlign="right" basis="50%">
                    <SuperField
                        as='datepicker'
                        placeholder={ t('date_to') }
                        value={filters.created_on_before}
                        onChange={(e, { value }) => setFilters({ ...filters, created_on_before: value })}
                    />
                </FlexItem>
                {/* <FlexItem>
                    <SuperField
                        as='checkbox'
                        label={"Is rejected"}
                        checked={filters.is_rejected}
                        onChange={() => setFilters({ ...filters, is_rejected: !filters.is_rejected })}
                    />
                </FlexItem> */}
                <FlexItem textAlign="right" basis="50%">
                    <SuperField as="choice"
                        search
                        endpoint={API.HIRING_ROOM + "?query={id, name}"}
                        text={"name"}
                        value={parseInt(filters.recruitment)}
                        onChange={(e, { value }) => setFilters({ ...filters, recruitment: value })}
                    />
                </FlexItem>
            </FlexRow>

            {filters.recruitment === "" &&
                <>
                    <Grid centered style={{ marginTop: "1rem" }}>
                        <Header as="h2" content={ t('select_hiring_room_first') } textAlign="center" />
                    </Grid>
                </>
            }
            <Segment basic loading={loading}>
                {!loading && filters.recruitment !== "" &&
                    <BarGraph data={data} />
                }
            </Segment>
        </>
    );
};

export default PublicationSources;