import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import NonFieldErrors from '@components/NonFieldErrors';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const TransportFulfillmentForm = ({ order, setData, onClose }) => {
    const { t } = useTranslation()
    const fulfillment = order.fulfillment

    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState([])
    const [form, setForm] = useState({
        delivery_date: fulfillment?.delivery_date || moment().format('YYYY-MM-DD'),
        note: fulfillment?.note || ""
    })

    const onSubmit = async () => {
        setProcessing(true)
        setErrors([])

        let request = null
        if (order.fulfillment?.id === undefined) {
            request = await requests.post(API.ORDERS + "fulfillments/", {
                order: order.id,
                resourcetype: "TransportFulfillment",
                delivery_date: form.delivery_date,
                note: form.note
            })
        } else {
            request = await requests.patch(API.ORDERS + "fulfillments/" + order.fulfillment?.id + "/", {
                // order: order.id,
                resourcetype: "TransportFulfillment",
                delivery_date: form.delivery_date,
                note: form.note
            })
        }

        if (request?.status === 400) setErrors(request.response)
        if ([200, 201].includes(request?.status)) {
            // patch order details
            const requestOrder = await requests.patch(API.ORDERS + order.id + "/", {
                order_status: 4,
                resourcetype: "TransportOrder",
            })
            if(requestOrder.status === 200) {
                setData(prev => prev.map(item => {
                    if (item.id === order.id) { 
                        item = requestOrder.response
                    }
                    return item
                }))
                onClose()
            }
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={onSubmit}>
            <NonFieldErrors errors={errors}/>
            
            <SuperField
                required as="datepicker"
                label={t('date_of_unload')}
                value={form.delivery_date}
                error={errors?.delivery_date?.[0] || false}
                onChange={(e, { value }) => setForm(prev => ({...prev, delivery_date: value}))}    
                />
            <SuperField as="textarea"
                label={t('note')}
                value={form.note}
                error={errors?.note?.[0] || false}
                onChange={(e, { value }) => setForm(prev => ({...prev, note: value}))}      
            />

            <Divider/>
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    loading={processing}
                    disabled={processing || form.name === "" || form.account === "" || form.owner === "" || form.price === "" || form.currency === "" || form.loading_date === ""}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default TransportFulfillmentForm;