import React from 'react';

const KanbanView = ({ issues }) => {
    return (
        <div style={{ textAlign: "center"}}>
           <h2>We are working on KanbanView</h2>
        </div>
    );
};

export default KanbanView;