import React, { useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
// components
import SuperFieldSet from '@components/forms/SuperFieldSet';
//components
import { Form } from 'semantic-ui-react';
import ChoiceField from '@components/forms/common/ChoiceField';

const TemporaryAddress = ({ setData, countries }) => {
    const { t } = useTranslation();

    const [address, setAddress] = useState({
        street: "",
        city: "",
        postcode: "",
        country: "",
        state: "",
        address_line: "",
    })

    useEffect( () => {
        let temporaryAddress = null;
        if( address.street !== "" && address.city !== "" && address.postcode !== "" ){
            temporaryAddress = address
        }

        setData(prevState => ({
            ...prevState,
            temporary_address: temporaryAddress
        }))

    }, [address, setData])

    return (
        <SuperFieldSet legend={ t('temp') }>
            <div>
                { countries !== undefined 
                    ?
                        <ChoiceField
                            search
                            label={t('country')}
                            customOptions={countries}
                            value={address.country}
                            onChange={(e, data) => setAddress({
                                ...address,
                                country: data.value,
                            })}
                        />
                    : 
                        <ChoiceField
                            search
                            type="countries"
                            label={t('country')}
                            value={address.country}
                            onChange={(e, data) => setAddress({
                                ...address,
                                country: data.value,
                            })}
                        />
                }

                <Form.Group widths='equal'>
                    <Form.Input
                        label={t('street')}
                        placeholder={t('street')}
                        value={address.street}
                        onChange={(e) => setAddress({
                            ...address,
                            street: e.target.value
                        })
                        }
                    />

                    <Form.Input
                        fluid
                        label={t('address_line')}
                        placeholder={t('address_line')}
                        value={address.address_line}
                        onChange={(e) => setAddress({
                            ...address,
                            address_line: e.target.value
                        })
                        }
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Input
                        width="4"
                        label={t('postcode')}
                        placeholder={t('postcode')}
                        value={address.postcode}
                        onChange={(e) => setAddress({
                            ...address,
                            postcode: e.target.value
                        })
                        }
                    />

                    <Form.Input
                        width="6"
                        label={t('city')}
                        placeholder={t('city')}
                        value={address.city}
                        onChange={(e) => setAddress({
                            ...address,
                            city: e.target.value
                        })
                        }
                    />

                    <Form.Input
                        width="6"
                        label={t('region')}
                        value={address.state}
                        onChange={(e) => setAddress({
                            ...address,
                            state: e.target.value
                        })}
                    />

                </Form.Group>
            </div>
        </SuperFieldSet>
    );
};

export default TemporaryAddress;