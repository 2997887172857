import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// components
import { Button, Form, Header, Divider, List, Icon } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import SuperFieldSet from '@components/forms/SuperFieldSet';

const Educations = ({ setData, educationTypes }) => {
    const { t } = useTranslation();

    const educationInit = {
        name: "",
        type: "",
        typeDisplay: ""
    }

    const [educations, setEducations] = useState([])
    const [education, setEducation] = useState(educationInit)

    const [isHovered, setIsHovered] = useState(false)
    const [isFormHovered, setIsFormHovered] = useState(false)
    const [isFormFocused, setIsFormFocused] = useState(false)

    useEffect( () => {
        let educationList = []
        if( educations.length > 0 ){
            for (let i = 0; i < educations.length; i++){
                educationList.push({
                    name: educations[i].name, education_type: parseInt(educations[i].type),
                })
            }
        }
        
        let educationsArray = {
            create: educationList
        }

        setData(prevState => ({
            ...prevState,
            educations: educationsArray
        }))

    }, [educations, setData])

    const addEducation = () => {
        if( education.name !== "" && education.type !== "" ){
            let educationData = education
            educationData.typeDisplay = educationTypes.find(item => item.value === education.type)?.text || "--"

            setEducations([...educations, educationData])
            setEducation(educationInit)
        }
    }

    return (
        <SuperFieldSet legend={ t('educations') } onClick={() => setIsFormHovered(true)} onMouseOver={() => setIsFormHovered(true)} onMouseLeave={() => setIsFormHovered(false)} >
            {/* Generate List of added educations */}
            {
                educations.length > 0
                ?
                <List selection verticalAlign='middle'>
                    { educations.map( (education, index) => (
                        <List.Item key={index} style={{ padding: "1rem" }} onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                            <List.Icon name='graduation cap' size='large' verticalAlign='middle' />
                            <List.Content>
                                <List.Content verticalAlign='middle' floated='right'>
                                    <Icon
                                        size="large"
                                        name="close"
                                        style={{
                                            display: isHovered ? "block" : "none",
                                            color: "var(--danger)",
                                            marginTop: "0.4rem"
                                        }}
                                        onClick={ () => setEducations(educations.filter( (item, listIndex) => listIndex !== index )) }
                                    />
                                </List.Content>
                                <List.Header>{ education.name }</List.Header>
                                <List.Description>{ education.typeDisplay }</List.Description>
                            </List.Content>
                        </List.Item>
                    )) }
                </List>
                :
                <Header
                    textAlign="center"
                    as="h4"
                    content={ t('mouse_or_click_educations') }
                    style={{
                        display: (isFormHovered && educations.length === 0) || isFormFocused ? "none" : "block",
                        opacity: ".7"
                    }}
                />
            }

            {/* Create simple form to add education */}
            <div style={{ display: isFormHovered || isFormFocused ? "block" : "none" }}>
                <Form.Group widths="equal">
                    <SuperField as="input"
                        label={ t('name') }
                        value={education.name}
                        onChange={(e) => setEducation({
                            ...education,
                            name: e.target.value
                        })}
                        onClick={ () => setIsFormFocused(true)}
                    />
                    <SuperField as="choice"
                        label={ t('type') }
                        // type="education_type_choices"
                        customOptions={educationTypes}
                        value={education.type}
                        onChange={(e, { value }) => setEducation({
                            ...education,
                            type: value
                        })}
                        onClick={ () => setIsFormFocused(true)}
                    />
                </Form.Group>
                <Divider />
                <Button.Group>
                    <Button
                        primary
                        size="small"
                        type="button"
                        content={ t("add") }
                        disabled={ education.name === "" || education.type === "" }
                        onClick={(e) => addEducation() }
                    />
                    <Button
                        style={{ marginLeft: "0.2rem" }}
                        size="small"
                        type="button"
                        content={ t("close") }
                        onClick={(e) => {
                            setIsFormHovered(false)
                            setIsFormFocused(false)
                        }}
                    />
                </Button.Group>
            </div>
        </SuperFieldSet>

    );
};

export default Educations;