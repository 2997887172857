import React from 'react';
import { useTranslation } from "react-i18next"
// store
import { /*isEmpty,*/ isEmptyErrorList } from '@helpers/validation';
// components
import { Button, Divider, Form, Icon } from 'semantic-ui-react';
import DateField from '@components/forms/common/DateField'
import ChoiceField from '@components/forms/common/ChoiceField';

const BirthInformationView = ({ visible, birthInfo, setBirthInfo, setView }) => {
    const { t } = useTranslation();

    return (
        <div>
            {visible &&
                <>
                    <Form.Group widths={2}>
                        <DateField
                            required
                            label={t("date_of_birth")}
                            value={birthInfo.dateOfBirth}
                            onChange={(e, data) => setBirthInfo({
                                ...birthInfo,
                                dateOfBirth: data.value
                            })}
                        />

                        <Form.Field>
                            <Form.Input
                                required
                                label={t("place_of_birth")}
                                placeholder={t("place_of_birth")}
                                value={birthInfo.placeOfBirth}
                                onChange={(e) => setBirthInfo({
                                    ...birthInfo,
                                    placeOfBirth: e.target.value
                                })}
                            />
                        </Form.Field>
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <ChoiceField
                            required
                            search
                            type="nationalities"
                            label={t("nationality")}
                            value={birthInfo.nationality}
                            onChange={(e, data) => setBirthInfo({
                                ...birthInfo,
                                nationality: data.value,
                                citizenship: data.value,
                                countryOfBirth: data.value
                            })}
                        />

                        <ChoiceField
                            required
                            search
                            type="citizenship"
                            label={t("citizenship")}
                            value={birthInfo.citizenship}
                            onChange={(e, data) => setBirthInfo({
                                ...birthInfo,
                                citizenship: data.value
                            })}
                        />

                        <ChoiceField
                            required
                            search
                            type="countries"
                            label={t("country_of_birth")}
                            value={birthInfo.countryOfBirth}
                            onChange={(e, data) => setBirthInfo({
                                ...birthInfo,
                                countryOfBirth: data.value
                            })}
                        />
                    </Form.Group>

                    <Divider style={{ marginTop: "2rem", marginBottom: "2rem" }} horizontal>
                        {t("add_info")}
                    </Divider>

                    <Form.Group widths='equal'>
                        <Form.Input
                            label={t("name_at_birth")}
                            placeholder={t("name_at_birth")}
                            value={birthInfo.nameAtBirth}
                            onChange={(e) => setBirthInfo({
                                ...birthInfo,
                                nameAtBirth: e.target.value
                            })}
                        />
                        <Form.Input
                            label={t("previous_surnames")}
                            placeholder={t("previous_surnames")}
                            value={birthInfo.allPreviousSurnames}
                            onChange={(e) => setBirthInfo({
                                ...birthInfo,
                                allPreviousSurnames: e.target.value
                            })}
                        />

                    </Form.Group>

                    <Divider />

                    <Button.Group floated="right">
                        <Button
                            type="button"
                            style={{ border: "1px solid var(--light-grey)" }}
                            onClick={() => setView(1)}>
                            <Icon name="arrow left" /> {t("back")}
                        </Button>
                        <Button
                            type="button"
                            onClick={() => setView(3)}
                            primary
                            disabled={!isEmptyErrorList([
                                birthInfo.dateOfBirth,
                                birthInfo.placeOfBirth,
                                birthInfo.nationality,
                                birthInfo.citizenship,
                                birthInfo.countryOfBirth,
                            ])}
                        >
                            {t("next")}  <Icon name="arrow right" />
                        </Button>
                    </Button.Group>
                </>
            }
        </div>
    );
};

export default BirthInformationView;