import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
// store
import { getDates, calculateTime } from '@helpers/dates'
// components
import { Form, Divider, Message } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const BulkShiftForm = ({ onClose, shifts, config, records, setRecords, selectedRows, setSelectedRows }) => {
    const { t } = useTranslation()
    const planner_record_types = useSelector((state) => state.choices?.attendance_record_planner_types)

    const firstDay = config.dates[0]?.date
    const lastDay = config.dates[config.dates.length - 1]?.date

    const [processing, setProcessing] = useState(false)
    const [keepExistingRecords, setKeepExistingRecords] = useState(true)
    const [excludeSundays, setExcludeSundays] = useState(false)
    const [excludeSaturdays, setExcludeSaturdays] = useState(false)
    const [excludeHolidays, setExcludeHolidays] = useState(false)
    const [form, setForm] = useState({
        date_from: firstDay || '',
        date_to: lastDay || '',
        shift: shifts.length === 1 ? shifts[0].id : '',
    })

    const handleBreakDeduction = (time_from, time_to, shift) => {
        let duration = calculateTime(time_from, time_to, 'minutes')
        if (shift?.pause_length !== '' && parseFloat(shift?.pause_length) > 0) {
            duration = duration - parseFloat(shift?.pause_length || 0)
        }

        if (shift?.second_pause_length !== '' && parseFloat(shift?.second_pause_length) > 0) {
            duration = duration - parseFloat(shift?.second_pause_length || 0)
        }

        return duration > 0 ? parseFloat(duration / 60) : 0
    }

    const handleSubmit = () => {
        setProcessing(true)
        const shift = shifts.find((item) => item.id === form.shift)

        // loop over all selected days (get range from date_from - date_to)
        const days = getDates(moment(form.date_from), moment(form.date_to))
        const existingRecords = records.slice()

        for (let i = 0; i < days.length; i++) {
            const day = config.dates.find((item) => item.date === days[i])

            if (excludeSundays && day.isSunday) continue
            if (excludeSaturdays && day.isSaturday) continue
            if (excludeHolidays && day.holiday !== null) continue

            for (let y = 0; y < selectedRows.length; y++) {
                const duration = handleBreakDeduction(shift?.time_from, shift?.time_to, shift)
                const newRecord = {
                    id: 0,
                    is_deleted: false,
                    date: day.date,
                    week: day.week,
                    contract: selectedRows[y].id,
                    employee: selectedRows[y].profile_id,
                    type: 3,
                    shift: shift?.id || null,
                    color: shift?.color || '#000000',
                    type_display: planner_record_types?.[3] || null,
                    time_from: shift?.time_from ? shift?.time_from : null,
                    time_to: shift?.time_to ? shift?.time_to : null,
                    duration: parseFloat(duration),
                    duration_display: duration.toString().replace('.00', ''),
                }
                const recordIndex = existingRecords.findIndex(
                    (item) =>
                        item.date === day.date && item.contract === selectedRows[y].id && item.is_deleted === false
                )
                if (keepExistingRecords) {
                    if (recordIndex === -1) {
                        // add new object
                        existingRecords.push(newRecord)
                    }
                } else {
                    if (recordIndex !== -1) {
                        // If 'id' exists and has a number greater than zero
                        if (existingRecords[recordIndex].id && existingRecords[recordIndex].id > 0) {
                            // Set 'is_deleted' to true instead of removing the object
                            existingRecords[recordIndex].is_deleted = true
                        } else {
                            // Remove the object if 'id' is undefined or not greater than zero
                            existingRecords.splice(recordIndex, 1)
                        }
                    }

                    // add new object
                    existingRecords.push(newRecord)
                }
            }
        }

        setRecords(existingRecords)
        setSelectedRows([])
        onClose()
        setProcessing(false)
    }

    const isFormValid = () => {
        if (form.date_from === '') return false
        if (form.date_to === '') return false
        if (form.shift === '') return false
        if (moment(form.date_from).isSameOrAfter(form.date_to)) return false
        if (!moment(form.date_from).isSameOrBefore(form.date_to)) return false
        return true
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                info
                visible
                content={
                    <>
                        <strong> {t('select_date_range_to_apply_work_schedule')} </strong>
                    </>
                }
            />
            <Form.Group widths="equal">
                <SuperField
                    as="datepicker"
                    required
                    label={t('date_from')}
                    value={form.date_from}
                    maxDate={moment(lastDay)}
                    minDate={moment(firstDay)}
                    error={moment(form.date_from).isSameOrAfter(form.date_to) ? t('invalid_date_range') : false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, date_from: value }))}
                />
                <SuperField
                    as="datepicker"
                    required
                    label={t('date_to')}
                    value={form.date_to}
                    maxDate={moment(lastDay)}
                    minDate={moment(firstDay)}
                    error={!moment(form.date_from).isSameOrBefore(form.date_to) ? t('invalid_date_range') : false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, date_to: value }))}
                />
            </Form.Group>

            <SuperField
                as="choice"
                search
                required
                label={t('work_schedule')}
                value={form.shift}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, shift: value }))}
                customOptions={shifts.map((shift) => ({ key: shift.id, value: shift.id, text: shift.title }))}
            />

            <SuperField
                as="checkbox"
                help={t('if_not_checked_then_it_will_replace_existing_records')}
                label={t('keep_existing_records')}
                checked={keepExistingRecords}
                onChange={() => setKeepExistingRecords(!keepExistingRecords)}
            />
            <SuperField
                as="checkbox"
                label={t('exclude_holidays')}
                checked={excludeHolidays}
                onChange={() => setExcludeHolidays(!excludeHolidays)}
            />
            <SuperField
                as="checkbox"
                label={t('exclude_saturdays')}
                checked={excludeSaturdays}
                onChange={() => setExcludeSaturdays(!excludeSaturdays)}
            />
            <SuperField
                as="checkbox"
                label={t('exclude_sundays')}
                checked={excludeSundays}
                onChange={() => setExcludeSundays(!excludeSundays)}
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit loading={processing} disabled={processing || !isFormValid()} />
            </Form.Field>
        </Form>
    )
}

export default BulkShiftForm
