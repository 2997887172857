import React, { useState } from "react";
import { useTranslation } from "react-i18next";
//components
import Icon from '@components/Icon';
import { Step, Grid, Button } from "semantic-ui-react";
import MyIcon from "./MyIcon";
import CandidateHeader from "./CandidateHeader";
import StageComponent from "./StageComponent";
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { MoveStageForm } from "../forms/MoveStageForm";
import CanView from '@components/perms/CanView';

const Pipeline = ({ data, rejected, setRejected, blacklisted, setBlacklisted, setCandidate, room, id, idRoom }) => {
    const { t } = useTranslation();
    const [currentView, setCurrentView] = useState(data.current_stage.id);
    const [currentStage, setCurrentStage] = useState(data.current_stage.id);
    const stages = room.archived_recruitment_stages;
    const count = room.archived_recruitment_stages && room.archived_recruitment_stages.length;

    const actionUp = [
        <CanView permissions={["candidates.c_manage_candidates_in_recruitment"]}>
            <SuperDuperModal
                key={1}
                trigger={
                    <Button disabled={currentStage === stages[stages?.length - 1]?.id} style={{ backgroundColor: 'var(--primary)', color: 'white' }} size="medium">
                        <span style={{ position: "relative", top: "-0.2rem" }}>{ t('stage') }</span>
                        <Icon name="chevron-up-outline" style={{ marginLeft: "0.5rem" }}/>
                    </Button>
                }
                header={t('update_candidate_stage')}
                size="small"
                content={
                    <MoveStageForm
                        current={data.current_stage}
                        roomId={room.id}
                        stages={stages}
                        candidateId={data.id}
                        isDown={false}
                        onConfirm={
                            (response) => {
                                setCurrentStage(response.id)
                                setCurrentView(response.id)
                            }
                        }
                    />
                }
            />
        </CanView>
    ]

    const actionDown = [
        <CanView permissions={["candidates.c_manage_candidates_in_recruitment"]}>
            <SuperDuperModal
                key={2}
                trigger={
                    <Button disabled={currentStage === stages[0]?.id} style={{ backgroundColor: 'var(--danger)', color: 'white' }} size="medium">
                        <span style={{ position: "relative", top: "-0.2rem" }}>{ t('stage') }</span>
                        <Icon name="chevron-down-outline" style={{ marginLeft: "0.5rem" }}/>
                    </Button>
                }
                header={t('downgrade_candidate_stage')}
                size="small"
                content={
                    <MoveStageForm
                        current={data.current_stage}
                        roomId={room.id}
                        stages={stages}
                        isDown={true}
                        candidateId={data.id}
                        onConfirm={
                            (response) => {
                                setCurrentStage(response.id)
                                setCurrentView(response.id)
                            }
                        }
                    />
                }
            />
        </CanView>
    ]

    const actionSelect = [
        <CanView permissions={["candidates.c_manage_candidates_in_recruitment"]}>
            <SuperDuperModal
                key={2}
                trigger={
                    <Button style={{ backgroundColor: 'var(--blue)', color: 'white', marginTop: "0.2rem", marginBottom: "0.2rem" }} size="medium" content={t('select_stage')} />}
                header={t('move_candidate_to_stage')}
                size="small"
                content={
                    <MoveStageForm
                        current={data.current_stage}
                        roomId={room.id}
                        jumpStage={true}
                        candidateId={data.id}
                        stages={stages}
                        position={room.job_position}
                        onConfirm={
                            (response) => {
                                setCurrentStage(response.id)
                                setCurrentView(response.id)
                            }
                        }
                    />
                }
            />
        </CanView>
    ]
    
    return (
        <div>
            <div
                style={{
                    margin: "1rem",
                    marginTop: "0rem",
                    backgroundColor: "white",
                }}
            >
                <CandidateHeader
                    rejected={rejected}
                    setRejected={setRejected}
                    blacklisted={blacklisted}
                    setBlacklisted={setBlacklisted}
                    data={data}
                    setCandidate={setCandidate}
                    buttonU={actionUp}
                    buttonSelect={actionSelect}
                    buttonD={actionDown}
                />
            </div>
            <div style={{ textAlign: "left", margin: "1rem" }}>
                <Grid style={{ marginTop: "1rem" }}>
                    <Step.Group widths={count}>
                        {room.archived_recruitment_stages && room.archived_recruitment_stages.map((stage, index) => (
                            <Step
                                key={index}
                                title={stage.name}
                                active={stage.id === currentView}
                                icon={<MyIcon state={stage.id <= currentStage} />}
                                disabled={stage.id > (data?.current_stage?.length !== 0 && currentStage)}
                                onClick={() => setCurrentView(stage.id)}
                                description={
                                    stage.id === currentStage
                                        ? (blacklisted || rejected) ? "-- " + t('stopped') + " --" : t('active')
                                        : stage.id < currentStage
                                            ? t('completed')
                                            : t('inactive')
                                }
                            />
                        ))
                        }
                    </Step.Group>
                </Grid>

                <StageComponent
                    rejected={rejected}
                    blacklisted={blacklisted}
                    room={idRoom}
                    candidate={id}
                    data={data}
                    setCandidate={setCandidate}
                    currentView={currentView}
                />
            </div>
        </div>
    );
};
export default Pipeline;
