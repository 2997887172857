import '@store/languages';
import React, { Fragment } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
// services
import { hiringService } from '@services/ServiceHiring';
import { tzDateTime, setLocaleLanguage } from '@helpers/dates';
// components
import Icon from '@components/Icon';
import Phone from "@components/general/Phone";
import CanView from '@components/perms/CanView';
import AvatarIcon from '@components/AvatarIcon';
import RatingComment from '../detail/RatingComment'
import FullHistoryLog from '../activity-log/FullHistoryLog';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import RejectBlackListForm from "../forms/RejectBlackListForm";
import { Grid, Item, Header, Button, Icon as SemanticIcon, Popup, Label } from 'semantic-ui-react';


const CandidateHome = ({ data, rejected, setRejected, blacklisted, setBlacklisted, buttonD, buttonU, buttonSelect }) => {
    const { t } = useTranslation()
    const params = useParams()

    const allow_jump_over_stages = useSelector(state => state.allow_jump_over_stages)

    const handleRejectOrBlacklist = async (type, note) => {
        if (type === "rejected") {
            const result = await hiringService.setRejected({
                note: note || "",
                profile: params.candidate,
                recruitment: params.room,
                rejected_by_candidate: false
            })
    
            if (result.status === 201) {
                setRejected(true)
            }
        } else if(type === "blacklisted") {
            const result = await hiringService.setBlacklisted({
                note: note || "",
                profile: params.candidate,
            })

            if (result.status === 201) {
                setBlacklisted(true)
            }
        }
    }

    const handleRejected = async () => {
        if (!rejected) {
            const result = await hiringService.setRejected({
                note: "",
                profile: params.candidate,
                recruitment: params.room
            })

            if (result.status === 201) {
                setRejected(true)
            }
        } else {
            // check if reject exist, find it and remove reject state
            const rejectedResult = await hiringService.getRejected("?profile=" + params.candidate + "&recruitment=" + params.room)

            if (rejectedResult.status === 200) {
                if (rejectedResult.response.length) {
                    // remove and on success set rejected = false
                    const rejectUndoResult = await hiringService.undoRejected(rejectedResult.response[0].id, {
                        note: ""
                    })
                    if (rejectUndoResult.status === 204) {
                        setRejected(false)
                    }
                }
            }
        }
    }

    const handleBlacklisted = async () => {
        if (!blacklisted) {
            const result = await hiringService.setBlacklisted({
                note: "",
                profile: params.candidate,
            })

            if (result.status === 201) {
                setBlacklisted(true)
            }
        } else {
            // check if blacklist exist, find it and remove blacklist state
            const blacklistedResult = await hiringService.getBlacklisted("?profile=" + params.candidate)

            if (blacklistedResult.status === 200) {
                if (blacklistedResult.response.length) {
                    // remove and on success set blacklisted = false
                    const blacklistUndoResult = await hiringService.undoBlacklisted(blacklistedResult.response[0].id, {
                        note: ""
                    })
                    if (blacklistUndoResult.status === 204) {
                        setBlacklisted(false)
                    }
                }
            }

        }
    }


    const HistoryIconWithPopup = () => {
        return (
            <Popup
                className="sidebar-item-popup"
                trigger={
                    <SemanticIcon
                        className="history-log-icon"
                        name="history"
                        size="huge"
                    />
                }
                flowing
                size="small"
                inverted
                position="top center"
                hoverable>
                {t('show_history_log')}
            </Popup>
        )
    }

    return (
        <Fragment>
            <Grid columns={4} verticalAlign='middle' className="header-block">
                <Grid.Row columns={4}>
                    <Grid.Column computer={2} tablet={6} mobile={16} textAlign='left'>
                        <AvatarIcon size={100} name={(data.fullname && data.fullname) || ' '} />
                    </Grid.Column>
                    <Grid.Column verticalAlign='middle' computer={4} tablet={6} mobile={16} style={{ textAlign: "left", paddingTop: "3rem", paddingBottom: "2rem" }}>
                        <Grid.Row   >
                            <Header as="h1" content={data.fullname} />
                        </Grid.Row>
                        <Grid.Row>
                            <Item>
                                <Item.Content> {data.date_of_assignment ? <><span style={{ marginRight: "0.5rem" }}>{tzDateTime(data.date_of_assignment).locale(setLocaleLanguage()).format("MMM Do YYYY")}</span><span>{tzDateTime(data.date_of_assignment, "YYYYMMDD").locale(setLocaleLanguage()).fromNow()} </span></> : "No data"} </Item.Content>
                            </Item>

                        </Grid.Row>
                        <Grid.Row verticalAlign="middle" textAlign="left">
                            <RatingComment rating={data.average_rating} />
                        </Grid.Row>

                    </Grid.Column>
                    <Grid.Column columns={1} computer={5} tablet={8} mobile={16} verticalAlign='middle' textAlign='left' style={{ marginTop: "0", marginLeft: "1rem" }}>
                        {data && data.personal_information && data.personal_information.contact_info ?
                            <Item.Group style={{ marginTop: "0" }}>
                                <Item>
                                    <Item.Meta style={{ width: "4rem" }}>{t('phone')}:</Item.Meta>
                                    <Item.Content>
                                        {data.personal_information.contact_info.phones && data.personal_information.contact_info.phones.length > 0
                                            ? data.personal_information.contact_info.phones.map((item, index) => (
                                                <Phone phone={item.phone}/>
                                            ))
                                            : <span>{t('no_data')}</span>
                                        }
                                    </Item.Content>
                                </Item>
                                <Item>
                                    <Item.Meta style={{ width: "4rem" }}>{t('email')}:</Item.Meta>
                                    <Item.Content>
                                        {data.personal_information.contact_info.emails && data.personal_information.contact_info.emails.length > 0
                                            ? data.personal_information.contact_info.emails.map((item, index) => (
                                                <a href={"mailto:" + item}>
                                                    <Icon name="mail-outline" style={{ marginRight: "0.5rem" }}/>
                                                    <span style={{ position: "relative", top: "-0.2rem" }}> {item.email} </span>
                                                </a>
                                            ))
                                            : <span>{t('no_data')}</span>
                                        }
                                    </Item.Content>
                                </Item>

                            </Item.Group>
                            : <span>{t('contact_info_not_provided')}</span>}

                    </Grid.Column>
                    <Grid.Column computer={2} tablet={6} mobile={16} textAlign='left'>
                        {blacklisted || rejected ?
                            blacklisted
                                ? <Label size="massive" color="black">{t('blacklisted')}</Label>
                                : rejected
                                    ? <Label size="massive" color="red">{t('rejected')}</Label>
                                    : ""
                            :
                            <Button.Group vertical icon>
                                {buttonU}
                                {allow_jump_over_stages && buttonSelect}
                                {buttonD}
                            </Button.Group>
                        }
                    </Grid.Column>
                    <Grid.Column computer={2} tablet={6} mobile={16} textAlign="center">
                        <CanView permissions={["candidates.c_view_history_records"]}>
                            <SuperDuperModal
                                trigger={
                                    <div>
                                        <HistoryIconWithPopup />
                                    </div>
                                }
                                content={
                                    <FullHistoryLog room={params.room} candidate={params.candidate} />
                                }
                            />
                        </CanView>
                        <Button.Group basic style={{ marginTop: "1rem" }}>
                            <CanView permissions={["candidates.c_manage_blacklist"]}>
                                { blacklisted 
                                    ? 
                                        <Popup
                                            className="sidebar-item-popup"
                                            trigger={
                                                <Button
                                                    icon={blacklisted ? <Icon name="arrow-undo-outline"/> : <Icon name="document"/>}
                                                    onClick={() => handleBlacklisted()}
                                                />
                                            }
                                            flowing
                                            size="small"
                                            inverted
                                            position="top center"
                                            hoverable>
                                            {blacklisted ? t('undo_blacklist') : t('put_candidate_to_blacklist')}

                                        </Popup>
                                    : 
                                        <SuperDuperModal
                                            header={t('put_candidate_to_blacklist')}
                                            trigger={
                                                <Button
                                                    icon={<Icon name="document"/>}
                                                />
                                            }
                                            content={
                                                <RejectBlackListForm 
                                                    type="blacklisted"
                                                    onPerformAction={handleRejectOrBlacklist}
                                                />
                                            }
                                        />
                                }
                            </CanView>
                            <CanView permissions={["candidates.c_manage_candidates_in_recruitment"]}>
                                { rejected 
                                    ? 
                                        <Popup
                                            className="sidebar-item-popup"
                                            trigger={
                                                <Button
                                                    icon={rejected ? <Icon name="arrow-undo-outline"/> : <Icon name="close-circle-outline"/>}
                                                    onClick={() => handleRejected()}
                                                />
                                            }
                                            flowing
                                            size="small"
                                            inverted
                                            position="top center"
                                            hoverable>
                                            {rejected ? t('undo_rejection') : t('reject_candidate_from_hr')}
                                        </Popup>
                                    :
                                        <SuperDuperModal
                                            header={t('reject_candidate_from_hr')}
                                            trigger={
                                                <Button
                                                    icon={<Icon name="close-circle-outline"/>}
                                                />
                                            }
                                            content={
                                                <RejectBlackListForm 
                                                    type="rejected"
                                                    onPerformAction={handleRejectOrBlacklist}
                                                />
                                            }
                                        />
                                }
                            </CanView>
                        </Button.Group>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Fragment>
    );
};

export default CandidateHome;