import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
// store
import { requests } from '@helpers/requests';
import { isEmpty } from '@services/helpers/validation';
import { API } from '@store/config';
// components
import { Form, Message, Divider } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const GroupForm = ({ groups, onClose, onConfirm, module }) => {
    const { t } = useTranslation();

    const [group, setGroup] = useState({
        id: groups ? groups.id : 0,
        name: groups ? groups.name : "",
        module: module
    })

    const [isProcessing, setIsProcessing] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleSuccess = (response) => {
        onConfirm(response);
        onClose()
    }

    const handleErrors = (response) => {
        let errorsArray = [];
        if (response.non_field_errors) {
            errorsArray.push(response.non_field_errors)
        }
        if (response.deital) {
            errorsArray.push(response.detail)
        }
        if (response.name) {
            errorsArray.push(response.field_name)
        }
        setErrors(errorsArray);
    }

    const handleSubmit = async () => {
        setErrors([]);
        setIsProcessing(true);
        let request = null;

        const data = {
            name: group ? group.name : "",
            module: module
        }

        if (!group.id || group.id === 0) {
            request = await requests.post(API.ATTACHMENT_GROUPS, data)
            if (request.status === 201) {
                handleSuccess(request.response)
            } else {
                handleErrors(request.response)
            }
        } else {
            request = await requests.put(API.ATTACHMENT_GROUPS + group.id + "/", data)
            if (request.status === 200) {
                handleSuccess(request.response)
            } else {
                handleErrors(request.response)
            }
        }

        setIsProcessing(false);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors.length > 0}
                header={t("error_submission_message")}
                list={errors}
            />
            <Form.Group widths="equal">
                <Form.Field>
                    <Form.Input
                        autoFocus
                        required
                        label={t('group_name')}
                        placeholder={t('group_name')}
                        value={group.name}
                        onChange={(e) => setGroup({
                            ...group,
                            name: e.target.value
                        })}
                    />
                </Form.Field>
            </Form.Group>

            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || isEmpty(group.name)}
                    text={t('save')} />
            </Form.Field>
        </Form>
    );
};

export default GroupForm;