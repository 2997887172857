import React from 'react';
//components
import { Icon, Header } from 'semantic-ui-react'

function SegmentHeader(props) {
    return (
        <div style={{ paddingTop: "2rem", paddingBottom: "1rem" }}>
            <Header as='h3'>
                <Icon name={props.headerIconName} />
                <Header.Content>{props.headerText}</Header.Content>
            </Header>
        </div>

    );
}

export default SegmentHeader;