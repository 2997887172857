import React, { useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
// components
import SuperField from '@components/forms/SuperField';

const LastCountryOfResidence = ({ setData, countries }) => {
    const { t } = useTranslation();
    const [lastCountryResidance, setlastCountryResidance] = useState("")

    useEffect( () => {
        setData(prevState => ({
            ...prevState,
            last_country_residence: lastCountryResidance
        }))

    }, [lastCountryResidance, setData])

    return (
        <SuperField as="choice"
            search
            width="8"
            label={ t('last_country_of_residence') }
            customOptions={countries}
            value={ lastCountryResidance }
            onChange={ (e, { value }) => setlastCountryResidance(value) }
        />
    );
};

export default LastCountryOfResidence;