import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import { Form, Divider } from 'semantic-ui-react'
import NonFieldErrors from '@components/NonFieldErrors'

const UnitChangeBulk = ({ selected, setSelected, setData, onClose }) => {
    const { t } = useTranslation()

    const contracts = selected.map((item) => item.id)
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)

    const [form, setForm] = useState({
        unit: '',
    })

    const handleSubmit = async () => {
        setProcessing(true)

        const request = await requests.patch(API.UNITS + form.unit + '/bulk/assign_employees/', {
            contracts: contracts,
        })
        if (request.status === 200) {
            const updateContracts = request.response
            const updateContractsIds = request.response.map((item) => item.contract_id)
            setData((prev) =>
                prev.filter((item) => {
                    if (updateContractsIds.includes(item.id)) {
                        const updateContract = updateContracts.find((contract) => contract.contract_id === item.id)
                        if (updateContract !== undefined) {
                            item.unit = {
                                id: updateContract.unit,
                                name: updateContract.unit_name,
                                company: updateContract?.company_id,
                            }
                        }
                    }
                    return item
                })
            )
            setSelected([])
            onClose()
        } else if (request.status === 400) {
            setErrors(request.response)
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <NonFieldErrors errors={errors} />
            <SuperField
                as="choice"
                label={t('department')}
                search
                endpoint={API.UNITS + '?query={id, name}&only_from_active_company=true'}
                text="name"
                value={form.unit}
                onChange={(e, { value }) =>
                    setForm({
                        ...form,
                        unit: value,
                    })
                }
            />
            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit text={t('confirm')} loading={processing} disabled={processing || form.unit === ''} />
            </Form.Field>
        </Form>
    )
}

export default UnitChangeBulk
