import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { isEmpty } from '@helpers/validation'
import { thousandsSeparators } from '@helpers/functions'
// components
import Icon from '@components/Icon'
import EmptyRow from '@components/tables/EmptyRow'
import SuperField from '@components/forms/SuperField'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import { FlexItem } from '@components/tables/FlexTable'
import FlexRowWithToggle from '@components/FlexRowWithToggle'
import { Form, Header, Divider, Grid, Popup, Button } from 'semantic-ui-react'
import TransactionForm from './TransactionForm'
import ReceivedInvoices from './ReceivedInvoices'

const PaymentRequestForm = ({ payment_request, setPaymentRequests, bankAccount, connection, onClose, setView }) => {
    const { t } = useTranslation()

    const [isProcessing, setIsProcessing] = useState(false)
    const [currentOpen, setCurrentOpen] = useState(null)
    const [transactions, setTransactions] = useState(
        payment_request?.id !== undefined
            ? payment_request?.payments?.map((payment) => {
                  delete payment.payment_identifier
                  delete payment.transaction_id
                  delete payment.created_at
                  delete payment.modified_at
                  delete payment.owner
                  delete payment.currency
                  return payment
              })
            : []
    )
    const [dateForPayment, setDateForPayment] = useState(
        payment_request?.id !== undefined ? payment_request.date_for_payment : moment().format('YYYY-MM-DD')
    )

    const handleSubmit = async () => {
        setIsProcessing(true)
        if (payment_request?.id === undefined) {
            const request = await requests.post(
                API.INVOICES + 'payments/payment_requests/?connection=' + connection.connection_id,
                {
                    account_id: bankAccount.accountId,
                    debtor_name: bankAccount?.displayName,
                    debtor_bic: bankAccount.bankCode === 'TBSK' ? 'TATRSKBX' : bankAccount.bic,
                    debtor_iban: bankAccount.accountReference.iban,
                    date_for_payment: dateForPayment,
                    payments: transactions,
                }
            )

            if (request.status === 201) {
                if (setView) setView(3)
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('success'),
                    description: t('payment_request_created_successfully'),
                    animation: 'bounce',
                    time: 5000,
                })
                onClose()
            } else {
                toast({
                    type: 'error',
                    icon: 'warning',
                    title: '',
                    description: request.response?.error || 'Error',
                    animation: 'bounce',
                    time: 5000,
                })
            }
        } else {
            const request = await requests.patch(
                API.INVOICES +
                    'payments/payment_requests/' +
                    payment_request.id +
                    '/?connection=' +
                    connection.connection_id,
                {
                    date_for_payment: dateForPayment,
                }
            )

            if (request.status === 200) {
                const total_transactions = transactions.length
                let processed_transactions = 0
                for (let i = 0; i < transactions.length; i++) {
                    const payment = transactions[i]
                    if (payment.id === 0) {
                        // create
                        delete payment.id
                        const transactionRequest = await requests.post(
                            API.INVOICES + 'payments/?connection=' + connection.connection_id,
                            {
                                ...payment,
                                payment_request: payment_request.id,
                            }
                        )

                        if (transactionRequest.status === 201) {
                            onTransactionChange(i, transactionRequest.id, 'id')
                            processed_transactions += 1
                        }
                    } else {
                        // update
                        const transactionRequest = await requests.patch(
                            API.INVOICES + 'payments/' + payment.id + '/?connection=' + connection.connection_id,
                            payment
                        )
                        if (transactionRequest.status === 200) {
                            processed_transactions += 1
                        }
                    }
                }

                if (total_transactions === processed_transactions) {
                    const fetchPaymentRequest = await requests.get(
                        API.INVOICES +
                            'payments/payment_requests/' +
                            payment_request.id +
                            '/?connection=' +
                            connection.connection_id
                    )
                    if (fetchPaymentRequest.status === 200) {
                        setPaymentRequests((prev) =>
                            prev.map((item) => {
                                if (payment_request.id === item.id) {
                                    item = fetchPaymentRequest.response
                                }

                                return item
                            })
                        )
                        onClose()
                    }
                }
            }
        }

        setIsProcessing(false)
    }

    const onTransactionChange = (index, value, attribute) => {
        setTransactions((prev) =>
            prev.filter((record, key) => {
                if (index === key) {
                    record[attribute] = value
                }
                return record
            })
        )
    }

    const isTransactionValid = (transaction) => {
        if (isEmpty(transaction.end_to_end_reference)) return false
        if (isEmpty(transaction.amount)) return false
        if (isNaN(transaction.amount)) return false
        if (isEmpty(transaction.receiver_bic)) return false
        if (isEmpty(transaction.receiver_name)) return false
        if (isEmpty(transaction.receiver_iban)) return false
        if (bankAccount.accountReference.iban === transaction.receiver_iban) return false

        return true
    }

    const onTransactionAdd = () => {
        setTransactions((prev) => {
            setCurrentOpen(prev.length - 1 + 1)
            return [
                ...prev,
                {
                    id: 0,
                    end_to_end_reference: '',
                    currency: bankAccount.accountReference.currency,
                    amount: '',
                    receiver_bic: '',
                    receiver_name: '',
                    receiver_iban: '',
                    receiver_note: '',
                    invoice_reference: null,
                    variable_symbol: '',
                    constant_symbol: '',
                    specific_symbol: '',
                },
            ]
        })
    }

    const onTransactionRemove = async (index, id) => {
        if (id === 0) {
            setTransactions((prev) => prev.filter((record, key) => index !== key))
        } else {
            const request = await requests.del(
                API.INVOICES + 'payments/' + id + '/?connection=' + connection.connection_id
            )
            if (request.status === 204) {
                setTransactions((prev) => prev.filter((record, key) => index !== key))
            }
        }
        setCurrentOpen(null)
    }

    const calculateAmount = () => {
        return thousandsSeparators(
            transactions
                .map((payment) => (isNaN(parseFloat(payment.amount)) ? 0 : parseFloat(payment.amount)))
                .reduce((partialSum, amount) => partialSum + amount, 0)
                .toFixed(2)
        )
    }

    const isPaymentDateInvalid = () => {
        let currentDay = moment()
        let currentDayPlus31 = moment().add(31, 'days')
        let paymentDay = moment(dateForPayment)

        if (currentDay.isAfter(paymentDay, 'day')) {
            return t('payment_date_cant_be_past')
        }

        if (paymentDay.isAfter(currentDayPlus31, 'day')) {
            return t('payment_date_cant_be_used')
        }

        return false
    }

    const isValidPaymentRequest = () => {
        if (dateForPayment === '') return false
        if (dateForPayment === null) return false
        if (isPaymentDateInvalid() !== false) return false

        if (transactions.length === 0) return false
        if (transactions.length > 500) return false
        if (transactions.filter((transaction) => !isTransactionValid(transaction)).length > 0) return false
        if (
            transactions.filter((transaction) => transaction.receiver_iban === bankAccount.accountReference.iban)
                .length > 0
        )
            return false

        return true
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Header
                as="h3"
                content={payment_request?.id === undefined ? t('create_payment_request') : t('update_payment_request')}
            />
            <Divider />

            <Grid>
                <Grid.Row style={{ padding: 0 }}>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <div style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{t('iban') + '/' + t('bic')}</div>
                        <p>{bankAccount.accountReference.iban + ' / ' + bankAccount.bic}</p>
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <div style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{t('bank_account_name')}</div>
                        <p>{bankAccount.displayName}</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: 0, marginTop: '1rem' }}>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <SuperField
                            as="datepicker"
                            size="small"
                            label={t('date_for_payment')}
                            help={t('date_for_payment_hint')}
                            value={dateForPayment}
                            error={isPaymentDateInvalid()}
                            onChange={(e, { value }) => setDateForPayment(value)}
                        />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <div style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>&nbsp;</div>
                        <p style={{ fontSize: '1.2rem' }}>
                            <strong>{t('sum')}: </strong>
                            {calculateAmount() + ' ' + bankAccount.accountReference.currency}
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider />
            <Grid>
                <Grid.Row style={{ padding: 0 }}>
                    <Grid.Column computer={6} tablet={6} mobile={16}>
                        <Header
                            style={{ marginTop: '0.5rem', marginBottom: 0 }}
                            as="h4"
                            content={t('transactions') + ' - ' + transactions.length + ' (max. 500)'}
                        />
                    </Grid.Column>
                    <Grid.Column style={{ textAlign: 'right' }} computer={10} tablet={10} mobile={16}>
                        <SuperDuperModal
                            size="large"
                            trigger={
                                <Button
                                    type="button"
                                    size="small"
                                    style={{
                                        marginRight: '0.5rem',
                                        background: 'var(--light)',
                                        border: '1px solid lightgrey',
                                    }}
                                >
                                    <Icon name="list-outline" style={{ marginRight: '0.5rem' }} />
                                    <span style={{ position: 'relative', top: '-0.2rem' }}>{t('select_invoices')}</span>
                                </Button>
                            }
                            content={
                                <ReceivedInvoices
                                    bankAccount={bankAccount}
                                    connection={connection}
                                    transactions={transactions}
                                    setTransactions={setTransactions}
                                />
                            }
                        />
                        <Button type="button" size="small" primary onClick={() => onTransactionAdd()}>
                            {t('add_transaction')}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider />

            <EmptyRow length={transactions.length} />
            {transactions.map((payment, index) => (
                <FlexRowWithToggle
                    isOpen={currentOpen === index}
                    setIsOpen={(index) => setCurrentOpen(index)}
                    background="var(--light)"
                    key={index}
                    loading={false}
                    rowContent={
                        <>
                            <FlexItem basis="20%">
                                <strong> #{index + 1} </strong>
                                <br />
                            </FlexItem>
                            <FlexItem bold basis="160%" fontSize="0.7rem">
                                <small>{payment?.receiver_iban || '--'}</small>
                            </FlexItem>
                            <FlexItem bold textAlign="center" fontSize="0.7rem">
                                <small>{payment?.end_to_end_reference || '--'}</small>
                            </FlexItem>
                            <FlexItem bold textAlign="center" fontSize="0.7rem">
                                <small>
                                    {thousandsSeparators(payment?.amount || 0)} {bankAccount.accountReference.currency}
                                </small>
                            </FlexItem>
                            <FlexItem basis="10%" bold textAlign="center" fontSize="0.7rem">
                                <Popup
                                    basic
                                    trigger={
                                        <Icon
                                            name={isTransactionValid(payment) ? 'checkmark-outline' : 'alert-outline'}
                                            style={{
                                                color: isTransactionValid(payment) ? 'var(--success)' : 'var(--danger)',
                                                cursor: 'pointer',
                                            }}
                                        />
                                    }
                                    content={
                                        isTransactionValid(payment)
                                            ? t('transaction_is_valid')
                                            : t('transaction_contain_errors')
                                    }
                                />
                            </FlexItem>
                        </>
                    }
                    content={
                        <TransactionForm
                            index={index}
                            key={index}
                            payment={payment}
                            bankAccount={bankAccount}
                            onTransactionChange={onTransactionChange}
                            onTransactionRemove={onTransactionRemove}
                        />
                    }
                />
            ))}
            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} disabled={isProcessing} />
                <ModalSubmit
                    disabled={isProcessing || !isValidPaymentRequest()}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    )
}

export default PaymentRequestForm
