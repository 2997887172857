import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
//store
import { requests } from '@helpers/requests';
import { API } from '@store/config';
//components
import { Container } from 'semantic-ui-react';
import ProfileHeader from '../components/ProfileHeader';
import AccountSettings from '../components/AccountSettings';
// import PreferenceSettings from '../components/PreferenceSettings';
import BreadcrumbNav from '@components/BreadcrumbNav';
import Loading from '@components/general/Loading';

// Access URL: .../dashboard/profile/
const Profile = () => {
    const { t } = useTranslation()

    const breadcrumb_items = [
        // Put: name, icon and href data inside to create breadcrumb item
        { 'name': t("profile"), 'icon': 'user', 'href': '' }
    ]

    // eslint-disable-next-line
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    // get user data:
    useEffect(() => {
        async function fetchData() {
            const result = await requests.get(API.GET_USER_ME);

            if (result.status === 200) {
                setData(result.response);
            }
            setIsLoading(false);
        };

        fetchData();
    }, []);

    // This is center point for Profile Page,
    // do any modification that you need to build-up Profile Page
    return (
        <Fragment>
            <BreadcrumbNav items={breadcrumb_items} />
            {isLoading ? <Loading /> :
                <Container fluid>
                    <ProfileHeader data={data} />
                    <AccountSettings data={data} />
                    {/* <PreferenceSettings /> */}
                </Container>
            }
        </Fragment >
    );
};

export default Profile;
