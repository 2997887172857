import React, { useState } from "react";

// components
import ChooseYourPasswordForm from "./ChooseYourPasswordForm";

// component
function ConfirmResetPassword(props) {
    // state
    const [data] = useState({
        uid: props.match.params.uid,
        token: props.match.params.token,
    });

    // template
    return (
        <div className="center-element">
            <ChooseYourPasswordForm data={data} />
        </div>
    );
}

export default ConfirmResetPassword;
