import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import Icon from '@components/Icon'
import CanView from '@components/perms/CanView'
import BreadcrumbNav from '@components/BreadcrumbNav'
import SlovakAbroads from './views/SlovakAbroads'
import { Button, Container, Divider } from 'semantic-ui-react'

const Abroads = () => {
    const { t } = useTranslation()
    const [view, setView] = useState('sk')

    return (
        <CanView redirect permissions={['abroads.c_view_abroad']}>
            <BreadcrumbNav items={[{ name: t('posting_staff'), icon: '', href: '' }]} mBottom="0rem" />

            <Container fluid>
                <Button.Group basic size="small" style={{ borderRadius: '0' }} className="custom-filter-button-group">
                    <Button active={view === 'sk'} onClick={() => setView('sk')}>
                        <Icon name={'people-outline'} style={{ marginRight: '0.5rem' }} />
                        <span style={{ position: 'relative', top: '-0.1rem' }}>{t('from_slovakia')}</span>
                    </Button>
                    <Button active={view === 'cz'} onClick={() => setView('cz')} disabled>
                        <Icon name={'people-outline'} style={{ marginRight: '0.5rem' }} />
                        <span style={{ position: 'relative', top: '-0.1rem' }}>{t('from_czechia')}</span>
                    </Button>
                </Button.Group>
                <Divider style={{ marginBottom: 0 }} />
            </Container>

            {view === 'sk' && <SlovakAbroads />}
        </CanView>
    )
}

export default Abroads
