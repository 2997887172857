import React, { useState } from 'react'
// store
import { API } from '@store/config'
// Components
import Icon from '@components/Icon'
import { Form, Header, Container, Grid, Button } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import SidebarPanel from '@components/SidebarPanel'
import MKEditor from '@components/editors/MKEditor'
import StatisticView from '@components/StatisticView'

const ComponentExamples = () => {
    const [markdown, setMarkdown] = useState('')

    const arrayOfObjects = [
        {
            metric: 'total',
            value_field: 'hours',
        },
        {
            value: 10,
            value_id: 5,
            value_display: 'profile',
            group_id: 1,
            group_display: 'category',
            category_id: 12,
            category_display: 'type',
        },
        {
            value: 20,
            value_id: 5,
            value_display: 'profile',
            group_id: 2,
            group_display: 'category',
            category_id: 15,
            category_display: 'type',
        },
        {
            value: 30,
            value_id: 5,
            value_display: 'profile',
            group_id: 3,
            group_display: 'category',
            category_id: 12,
            category_display: 'type',
        },
        {
            value: 10,
            value_id: 2565,
            value_display: 'profile',
            group_id: 1,
            group_display: 'category',
            category_id: 13,
            category_display: 'type',
        },
        {
            value: 9,
            value_id: 2799,
            value_display: 'profile',
            group_id: 2,
            group_display: 'category',
            category_id: 15,
            category_display: 'type',
        },
    ]

    return (
        <>
            {/* 
                valuesCount,
                groupsCount,
                categoriesCount,
                valueWithGroup,
                groupWithValue,
                valueWithCategory,
                groupWithCategory,
                categoryWithValue,
                categoryWithGroup,
            */}

            <Container fluid style={{ marginTop: '3rem' }}>
                <StatisticView header="Bar + Pie (values count)" customDataset={arrayOfObjects} bar pie valuesCount />
                <StatisticView header="Bar + Pie (groups count)" customDataset={arrayOfObjects} bar pie groupsCount />
                <StatisticView
                    header="Bar + Pie (categories count)"
                    customDataset={arrayOfObjects}
                    bar
                    pie
                    categoriesCount
                />
                <StatisticView
                    header="Bar + Pie (groupWithValue)"
                    customDataset={arrayOfObjects}
                    bar
                    pie
                    groupWithValue
                />
                <StatisticView
                    header="Bar + Pie (categoryWithValue)"
                    customDataset={arrayOfObjects}
                    bar
                    pie
                    categoryWithValue
                />

                <StatisticView header="Line + Pie (values count)" customDataset={arrayOfObjects} line pie valuesCount />
                <StatisticView
                    header="Line + Pie (category with group)"
                    customDataset={arrayOfObjects}
                    line
                    pie
                    categoryWithGroup
                />
            </Container>

            <Container fluid style={{ marginTop: '20px' }}>
                <MKEditor label={'Desciption'} markdown={markdown} onChange={(value) => setMarkdown(value)} />
            </Container>

            <Header as="h1" content="Sidebar Panel" textAlign="center" />
            <Container style={{ textAlign: 'center' }}>
                <SidebarPanel
                    width="1000px"
                    trigger={<Button content="Click to open Sidebar Panel" />}
                    content={<p>Test 123</p>}
                />
            </Container>

            <Header as="h1" content="Icon component examples" textAlign="center" />
            <Container style={{ textAlign: 'center' }}>
                <Grid>
                    <Grid.Row verticalAlign="middle">
                        <Grid.Column>
                            <Icon
                                name="book-outline"
                                style={{ fontSize: '2rem', paddingRight: '0.5rem', fontWeight: 'bold', color: 'pink' }}
                                height="28px"
                            />
                            <span style={{ position: 'relative', top: '-0.65rem' }}>Book</span>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>

            <Form style={{ padding: '5rem' }}>
                <Header as="h1" content="One SuperField component to rule them all!" textAlign="center" />

                <Form.Group widths="equal">
                    <SuperField as="input" label="Input Field" help="This is help text" placeholder="..." />

                    <SuperField
                        as="input"
                        label="Input Field"
                        placeholder="..."
                        fieldError={{
                            key: 'input',
                            errors: {
                                input: 'This is an error for input field',
                            },
                        }}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <SuperField as="textarea" label="TextArea Field" placeholder="..." />

                    <SuperField
                        as="textarea"
                        label="TextArea Field"
                        placeholder="..."
                        fieldError={{
                            key: 'textarea',
                            errors: {
                                textarea: 'This is an error for TextArea Field',
                            },
                        }}
                    />
                </Form.Group>

                <Form.Group widths="equal">
                    <SuperField as="select" label="Select Field" />

                    <SuperField
                        as="dropdown"
                        label="Dropdown Field"
                        selection
                        search
                        options={[
                            {
                                key: 'Jenny Hess',
                                text: 'Jenny Hess',
                                value: 'Jenny Hess',
                            },
                        ]}
                    />
                </Form.Group>

                <Form.Group widths="equal">
                    <SuperField
                        as="choice"
                        label="Choice Field"
                        help="This is help text"
                        endpoint={API.ACCOUNTS}
                        text="name"
                    />
                </Form.Group>

                <Form.Group widths="equal">
                    <SuperField
                        as="datepicker"
                        label="Date Picker Field"
                        help="This is help text"
                        fieldError={{
                            key: 'textarea',
                            errors: {
                                textarea: 'This is an error for Date Picker Field',
                            },
                        }}
                    />
                </Form.Group>

                <Form.Group widths="equal">
                    <SuperField as="checkbox" help="This is help text" label="Checkbox Field" />

                    <SuperField as="radio" label="Radio Field" />
                </Form.Group>
            </Form>
        </>
    )
}

export default ComponentExamples
