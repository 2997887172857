import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import Icon from '@components/Icon';

const ExportTo = ({ text, selected }) => {
    const { t } = useTranslation()

    return (
        <>
            <Icon name="download-outline" style={{ marginRight: "0.5rem" }}/>
            <span style={{ position: "relative", top: "-0.1rem" }}>
                {t('export')} { text }
                { selected.length > 0 
                    ? <span style={{ textTransform: "lowercase" }}>{ ": " + selected.length + " " + t('selected') } </span> 
                    : ""
                }
            </span>
        </>
    )
}

export default ExportTo;