import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import SuperField from '@components/forms/SuperField';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Header, Divider, Card, Form, Button, Icon, Grid } from 'semantic-ui-react';
// module specific
import ImportInvalidReport from './ImportInvalidReport';

const ImportInvoices = ({ connection, user, onClose, setRefetch }) => {
    const { t } = useTranslation()
    const [source, setSource] = useState(null) // 1 - XLSX Template, 2 - MKSOFT XML, 3 - Pohoda XML
    const [issued, setIssued] = useState(null)
    const [response, setResponse] = useState(null)
    const [importing, setImporting] = useState(false)
    const [file, setFile] = useState(null)

    // document.getElementById('source-file').value = null
    const handleSubmit = async () => {
        setResponse(null)
        setImporting(true)
        const formData = new FormData()
        formData.append("file", file)

        const endpoint = determinateSourceEndpoint()
        const request = await requests.post(endpoint, formData)
        setResponse(request)
        setImporting(false)
    }

    const determinateSource = () => {
        if (source === 1) return t('template') + " XLSX"
        if (source === 2) return "MKSOFT - XML"
        if (source === 3) return "POHODA - XML"

        return "None"
    }

    const determinateSourceEndpoint = () => {
        const queryparams = `?connection=${connection.connection_id}&account=${user.journal.id}`
        const issuedParam = "&is_issued=" + (issued)
        if (source === 1) return API.INVOICES + `import/${queryparams}`
        if (source === 2) return API.INVOICES + `import_mksoft/${queryparams}${issuedParam}`
        if (source === 3) return API.INVOICES + `import_pohoda/${queryparams}`

        return null
    }

    const XLSXImportResult = ({ response }) => {

        const calculateInvoiceInvalid = (type) => {
            let total = 0
            if (response?.[type]?.totals?.invalid){
                total += response?.[type]?.totals?.invalid || 0
            }

            if (response?.[type]?.totals?.error){
                total += response?.[type]?.totals?.error || 0
            }

            return total
        }

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <p style={{ fontWeight: "bold", fontSize: "1.3rem" }}> {t('invoices_import_result')}:</p>
                        <ol style={{ listStyleType: "none", paddingLeft: "0.5rem" }}>
                            <li>{t('new')}: { response?.Invoices?.totals?.new || 0 }</li>
                            <li>{t('updated')}: { response?.Invoices?.totals?.updated || 0 }</li>
                            <li>{t('skipped')}: { response?.Invoices?.totals?.skipped || 0 }</li>
                            <li style={{ fontWeight: "bold" }}>
                                {t('invalid')}: { calculateInvoiceInvalid("Invoices") }
                                { calculateInvoiceInvalid("Invoices") > 0 && 
                                    <SuperDuperModal
                                        header={t('invalid_records')}
                                        trigger={
                                            <span className="ref-link" style={{ color: "var(--primary)", marginLeft: "0.5rem", fontSize: "0.9rem" }}>
                                                {t('show_report')}
                                            </span>
                                        }
                                        content={<ImportInvalidReport source={source} errors={response?.Invoices?.invalid}/>}
                                    />
                                }
                            </li>
                        </ol>
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <p style={{ fontWeight: "bold", fontSize: "1.3rem" }}> {t('items_import_result')}:</p>
                        <ol style={{ listStyleType: "none", paddingLeft: "0.5rem" }}>
                            <li>{t('new')}: { response?.Items?.totals?.new || 0 }</li>
                            <li>{t('updated')}: { response?.Items?.totals?.updated || 0 }</li>
                            <li>{t('skipped')}: { response?.Items?.totals?.skipped || 0 }</li>
                            <li style={{ fontWeight: "bold" }}>
                                {t('invalid')}: { calculateInvoiceInvalid("Items") }
                                { calculateInvoiceInvalid("Items") > 0 && 
                                    <SuperDuperModal
                                        header={t('invalid_records')}
                                        trigger={
                                            <span className="ref-link" style={{ color: "var(--primary)", marginLeft: "0.5rem", fontSize: "0.9rem" }}> 
                                                {t('show_report')}
                                            </span>
                                        }
                                        content={<ImportInvalidReport source={source} errors={response?.Items?.invalid}/>}
                                    />
                                }
                            </li>
                        </ol>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }

    const XMLImportResult = ({ response }) => {
        
        const calculateInvoiceInvalid = (type) => {
            let total = 0
            if (response?.totals?.invalid){
                total += response?.totals?.invalid || 0
            }

            if (response?.totals?.error){
                total += response?.totals?.error || 0
            }

            return total
        }

        return (
            <>
                <p style={{ fontWeight: "bold", fontSize: "1.3rem" }}> {t('invoices_import_result')}:</p>
                <ol style={{ listStyleType: "none", paddingLeft: "0.5rem" }}>
                    <li>{t('new')}: { response?.totals?.new || 0 }</li>
                    <li>{t('updated')}: { response?.totals?.updated || 0 }</li>
                    <li>{t('skipped')}: { response?.totals?.skipped || 0 }</li>
                    <li style={{ fontWeight: "bold" }}>
                        {t('invalid')}: { calculateInvoiceInvalid("Invoices") }
                        { calculateInvoiceInvalid("Invoices") > 0 && 
                            <SuperDuperModal
                                header={t('invalid_records')}
                                trigger={
                                    <span className="ref-link" style={{ color: "var(--primary)", marginLeft: "0.5rem", fontSize: "0.9rem" }}>
                                        {t('show_report')}
                                    </span>
                                }
                                content={<ImportInvalidReport source={source} errors={response?.invalid}/>}
                            />
                        }
                    </li>
                </ol>
            </>
        )
    }

    return (
        <>
            <Header as="h3" content={t('import_invoices')}/>
            <Divider/>
            <strong>{t('you_can_import_invoices_from_three_different_sources')}:</strong>
            <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
                1. {t('template')} XLSX - <a href="/imports/xlsx_template.xlsx" target="_blank" download>{t('example')} XLSX</a>  
                <span style={{ paddingLeft: "0.5rem" }}>
                    ({t('please_refer_to_documentation_for_more_info')}: <a href="/imports/xlsx_specification.pdf" target="_blank">{t('xlsx_specifications')}</a>)
                </span><br/>
                2. MKSoft XML - <a href="/imports/mksoft_import_example.xml" target="_blank" download>{t('example')} XML</a><br/>
                3. Pohoda XML - <a href="/imports/pohoda_xml_example.xml" target="_blank" download>{t('example')} XML</a><br/>
            </div>

            <Header as="h4">
                {t('selected_journal')}: <strong>{user.journal.name} - {user.journal.id_number}</strong> <br/>
            </Header>
            <Divider/>

            { source === null && <Header as="h4" content={t('select_which_source_do_you_want_to_use') + ":"}/> }
            { source !== null && 
                <Header as="h4">
                    {t('selected_source')}: { determinateSource() } (<span className="ref-link" style={{ color: "var(--primary)" }} onClick={ () => {
                        document.getElementById('source-file').value = null
                        setFile(null)
                        setSource(null)
                        setResponse(null)
                    } }>{t('change_source')}</span>)
                    <br/> {t('please_provider_import_file')}:
                </Header>
            }
            { source === null 
                ? 
                <Card.Group itemsPerRow={3} stackable>
                    <Card style={{ fontWeight: "bold", borderRadius: 0, cursor: "pointer" }} onClick={() => setSource(1)}>
                        <Card.Content style={{ textAlign: "center" }}>
                            {t('template')} - XLSX
                        </Card.Content>
                    </Card>
                    <Card style={{ fontWeight: "bold", borderRadius: 0, cursor: "pointer" }} onClick={() => setSource(2)}>
                        <Card.Content style={{ textAlign: "center" }}>
                            MKSOFT - XML
                        </Card.Content>
                    </Card>
                    <Card style={{ fontWeight: "bold", borderRadius: 0, cursor: "pointer" }} onClick={() => setSource(3)}>
                        <Card.Content style={{ textAlign: "center" }}>
                            POHODA - XML
                        </Card.Content>
                    </Card>
                </Card.Group>
                :
                <Form onSubmit={handleSubmit}>
                    { source === 1 && 
                        <p style={{ color: "var(--danger)" }}> { t('invoice_import_description_hint') } </p>
                    }
                    <Form.Group>
                        <SuperField 
                            id="source-file"
                            as="input"
                            type="file"
                            onChange={(event) => {
                                if (event.target?.files?.length > 0) {
                                    setFile(event.target.files[0])
                                }
                            }}
                        />
                        <Form.Field style={{ marginTop: "0.7rem" }} disabled={importing}>
                            { file !== null && 
                                <span className="ref-link" style={{ color: "var(--danger)" }} onClick={() => {
                                    document.getElementById('source-file').value = null
                                    setFile(null)
                                    setResponse(null)
                                }}>{t('remove_file')}</span>
                            }
                        </Form.Field>
                    </Form.Group>
                    { source === 2 &&
                        <>
                            <div style={{ fontWeight: "bold", marginBottom: "0.5rem" }}> {t('please_specify_invoices_type')}: </div>
                            <SuperField 
                                as="radio"
                                checked={issued === true} 
                                label={t('send_invoices')}
                                onChange={() => setIssued(true)}
                            />
                            <SuperField 
                                as="radio"
                                checked={issued === false} 
                                label={t('recieved_invoices')}
                                onChange={() => setIssued(false)}
                            />
                        </>
                    }

                    <Divider/>

                    { response !== null && 
                        <>
                            { response?.status >= 500 && <p style={{ fontWeight: "bold", fontSize: "1.3rem", color: "var(--danger)" }}> { t('system_failure_message') } </p> }
                            { response?.status === 400 && <p style={{ fontWeight: "bold", fontSize: "1.3rem", color: "var(--danger)" }}> { t('file_or_structure_not_allowed') } </p> }
                            { response?.status === 200 && 
                                <>
                                    { source === 1 && <XLSXImportResult response={response?.response}/> }
                                    { source !== 1 && <XMLImportResult response={response?.response}/> }


                                    <Divider/>
                                    <Header as="h3" style={{ textAlign: "center", marginTop: "0.5rem" }}>
                                        { t('import_finished_hint') + " " }
                                        <u>
                                            <span className="ref-link" onClick={() => {
                                                onClose()
                                                setRefetch(true)
                                            }}>{t('click_here')}.
                                            </span>
                                        </u>
                                    </Header>
                                </>

                            }
                            <Divider/>
                        </>
                    }
                    <Form.Group>
                        <Form.Field>
                            <Button disabled={importing || file === null} loading={importing} primary content={t('confirm')}/>
                        </Form.Field>
                        { importing && 
                            <Form.Field style={{ fontWeight: "bold", marginTop: "0.6rem" }}>
                                {t('we_are_importing_your_data_hint')} ({ t('good_time_for_a_coffee') } <Icon name="coffee"/>)
                            </Form.Field>
                        }
                    </Form.Group>
                </Form>
            }
        </>
    );
};

export default ImportInvoices;