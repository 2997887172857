import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { createRequest } from '@services/ServiceCommon';
// components
import { Form } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const AgreementForm = ({ onClose, onConfirm, setData, setTotal, employees, candidates }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [form, setForm] = useState({
        profile: "",
        gdpr: ""
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        const request = await createRequest(API.API_URL + "/common/gdpr_agreements_manual/", form)
        if (request.status === 201) {
            onConfirm(request.response, setData, setTotal)
            onClose()
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="choice"
                search
                required
                label={t('person')}
                customOptions={[...candidates.options, ...employees.options]}
                loading={candidates.isLoading || employees.isLoading}
                disabled={candidates.isLoading || employees.isLoading}
                value={form.profile}
                onChange={(e, { value }) => setForm({ ...form, profile: value })}
            />
            <SuperField as="choice"
                required
                label={t('gdpr')}
                endpoint={API.API_URL + "/common/gdprs/?query={id, title}"}
                text="title"
                value={form.gdpr}
                onChange={(e, { value }) => setForm({ ...form, gdpr: value })}
            />

            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel disabled={isProcessing} onClose={onClose} />
                <ModalSubmit loading={isProcessing} disabled={isProcessing || form.profile === "" || form.gdpr === ""} text={t('confirm')} />
            </Form.Field>
        </Form>
    );
};

export default AgreementForm;