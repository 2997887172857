import React, { useState } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
// store
import { routes } from '@routes'
import { API } from '@store/config'
import { fileDownload } from '@helpers/requests'
import { getRequest } from '@services/ServiceCommon'
import { dates } from '@store/services/helpers/dates'
import { useFetchData, useQueryPage } from '@helpers/hooks'
// components
import Icon from '@components/Icon'
import Paginator from '@components/Paginator'
import CanView from '@components/perms/CanView'
import Loading from '@components/general/Loading'
import EmptyRow from '@components/tables/EmptyRow'
import SuperField from '@components/forms/SuperField'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Form, Button, Grid, Divider, Segment } from 'semantic-ui-react'
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable'
// module specific components
import CardForm from '../components/cards/forms/CardForm'

const IssuedReturnedCardsView = ({ employees }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const history = useHistory()
    let page = useQueryPage()

    const [limit, setLimit] = useState(10)
    const [cards, setCards] = useFetchData(
        API.ASSET_ISSUE_CARDS + '?paginate=true&ordering=-created_on&page=' + page + '&limit=' + limit
    )

    const [filters, setFilters] = useState({
        employee: '',
        type: '',
    })

    const onFilter = async (params, isFiltered) => {
        setCards((prev) => ({
            ...prev,
            isLoading: true,
        }))

        if (isFiltered) {
            page = 1
        } else {
            let searchParams = new URLSearchParams(location.search)
            const param = searchParams.get('page')
            page = param !== null ? param : 1
        }

        let limitParam = limit
        if (params.includes('limit')) {
            let searchParams = new URLSearchParams(params)
            const retrievedLimit = searchParams.get('limit')
            setLimit(retrievedLimit)
            limitParam = retrievedLimit
        }

        if (!params.includes('page')) {
            params += '&page=' + page
        }

        if (!params.includes('limit')) {
            params += '&limit=' + limitParam
        }

        if (filters.employee !== '') params += '&employee=' + filters.employee
        if (filters.type !== '') params += '&status=' + filters.type
        const request = await getRequest(
            API.ASSET_ISSUE_CARDS +
                '?paginate=true&ordering=-created_on&exclude_profiles_without_stored_items=true' +
                params
        )

        if (request.status === 200) {
            setCards((prev) => ({
                ...prev,
                data: request.response,
            }))
        }

        setCards((prev) => ({
            ...prev,
            isLoading: false,
        }))
    }

    const showCard = async (id) => {
        await fileDownload(
            'GET',
            API.API_URL + `/exports/issue_card_pdf_view/v2/${id}/?is_blob=true`,
            'application/pdf',
            `card_${id}.pdf`
        )
    }

    return (
        <CanView permissions={['assets.c_view_all_issued_items', 'assets.c_view_user_issued_items']} redirect>
            <Grid>
                <Grid.Row style={{ padding: 0 }}>
                    <Grid.Column computer={8} tables={16} mobile={16}>
                        <Form
                            onSubmit={async () => {
                                history.replace({ pathname: location.pathname, search: `?page=${1}` })
                                await onFilter('', true)
                            }}
                        >
                            <Form.Group widths="equal">
                                <SuperField
                                    as="choice-select"
                                    search
                                    size="small"
                                    label={t('person')}
                                    text="fullname_with_titles"
                                    endpoint={API.EMPLOYEES}
                                    additionalFilters={'&profiles_with_asset_history=true&only_basic_info=true'}
                                    value={filters.employee}
                                    onChange={(e, { value }) => setFilters({ ...filters, employee: value })}
                                />
                                <SuperField
                                    as="choice"
                                    label={t('type')}
                                    type="issued_item_status"
                                    value={filters.type}
                                    onChange={(e, { value }) => setFilters({ ...filters, type: value })}
                                />
                                <Form.Field style={{ marginTop: '1.8rem' }}>
                                    <Button
                                        fluid
                                        primary
                                        disabled={cards.isLoading}
                                        loading={cards.isLoading}
                                        content={t('confirm')}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                    <Grid.Column computer={8} tables={16} mobile={16} style={{ textAlign: 'right' }}>
                        <Button.Group>
                            <CanView permissions={['assets.c_issue_items']}>
                                <SuperDuperModal
                                    size="large"
                                    header={t('return_card')}
                                    trigger={
                                        <Button
                                            style={{
                                                marginTop: '1.8rem',
                                                background: 'var(--dark)',
                                                color: 'var(--white)',
                                            }}
                                        >
                                            <Icon name="arrow-undo-outline" style={{ marginRight: '0.5rem' }} />
                                            <span style={{ position: 'relative', top: '-0.1rem' }}>
                                                {t('return_items')}
                                            </span>
                                        </Button>
                                    }
                                    content={<CardForm type={2} setCards={setCards} employees={employees} />}
                                />
                                <SuperDuperModal
                                    size="large"
                                    header={t('issue_card')}
                                    trigger={
                                        <Button style={{ marginTop: '1.8rem', marginLeft: '0.5rem' }} primary>
                                            <Icon name="clipboard-outline" style={{ marginRight: '0.5rem' }} />
                                            <span style={{ position: 'relative', top: '-0.1rem' }}>
                                                {t('issue_items')}
                                            </span>
                                        </Button>
                                    }
                                    content={<CardForm type={1} setCards={setCards} employees={employees} />}
                                />
                            </CanView>
                        </Button.Group>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider />

            <Segment
                loading={cards.isLoading}
                style={{
                    boxShadow: 'none',
                    border: 'none',
                    padding: 0,
                    background: 'transparent',
                }}
            >
                {cards.isLoading && (
                    <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '6rem' }}>
                        {' '}
                        {t('message_loading_assigned_items')}{' '}
                    </p>
                )}

                {!cards.isLoading && (
                    <>
                        <FlexTable>
                            <FlexRow>
                                <FlexHeader content={t('employee')} />
                                <FlexHeader content={t('type')} />
                                <FlexHeader content={t('items')} />
                                <FlexHeader content={t('created')} />
                                <FlexHeader content={t('actions')} />
                            </FlexRow>
                            <EmptyRow length={cards?.data?.results?.length || 0} />
                            {cards?.data?.results?.map((card) => (
                                <FlexRow background="var(--white)" fontSize="1rem" borders key={card.id}>
                                    <FlexItem bold>
                                        {card.employee?.fullname ? (
                                            <Link
                                                className="ref-link"
                                                to={routes.EMPLYOEES_DETAIL + card.employee.id}
                                                target="_blank"
                                            >
                                                {card.employee.fullname}
                                            </Link>
                                        ) : (
                                            '--'
                                        )}
                                    </FlexItem>
                                    <FlexItem bold content={card.status_display} />
                                    <FlexItem bold content={card.item_count} />
                                    <FlexItem>
                                        {moment(dates.convertUTCDateToLocalDate(card?.created_on)).format(
                                            'DD.MM.YYYY HH:mm'
                                        )}
                                        <br />
                                        <small>
                                            by <strong>{card?.created_by?.name}</strong>
                                        </small>
                                    </FlexItem>
                                    <FlexItem>
                                        <FlexRow background="transparent" padding="0">
                                            <FlexItem>
                                                <Icon
                                                    name="eye-outline"
                                                    style={{ color: 'var(--primary)', cursor: 'pointer' }}
                                                    onClick={() => showCard(card.id)}
                                                />
                                            </FlexItem>
                                        </FlexRow>
                                    </FlexItem>
                                </FlexRow>
                            ))}
                        </FlexTable>
                        <Paginator
                            forcePage={page || 1}
                            limit={limit}
                            setLimit={setLimit}
                            length={cards?.data?.count || 0}
                            onChange={(params) => onFilter(params)}
                        />
                    </>
                )}
            </Segment>
        </CanView>
    )
}

export default IssuedReturnedCardsView
