import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { routes } from '@routes/index'
// store
import { API } from '@store/config'
import { isEmpty } from '@helpers/validation'
import { requests } from '@helpers/requests'
import { getRandomColor } from '@helpers/functions'
// components
import CanView from '@components/perms/CanView'
import { Form, Message, Divider } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const ProjectForm = ({ record, setData, onClose, setHeader }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const [isProcessing, setIsProcessing] = useState(false) // true, false
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        name: record?.name || '',
        identifier: record?.identifier || '',
        description: record?.description || '',
        project_manager: record?.project_manager?.id || '',
        from_date: record?.from_date || '',
        due_date: record?.due_date || '',
        color: record?.color || getRandomColor(),
    })

    useEffect(() => {
        if (record?.id) {
            setHeader(t('update_project'))
        }
        // eslint-disable-next-line
    }, [])

    const handleSubmit = async () => {
        setErrors(null)
        setIsProcessing(true)

        if (record?.id) {
            const request = await requests.patch(API.PROJECTS + record?.id + '/', {
                ...form,
                from_date: form.from_date ? form.from_date : null,
                due_date: form.due_date ? form.due_date : null,
                project_manager: form.project_manager ? form.project_manager : null,
            })
            if (request.status === 200) {
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === record.id) {
                            item = request.response
                        }

                        return item
                    })
                )
            }
        } else {
            const request = await requests.post(API.PROJECTS, {
                ...form,
                from_date: form.from_date ? form.from_date : null,
                due_date: form.due_date ? form.due_date : null,
                project_manager: form.project_manager ? form.project_manager : null,
            })

            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                history.push(routes.PROJECT_DETAIL + request.response?.id)
            }
        }
        setIsProcessing(false)
    }

    const replaceSpaces = (str) => {
        let strD = ''
        strD = str.replace(/\s+/g, '-').toLowerCase()
        return strD
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors?.non_field_errors?.length > 0}
                header={t('error_submission_message')}
                list={errors?.non_field_errors}
            />
            <Form.Group widths={2}>
                <SuperField
                    as="input"
                    required
                    autoFocus
                    label={t('name')}
                    placeholder={t('name')}
                    error={errors?.name || false}
                    value={form.name}
                    onChange={(e, { value }) => setForm({ ...form, name: value, identifier: replaceSpaces(value) })}
                />
                <SuperField
                    as="input"
                    required
                    label={t('identifier')}
                    placeholder={t('project_identifier')}
                    error={errors?.identifier || false}
                    value={form.identifier}
                    onChange={(e, { value }) => setForm({ ...form, identifier: replaceSpaces(value) })}
                />
            </Form.Group>

            <SuperField
                as="textarea"
                label={t('description')}
                placeholder={t('description')}
                error={errors?.description || false}
                value={form?.description}
                onChange={(e, { value }) => setForm({ ...form, description: value })}
            />

            <Form.Group widths="equal">
                <SuperField
                    as="datepicker"
                    label={t('date_from')}
                    error={errors?.from_date || false}
                    value={form?.from_date}
                    onChange={(e, { value }) => setForm({ ...form, from_date: value })}
                />
                <SuperField
                    as="datepicker"
                    label={t('date_to')}
                    value={form?.due_date}
                    error={errors?.due_date || false}
                    onChange={(e, { value }) => {
                        value === '' || value === null
                            ? setForm({ ...form, due_date: null })
                            : setForm({ ...form, due_date: value })
                    }}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <CanView permissions={['projects.c_assign_project_manager']}>
                    <SuperField
                        search
                        as="choice-select"
                        text="fullname_with_titles"
                        label={t('project_manager')}
                        error={errors?.project_manager || false}
                        value={form?.project_manager}
                        onChange={(e, { value }) => setForm({ ...form, project_manager: value })}
                        endpoint={API.EMPLOYEES}
                        additionalFilters="&only_basic_info=true&is_active=true"
                        initialOptions={{
                            attribute: "fullname",
                            source: record?.project_manager
                        }}
                    />
                </CanView>
                <SuperField
                    as="colorpicker"
                    label={t('color')}
                    help={t('color_to_improve_visual_in_graphs')}
                    color={form.color}
                    setColor={(value) => setForm((prev) => ({ ...prev, color: value }))}
                />
            </Form.Group>
            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} disabled={isProcessing} />
                <ModalSubmit
                    disabled={isProcessing || isEmpty(form.name) || isEmpty(form.identifier)}
                    loading={isProcessing}
                />
            </Form.Field>
        </Form>
    )
}

export default ProjectForm
