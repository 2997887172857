import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests, fileDownload } from '@helpers/requests'
import { isEmpty } from '@helpers/validation'
// components
import { Form, Divider, Header } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const AllowedUserForm = ({ onClose, vehicle, record, setData }) => {
    const { t } = useTranslation()
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [templates, setTemplates] = useState([])
    const [generate_doc, setGenerateDoc] = useState(false)
    const [form, setForm] = useState({
        valid_until: record?.valid_until || '',
        contract: record?.contract?.id || '',
        vehicle: vehicle.id,
    })

    const handleSubmit = async () => {
        setErrors(null)
        setProcessing(true)

        let request = undefined
        let data = form
        if (data.contract === '') data.contract = null
        if (data.valid_until === '') data.valid_until = null
        if (record?.id === undefined) {
            // create logic
            request = await requests.post(API.VEHICLES + 'allowed_users/', data)
        } else {
            // update logic
            request = await requests.patch(API.VEHICLES + 'allowed_users/' + record?.id + '/', data)
        }

        if (request !== undefined) {
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                setData((prev) => [request.response, ...prev])
            }
            if (request.status === 200) {
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === record?.id) {
                            item = request.response
                        }

                        return item
                    })
                )
            }

            if ([200, 201].includes(request.status)) {
                const response = request.response
                if (generate_doc) {
                    for (let i = 0; i < templates.length; i++) {
                        if (templates[i].checked === true) {
                            const request = await requests.get(
                                API.TEMPLATING + `${templates[i].id}/fill/vehicles.VehicleAllowedUser/${response.id}`
                            )
                            if (request.status === 200) {
                                await fileDownload(
                                    'POST',
                                    API.EXPORTS_HTML_TO_PDF,
                                    'application/pdf',
                                    `${templates[i].title}-${i + 1}.pdf`,
                                    {
                                        content: request.response.html,
                                    }
                                )
                            }
                        }
                    }
                }

                onClose()
            }
        }

        setProcessing(false)
    }

    async function fetchTemplates() {
        const request = await requests.get(API.TEMPLATING + '?source=vehicles.VehicleAllowedUser&is_archived=false')
        if (request.status === 200) {
            let template_list = request.response.map((item) => ({ checked: false, ...item }))
            if (template_list.length === 1) {
                template_list[0].checked = true
            }
            setTemplates(template_list)
        }
    }

    useEffect(() => {
        fetchTemplates()
    }, [])

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField
                as="choice-select"
                required
                search
                endpoint={API.CONTRACTS}
                text={(item) => item?.employee?.fullname_with_titles}
                additionalFilters={'&query={id, employee{id, fullname_with_titles}}&status=1'}
                label={t('vehicle_responsible_person')}
                value={form.contract}
                error={errors?.contract?.[0] || false}
                onChange={(e, { value }) => setForm({ ...form, contract: value })}
                initialOptions={{
                    attribute: 'fullname_with_titles',
                    source: record?.contract?.employee,
                }}
            />
            <SuperField
                as="datepicker"
                label={t('valid_until')}
                value={form.valid_until}
                error={errors?.valid_until?.[0] || false}
                onChange={(e, { value }) => setForm({ ...form, valid_until: value })}
            />

            {record?.id === undefined && (
                <>
                    <Divider />
                    <SuperField
                        as="checkbox"
                        label={t('generate_documents_for_this_vehicle')}
                        checked={generate_doc}
                        onChange={(e, { value }) => setGenerateDoc(!generate_doc)}
                    />

                    {generate_doc && (
                        <>
                            <Header as="h4">{t('choose_documents')}:</Header>
                            {templates.map((template) => (
                                <SuperField
                                    as="checkbox"
                                    key={template.id}
                                    label={t('template') + ' - ' + template.title}
                                    checked={template.checked}
                                    onChange={() =>
                                        setTemplates((prev) =>
                                            prev.filter((item) => {
                                                if (item.id === template.id) {
                                                    item.checked = !template.checked
                                                }

                                                return item
                                            })
                                        )
                                    }
                                />
                            ))}
                        </>
                    )}
                </>
            )}

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit disabled={processing || isEmpty(form.contract)} loading={processing} text={t('confirm')} />
            </Form.Field>
        </Form>
    )
}

export default AllowedUserForm
