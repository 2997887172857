import React, { useState, useEffect } from 'react';
import { getQueryParam } from '@helpers/functions';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
import { useSelector } from 'react-redux';
import moment from 'moment';
// store
import { icons } from '@icons';
import { API } from '@store/config';
import { requests } from '@helpers/requests';  
// components
import Icon from '@components/Icon';
import { Form, Divider, Header, Segment, Label, Button, Grid, Popup } from 'semantic-ui-react';
import { Icon as SemanticIcon } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import SpinnerSegment from '@components/SpinnerSegment';
import EmptyRow from '@components/tables/EmptyRow';
import ModalCancel from '@components/buttons/ModalCancel';
import Paginator from '@components/Paginator';

const HROrderFulfillment = ({ record, setData, customDataHandler, onClose }) => {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(false)
    // eslint-disable-next-line
    const [candidates, setCandidates] = useState([])
    const [recruitment, setRecruitment] = useState('')
    const [stage, setStage] = useState('')
    const [search, setSearch] = useState("")

    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    const dateFormat = useSelector(state => state.date_format)
    
    const [selectedCandidates, setSelectedCandidates] = useState([]) 
    const [hiringRooms, setHiringRoom] = useState([])
    const [fetching, setFetching] = useState(false)
    const [processing, setProcessing] = useState(false)

    const [dateAutofill, setDateAutofill] = useState('')
    const [noteAutofill, setNoteAutofill] = useState('')

    useEffect(() => {
        async function fetchRooms(){
            setFetching(true)
            const request = await requests.get(API.HIRING_ROOMS + "?query={id,name,archived_recruitment_stages}&hrorder=" + record.id)
            if( request.status === 200 ){
                setHiringRoom(request.response)
            }
            setFetching(false)
        }

        fetchRooms()
        // eslint-disable-next-line
    }, [])

    const getStagesByRoom = (roomID) => {
        let stages = []
        
        if (hiringRooms.length > 0 && roomID > 0) {
            stages = hiringRooms.find(item => item.id === roomID)?.archived_recruitment_stages || []
        }  

        return stages.map(item => ({ id: item.id, value: item.id, text: item.name }))
    }

    async function fetchCandidates(params, isFiltered) {
        setLoading(true)
        params = params || ""
        params += "&is_active=true"
        params += "&exclude_blacklisted=true"
        params += "&exclude_assigned_candidates_from_order=" + record.id
        // params += recruitment && ("&recruitment=" + recruitment)
        // params += stage && ("&current_stage=" + stage)

        if ( search !== "" ) {
            params += "&search=" + search
        }

        if (isFiltered) {
            setPage(1)
            // params += `&page=${1}&limit=${limit}`
        }

        if (!params.includes('recruitment') && recruitment !== "") {
            params += '&recruitment=' + recruitment
        }
        if (!params.includes('current_stage') && stage !== "") {
            params += '&current_stage=' + stage
        }

        if (!params.includes('limit')) {
            params += '&limit=' + limit
        }
        if (!params.includes('page')) {
            params += '&page=' + page
        } else {
            const pageNumber = getQueryParam("page", params)
            params += '&page=' + pageNumber
            setPage(pageNumber)
        }

        let queryParam = "?paginate=true" + params

        const request = await requests.get(`${API.CANDIDATES}${queryParam}`)
        if (request.status === 200) {
            setTotal(request.response.count)
            setCandidates(request.response.results)
        }
        setLoading(false)
    }

    const removeRef = (candidate) => {
        setSelectedCandidates(prev => prev.filter(item => item.candidate.id !== candidate.id))
    }

    const onSearch = async () => {
        await fetchCandidates("", true)
    }

    const handleConfirm = async () => {
        setProcessing(true)
        let totalToAdd = selectedCandidates.length 
        let totalAdded = 0
        for(let i = 0; i < selectedCandidates.length; i++){
            let dataset = {
                order: record.id,
                resourcetype: "HRFulfillment",
                date: selectedCandidates[i].date,
                note: selectedCandidates[i].note,
                profile: selectedCandidates[i].candidate.id,
                recruitment: selectedCandidates[i].recruitment,
                total_fee: record.fee.sum,
                currency: record.fee.currency,
            }
    
            const request = await requests.post(API.ORDERS + 'fulfillments/', dataset)
            if (request.status === 201) {
                totalAdded = totalAdded + 1
            }
        }

        if (totalToAdd === totalAdded) {
            // fetch order
            const request = await requests.get(API.ORDERS + record.id + "/") 
            if (request.status === 200) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('fulfillments_created'),
                    animation: 'pulse',
                    time: 2000,
                })
                if (customDataHandler) {
                    customDataHandler(request.response)
                } else {
                    setData(prev => prev.map(item => {
                        if (item.id === record.id) {
                            item = request.response
                        }
    
                        return item
                    }))
                }
                onClose()
            }
        }

        setProcessing(false)
    }

    return (
        <>
        <Form>
            <Grid style={{ padding: 0, margin: 0 }}>
                <Grid.Row columns={2} style={{ padding: 0, margin: 0 }}>
                    <Grid.Column textAlign='left' style={{ paddingLeft: 0 }}>
                        <Header as="h4" content={t("select_candidates")}/>
                    </Grid.Column>
                    <Grid.Column textAlign='right' style={{ paddingRight: 0 }}>
                        {t("number_of_selected") + ": " } <strong>{selectedCandidates.length}</strong>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            
            <Divider />

            <Grid>
                <Grid.Row columns={2} style={{padding:0}}>
                    <Grid.Column>
                        <SuperField as="choice" 
                            key={fetching}
                            loading={fetching}
                            disabled={fetching}
                            customOptions={hiringRooms.map(item => ({ id: item.id, value: item.id, text: item.name }))}
                            search
                            text="name"
                            label={t('hiring_room')}
                            value={recruitment}
                            onChange={(e, { value }) => {
                                setRecruitment(value)
                                setStage(value ? getStagesByRoom(value)[getStagesByRoom(value).length - 1].value : '')
                                if (value !== "") {
                                    fetchCandidates(`&recruitment=${value}&current_stage=${getStagesByRoom(value)[getStagesByRoom(value).length - 1].value}`, true)
                                } else {
                                    setCandidates([])
                                    setTotal(0)
                                }
                            }}
                        />
                    </Grid.Column>
                    
                    <Grid.Column>
                        <SuperField as="choice" 
                            key={recruitment}
                            text="name"
                            label={t('stage')}
                            value={stage}
                            disabled={recruitment === ""}
                            customOptions={getStagesByRoom(recruitment)}
                            onChange={(e, { value }) => {
                                setStage(value)
                                if (value !== "") {
                                    fetchCandidates(`&current_stage=${value}`, true)
                                } else {
                                    fetchCandidates("", true)
                                }
                            }}
                        />
                    </Grid.Column>

                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <SuperField as="input"
                            fluid
                            autoFocus
                            disabled={loading}
                            icon={
                                <SemanticIcon
                                    link
                                    name="search"
                                    onClick={() => onSearch()}
                                />
                            }
                            value={search}
                            placeholder={t('search')}
                            onChange={(e, { value }) => setSearch(value)}
                            onKeyPress={(event) => {
                                if (event.key === "Enter"){
                                    onSearch()
                                }
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Divider />

            { selectedCandidates.length > 0 &&
                <Label.Group size="tiny" style={{ marginTop: "0.5rem" }}>
                    { selectedCandidates.map((item, idx) => (
                        <Label basic key={idx}> 
                            { item.candidate.fullname } {" "}
                            <Popup
                                trigger={
                                    <Icon name={"calendar-outline"} style={{marginLeft: "1rem"}}/>
                                }
                                flowing
                                size="small"
                                inverted
                                position="top center"
                                hoverable>
                                { moment(item.date).format(dateFormat) }
                            </Popup>
                            { item.note &&
                                <Popup
                                    trigger={
                                        <Icon name={"document-text-outline"} style={{marginLeft: "0.5rem"}}/>
                                    }
                                    flowing
                                    size="small"
                                    inverted
                                    position="top center"
                                    hoverable>
                                    { item.note }
                                </Popup>
                            }
                            <Icon 
                                name="close-outline" 
                                onClick={() => removeRef(item.candidate)}
                                style={{ color: "var(--danger)", marginLeft: "0.3rem", position: "relative", top: "0.05rem", cursor: "pointer" }}
                            />
                        </Label>
                    )) }
                </Label.Group>
            }
            
            <SpinnerSegment loading={loading}>
                <EmptyRow length={candidates.length}/>
                { candidates.map((fulfillment, idx) => (
                    <Fulfillment 
                        key={idx}
                        isFirst={idx === 0}
                        candidate={fulfillment}
                        removeRef={removeRef}
                        dateFormat={dateFormat}
                        processing={processing}
                        selectedCandidates={selectedCandidates}
                        setSelectedCandidates={setSelectedCandidates}
                        dateAutofill={dateAutofill}
                        setDateAutofill={setDateAutofill}
                        noteAutofill={noteAutofill}
                        setNoteAutofill={setNoteAutofill}
                    />
                ))}

                {total > 0 && (
                    <Paginator
                        forcePage={page}
                        forceReload={false}
                        limit={limit}
                        setLimit={setLimit}
                        length={total}
                        onChange={(params) => fetchCandidates(params)}
                    />
                )}

            </SpinnerSegment>

            <Divider/>
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose}/>
                <Button 
                    primary
                    type="button"
                    content={t('confirm')}
                    onClick={() => handleConfirm()}
                    disabled={selectedCandidates.length === 0 || processing}
                    loading={processing}
                />
            </Form.Field>
        </Form>
        </>
    )
}

const Fulfillment = ({ isFirst, candidate, removeRef, processing, dateFormat, selectedCandidates, setSelectedCandidates, dateAutofill, setDateAutofill, noteAutofill, setNoteAutofill }) => {
    const { t } = useTranslation()

    const [date, setDate] = useState('')
    const [note, setNote] = useState('')

    const addedCandidate = selectedCandidates.filter(item => item.candidate.id === candidate.id).length !== 0

    useEffect(() => {
        if(!addedCandidate){
            setDate(dateAutofill)
            setNote(noteAutofill)
        }
        // eslint-disable-next-line
    }, [dateAutofill, noteAutofill])

    const handleCandidate = (candidate, date, note) => {
        const fullCandidate = {
            candidate: candidate,
            date: date,
            note: note,
            recruitment: candidate?.hiring_rooms?.[0]?.recruitment_id || null
        }
        if (selectedCandidates.filter(item => item.candidate.id === candidate.id).length === 0) {
            setSelectedCandidates(prev => ([...prev, fullCandidate]))
        } else {
            setSelectedCandidates(prev => prev.filter(item => item.candidate.id !== candidate.id))
        }
    }

    const autofillData = (date, note) => {
        setDateAutofill(date)
        setNoteAutofill(note)
    }

    const ListHiringRooms = ({ data }) => {

        const maxVisible = 1

        return(
            <Popup
                hoverable
                position="left center"
                trigger={
                    <Label basic style={{ marginTop: "0.5rem" }}>
                        <span style={{ fontSize: "0.8rem", color: "var(--dark)" }}>
                            { (data.length - maxVisible) } {" "}
                            <span style={{ textTransform: "lowercase" }}>{(data.length - maxVisible) === 1 ? t('hiring_room') : t('hiring_rooms')}</span>...
                        </span>
                    </Label>
                }
                content={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        { data?.map((candidate, idx) => (
                            <>
                                { idx + 1 > maxVisible  && 
                                    <>
                                        <Label 
                                            content={
                                                <>
                                                    <Icon name={icons?.HIRING_ROOM} />
                                                    <span style={{paddingLeft: "0.5rem"}}>{ candidate?.recruitment}</span>
                                                </>
                                            } 
                                        />
                                    </>
                                }
                            </>
                        ))}
                    </div>
                }
            />
        );
    }

    return (
        <Segment
            style={{ 
                marginTop: isFirst ? "0.5rem" : "1rem",
                display: "flex",
                alignItems: "center",
                marginBottom: "0.5rem",
                borderBottom: addedCandidate ? "2px solid var(--primary)" : "1px solid rgba(34,36,38,.15)" 
            }}
        >
            <Grid>
                <Grid.Row columns={3} style={{ padding: 0 }} verticalAlign="middle">
                    <Grid.Column width={4} style={{ padding: 0 }}>
                        <strong>{candidate?.fullname_with_titles}</strong>
                    </Grid.Column>
                    <Grid.Column width={9} textAlign='right' style={{padding: 0, paddingRight: "1rem"}}>
                        { false // candidate?.in_active_recruitment 
                            ? 
                                <>
                                    <Label 
                                        basic
                                        content={
                                            <>
                                                <Icon name={icons?.HIRING_ROOM} />
                                                <span style={{paddingLeft: "0.5rem"}}>{ candidate?.hiring_rooms[0].recruitment}</span>
                                            </>
                                        } 
                                    />
                                    { candidate?.current_stage &&
                                        <Label
                                            content={
                                                <>
                                                    <Icon name={candidate?.current_stage?.icon ? candidate.current_stage.icon : ''} />
                                                    <span style={{paddingLeft: "0.5rem"}}>{candidate?.current_stage?.name && candidate.current_stage.name}</span>
                                                </>
                                            } 
                                            style={{backgroundColor: candidate?.current_stage?.color, color: "white"}}
                                        />
                                    }
                                    { candidate?.hiring_rooms.length > 1 && 
                                        <ListHiringRooms data={candidate?.hiring_rooms} />
                                    }
                                </>
                            : <div style={{ width: "100px" }}></div>
                        }
                    </Grid.Column>
                    <Grid.Column width={3} style={{ padding: 0 }} textAlign="center">
                        {!addedCandidate && isFirst ?
                            <Button 
                                content={t('autofill_data')} 
                                disabled={!date}
                                onClick={() => autofillData(date, note)} style={{background: 'none', margin: 0, padding: 0, fontSize: 12, width: "8rem", textDecorationLine: "underline"}} 
                            />
                            :
                            <div
                                style={{margin: 0, padding: 0, width: "8rem"}} 
                            />
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3} style={{ padding: 0, paddingTop: "0.5rem" }}>
                    <Grid.Column width={13} style={{padding: 0}}>
                        <Form.Group style={{ marginBottom: 0 }}>
                            <SuperField as="datepicker"
                                disabled={addedCandidate}
                                value={date}
                                placeholder={t('date')}
                                onChange={(e, { value }) => setDate(value)}
                            />
                            <SuperField as="input"
                                width="9"
                                size="small"
                                placeholder={t('note')}
                                value={note}
                                disabled={addedCandidate}
                                onChange={(e, { value }) => setNote(value)}
                                style={{height: "2.7rem"}}
                            />
                        </Form.Group>
                    </Grid.Column>
                    {/* <Grid.Column width={9} style={{padding: 0, paddingLeft: "1rem", paddingRight: "1rem"}}>
                    </Grid.Column> */}
                    <Grid.Column width={3} style={{padding: 0}} textAlign="center">
                        {!addedCandidate
                            ? 
                                date
                                ? <Button primary content={t('add')} disabled={processing} onClick={() => handleCandidate(candidate, date, note)} style={{ width: "8rem", height: "2.7rem" }} />
                                : 
                                <Popup
                                    trigger={
                                        <div>
                                            <Button primary content={t('add')} disabled={!date} onClick={() => handleCandidate(candidate, date, note)} style={{ width: "8rem", height: "2.7rem" }} />
                                        </div>
                                    }
                                    flowing
                                    size="small"
                                    inverted
                                    position="left center"
                                    hoverable
                                >
                                    { t('add_date') }
                                </Popup>
                            : <Button disabled={processing} content={t('remove')} onClick={() => removeRef(candidate)} style={{ width: "8rem", height: "2.7rem", background: "var(--dark)", color: "var(--white)" }} />
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
}

export default HROrderFulfillment;