import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { setDotSeparator } from '@helpers/functions';
import { createRequest, updateRequest } from '@services/ServiceCommon';
// components 
import { Form, Divider } from 'semantic-ui-react';
import ApprovalForm from '../../approvals/components/ApprovalForm';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const BudgetForm = ({ budget, onClose, onSubmit }) => {
    const { t } = useTranslation();

    const [isProcessing, setIsProcessing] = useState(false)
    const [form, setForm] = useState({
        name: budget?.id ? budget.name : "",
        amount: budget?.id ? budget.amount.sum : 0,
        currency: budget?.id ? budget.amount.currency : "EUR",
        dateFrom: budget?.id ? budget.date_from : "",
        dateTo: budget?.id ? budget.date_to : "",
        costCenters: budget?.id ? budget.cost_centers.map(center => center.id) : []
    })

    const [approval, setApproval] = useState(null)

    const handleSubmit = async () => {
        setIsProcessing(true)
        let request = undefined;
        const data = {
            amount: {
                sum: form.amount,
                currency: form.currency
            },
            // currency: form.currency,
            name: form.name,  
            date_from: form.dateFrom === "" ? null : form.dateFrom,  
            date_to: form.dateTo === "" ? null : form.dateTo,  
            cost_centers: {
                add: form.costCenters
            }
        }

        if( budget?.id === undefined ){ // create
            request = await createRequest(API.COST_CENTERS_BUDGETS, data)
        } else { // update 
            request = await updateRequest(API.COST_CENTERS_BUDGETS + budget.id + "/", {
                ...data,
                cost_centers:{
                    remove: budget?.id ? budget.cost_centers.map(center => center.id) : [],
                    ...data.cost_centers
                }
            })
        }

        if( request !== undefined ){
            if( request.status === 201 ) onSubmit(request.response);
            if( request.status === 200 ) onSubmit(request.response);
            if( request.status === 200 || request.status === 201 ){
                setApproval(request.response.approval)
            }

            if( request.response.approval.responsible_person !== null ){
                onClose()
            }
        }
        setIsProcessing(false)
    }

    return (
        <>
            { approval === null
                ?
                <Form onSubmit={handleSubmit}>
                    <SuperField as="input"
                        required
                        autoFocus
                        label={t('name')}
                        value={form.name}
                        onChange={(e, { value }) => setForm({ ...form, name: value })}
                    />
                    <Form.Group widths="equal">
                        <SuperField as="input"
                            required
                            label={t('amount')}
                            value={form.amount}
                            onChange={(e, { value }) => setForm({ ...form, amount: setDotSeparator(value) })}
                        />
                        <SuperField as="choice"
                            search
                            type="currency_codes"
                            label={t('currency')}
                            value={form.currency}
                            onChange={(e, { value }) => setForm({ ...form, currency: value })}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField
                            required
                            as='datepicker'
                            label={t("date_from")}
                            value={form.dateFrom}
                            onChange={(e, { value }) => setForm({ ...form, dateFrom: value })}
                        />
                        <SuperField
                            required
                            as='datepicker'
                            label={t("date_to")}
                            value={form.dateTo}
                            onChange={(e, { value }) => setForm({ ...form, dateTo: value })}
                        />
                    </Form.Group>

                    <SuperField
                        multiple
                        as='choice'
                        text="title"
                        value={form.costCenters}
                        label={t("cost_centers")}
                        endpoint={API.COST_CENTERS + "?query={id, title}"}
                        onChange={(e, { value }) => setForm({ ...form, costCenters: value })}
                    />

                    <Divider/>

                    <Form.Field style={{ textAlign: "right" }}>
                        <ModalCancel onClose={onClose} disabled={isProcessing} />
                        <ModalSubmit
                            disabled={isProcessing || form.amount === "" || form.dateFrom === "" || form.dateTo === ""}
                            loading={isProcessing}
                            text={t('confirm')}
                        />
                    </Form.Field>
                </Form>
                : approval?.responsible_person === null && (
                    <ApprovalForm 
                        approval={approval} 
                        onClose={onClose}
                        permission="cost_centers.c_view_budgets"
                    />
                )
            }
            
        </>
    );
};

export default BudgetForm;