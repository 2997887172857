import { APIRequest } from './helpers/requests';
import { API } from '../config';

/*
 * Service functions
 */
export const employeesService = {
    getEmployees,
    deleteEmployee,
    getEmployee,
    updatePhone,
    updateEmail,
    updateEmployee,
    getChoices,
    getStats,
    getIssues,
    getClosedIssues,
    createProfile,
    createPersonalInformation,
    updatePersonalInformation,
    updateBirthInfo,
    updatePersons,
    updateAddress,
    updateBirthInfoFamily,
    updatePersonType,
    updateFamilyMembers,
    addPersons,
    addFamilyMembers,
    addPersonType,
    addBirthInfoFamily,
    addAddress,
    deleteFamily,
    getPersonTypes,
    getPersonType,
    getUnitName,
    getActiveCompany,
    getUnits,
    uploadPicture,
    deletePicture
};

async function getUnits(params) {
    params = params || ""
    return await APIRequest({
        url: API.UNIT_NAME + params,
        method: 'GET',
        data: {},
        private: true
    });
}


async function getActiveCompany() {
    return await APIRequest({
        url: API.ACTIVE_COMPANY + "?is_active=true",
        method: 'GET',
        data: {},
        private: true
    });
}

async function getUnitName(id, params) {
    params = params || ""
    return await APIRequest({
        url: API.UNIT_NAME + id + "/" + params,
        method: 'GET',
        data: {},
        private: true
    });
}

// updateEmployeeEmail
async function updateEmail(id, data) {
    return await APIRequest({
        url: API.CREATE_EMAIL + id + "/",
        method: "PATCH",
        data: data,
        private: true
    });
}

// updateEmployeePhone
async function updatePhone(id, data) {
    return await APIRequest({
        url: API.CREATE_PHONE + id + "/",
        method: "PATCH",
        data: data,
        private: true
    });
}


// getEmployees:
async function getEmployees(params) {
    params = params || ""
    return await APIRequest({
        url: API.GET_EMPLOYEES + params,
        method: 'GET',
        data: {},
        private: true
    });
}

// deleteEmployee:
async function deleteEmployee(id) {
    return await APIRequest({
        url: API.DELETE_EMPLOYEE + id + "/",
        method: 'DELETE',
        private: true
    });
}

// getEmployee:
async function getEmployee(id) {
    return await APIRequest({
        url: API.GET_EMPLOYEE + id + "/",
        method: 'GET',
        data: {},
        private: true
    });
}

//updateEmployee:
async function updateEmployee(data, id) {
    return await APIRequest({
        url: API.GET_EMPLOYEE + id + '/',
        method: 'PATCH',
        data: data,
        private: true
    });
}

//updatePersonalInformation:
async function updatePersonalInformation(data, id) {
    return await APIRequest({
        url: API.PERSONAL_INFORMATION + id + '/',
        method: 'PATCH',
        data: data,
        private: true
    });
}

async function createPersonalInformation(data) {
    return await APIRequest({
        url: API.PERSONAL_INFORMATION,
        method: 'POST',
        data: data,
        private: true
    });
}

async function createProfile(data) {
    return await APIRequest({
        url: API.GET_EMPLOYEES,
        method: 'POST',
        data: data,
        private: true
    });
}

// getChoices:
async function getChoices() {
    return await APIRequest({
        url: API.CHOICES,
        method: 'GET',
        data: {},
        private: true
    });
}

// getStats:
async function getStats(id) {
    return await APIRequest({
        url: API.EMPLOYEES_STATS + id + API.STATS,
        method: 'GET',
        data: {},
        private: true
    });
}

// getIssues:
async function getIssues(query_params, id) {
    query_params = query_params || ""
    return await APIRequest({
        url: API.EMPLOYEES_STATS + id + API.STATS + 'issues/' + query_params,
        method: 'GET',
        data: {},
        private: true
    });
}

//updateBirthInfoFamily:
async function updateBirthInfoFamily(data, id) {
    return await APIRequest({
        url: API.BIRTH_INFO_FAMILY + id + '/',
        method: 'PATCH',
        data: data,
        private: true
    });
}

async function addBirthInfoFamily(data) {
    return await APIRequest({
        url: API.BIRTH_INFO_FAMILY,
        method: 'POST',
        data: data,
        private: true
    });
}

async function updatePersonType(data, id) {
    return await APIRequest({
        url: API.PERSON_TYPE + id + '/',
        method: 'PATCH',
        data: data,
        private: true
    });
}

async function addPersonType(data) {
    return await APIRequest({
        url: API.PERSON_TYPE,
        method: 'POST',
        data: data,
        private: true
    });
}

async function updateFamilyMembers(data, id) {
    return await APIRequest({
        url: API.FAMILY_MEMBERS + id + '/',
        method: 'PATCH',
        data: data,
        private: true
    });
}

async function deleteFamily(id) {
    return await APIRequest({
        url: API.FAMILY_MEMBERS + id + '/',
        method: 'DELETE',
        private: true
    });
}

async function addFamilyMembers(data) {
    return await APIRequest({
        url: API.FAMILY_MEMBERS,
        method: 'POST',
        data: data,
        private: true
    });
}

// getClosedIssues:
async function getClosedIssues(query_params, id) {
    query_params = query_params || ""
    return await APIRequest({
        url: API.EMPLOYEES_STATS + id + API.STATS + 'issues/?state=2' + query_params,
        method: 'GET',
        data: {},
        private: true
    });
}

//updateBirthInfo:
async function updateBirthInfo(data, id) {
    return await APIRequest({
        url: API.BIRTH_INFO + id + '/',
        method: 'PATCH',
        data: data,
        private: true
    });
}

//updatePersons:
async function updatePersons(data, id) {
    return await APIRequest({
        url: API.PERSONS + id + '/',
        method: 'PATCH',
        data: data,
        private: true
    });
}

async function addPersons(data) {
    return await APIRequest({
        url: API.PERSONS,
        method: 'POST',
        data: data,
        private: true
    });
}

//updateAddress
async function updateAddress(data, id) {
    return await APIRequest({
        url: API.CREATE_ADDRESS + id + '/',
        method: "patch",
        data: data,
        private: true
    })
}

async function addAddress(data) {
    return await APIRequest({
        url: API.CREATE_ADDRESS,
        method: "POST",
        data: data,
        private: true
    })
}

async function getPersonTypes() {
    return await APIRequest({
        url: API.PERSON_TYPE,
        method: 'GET',
        data: {},
        private: true
    });
}

async function getPersonType(id) {
    return await APIRequest({
        url: API.PERSON_TYPE + id + '/',
        method: 'GET',
        data: {},
        private: true
    });
}

async function uploadPicture(id, data) {
    return await APIRequest({
        url: API.GET_EMPLOYEE + id + '/picture/',
        method: 'POST',
        data: data,
        private: true
    });
}
async function deletePicture(id) {
    return await APIRequest({
        url: API.GET_EMPLOYEE + id + '/picture/',
        method: 'DELETE',
        private: true
    });
}