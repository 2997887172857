import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { thousandsSeparators, setDotSeparator, isValidNumber, isDescendantElement } from '@helpers/functions'
// components
import Icon from '@components/Icon'
import CanView from '@components/perms/CanView'
import EmptyRow from '@components/tables/EmptyRow'
import SuperField from '@components/forms/SuperField'
import NonFieldErrors from '@components/NonFieldErrors'
import SpinnerSegment from '@components/SpinnerSegment'
import ModalCancel from '@components/buttons/ModalCancel'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Divider, Form, Button, Message, Header, Dropdown, Input, Label, Icon as SemanticIcon } from 'semantic-ui-react'
// specific components
import WarehousePositionForm from './WarehousePositionForm'

const StockItemForm = ({ record, setData, onClose, setTotal, setSize, setHeader, warehouses, selectedWarehouse }) => {
    const [storedItem, setStoredItem] = useState(record)
    const [isNew, setIsNew] = useState(false)

    return (
        <>
            {storedItem === undefined ? (
                <StockVerificationForm
                    setData={setData}
                    setSize={setSize}
                    onClose={onClose}
                    setHeader={setHeader}
                    setStoredItem={setStoredItem}
                    setIsNew={setIsNew}
                    warehouses={warehouses}
                    selectedWarehouse={selectedWarehouse}
                />
            ) : (
                <StockForm
                    setData={setData}
                    setTotal={setTotal}
                    onClose={onClose}
                    isNew={isNew}
                    storedItem={storedItem}
                />
            )}
        </>
    )
}

const StockVerificationForm = ({
    onClose,
    setIsNew,
    setSize,
    setStoredItem,
    setHeader,
    selectedWarehouse,
    warehouses,
}) => {
    const { t } = useTranslation()
    const [processing, setProcessing] = useState(false)
    const [fetching, setFetching] = useState(false)
    const [items, setItems] = useState([])
    const [form, setForm] = useState({
        item: '',
        warehouse: selectedWarehouse?.id || '',
    })

    useEffect(() => {
        async function fetchCatalogueItems() {
            setFetching(true)
            const request = await requests.get(
                API.CATALOGUE_ITEMS + '?query={id, title, code, weight_is_primary_measurement}&is_service=false'
            )
            if (request.status === 200) setItems(request.response)
            setFetching(false)
        }

        fetchCatalogueItems()
    }, [])

    const isFormValid = () => {
        if (form.item === '') return false
        if (form.warehouse === '') return false

        return true
    }

    const handleSubmit = async () => {
        setProcessing(true)
        const request = await requests.get(
            API.ASSET_STORED_ITEMS + '?catalogue_item=' + form.item + '&warehouse=' + form.warehouse
        )
        if (request.status === 200) {
            if (request.response.length > 0) {
                setStoredItem(request.response[0])
                // setSize("small")
                setHeader(undefined)
            } else {
                const selectedItem = items.find((item) => item.id === parseInt(form.item))
                // create storedItem
                const request = await requests.post(API.ASSET_STORED_ITEMS, {
                    catalogue_item: form.item,
                    code: items.find((item) => item.id === form.item)?.code || '',
                    item: null,
                    warehouse: form.warehouse,
                    weight_is_primary_measurement: selectedItem?.weight_is_primary_measurement || false,
                    quantity: 0,
                    is_active: true,
                })

                if (request.status === 201) {
                    setStoredItem(request.response)
                    setIsNew(true)
                    // setSize("small")
                    setHeader(undefined)
                }
            }
        }
        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField
                as="choice"
                search
                required
                label={t('item')}
                value={form.item}
                loading={fetching}
                disabled={fetching}
                customOptions={items.map((item) => ({
                    key: item.id,
                    value: item.id,
                    text: `${item.code ? item.code + ' - ' : ''}${item.title}`,
                }))}
                onChange={(e, { value }) => setForm({ ...form, item: value })}
            />

            <SuperField
                as="choice"
                search
                required
                label={t('warehouse')}
                value={form.warehouse}
                customOptions={warehouses.map((item) => ({
                    id: item.id,
                    value: item.id,
                    text: item.title,
                }))}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, warehouse: value }))}
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <Button primary loading={processing} content={t('confirm')} disabled={!isFormValid()} />
            </Form.Field>
        </Form>
    )
}

const StockForm = ({ storedItem, setData, isNew, setTotal, onClose }) => {
    const { t } = useTranslation()
    const dropdownRef = useRef(null)
    const profile = useSelector((state) => state.user?.profile?.id || null)

    const [errors, setErrors] = useState(null)
    const [focus, setFocus] = useState(false)
    const [showAdditionalInfo, setShowAdditionalInfo] = useState(false)

    const [loading, setLoading] = useState(true)
    const [processing, setProcessing] = useState(false)
    const [search, setSearch] = useState('')
    const [searching, setSearching] = useState(false)
    const [positions, setPositions] = useState([])
    const [form, setForm] = useState({
        quantity: 1,
        note: '',
        average_weight: 0,
        weight: '',
        total_price: '',
        unit_price: '',
        currency: 'EUR',
        stored_item: storedItem.id,
        supplier: '',
        warehouse_position: '',
        stock_number: '',
        packages: '',
    })

    const calculateValues = (quantity, packages, weight, average_weight, toChange) => {
        const isWeightPrimary = storedItem.catalogue_item.weight_is_primary_measurement
        packages = !isWeightPrimary ? quantity : packages
        weight = isWeightPrimary ? quantity : weight

        if (isValidNumber(weight) && isValidNumber(packages) && toChange !== 'average_weight') {
            average_weight = parseFloat(parseFloat(weight) / parseFloat(packages)).toFixed(4)
        }

        if (isValidNumber(weight) && isValidNumber(average_weight) && toChange !== 'packages' && isWeightPrimary) {
            packages = average_weight > 0 ? parseFloat(parseFloat(weight) / parseFloat(average_weight)).toFixed(2) : 0
        }

        if (isValidNumber(packages) && isValidNumber(average_weight) && toChange !== 'weight' && !isWeightPrimary) {
            weight = parseFloat(parseFloat(packages) * parseFloat(average_weight)).toFixed(4)
        }

        return {
            quantity: quantity,
            packages: packages,
            weight: weight,
            average_weight: average_weight,
        }
    }

    const selectedPosition = positions.find((item) => item.id === form.warehouse_position)
    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)

        const request = await requests.post(API.ASSETS + 'warehouse_process_operations/', {
            quantity: form.quantity,
            note: form.note,
            operation: 5,
            average_weight: isValidNumber(form.average_weight) ? form.average_weight : null,
            weight: isValidNumber(form.weight) ? form.weight : null,
            total_price: isValidNumber(form.total_price) ? form.total_price : null,
            unit_price: isValidNumber(form.unit_price) ? form.unit_price : null,
            currency: form.currency || 'EUR',
            stored_item: storedItem.id,
            supplier: form.supplier || null,
            warehouse_position: form.warehouse_position || null,
            performed_by: profile,
            stock_number: form.stock_number,
        })

        const requestStoredItem = await requests.get(
            API.ASSETS + `stored_item_positions/?stock_number=${form.stock_number}&stored_item=${form.stored_item}`
        )
        if (request.status === 400) setErrors(request.response)
        if (request.status === 201) {
            if (requestStoredItem.response.length > 0) {
                if (requestStoredItem.response[0]?.id) {
                    setTotal((prev) => prev + 1)
                    setData((prev) => [...prev, requestStoredItem.response[0]])
                }
            }
            onClose()
        }

        setProcessing(false)
    }

    const isFormValid = () => {
        if (!isValidNumber(form.quantity)) return false
        if (!isValidNumber(form.unit_price, true)) return false
        if (!isValidNumber(form.average_weight, true)) return false
        if (!isValidNumber(form.weight, true)) return false
        if (form.stock_number === '') return false

        return true
    }

    const handleGlobalClick = (event) => {
        if (
            dropdownRef.current?.ref?.current !== event.target &&
            !isDescendantElement(dropdownRef.current?.ref?.current, event.target)
        ) {
            setFocus(false)
        }
    }

    async function fetchAvailablePositions(search, forceSearch) {
        let searchFilter = ''
        if (search !== '' || forceSearch) {
            setSearching(true)
            searchFilter += '&search=' + search
        } else {
            setLoading(true)
        }
        const request = await requests.get(
            API.ASSETS +
                'warehouse_available_positions/?stored_item=' +
                storedItem.id +
                '&warehouse=' +
                storedItem.warehouse?.id +
                searchFilter
        )
        if (request.status === 200) setPositions(request.response)

        setLoading(false)
        setSearching(false)
    }

    useEffect(() => {
        // Attach the global click event listener when the component mounts
        document.addEventListener('mousedown', handleGlobalClick)

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleGlobalClick)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const handleKeyDown = async (event) => {
            if (event.key === 'Enter') {
                await fetchAvailablePositions(search, true)
            }
        }

        document.addEventListener('keydown', handleKeyDown)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
        // eslint-disable-next-line
    }, [search])

    useEffect(() => {
        fetchAvailablePositions(search)
        // eslint-disable-next-line
    }, [])

    return (
        <SpinnerSegment loading={loading} loadingMessage={t('loading_available_positions')}>
            {!loading && (
                <Form onSubmit={handleSubmit}>
                    <Message
                        info
                        visible
                        content={
                            <div>
                                <Header as="h4">
                                    <strong>{t('currently_stored_item')}:</strong>
                                </Header>
                                {t('name')}:{' '}
                                <strong>
                                    {storedItem.catalogue_item?.code ? storedItem.catalogue_item?.code + ' - ' : ''}
                                    {storedItem.catalogue_item.title}
                                </strong>
                                <br />
                                {t('warehouse')}: <strong>{storedItem.warehouse.title}</strong>
                                <br />
                            </div>
                        }
                    />

                    <NonFieldErrors errors={errors} />
                    <Divider />
                    <Form.Field>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginBottom: '0.2rem',
                            }}
                        >
                            <label style={{ fontWeight: 'bold' }}>
                                {t('select_position_to_store')} <sup style={{ color: 'var(--danger)' }}>*</sup>
                            </label>
                            {form.warehouse_position !== '' && (
                                <div
                                    className="ref-link"
                                    style={{ color: 'var(--primary)' }}
                                    onClick={() => {
                                        setForm((prev) => ({ ...prev, warehouse_position: '' }))
                                        setFocus(false)
                                        setShowAdditionalInfo(false)
                                    }}
                                >
                                    {t('clear_selection')}
                                </div>
                            )}
                        </div>
                        <Dropdown
                            fluid
                            basic
                            scrolling
                            open={focus}
                            onOpen={() => setFocus(true)}
                            ref={dropdownRef}
                            as={Button}
                            type="button"
                            style={{ height: '40px' }}
                            text={
                                selectedPosition
                                    ? selectedPosition.title +
                                      (selectedPosition.section && ' / ' + selectedPosition.section) +
                                      (selectedPosition.row && ' / ' + selectedPosition.row)
                                    : t('select_an_option')
                            }
                        >
                            <Dropdown.Menu style={{ borderRadius: '0' }}>
                                <Input
                                    loading={searching}
                                    size="small"
                                    icon={
                                        <SemanticIcon
                                            link
                                            name="search"
                                            onClick={() => fetchAvailablePositions(search, true)}
                                        />
                                    }
                                    style={{ margin: '1rem', height: '40px' }}
                                    value={search}
                                    placeholder={t('search')}
                                    onChange={(e, { value }) => setSearch(value)}
                                />
                                <CanView permissions={['assets.c_manage_warehouses']}>
                                    <Dropdown.Divider style={{ marginBottom: 0 }} />
                                    <SuperDuperModal
                                        header={t('add_new_warehouse_position')}
                                        trigger={
                                            <Dropdown.Item
                                                onClick={() => setFocus(true)}
                                                style={{ background: 'var(--primary)', color: 'var(--light)' }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Icon name="add-outline" style={{ marginRight: '0.5rem' }} />
                                                    <div> {t('add_new_warehouse_position')} </div>
                                                </div>
                                            </Dropdown.Item>
                                        }
                                        content={
                                            <WarehousePositionForm
                                                warehouse={storedItem?.warehouse}
                                                setPositions={setPositions}
                                                setForm={setForm}
                                            />
                                        }
                                    />
                                    <Dropdown.Divider style={{ marginTop: 0, marginBottom: 0 }} />
                                </CanView>
                                <EmptyRow
                                    length={positions.length}
                                    align="left"
                                    message={t('there_is_no_available_position_to_store')}
                                />
                                {positions.filter((item) => item.is_partially_occupied).length > 0 && (
                                    <>
                                        <Dropdown.Divider />
                                        <Dropdown.Header
                                            style={{ textTransform: 'capitalize', opacity: '0.7' }}
                                            content={
                                                t('partially_occupied_positions') +
                                                ' (' +
                                                positions.filter((item) => item.is_partially_occupied).length +
                                                ') '
                                            }
                                        />
                                        <Dropdown.Divider />
                                    </>
                                )}

                                {positions
                                    .filter((item) => item.is_partially_occupied)
                                    .map((item) => (
                                        <Dropdown.Item
                                            active={form.warehouse_position === item.id}
                                            onClick={() => {
                                                setForm((prev) => ({ ...prev, warehouse_position: item.id }))
                                                setFocus(false)
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div style={{ fontWeight: 'bold' }}>
                                                    {item.title} {item.section && ' / ' + item.section}{' '}
                                                    {item.row && ' / ' + item.row}
                                                </div>
                                                <Label basic style={{ opacity: '0.7' }}>
                                                    {' '}
                                                    {item.occupied_amount || 0} /{' '}
                                                    {item.quantity_limit || t('unlimited')}{' '}
                                                </Label>
                                            </div>
                                        </Dropdown.Item>
                                    ))}

                                {positions.filter((item) => !item.is_partially_occupied).length > 0 && (
                                    <>
                                        <Dropdown.Divider />
                                        <Dropdown.Header
                                            style={{ textTransform: 'capitalize', opacity: '0.7' }}
                                            content={
                                                t('free_positions') +
                                                ' (' +
                                                positions.filter((item) => !item.is_partially_occupied).length +
                                                ') '
                                            }
                                        />
                                        <Dropdown.Divider />
                                    </>
                                )}

                                {positions
                                    .filter((item) => !item.is_partially_occupied)
                                    .map((item) => (
                                        <Dropdown.Item
                                            active={form.warehouse_position === item.id}
                                            onClick={() => {
                                                setForm((prev) => ({ ...prev, warehouse_position: item.id }))
                                                setFocus(false)
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div style={{ fontWeight: 'bold' }}>
                                                    {item.title} {item.section && ' / ' + item.section}{' '}
                                                    {item.row && ' / ' + item.row}
                                                </div>
                                                <Label basic style={{ opacity: '0.7' }}>
                                                    {' '}
                                                    {item.occupied_amount || 0} /{' '}
                                                    {item.quantity_limit || t('unlimited')}{' '}
                                                </Label>
                                            </div>
                                        </Dropdown.Item>
                                    ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Form.Field>
                    {form.warehouse_position !== '' && (
                        <>
                            <SuperField
                                as="input"
                                required
                                autoFocus
                                label={t('stock_number')}
                                value={form.stock_number}
                                error={errors !== null ? errors?.[0]?.stock_number || errors?.stock_number : false}
                                onChange={(e, { value }) => setForm((prev) => ({ ...prev, stock_number: value }))}
                            />

                            <SuperField
                                as="input"
                                key={form.warehouse_position}
                                required
                                label={t('stock')}
                                help={
                                    storedItem.catalogue_item.weight_is_primary_measurement
                                        ? t('weight') +
                                          (storedItem.catalogue_item?.measure_unit
                                              ? ' - ' + storedItem.catalogue_item?.measure_unit?.title
                                              : '')
                                        : t('packages') +
                                          (storedItem.catalogue_item?.measure_unit
                                              ? ' - ' + storedItem.catalogue_item?.measure_unit?.title
                                              : '')
                                }
                                value={form.quantity}
                                error={
                                    errors !== null
                                        ? errors?.[0]?.quantity
                                        : !isValidNumber(form.quantity)
                                        ? t('invalid_quantity')
                                        : false
                                }
                                onChange={(e, { value }) =>
                                    setForm((prev) => ({
                                        ...prev,
                                        ...calculateValues(
                                            setDotSeparator(value),
                                            form.packages,
                                            form.weight,
                                            form.average_weight
                                        ),
                                    }))
                                }
                            />

                            <Form.Group widths="equal">
                                <SuperField
                                    as="input"
                                    required
                                    name="average_weight"
                                    label={t('average_weight')}
                                    help={t('leave_it_at_zero_if_none')}
                                    error={
                                        !isValidNumber(form.average_weight) && form.average_weight !== ''
                                            ? t('not_a_number')
                                            : false
                                    }
                                    value={form.average_weight}
                                    onChange={(e, { value }) =>
                                        setForm((prev) => ({
                                            ...prev,
                                            ...calculateValues(
                                                form.quantity,
                                                form.packages,
                                                form.weight,
                                                setDotSeparator(value),
                                                'average_weight'
                                            ),
                                        }))
                                    }
                                />
                                <SuperField
                                    as="input"
                                    label={
                                        storedItem.catalogue_item.weight_is_primary_measurement === true
                                            ? t('num_of_packages')
                                            : t('total_weight')
                                    }
                                    error={
                                        !isValidNumber(
                                            storedItem.catalogue_item.weight_is_primary_measurement === true
                                                ? form.packages
                                                : form.weight
                                        ) &&
                                        (storedItem.catalogue_item.weight_is_primary_measurement === true
                                            ? form.packages
                                            : form.weight) !== ''
                                            ? t('not_a_number')
                                            : undefined
                                    }
                                    value={
                                        storedItem.catalogue_item.weight_is_primary_measurement === true
                                            ? form.packages
                                            : form.weight
                                    }
                                    onChange={(e, { value }) => {
                                        let toChange =
                                            storedItem.catalogue_item.weight_is_primary_measurement === true
                                                ? 'packages'
                                                : 'weight'
                                        let weight =
                                            storedItem.catalogue_item.weight_is_primary_measurement === true
                                                ? form.weight
                                                : setDotSeparator(value)
                                        let packages =
                                            storedItem.catalogue_item.weight_is_primary_measurement === true
                                                ? setDotSeparator(value)
                                                : form.packages
                                        setForm((prev) => ({
                                            ...prev,
                                            ...calculateValues(
                                                form.quantity,
                                                packages,
                                                weight,
                                                form.average_weight,
                                                toChange
                                            ),
                                        }))
                                    }}
                                />
                            </Form.Group>

                            <p>
                                {t('current_stock')}:{' '}
                                <Label basic>
                                    {selectedPosition.occupied_amount || 0} /{' '}
                                    {selectedPosition.quantity_limit || t('unlimited')}
                                </Label>
                            </p>
                            <Divider />

                            <Header
                                as="h3"
                                style={{
                                    marginTop: '0.5rem',
                                    marginBottom: '0.5rem',
                                    cursor: 'pointer',
                                    opacity: '0.8',
                                }}
                                onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
                            >
                                <Icon
                                    name={`chevron-${showAdditionalInfo ? 'down' : 'forward'}-outline`}
                                    style={{ marginRight: '0.5rem', position: 'relative', top: '0.2rem' }}
                                />
                                <span>{t('additional_information')}</span>
                            </Header>

                            {showAdditionalInfo && (
                                <>
                                    <SuperField
                                        as="choice"
                                        search
                                        text="name"
                                        value={form.supplier}
                                        label={t('supplier')}
                                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, supplier: value }))}
                                        endpoint={
                                            API.ACCOUNTS +
                                            'business_details/?is_active=true&is_supplier=true&query={id, name}'
                                        }
                                    />

                                    <Form.Group widths="equal">
                                        <SuperField
                                            as="input"
                                            value={form.unit_price}
                                            label={t('unit_price')}
                                            error={
                                                !isValidNumber(form.unit_price) && form.unit_price !== ''
                                                    ? t('not_a_number')
                                                    : false
                                            }
                                            onChange={(e, { value }) =>
                                                setForm((prev) => ({
                                                    ...prev,
                                                    unit_price: setDotSeparator(value),
                                                    total_price: parseFloat(value * form.quantity).toFixed(2) || 0,
                                                }))
                                            }
                                        />
                                        <SuperField
                                            as="choice"
                                            type="currency_codes"
                                            value={form.currency}
                                            label={t('currency')}
                                            onChange={(e, { value }) =>
                                                setForm((prev) => ({ ...prev, currency: value }))
                                            }
                                        />
                                    </Form.Group>

                                    <p>
                                        {t('total_price')}:{' '}
                                        {thousandsSeparators(
                                            parseFloat(form.unit_price * form.quantity).toFixed(2) || 0
                                        )}{' '}
                                        {form.currency}
                                    </p>

                                    <SuperField
                                        as="textarea"
                                        value={form.note}
                                        label={t('note')}
                                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, note: value }))}
                                    />
                                </>
                            )}
                        </>
                    )}

                    <Divider />
                    <Form.Field style={{ textAlign: 'right' }}>
                        <ModalCancel onClose={onClose} />
                        <Button
                            primary
                            loading={processing}
                            content={t('confirm')}
                            disabled={!isFormValid() && form.quantity !== ''}
                        />
                    </Form.Field>
                </Form>
            )}
        </SpinnerSegment>
    )
}

export default StockItemForm
