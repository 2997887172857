import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import DocPreview from '@components/DocPreview'
import ModalCancel from '@components/buttons/ModalCancel'
import {
    Form,
    Divider,
    Message,
    Header,
    Label,
    Popup,
    Icon as SemanticIcon,
    Button,
    Loader,
    Grid,
} from 'semantic-ui-react'

// custom components
import Invoice from './documents/Invoice'

const InvoiceUsingAI = ({ onClose, setData, setTotal, connection, journal, setSize, setAllowClose }) => {
    const { t } = useTranslation()

    const [file, setFile] = useState(null)
    const [fileURL, setFileURL] = useState(null)
    const [view, setView] = useState(0)
    const [processing, setProcessing] = useState(false)
    const [parsedData, setParsedData] = useState(null)

    const [process, setProcess] = useState('OCR')

    const hiddenFileInput = React.useRef(null)
    const handleFrontClick = (event) => {
        hiddenFileInput.current.click()
    }

    function generateUniqueKey() {
        return Date.now().toString(36) + Math.random().toString(36).substr(2, 9)
    }

    const handleFileInputChange = (event) => {
        const selectedFiles = event.target.files

        // Allowed file types
        const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf']

        const newFiles = Array.from(selectedFiles).filter((file) => {
            const isValidSize = file.size <= 2 * 1024 * 1024 // 2 MB
            const isValidType = allowedTypes.includes(file.type)
            return isValidSize && isValidType
        })

        if (newFiles.length > 0) {
            setFile(newFiles[0])
        } else {
            toast({
                type: 'error',
                icon: 'warning circle',
                title: t('Please select files with a size of 2 MB or less and of type PNG, JPG, or PDF.'),
                description: '',
                animation: 'bounce',
                time: 5000,
            })
        }
    }

    const handleSubmit = async () => {
        setProcessing(true)
        setSize('tiny')
        setAllowClose(false)

        const formData = new FormData()
        formData.append('request', file)
        const request = await requests.post(API.AZURE_PARSER + 'read/', formData)
        let ocr_processing = false
        let parsed_document = null
        let parsed_document_url = null

        if (request.status === 200) {
            ocr_processing = true

            while (ocr_processing === true) {
                if (ocr_processing === true) {
                    await new Promise((resolve) => setTimeout(resolve, 3000))
                }

                if (request?.response?.response) {
                    const verify_parsed_document = await requests.get(
                        API.AZURE_PARSER + `result/?url=${request.response.response}`
                    )
                    if (verify_parsed_document.status === 200) {
                        // verify if response contain any content
                        if (verify_parsed_document.response?.response?.status === 'succeeded') {
                            ocr_processing = false
                            parsed_document_url = verify_parsed_document.response?.file
                            parsed_document = verify_parsed_document.response?.response?.analyzeResult
                        }
                    }
                }
            }

            if (parsed_document && parsed_document?.content?.length > 0) {
                const content = parsed_document?.content || ''
                if (content.length > 0) {
                    setProcess('AI')
                    const request_chatty = await requests.post(API.OPENAI + `ask-chatty/`, {
                        instruction: `
                            Extract data from the given invoice text and return it in JSON format. Use the following rules:
                            - If you can't find data use null instead.
                            - Convert dates to 'YYYY-MM-DD' format.
                            - Replace currency symbols with their respective 3-letter currency codes (e.g., € to EUR).
                            - Return the data in this structure: {invoice_number,variable_symbol,constant_symbol,specific_symbol,issue_date,due_date,delivery_date,customer_details{name,id_number,vat_number,dit_number,phone,email,address},supplier_details{name,id_number,vat_number,dit_number,invoice_tax_mode,phone,email,address,iban,payment_method,swift},items{code,title,quantity,measure_unit,unit_price,tax,tax_rate},invoice_total,order_number,note,currency}`,
                        prompt: content,
                    })

                    if (request_chatty.status === 200) {
                        setSize('fullscreen')
                        setParsedData(request_chatty.response)
                        setFileURL(parsed_document_url)
                        setView(1)
                    } else {
                        setSize('small')
                        setAllowClose(true)
                        toast({
                            type: 'error',
                            icon: 'warning circle',
                            title: t('the_file_is_either_corrupted_or_in_an_incorrect_format'),
                            description: '',
                            animation: 'bounce',
                            time: 5000,
                        })
                    }
                }
            }
        } else {
            setSize('small')
            setAllowClose(true)
            toast({
                type: 'error',
                icon: 'warning circle',
                title: t('the_file_is_either_corrupted_or_in_an_incorrect_format'),
                description: '',
                animation: 'bounce',
                time: 5000,
            })
        }

        setProcessing(false)
    }

    return (
        <>
            {view === 0 ? (
                <>
                    {processing ? (
                        <>
                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{file?.name}</div>
                            <div class="scanner-container">
                                <Icon name="document-text-outline" />
                                <div class="scanner-overlay">
                                    <div class="scanner-line"></div>
                                </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <strong>
                                    <Loader
                                        size="tiny"
                                        className="dark-loader"
                                        active
                                        inline
                                        style={{ marginRight: '0.5rem' }}
                                    />
                                    <span style={{ position: 'relative', top: '0.2rem' }}>
                                        {process === 'OCR' ? t('ocr_processing') + '...' : t('ai_processing') + '...'} (
                                        {process === 'OCR' ? '1' : '2'}/2)
                                    </span>
                                </strong>
                            </div>
                        </>
                    ) : (
                        <Form onSubmit={handleSubmit}>
                            <InformationHeader />
                            <Divider />
                            <div style={{ textAlign: 'center' }}>
                                <input
                                    type="file"
                                    id="fileInput"
                                    ref={hiddenFileInput}
                                    onChange={handleFileInputChange}
                                    style={{ display: 'none' }}
                                    accept=".png, .jpg, .jpeg, .pdf"
                                />
                                <div
                                    style={{
                                        padding: '0.7rem',
                                        paddingTop: '2rem',
                                        paddingBottom: '2rem',
                                        cursor: 'pointer',
                                        background: 'var(--light-grey)',
                                        border: '1px dashed var(--dark)',
                                        borderRadius: 'var(--border-radius)',
                                    }}
                                    onClick={handleFrontClick}
                                >
                                    <Header
                                        as="h4"
                                        style={{ marginTop: '0.5rem', marginBottom: '0.5rem', fontSize: '15px' }}
                                    >
                                        <span>
                                            <SemanticIcon
                                                name="upload"
                                                size="tiny"
                                                style={{
                                                    color: 'var(--primary)',
                                                    fontSize: '15px',
                                                    marginRight: '0.4rem',
                                                }}
                                            />
                                            {t('upload_file')}
                                        </span>
                                        {file ? (
                                            <Header.Subheader content={file?.name || t('one_file_selected')} />
                                        ) : (
                                            <Header.Subheader content={t('no_file_selected')} />
                                        )}
                                    </Header>
                                </div>
                            </div>
                            <p>
                                {t('allowed_format')}: <strong>PDF, JPG, JPEG, PNG</strong>, {t('max_file_size')}:{' '}
                                <strong>2 mb</strong>
                            </p>
                            <Divider />
                            <Form.Field style={{ textAlign: 'right' }}>
                                {!processing && <ModalCancel onClose={onClose} />}
                                <Button primary disabled={file === null || processing} loading={processing}>
                                    {t('confirm')}
                                </Button>
                            </Form.Field>
                        </Form>
                    )}
                </>
            ) : (
                <Grid>
                    <Grid.Row style={{ paddingTop: 0, borderBottom: '1px solid var(--light)' }}>
                        <Grid.Column
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <div>
                                <Icon
                                    name="close-outline"
                                    style={{
                                        cursor: 'pointer',
                                        color: 'var(--danger)',
                                        fontWeight: 'bold',
                                        fontSize: '1.5rem',
                                    }}
                                    onClick={() => onClose()}
                                />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns="2" divided>
                        <Grid.Column width={7}>
                            {fileURL && <DocPreview key={generateUniqueKey()} uri={fileURL} />}
                        </Grid.Column>
                        <Grid.Column width={9}>
                            <Invoice
                                parsedData={parsedData}
                                document={'recieved_invoice'}
                                connection={connection}
                                journal={journal}
                                setData={setData}
                                setTotal={setTotal}
                                onClose={onClose}
                                attachment={file}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </>
    )
}

const InformationHeader = () => {
    const { t } = useTranslation()

    return (
        <Message visible>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '0.5rem',
                }}
            >
                <Header as="h3" style={{ marginTop: '0.1rem' }}>
                    {t('upload_your_invoice_file_and_let_ai_handle_the_rest')}
                </Header>
                <Popup
                    flowing
                    hoverable
                    position="left center"
                    trigger={
                        <Label
                            style={{
                                fontWeight: 'bold',
                                background: 'var(--blue)',
                                color: 'var(--light)',
                                cursor: 'default',
                            }}
                        >
                            BETA
                        </Label>
                    }
                    content={
                        <div>
                            <Header as="h4" style={{ marginBottom: 0 }}>
                                {t('testing_mode')}
                            </Header>
                            <span>{t('one_invoice_at_a_time_bulk_processing_is_not_available')}</span> <br />
                        </div>
                    }
                />
            </div>
            <p>
                {t('allow_us_to_perform')}{' '}
                <Popup
                    hoverable
                    position="bottom center"
                    content={
                        <div>
                            <strong>OCR</strong> (Optical Character Recognition) {t('invoice_ocr_definition')}
                            <br />
                            <br />
                            <strong>AI</strong> (Artificial Intelligence) {t('invoice_ai_definition')}
                            <br />
                        </div>
                    }
                    trigger={
                        <strong style={{ cursor: 'default', textDecoration: 'underline' }}>
                            {t('ocr_and_ai_processing')}
                        </strong>
                    }
                />{' '}
                {t('on_your_invoice_file_to_extract_relevant_information')}.
            </p>
            <Divider style={{ marginBottom: '0.5rem' }} />
            <span style={{ color: 'var(--danger)', fontWeight: 'bold' }}>
                <Icon name="warning-outline" style={{ marginRight: '0.5rem', position: 'relative', top: '0.1rem' }} />
                {t('make_sure_to_review_the_results_before_making_any_decisions')}
            </span>
        </Message>
    )
}

export default InvoiceUsingAI
