import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import SuperField from '@components/forms/SuperField';
import { Form, Divider, Header } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import NonFieldErrors from '@components/NonFieldErrors';

const CatalogueItemForm = ({ data, setData, setTotal, onClose }) => {
    const { t } = useTranslation()
    
    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState(null)

    const [showDescription, setShowDescription] = useState(data?.description?.length > 0 ? true : false)

    const [form, setForm] = useState({
        id: data?.id || "",
        title: data?.title || "",
        code: data?.code || "",
        is_fragile: data?.is_fragile || false,
        is_archived: data?.is_archived || false,
        is_service: data?.is_service || false,
        description: data?.description || "",
        amount: data?.amount || 0,
        average_package_weight: data?.average_package_weight || "",
        measurement_z: data?.measurement_z || "",
        measurement_x: data?.measurement_x || "",
        measurement_y: data?.measurement_y || "",
        quality: data?.quality || "",
        material_color: data?.material_color || "",
        addition_description_info: data?.addition_description_info || "",
        buy_price: data?.buy_price || 0,
        buy_price_currency: data?.buy_price_currency || "EUR",
        sell_price_without_tax: data?.sell_price_without_tax || 0,
        sell_price_with_tax: data?.sell_price_with_tax || 0,
        sell_tax: data?.sell_tax || 0,
        measure_unit: data?.measure_unit?.id || "",
        seller: data?.seller?.id || "",
        owner: data?.owner?.id || "",
        category: data?.category?.id || "",
        weight_is_primary_measurement: data?.weight_is_primary_measurement === undefined ? false : data?.weight_is_primary_measurement
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        setErrors(null)

        let formData = {
            code: form.code,
            title: form.title,
            category: form.category,
            sell_tax: form.sell_tax,
            buy_price: form.buy_price,
            owner: form?.owner || null,
            seller: form?.seller || null,
            is_archived: form.is_archived,
            description: form.description,
            buy_price_currency: form.buy_price_currency,
            sell_price_with_tax: form.sell_price_with_tax,
            sell_price_without_tax: form.sell_price_without_tax,
            addition_description_info: form.addition_description_info,
            weight_is_primary_measurement: form.weight_is_primary_measurement,
        }

        if (!form.is_service) {
            formData['is_service'] = false;
            formData['is_fragile'] = form.is_fragile;
            formData['amount'] = form.amount;
            formData['quality'] = form.quality;
            formData['material_color'] = form.material_color;
            formData['average_package_weight'] = form.average_package_weight;
            formData['measurement_z'] = form.measurement_z;
            formData['measurement_x'] = form.measurement_x;
            formData['measurement_y'] = form.measurement_y;
            formData['measure_unit'] = form.measure_unit;
        } else{
            formData['is_service'] = true;
        }

        let request = undefined

        if (!form.id || form.id === 0) {
            request = await requests.post(API.CATALOGUE_ITEMS, formData)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                setData((prev) => [request.response, ...prev])
                setTotal((prev) => prev + 1)
                onClose()
            }
        } else {
            request = await requests.patch(API.CATALOGUE_ITEMS + data.id + "/", formData)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === request.response.id) {
                            item = request.response
                        }
                        return item
                    })
                )
                onClose()
            }
        }

        setIsProcessing(false)
    }

    return (
        <>
            <Form onSubmit={handleSubmit}>
                { data?.id !== undefined &&
                    <>
                        <Header as="h3" content={t('update')}/>
                        <Divider/>
                    </>
                }
                <>
                    <NonFieldErrors errors={errors} />
                    <Form.Group widths="equal">
                        <SuperField as="input"
                            width="4"
                            label={t('code')}
                            value={form.code}
                            onChange={(e, { value }) => setForm({ ...form, code: value })}
                        />

                        <SuperField as="input"
                            required
                            width="12"
                            label={t('title')}
                            value={form.title}
                            onChange={(e, { value }) => setForm({ ...form, title: value })}
                        />
                    </Form.Group>

                    <Header as="h4" content={t('choose_item_type')}/>
                    <Form.Group>
                       <SuperField as="radio"
                            label={t("product")}
                            checked={form.is_service === false}
                            onChange={() => setForm({...form, is_service: false})}
                        />
                        <SuperField as="radio"
                            label={t("service")}
                            checked={form.is_service === true}
                            onChange={() => setForm({...form, is_service: true})}
                        /> 
                    </Form.Group>
                    <Divider />

                    <SuperField as="checkbox"
                        label={t('add_item_description')}
                        checked={showDescription}
                        onChange={() => setShowDescription(!showDescription)}
                    /> 
                    { showDescription && 
                        <SuperField as="textarea"
                            autoFocus={data === undefined && showDescription}
                            label={t('description')}
                            value={form.description}
                            onChange={ (e, { value }) => setForm({ ...form, description: value })}
                        />
                    }                   
                    
                    <Form.Group widths='equal'>
                        <SuperField as="choice"
                            search
                            text="title"
                            label={t('category')}
                            value={form.category}
                            onChange={(e, { value }) => setForm({ ...form, category: value })}
                            endpoint={API.CATALOGUE_ITEM_CATEGORY}
                        />
                        <SuperField as="choice"
                            search
                            text="name"
                            label={t('owner')}
                            value={form.owner}
                            onChange={(e, { value }) => setForm({ ...form, owner: value })}
                            endpoint={API.UNITS}
                        />
                    </Form.Group>

                    <SuperField as="choice"
                        search
                        text="name"
                        label={t('seller')}
                        value={form.seller}
                        onChange={(e, { value }) => setForm({ ...form, seller: value })}
                        endpoint={API.ACCOUNTS + `business_details/?query={id, name}&is_supplier=true&is_active=true&exclude_unit=true`}
                    />

                    <Divider/>

                    <Form.Group widths='equal'>
                        <SuperField as="input"
                            pattern="^-?[0-9]\d*\.?\d*$"
                            label={t("buy_unit_price")}
                            error={errors?.buy_price?.[0] || false}
                            value={form.buy_price}
                            onChange={(e, { value }) => setForm({ ...form, buy_price: value.replace(",", ".") })}
                        />
                        <SuperField as="input"
                            pattern="^-?[0-9]\d*\.?\d*$"
                            label={t("sell_unit_price")}
                            error={errors?.sell_price_without_tax?.[0] || false}
                            value={form.sell_price_without_tax}
                            onChange={(e, { value }) => setForm({ ...form, sell_price_without_tax: value.replace(",", ".") })}
                        />
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <SuperField as="choice"
                            search
                            type="currency_codes"
                            label={t('currency')}
                            value={form.buy_price_currency}
                            onChange={(e, { value }) => setForm({ ...form, buy_price_currency: value })}
                        />
                        {/* 
                            <SuperField as="input"
                                type="number"
                                pattern="^-?[0-9]\d*\.?\d*$"
                                label={t("sell_price_with_tax")}
                                error={errors?.sell_price_with_tax?.[0] || false}
                                value={form.sell_price_with_tax}
                                onChange={(e, { value }) => setForm({ ...form, sell_price_with_tax: value.replace(",", ".") })}
                            />
                            <SuperField as="input"
                                type="number"
                                pattern="^-?[0-9]\d*\.?\d*$"
                                label={t("sell_tax")}
                                value={form.sell_tax}
                                onChange={(e, { value }) => setForm({ ...form, sell_tax: value })}
                            /> 
                        */}
                    </Form.Group>

                    <Divider/>

                    {!form.is_service &&
                        <>
                            <Form.Group widths="equal">
                                <SuperField as="choice"
                                    required
                                    label={t('measurement_type')}
                                    value={form.weight_is_primary_measurement}
                                    customOptions={[
                                        { key: 1, value: false, text: t("packages_pieces") }, 
                                        { key: 2, value: true, text: t("weight") }, 
                                    ]}
                                    onChange={ (e, { value }) => setForm({ ...form, weight_is_primary_measurement: value }) }
                                />
                                <SuperField as="choice"
                                    search
                                    label={t('measure_unit')}
                                    endpoint={API.COMMON + "measure_units/?query={id, abbreviation}"}
                                    text="abbreviation"
                                    value={form.measure_unit}
                                    onChange={ (e, { value }) => setForm({ ...form, measure_unit: value }) }
                                />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <SuperField as="input"
                                    label={t('quality')}
                                    value={form.quality}
                                    onChange={(e, { value }) => setForm({ ...form, quality: value })}
                                />
                                <SuperField as="input"
                                    label={t('material_color')}
                                    value={form.material_color}
                                    onChange={(e, { value }) => setForm({ ...form, material_color: value })}
                                />
                            </Form.Group>

                            { form.weight_is_primary_measurement === false && 
                                <>
                                    <Form.Group widths='equal'>
                                        <SuperField as="input"
                                            type="number"
                                            pattern="^-?[0-9]\d*\.?\d*$"
                                            label={t("measurement_x")}
                                            value={form.measurement_x}
                                            onChange={(e, { value }) => setForm({ ...form, measurement_x: value.replace(",", ".") })}
                                        />
                                        <SuperField as="input"
                                            type="number"
                                            pattern="^-?[0-9]\d*\.?\d*$"
                                            label={t("measurement_y")}
                                            value={form.measurement_y}
                                            onChange={(e, { value }) => setForm({ ...form, measurement_y: value.replace(",", ".") })}
                                        />
                                        <SuperField as="input"
                                            type="number"
                                            pattern="^-?[0-9]\d*\.?\d*$"
                                            label={t("measurement_z")}
                                            value={form.measurement_z}
                                            onChange={(e, { value }) => setForm({ ...form, measurement_z: value.replace(",", ".") })}
                                        />
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <SuperField as="input"
                                            type="number"
                                            pattern="^-?[0-9]\d*\.?\d*$"
                                            label={t("average_package_weight")}
                                            value={form.average_package_weight}
                                            onChange={(e, { value }) => setForm({ ...form, average_package_weight: value.replace(",", ".") })}
                                        />
                                        <SuperField as="input"
                                            type="number"
                                            min="0"
                                            pattern="[0-9]*"
                                            label={t("amount")}
                                            help={t('package_amount_hint')}
                                            value={form.amount}
                                            onChange={(e, { value }) => setForm({ ...form, amount: value })}
                                        />
                                    </Form.Group>
                                </>
                            }
                        </>
                    }

                    <SuperField as="input"
                        label={t('addition_description_info')}
                        value={form.addition_description_info}
                        onChange={ (e, { value }) => setForm({ ...form, addition_description_info: value })}
                    />

                    <Form.Group>
                        <SuperField as="checkbox"
                            label={t('archived')}
                            checked={form.is_archived}
                            onChange={() => setForm({...form, is_archived: !form.is_archived})}
                        />
                        {!form.is_service &&
                            <SuperField as="checkbox"
                                label={t('fragile')}
                                checked={form.is_fragile}
                                onChange={() => setForm({...form, is_fragile: !form.is_fragile})}
                            />
                        }
                    </Form.Group>

                    <Divider />
                    <Form.Field style={{ textAlign: "right" }}>
                        <ModalCancel onClose={onClose}/>
                        <ModalSubmit
                            text={t("confirm")}
                            disabled={isProcessing || form.title === ""}
                            loading={isProcessing} 
                        />
                    </Form.Field>
                </>
            </Form>
        </>
    );
};

export default CatalogueItemForm;