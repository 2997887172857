import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Button } from 'semantic-ui-react';
import ConfirmModal from '@components/modals/ConfrimModal';
import SuperDuperModal from '@components/modals/SuperDuperModal';
// modulespecific component
import SelectOrderForm from './forms/SelectOrderForm';

const InvoiceOrderRef = ({ journal, isIssued, invoiceID, taxMode, orderRef, setForm, setItems, setOrderRef }) => {
    const { t } = useTranslation()
    const [processing, setProcessing] = useState(false)

    const handleOrderRefRemoval = async () => {
        setProcessing(true)
        const refs = orderRef.split("ref:")
        const clearedRefs = refs.filter(item => item !== "")
        let total = clearedRefs.length

        for (let i in refs) {
            if (refs[i] !== "") {
                let refParts = refs[i].split("__")
                const request = await requests.patch(API.ORDERS + "fulfillment_edit/" + refParts[1] + "/", { invoice_reference: null })
                if (request.status === 200) {
                    total--
                }
            }
        }

        if (total === 0){
            setOrderRef("")
            setForm(prev => ({...prev, order_number: ""}))

            toast({
                type: 'success',
                icon: 'check circle',
                description: t('reference_removed'),
                animation: 'pulse',
                time: 3000,
            }) 
        } else {
            toast({
                type: 'error',
                icon: 'warning circle',
                description: t('unable_to_remove_reference'),
                animation: 'bounce',
                time: 5000,
            })
        }
        

        setProcessing(false)
    }

    return (
        <>
            { orderRef === "" // if empty then allow to select
                ?
                    <SuperDuperModal
                        size="small"
                        centered={false}
                        closeIcon={false}
                        closeOnDimmerClick={false}
                        closeOnDocumentClick={false}
                        trigger={
                            <Button
                                primary
                                fluid
                                type="button"
                                size="tiny"
                                style={{ 
                                    height: "35px",
                                }}
                            >
                                { t('select_order') }
                            </Button>
                        }
                        content={
                            <SelectOrderForm
                                isIssued={isIssued}
                                journal={journal}
                                setForm={setForm}
                                taxMode={taxMode}
                                setItems={setItems}
                                invoiceID={invoiceID}
                                setOrderRef={setOrderRef}
                            />
                        }
                    />   
                : // non-empty allow to remove selection 
                    <ConfirmModal
                        description={t('are_you_sure_to_remove_order_reference_from_invoice')}
                        onConfirm={() => handleOrderRefRemoval()}
                        button={
                            <Button
                                fluid
                                type="button"
                                size="tiny"
                                style={{ 
                                    height: "35px",
                                    color: "var(--white)",
                                    background: "var(--danger)"
                                }}
                                loading={processing}
                                disabled={processing}
                            >
                                { t('remove_reference') }
                            </Button>
                        }
                    />
            }
        </>
    );
};

export default InvoiceOrderRef;