import React from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
// components
import Icon from '@components/Icon'
import CanView from '@components/perms/CanView'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { FlexRow, FlexItem } from '@components/tables/FlexTable'
import { Divider, Grid, Header, Button } from 'semantic-ui-react'
import BusinessDetailView from '@components/common/business-details/BusinessDetailView'
import BusinessDetailForm from '@components/common/business-details/BusinessDetailForm'
import ContactList from '../../../components/ContactList'

const DetailView = ({ unit, setUnit }) => {
    const { t } = useTranslation()

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header as="h3" content={t('description')} />
                    <p>{unit.description !== '' && unit.description !== null ? unit.description : '-'} </p>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row style={{ padding: 0 }}>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                    <FlexRow background="transparent" padding="0">
                        <FlexItem>
                            <Header as="h3" content={t('business_detail')} />
                        </FlexItem>
                        <FlexItem textAlign="right">
                            <CanView permissions={['company.c_view_units']}>
                                <SuperDuperModal
                                    size="small"
                                    header={t('business_detail')}
                                    trigger={
                                        <Button basic size="small" style={{ fontWeight: 'bold' }}>
                                            <Icon
                                                name={unit.business_detail === null ? 'add-outline' : 'pencil-outline'}
                                                style={{ marginRight: '0.5rem' }}
                                            />
                                            <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                {unit.business_detail === null
                                                    ? t('add_business_details')
                                                    : t('update_business_details')}
                                            </span>
                                        </Button>
                                    }
                                    content={
                                        <BusinessDetailForm
                                            endpoint={API.UNITS + unit.id + '/'}
                                            updateState={setUnit}
                                            data={unit.business_detail}
                                        />
                                    }
                                />
                            </CanView>
                        </FlexItem>
                    </FlexRow>
                    <Divider />
                    {unit.business_detail !== null ? (
                        <BusinessDetailView
                            allowSignature
                            allowPayrollProvider
                            updateState={setUnit}
                            permissions={{
                                view: 'company.c_view_unit_bankaccounts',
                                manage: 'company.c_manage_unit_bankaccounts',
                                delete: 'company.c_manage_unit_bankaccounts',
                            }}
                            data={unit.business_detail}
                            endpoint={API.UNITS + unit.id + '/'}
                        />
                    ) : (
                        <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            {' '}
                            {t('business_details_not_provided')}{' '}
                        </p>
                    )}
                    <Divider />
                </Grid.Column>

                <Grid.Column computer={8} tablet={8} mobile={16}>
                    <ContactList data={unit} setData={setUnit} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default DetailView
