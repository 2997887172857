import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { isEmpty } from '@helpers/validation'
// components
import Action from '@components/general/Action'
import CanView from '@components/perms/CanView'
import { Form, Divider } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const JobkaForm = ({ JobkaConfig, setJobkaConfig, onClose }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [form, setForm] = useState({
        client_id: '',
        client_secret: '',
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        const request = await requests.post(API.INTEGRATIONS + 'jobka_auth_configs/', form)
        if (request.status === 201) {
            setJobkaConfig(request.response)
            const requestPref = await requests.patch(
                API.PREFERENCES + 'integration_preferences__allow_jobka_integration/',
                {
                    value: true,
                }
            )

            if (requestPref.status === 200) {
                onClose()
            }
        }
        setIsProcessing(false)
    }

    const deactivate = async (id) => {
        setIsProcessing(true)
        const request = await requests.del(API.INTEGRATIONS + 'jobka_auth_configs/' + id + '/')
        if (request.status === 204) {
            setJobkaConfig(null)
            const requestPref = await requests.patch(
                API.PREFERENCES + 'integration_preferences__allow_jobka_integration/',
                {
                    value: false,
                }
            )

            if (requestPref.status === 200) {
                onClose()
            }
            onClose()
        }
        setIsProcessing(false)
    }

    return (
        <>
            {JobkaConfig === null ? (
                <Form onSubmit={handleSubmit}>
                    <SuperField
                        as="input"
                        autoFocus
                        required
                        label="Client ID"
                        value={form.client_id}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, client_id: value }))}
                    />
                    <SuperField
                        as="input"
                        required
                        label="Client secret"
                        value={form.client_secret}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, client_secret: value }))}
                    />

                    <Divider />
                    <Form.Field style={{ textAlign: 'right' }}>
                        <ModalCancel onClose={onClose} disabled={isProcessing} />
                        <ModalSubmit
                            loading={isProcessing}
                            disabled={isProcessing || isEmpty(form.client_id) || isEmpty(form.client_secret)}
                            text={t('save')}
                        />
                    </Form.Field>
                </Form>
            ) : (
                <CanView permissions={['integrations.c_manage_third_party_auth']}>
                    <Form.Field width="16" style={{ textAlign: 'left' }}>
                        <Action
                            fluid
                            as="custom"
                            type="button"
                            buttonText={t('delete')}
                            backgroundColor="red"
                            iconColor="var(--white)"
                            paddingLeft="1rem"
                            paddingRight="1rem"
                            size="large"
                            disabled={isProcessing}
                            loading={isProcessing}
                            onClick={() => deactivate(JobkaConfig?.id)}
                        />
                    </Form.Field>
                </CanView>
            )}
        </>
    )
}

export default JobkaForm
