import React, { useEffect, useState,  } from 'react';
// store
import { commonService } from '../../../store/services/ServiceCommon';
// components
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const UnitTypeField = ({ disabled, value, setValue, ...rest }) => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const {t}= useTranslation()

    useEffect( () => {
        async function getUnitTypes(){
            setLoading(true);

            const result = await commonService.getUnitTypes();

            let optionsArray = [];
            if(result.status === 200){
                for (let i = 0; i < result.response.length; i++) {
                    optionsArray.push({
                        key: result.response[i].id,
                        text: result.response[i].name,
                        value: result.response[i].id
                    });
                };
                setOptions(optionsArray);
            }
            setLoading(false);
        }

        getUnitTypes();
    }, [])

    return (
        <Form.Field>
            <Form.Select
                {...rest}
                fluid
                search
                label={t('unit_type')}
                lazyLoad
                loading={loading}
                disabled={disabled || loading}
                value={value}
                options={options}
                placeholder='---------'
                onChange={setValue}
            />
        </Form.Field>
    );
};

export default UnitTypeField;