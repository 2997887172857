import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
// store
import { isEmpty } from "@helpers/validation"
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Form, Message, Divider } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import TemplateEditor from '@components/editors/template-editor/TemplateEditor';
import SuperField from '@components/forms/SuperField';

const GdprForm = ({ gdpr, setData, setTotal, onClose }) => {
    const { t } = useTranslation();

    const [gdprID] = useState(gdpr ? gdpr.id : 0);

    const [title, setTitle] = useState(gdpr ? gdpr.title : "");
    const [type, setType] = useState(gdpr ? gdpr.type : "");
    const [lengthInMonths, setLengthInMonths] = useState(gdpr?.lenght_in_months ? gdpr.lenght_in_months : 0);
    const [isRequired, setIsRequired] = useState(gdpr ? gdpr.is_required : false)
    const [text, setText] = useState(gdpr ? gdpr.text : "");

    // form states
    const [isProcessing, setIsProcessing] = useState(false); // true, false
    const [errors, setErrors] = useState([]);

    const handleError = (response) => {
        let errorList = [];
        if (response.non_field_errors) {
            for (let i = 0; i < response.non_field_errors.length; i++) {
                errorList.push(response.non_field_errors[i]);
            }
        }

        if (response.field_name) {
            for (let i = 0; i < response.field_name.length; i++) {
                errorList.push(response.field_name[i]);
            }
        }

        if (response.detail) {
            for (let i = 0; i < response.detail.length; i++) {
                errorList.push(response.detail[i]);
            }
        }

        setErrors(errorList);
    }

    const handleSubmit = async () => {
        setIsProcessing(true);
        setErrors([])
        let request = null;

        const data = {
            title: title,
            type: type,
            lenght_in_months: lengthInMonths,
            is_required: isRequired,
            text: text
        }

        if (gdprID === 0) {
            request = await requests.post(API.GDPRS, data)
            if (request.status === 201) {
                setData(prev => [request.response, ...prev])
                setTotal(prev => prev + 1)
                onClose()
            } else {
                handleError(request.response);
            }
        } else {
            request = await requests.put(API.GDPRS + gdprID + "/", data)
            if (request.status === 200) {
                setData(prev => prev.map(item => {
                    if (item.id === gdprID) {
                        item = request.response
                    }

                    return item
                }))
                onClose()
            } else {
                handleError(request.response);
            }
        }

        setIsProcessing(false);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors.length > 0}
                header={t('error_submission_message')}
                list={errors}
            />
            <Form.Group widths="equal">
                <SuperField as="input"
                    label={t('title')}
                    value={title}
                    onChange={(e, { value }) => setTitle(value)}
                />

                <SuperField as="choice"
                    search
                    required
                    autoFocus
                    label={t('type')}
                    value={type?.toString?.()}
                    type="gdpr_types"
                    onChange={(e, { value }) => setType(value)}
                />

            </Form.Group>
            <Form.Group widths="equal">
                <SuperField as="input"
                    type="number"
                    pattern="[0-9]*"
                    label={t('length_in_months')}
                    value={lengthInMonths}
                    onChange={(e, { value }) => setLengthInMonths(value)}
                />

                <SuperField as="checkbox"
                    label={t('is_required_gdpr')}
                    checked={isRequired}
                    onChange={(e) => setIsRequired(!isRequired)}
                    style={{ paddingTop: '1.5rem' }}
                />

            </Form.Group>

            <Divider />
            <TemplateEditor value={text} onChange={setText} />
            <Divider />

            <Form.Field style={{ textAlign: "right", margin: " 1rem" }}>
                <ModalCancel
                    onClose={onClose}
                    disabled={isProcessing}
                />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || isEmpty(type) || isEmpty(text)}
                    text={t('save')}
                />
            </Form.Field>
        </Form >
    )
}
export default GdprForm;