import React from 'react';
//components
import { Icon, Grid, Header } from 'semantic-ui-react'

function SegmentDetail(props) {

    return (
        <Grid columns={2}>
            <Grid.Row>
                <Grid.Column>
                    <Header as='h3'>
                        <Icon name={props.icon} />
                        <Header.Content>{props.header}</Header.Content>
                    </Header>
                    <p style={{ textAlign: "Left" }}>{props.text}</p>
                </Grid.Column>
                <Grid.Column style={{ textAlign: "right" }}>
                    {props.modal}
                </Grid.Column>
            </Grid.Row>
        </Grid>

    );
}

export default SegmentDetail;