import React from 'react';
import { Grid } from 'semantic-ui-react';
import AvatarIcon from '../AvatarIcon';

const AvatarWithName = ({ name, avatar, className }) => {
    return (
        <Grid className={`avatar-group ${className ? className : ''}`} columns='2' >
            <Grid.Row style={{ padding: "0.5rem", display: "flex", alignItems: "center", justifyContent: "left" }}>
                <div>
                    <AvatarIcon
                        size={30}
                        name={name}
                        src={avatar}
                        uploadable={false}
                    />
                </div>
                <div style={{ marginLeft: "0.5rem" }}>
                    {name}
                </div>
            </Grid.Row>
        </Grid>
    );
};

export default AvatarWithName;