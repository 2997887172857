import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { thousandsSeparators } from '@helpers/functions';
import { tzDateTime } from '@helpers/dates';
// components
import Icon from '@components/Icon';
import EmptyRow from '@components/tables/EmptyRow';
import SuperField from '@components/forms/SuperField';
import SpinnerSegment from '@components/SpinnerSegment';
import ModalCancel from '@components/buttons/ModalCancel';
import { Form, Checkbox, Divider, Button, Header, Label, Segment, Icon as SemanticIcon } from 'semantic-ui-react';

const SelectOrderForm = ({ journal, isIssued, invoiceID, taxMode, setOrderRef, setForm, setItems, onClose }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector(state => state.date_format)

    const [autofill, setAutofill] = useState(true)
    const [loading, setLoading] = useState(false)
    const [selectedFulfillments, setSelectedFulfillments] = useState([]) 
    const [search, setSearch] = useState("")
    const [fulfillments, setFulfillments] = useState([])
    const [account, setAccount] = useState(null)
    const [dateSupply, setDateSupply] = useState(null)

    const isPurchaseOrder = !isIssued

    const itemInitial = {
        id: 0,
        invoice: invoiceID,
        service_type: 1,
        amount: null,
        code: null,
        name: null,
        unit: null,
        unit_price: null,
        tax_rate: taxMode === 1 ? "20.00" : 0,
        discount: 0,
        note: "",
        processing: false,
        error: null
    }

    async function fetchFulfillments() {
        setLoading(true)
        let params = ""
        params += "&invoice_reference_empty=true"
        params += "&resource_type=transportfulfillment&resource_type=salefulfillment"
        params += "&only_basic_info=true"
        params += "&only_from_active_orders=true"
        params += "&limit=10&paginate=true"
        params += "&ordering=-created_on"
        params += "&is_purchase_order=" + isPurchaseOrder

        if ( search !== "" ) {
            params += "&search=" + search
        }

        if ( account !== null ) {
            params += `&account_id_number=` + account.identification_number 
        }
        const request = await requests.get(`${API.ORDERS}fulfillments/invoice_fill/?owner_id_number=${journal.id_number}${params}`)
        if (request.status === 200) {
            setFulfillments(request.response.results)
        }
        setLoading(false)
    }

    useEffect( () => {
        fetchFulfillments()
        // eslint-disable-next-line
    }, [])

    const onSearch = async () => {
        await fetchFulfillments()
    }

    const handleFulfillment = (fulfillment) => {
        if (selectedFulfillments.filter(item => item.id === fulfillment.id).length === 0) {
            if (selectedFulfillments.length === 0) setAccount(fulfillment.order.business_detail) 
            if (selectedFulfillments.length === 0) {
                if (fulfillment.resourcetype === "TransportFulfillment") {
                    setDateSupply(fulfillment.delivery_date)
                }
            }
            setSelectedFulfillments(prev => ([...prev, fulfillment]))
        } else {
            if (selectedFulfillments.length === 1) setAccount(null) 
            if (selectedFulfillments.length === 1) setDateSupply(null) 
            setSelectedFulfillments(prev => prev.filter(item => item.id !== fulfillment.id))
        }
    }

    const removeRef = (fulfillment) => {
        if (selectedFulfillments.length === 1) setAccount(null)
        setSelectedFulfillments(prev => prev.filter(item => item.id !== fulfillment.id))
    }
     
    const currentRef = () => {
        let refs = ""
        let orderNums = []
        let items = []
        for (let i = 0; i < selectedFulfillments.length; i++) {
            const fulfillment = selectedFulfillments[i]
            const order = fulfillment.order
            let order_origin_number = fulfillment.order.original_order_number
            let fulfillment_id = fulfillment.id
            refs += `ref:${order_origin_number}__${fulfillment_id}`

            let order_number = ""
            if (order.order_number !== null || order.order_number !== "") {
                order_number = order.order_number
            } else {
                order_number = order.original_order_number
            }

            if (!orderNums.includes(order_number)){
                orderNums.push(order_number)
            }

            let unit_price = null
            let name = null
            let amount = 1
            if (fulfillment.resourcetype === "TransportFulfillment") {
                unit_price = fulfillment.transportorder_price
                name = fulfillment.order.name 
            } 

            if (fulfillment.resourcetype === "ServiceFulfillment") {
                unit_price = fulfillment.total_fee
                name = `${fulfillment.order.name}: ${fulfillment?.profile?.fullname || ""} | ${fulfillment.total_hours} ${t('hours_shortcut')}` 
            }

            if (fulfillment.resourcetype === "SaleFulfillment") {
                amount = fulfillment.quantity
                unit_price = parseFloat(fulfillment.total_fee / fulfillment.quantity).toFixed(4)
                name = `${fulfillment.order_item.item.title}` 
            }

            items.push({
                ...itemInitial,
                amount: amount,
                unit_price: unit_price,
                name: name
            })
        }

        let order_number_str = ""
        for (let i = 0; i < orderNums.length; i++) {
            if (i + 1 < orderNums.length) {
                order_number_str += `${orderNums[i]}, `
            } else {
                order_number_str += `${orderNums[i]}`
            }
        }

        return {
            refs: refs,
            order_number: order_number_str,
            items: items,
        }
    }

    const handleConfirm = () => {
        const ref = currentRef()

        let additional = {}

        if (dateSupply !== null) {
            additional['date_supply'] = dateSupply
        }

        // if (isPurchaseOrder) {
        //     // find primary bank for supplier
        //     additional['iban'] = dateSupply
        //     additional['swift_code'] = dateSupply
        // }

        let phone = ""
        let email = ""
        const primaryContact = account?.contacts?.find(item => item?.is_invoicing)
        if (primaryContact) {
            phone = primaryContact?.phone || ""
            email = primaryContact?.email || ""
        } else {
            const primaryContact = account?.contacts?.[0]
            phone = primaryContact?.phone || ""
            email = primaryContact?.email || ""

        }

        setForm(prev => ({
            ...prev,
            order_number: ref.order_number,
            [isPurchaseOrder ? "supplier_name" : "customer_name"]: account?.name || "",
            [isPurchaseOrder ? "supplier_registration_id" : "customer_registration_id"]: account?.identification_number || "",
            [isPurchaseOrder ? "supplier_tax_id" : "customer_tax_id"]: account?.dit_number || "",
            [isPurchaseOrder ? "supplier_vat_id" : "customer_vat_id"]: account?.vat_number || "",
            [isPurchaseOrder ? "supplier_business_register_folder" : "customer_business_register_folder"]: account?.business_register_folder,
            [isPurchaseOrder ? "supplier_street" : "customer_street"]: account?.billing_address?.street || "",
            [isPurchaseOrder ? "supplier_zip" : "customer_zip"]: account?.billing_address?.postcode || "",
            [isPurchaseOrder ? "supplier_city" : "customer_city"]: account?.billing_address?.city || "",
            [isPurchaseOrder ? "supplier_country" : "customer_country"]: account?.billing_address?.country || "",
            [isPurchaseOrder ? "supplier_email" : "customer_email"]: email,
            [isPurchaseOrder ? "supplier_phone" : "customer_phone"]: phone,
            ...additional
        }))

        setItems(prev => ([...prev, ...ref.items]))
        setOrderRef(ref.refs)
    }

    const isSelected = (selected) => {
        if (account === null) return selected

        if (account?.id === selected?.id) {
            return selected
        }
    }

    const filteredFulfillments = fulfillments.filter(item => isSelected(item.order.business_detail))

    const Fulfillment = ({ isFirst, fulfillment }) => {
        return (
            <Segment
                style={{ 
                    marginTop: isFirst ? "0.5rem" : "1rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "0.5rem",
                    cursor: "pointer",
                    borderBottom: selectedFulfillments.filter(item => item.id === fulfillment.id).length !== 0 ? "2px solid var(--primary)" : "1px solid rgba(34,36,38,.15)" 
                }}
                onClick={() => handleFulfillment(fulfillment)}
            >
                <div>
                    <small> { t('order_number_full') } / { t('fulfillment') } </small> <br/>
                    <strong>{ fulfillment.order.order_number } / { fulfillment.id }</strong>

                    { fulfillment.resourcetype === "TransportFulfillment" && 
                        <div>
                            <small style={{ marginTop: "0.5rem" }}>
                                { fulfillment.order.name }
                            </small>
                        </div>
                    }
                    { fulfillment.resourcetype === "ServiceFulfillment" && 
                        <div>
                            <small style={{ marginTop: "0.5rem" }}>
                                { fulfillment.profile.fullname }: { moment(fulfillment.date_from).format(dateFormat) } -- { moment(fulfillment.date_to).format(dateFormat) } 
                            </small>
                        </div>
                    }

                    { fulfillment.resourcetype === "SaleFulfillment" && 
                        <div>
                            <small style={{ marginTop: "0.5rem" }}>
                                { fulfillment.order_item.item.title } 
                            </small>
                        </div>
                    }
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div style={{ textAlign: "right", marginBottom: "0.5rem", opacity: 0.8 }}>
                        {t('created')} {" "} { tzDateTime(fulfillment.created_on).format(dateFormat + " HH:mm") }
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        { fulfillment.resourcetype === "TransportFulfillment" &&
                            <Label basic style={{ marginRight: "0.5rem" }}>
                                { fulfillment.transportorder_car_plate_number || fulfillment.transportorder_external_car_plate_number }
                            </Label>
                        }
                        <Label basic style={{ marginRight: "0.5rem" }}>
                            <Icon name="business-outline" style={{ marginRight: "0.4rem" }}/>
                            <span style={{ position: "relative", top: "-0.1rem" }}>
                                { fulfillment.order?.business_detail?.name }
                            </span>
                        </Label>
                        <Label basic style={{ marginRight: "0.5rem" }}>
                            { fulfillment.resourcetype === "TransportFulfillment" && 
                                <span>{ thousandsSeparators(fulfillment?.transportorder_price || 0) } { fulfillment.order.transportorder_price_currency }</span> 
                            }
                            { fulfillment.resourcetype === "ServiceFulfillment" && 
                                <span>{ thousandsSeparators(fulfillment?.total_fee || 0) } EUR</span> 
                            }

                            { fulfillment.resourcetype === "SaleFulfillment" && 
                                <span>{ thousandsSeparators(fulfillment?.total_fee || 0) } { fulfillment.currency }</span> 
                            }
                        </Label>
                        <Label style={{ 
                            background: fulfillment.resourcetype === "TransportFulfillment" ? "var(--primary)" :
                                fulfillment.resourcetype === "ServiceFulfillment" ? "var(--info)" : 
                                fulfillment.resourcetype === "SaleFulfillment" ? "var(--blue)" : "var(--dark)"
                                
                            , 
                            color: "var(--white)"
                        }}>
                            { fulfillment.resourcetype === "TransportFulfillment" && t('transport') }
                            { fulfillment.resourcetype === "ServiceFulfillment" && t('service') }
                            { fulfillment.resourcetype === "SaleFulfillment" && t('business') }
                        </Label>
                    </div>
                </div>
            </Segment>
        )
    }
    
    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <Header as="h4" content={t("select_from_order_fulfillments")} style={{ margin: 0 }}/>
                <div>
                    { isPurchaseOrder ? t('supplier') : t('account') }: <strong>{ account?.name || "--" }</strong>
                </div>
            </div>

            <Form.Field style={{ width: 'auto', marginTop: "0.5rem" }}>
                <SuperField as="input"
                    fluid
                    autoFocus
                    disabled={loading}
                    icon={
                        <SemanticIcon
                            link
                            name="search"
                            onClick={() => onSearch()}
                        />
                    }
                    value={search}
                    placeholder={t('search')}
                    onChange={(e, { value }) => setSearch(value)}
                    onKeyPress={(event) => {
                        if (event.key === "Enter"){
                            onSearch()
                        }
                    }}
                />
            </Form.Field>

            { selectedFulfillments.length > 0 &&
                <Label.Group size="tiny" style={{ marginTop: "0.5rem" }}>
                    { selectedFulfillments.map(item => (
                        <Label basic> 
                            { item.order.order_number } / { item.id } {" "}
                            <Icon 
                                name="close-outline" 
                                onClick={() => removeRef(item)}
                                style={{ color: "var(--danger)", marginLeft: "0.3rem", position: "relative", top: "0.05rem", cursor: "pointer" }}
                            />
                        </Label>
                    )) }
                </Label.Group>
            }

            <SpinnerSegment loading={loading}>
                <EmptyRow length={filteredFulfillments.length}/>
                { filteredFulfillments.map((fulfillment, idx) => (
                    <Fulfillment 
                        key={idx}
                        isFirst={idx === 0}
                        fulfillment={fulfillment}
                    />
                ))}
                
            </SpinnerSegment>

            <Divider/>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <div>
                    <Checkbox
                        label={t('allow_to_autofill_data_from_order')}
                        checked={autofill}
                        onChange={() => setAutofill(!autofill)}
                    />
                </div>
                <Form.Field centered>
                    <ModalCancel onClose={onClose} disabled={loading}/>
                    <Button 
                        primary
                        type="button"
                        content={t('confirm')}
                        onClick={() => handleConfirm()}
                        disabled={selectedFulfillments.length === 0 && account !== null}
                    />
                </Form.Field>
            </div>
        </>
    );
};

export default SelectOrderForm;