import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
// eslint-disable-next-line
import { API } from '@store/config'
// eslint-disable-next-line
import { requests } from '@helpers/requests'
import { isEmpty } from '@helpers/validation'
// components
import DocPreview from '@components/DocPreview'
import SuperField from '@components/forms/SuperField'
import NonFieldErrors from '@components/NonFieldErrors'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import { Form, Divider, Grid, Header } from 'semantic-ui-react'
import SuperDuperModal from '@components/modals/SuperDuperModal'

const IdentityDocForm = ({ onClose, profile_id, type, record, setData }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    // eslint-disable-next-line
    const [imgErrors, setImgErrors] = useState(null)
    // eslint-disable-next-line
    const [errors, setErrors] = useState(null)

    const [frontImage, setFrontImage] = useState(null)
    const [backImage, setBackImage] = useState(null)
    const [docImages, setDocImages] = useState({
        front: record?.image_front || null,
        back: record?.image_back || null,
    })
    const [form, setForm] = useState({
        type: record?.type || type,
        profile: record?.profile?.id || profile_id,
        document_number: record?.document_number || '',
        valid_until: record?.valid_until || '',
        issued_date: record?.issued_date || '',
        issued_country: record?.issued_country || 'SK',
        note: record?.note || '',
    })

    const handleSubmit = async () => {
        setErrors(null)
        setImgErrors(null)
        setProcessing(true)

        let request = null
        let data = form
        if (frontImage === null && docImages.front === null) data.image_front = null
        if (backImage === null && docImages.back === null) data.image_back = null
        if (data.issued_date === '') data.issued_date = null
        if (data.valid_until === '') data.valid_until = null
        if (data.issued_country === '') data.issued_country = 'SK'
        if (record?.id === undefined) {
            request = await requests.post(API.IDENTITY_DOCUMENTS, data)
        } else {
            request = await requests.patch(API.IDENTITY_DOCUMENTS + record?.id + '/', data)
        }

        if (request?.status === 400) setErrors(request?.response || null)
        if ([200, 201].includes(request?.status)) {
            let document_id = record?.id || null // get ID
            if (request?.status === 201) document_id = request?.response?.id || null

            if (document_id !== null) {
                // attempt to upload images
                const formData = new FormData()
                if (frontImage !== null) formData.append('image_front', frontImage)
                if (backImage !== null) formData.append('image_back', backImage)

                if (formData.get('image_front') !== null || formData.get('image_back') !== null) {
                    const request_upload = await requests.patch(
                        API.IDENTITY_DOCUMENTS + document_id + '/file/',
                        formData
                    )
                    if (request_upload.status === 400) {
                        setImgErrors(request_upload.response)
                        setProcessing(false)
                        return
                    }
                }

                // fetch original object
                const fetch_request = await requests.get(API.IDENTITY_DOCUMENTS + document_id + '/')
                if (fetch_request.status === 200) {
                    setData((prev) => {
                        if (prev.find((item) => item.id === document_id)) {
                            return prev.map((item) => {
                                if (item.id === document_id) {
                                    item = fetch_request.response
                                }
                                return item
                            })
                        } else {
                            return [...prev, fetch_request.response]
                        }
                    })
                    onClose()
                }
            }
        }

        setProcessing(false)
    }

    const hiddenFrontFileInput = React.useRef(null)
    const hiddenBackFileInput = React.useRef(null)

    const handleFrontClick = (event) => {
        hiddenFrontFileInput.current.click()
    }

    const handleBackClick = (event) => {
        hiddenBackFileInput.current.click()
    }

    const handleFrontChange = function loadFile(event) {
        if (event.target.files.length > 0) {
            setFrontImage(event.target.files[0])
        }
    }

    const handleBackChange = function loadFile(event) {
        if (event.target.files.length > 0) {
            setBackImage(event.target.files[0])
        }
    }

    const handlePreview = (file) => {
        let result = ''
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            result = reader.result
        }

        return result
    }

    const onRemove = (type) => {
        if (type === 'front') {
            document.getElementById('front_image').value = null
            setDocImages((prev) => ({ ...prev, front: null }))
            setFrontImage(null)
        } else {
            document.getElementById('back_image').value = null
            setDocImages((prev) => ({ ...prev, back: null }))
            setBackImage(null)
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <NonFieldErrors errors={errors} />
            <Form.Group widths="equal">
                <SuperField
                    as="input"
                    required
                    autoFocus
                    label={t('document_number')}
                    value={form.document_number}
                    error={errors?.document_number || false}
                    onChange={(e, { value }) => setForm({ ...form, document_number: value })}
                />

                <SuperField
                    as="choice-select"
                    search
                    label={t('issued_country')}
                    type="countries"
                    value={form.issued_country}
                    error={errors?.issued_country || false}
                    onChange={(e, { value }) => setForm({ ...form, issued_country: value })}
                    initialOptions={{
                        attribute: 'issued_country',
                        as_display: true,
                        source: record,
                    }}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField
                    as="datepicker"
                    label={t('valid_until')}
                    value={form.valid_until}
                    error={errors?.valid_until || false}
                    onChange={(e, { value }) => setForm({ ...form, valid_until: value })}
                />
                <SuperField
                    as="datepicker"
                    label={t('issued_date')}
                    value={form.issued_date}
                    error={errors?.issued_date || false}
                    onChange={(e, { value }) => setForm({ ...form, issued_date: value })}
                />
            </Form.Group>

            <SuperField
                as="textarea"
                label={t('note')}
                value={form.note}
                error={errors?.note || false}
                onChange={(e, { value }) => setForm({ ...form, note: value })}
            />

            <Divider />

            <Grid stackable>
                <Grid.Row columns={2} verticalAlign="middle" textAlign="center">
                    <Grid.Column>
                        <input
                            type="file"
                            id="front_image"
                            name="front_image"
                            ref={hiddenFrontFileInput}
                            onChange={handleFrontChange}
                            style={{ display: 'none' }}
                        />
                        {frontImage === null && docImages.front === null ? (
                            <div
                                style={{
                                    padding: '2.5rem',
                                    cursor: 'pointer',
                                    borderRadius: '5px',
                                    border: '1px dotted black',
                                }}
                                onClick={handleFrontClick}
                            >
                                <Header as="h4" content={t('front_side')} style={{ marginTop: 0 }} />
                            </div>
                        ) : (
                            <div>
                                <SuperDuperModal
                                    size="large"
                                    trigger={
                                        <div
                                            className="ref-link"
                                            style={{
                                                color: 'var(--primary)',
                                                fontWeight: 'bold',
                                                padding: '2rem',
                                                borderRadius: '5px',
                                                border: '1.5px dotted var(--dark)',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {t('show_preview') + ' 1'}
                                        </div>
                                    }
                                    content={
                                        <DocPreview
                                            uri={docImages.front ? docImages.front : handlePreview(frontImage)}
                                        />
                                    }
                                />
                                <div style={{ display: 'flex', fontWeight: 'bold', marginTop: '0.5rem' }}>
                                    <p style={{ width: '50%', textAlign: 'left' }}>{t('front_side')}</p>
                                    <p
                                        style={{
                                            width: '50%',
                                            textAlign: 'right',
                                            color: 'var(--danger)',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <span onClick={() => onRemove('front')}>{t('remove')}</span>
                                    </p>
                                </div>
                            </div>
                        )}
                    </Grid.Column>
                    <Grid.Column>
                        <input
                            type="file"
                            id="back_image"
                            name="back_image"
                            ref={hiddenBackFileInput}
                            onChange={handleBackChange}
                            style={{ display: 'none' }}
                        />
                        {backImage === null && docImages.back === null ? (
                            <div
                                style={{
                                    padding: '2.5rem',
                                    cursor: 'pointer',
                                    borderRadius: '5px',
                                    border: '1px dotted black',
                                }}
                                onClick={handleBackClick}
                            >
                                <Header as="h4" content={t('back_side')} style={{ marginTop: 0 }} />
                            </div>
                        ) : (
                            <div>
                                <SuperDuperModal
                                    size="large"
                                    trigger={
                                        <div
                                            className="ref-link"
                                            style={{
                                                color: 'var(--primary)',
                                                fontWeight: 'bold',
                                                padding: '2rem',
                                                borderRadius: '5px',
                                                border: '1.5px dotted var(--dark)',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {t('show_preview') + ' 2'}
                                        </div>
                                    }
                                    content={
                                        <DocPreview uri={docImages.back ? docImages.back : handlePreview(backImage)} />
                                    }
                                />
                                <div style={{ display: 'flex', fontWeight: 'bold', marginTop: '0.5rem' }}>
                                    <p style={{ width: '50%', textAlign: 'left' }}>{t('back_side')}</p>
                                    <p
                                        style={{
                                            width: '50%',
                                            textAlign: 'right',
                                            color: 'var(--danger)',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <span onClick={() => onRemove('back')}>{t('remove')}</span>
                                    </p>
                                </div>
                            </div>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <NonFieldErrors errors={imgErrors} />
            {imgErrors?.image_front?.[0] && <p style={{ color: 'var(--danger)' }}> {imgErrors?.image_front?.[0]} </p>}
            {imgErrors?.image_back?.[0] && <p style={{ color: 'var(--danger)' }}> {imgErrors?.image_back?.[0]} </p>}

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    disabled={processing || isEmpty(form.document_number)}
                    loading={processing}
                    content={t('confirm')}
                />
            </Form.Field>
        </Form>
    )
}

export default IdentityDocForm
