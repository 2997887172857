import React, { useEffect, useState } from 'react';

// store
import { getRequest } from '@store/services/ServiceCommon';
import { API } from '@store/config';

// components
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';
import { FlexItem, FlexRow } from '@components/tables/FlexTable';
import SuperField from '@components/forms/SuperField';

import { Bar } from 'react-chartjs-2';

export const options = {
  plugins: {
    title: {
      display: true,
      text: 'Chart.js Bar Chart - Stacked',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const Fluctuations = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    start_date: '',
    end_date: '',
  });

  const labels = data.map((el) => {
    return el.date;
  });

  const barData = {
    labels,
    datasets: [
      {
        label: 'terminations_count',
        data: data.map((el) => {
          return el.terminations_count - 2 * el.terminations_count;
        }),
        backgroundColor: 'rgb(255, 99, 132)',
      },
      {
        label: 'engagements_count',
        data: data.map((el) => {
          return el.engagements_count;
        }),
        backgroundColor: 'rgb(75, 192, 192)',
      },
    ],
  };

  useEffect(() => {
    const today = new Date();

    let month = today.getMonth() + 1;
    today.setMonth(month);
    let nextMonths = today.getMonth() + 1;

    setFilters({
      ...filters,
      start_date: today.getFullYear() + '-' + month + '-' + today.getDate(),
      end_date: today.getFullYear() + '-' + nextMonths + '-' + today.getDate(),
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function fetchStats() {
      setLoading(true);
      const request = await getRequest(
        API.CONTRACTS_FLUCTUATIONS + '?end_date=' + filters.end_date + '&start_date=' + filters.start_date + ''
      );

      if (request.status === 200) {
        setData(request.response);
      }
      setLoading(false);
    }

    fetchStats();
  }, [filters]);

  return (
    <>
      <FlexRow padding="0,5rem">
        <FlexItem>
          <Header as="h2" textAlign="left" content={t('fluctuation')} />
        </FlexItem>
        <FlexItem textAlign="right" basis="50%">
          <SuperField
            as="datepicker"
            placeholder={t('start_date')}
            value={filters.start_date}
            onChange={(e, { value }) => setFilters({ ...filters, start_date: value })}
          />
        </FlexItem>
        <FlexItem textAlign="right" basis="30%">
          <SuperField
            as="datepicker"
            placeholder={t('end_date')}
            value={filters.end_date}
            onChange={(e, { value }) => setFilters({ ...filters, end_date: value })}
          />
        </FlexItem>
      </FlexRow>

      <Bar options={options} data={barData} />
    </>
  );
};

export default Fluctuations;
