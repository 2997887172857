import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// components
import EmptyList from '@components/EmptyList';
import { Button } from 'semantic-ui-react';
// module specific components 
import IssueFilters from './tracker/IssueFilters';
import IssueViews from './tracker/IssueViews';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import IssueForm from './forms/IssueForm';

const IssueTracker = ({ stats, issues, setIssues, groups, setStats, setFiltered, filtered, state, setState, isLoading, setIsLoading }) => {
    const { t } = useTranslation();
    const [selectedView, setSelectedView] = useState("list") // ListView by default
    return (
        <>
            <IssueFilters
                selectedView={selectedView}
                setSelectedView={setSelectedView}
                issues={issues}
                setIssues={setIssues}
                setIsLoading={setIsLoading}
                stats={stats}
                setStats={setStats}
                setFiltered={setFiltered}
                state={state}
                setState={setState}
            />

            {/* Issue List - on empty*/}
            <EmptyList
                isEmpty={issues.length === 0} // check if there is any issue for rendering
                icon="tasks"
                text={t('no_issues_tracked_yet')}
                content={
                    <SuperDuperModal
                        size="small"
                        closeIcon
                        trigger={
                            <Button primary content={t('add')} />
                        }
                        content={
                            <IssueForm employees={stats} setIssues={setIssues} setStats={setStats} />
                        }
                    />
                }
            />

            {/* Based on selectedView state, view is rendered. Currently supported views: list, kanban - not so much :*) */}
            <IssueViews
                issues={issues}
                stats={stats}
                setIssues={setIssues}
                groups={groups.data}
                selectedView={selectedView}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                setStats={setStats}
                filtered={filtered}
                state={state}
            />
        </>


    );
};

export default IssueTracker;