import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components 
import { Form, Icon } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const UpdateStoredItemForm = ({ parentItem, storedItem, setStoredItems, onClose }) => {
    const { t } = useTranslation();
    const [isProcessing, setIsProcessing] = useState(false)
    const [alreadyStored, setAlreadyStored] = useState(false)
    const [form, setForm] = useState({
        sn: storedItem.sn,
        code: storedItem?.code || null,
        note: storedItem?.note || "",
        critical_amount: storedItem.critical_amount,
    })

    const isUnique = (item) => {
        return (item.sn !== null && item.sn !== "")
    }

    const handleSubmit = async () => {
        setAlreadyStored(false)
        setIsProcessing(true)
        const request = await requests.patch(API.ASSET_STORED_ITEMS + storedItem.id + "/", { 
            sn: isUnique(storedItem) ? form.sn : null,
            code: form.code,
            note: form.note,
            critical_amount: parseInt(form.critical_amount),
        })

        if( request.status === 200 ){
            if (parentItem?.id !== undefined) {
                const getStoredItem = await requests.get(API.ASSET_STORED_ITEMS + parentItem?.id + "/")
                if (getStoredItem.status === 200) {
                    setStoredItems(prev => ({
                        ...prev,
                        data: {
                            ...prev.data,
                            results: prev.data.results.map(item => {
                                if( item.id === parentItem.id ){
                                    item = getStoredItem.response
                                }
            
                                return item
                            })
                        }
                    }))
                } 
            } else {
                setStoredItems(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        results: prev.data.results.filter(item => {
                            if( item.id === storedItem.id ){
                                item.sn = request.response.sn
                                item.code = request.response.code
                                item.note = request.response.note
                                item.critical_amount = request.response.critical_amount
                            }
        
                            return item
                        })
                    }
                }))
            }
            onClose()
        }

        if( request.status === 409 ){ // conflict - already stored in this warehouse  
            setAlreadyStored(true)
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
                { isUnique(storedItem) &&
                    <SuperField as="input"
                        required
                        label={t('serial_number')}
                        value={form.sn}
                        error={(form.sn === "" || form.sn === null) ? t('validation_serial_number_is_required_field') : null}
                        onChange={ (e, { value }) => setForm({ ...form, sn: value })}
                    />
                }

                { !isUnique(storedItem) &&
                    <SuperField as="input"
                        type="number"
                        min="0"
                        label={t('minimum_stock_limit')}
                        help={t('hint_system_will_send_notification_when_limit_is_reached')}
                        value={form.critical_amount}
                        error={isUnique(storedItem) && form.critical_amount > 1 ? t('validation_stock_limit_can_not_be_higher_then_one') : null}
                        onChange={ (e, { value }) => setForm({ ...form, critical_amount: value })}
                    />
                }
            </Form.Group>

            <SuperField as="input"
                label={t('code')}
                value={form.code}
                onChange={ (e, { value }) => setForm({ ...form, code: value })}
            />
            <SuperField as="textarea"
                label={t('note')}
                value={form.note}
                onChange={ (e, { value }) => setForm({ ...form, note: value })}
            />

            { alreadyStored && 
                <Form.Field style={{ textAlign: "left", fontWeight: "bold", color: "var(--danger)"}}>
                    <Icon name="warning"/> { t('validation_already_stored_to_this_warehouse') }
                </Form.Field>
            }

            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={
                        isProcessing || 
                        form.warehouse === "" || 
                        form.warehouse === null || 
                        (isUnique(storedItem) && form.critical_amount > 1)
                    }
                    text={t('save')} />
            </Form.Field>
        </Form>
    );
};

export default UpdateStoredItemForm;