import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import { Form, Header, Divider, Message } from 'semantic-ui-react'

const EmploymentPersonsForm = ({ record, setData, onClose }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [form, setForm] = useState({
        contracts: [],
        valid_from: '',
        valid_to: '',
        calculate_work_hours_based_on: '1',
    })

    const handleSubmit = async () => {
        setProcessing(true)

        let totalCreated = []
        if (form.contracts.length > 0) {
            let employees = form.contracts
            for (let i = 0; i < employees.length; i++) {
                let data = {}
                data.valid_from = form.valid_from === '' ? null : form.valid_from
                data.valid_to = form.valid_to === '' ? null : form.valid_to
                if (record.fee_per_hour > 0 && record.fee_per_hour !== '' && record.fee_per_hour !== null) {
                    data.fee_per_hour = record.fee_per_hour
                } else {
                    data.fee_per_hour = 1 // can not be empty or null
                }

                data.contract = parseInt(employees[i])

                if (record.workplace_address?.id !== undefined) {
                    data.workplace_address = {
                        ...record.workplace_address,
                    }
                } else {
                    data.workplace_address = null
                }
                const request = await requests.post(API.ORDERS + 'employment_items/', data)
                if (request.status === 201) {
                    totalCreated.push(request.response.id)
                }
            }
        }

        if (totalCreated.length > 0) {
            const requestUpdateOrder = await requests.patch(API.ORDERS + record.id + '/', {
                resourcetype: 'EmploymentOrder',
                items: {
                    add: totalCreated,
                },
            })

            if (requestUpdateOrder.status === 200) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('person_was_assigned_to_order'),
                    animation: 'pulse',
                    time: 2000,
                })
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === record.id) {
                            item = requestUpdateOrder.response
                        }
                        return item
                    })
                )
                onClose()
            }
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Header as="h3">{t('assign_persons_for_employment')}</Header>
            <Divider />
            <Message
                info
                visible
                content={
                    <strong>
                        <Icon
                            name="information-circle"
                            style={{ marginRight: '0.5rem', fontSize: '1.2rem', position: 'relative', top: '0.2rem' }}
                        />
                        {t('assign_person_for_employment_form_hint')}.
                    </strong>
                }
            />

            <SuperField
                as="choice-select"
                search
                required
                multiple
                label={t('employees')}
                value={form.contracts}
                endpoint={API.CONTRACTS}
                help={t('list_of_contract_for_employment_hint')}
                additionalFilters={`query={id, valid_from, valid_until, employer, workplace_address, work_position{id, title}, employee{id, fullname, fullname_with_titles}}&status=1&status=3&status=6&exclude_assigned_to_employment_order=${
                    record.id
                }&ordering=-valid_from${
                    record.owner?.business_detail?.id ? `&employer=${record.owner?.business_detail?.id}` : ''
                }`}
                text={(item) => item.employee.fullname_with_titles + ' - ' + item.work_position.title}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, contracts: value }))}
            />

            <Form.Group widths="equal">
                <SuperField
                    as="datepicker"
                    label={t('valid_from')}
                    value={form.valid_from}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, valid_from: value }))}
                />
                <SuperField
                    as="datepicker"
                    label={t('valid_to')}
                    value={form.valid_to}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, valid_to: value }))}
                />
            </Form.Group>

            <SuperField
                as="choice"
                clearable={false}
                type="employment_order_calculate_based_on"
                label={t('calculate_work_hours_based_on')}
                help={t('calculate_work_hours_based_on_hint')}
                value={form.calculate_work_hours_based_on}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, calculate_work_hours_based_on: value }))}
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    text={t('confirm')}
                    loading={processing}
                    disabled={processing || form.contracts.length === 0}
                />
            </Form.Field>
        </Form>
    )
}

export default EmploymentPersonsForm
