import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import Icon from '@components/Icon';
import { Form, Grid, Header, Divider, Button } from 'semantic-ui-react';
import DatePresets from '@components/DatePresets';
import SuperField from '@components/forms/SuperField';

const OverviewFilterForm = ({ filters, setFilters, initialFilters, setVisible, fetchData }) => {
    const { t } = useTranslation()

    return (
        <Form
            style={{ paddingLeft: '1rem', paddingRight: '1rem', textAlign: 'left' }}
            onSubmit={(e) => e.preventDefault()}
        >
            <Grid>
                <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
                    <Grid.Column>
                        <Header as="h2" content={t('filters')} style={{ marginTop: '1rem', textAlign: 'left' }} />
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Icon
                            name="close-outline"
                            style={{
                                cursor: 'pointer',
                                color: 'var(--danger)',
                                marginTop: '1.5rem',
                                fontSize: '1.5rem',
                            }}
                            onClick={() => setVisible(false)}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider />

            <Form.Group widths="equal">
                <SuperField
                    search
                    as="datepicker"
                    label={t('date_from')}
                    value={filters.date_from}
                    onChange={(e, { value }) => {
                        setFilters(prev => ({ ...prev, date_from: value }))
                    }} 
                    />
                <SuperField
                    search
                    as="datepicker"
                    label={t('date_to')}
                    value={filters.date_to}
                    onChange={(e, { value }) => {
                        setFilters(prev => ({ ...prev, date_to: value }))
                    }} 
                />
            </Form.Group>
            <DatePresets
                from={filters.date_from}
                to={filters.date_to}
                onSelection={ (firstMonthDay, lastMonthDay, year) => {
                    setFilters(prev => ({ ...prev, date_from: firstMonthDay, date_to: lastMonthDay }))
                }}
            />  

            <Divider />

            <Form.Group widths="equal">
                <SuperField as="input"
                    label={ t('name') }
                    value={ filters.name }
                    onChange={ (e, { value }) => setFilters({...filters, name: value})}
                />
                <SuperField as="input"
                    label={ t('registration_id') }
                    value={ filters.registration_id }
                    onChange={ (e, { value }) => setFilters({...filters, registration_id: value})}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField as="input"
                    label={ t('tax_id') }
                    value={ filters.tax_id }
                    onChange={ (e, { value }) => setFilters({...filters, tax_id: value})}
                />
                <SuperField as="input"
                    label={ t('vat_id') }
                    value={ filters.vat_id }
                    onChange={ (e, { value }) => {
                        setFilters({...filters, vat_id: value})
                    }}
                />
            </Form.Group>

            <SuperField as="choice"
                search
                clearable={false}
                label={ t('currency') }
                type="currency_codes"
                value={ filters.currency }
                onChange={ (e, { value }) => setFilters({...filters, currency: value}) }
            />

            <SuperField as="checkbox"
                label={t('allow_to_count_with_tax')}
                checked={filters.count_with_tax}
                onChange={() => setFilters(prev => ({ ...prev, count_with_tax: !filters.count_with_tax }))}
            />

            <Divider />
            <Form.Field>
                <Button type="button" content={t('reset')} onClick={() => setFilters(initialFilters)} />
                <Button primary type="button" content={t('confirm')} onClick={async () => await fetchData()} />
            </Form.Field>
        </Form>
    );
};

export default OverviewFilterForm;