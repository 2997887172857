import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import SuperField from '@components/forms/SuperField'
import { Divider, Form, Message } from 'semantic-ui-react'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const ProjectMemberCreateForm = ({ project, setData, members, setMembers, isDetail, setTotal, onClose }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState(null)

    const [form, setForm] = useState({
        profile: null,
        project: project.id,
        gitlab_role: null,
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        if (form.profile.length > 0) {
            const newMembers = []
            for (let i = 0; i < form.profile.length; i++) {
                const request = await requests.post(API.PROJECT_MEMBERS, {
                    profile: form.profile[i],
                    project: project.id,
                    gitlab_role: form.gitlab_role,
                })

                if (request.status === 201) {
                    newMembers.push(request.response)
                } else if (request.status === 400) {
                    setErrors(request.response)
                }

                if (isDetail === undefined) {
                    setData((prev) => [...newMembers, ...prev])
                    setMembers((prev) => [...newMembers, ...prev])
                    if (setTotal) setTotal((prev) => prev + newMembers.length)
                } else {
                    setData((prev) => [...newMembers, ...prev])
                }
            }
            onClose()
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors?.non_field_errors?.length > 0}
                header={t('error_submission_message')}
                list={errors?.non_field_errors}
            />
            <SuperField
                as="choice"
                search
                multiple
                endpoint={API.EMPLOYEES + '?only_basic_info=true&has_user=true'}
                exclude={members.map((item) => item.profile.id)}
                style={{ marginTop: '4px' }}
                text="fullname"
                label={t('employee')}
                value={form?.profile?.id}
                error={errors === null ? false : errors?.profile}
                onChange={(e, { value }) => setForm({ ...form, profile: value })}
            />
            {project?.allow_gitlab && (
                <SuperField
                    as="choice"
                    type="gitlab_roles"
                    label={t('gitlab_role')}
                    value={form.gitlab_role}
                    onChange={(e, { value }) => setForm({ ...form, gitlab_role: value })}
                />
            )}
            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit disabled={isProcessing} loading={isProcessing} />
            </Form.Field>
        </Form>
    )
}

export default ProjectMemberCreateForm
