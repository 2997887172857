import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperField from '@components/forms/SuperField';
import { Form, Divider } from 'semantic-ui-react';

const AssignProcessGroupForm = ({ selected, setSelected, setData, onClose }) => {
    const { t } = useTranslation()

    const [processes, setProcesses] = useState([])
    const [processGroup, setProcessGroup] = useState("")
    const [processing, setProcessing] = useState(false)
    const [loadingProcessGroup, setLoadingProcessGroup] = useState(false)

    useEffect(() => {
        async function fetchProcessGroups() {
            setLoadingProcessGroup(true)
            const request = await requests.get(API.ORDERS + "sale_processes/?query={id, title}")
            if (request.status === 200) setProcesses(request.response)
            setLoadingProcessGroup(false)
        }

        fetchProcessGroups()
    }, [])

    const handleAddProcessGroup = async (value) => {
        setLoadingProcessGroup(true)
        const request = await requests.post(API.ORDERS + "sale_processes/", {
            title: value
        })

        if (request.status === 201) {
            setProcessGroup(request.response.id)
            setProcesses(prev => [...prev, request.response])
        }
        setLoadingProcessGroup(false)
    }

    const handleSubmit = async () => {
        setProcessing(true)

        let updated = []
        for (let i = 0; i < selected.length; i++) {
            const request = await requests.patch(API.ORDERS + "sale_stages/" + selected[i].id + "/", {
                process: processGroup
            })

            if (request.status === 200) {
                updated.push(request.response) 
            }
        }

        setData(prev => prev.filter(item => {
            const itemToUpdate = updated.find(updatedItem => updatedItem.id === item.id)
            if (item.id === itemToUpdate?.id) {
                item.process = itemToUpdate.process
            }
            return item
        }))

        onClose()
        setSelected([])
        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="choice"
                search
                value={processGroup}
                allowAdditions={true}
                label={t('process_group')}
                loading={loadingProcessGroup}
                disabled={loadingProcessGroup}
                help={t('process_group_of_orders_hint')}
                onAddItem={(e, { value }) => handleAddProcessGroup(value)}
                onChange={(e, { value }) => setProcessGroup(value)}
                customOptions={processes.map(item => ({ key: item.id, value: item.id, text: item.title }))}
            />
            
            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    text={t('confirm')}
                    loading={processing}
                    disabled={processGroup === "" || processing}
                />
            </Form.Field>
        </Form>
    );
};

export default AssignProcessGroupForm;