import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
// store
import { API } from '@store/config'
// components
import Icon from '@components/Icon'
import UserAvatar from 'react-user-avatar'
import ListView from '@components/ListView'
import Action from '@components/general/Action'
import SuperField from '@components/forms/SuperField'
import { Form, Popup } from 'semantic-ui-react'
import GenerateDocuments from '@components/GenerateDocuments'
// specific components
import VehicleForm from '../components/VehicleForm'
import VehicleDetailView from './VehicleDetailView'
import VehicleDocuments from '../components/VehicleDocuments'
import VehiclesDocumentView from './VehiclesDocumentView'

const VehiclesList = () => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)
    const [forceModalOpen, setForceModalOpen] = useState(0)

    return (
        <ListView
            as="table"
            allowSearch
            allowSelection
            actionsCellWidth="2"
            isSoftDeleteAllowed
            endpoint={API.VEHICLES}
            initialFilters={{
                car_plate_number: '',
                fuel_type: '',
                owner: '',
                tss_uuid: '',
                type: '',
                vin: '',
                responsible_persons: '',
                ordering: 'title',
            }}
            bulkActions={(selected) => [
                {
                    as: 'modal',
                    name: t('generate_documents_for_vehicle'),
                    icon: 'document-text-outline',
                    modal: <GenerateDocuments source="vehicles.Vehicle" selected={selected} />,
                },
                {
                    as: 'modal',
                    name: t('generate_documents_for_responsible_person'),
                    icon: 'document-text-outline',
                    modal: <VehiclesDocumentView selectedVehicles={selected} />,
                },
            ]}
            tableHeaders={[
                { title: t('title') },
                { title: t('owner') },
                { title: t('fuel_type') },
                { title: t('car_plate_number') },
                { title: t('vignette') },
                { title: t('inspections') },
                { title: t('responsible_persons') },
                { title: t('documents') },
            ]}
            renderFilterFields={(filters, setFilters) => (
                <>
                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            label={t('car_plate_number')}
                            value={filters.car_plate_number}
                            onChange={(e, { value }) => setFilters({ ...filters, car_plate_number: value })}
                        />
                        <SuperField
                            as="input"
                            label={t('vin')}
                            value={filters.vin}
                            onChange={(e, { value }) => setFilters({ ...filters, vin: value })}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField
                            as="choice-select"
                            label={t('fuel_type')}
                            type="fuel_type"
                            value={filters.fuel_type?.toString() || ''}
                            onChange={(e, { value }) => setFilters({ ...filters, fuel_type: value })}
                        />
                        <SuperField
                            as="choice-select"
                            search
                            label={t('vehicle_type')}
                            endpoint={API.VEHICLES + 'types/'}
                            text="title"
                            value={filters.type}
                            onChange={(e, { value }) => setFilters({ ...filters, type: value })}
                        />
                    </Form.Group>
                    <SuperField
                        as="choice-select"
                        search
                        label={t('owner')}
                        value={filters?.owner || ''}
                        endpoint={API.BUSINESS_DETAIL}
                        text={(item) => item.name + ' - ' + item.identification_number}
                        additionalFilters={'&query={id, name, identification_number}&is_employer=true'}
                        onChange={(e, { value }) => setFilters({ ...filters, owner: value })}
                    />
                    <SuperField
                        as="choice-select"
                        search
                        endpoint={API.EMPLOYEES}
                        text="fullname_with_titles"
                        label={t('responsible_person')}
                        additionalFilters={'&only_basic_info=true&is_active=true'}
                        value={filters?.responsible_persons || ''}
                        onChange={(e, { value }) => setFilters({ ...filters, responsible_persons: value })}
                    />
                </>
            )}
            actions={[
                {
                    as: 'modal',
                    type: 'add',
                    name: t('add_vehicle'),
                    modal: <VehicleForm />,
                    permissions: ['vehicles.c_manage_all_vehicles'],
                },
                {
                    as: 'modal',
                    type: 'custom',
                    icon: 'eye-outline',
                    name: t('detail'),
                    modalSize: 'large',
                    customIconColor: 'var(--primary)',
                    modal: (item) => <VehicleDetailView vehicle={item} />,
                    permissions: ['vehicles.c_view_all_vehicles', 'vehicles.c_view_assigned_vehicles'],
                },
                {
                    as: 'modal',
                    type: 'edit',
                    name: t('edit'),
                    icon: 'pencil-outline',
                    permissions: ['vehicles.c_manage_all_vehicles'],
                    modal: <VehicleForm />,
                },
                {
                    name: t('delete'),
                    type: 'delete',
                    as: 'delete',
                    text: t('are_you_sure_that_you_want_to_delete_this_vehicle'),
                    permissions: ['vehicles.c_delete_all_vehicles'],
                },
            ]}
            renderCells={(item, setData) => [
                {
                    content: (
                        <>
                            <strong>{item.title}</strong>
                            <br />
                            <span>{item.type?.title || ''}</span>
                        </>
                    ),
                },
                {
                    content: (
                        <>
                            {item.owner ? (
                                // <Link to={routes.UNIT_DETAIL + item.owner?.id} target="_blank">
                                <>
                                    {item.owner?.name} <br /> {item.owner?.identification_number}
                                </>
                            ) : (
                                // </Link>
                                '--'
                            )}
                        </>
                    ),
                },
                { content: item.fuel_type_display },
                { content: item.car_plate_number },
                {
                    content: (
                        <>
                            {item.vignettes.length === 0 && t('no_valid_vignette')}
                            {item.vignettes.map((vignette) => (
                                <>
                                    <span>
                                        {vignette.country}: {moment(vignette.date_from).format(dateFormat)} -{' '}
                                        {moment(vignette.date_to).format(dateFormat)}
                                    </span>
                                    <br />
                                </>
                            ))}
                        </>
                    ),
                },
                {
                    content: (
                        <>
                            {item.inspections.length === 0 && t('no_inspections')}
                            {item.inspections
                                .sort((a, b) => (a.valid_to < b.valid_to ? 1 : -1))
                                .map((inspection) => (
                                    <>
                                        <span>
                                            {inspection.type_display} -{' '}
                                            <span style={{ textTransform: 'lowercase' }}>{t('ends')}</span>:{' '}
                                            {inspection.valid_to
                                                ? moment(inspection.valid_to).format(dateFormat)
                                                : '--'}
                                        </span>
                                        <br />
                                    </>
                                ))}
                        </>
                    ),
                },
                {
                    content: (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {item?.allowed_users?.map((responsiblePerson, index) => (
                                <Popup
                                    key={index}
                                    style={{ cursor: 'pointer' }}
                                    position="top center"
                                    trigger={
                                        <div style={{ cursor: 'pointer' }}>
                                            <UserAvatar
                                                key={index}
                                                style={{ paddingLeft: '0rem', fontSize: 30 / 2.5 + 'px' }}
                                                size={30}
                                                name={responsiblePerson?.employee?.fullname || '--'}
                                                color="var(--variant5)"
                                                src={responsiblePerson?.employee?.profile_picture || ''}
                                            />
                                        </div>
                                    }
                                    content={responsiblePerson?.employee?.fullname || '--'}
                                />
                            ))}
                        </div>
                    ),
                },
                {
                    content: (
                        <Action
                            as="modal"
                            modalSize="tiny"
                            tooltip={t('documents')}
                            open={item.id === forceModalOpen}
                            onClose={() => setForceModalOpen(0)}
                            trigger={
                                <span
                                    style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                                    onClick={() => setForceModalOpen(item.id)}
                                >
                                    <Icon name="document-outline" />
                                    <sup style={{ fontWeight: 'bold', marginLeft: '0.5rem' }}>
                                        {item.attachments.length}
                                    </sup>
                                </span>
                            }
                            content={
                                <VehicleDocuments
                                    vehicleID={item.id}
                                    attachments={item.attachments}
                                    setData={setData}
                                />
                            }
                        />
                    ),
                },
            ]}
        />
    )
}

export default VehiclesList
