import React, { useEffect, useState } from 'react';
// store 
import { getRequest } from '@services/ServiceCommon';
import { makeQuery } from '@helpers/functions';
// components
import { Form, Dropdown, Input } from 'semantic-ui-react';

const SuperCheckboxField = ({ customOptions, endpoint, label, text, value, onChange, ...rest }) => {

    const [allOptions, setAllOptions] = useState(customOptions ?? [])
    const [filteredOptions, setFilteredOptions] = useState(customOptions ?? [])
    const [selected, setSelected] = useState(value ?? [])
    const [query, setQuery] = useState("")
    const [open, setOpen] = useState(false)
    const [stayOpen, setStayOpen] = useState(false)
    
    useEffect(() => {
        async function fetchOptions(){
            if(endpoint){
                const request = await getRequest(endpoint)
    
                if( request.status === 200 ){
                    const tmpOptions = request.response.map( option => {
                        return {
                            key: option.id, text: option?.[text], value: option.id
                        }
                    })
                    setAllOptions(tmpOptions)
                    setFilteredOptions(tmpOptions)
                }
            }
        }

        fetchOptions()
    }, [endpoint, text])

    useEffect(() => {
        onChange(selected)
        // eslint-disable-next-line
    }, [selected])

    const handleSearch = (input) => {
        setQuery(input)
        const foundedOptions = allOptions.filter(makeQuery('text', new RegExp(input+'.+$', 'i')));
        setFilteredOptions(foundedOptions)
    }

    const handleChecked = (currentSelected, isSelected) => {
        if( isSelected ){
            setSelected(prevState => prevState.filter( item => item !== currentSelected ))
        } else {
            setSelected(prevState => [...prevState, currentSelected])
        }
    }

    const CheckboxOption = ({ item, setStayOpen }) => {
        const isSelected = selected.includes(item.value)
        // const [isSelected, setIsSelected] = useState(selected.includes(item.value))

        return (
            <Dropdown.Item selected={isSelected} onClick={ (event) => {
                setStayOpen(true)
                // setIsSelected(true)
                handleChecked(item.value, isSelected)
            } }>
                <Form.Checkbox 
                    checked={isSelected}
                    label={ item.text }
                    style={{ width: "100%" }}
                />    
            </Dropdown.Item>
        )
    }
    

    return (
        <Form.Field>
            <label>{ label }</label>
            <Dropdown
                simple item
                {...rest}
                text={`${selected.length} items selected`}
                labeled
                selection
                focus={ stayOpen ? "false" : "true" }
                closeOnBlur={true}
                onClick={ () => setOpen(true) }
                onBlur={ () => setOpen(true) }
                onClose={ () => setOpen(false)}
                onHover={ () => {
                    setOpen(true)
                    setStayOpen(true)
                }}
                onFocus={ () => setOpen(true) }
            >
                <Dropdown.Menu onClick={() => setStayOpen(true)} scrolling open={open || stayOpen} style={{ background: "var(--light)", maxHeight: "20rem", minHeight: "20rem", overflowY: "auto", zIndex: "2147483647"}}>
                    <Input 
                        onClick={ () => setStayOpen(true) }
                        onFocus={ () => setStayOpen(true) }
                        onBlur={ () => setStayOpen(true)}
                        onChange={ (e, { value }) => handleSearch(value) }
                        value={ query }
                        icon='search'
                        iconPosition='left'
                        className='search'
                        style={{ width: "auto" }}
                    />
                    { filteredOptions?.map( item => (
                        <CheckboxOption 
                            setStayOpen={setStayOpen} 
                            key={item.key} 
                            item={item}
                        />
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </Form.Field>
    );
};

export default SuperCheckboxField;