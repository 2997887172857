import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { addWarehousingConfig } from '@store/actions'
// comonents
import Icon from '@components/Icon'
import { Card, Popup, Button } from 'semantic-ui-react'
import SpinnerSegment from '@components/SpinnerSegment'

const StatCard = ({ color, isCustomColor, number, description, icon, onClick }) => {
    return (
        <Card
            style={
                isCustomColor
                    ? {
                          borderRadius: 0,
                          boxShadow: `0 0 0 1px #d4d4d5,0 2px 0 0 ${color},0 1px 3px 0 #d4d4d5`,
                      }
                    : { borderRadius: 0 }
            }
            color={isCustomColor ? undefined : color}
            onClick={onClick}
        >
            <Card.Content>
                <div style={{ display: 'flex', marginBottom: '2rem' }}>
                    <div
                        style={{
                            textAlign: 'left',
                            fontWeight: 'bold',
                            width: '70%',
                            fontSize: '1.7rem',
                            paddingTop: '0.25rem',
                            color: 'var(--dark)',
                        }}
                    >
                        {number}
                    </div>
                    <div style={{ textAlign: 'right', width: '30%' }}>
                        {icon && (
                            <Icon
                                name={icon}
                                style={{ fontSize: '2rem', fontWeight: 'bold', textAlign: 'left', color: color }}
                            />
                        )}
                    </div>
                </div>

                <div
                    style={{
                        position: 'absolute',
                        bottom: '1rem',
                        marginTop: '1rem',
                        textTransform: 'uppercase',
                        color: 'var(--dark)',
                        opacity: '0.8',
                    }}
                >
                    {description}
                </div>
            </Card.Content>
        </Card>
    )
}

const StockListStatistics = ({ data, params, setFilters, fetchData }) => {
    // eslint-disable-next-line
    const { t } = useTranslation()

    const warehousingConfig = useSelector((store) => store.warehousing_config)
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [stats, setStats] = useState([])
    const [viewMode, setViewMode] = useState(warehousingConfig?.view_mode || 'items')

    useEffect(() => {
        async function fetchStats() {
            setLoading(true)
            const request = await requests.get(
                API.REPORTS + `assets/stored_item_positions/stats/?query={*}&view_mode=${viewMode}` + params
            )
            if (request.status === 200) setStats(request.response)
            setLoading(false)
        }

        fetchStats()
        // eslint-disable-next-line
    }, [data, viewMode])

    const renderOnClick = async (item) => {
        if (viewMode === 'categories') {
            setFilters((prev) => ({ ...prev, catalogue_item: '', category: item }))
            await fetchData('&category=' + item, true)
        } else {
            setFilters((prev) => ({ ...prev, catalogue_item: item, category: '' }))
            await fetchData('&catalogue_item=' + item, true)
        }
    }

    return (
        <div style={{ marginTop: '1rem' }}>
            <SpinnerSegment loading={loading}>
                <Button.Group size="small" basic>
                    <Button
                        active={viewMode === 'items'}
                        onClick={() => {
                            setViewMode('items')
                            dispatch(addWarehousingConfig({ view_mode: 'items' }))
                        }}
                    >
                        {t('items')}
                    </Button>
                    <Button
                        active={viewMode === 'categories'}
                        onClick={() => {
                            setViewMode('categories')
                            dispatch(addWarehousingConfig({ view_mode: 'categories' }))
                        }}
                    >
                        {t('categories')}
                    </Button>
                </Button.Group>

                {stats.length === 0 && (
                    <p style={{ fontSize: '1.2rem', marginTop: '0.5rem', fontWeight: 'bold' }}>{t('no_data')}</p>
                )}

                <Card.Group stackable doubling itemsPerRow={4} style={{ padding: '0.25rem', marginTop: '0.25rem' }}>
                    {stats.map((item) => (
                        <StatCard
                            isCustomColor
                            color={item.color || 'black'}
                            number={
                                <div>
                                    {(parseFloat(item.current_stock) > 0
                                        ? parseFloat(item.current_stock).toFixed(3).replace('.000', '')
                                        : '0') +
                                        ' ' +
                                        (item?.measure_unit_abbreviation || '')}
                                    <div style={{ marginTop: '0.5rem', fontSize: '1.2rem', color: 'var(--success)' }}>
                                        {item.expected_stock > 0 ? (
                                            <Popup
                                                position="right center"
                                                trigger={
                                                    <span>
                                                        +{' '}
                                                        {parseFloat(item.expected_stock) > 0
                                                            ? parseFloat(item.expected_stock)
                                                                  .toFixed(3)
                                                                  .replace('.000', '')
                                                            : '0'}
                                                    </span>
                                                }
                                                content={t('expected_stock')}
                                            />
                                        ) : (
                                            ' '
                                        )}
                                    </div>
                                </div>
                            }
                            icon={'cube-outline'}
                            description={(item.code ? item.code + ' • ' : '') + item.title}
                            onClick={() => renderOnClick(item.id)}
                        />
                    ))}
                </Card.Group>
            </SpinnerSegment>
        </div>
    )
}

export default StockListStatistics
