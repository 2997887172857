import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fileDownload } from '@helpers/requests';
// store
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
// components
import { Segment, Grid, Divider } from 'semantic-ui-react';
import DocPreview from '@components/DocPreview';

const InvoicePreviewPDF = ({ id, approvalActions, onClose }) => {
    const { t } = useTranslation()

    const [generating, setGenerating] = useState(false)
    const [isError, setIsError] = useState(false)
    const [canView, setCanView] = useState(true)
    const [doc, setDoc] = useState(null)

    const showInvoice = async (id, connection_id) => {
        const xhr = await fileDownload("GET", API.API_URL + `/integrations/invoices/${id}/pdf/?connection=${connection_id}`, "application/pdf", "invoice.pdf")

        xhr.onreadystatechange = function() {
            if( xhr.status !== 200 ) setIsError(true)
            if( xhr.status === 401 ) setCanView(false)
            setGenerating(false)
        };
    }

    const fetchInvoice = async (id, connection_id) => {
        return await getRequest(API.INVOICES + `${id}/?connection=${connection_id}`) 
    }

    useEffect( () => {
        async function loadInvoicePDFPreview(){
            setGenerating(true)
            const request = await getRequest(API.INVOICES + "invoice_connections/")

            if(request.status === 200){
                let connection = request?.response?.[0] 
                const invoiceRequest = await fetchInvoice(id, connection?.connection_id)

                if( invoiceRequest.status === 200 ){
                    if (invoiceRequest.response.scan !== "" && invoiceRequest.response.scan !== null ){
                        setDoc(invoiceRequest.response.scan)
                        // generate Scan
                        // const link = document.createElement('a');
                        // link.href = invoiceRequest.response.scan;
                        // link.setAttribute('target', '_blank')

                        // // Append to html link element page
                        // document.body.appendChild(link);

                        // // Start download
                        // link.click();

                        // // Clean up and remove the link
                        // link.parentNode.removeChild(link);
                        setGenerating(false)
                    } else {
                        // generate pdf view
                        await showInvoice(id, connection?.connection_id)
                        setGenerating(false)
                    }
                } else {
                    if( invoiceRequest.status !== 200 ) setIsError(true)
                    if( invoiceRequest.status !== 401 ) setCanView(false)
                    setGenerating(false)
                }

                
            }
        }

        loadInvoicePDFPreview()
        // eslint-disable-next-line
    }, [])

    return (
        <Segment loading={generating} style={{ minHeight: !generating ? "auto" : "10rem", fontSize: "1.3rem", fontWeight: "bold"}}>
            { generating && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "6rem" }}> { t('message_performing_verification') } </p> }
            { !generating && 
                <>
                    <Grid centered>
                        <Grid.Row>
                            <Grid.Column textAlign="center">
                                { isError 
                                    ?  canView ? t('message_processing_error_can_generate_invoice_preview')
                                    : t('invoice_permission_can_not_view') + "!"
                                    : <>{ doc !== null ? <DocPreview uri={doc} additionalActions={approvalActions}/> : t('invoice_detail_generated_on_new_tab') }</>
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Divider/>
                </>
            }
        </Segment>
    );
};

export default InvoicePreviewPDF;