import React from 'react'
// components
import FlexRowWithToggle from '@components/FlexRowWithToggle'

const FlexTable = ({ children, stripped, hoverable, responsive }) => {
    return (
        <>
            {responsive === false ? (
                <div
                    className={
                        'flex-table' +
                        (stripped || stripped === undefined ? ' stripped' : '') +
                        (hoverable || hoverable === undefined ? ' hoverable' : '') +
                        (responsive || responsive === undefined ? ' responsive' : '')
                    }
                >
                    {children}
                </div>
            ) : (
                <div style={{ padding: 0, overflow: 'auto', width: '100%' }}>
                    <div
                        className={
                            'flex-table' +
                            (stripped || stripped === undefined ? ' stripped' : '') +
                            (hoverable || hoverable === undefined ? ' hoverable' : '') +
                            (responsive || responsive === undefined ? ' responsive' : '')
                        }
                    >
                        {children}
                    </div>
                </div>
            )}
        </>
    )
}

const FlexRow = ({
    children,
    padding,
    bold,
    fontSize,
    background,
    color,
    borders,
    pointer,
    customClassName,
    isToggle,
    content,
    ...rest
}) => {
    return (
        <>
            {isToggle ? (
                <FlexRowWithToggle
                    rowContent={children}
                    content={content}
                    marginBottom="0"
                    marginTop="0"
                    borderless={true}
                    background={background}
                    fontSize={fontSize}
                    {...rest}
                />
            ) : (
                <div
                    {...rest}
                    className={'flex-row ' + customClassName}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        width: '100%',
                        padding: padding ? padding : '1.2rem',
                        fontWeight: bold ? 'bold' : 'normal',
                        fontSize: fontSize ? fontSize : '1.2rem',
                        color: color ? color : 'var(--dark)',
                        background: background ? background : 'transparent',
                        // borderBottom: borders ? "1px solid var(--light)" : "none",
                        // borderTop: borders ? "1px solid var(--light)" : "none",
                        cursor: pointer ? 'pointer' : 'normal',
                    }}
                >
                    {children}
                </div>
            )}
        </>
    )
}

const FlexHeader = ({ content, basis, bold, children, textAlign }) => {
    return (
        <span
            className="flex-header"
            style={{
                flexBasis: basis ? basis : '100%',
                fontWeight: bold || bold === undefined ? 'bold' : 'normal',
                textAlign: textAlign ? textAlign : 'left',
            }}
        >
            {content}
            {children}
        </span>
    )
}

const FlexItem = ({ content, basis, bold, children, textAlign, alignSelf, ...rest }) => {
    return (
        <span
            {...rest}
            className="flex-item"
            style={{
                alignSelf: alignSelf || 'inherit',
                flexBasis: basis ? basis : '100%',
                fontWeight: bold ? 'bold' : 'normal',
                textAlign: textAlign ? textAlign : 'left',
            }}
        >
            {content}
            {children}
        </span>
    )
}

export { FlexTable, FlexRow, FlexHeader, FlexItem }
