import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
// store
import { routes } from '@routes';
import { addJournal, addInvoicePairingStatus } from '@store/actions';
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Container, Grid, Button, Divider, Dropdown, Popup } from 'semantic-ui-react';
// module specific components
import ImportInvoices from './ImportInvoices';
import JournalForm from './forms/JournalForm';
import ManageAllowedUsers from './ManageAllowedUsers';
import AccountingJournalForm from './forms/AccountingJournalForm';

const NavigationPanel = ({ connection, user, setUser, setRefetch, premiumConnection } ) => {
    const { t } = useTranslation()
    const location = useLocation()
    const dispatch = useDispatch()
    const history = useHistory()
    const pairing_status = useSelector(state => state.pairing_status)
    const [pairInvoices, setPairInvoices] = useState(pairing_status)

    const switchJournal = (journal) => {
        dispatch(addJournal(journal))
        history.push(routes.INVOICES)
        setRefetch(true)
    }
    
    const setInvoicePairing = () => {
        setPairInvoices(!pairInvoices)
        dispatch(addInvoicePairingStatus(!pairInvoices))
        setRefetch(true)
    }

    return (
        <Container fluid>
            <Grid stackable>
                <Grid.Row verticalAlign="middle" style={{ padding: 0 }} columns={2}>
                    <Grid.Column computer={8} style={{ textAlign: "left" }}>
                        <Button.Group basic size="small" style={{ borderRadius: '0' }} className='custom-filter-button-group'>
                            <Button active={routes.INVOICES === location.pathname} as={Link} to={routes.INVOICES}>
                                <Icon name="bar-chart-outline" style={{ marginRight: "0.5rem" }}/>
                                <span style={{ position: "relative", top: "-0.1rem" }}>
                                    { t('overview') } 
                                </span>
                            </Button>
                            <Button active={routes.INVOICES + "incomes/" === location.pathname} as={Link} to={routes.INVOICES + "incomes/"}>
                                <Icon name="document-text-outline" style={{ marginRight: "0.5rem" }}/> 
                                <span style={{ position: "relative", top: "-0.1rem" }}>
                                    { t('send_invoices') } 
                                </span>
                            </Button>
                            <Button active={routes.INVOICES + "expenses/" === location.pathname} as={Link} to={routes.INVOICES + "expenses/"}>
                                <Icon name="document-text-outline" style={{ marginRight: "0.5rem" }}/> 
                                <span style={{ position: "relative", top: "-0.1rem" }}>
                                    { t('recieved_invoices') }
                                </span>
                            </Button>
                        </Button.Group>
                    </Grid.Column>
                    <Grid.Column computer={2}/>
                    <Grid.Column style={{ textAlign: "right" }} computer={6}>
                        <Button.Group size="small" style={{ borderRadius: '0' }}>
                            <Button.Group>
                                <Button style={{ background: "var(--dark)", color: "var(--white)", cursor: "default"}}>
                                    <Popup 
                                        position='left center'
                                        trigger={
                                            <div>
                                                <Icon name={ connection.is_active && user.journal !== null ? "ellipse" : "alert-circle" } style={{ marginRight: "0.5rem", color: connection.is_active && user.journal !== null ? "var(--success)" : "var(--danger)" }}/> 
                                                <span style={{ position: "relative", top: "-0.2rem" }}>
                                                    { user.journal !== null ? user.journal.name : "Journal unavailable!" }
                                                </span>
                                            </div>
                                        }
                                        content={ connection.is_active ? t('connected') : t('connection_error') }
                                    />
                                </Button>
                                <Dropdown
                                    simple
                                    style={{ background: "var(--dark-hover)", color: "var(--white)", marginRight: "0.5rem" }}
                                    className='button icon'
                                    floating
                                    trigger={<Icon name="settings-outline" style={{ marginRight: "0.5rem" }}/>}
                                >
                                    <Dropdown.Menu className="invoices-navpanel" style={{ zIndex: 999 }}>
                                        <CanView permissions={['integrations.c_view_all_invoice_allowed_users']}>
                                            <SuperDuperModal 
                                                size="large"
                                                trigger={
                                                    <Dropdown.Item>
                                                        <Icon name="people-outline" style={{ marginRight: "0.5rem" }}/> 
                                                        <span style={{ position: "relative", top: "-0.2rem" }}>
                                                            { t('permissions_management_users') + " - " + (user.journal !== null && user.journal.name) }
                                                        </span>
                                                    </Dropdown.Item>
                                                }
                                                content={
                                                    <ManageAllowedUsers connection={connection} user={user} setUser={setUser}/>
                                                }
                                            />
                                        </CanView>
                                        <SuperDuperModal 
                                            size="small"
                                            trigger={
                                                <Dropdown.Item disabled={!user.canManage}>
                                                    <Icon name="cloud-upload-outline" style={{ marginRight: "0.5rem" }}/> 
                                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                                        { t('import_invoices') }
                                                    </span>
                                                </Dropdown.Item>
                                            }
                                            content={
                                                <ImportInvoices connection={connection} user={user} setRefetch={setRefetch}/>
                                            }
                                        />
                                        <Dropdown 
                                            disabled={connection.accounts.length < 2}
                                            as={Dropdown.Item}
                                            direction="left"
                                            pointing="left center"
                                            icon={null}
                                            text={
                                                <>
                                                    <Icon name="swap-vertical-outline" style={{ marginRight: "0.5rem" }}/> 
                                                    <span style={{ position: "relative", top: "-0.1rem" }}>
                                                        { t('switch_journal') }
                                                    </span>   
                                                </>
                                            }
                                            className='link item'
                                        >
                                            <Dropdown.Menu>
                                                { connection.accounts.map(journal => (
                                                    <>
                                                        { journal.allowed_users.find(item => item.user.username === user.username)?.can_view === true &&
                                                            <Dropdown.Item 
                                                                disabled={journal.id === user.journal.id}
                                                                onClick={() => switchJournal(journal)}
                                                            >
                                                                <Icon name={ "ellipse" } style={{ marginRight: "0.5rem", color: journal.id === user.journal.id ? "var(--success)" : "var(--dark)" }}/> 
                                                                <span style={{ position: "relative", top: "-0.1rem" }}>
                                                                    {journal.name} - {journal.id_number}
                                                                </span>
                                                            </Dropdown.Item>
                                                        }
                                                    </>
                                                )) }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <CanView permissions={['integrations.c_manage_all_invoice_accounts']}>
                                            <Dropdown 
                                                as={Dropdown.Item}
                                                direction="left"
                                                pointing="left center"
                                                icon={null}
                                                text={
                                                    <>
                                                        <Icon name="settings-outline" style={{ marginRight: "0.5rem" }}/> 
                                                        <span style={{ position: "relative", top: "-0.1rem" }}>
                                                            { t('manage_accounting_journal') }
                                                        </span>   
                                                    </>
                                                }
                                                className='link item'
                                            >
                                                <Dropdown.Menu>
                                                    { connection.accounts.map(journal => (
                                                        <>
                                                            { journal.allowed_users.find(item => item.user.username === user.username)?.can_view === true &&
                                                                <SuperDuperModal 
                                                                    size="tiny"
                                                                    centered={false}
                                                                    header={t('update_accounting_journal')}
                                                                    trigger={
                                                                        <Dropdown.Item>
                                                                            {journal.name} - {journal.id_number}
                                                                        </Dropdown.Item>
                                                                    }
                                                                    content={
                                                                        <JournalForm journal={journal} connection={connection} user={user} setRefetch={setRefetch}/>
                                                                    }
                                                                />
                                                                
                                                            }
                                                        </>
                                                    )) }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </CanView>
                                        <CanView permissions={['integrations.c_manage_all_invoice_accounts']}>
                                            <SuperDuperModal 
                                                size="tiny"
                                                header={t('add_another_journal')}
                                                trigger={
                                                    <Dropdown.Item disabled={user.role !== 1}>
                                                        <Icon name="add-outline" style={{ marginRight: "0.5rem" }}/> 
                                                        <span style={{ position: "relative", top: "-0.2rem" }}>
                                                            { t('add_another_journal') }
                                                        </span>
                                                    </Dropdown.Item>
                                                }
                                                content={
                                                    <AccountingJournalForm connection={connection} user={user} setRefetch={setRefetch}/>
                                                }
                                            />
                                        </CanView>
                                        <Dropdown.Divider/>
                                        <Popup
                                            position='left center'
                                            content={pairInvoices ? t('pause_pairing') : t('start_pairing')}
                                            trigger={
                                                <Dropdown.Item onClick={() => setInvoicePairing()}>
                                                    <Icon name={pairInvoices ? "pause-circle-outline" : "play-circle-outline" } style={{ marginRight: "0.5rem" }}/> 
                                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                                        { t('invoice_pairing') }
                                                    </span>
                                                </Dropdown.Item>
                                            }
                                        />
                                        <CanView permissions={['integrations.c_view_all_bank_account_allowed_users']}>
                                            <Popup
                                                position='left center'
                                                content={t('finance_management_hint') + "."}
                                                trigger={
                                                    <Dropdown.Item as={Link} to={routes.SETTINGS + "finances"}>
                                                        <Icon name="settings-outline" style={{ marginRight: "0.5rem" }}/> 
                                                        <span style={{ position: "relative", top: "-0.2rem" }}>
                                                            { t('finance_management') }
                                                        </span>
                                                    </Dropdown.Item>
                                                }
                                            />
                                        </CanView>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Button.Group>
                        </Button.Group>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider/>
        </Container>
    );
};

export default NavigationPanel;