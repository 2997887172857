import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import EmptyRow from '@components/tables/EmptyRow'
import SuperField from '@components/forms/SuperField'
import SortedFileRenderer from '@components/SortedFileRenderer'
import { Form, Button, Divider, Header, Icon as SemanticIcon } from 'semantic-ui-react'
import AttachmentGroups from './documents/AttachmentGroups'
import Attachment from './documents/Attachment'
import Loading from '@components/general/Loading'

const Attachments = ({
    attribute,
    viewOnly,
    allowFolders,
    moduleName,
    record,
    setData,
    sourceEndpoint,
    additionalData,
    closeView,
    customDataHandler,
}) => {
    const { t } = useTranslation()
    const [files, setFiles] = useState([])
    const [isProcessing, setIsProcessing] = useState(false)
    const [groups, setGroups] = useState([])
    const [group, setGroup] = useState(null)
    const [groupOptions, setGroupOptions] = useState([])
    const [docsWithoutGroup, setDocsWithoutGroup] = useState([])
    const [docsWithGroup, setDocsWithGroup] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    attribute = attribute || 'attachments'

    async function fetchData() {
        setDocsWithoutGroup(record[attribute].filter((doc) => doc.group === null || !doc.group?.name))
        setDocsWithGroup(record[attribute].filter((doc) => doc.group !== null))
        setIsLoading(false)
    }

    async function fetchGroups() {
        const request = await requests.get(API.ATTACHMENT_GROUPS + '?module=' + moduleName)

        if (request.status === 200) {
            setGroups(request.response)
            let output = []
            for (let item of request.response) {
                if (item) {
                    output.push({ key: item.id, value: item.id, text: item.name })
                }
            }
            setGroupOptions(output)
        }
    }

    useEffect(() => {
        fetchGroups()
        fetchData()
        // eslint-disable-next-line
    }, [record])

    const onSubmit = async () => {
        setIsProcessing(true)
        const formData = new FormData()
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i])
            if (group) formData.append('group', group)
        }

        const request = await requests.post(API.ATTACHMENTS_BULK, formData)

        if (request.status === 201) {
            setFiles([])
            setGroup(null)
            document.getElementById('fileInput').value = null

            let data = {
                [attribute]: { add: request.response.map((item) => item.id) },
            }

            if (additionalData !== undefined) {
                data = {
                    ...data,
                    ...additionalData,
                }
            }

            const update = await requests.patch(sourceEndpoint + record.id + '/', data)
            if (update.status === 200) {
                if (customDataHandler) {
                    customDataHandler(update.response)
                } else {
                    setData((prev) =>
                        prev.filter((item) => {
                            if (item.id === record.id) {
                                item[attribute] = update.response[attribute]
                            }
                            return item
                        })
                    )
                }
                fetchData()

                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('attachments_added'),
                    animation: 'pulse',
                    time: 2000,
                })
            }
        } else {
            toast({
                type: 'error',
                icon: 'warning',
                title: request.response?.non_field_errors?.[0] || t('service_unavailable'),
                animation: 'pulse',
                time: 5000,
            })
        }
        setIsProcessing(false)
    }

    const onDelete = async (id) => {
        const request = await requests.del(API.ATTACHMENTS + id + '/')

        if (request.status === 204) {
            if (customDataHandler) {
                customDataHandler(null, id)
            } else {
                setData((prev) =>
                    prev.filter((item) => {
                        if (item.id === record.id) {
                            item[attribute] = record[attribute].filter((attachment) => attachment.id !== id)
                        }

                        return item
                    })
                )
            }
            fetchData()

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('attachment_removed'),
                animation: 'pulse',
                time: 2000,
            })
        }
    }

    const onDeleteGroup = async (id) => {
        const request = await requests.post(API.ATTACHMENT_GROUPS + id + '/delete/')

        if (request.status === 200) {
            const newList = groups.filter((item) => item.id !== id)
            setGroups(newList)
        }
    }

    const handleAddGroup = async (item) => {
        const request = await requests.post(API.ATTACHMENT_GROUPS, {
            name: item,
            module: moduleName,
        })

        if (request.status === 201) {
            setGroupOptions([
                ...groupOptions,
                { key: request.response.id, value: request.response.id, text: request.response.name },
            ])
            setGroups((prev) => [request.response, ...prev])
            setGroup(request.response.id)
        }
    }

    const hiddenFrontFileInput = React.useRef(null)
    const handleFrontClick = (event) => {
        hiddenFrontFileInput.current.click()
    }

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <div style={{ minWidth: '400px' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'middle',
                            marginBottom: '0.5rem',
                        }}
                    >
                        <span style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                            {t('documents')} ({record[attribute].length})
                        </span>
                        <span>
                            {closeView !== undefined && (
                                <Icon
                                    name="close"
                                    style={{ color: 'var(--danger)', cursor: 'pointer' }}
                                    onClick={() => closeView()}
                                />
                            )}
                        </span>
                    </div>
                    <Divider style={{ marginTop: 0 }} />
                    {!viewOnly && (
                        <>
                            <Form onSubmit={onSubmit}>
                                {allowFolders && (
                                    <SuperField
                                        as="choice"
                                        allowAdditions
                                        help={t('help_group_create')}
                                        search
                                        onAddItem={(event, data) => handleAddGroup(data.value)}
                                        customOptions={groupOptions}
                                        label={t('att_group')}
                                        value={group}
                                        onChange={(e, { value }) => setGroup(value)}
                                    />
                                )}
                                <Form.Group widths={'equal'}>
                                    <div style={{ paddingLeft: '0.5rem' }}>
                                        <input
                                            type="file"
                                            multiple
                                            id="fileInput"
                                            ref={hiddenFrontFileInput}
                                            onChange={(event) => {
                                                if (event.target?.files?.length > 0) {
                                                    setFiles(event.target.files)
                                                }
                                            }}
                                            style={{ display: 'none' }}
                                        />
                                        <div
                                            style={{
                                                padding: '0.7rem',
                                                cursor: 'pointer',
                                                border: '1px solid var(--light-grey)',
                                            }}
                                            onClick={handleFrontClick}
                                        >
                                            <Header as="h4" style={{ marginTop: 0, fontSize: '15px' }}>
                                                <SemanticIcon
                                                    name="upload"
                                                    size="tiny"
                                                    style={{ color: 'var(--primary)', fontSize: '15px' }}
                                                />
                                                {t('upload_files')}
                                            </Header>
                                        </div>
                                    </div>
                                    <div style={{ padding: '0.8rem' }}>{files.length + ' ' + t('files_selected')}</div>
                                    <Form.Field width="6">
                                        <Button
                                            size="tiny"
                                            primary
                                            fluid
                                            style={{ height: '3rem' }}
                                            loading={isProcessing}
                                            disabled={isProcessing || files.length === 0}
                                            content={t('confirm')}
                                        />
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                            <Divider />
                        </>
                    )}
                    {!allowFolders && <EmptyRow length={record[attribute].length} />}
                    {!allowFolders &&
                        record[attribute].map((attachment) => (
                            <div
                                key={attachment.id}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'middle',
                                    justifyContent: 'space-between',
                                    marginTop: '1rem',
                                }}
                            >
                                <SortedFileRenderer
                                    document={attachment}
                                    name={attachment.name}
                                    file={attachment.file}
                                />
                                {!viewOnly && (
                                    <Icon
                                        name="close"
                                        style={{
                                            marginLeft: '1rem',
                                            fontSize: '1.2rem',
                                            color: 'var(--danger)',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => onDelete(attachment.id)}
                                    />
                                )}
                            </div>
                        ))}

                    {allowFolders &&
                        groups &&
                        groups.map((group) => (
                            <AttachmentGroups
                                key={group.id}
                                group={group}
                                setGroups={setGroups}
                                moduleName={moduleName}
                                docs={docsWithGroup}
                                setDocsWithGroup={setDocsWithGroup}
                                setDocsWithoutGroup={setDocsWithoutGroup}
                                onDelete={(item) => onDelete(item)}
                                fetchData={fetchData}
                                setData={setData}
                                options={groupOptions}
                                setGroupOptions={setGroupOptions}
                                handleAddGroup={(item) => handleAddGroup(item)}
                                onDeleteGroup={(item) => onDeleteGroup(item)}
                            />
                        ))}
                    {allowFolders &&
                        docsWithoutGroup &&
                        docsWithoutGroup.map((doc) => (
                            <Attachment
                                key={doc.id}
                                setDocsWithGroup={setDocsWithGroup}
                                setDocsWithoutGroup={setDocsWithoutGroup}
                                docs={doc}
                                canMove
                                options={groupOptions}
                                setGroupOptions={setGroupOptions}
                                moduleName={moduleName}
                                setGroups={setGroups}
                                handleAddGroup={(item) => handleAddGroup(item)}
                                onDelete={(item) => onDelete(item)}
                            />
                        ))}
                </div>
            )}
        </>
    )
}

export default Attachments
