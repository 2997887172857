import React from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { fileDownload } from '@helpers/requests';
// components
import ListView from '@components/ListView';
import CanView from '@components/perms/CanView';
// module specific components
import WarehouseTransferCardForm from '../components/transfers/WarehouseTransferCardForm';

const AssetTransfers = ({ employees }) => {
    const { t } = useTranslation()

    const generateTransferDocument = async (id) => {
        await fileDownload("GET", API.API_URL + `/exports/warehouse_transfer_card_pdf_view/${id}/?is_blob=true`, "application/pdf", `warehouse_transfer__${id}.pdf`)
    }

    return (
        <CanView permissions={['assets.c_view_items']} redirect>
            <ListView as="table"
                actionsCellWidth={1}
                endpoint={API.ASSETS + "warehouse_transfer_cards/"}
                tableHeaders={[
                    { title: t('transfer_from') },
                    { title: t('transfer_to') },
                    { title: t('total_items') },
                    { title: t('note') },
                    { title: t('responsible_person') },
                    { title: t('created_on') },
                    { title: t('created_by') },
                ]}
                renderCells={(transfer, setData) => ([
                    { content: transfer.warehouse_from?.title || "" },
                    { content: transfer.warehouse_to?.title || "" },
                    { content: transfer?.transfered_items?.length || 0 },
                    { content: transfer?.note || "" },
                    { content: transfer?.responsible_person?.fullname || "" },
                    { content: transfer?.created_on || "" },
                    { content: transfer?.created_by?.fullname_with_titles || "" },
                ])}
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        name: t('create'),
                        modalSize: 'small',
                        permissions: ['assets.c_manage_items'],
                        modal: <WarehouseTransferCardForm/>,
                    },
                    {
                        as: 'custom',
                        type: 'custom',
                        icon: 'eye-outline',
                        customIconColor: "var(--primary)",
                        name: t('generate_document'),
                        permissions: ['assets.c_view_items'],
                        onClick: (item) => generateTransferDocument(item.id)
                    },
                ]}
            />
        </CanView>
    );
};

export default AssetTransfers;