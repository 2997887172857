import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { requests } from '@helpers/requests'
import { API } from '@store/config'
// components
import { Divider, Form } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const StatusBulk = ({ selected, setSelected, filters, setData, onClose }) => {
    const { t } = useTranslation()

    const [isProcessing, setIsProcessing] = useState(false)
    const [status, setStatus] = useState(filters?.process_status || 1)
    const [form, setForm] = useState({
        suspension_of_proceedings_from: '',
        suspension_of_proceedings_to: '',
        suspension_of_proceedings_reason: '',
        granted_at: '',
        denied_at: '',
        denied_reason: '',
    })

    const statuses = [
        { key: 1, value: 1, text: t('open') },
        { key: 2, value: 2, text: t('submitted') },
        { key: 3, value: 3, text: t('granted') },
        { key: 4, value: 4, text: t('suspended') },
        { key: 5, value: 5, text: t('denied') },
    ]

    const onSubmit = async () => {
        setIsProcessing(true)
        let updated = []

        for (let i = 0; i < selected.length; i++) {
            let additionalData = {}

            if (status === 2) {
                if (form.date_of_submission !== '') {
                    additionalData.date_of_submission = form.date_of_submission
                }
            }

            if (status === 3) {
                additionalData.granted_at = form.granted_at ? form.granted_at : null
            }

            if (status === 5) {
                additionalData.denied_at = form.denied_at ? form.denied_at : null
                additionalData.denied_reason = form.denied_reason
            }

            if (status === 4) {
                additionalData.suspension_of_proceedings_from = form.suspension_of_proceedings_from
                    ? form.suspension_of_proceedings_from
                    : null
                additionalData.suspension_of_proceedings_to = form.suspension_of_proceedings_to
                    ? form.suspension_of_proceedings_to
                    : null
                additionalData.suspension_of_proceedings_reason = form.suspension_of_proceedings_reason
            }

            const request = await requests.patch(API.FOREIGNERS + 'processes/' + selected[i].id + '/', {
                process_status: status,
                ...additionalData,
            })
            if (request.status === 200) {
                updated.push(request.response)
            }
        }

        setData((prev) =>
            prev.map((item) => {
                const itemToUpdate = updated.find((updatedItem) => updatedItem.id === item.id)
                if (item.id === itemToUpdate?.id) {
                    item = itemToUpdate
                }
                return item
            })
        )

        onClose()
        setSelected([])
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={onSubmit}>
            <SuperField
                as="choice"
                required
                search
                value={status}
                clearable={false}
                customOptions={statuses}
                label={t('process_status')}
                onChange={(e, { value }) => setStatus(value)}
            />
            <Divider />

            {parseInt(status) === 4 && (
                <>
                    <Form.Group widths="equal">
                        <SuperField
                            as="datepicker"
                            label={t('date_from')}
                            value={form.suspension_of_proceedings_from}
                            onChange={(e, { value }) => {
                                setForm((prev) => ({ ...prev, suspension_of_proceedings_from: value }))
                            }}
                        />
                        <SuperField
                            as="datepicker"
                            label={t('date_to')}
                            value={form.suspension_of_proceedings_to}
                            onChange={(e, { value }) => {
                                setForm((prev) => ({ ...prev, suspension_of_proceedings_to: value }))
                            }}
                        />
                    </Form.Group>
                    <SuperField
                        as="input"
                        label={t('reason')}
                        value={form.suspension_of_proceedings_reason}
                        onChange={(e, { value }) =>
                            setForm((prev) => ({ ...prev, suspension_of_proceedings_reason: value }))
                        }
                    />
                </>
            )}

            {parseInt(status) === 5 && (
                <>
                    <Form.Group widths="equal">
                        <SuperField
                            as="datepicker"
                            label={t('denied_date')}
                            value={form.denied_at}
                            onChange={(e, { value }) => {
                                setForm((prev) => ({ ...prev, denied_at: value }))
                            }}
                        />
                        <SuperField
                            as="input"
                            label={t('reason')}
                            value={form.denied_reason}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, denied_reason: value }))}
                        />
                    </Form.Group>
                </>
            )}

            {parseInt(status) === 3 && (
                <>
                    <Form.Group widths="equal">
                        <SuperField
                            as="datepicker"
                            label={t('granted_date')}
                            value={form.granted_at}
                            onChange={(e, { value }) => {
                                setForm((prev) => ({ ...prev, granted_at: value }))
                            }}
                        />
                    </Form.Group>
                </>
            )}

            {parseInt(status) === 2 && (
                <>
                    <Form.Group widths="equal">
                        <SuperField
                            as="datepicker"
                            label={t('date_of_submission')}
                            value={form.date_of_submission}
                            onChange={(e, { value }) => {
                                setForm((prev) => ({ ...prev, date_of_submission: value }))
                            }}
                        />
                    </Form.Group>
                </>
            )}

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit disabled={isProcessing} loading={isProcessing} text={t('confirm')} />
            </Form.Field>
        </Form>
    )
}

export default StatusBulk
