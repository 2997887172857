import React from 'react';
import { Redirect } from 'react-router-dom';
import { routes } from '../../store/routes/index';
import { useHasModuleAllowed } from '@helpers/hooks';

const CanAccess = ({allowedModules, children, redirect, route, render, ...rest}) => {
    allowedModules = allowedModules || [];

    let canRender = useHasModuleAllowed(allowedModules);
    if( allowedModules === undefined || allowedModules.length === 0 ){
        canRender = true
    } else {
        canRender = render ? render : canRender 
    }

    return (
        <>
        {canRender ? children : redirect && <Redirect to={route||routes.DASHBOARD} />}
        </>
    )
}

export default CanAccess;