import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// routes
import { routes } from "../../store/routes/index";

// components
import { Button } from "semantic-ui-react";

function Error(props) {
    const { t } = useTranslation()

    return (
        <div className="error-page">
            <span className="center">
                <code>{props.code}</code>
                <p>{props.message}</p>
                <Link to={routes.HOME}>
                    <Button primary size="huge">
                        { t('404_message') }
                    </Button>
                </Link>
            </span>
        </div>
    );
}

export default Error;
