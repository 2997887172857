import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from "react-i18next"
// store
import { projectsService } from '@services/ServiceProjects';
import { isEmpty } from '@helpers/validation';
// components
import { Form, Message } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import DateField from '@components/forms/common/DateField';

const MilestoneForm = ({ milestoneItem, onClose, onConfirm }) => {
    const params = useParams()
    const { t } = useTranslation();

    // Milestone states
    const [milestone, setMilestone] = useState({
        name: milestoneItem ? milestoneItem.name : "",
        description: milestoneItem ? milestoneItem.description : "",
        from_date: milestoneItem ? milestoneItem.from_date : "",
        due_date: milestoneItem ? milestoneItem.due_date : "",
        project: params.id
    })

    // Form states
    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState([])

    const handleSubmit = async () => {
        setIsProcessing(true)

        let result = null;
        if (!milestoneItem) {
            result = await projectsService.createMilestone({
                ...milestone,
                from_date: milestone.from_date ? milestone.from_date : null,
                due_date: milestone.from_date ? milestone.due_date : null,
            })
        } else {
            result = await projectsService.updateMilestone(milestoneItem.id, {
                ...milestone,
                from_date: milestone.from_date ? milestone.from_date : null,
                due_date: milestone.from_date ? milestone.due_date : null,
            })
        }

        if (result && (result.status === 201 || result.status === 200)) {
            onConfirm(result.response)
            onClose()
        } else {
            if (result.response.non_field_errors) {
                setErrors([...errors, result.response.non_field_errors])
            }

            if (result.response.from_date) {
                setErrors([...errors, result.response.from_date])
            }

            if (result.response.due_date) {
                setErrors([...errors, result.response.due_date])
            }
        }

        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors.length > 0}
                header={t('err')}
                list={errors}
            />

            <Form.Field>
                <Form.Input
                    required
                    label={t('name')}
                    placeholder={t('enter_name')}
                    value={milestone.name}
                    onChange={(e) => setMilestone({
                        ...milestone,
                        name: e.target.value
                    })}
                />
            </Form.Field>

            <Form.Field>
                <Form.TextArea
                    label={t('desc')}
                    placeholder={t('desc')}
                    value={milestone.description}
                    onChange={(e) => setMilestone({
                        ...milestone,
                        description: e.target.value
                    })}
                />
            </Form.Field>

            <Form.Group widths={2}>
                <DateField
                    label={t("date_from")}
                    value={milestone.from_date}
                    onChange={(e, data) => setMilestone({
                        ...milestone,
                        from_date: data.value
                    })}
                />

                <DateField
                    label={t("date_to")}
                    value={milestone.due_date}
                    onChange={(e, data) => setMilestone({
                        ...milestone,
                        due_date: data.value
                    })}
                />
            </Form.Group>

            <Form.Field>
                <ModalCancel
                    onClose={onClose}
                    disabled={isProcessing}
                />
                <ModalSubmit
                    text={t("save")}
                    loading={isProcessing}
                    disabled={isProcessing || isEmpty(milestone.name)}
                />
            </Form.Field>
        </Form>
    );
};

export default MilestoneForm;