import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { useParams } from 'react-router-dom';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { isEmpty } from "@store/services/helpers/validation"

//components
import { Form, Button, Checkbox, Message } from 'semantic-ui-react'
import CanView from '@components/perms/CanView';

function MemberForm(props) {
    // GET ID from URL Parameters
    const params = useParams(); // for getting URL parameters like ":id" that represent team ID
    const [teamId] = useState(params.id); // getting ID from params object
    const [memberId] = useState(props.memberId ? props.memberId : 0); // sended from props (of its update form)

    const [employee, setEmployee] = useState(""); // will be used only for creating user
    const [employees, setEmployees] = useState([]); // will be used only for creating user
    const [position, setPosition] = useState("");
    const [description, setDescription] = useState("");
    const [isLeader, setIsLeader] = useState(false);

    // form state
    const [isProcessing, setIsProcessing] = useState(false); // true, false
    const [errorListAdd, setErrorListAdd] = useState([]); // empty array
    const [errorListLeader, setErrorListLeader] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();

    const handleSearch = async (query) => {
        const employees = await requests.get(API.EMPLOYEES + "?exclude_team=" + teamId + "&search=" + query + "&only_basic_info=true")
        if (employees.status === 200) {
            const employeesArray = []
            for (let i = 0; i < employees.response.length; i++) {
                const response = employees.response[i]
                employeesArray.push({ key: response.id, text: response.fullname_with_titles, value: response.id })
            }
        }
    }

    // used to get all information about team member
    useEffect(() => {
        async function fetchData() {
            const employees = await requests.get(API.EMPLOYEES + "?exclude_team=" + teamId + "&only_basic_info=true")
            if (employees.status === 200) {
                const employeesArray = []
                for (let i = 0; i < employees.response.length; i++) {
                    const response = employees.response[i]
                    employeesArray.push({ key: response.id, text: response.fullname_with_titles, value: response.id })
                }

                setEmployees(employeesArray)
            }
        }

        fetchData()
    }, [teamId])

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            if (memberId > 0) { // if memberId variable is not 0 then fetch data for this member
                const result = await requests.get(API.TEAMMEMBERS + memberId)

                if (result.status === 200) {
                    setEmployee(result.response.id);
                    setPosition(result.response.position);
                    setDescription(result.response.description);
                    setIsLeader(result.response.is_leader);
                }


            }
            setIsLoading(false);
        }

        fetchData();
    }, [memberId, teamId]);




    // async/await
    const handleSubmit = async () => {
        setIsProcessing(true);

        const data = {
            position: position,
            is_leader: isLeader,
            description: description,
            profile: employee,
            team: parseInt(teamId)
        }

        if (memberId === 0) { // then its create form
            const result = await requests.post(API.TEAMMEMBERS, data);
            if (result.status === 201) { // on success create
                props.onConfirm(result.response);
                props.onClose();
            } else { // some error occured, lets show some message

                if (result.response.profile) {
                    errorListAdd.push(result.response);
                    setErrorListAdd(errorListAdd);
                } else if (result.response.is_leader) {
                    errorListLeader.push(result.response);
                    setErrorListLeader(errorListLeader);
                }

            }
        } else { // then its update form
            const result = await requests.put(API.TEAMMEMBERS + memberId + "/", data)
            if (result.status === 200) { // on success update
                props.onConfirm(result.response); // second parameter for
                props.onClose();
            } else { // some error occured, lets show some message

                if (result.response.profile) {
                    errorListAdd.push(result.response);
                    setErrorListAdd(errorListAdd);
                } else if (result.response.is_leader) {
                    errorListLeader.push(result.response);
                    setErrorListLeader(errorListLeader);
                }

            }
        }
        setIsProcessing(false);
    }

    return (
        <Form onSubmit={handleSubmit} loading={isLoading}>
            <Form.Field>

                {errorListAdd.length > 0 &&
                    <Message negative style={{ textAlign: "center" }}>
                        <Message.Header >{errorListAdd[0].user[0]}</Message.Header>
                    </Message>
                }

                {errorListLeader.length > 0 &&
                    <Message negative style={{ textAlign: "center" }}>
                        <Message.Header >{errorListLeader[0].is_leader}</Message.Header>
                    </Message>

                }
            </Form.Field>
            <Form.Group widths="equal">
                {memberId === 0 && // consider that as create form if memberId doesn't exists
                    <Form.Select fluid
                        required
                        autoFocus
                        search
                        onSearchChange={(e) => handleSearch(e.target.value)}
                        label={t('employee')}
                        value={employee}
                        options={employees} // temporary usage
                        placeholder='---------'
                        onChange={(e, data) => setEmployee(data.value)}
                    />
                }

                <Form.Input fluid
                    // autoFocus
                    required
                    value={position}
                    label={t('position')}
                    placeholder={t('member_position')}
                    onChange={(e) => setPosition(e.target.value)}
                />
            </Form.Group>

            <Form.Field>
                <Form.Input
                    value={description}
                    label={t('description')}
                    placeholder={t('member_description')}
                    control='textarea' rows='2'
                    onChange={(e) => setDescription(e.target.value)}
                />
            </Form.Field>
            <CanView permissions={["teams.c_manage_team_leader"]}>
                <Form.Field>
                    <Checkbox
                        label={t('leader')}
                        checked={isLeader}
                        onChange={(e, data) => setIsLeader(data.checked)}
                        style={{ float: "left" }} />
                </Form.Field>
            </CanView>

            <Form.Field style={{ textAlign: "right" }}>
                <Button
                    type="button"
                    style={{ backgroundColor: "var(--variant5)" }}
                    onClick={props.onClose}>
                    {t('cancel')}
                </Button>
                <Button
                    primary
                    disabled={isProcessing || isEmpty(position) || isEmpty(isLeader) || isEmpty(employee)}
                    loading={isProcessing}
                    style={{ backgroundColor: "var(--success)" }}
                >
                    {props.btnName}
                </Button>
            </Form.Field>
        </Form>
    )
}

export default MemberForm