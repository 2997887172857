import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests';
// components
import { Form, Button, Message } from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react'
import ChoiceField from '@components/forms/common/ChoiceField'


function EducationForm({ education, onConfirm, onClose}) {
    const { t } = useTranslation();

    const [data, setData] = useState({
        id: education ? education.id : 0,
        name: education.name ? education.name : '',
        educationType: education.education_type ? education.education_type : null,
        department: education.department ? education.department : '',
        field: education.field ? education.field : '',
        dateFrom: education.date_from ? education.date_from : null,
        dateTo: education.date_to ? education.date_to : null
    })

    const [errors, setErrors] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleErrors = (response) => {
        let errorsArray = [];
        if (response.date_to) {
            errorsArray.push(response.date_to)
        }
        if (response.date_from) {
            errorsArray.push(response.date_from)
        }
        if (response.non_field_errors) {
            errorsArray.push(response.non_field_errors)
        }

        setErrors(errorsArray);
    }

    const handleSuccess = (response) => {
        onConfirm(response);
        onClose()
    }

    const handleSubmit = async () => {
        setErrors([]);
        setIsProcessing(true);

        let dataset = {
            name: data.name,
            education_type: data.educationType,
            department: data.department,
            field: data.field,
            date_from: data.dateFrom,
            date_to: data.dateTo ? data.dateTo : null,
        }

        if(!data.id || data.id === 0) {
            const request = await requests.post(API.ADD_EDUCATION, dataset);
            if (request.status === 201) {
                handleSuccess(request.response)
            } else {
                handleErrors(request.response)
            }
        } else {
            const request = await requests.patch(API.ADD_EDUCATION + data.id + '/', dataset);
            if (request.status === 200) {
                handleSuccess(request.response)
            } else {
                handleErrors(request.response)
            }
        }

        setIsProcessing(false);
    }

    return (

        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors.length > 0}
                header={t("error_submission_message")}
                list={errors}
            />

            <Form.Field>
                <Form.Input
                    autoFocus
                    label={t('school_name')}
                    placeholder={t('school_name')}
                    value={data.name}
                    onChange={(e) => setData({
                        ...data,
                        name: e.target.value
                    })}
                />

                <ChoiceField
                    type="education_type_choices"
                    label={t('type')}
                    placeholder={t('type')}
                    value={data.educationType}
                    onChange={(e, { value }) => setData({
                        ...data,
                        educationType: value
                    })}
                />

                <Form.Input
                    label={t('department')}
                    placeholder={t('department')}
                    value={data.department}
                    onChange={(e) => setData({
                        ...data,
                        department: e.target.value
                    })}
                />

                <Form.Input
                    label={t('field')}
                    placeholder={t('field')}
                    value={data.field}
                    onChange={(e) => setData({
                        ...data,
                        field: e.target.value
                    })}
                />
                <DateInput
                    dateFormat="YYYY-MM-DD"
                    name='date'
                    label={t('date_from')}
                    placeholder="2020-01-01"
                    value={data.dateFrom}
                    iconPosition="left"
                    onChange={(e, { value }) =>
                        { value === "" || value === null ?
                        setData({ ...data, dateFrom: null}) :
                        setData({ ...data, dateFrom: value})
                    }}
                />
                <DateInput
                    dateFormat="YYYY-MM-DD"
                    name='date'
                    label={t('date_until')}
                    placeholder="2020-12-30"
                    value={data.dateTo}
                    iconPosition="left"
                    onChange={(e, { value }) =>
                        { value === "" || value === null ?
                        setData({ ...data, dateTo: null}) :
                        setData({ ...data, dateTo: value})
                    }}
                />
            </Form.Field>
            <Form.Field style={{ textAlign: "right" }}>
                <Button
                    basic
                    type="button"
                    onClick={onClose}>
                    {t('cancel')}
                </Button>
                <Button
                    primary
                    loading={isProcessing}
                    disabled={
                        isProcessing
                    }
                >
                    {t('confirm')}
                </Button>
            </Form.Field>
        </Form>
    )
}

export default EducationForm