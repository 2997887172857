import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import moment from 'moment';
// store
import { requests } from '@helpers/requests';
import { dates as dateHelper } from '@helpers/dates';
// components
import CanView from '@components/perms/CanView';
import SuperField from '@components/forms/SuperField';
import { Comment, Button, Form, Divider } from 'semantic-ui-react';

const Notes = ({ notes, endpoint, id, setData, onResponse, canDelete, canManage }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector(state => state?.date_format || "YYYY-MM-DD")
    const [processing, setProcessing] = useState(false)
    const [note, setNote] = useState("")

    canDelete = canDelete || []
    canManage = canManage || []

    const onDelete = async (note_id) => {
        const request = await requests.patch(endpoint + id + "/", {
            notes: {
                remove: [note_id]
            }
        })
        
        if (request.status === 200) {
            if (onResponse === undefined && setData !== undefined) {
                setData(prev => prev.filter(item => {
                    if (item.id === id){
                        item.notes = request.response.notes
                    }
    
                    return item
                }))
            } else {
                onResponse(request.response)
            }
        }
    }

    const handleSubmit = async () => {
        setProcessing(true)
        const request = await requests.patch(endpoint + id + "/", {
            notes: {
                create: [{ text: note }]
            }
        })
        
        if (request.status === 200) {
            if (onResponse === undefined && setData !== undefined) {
                setData(prev => prev.filter(item => {
                    if (item.id === id){
                        item.notes = request.response.notes
                    }
    
                    return item
                }))
            } else {
                onResponse(request.response)
            }
        }
        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <CanView permissions={canManage}>
                <SuperField
                    autoFocus
                    value={note}
                    label={t('note')}
                    fluid as="textarea"
                    onChange={(e, { value }) => setNote(value)}
                />
                <Button
                    primary
                    size="tiny"
                    disabled={processing || note === ""}
                    loading={processing}
                    content={t('confirm')}
                />
                <Divider/>
            </CanView>
            <Comment.Group>
                { notes.map(note => (
                    <div key={note.id}>
                        <Comment>
                            <Comment.Content>
                                <Comment.Author>{ note.created_by.name }</Comment.Author>
                                <Comment.Metadata style={{ fontSize: "0.9rem", marginLeft: "0" }}><div>{moment(dateHelper.convertUTCDateToLocalDate(note.created_on)).format(dateFormat + " HH:mm")}</div></Comment.Metadata>
                                <Comment.Text style={{ fontWeight: "bold"}}>{note.text}</Comment.Text>
                                <CanView permissions={canDelete}>
                                    <Comment.Actions>
                                        <Comment.Action onClick={() => onDelete(note.id)}> 
                                            <span style={{ color: "var(--danger)" }}>{ t("remove") }</span>
                                        </Comment.Action>
                                    </Comment.Actions>
                                </CanView>
                            </Comment.Content>
                        </Comment>
                        <Divider/>
                    </div>
                )) }
            </Comment.Group>
            
        </Form>
    )
}

export default Notes;