// react
import React, { Fragment } from "react";
// router
import { Link } from "react-router-dom";

// routes
import { routes } from "../../../../store/routes/index";

// styles
import { Button, Header, Icon } from "semantic-ui-react";

// component
function ActivationSuccessMessage() {
    // template
    return (
        <Fragment>
            <Icon name="checkmark" size="huge" primary circular />
            <Header
                as="h3"
                style={{
                    marginTop: "0.5em",
                }}
                content="Account activation was successfully completed"
            />
            <Header
                as="H4"
                content="Your account was activated."
            />

            <Link to={routes.LOGIN}>
                <Button fluid primary size="big">
                    Sign in
                </Button>
            </Link>
        </Fragment>
    );
}

export default ActivationSuccessMessage;
