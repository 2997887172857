const journalReducer = (journal = null, action) => {
    switch (action.type) {
        case 'RESET_STATE':
            return null
        case 'ADD_JOURNAL':
            return action.payload
        default:
            return journal
    }
}

export default journalReducer
