import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-semantic-toasts'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import { Form, Divider } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const TransferRecordToAnotherDayForm = ({
    day,
    contract,
    onClose,
    record,
    planRecord,
    setContracts,
    attendanceRecords,
    setAttendanceRecords,
}) => {
    const { t } = useTranslation()

    const firstDay = moment(record.date).startOf('month').format('YYYY-MM-DD')
    const lastDay = moment(record.date).endOf('month').format('YYYY-MM-DD')

    const dateFormat = useSelector((state) => state.date_format)
    const [processing, setProcessing] = useState(false)
    const [selectedDay, setSelectedDay] = useState(record.date)

    const findExisting = (date) => {
        return attendanceRecords.find(
            (item) => item.contract === contract.id && item.employee === contract.profile_id && item.date === date
        )
    }

    const handleSubmit = async () => {
        setProcessing(true)

        // if selectedDay is already taken then alert user about that
        if (findExisting(selectedDay) !== undefined) {
            toast({
                type: 'error',
                icon: 'warning circle',
                title: t('selected_day_is_taken_and_action_can_not_be_performed'),
                animation: 'pulse',
                time: 5000,
            })
            setProcessing(false)
            return
        }

        let date = selectedDay
        let start = record?.start || null
        let end = record?.end || null
        let start_time = record?.start ? moment(record.start).format('HH:mm') : null
        let end_time = record?.end ? moment(record.end).format('HH:mm') : null

        if (start_time && end_time) {
            // exists
            if (moment(end_time, 'HH:mm').isAfter(moment(start_time, 'HH:mm'))) {
                start = moment(date + ' ' + start_time).format('YYYY-MM-DD HH:mm')
                end = moment(date + ' ' + end_time).format('YYYY-MM-DD HH:mm')
            } else if (moment(end_time, 'HH:mm').isSame(moment(start_time, 'HH:mm'))) {
                start = moment(date + ' ' + start_time).format('YYYY-MM-DD HH:mm')
                end = moment(date + ' ' + end_time).format('YYYY-MM-DD HH:mm')
            } else {
                start = moment(date + ' ' + start_time).format('YYYY-MM-DD HH:mm')
                end = moment(date + ' ' + end_time)
                    .add(1, 'day')
                    .format('YYYY-MM-DD HH:mm')
            }
        }

        if (record.is_corrected || record.original_record !== null) {
            const request = await requests.patch(
                API.ATTENDANCE_BASE + 'records/' + record.id + '/?is_manager_edit=true',
                {
                    date: date,
                    start: start,
                    end: end,
                }
            )

            if (request.status === 200) {
                setAttendanceRecords((prev) =>
                    prev.filter((item) => {
                        if (item.id === record.id) {
                            item.date = request.response.date
                            item.start = request.response.start
                            item.end = request.response.end
                            item.duration = request.response?.work_hours || 0
                            item.duration_real = request.response?.duration || 0
                        }
                        return item
                    })
                )
            }
        } else {
            // create correction from original and move to another day
            const request = await requests.post(API.ATTENDANCE_BASE + 'records/manual/', {
                date: date,
                start: start,
                end: end,
                contract: record.contract,
                employee: record.employee,
                closed: true,
                is_corrected: true,
            })

            if (request.status === 201) {
                const updateExistingRecord = await requests.patch(
                    API.ATTENDANCE_BASE + 'records/' + record.id + '/?is_manager_edit=true',
                    {
                        correction: request.response.id,
                        is_resolved: true,
                    }
                )

                if (updateExistingRecord.status === 200) {
                    const requestCorrectedRecord = await requests.get(
                        API.ATTENDANCE_BASE + 'records/' + request.response.id + '/'
                    )
                    // update attendancerecords listing to include corrected record
                    if (requestCorrectedRecord.status === 200) {
                        setAttendanceRecords((prev) => [
                            ...prev.filter((item) => item.id !== record.id),
                            {
                                id: requestCorrectedRecord.response.id,
                                employee: requestCorrectedRecord.response.employee?.id,
                                contract: requestCorrectedRecord.response.contract?.id,
                                date: requestCorrectedRecord.response.date,
                                start: requestCorrectedRecord.response.start,
                                end: requestCorrectedRecord.response.end,
                                duration: requestCorrectedRecord.response?.work_hours || 0,
                                duration_real: requestCorrectedRecord.response?.duration || 0,
                                closed: requestCorrectedRecord.response.closed,
                                vacation: requestCorrectedRecord.response.vacation,
                                paragraph: requestCorrectedRecord.response.paragraph,
                                sick_day: requestCorrectedRecord.response.sick_day,
                                absention: requestCorrectedRecord.response.absention,
                                care_for_a_family_member: requestCorrectedRecord.response.care_for_a_family_member,
                                lunch_voucher: requestCorrectedRecord.response.lunch_voucher,
                                original_record: requestCorrectedRecord.response.original_record,
                                correction: requestCorrectedRecord.response.correction,
                                is_corrected: requestCorrectedRecord.response.is_corrected,
                                week: moment(requestCorrectedRecord.response.date).isoWeek(),
                            },
                        ])
                    }
                }
            }
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <p>
                {t('current_day')}: <strong>{moment(record.date).format(dateFormat)}</strong>
            </p>
            <Form.Group widths={'equal'}>
                <SuperField
                    as="datepicker"
                    required
                    value={selectedDay}
                    label={t('change_date')}
                    maxDate={moment(lastDay)}
                    minDate={moment(firstDay)}
                    onChange={(e, { value }) => {
                        setSelectedDay(value)
                    }}
                />
            </Form.Group>
            {findExisting(selectedDay) !== undefined && selectedDay !== record.date ? (
                <p style={{ fontWeight: 'bold', color: 'var(--danger)' }}>
                    {t('selected_day_is_taken_and_action_can_not_be_performed')}!
                </p>
            ) : selectedDay === record.date ? (
                <p style={{ fontWeight: 'bold', color: 'var(--danger)' }}>{t('selected_day_is_the_same')}.</p>
            ) : (
                <p style={{ fontWeight: 'bold', color: 'green' }}>{t('selected_day_is_free_to_use')}.</p>
            )}

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit loading={processing} disabled={processing || selectedDay === ''} />
            </Form.Field>
        </Form>
    )
}

export default TransferRecordToAnotherDayForm
