import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
// store
import { routes } from '@routes/index'
import { setToggledSidebar } from '@actions'
import { authService } from '@services/ServiceAuth'
import { toggleSidebar } from '@services/helpers/functions'
// components
import { Menu, Image, Dropdown, Label } from 'semantic-ui-react'
import Icon from '@components/Icon'
import LanguageSelector from '@components/general/LanguageSelector'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import HorizontalSidebar from './HorizontalSidebar'
import Notifications from './Notifications'
import BugReporter from '@components/widgets/BugReporter'

function MainNavigation({ changeViewType, isFullWidth }) {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()

    const user = useSelector((state) => state.user)
    const logo = useSelector((state) => state.logo)

    const [hSidebar, setHSidebar] = useState(false)

    const logout = () => {
        authService.logout(dispatch)
        history.push(routes.LOGIN)
    }

    function Toggle() {
        let wrapper = document.querySelector('.wrapper')
        if (wrapper.classList.contains('collapsed')) {
            dispatch(setToggledSidebar(true))
        } else {
            dispatch(setToggledSidebar(false))
        }
        toggleSidebar()
    }

    return (
        <Fragment>
            <div className="bigMenu">
                <HorizontalSidebar visible={hSidebar} close={() => setHSidebar(false)} />

                <Menu
                    stackable
                    fixed="top"
                    borderless
                    style={isFullWidth ? { left: 0, width: '100%', height: '65px' } : { height: '65px' }}
                >
                    <Menu.Menu position="left">
                        <Menu.Item>
                            <Link to={routes.DASHBOARD}>
                                <Image
                                    size="tiny"
                                    src={logo}
                                    style={{ maxHeight: '43px', height: '100%' }}
                                    onError={(e) => {
                                        e.currentTarget.src = '/images/logo.svg'
                                    }}
                                />
                            </Link>
                        </Menu.Item>
                    </Menu.Menu>

                    <Menu.Menu style={{ width: '55%', marginLeft: 'auto', marginRight: 'auto' }}>
                        {/* <Search disabled className="search-bar" fluid style={{ width: "100%", marginTop: ".8rem" }} size="large" placeholder={t('search')} /> */}
                    </Menu.Menu>

                    <Menu.Menu position="right">
                        <Menu.Item>
                            <LanguageSelector />
                        </Menu.Item>
                        {/* <Menu.Item>
                            <Icon style={{ cursor: "pointer" }} title={t('quick_access')} onClick={() => setHSidebar(true)} name="grid-outline" />
                        </Menu.Item> */}
                        {window.location.pathname === routes.DASHBOARD && (
                            <Menu.Item>
                                <Icon
                                    style={{ cursor: 'pointer' }}
                                    onClick={changeViewType !== undefined ? changeViewType : null}
                                    name="swap-horizontal-outline"
                                />
                            </Menu.Item>
                        )}
                        <Notifications />
                        <Menu.Item>
                            {!user?.profile_picture ? (
                                <Label
                                    circular
                                    className="circular-label"
                                    style={{
                                        marginRight: '.5rem',
                                        backgroundColor: 'var(--light)',
                                        color: 'var(--dark)',
                                    }}
                                >
                                    {user.name ? user?.name.charAt(0) : ''}
                                </Label>
                            ) : (
                                <Image src={user?.profile_picture} style={{ marginRight: '.5rem' }} avatar />
                            )}
                            <Dropdown text={user?.name}>
                                <Dropdown.Menu direction="left" style={{ marginTop: '1rem', borderRadius: '0' }}>
                                    <Dropdown.Item
                                        as={Link}
                                        className="nav-link"
                                        to={routes.PROFILE}
                                        icon={
                                            <Icon
                                                name="person-circle-outline"
                                                style={{ fontSize: '1.1rem', marginRight: '0.5rem' }}
                                            />
                                        }
                                        text={
                                            <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                {' '}
                                                {t('profile')}{' '}
                                            </span>
                                        }
                                    />
                                    <Dropdown.Item
                                        as={Link}
                                        className="nav-link"
                                        to={routes.SETTINGS}
                                        icon={
                                            <Icon
                                                name="settings-outline"
                                                style={{ fontSize: '1.1rem', marginRight: '0.5rem' }}
                                            />
                                        }
                                        text={
                                            <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                {' '}
                                                {t('settings')}{' '}
                                            </span>
                                        }
                                    />
                                    <Dropdown.Item
                                        as="button"
                                        icon={
                                            <Icon
                                                name="log-out-outline"
                                                style={{ fontSize: '1.1rem', marginRight: '0.5rem' }}
                                            />
                                        }
                                        text={
                                            <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                {' '}
                                                {t('sign_out')}{' '}
                                            </span>
                                        }
                                        onClick={logout}
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </div>

            <div className="smallMenu" style={{ marginBottom: '5rem' }}>
                <HorizontalSidebar visible={hSidebar} close={() => setHSidebar(false)} />

                <Menu
                    borderless
                    fixed="top"
                    style={{
                        top: 0,
                        marginTop: 0,
                        zIndex: 1000,
                        height: '65px',
                        left: isFullWidth && 0,
                        width: isFullWidth && '100%',
                        right: isFullWidth && 0,
                    }}
                >
                    <Menu.Menu position="left" style={{ width: '70%' }}>
                        <Menu.Item className="siderbar-top">
                            <div
                                onClick={Toggle}
                                style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    fontSize: '1.2rem',
                                    fontWeight: 'bold',
                                    alignItems: 'center',
                                }}
                            >
                                <Icon name="menu-outline" style={{ margin: 0, color: 'var(--dark)' }} />
                            </div>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to={routes.DASHBOARD}>
                                <Image
                                    size="tiny"
                                    src={logo}
                                    style={{ maxHeight: '43px', height: '100%' }}
                                    onError={(e) => {
                                        e.currentTarget.src = '/images/logo.svg'
                                    }}
                                />
                            </Link>
                        </Menu.Item>
                    </Menu.Menu>

                    <Menu.Menu position="right">
                        {/* <Menu.Item>
                            <Icon style={{ cursor: "pointer" }} title={t('quick_access')} onClick={() => setHSidebar(true)} name="grid-outline" />
                        </Menu.Item> */}
                        {/*<Notifications smallMenu />*/}
                        {/*
                        <Menu.Item>
                            <Popup
                                position="bottom center"
                                trigger={
                                    <Icon
                                        className="disabled"
                                        style={{ cursor: 'pointer' }}
                                        name={theme === 'light' ? 'moon-outline' : 'sunny-outline'}
                                        onClick={() => dispatch(setTheme(theme === "light" ? "dark" : "light"))}
                                    />
                                }
                                content={theme === 'light' ? 'Dark mode' : 'Light mode'}
                            />
                        </Menu.Item>
                        */}
                        <Menu.Item position="right">
                            <Dropdown icon="user circle outline" floating className="icon">
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        as={Link}
                                        className="nav-link"
                                        to={routes.PROFILE}
                                        icon={
                                            <Icon
                                                name="person-circle-outline"
                                                style={{ fontSize: '1.1rem', marginRight: '0.5rem' }}
                                            />
                                        }
                                        text={
                                            <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                {' '}
                                                {t('profile')}{' '}
                                            </span>
                                        }
                                    />
                                    <Dropdown.Item
                                        as={Link}
                                        className="nav-link"
                                        to={routes.SETTINGS}
                                        icon={
                                            <Icon
                                                name="settings-outline"
                                                style={{ fontSize: '1.1rem', marginRight: '0.5rem' }}
                                            />
                                        }
                                        text={
                                            <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                {' '}
                                                {t('settings')}{' '}
                                            </span>
                                        }
                                    />
                                    <BugReporter
                                        trigger={
                                            <Dropdown.Item
                                                className="bug-reporter-form"
                                                icon={
                                                    <Icon
                                                        name="bug"
                                                        style={{ fontSize: '1.1rem', marginRight: '0.5rem' }}
                                                    />
                                                }
                                                text={
                                                    <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                        {' '}
                                                        {t('report_a_bug')}{' '}
                                                    </span>
                                                }
                                            />
                                        }
                                    />
                                    <Dropdown.Item
                                        as="button"
                                        icon={
                                            <Icon
                                                name="log-out-outline"
                                                style={{ fontSize: '1.1rem', marginRight: '0.5rem' }}
                                            />
                                        }
                                        text={
                                            <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                {' '}
                                                {t('sign_out')}{' '}
                                            </span>
                                        }
                                        onClick={logout}
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </div>
        </Fragment>
    )
}

export default MainNavigation
