import React from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
//components
import SuperField from '@components/forms/SuperField'
import DatePresets from '@components/DatePresets'
import { Form, Divider } from 'semantic-ui-react'

const VisaAndResidencessFilters = ({ filters, setFilters }) => {
    const { t } = useTranslation()

    return (
        <Form>
            <Form.Group widths="equal">
                <SuperField
                    search
                    as="datepicker"
                    label={t('date_from')}
                    value={filters.valid_from_after}
                    onChange={(e, { value }) => {
                        setFilters({ ...filters, valid_from_after: value })
                    }}
                />
                <SuperField
                    search
                    as="datepicker"
                    label={t('date_to')}
                    value={filters.valid_to_before}
                    onChange={(e, { value }) => {
                        setFilters({ ...filters, valid_to_before: value })
                    }}
                />
            </Form.Group>
            <DatePresets
                from={filters.valid_from_after}
                to={filters.valid_to_before}
                onSelection={(firstMonthDay, lastMonthDay, year) => {
                    setFilters({ ...filters, valid_from_after: firstMonthDay, valid_to_before: lastMonthDay })
                }}
            />

            <Divider />

            <Form.Group widths={'equal'}>
                <SuperField
                    as="choice-select"
                    search
                    label={t('foreigner')}
                    endpoint={API.FOREIGNERS}
                    additionalFilters={'&only_basic_info=true'}
                    text="fullname_with_titles"
                    value={filters.profile}
                    onChange={(e, { value }) => setFilters({ ...filters, profile: value })}
                />
                <SuperField
                    as="choice-select"
                    text="title"
                    endpoint={API.FOREIGNERS + 'types_of_residence/'}
                    label={t('type_of_residence')}
                    value={filters.type_of_residence}
                    onChange={(e, { value }) => setFilters({ ...filters, type_of_residence: value })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="choice-select"
                    search
                    label={t('accommodation')}
                    value={filters.accommodation}
                    endpoint={API.ACCOMMODATIONS}
                    text="name"
                    onChange={(e, { value }) => setFilters({ ...filters, accommodation: value })}
                />
                <SuperField
                    as="choice-select"
                    text="title"
                    endpoint={API.FOREIGNERS + 'purposes_of_stay/'}
                    label={t('purpose_of_stay')}
                    value={filters.purpose_of_stay}
                    onChange={(e, { value }) => setFilters({ ...filters, purpose_of_stay: value })}
                />
            </Form.Group>

            <SuperField
                as="choice-select"
                label={t('citizenship')}
                type="citizenship"
                value={filters.citizenship}
                onChange={(e, { value }) =>
                    setFilters({
                        ...filters,
                        citizenship: value,
                    })
                }
            />

            <SuperField
                as="choice-select"
                search
                multiple
                value={filters.labels}
                label={t('labels')}
                endpoint={API.FOREIGNERS_LABELS}
                text="name"
                onChange={(e, { value }) =>
                    setFilters({
                        ...filters,
                        labels: value,
                    })
                }
            />

            <SuperField
                as="choice"
                label={t('status')}
                customOptions={[
                    { key: 1, value: 1, text: t('open') },
                    { key: 2, value: 2, text: t('submitted') },
                    { key: 3, value: 3, text: t('granted') },
                    { key: 4, value: 4, text: t('suspended') },
                    { key: 5, value: 5, text: t('denied') },
                ]}
                value={filters.process_status}
                onChange={(e, { value }) =>
                    setFilters({
                        ...filters,
                        process_status: value,
                    })
                }
            />

            <Divider />

            <SuperField
                as="checkbox"
                label={t('has_valid_pass')}
                checked={filters.have_valid_pass === true}
                onChange={() =>
                    setFilters({ ...filters, have_valid_pass: filters.have_valid_pass === true ? '' : true })
                }
            />

            <SuperField
                as="checkbox"
                label={t('has_register_of_penalties_from_his_country')}
                checked={filters.register_of_penalties}
                onChange={() => setFilters({ ...filters, register_of_penalties: !filters.register_of_penalties })}
            />
            <SuperField
                as="checkbox"
                label={t('short_position')}
                checked={filters.short_position}
                onChange={() => setFilters({ ...filters, short_position: !filters.short_position })}
            />
            <SuperField
                as="checkbox"
                label={t('significant_investor')}
                checked={filters.significant_investor}
                onChange={() => setFilters({ ...filters, significant_investor: !filters.significant_investor })}
            />
        </Form>
    )
}

export default VisaAndResidencessFilters
