import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import CanView from '@components/perms/CanView'
import SuperField from '@components/forms/SuperField'
import NonFieldErrors from '@components/NonFieldErrors'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import { Form, Divider, Header } from 'semantic-ui-react'
import {
    BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    // BtnItalic,
    BtnLink,
    BtnNumberedList,
    BtnRedo,
    // BtnStrikeThrough,
    BtnUnderline,
    BtnUndo,
    HtmlButton,
    Separator,
    Toolbar,
    EditorProvider,
    Editor,
} from 'react-simple-wysiwyg'

const PositionForm = ({ onClose, isDuplicate, record, setData, setTotal, setHeader }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)

    const [showPositionDesctiption, setShowPositionDesctiption] = useState(false)
    const [showManageRequirements, setShowManageRequirements] = useState(false)
    const [showManagePositionSettings, setShowManagePositionSettings] = useState(false)
    const [showManagePositionContractDetails, setShowManagePositionContractDetails] = useState(false)

    const [form, setForm] = useState({
        title: record?.title || '',
        position_number: record?.position_number || '',
        is_external: record?.is_external === undefined ? false : record.is_external,
        isco: record?.isco?.id || '',
        group: record?.group?.id || '',
        job_family: record?.job_family?.id || '',
        description: record?.description || '',
        work_activity: record?.work_activity || '',
        work_factors: record?.work_factors || '',
        additional_info: record?.additional_info || '',
        bonuses: record?.bonuses?.map((item) => item.id) || [],
        required_courses: record?.required_courses?.map((item) => item.id) || [],
        eligibilities: record?.eligibilities ? record.eligibilities.map((item) => item.toString()) : [] || [],
        citizenships: record?.citizenships || [],
        drivers_licences: record?.drivers_licences ? record.drivers_licences.map((item) => item.toString()) : [],
        practice_years: record?.practice_years || 0,
        specialization: record?.specialization || '',
        educations: record?.educations ? record.educations.map((item) => item.toString()) : [],
        gender: record?.gender || '',
        age_higher: record?.age_higher || '',
        age_lower: record?.age_lower || '',
        min: record?.min || '',
        max: record?.max || '',
        currency: record?.currency || 'EUR',
        work_level: record?.work_level || 1,
        salary_type: record?.salary_type || 1,
        variable_remuneration_ratio: record?.variable_remuneration_ratio || '',
        variable_remuneration_min_value: record?.variable_remuneration_min_value || '',
        variable_remuneration_max_value: record?.variable_remuneration_max_value || '',
        contract_type: record?.contract_type?.id || '',
    })

    useEffect(() => {
        if (record?.id) setHeader(t('update_position'))
        // eslint-disable-next-line
    }, [record])

    const isFormValid = () => {
        // check some fields if needed
        if (form.title === '') return false

        return true
    }

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)

        // handle form processing
        let data = {
            ...form,
            age_lower: form.age_lower || null,
            age_higher: form.age_higher || null,
            min: form.min || null,
            max: form.max || null,
            variable_remuneration_ratio: form.variable_remuneration_ratio || null,
            variable_remuneration_max_value: form.variable_remuneration_max_value || null,
            variable_remuneration_min_value: form.variable_remuneration_min_value || null,
            group: form.group || null,
            isco: form.isco || null,
            job_family: form.job_family || null,
            contract_type: form.contract_type || null,
        }

        if (record === undefined || isDuplicate) {
            if (data.bonuses.length === 0) {
                data.bonuses = {}
            } else {
                data.bonuses = {
                    add: form.bonuses,
                }
            }

            if (data.required_courses.length === 0) {
                data.required_courses = {}
            } else {
                data.required_courses = {
                    add: form.required_courses,
                }
            }
        } else {
            data.bonuses = {
                remove: record.bonuses.map((item) => item.id),
                add: form.bonuses,
            }

            data.required_courses = {
                remove: record.required_courses.map((item) => item.id),
                add: form.required_courses,
            }
        }

        if (record === undefined || isDuplicate) {
            const request = await requests.post(API.JOB_POSITIONS, data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                setTotal((prev) => prev + 1)
                setData((prev) => [request.response, ...prev])
                onClose()
            }
        } else {
            const request = await requests.patch(API.JOB_POSITIONS + record.id + '/', data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === record.id) {
                            item = request.response
                        }
                        return item
                    })
                )
                onClose()
            }
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <NonFieldErrors errors={errors} />

            <Form.Group widths="equals">
                <SuperField
                    as="input"
                    required
                    autoFocus
                    width="12"
                    label={t('title')}
                    value={form.title}
                    error={errors ? errors.title?.[0] : false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, title: value }))}
                />
                <SuperField
                    as="input"
                    width="4"
                    label={t('position_number')}
                    value={form.position_number}
                    error={errors ? errors.position_number?.[0] : false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, position_number: value }))}
                />
            </Form.Group>

            <SuperField
                as="checkbox"
                label={t('mark_this_position_as_external')}
                checked={form.is_external}
                onChange={() => setForm((prev) => ({ ...prev, is_external: !form.is_external }))}
            />

            <Divider />

            <SuperField
                as="choice-select"
                search
                label={'ISCO'}
                settings="types/isco"
                value={form.isco}
                endpoint={API.JOBS + 'isco/'}
                text={(item) =>
                    `${item.code || ''} - ${item.fullname} ${item.shortname ? `- (${item.shortname})` : ''}`
                }
                onChange={(e, { value }) =>
                    setForm((prev) => ({
                        ...prev,
                        isco: value,
                    }))
                }
                initialOptions={{
                    attribute: 'fullname',
                    source: record?.isco,
                }}
            />

            <Form.Group widths="equal">
                <SuperField
                    as="choice-select"
                    search
                    text="title"
                    label={t('position_group')}
                    value={form.group}
                    settings="types/position-groups"
                    endpoint={API.JOBS + 'position_groups/'}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({
                            ...prev,
                            group: value,
                        }))
                    }
                    initialOptions={{
                        attribute: 'title',
                        source: record?.group,
                    }}
                />

                <SuperField
                    as="choice-select"
                    search
                    text="title"
                    label={t('job_family')}
                    value={form.job_family}
                    settings="types/job-families"
                    endpoint={API.JOBS + 'job_families/'}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({
                            ...prev,
                            job_family: value,
                        }))
                    }
                    initialOptions={{
                        attribute: 'title',
                        source: record?.job_family,
                    }}
                />
            </Form.Group>

            <Divider />
            <Header
                as="h5"
                style={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                    cursor: 'pointer',
                    opacity: '0.8',
                    color: 'var(--primary)',
                }}
                onClick={() => setShowPositionDesctiption(!showPositionDesctiption)}
            >
                <Icon
                    name={`chevron-${showPositionDesctiption ? 'down' : 'forward'}-outline`}
                    style={{ marginRight: '0.5rem', position: 'relative', top: '0.2rem' }}
                />
                <span>{t('manage_position_description')}</span>
            </Header>

            {showPositionDesctiption && (
                <>
                    <Divider />
                    <Form.Field>
                        <EditorProvider>
                            <Editor
                                containerProps={{
                                    style: {
                                        resize: 'vertical',
                                        width: '100%',
                                        background: 'white',
                                        minHeight: '250px',
                                    },
                                }}
                                placeholder={t('add_position_description')}
                                value={form.description}
                                onChange={(e) =>
                                    setForm((prev) => ({
                                        ...prev,
                                        description: e.target.value,
                                    }))
                                }
                            >
                                <Toolbar>
                                    <BtnUndo />
                                    <BtnRedo />
                                    <Separator />
                                    <BtnBold />
                                    {/* <BtnItalic /> */}
                                    <BtnUnderline />
                                    {/* <BtnStrikeThrough /> */}
                                    <Separator />
                                    <BtnNumberedList />
                                    <BtnBulletList />
                                    <Separator />
                                    <BtnLink />
                                    <Separator />
                                    <BtnClearFormatting />
                                    <HtmlButton />
                                </Toolbar>
                            </Editor>
                        </EditorProvider>
                    </Form.Field>

                    <SuperField
                        as="input"
                        label={t('work_factors')}
                        placeholder={t('work_factors')}
                        value={form.work_factors}
                        onChange={(e, { value }) =>
                            setForm((prev) => ({
                                ...prev,
                                work_factors: value,
                            }))
                        }
                    />

                    <SuperField
                        as="input"
                        label={t('work_activity')}
                        placeholder={t('work_activity')}
                        value={form.work_activity}
                        onChange={(e, { value }) =>
                            setForm((prev) => ({
                                ...prev,
                                work_activity: value,
                            }))
                        }
                    />

                    <SuperField
                        as="input"
                        label={t('additional_info')}
                        placeholder={t('additional_info')}
                        value={form.additional_info}
                        onChange={(e, { value }) =>
                            setForm((prev) => ({
                                ...prev,
                                additional_info: value,
                            }))
                        }
                    />
                </>
            )}

            <Divider />
            <Header
                as="h5"
                style={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                    cursor: 'pointer',
                    opacity: '0.8',
                    color: 'var(--primary)',
                }}
                onClick={() => setShowManageRequirements(!showManageRequirements)}
            >
                <Icon
                    name={`chevron-${showManageRequirements ? 'down' : 'forward'}-outline`}
                    style={{ marginRight: '0.5rem', position: 'relative', top: '0.2rem' }}
                />
                <span>{t('manage_position_requirements')}</span>
            </Header>

            {showManageRequirements && (
                <>
                    <Divider />
                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            type="number"
                            label={t('min_age')}
                            value={form.age_lower}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, age_lower: value }))}
                            error={
                                form.age_lower === ''
                                    ? false
                                    : form.age_lower < 0
                                    ? t('value_higher_then_zero')
                                    : parseFloat(form.age_lower) < 15
                                    ? t('min_age_required_is_15')
                                    : false
                            }
                        />
                        <SuperField
                            as="input"
                            type="number"
                            label={t('max_age')}
                            value={form.age_higher}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, age_higher: value }))}
                            error={
                                form.age_higher === ''
                                    ? false
                                    : form.age_higher < 0
                                    ? t('value_higher_then_zero')
                                    : parseFloat(form.age_higher) < 15
                                    ? t('min_age_required_is_15')
                                    : false
                            }
                        />
                        <SuperField
                            as="choice"
                            value={form.gender}
                            type="job_gender_choices"
                            label={t('gender_preference')}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, gender: value }))}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField
                            as="choice"
                            search
                            multiple
                            label={t('education')}
                            value={form.educations}
                            type="education_type_choices"
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, educations: value }))}
                        />
                        <SuperField
                            as="choice"
                            multiple
                            search
                            label={t('driver_licences')}
                            type="driving_licence_choices"
                            value={form.drivers_licences}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, drivers_licences: value }))}
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            type="number"
                            label={t('practice_years')}
                            value={form.practice_years}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, practice_years: value }))}
                        />
                        <SuperField
                            as="choice"
                            search
                            multiple
                            label={t('citizenship')}
                            type="citizenship"
                            value={form.citizenships}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, citizenships: value }))}
                        />
                    </Form.Group>

                    <SuperField
                        as="choice"
                        search
                        multiple
                        label={t('eligibility')}
                        type="eligibility_choices"
                        value={form.eligibilities}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, eligibilities: value }))}
                    />

                    <SuperField
                        as="input"
                        label={t('specialization')}
                        value={form.specialization}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, specialization: value }))}
                    />
                </>
            )}

            <Divider />
            <Header
                as="h5"
                style={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                    cursor: 'pointer',
                    opacity: '0.8',
                    color: 'var(--primary)',
                }}
                onClick={() => setShowManagePositionContractDetails(!showManagePositionContractDetails)}
            >
                <Icon
                    name={`chevron-${showManagePositionContractDetails ? 'down' : 'forward'}-outline`}
                    style={{ marginRight: '0.5rem', position: 'relative', top: '0.2rem' }}
                />
                <span>{t('manage_position_contract_details')}</span>
            </Header>

            {showManagePositionContractDetails && (
                <>
                    <Divider />
                    <SuperField
                        as="choice-select"
                        search
                        text="title"
                        label={t('contract_type')}
                        value={form.contract_type}
                        settings="types/contract-types"
                        endpoint={API.CONTRACTS + 'types/'}
                        onChange={(e, { value }) =>
                            setForm((prev) => ({
                                ...prev,
                                contract_type: value,
                            }))
                        }
                        initialOptions={{
                            attribute: 'title',
                            source: record?.contract_type,
                        }}
                    />
                    <Form.Group widths="equal">
                        <SuperField
                            as="choice"
                            type="contracts_work_levels"
                            label={t('work_level')}
                            value={form.work_level?.toString?.()}
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    work_level: value,
                                }))
                            }
                        />
                        <SuperField
                            as="choice"
                            search
                            type="currency_codes"
                            label={t('currency')}
                            value={form.currency}
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    currency: value,
                                }))
                            }
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            label={t('min_salary')}
                            placeholder={t('min_salary')}
                            value={form.min}
                            error={
                                form.min === ''
                                    ? false
                                    : form.min < 0
                                    ? t('value_higher_then_zero')
                                    : parseFloat(form.min) >= parseFloat(form.max)
                                    ? t('min_lower_then_max')
                                    : false
                            }
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    min: value.replace(/,/g, '.'),
                                }))
                            }
                        />
                        <SuperField
                            as="input"
                            label={t('max_salary')}
                            placeholder={t('max_salary')}
                            value={form.max}
                            error={
                                form.max === ''
                                    ? false
                                    : form.max < 0
                                    ? t('value_higher_then_zero')
                                    : parseFloat(form.max) <= parseFloat(form.min)
                                    ? t('max_higher_then_min')
                                    : false
                            }
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    max: value.replace(/,/g, '.'),
                                }))
                            }
                        />

                        <SuperField
                            as="choice"
                            label={t('salary_type')}
                            type="job_salary_type"
                            value={form.salary_type?.toString?.()}
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    salary_type: value,
                                }))
                            }
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            label={t('variable_remuneration_ratio')}
                            value={form.variable_remuneration_ratio}
                            error={
                                form.variable_remuneration_ratio === ''
                                    ? false
                                    : form.variable_remuneration_ratio < 0
                                    ? t('value_higher_then_zero')
                                    : false
                            }
                            onChange={(e, { value }) =>
                                setForm((prev) => ({ ...prev, variable_remuneration_ratio: value.replace(/,/g, '.') }))
                            }
                        />
                        <SuperField
                            as="input"
                            label={t('variable_remuneration_min_value')}
                            value={form.variable_remuneration_min_value}
                            error={
                                form.variable_remuneration_min_value === ''
                                    ? false
                                    : form.variable_remuneration_min_value < 0
                                    ? t('value_higher_then_zero')
                                    : parseFloat(form.variable_remuneration_min_value) >
                                      parseFloat(form.variable_remuneration_max_value)
                                    ? t('min_lower_then_max')
                                    : false
                            }
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    variable_remuneration_min_value: value.replace(/,/g, '.'),
                                }))
                            }
                        />
                        <SuperField
                            as="input"
                            label={t('variable_remuneration_max_value')}
                            value={form.variable_remuneration_max_value}
                            error={
                                form.variable_remuneration_max_value === ''
                                    ? false
                                    : form.variable_remuneration_max_value < 0
                                    ? t('value_higher_then_zero')
                                    : parseFloat(form.variable_remuneration_min_value) >
                                      parseFloat(form.variable_remuneration_max_value)
                                    ? t('max_higher_then_min')
                                    : false
                            }
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    variable_remuneration_max_value: value.replace(/,/g, '.'),
                                }))
                            }
                        />
                    </Form.Group>
                </>
            )}

            <Divider />
            <Header
                as="h5"
                style={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                    cursor: 'pointer',
                    opacity: '0.8',
                    color: 'var(--primary)',
                }}
                onClick={() => setShowManagePositionSettings(!showManagePositionSettings)}
            >
                <Icon
                    name={`chevron-${showManagePositionSettings ? 'down' : 'forward'}-outline`}
                    style={{ marginRight: '0.5rem', position: 'relative', top: '0.2rem' }}
                />
                <span>{t('manage_position_settings')}</span>
            </Header>

            {showManagePositionSettings && (
                <>
                    <Divider />
                    <CanView permissions={['payrolls.c_view_all_payrolls']}>
                        <SuperField
                            as="choice-select"
                            search
                            multiple
                            text="title"
                            label={t('bonuses')}
                            settings="types/bonuses"
                            value={form.bonuses}
                            endpoint={API.PAYROLLS + 'bonuses/'}
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    bonuses: value,
                                }))
                            }
                            initialOptions={{
                                attribute: 'title',
                                source: record?.bonuses,
                            }}
                        />
                    </CanView>
                    <CanView permissions={['courses.c_view_all_courses']}>
                        <SuperField
                            as="choice-select"
                            search
                            multiple
                            text="title"
                            label={t('required_courses')}
                            settings="types/courses"
                            value={form.required_courses}
                            endpoint={API.COURSES}
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    required_courses: value,
                                }))
                            }
                            initialOptions={{
                                attribute: 'title',
                                source: record?.required_courses,
                            }}
                        />
                    </CanView>
                </>
            )}

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit loading={processing} disabled={processing || !isFormValid()} />
            </Form.Field>
        </Form>
    )
}

export default PositionForm
