import React, { useState, useEffect } from 'react'
import { toast } from 'react-semantic-toasts'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router'
import moment from 'moment'
// store
import { routes } from '@routes'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { useIsMount } from '@helpers/hooks'
// components
import Icon from '@components/Icon'
import AvatarList from '@components/AvatarList'
import { Button, Grid, Dropdown, Divider, Segment, Label, Popup, Card } from 'semantic-ui-react'
import CanView from '@components/perms/CanView'
import SpinnerSegment from '@components/SpinnerSegment'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import LaunchNewRoomForm from '../components/forms/LaunchNewRoomForm'
import ConfirmModal from '@components/modals/ConfrimModal'
import StagesListView from '../components/lists/StagesListView'
import RejectedListView from '../components/lists/RejectedListView'
import BlacklistedListView from '../components/lists/BlacklistedListView'
import ReactionsListView from './ReactionsListView'

const HiringRoomDetail = ({ setItems }) => {
    const { t } = useTranslation()

    const isMount = useIsMount()

    const params = useParams()
    const history = useHistory()
    const [stages, setStages] = useState([])
    const [view, setView] = useState(0)
    const [listView, setListView] = useState(0)
    const [room, setRoom] = useState({})
    const [totalCandidates, setTotalCandidates] = useState(0)
    const [rejected, setRejected] = useState([])
    const [blacklisted, setBlacklisted] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isAdded, setIsAdded] = useState(false)
    const [selectedStage, setSelectedStage] = useState('all')

    async function fetchStages() {
        const request = await requests.get(API.HIRING_ROOM + params.id + '/')
        if (request.status === 200) {
            setRoom(request.response)
            setStages(request.response.archived_recruitment_stages)
            setTotalCandidates(request.response.candidate_count)
            if (isMount) {
                setItems((prev) => [...prev, { name: request.response.name }])
            }
        }
        setIsAdded(false)
    }

    const getDays = (date) => {
        var m = moment(date)
        var today = moment().startOf('day')

        var days = Math.round(moment.duration(m - today).asDays())

        return days
    }

    async function fetchRejected() {
        const request = await requests.get(API.REJECTED + '?recruitment=' + params.id + '&paginate=true')
        if (request.status === 200) setRejected(request.response)
    }
    async function fetchBlacklisted() {
        const request = await requests.get(API.BLACKLISTED + '?recruitment=' + params.id + '&paginate=true')
        if (request.status === 200) setBlacklisted(request.response)
    }

    useEffect(() => {
        async function fetchResource() {
            await fetchStages()
            await fetchRejected()
            await fetchBlacklisted()
            setIsLoading(false)
        }

        fetchResource()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!isMount) {
            if (isAdded) {
                fetchStages()
            }
        }
        // eslint-disable-next-line
    }, [isAdded])

    // eslint-disable-next-line
    const getTargetPercent = (recruited, target) => {
        let x
        x = (recruited * 100) / target
        let result = Number(x.toFixed(1))
        if (isNaN(result)) {
            result = 0
        }
        return result
    }

    const closeRoom = async (id) => {
        const request = await requests.patch(API.HIRING_ROOM + params.id + '/', { is_active: false })

        if (request.status === 200) {
            history.push(routes.HIRING)
        }
    }

    const reOpenRoom = async (id) => {
        const request = await requests.patch(API.HIRING_ROOM + id + '/', { is_active: true })
        if (request.status === 200) {
            setRoom((prev) => ({
                ...prev,
                is_active: true,
            }))

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('hiring_room_was_reopened'),
                animation: 'pulse',
                time: 2000,
            })
        }
    }

    const StageCard = ({ stage, index, isRejected, isBlacklisted, isAll, isActive }) => {
        const handleStageOnClick = () => {
            if (isAll) {
                setSelectedStage('all')
                setListView(0)
                return
            }
            if (isRejected) {
                setSelectedStage('rejected')
                setListView(1)
                return
            }
            if (isBlacklisted) {
                setSelectedStage('blacklisted')
                setListView(2)
                return
            }

            setSelectedStage(stage.id)
            setListView(0)
            return
        }

        const IconMark = ({ icon, color, title }) => {
            return (
                <Popup
                    content={title}
                    position="top center"
                    trigger={<Icon name={icon} style={{ color: color, cursor: 'pointer' }} />}
                />
            )
        }

        return (
            <Grid.Column>
                <Card
                    as="button"
                    fluid
                    onClick={() => handleStageOnClick()}
                    style={{
                        borderRadius: '0rem',
                        border: isActive ? `1px solid ${stage.color || 'var(--variant2)'}` : 'inherit',
                        background: stage.color !== '' ? stage.color + '33' : 'var(--dark)',
                        borderBottom: '2px solid ' + (stage.color !== '' ? stage.color : 'var(--dark)'),
                        cursor: 'pointer',
                    }}
                >
                    <Grid>
                        <Grid.Row style={{ padding: 0 }} columns={2}>
                            <Grid.Column
                                width="11"
                                style={{ paddingLeft: '1rem', textAlign: 'left', marginTop: '1rem' }}
                            >
                                <Card.Header style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                    {' '}
                                    {stage.candidate_count}
                                    {room?.archived_recruitment_stages.length === index + 1 && room?.target > 0 && (
                                        <span style={{ opacity: '0.8', fontWeight: 'normal' }}>
                                            <span>
                                                {' '}
                                                {'/'} {room?.target || 0}
                                                {/*<small>({getTargetPercent(stage.candidate_count, room.target)}%)</small>*/}
                                            </span>
                                        </span>
                                    )}
                                </Card.Header>
                            </Grid.Column>
                            <Grid.Column width="4" style={{ textAlign: 'right', marginTop: '1rem' }}>
                                <Icon
                                    name={stage.icon}
                                    style={{
                                        color: 'var(--dark)',
                                        fontSize: '2rem',
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ padding: 0, paddingBottom: '1rem', marginLeft: '1rem', textAlign: 'left' }}>
                            <Card.Description style={{ marginTop: '0.5rem' }}>
                                {stage?.id === undefined && <div>&nbsp;</div>}
                                <div>
                                    {stage.name}{' '}
                                    {stage?.is_approval_required === true && (
                                        <IconMark icon="information-circle-outline" title={t('approval_stage')} />
                                    )}
                                </div>
                                <div>
                                    {stage?.id && (
                                        <small style={{ color: 'var(--variant3)' }}>
                                            {t('stage')} {index + 1}
                                            {room?.archived_recruitment_stages.length === index + 1 &&
                                                ' (' + t('final_stage') + ')'}
                                        </small>
                                    )}
                                </div>
                            </Card.Description>
                        </Grid.Row>
                    </Grid>
                </Card>
            </Grid.Column>
        )
    }

    return (
        <SpinnerSegment loading={isLoading}>
            <Grid>
                <Grid.Row verticalAlign="middle" style={{ padding: 0 }}>
                    <Grid.Column computer={3} tablet={3} mobile={16}>
                        <Button.Group size="small" style={{ borderRadius: '0' }} basic>
                            <Button
                                active={view === 0}
                                onClick={() => {
                                    setView(0)
                                }}
                            >
                                {' '}
                                {t('recruitment')}{' '}
                            </Button>
                            <Button active={view === 1} onClick={() => setView(1)}>
                                {t('reactions')}
                                {room?.new_reactions_count > 0 && (
                                    <Label
                                        color="red"
                                        floating
                                        circular
                                        style={{ left: 'auto', margin: '0!important' }}
                                    >
                                        {'+' + room.new_reactions_count}
                                    </Label>
                                )}
                            </Button>
                        </Button.Group>
                    </Grid.Column>
                    <Grid.Column
                        computer={13}
                        tablet={13}
                        mobile={16}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'right',
                        }}
                    >
                        <div
                            style={{
                                marginRight: '0.5rem',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            {room.labels?.length > 0 && (
                                <div style={{ marginRight: '1.5rem', marginBottom: '0.5rem' }}>
                                    {room?.labels?.map((label, idx) => (
                                        <Label
                                            basic
                                            key={label.id}
                                            style={{
                                                background: label.color === '' ? 'var(--dark)' : label.color + '40',
                                                padding: '0.5rem',
                                                marginTop: '0.5rem',
                                                marginLeft: idx === 0 ? 'unset' : '0.25rem',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    position: 'relative',
                                                    top: '-0.05rem',
                                                    fontSize: '0.8rem',
                                                    color: 'var(--dark)',
                                                }}
                                            >
                                                {label.name}
                                            </span>
                                        </Label>
                                    ))}
                                </div>
                            )}
                            {room.recruiters?.length > 0 && (
                                <div style={{ position: 'relative', top: '-0.1rem' }}>
                                    <AvatarList
                                        size="25"
                                        alignDirection="start"
                                        persons={room.recruiters?.map((item) => ({
                                            ID: item?.id,
                                            id: item?.id,
                                            name: item?.fullname,
                                            avatar: item?.profile_picture,
                                            displayName: item?.fullname,
                                        }))}
                                    />
                                </div>
                            )}
                            <div style={{ marginRight: '1.5rem' }}>
                                <div
                                    style={{ position: 'relative', opacity: '0.7', top: '-0.1rem', fontSize: '0.8rem' }}
                                >
                                    {t('due_date')}
                                </div>
                                <Popup
                                    position="left center"
                                    trigger={
                                        <strong style={{ position: 'relative', top: '-0.3rem', cursor: 'default' }}>
                                            {room.date_to ? (
                                                moment(room.date_to).format('DD.MM.YYYY') +
                                                (getDays(room.date_to) >= 0
                                                    ? ', ' + t('in') + ' ' + getDays(room.date_to) + ' ' + t('days')
                                                    : ', ' +
                                                      t('before') +
                                                      ' ' +
                                                      Math.abs(getDays(room.date_to)) +
                                                      ' ' +
                                                      t('dayss'))
                                            ) : (
                                                <span style={{ opacity: '0.5' }}>{t('not_specified')}</span>
                                            )}
                                        </strong>
                                    }
                                    content={
                                        <div>
                                            <strong>{t('starts')}:</strong>{' '}
                                            {room.date_from ? (
                                                moment(room.date_from).format('DD.MM.YYYY')
                                            ) : (
                                                <span style={{ opacity: '0.5' }}>{t('not_specified')}</span>
                                            )}{' '}
                                            <br />
                                            <strong>{t('ends')}:</strong>{' '}
                                            {room.date_to ? (
                                                moment(room.date_to).format('DD.MM.YYYY')
                                            ) : (
                                                <span style={{ opacity: '0.5' }}>{t('not_specified')}</span>
                                            )}
                                        </div>
                                    }
                                />
                            </div>

                            <div style={{ marginRight: '1.5rem' }}>
                                <div
                                    style={{ position: 'relative', opacity: '0.7', top: '-0.1rem', fontSize: '0.8rem' }}
                                >
                                    {t('status')}
                                </div>
                                <>
                                    {room.is_active ? (
                                        <strong style={{ position: 'relative', top: '-0.2rem', color: 'var(--dark)' }}>
                                            {t('active_hiring')}
                                        </strong>
                                    ) : (
                                        <strong style={{ position: 'relative', top: '-0.2rem', color: 'var(--dark)' }}>
                                            {t('inactive_hiring')}
                                        </strong>
                                    )}
                                </>
                            </div>
                        </div>
                        <CanView permissions={['candidates.c_manage_recruitment']}>
                            <Button.Group size="small" style={{ borderRadius: '0' }}>
                                <Button.Group>
                                    <Button
                                        style={{ background: 'var(--dark)', color: 'var(--white)', cursor: 'default' }}
                                    >
                                        {t('manage_hiring_room')}
                                    </Button>
                                    <Dropdown
                                        simple
                                        style={{
                                            background: 'var(--dark-hover)',
                                            color: 'var(--white)',
                                            marginRight: '0.5rem',
                                        }}
                                        className="button icon"
                                        floating
                                        trigger={<Icon name="settings-outline" style={{ marginRight: '0.5rem' }} />}
                                    >
                                        <Dropdown.Menu style={{ marginTop: '0', zIndex: 1000 }}>
                                            <SuperDuperModal
                                                header={t('edit_room')}
                                                size="small"
                                                trigger={
                                                    <Dropdown.Item>
                                                        <Icon
                                                            name="settings-outline"
                                                            style={{ marginRight: '0.5rem' }}
                                                        />
                                                        <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                            {t('edit_hiring_room')}
                                                        </span>
                                                    </Dropdown.Item>
                                                }
                                                content={
                                                    <LaunchNewRoomForm
                                                        room={room}
                                                        btnName={t('confirm')}
                                                        onConfirm={(response) => setRoom(response)}
                                                    />
                                                }
                                            />

                                            {room.is_active ? (
                                                <ConfirmModal
                                                    button={
                                                        <Dropdown.Item
                                                            style={{
                                                                background: 'var(--danger)',
                                                                color: 'var(--white)',
                                                            }}
                                                        >
                                                            <Icon
                                                                name="close-outline"
                                                                style={{ marginRight: '0.5rem' }}
                                                            />
                                                            <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                                {t('end_hiring')}
                                                            </span>
                                                        </Dropdown.Item>
                                                    }
                                                    description={t('end_hiring_message')}
                                                    onConfirm={() => closeRoom(room.id)}
                                                />
                                            ) : (
                                                <ConfirmModal
                                                    button={
                                                        <Dropdown.Item
                                                            style={{ background: 'var(--dark)', color: 'var(--white)' }}
                                                        >
                                                            <Icon
                                                                name="arrow-undo-circle-outline"
                                                                style={{ marginRight: '0.5rem' }}
                                                            />
                                                            <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                                {t('reopen_room')}
                                                            </span>
                                                        </Dropdown.Item>
                                                    }
                                                    description={t('are_you_sure_that_you_want_to_reopen_this_room')}
                                                    onConfirm={() => reOpenRoom(room.id)}
                                                />
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Button.Group>
                            </Button.Group>
                        </CanView>
                    </Grid.Column>
                </Grid.Row>
                <Divider />
            </Grid>

            {view === 0 && (
                <>
                    <Segment
                        loading={isLoading}
                        style={{
                            padding: 0,
                            margin: 0,
                            minHeight: '2rem',
                            backgroundColor: 'transparent',
                            border: 'none',
                            boxShadow: 'none',
                        }}
                    >
                        {!isLoading && (
                            <Grid stackable stretched>
                                <Grid.Row verticalAlign="middle" columns="equal" style={{ padding: 0 }}>
                                    <StageCard
                                        isActive={selectedStage === 'all'}
                                        isAll
                                        stage={{
                                            icon: 'people-outline',
                                            color: '#0D3C62',
                                            name: t('active_applicants'),
                                            candidate_count: room.candidate_count - (rejected?.count || 0),
                                        }}
                                    />

                                    {room.archived_recruitment_stages.map((stage, index) => (
                                        <StageCard
                                            isActive={selectedStage === stage.id}
                                            stage={stage}
                                            index={index}
                                            key={stage.id}
                                        />
                                    ))}

                                    {/*<StageCard
                                        isActive={selectedStage === 'rejected'}
                                        isRejected
                                        stage={{
                                            icon: 'close-circle-outline',
                                            color: '#FB4A76',
                                            name: t('rejected_plural'),
                                            candidate_count: rejected?.results?.length || 0,
                                        }}
                                    />
                                    <StageCard
                                        isActive={selectedStage === 'blacklisted'}
                                        isBlacklisted
                                        stage={{
                                            icon: 'warning-outline',
                                            color: '#000000',
                                            name: t('blacklisted'),
                                            candidate_count: blacklisted?.results?.length || 0,
                                        }}
                                    />*/}
                                </Grid.Row>
                            </Grid>
                        )}
                    </Segment>
                    <Divider />
                    {listView === 0 && (
                        <StagesListView
                            key={selectedStage}
                            params={params}
                            room={room}
                            stage={selectedStage}
                            stages={stages}
                            setStages={setStages}
                            rejected={rejected}
                            setRejected={setRejected}
                            blacklisted={blacklisted}
                            setBlacklisted={setBlacklisted}
                            totalCandidates={totalCandidates}
                            listView={listView}
                            setListView={setListView}
                            setIsAdded={setIsAdded}
                            setRejectedState={setSelectedStage}
                            setBlacklistStage={setSelectedStage}
                            setAllStage={setSelectedStage}
                        />
                    )}

                    {listView === 1 && (
                        <RejectedListView
                            params={params}
                            stages={stages}
                            setStages={setStages}
                            rejected={rejected}
                            setRejected={setRejected}
                            blacklisted={blacklisted}
                            setBlacklisted={setBlacklisted}
                            totalCandidates={totalCandidates}
                            listView={listView}
                            setListView={setListView}
                            setRejectedState={setSelectedStage}
                            setBlacklistStage={setSelectedStage}
                            setAllStage={setSelectedStage}
                        />
                    )}

                    {listView === 2 && (
                        <BlacklistedListView
                            params={params}
                            stages={stages}
                            setStages={setStages}
                            rejected={rejected}
                            setRejected={setRejected}
                            blacklisted={blacklisted}
                            setBlacklisted={setBlacklisted}
                            totalCandidates={totalCandidates}
                            listView={listView}
                            setListView={setListView}
                            setRejectedState={setSelectedStage}
                            setBlacklistStage={setSelectedStage}
                            setAllStage={setSelectedStage}
                        />
                    )}
                </>
            )}
            {view === 1 && <ReactionsListView params={params} setRoom={setRoom} />}
        </SpinnerSegment>
    )
}

export default HiringRoomDetail
