import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
// components
import SuperField from '@components/forms/SuperField';
import { Header, Grid } from 'semantic-ui-react';
import { Line } from 'react-chartjs-2';
import Loading from '@components/general/Loading';

const CandidatesStageTime = ({ room }) => {
    const { t } = useTranslation()
    const [selectedRoom, setSelectedRoom] = useState(room ? room : "")
    const [selectedCandidate, setSelectedCandidate] = useState("")
    const [selectedTime, setSelectedTime] = useState("")
    const [barLabels, setBarLabels] = useState("")
    const [barData, setBarData] = useState("")
    const [barDataM, setBarDataM] = useState("")
    const [barDataH, setBarDataH] = useState("")
    const [barDataD, setBarDataD] = useState("")
    const [loading, setLoading] = useState(false)
    const [loadingOptions, setLoadingOptions] = useState(false)
    const [listOfCandidates, setListOfCandidates] = useState([])


    useEffect( () => {
        async function fetchCandidates(){
            setLoadingOptions(true)
            const request = await getRequest(API.CANDIDATES + "?recruitment=" + selectedRoom)
            if(request.status === 200){
                const candidates = []
                for (let i = 0; i < request.response.length; i++ ){
                    candidates.push(
                        { key: request.response[i].id, text: request.response[i].fullname, value: request.response[i].id },
                    )
                }
                setListOfCandidates(candidates)
            }
            setLoadingOptions(false)
        }

        fetchCandidates()
    }, [selectedRoom])

    useEffect(() => {
        async function fetchHiringStats(){
            if(selectedRoom !== "" && selectedCandidate !== ""){
                setLoading(true)
                const request = await getRequest(API.CANDIDATES + "recruitments/" + selectedRoom)
                if(request.status === 200){
                    const l = []
                    const d = []
                    let hiring = request.response.archived_recruitment_stages
                    for (let x = 0; x < hiring.length; x++ ){
                        l.push(
                            hiring[x].name
                        )
                        const timeS = await getRequest(API.CANDIDATES + "archived_recruitment_stages/"+hiring[x].id + "/?candidate="+selectedCandidate)
                        if(timeS.status === 200){
                            d.push(
                                timeS.response.candidate_time_in_stage
                            )}
                        }
                    setBarLabels(l)
                    setBarData(d)
                }
                setLoading(false)

            }
        }
        fetchHiringStats()
    }, [selectedRoom, selectedCandidate, selectedTime])

    const timeOptions = [
        { key: 0, text: t('seconds'), value: 0 },
        { key: 1, text: t('minutes'), value: 1 },
        { key: 2, text: t('hours'), value: 2 },
        { key: 3, text: t('days'), value: 3 },
    ]

    useEffect(() => {
        if(selectedTime===1){
            let newData=[];
            for (let x = 0; x < barData.length; x++ ){
                newData.push(
                    Math.round((barData[x]/60) * 100) / 100
                )
            }
            setBarDataM(newData)
        }

        if(selectedTime===2){
            let newData=[];
            for (let x = 0; x < barData.length; x++ ){
                newData.push(
                    Math.round((barData[x]/3600) * 100) / 100
                )
            }
            setBarDataH(newData)
        }

        if(selectedTime===3){
            let newData=[];
            for (let x = 0; x < barData.length; x++ ){
                newData.push(
                    Math.round((barData[x]/86400) * 100) / 100
                )
            }
            setBarDataD(newData)
        }

    // eslint-disable-next-line
    }, [selectedTime])

    const dataLine = {
        labels: barLabels,
        datasets: [
          {
            label: t('time'),
            fill: false,
            lineTension: 0.2,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 5,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: selectedTime === 1 ? barDataM : selectedTime === 2 ? barDataH : selectedTime === 3 ? barDataD : barData,
          }
        ]
    };

    const lineOptions = {
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            gridLines: {
              display: false,
            },
            ticks: {
              beginAtZero: true,
              userCallback(value) {
                value = value.toString();
                value = value.split(/(?=(?:...)*$)/);
                value = value.join('.');
                return selectedTime===1 ?  t('min')+`${value}` : selectedTime === 2  ? t('hours_')+`${value}`: selectedTime === 3 ? t('days_')+`${value}` : t('sec')+`${value}`  ;
              },
            },
          }],
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
        },
      };

    return (
        <>
            <Grid>
                <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={7}>
                    <Header as="h2" textAlign="left" content={t('candidate_stage_time')} style={{marginTop:"1rem"}}/>
                </Grid.Column>
                <Grid.Column  mobile={16} tablet={16} computer={3} style={{padding:0}}>
                    <SuperField as="choice"
                        search
                        loading={loading}
                        label={t('hiring_room')}
                        endpoint={API.HIRING_ROOM + "?query={id, name}"}
                        text={"name"}
                        value={ parseInt(selectedRoom) }
                        onChange={ (e, { value }) => setSelectedRoom(value) }
                    />
                </Grid.Column>
                {selectedRoom &&
                    <Grid.Column mobile={16} tablet={16} computer={3} style={{padding:0}}>
                        <SuperField as="choice"
                            search
                            label={t('candidate')+":"}
                            loading={loadingOptions}
                            customOptions={listOfCandidates}
                            value={ parseInt(selectedCandidate) }
                            onChange={ (e, { value }) => setSelectedCandidate(value) }
                        />
                    </Grid.Column>
                }
                {selectedRoom && selectedCandidate &&
                    <Grid.Column  mobile={16} tablet={16} computer={3} style={{padding:0}}>
                        <SuperField as="choice"
                            label={t('time_unit')}
                            customOptions={timeOptions}
                            value={ parseInt(selectedTime) }
                            onChange={ (e, { value }) => setSelectedTime(value) }
                        />
                    </Grid.Column>
                }
                </Grid.Row>
                </Grid>

                { selectedRoom === "" && <Grid centered> <Header as="h2" content={t('select_hiring_room_first')} textAlign="center"/> </Grid> }
                { selectedRoom !== "" &&selectedCandidate === "" && <Grid centered> <Header as="h2" content={t('select_candidate')} textAlign="center"/> </Grid> }
                {/* { selectedRoom !== "" &&selectedCandidate !== "" && selectedTime === "" && <Grid centered> <Header as="h2" content="Select Time Unit" textAlign="center"/> </Grid> } */}

                 { barData !== "" && barLabels  !== "" &&
                    <Grid centered >
                        <Grid.Column computer={10} tablet={16} mobile={16} style={{paddingLeft:0, paddingRight: 0}}>
                            { loading ? <Loading/> : <Line data={dataLine} options={lineOptions} /> }
                         </Grid.Column>
                    </Grid>
                 }


        </>
    );

};

export default CandidatesStageTime;