import React from "react";
//components
import Icon from '@components/Icon';

const MyIcon = (props) => {

    return props.state ? (
        <Icon
            name="checkmark-circle-outline"
            style={{ fontSize: "2.5rem", marginRight: "1rem" }}
        />
    ) : (
        <Icon
            name="close-circle-outline"
            style={{ fontSize: "2.5rem", marginRight: "1rem" }}
        />
    );
};

export default MyIcon;
