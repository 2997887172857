import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { hiringService } from "@services/ServiceHiring";
import { useHasPermissions } from '@helpers/hooks';
// components
import { Grid, Segment, Comment, Header, Tab, Message, Rating, Divider, Checkbox } from "semantic-ui-react";
import CommentList from "../comments/CommentList";
import CommentForm from "../comments/CommentForm";
import Loading from "@components/general/Loading";
import HistoryStageRecordList from "../activity-log/HistoryStageRecordList";
import CanView from '@components/perms/CanView';

const StageComponent = ({ room, rejected, blacklisted, candidate, data, setCandidate, currentView }) => {
    const { t } = useTranslation();

    const [history, setHistory] = useState([])
    const [comments, setComments] = useState([])
    const [requirements, setRequirements] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    const user = useSelector(state => state.user)
    const canViewHistoryTab = useHasPermissions(['candidates.c_view_history_records']);

    const [rating, setRating] = useState(0)

    useEffect(() => {
        async function fetchData() {
            const resultRequirements = await requests.get(API.CANDIDATES + "requirement_instances/?profile=" + candidate + "&archived_stage=" + currentView);

            if (resultRequirements.status === 200) {
                setRequirements(resultRequirements.response);
            }
        };

        fetchData();

        // eslint-disable-next-line
    }, [currentView]);

    const handleRate = async (rating) => {
        if (currentView) {
            const result = await hiringService.setRating(candidate, {
                rating: rating,
                recruitment: room,
                archived_recruitment_stage: currentView
            })
    
            if (result.status === 200 || result.status === 201) {
                setCandidate({
                    ...data,
                    average_rating: result.response.average_in_this_recruitment
                })
                setRating(result.response.rating)
            }
        }
    }

    const loadUserRating = async () => {
        if (currentView) {
            const result = await hiringService.getHistory(candidate, "?recruitment=" + room + "&archived_recruitment_stage=" + currentView + "&created_by=" + user.profile_id + "&action=3")
    
            if (result.status === 200) {
                if (result.response.length) {
                    setRating(result.response[0].rating)
                } else {
                    setRating(0)
                }
            }
        }
    }

    const loadComments = async () => {
        if (currentView) {
            const result = await hiringService.getComments(candidate, "?action=2&archived_recruitment_stage=" + currentView + "&recruitment=" + room)
            if (result.status === 200) {
                let commentsArray = [];
                for (let i = 0; i < result.response.length; i++) {
                    commentsArray.push({
                        note: result.response[i].note,
                        created_on: result.response[i].created_on,
                        created_by: result.response[i].created_by ? result.response[i].created_by.name : ""
                    });
    
                }
                setComments(commentsArray)
            }
        }
    }

    const loadHistoryLog = async () => {
        if (currentView) {
            const result = await hiringService.getHistory(candidate, "?archived_recruitment_stage=" + currentView + "&recruitment=" + room)

            if (result.status === 200) {
                setHistory(result.response)
            }
        }
    }

    const completeTask = async (isChecked,id) => {
        const result = await requests.patch(API.CANDIDATES + 'requirement_instances/' + id + "/", {
            is_completed: isChecked ? false : true,
            archived_stage: currentView
        });

        if (result.status === 200) {
            setRequirements((prev) => prev.map((item) => {
                if (item.id === result.response.id) {
                    item = result.response
                }
                return item
            }))
        }
    }

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            await loadComments();
            await loadHistoryLog();
            await loadUserRating();
            setIsLoading(false);
        };

        fetchData();
        // eslint-disable-next-line
    }, [candidate, currentView, room]);

    const onConfirm = async (newItem) => {
        setComments([{
            note: newItem.note,
            created_on: newItem.created_on,
            created_by: newItem.created_by ? newItem.created_by.name : ""
        }, ...comments])

        await loadHistoryLog()
    }

    const CommentSection = () => {
        return (
            <div style={{ padding: "1rem" }}>
                <Comment.Group>
                    <Header as='h3'>
                        {t('comments')}
                    </Header>
                    <CommentForm
                        hideForm={blacklisted || rejected}
                        recruitmentId={room}
                        id={candidate}
                        stage={currentView}
                        onConfirm={(new_item) => onConfirm(new_item)}
                    />
                    {isLoading ? <Loading /> : <CommentList comments={comments} />}
                </Comment.Group>

            </div>
        );
    }

    const ActivityLogSection = () => {
        return (
            <>
                {isLoading ? <Loading /> : <HistoryStageRecordList history={history} setHistory={setHistory} />}
            </>
        );
    }

    const GeneralSection = () => {
        return (
            <>
                <CanView permissions={["candidates.c_manage_candidates_in_recruitment"]}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer={16} tablet={16} mobile={16}>
                                <Message info size="large">
                                    {t('set_candidate_rating')}
                                    <Rating
                                        size="huge"
                                        style={{ float: "right" }}
                                        disabled={blacklisted || rejected}
                                        rating={rating}
                                        maxRating={5}
                                        onRate={(e, data) => handleRate(data.rating)}
                                    />
                                </Message>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Divider />
                </CanView>

                <Header as="h3" content={t('requirements')} />
                
                    {requirements?.length > 0 ?
                        requirements?.map((task) => (
                            <div style={{ marginBottom: 0, marginTop: "0.3rem", opacity: task.is_completed && "0.6" }}>
                                <Checkbox checked={task.is_completed} onChange={() => completeTask(task.is_completed,task.id)} label={task.config.task} style={{ fontWeight: "bold" }} />
                                {task.config.is_required && <span style={{ color: "red", fontWeight: "bold" }}>*</span>}
                                <div style={{ paddingLeft: "1.8rem", fontWeight: "lighter", color: "GrayText" }}>{task.config.description}</div>
                            </div>
                        ))
                    : t('no_requirements')
                    }
                
            </>
        );
    }

    const panes = [
        {
            menuItem: t('general'),
            render: () => <Tab.Pane className="stage-panes" active attached={false}><GeneralSection /></Tab.Pane>,
        },
        // {
        //     menuItem: t('attachments'),
        //     render: () => <Tab.Pane className="stage-panes" attached={false}><Grid centered> {t('no_attachments')}</Grid></Tab.Pane>,
        // },
        {
            menuItem: canViewHistoryTab && t('activity_log'),
            render: () => <Tab.Pane className="stage-panes" attached={false}><ActivityLogSection /></Tab.Pane>,
        }
    ]

    return (
        <div style={{ marginTop: "1rem" }}>
            <Segment loading={isLoading}>
                <Grid divided>
                    <Grid.Column
                        computer={10}
                        tablet={10}
                        mobile={16}
                    >
                        <Tab menu={{
                            secondary: true,
                            pointing: true
                        }} panes={panes} />
                    </Grid.Column>
                    <Grid.Column computer={6} tablet={6} mobile={16}>
                        <CanView permissions={["candidates.c_view_history_records"]}>
                            <CommentSection />
                        </CanView>
                    </Grid.Column>
                </Grid>
            </Segment>
        </div>
    );
};
export default StageComponent;
