import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import { Form, Divider, Header, Button } from 'semantic-ui-react'

const TaskTemplateConfigurator = ({ onClose }) => {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(false)
    const [templates, setTemplates] = useState([])
    const [processing, setProcessing] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState('')
    const [isNew, setIsNew] = useState(false)
    const [title, setTitle] = useState('')
    const [tasks, setTasks] = useState([''])

    useEffect(() => {
        async function fetchTemplates() {
            setLoading(true)
            const request = await requests.get(API.FOREIGNERS + 'task_templates/')
            if (request.status === 200) {
                setTemplates(request.response)
            }

            setLoading(false)
        }

        fetchTemplates()
    }, [])

    const isValidForm = () => {
        if (title === '') return false
        if (tasks.length === 0) return false

        return true
    }

    const onTemplateDelete = async () => {
        setProcessing(true)
        const request = await requests.del(API.FOREIGNERS + 'task_templates/' + selectedTemplate + '/')
        if (request.status === 204) {
            setTemplates((prev) => prev.filter((item) => item.id !== selectedTemplate))
            setSelectedTemplate('')
            setTitle('')
            setTasks([''])

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('task_template_removed'),
                animation: 'pulse',
                time: 2000,
            })
        }
        setProcessing(false)
    }

    const handleSelection = (selected) => {
        const template = templates.find((item) => item.id === selected)
        if (template) {
            setSelectedTemplate(template.id)
            setTitle(template.title)
            let tasksArray = template.tasks.split('__')
            if (tasksArray.length > 0) {
                setTasks(tasksArray)
            } else {
                setTasks([''])
            }
        }
    }

    const clearSelection = () => {
        setSelectedTemplate('')
        setTitle('')
        setTasks([''])
        setIsNew(false)
    }

    const handleSubmit = async () => {
        setProcessing(true)

        if (selectedTemplate === '' && isNew === true) {
            let tasks_config = tasks.filter((task) => task !== '').join('__')
            const request = await requests.post(API.FOREIGNERS + 'task_templates/', {
                title: title,
                tasks: tasks_config,
            })

            if (request.status === 201) {
                setTemplates((prev) => [...prev, request.response])
                clearSelection()
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('task_template_created'),
                    animation: 'pulse',
                    time: 2000,
                })
            }
        } else if (selectedTemplate !== '' && isNew === false) {
            let tasks_config = tasks.filter((task) => task !== '').join('__')
            const request = await requests.patch(API.FOREIGNERS + 'task_templates/' + selectedTemplate + '/', {
                title: title,
                tasks: tasks_config,
            })

            if (request.status === 200) {
                setTemplates((prev) =>
                    prev.map((item) => {
                        if (item.id === request.response.id) {
                            item = request.response
                        }
                        return item
                    })
                )
                clearSelection()

                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('task_template_updated'),
                    animation: 'pulse',
                    time: 2000,
                })
            }
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            {!isNew && (
                <SuperField
                    as="choice"
                    disabled={loading}
                    value={selectedTemplate}
                    label={t('select_existing_template_to_update')}
                    onChange={(e, { value }) => {
                        if (value === '') {
                            setTitle('')
                            setTasks([''])
                            setSelectedTemplate('')
                        } else {
                            handleSelection(value)
                        }
                    }}
                    customOptions={templates.map((item) => ({ key: item.id, value: item.id, text: item.title }))}
                />
            )}

            {selectedTemplate === '' && (
                <SuperField
                    as="checkbox"
                    checked={isNew}
                    label={t('create_new_task_template')}
                    onChange={() => {
                        setIsNew(!isNew)
                        if (isNew === false) {
                            setTitle('')
                            setTasks([''])
                        }
                    }}
                />
            )}

            {(isNew || selectedTemplate) && (
                <>
                    <Divider />
                    <SuperField
                        as="input"
                        required
                        autoFocus
                        value={title}
                        label={t('title')}
                        onChange={(e, { value }) => setTitle(value)}
                    />

                    <Header as="h3" content={t('tasks')} />
                    {tasks.map((item, idx) => (
                        <Form.Group key={idx}>
                            <Form.Field
                                width="1"
                                style={{
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    fontSize: '1.2rem',
                                    marginTop: '0.5rem',
                                }}
                            >
                                {idx + 1}.
                            </Form.Field>
                            <SuperField
                                width="14"
                                as="input"
                                value={item}
                                onChange={(e, { value }) =>
                                    setTasks((prev) =>
                                        prev.map((task, index) => (index === idx ? value.replaceAll('__', '') : task))
                                    )
                                }
                            />
                            <Form.Field
                                width="1"
                                style={{
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    fontSize: '1.5rem',
                                    marginTop: '0.5rem',
                                }}
                            >
                                <Icon
                                    name="close-outline"
                                    style={{ cursor: 'pointer', color: 'var(--danger)' }}
                                    onClick={() => setTasks((prev) => prev.filter((item, index) => index !== idx))}
                                />
                            </Form.Field>
                        </Form.Group>
                    ))}

                    <Divider />
                    <Button
                        primary
                        type="button"
                        size="small"
                        content={t('add_task')}
                        onClick={() => setTasks((prev) => [...prev, ''])}
                    />
                </>
            )}

            <Divider />

            <Form.Field style={{ textAlign: 'right' }}>
                {selectedTemplate !== '' && (
                    <Button
                        type="button"
                        style={{ background: 'var(--danger)', color: 'var(--white)' }}
                        content={t('remove_template')}
                        onClick={() => onTemplateDelete(selectedTemplate)}
                    />
                )}
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={processing}
                    disabled={(selectedTemplate === null && isNew === false) || processing || !isValidForm()}
                />
            </Form.Field>
        </Form>
    )
}

export default TaskTemplateConfigurator
