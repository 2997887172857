import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
import { Link } from 'react-router-dom';
import moment from 'moment';
// store 
import { routes } from '@routes';
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { isEmpty } from '@helpers/validation';
import { useSelectOptions } from '@helpers/hooks';
import { setDotSeparator, thousandsSeparators } from '@helpers/functions';
// components 
import EmptyRow from '@components/tables/EmptyRow';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import { Form, Divider, Grid, Header, Button, Icon, Loader, Popup, Segment } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';

const calculateTaxes = (invoice) => {
    let summary = {
        total: 0,
        total_excluding_tax: 0
    }

    let total = 0
    let total_excluding_tax = 0
    for (let i = 0; i < invoice?.taxes?.length; i++) {
        total += parseFloat(invoice?.taxes?.[i]?.sum)
        total_excluding_tax += parseFloat(invoice?.taxes?.[i]?.sum_excluding_tax)
    }

    let float_total = parseFloat(total).toFixed(2)
    let float_total_excluding = parseFloat(total_excluding_tax).toFixed(2)
    summary.total = float_total < 0 ? (float_total * (-1)) : float_total
    summary.total_excluding_tax = float_total_excluding < 0 ? (float_total_excluding * (-1)) : float_total_excluding

    return summary
}

const InvoiceDivideToCC = ({ invoice, connection, setData, onClose }) => {
    const { t } = useTranslation()
    const [isFetching, setIsFetching] = useState(true)
    const [isProcessing, setIsProcessing] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [costCenters, setCostCenters] = useSelectOptions(API.COST_CENTERS + "?query={id, title}", "title")
    const [records, setRecords] = useState([])
    const [settings, setSettings] = useState({
        wasSplitted: false,
        tax: 1,
        split_definition: 2
    })

    const [categories, setCategories] = useState([])
    const calculatedTaxes = calculateTaxes(invoice)

    const [difference, setDifference] = useState({
        diff: 0,
        percentage: 0
    })

    useEffect( () => {
        async function loadCategories(){
            setIsLoading(true)
            const request = await requests.get(API.COST_CENTERS_RECORD_CATEGORIES)
            
            if( request.status === 200 ) {
                setCategories(request.response)
            }
            setIsLoading(false)
        }

        async function fetchRecords() {
            if( invoice.price_display !== null && invoice.price_display !== "" ){
                setIsFetching(true)
                const request = await requests.get(API.COST_CENTERS_RECORDS + "?invoice_reference=" + (invoice.id + "__" + invoice.number) )
                if (request.status === 200 ) {
                    let items = []
                    const response = request.response?.results || []
                    for (let i = 0; i < response.length; i++) {
                        items.push({
                            id: response[i].id,
                            title: response[i].title,
                            amount: response[i].amount.sum,
                            date: response[i].date,
                            category: response[i].category?.id,
                            cost_center: response[i].cost_center,
                            invoice_reference: response[i].invoice_reference,
                            processing: false
                        })
                    }

                    let taxMode = 1
                    if( response.length > 0 ){
                        let referenceValues = response[0].invoice_reference.split("__")
                        let taxSetting = referenceValues[2].split(":")
                        taxMode = taxSetting[1] !== undefined ? parseInt(taxSetting[1]) : 1
                    } else {
                        items.push({ 
                            id: 0,
                            type: invoice.is_issued ? 1 : 2,
                            title: renderName(),
                            amount: settings.tax === 1 ? calculatedTaxes.total_excluding_tax : calculatedTaxes.total,
                            date: invoice.date_supply !== null ? moment(invoice.date_supply).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                            category: "",
                            cost_center: "",
                            processing: false
                        })
                    }

                    setSettings(prevState => ({
                        ...prevState,
                        wasSplitted: response.length > 0,
                        tax: taxMode
                    }))

                    setRecords(items)
                }
            }
            setIsFetching(false)
        }


        fetchRecords()
        loadCategories()
        // calculateTaxes()
        // eslint-disable-next-line
    }, [])

    useEffect( () => {
        calculateDiff()
        // eslint-disable-next-line
    }, [records])

    

    const calculateRecordSum = () => {
        let sum = 0
        for (let i = 0; i < records.length; i++) {
            if( records[i].amount > 0 ){
                sum += parseFloat(records[i].amount)
            } else {
                sum += parseFloat(0)
            }
        }

        return sum
    }

    const calculateDiff = async () => {
        let diff = 0
        let percentage = 0
        let dividedSum = calculateRecordSum()
        let totalSum = settings.tax === 1 ? calculatedTaxes.total_excluding_tax : calculatedTaxes.total
        diff = parseFloat(parseFloat(totalSum).toFixed(2) - parseFloat(dividedSum)).toFixed(2)

        if( diff <= 0 ) {
            diff = 0
        } else {
            percentage = parseFloat((diff / totalSum) * 100).toFixed(2)
        }

        setDifference({
            diff: diff,
            percentage: percentage
        })
    }

    const handleSubmit = async () => {
        setIsProcessing(true)

        let divided = 0
        for (let i = 0; i < records.length; i++) {
            onRecordChange(i, "processing", true)
            let data = {
                title: records[i].title,
                amount: {
                    sum: records[i].amount,
                    currency: invoice.currency || "EUR"
                },
                date: records[i].date,
                invoice_reference: setInvoiceReference(),
                category: records[i].category,
                cost_center: records[i].cost_center,
            }

            // find account based on given ID (if exists) and assign account to record
            data.business_detail = null
            let id_number = null
            if ( invoice.is_issued ) id_number = invoice.customer_registration_id
            if ( !invoice.is_issued ) id_number = invoice.supplier_registration_id

            if (id_number !== null && id_number !== ""){
                const findAccountRequest = await requests.get(API.ACCOUNTS + "business_details/?query={id}&exclude_unit=true&identification_number=" + id_number)
                if( findAccountRequest.status === 200 ){
                    if( findAccountRequest.response.length > 0 ){
                        data.business_detail = findAccountRequest?.response?.[0]?.id || null
                    }
                }
            }

            let request = undefined
            if( records[i].id === 0 ){
                request = await requests.post(API.COST_CENTERS_RECORDS, data)
            } else {
                request = await requests.patch(API.COST_CENTERS_RECORDS + records[i].id + "/", data)
            }
            
            if( request !== undefined ){
                if( request.status === 201 || request.status === 200 ){
                    onRecordChange(i, "processing", false)
                    onRecordChange(i, "id", request.response.id)
                    onRecordChange(i, "title", request.response.title)
                    onRecordChange(i, "amount", request.response.amount.sum)
                    onRecordChange(i, "date", request.response.date)
                    onRecordChange(i, "invoice_reference", request.response.invoice_reference)
                    onRecordChange(i, "category", request.response.category.id)
                    onRecordChange(i, "cost_center", request.response.cost_center)
                    divided += 1
                }
            }
        }

        if( records.length === divided ){
            let syncInvoice = true
            const syncInvoiceRequest = await requests.patch(API.INVOICES + invoice.id + "/?connection=" + connection.connection_id, {
                split_ratio: parseFloat(difference.percentage).toFixed(2) 
            })

            if( syncInvoiceRequest.status !== 200 ) syncInvoice = false

            if( syncInvoice ){
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('success'),
                    description: t('successfully_splittet_to_cc'),
                    animation: 'pulse',
                    time: 5000,
                }) 
                setData(prev => prev.map(item => {
                    if (item.id === invoice.id) {
                        item = syncInvoiceRequest.response
                    }
                    return item
                }))
                onClose()
            } else {
                alert("External service failure.")
            }

        }

        setIsProcessing(false)
    }

    const handleAdditionCostCenter = async (value, key) => {
        setCostCenters({ ...costCenters, isLoading: true });
        const request = await requests.post(API.COST_CENTERS, {
            title: value,
        });

        if (request.status === 201) {
            setCostCenters((prevState) => {
                return {
                    ...prevState,
                    isLoading: false,
                    options: [
                        {
                            key: request.response.id,
                            value: request.response.id,
                            text: request.response.title,
                        },
                        ...prevState.options,
                    ],
                };
            });

            onRecordChange(key, "cost_center", request.response.id)
        }
    }

    const setInvoiceReference = () => {
        return invoice.id + "__" + invoice.number + "__tax:" + settings.tax
    }

    const onAddRecord = () => {
        setRecords(prevState => [...prevState, { 
            id: 0,
            title: renderName(),
            amount: "",
            date: invoice.date_supply !== null ? moment(invoice.date_supply).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
            category: "",
            cost_center: "",
            invoice_reference: "",
            processing: false
        }])
    }

    const onRecordRemove = async (key, item) => {
        onRecordChange(key, "processing", true)
        if( item.id === 0 ){
            setRecords(prevState => prevState.filter((record, index) => index !== key))
        } else {
            // !! update total split value for invoice
            const request = await requests.del(API.COST_CENTERS_RECORDS + item.id + "/")
            if( request.status === 204 ){
                setRecords(prevState => prevState.filter((record, index) => index !== key))
            }
        }
        onRecordChange(key, "processing", false)
    }

    const onRecordChange = (key, updatedKey, value) => {
        setRecords(prevState => prevState.filter((record, index) => {
            if( index === key ){
                record[updatedKey] = value
            }

            return record
        }))
    }

    const checkPriceIsOver = () => {
        let price = 0
        if( settings.tax === 1 ){
            price = calculatedTaxes.total_excluding_tax
        } else {
            price = calculatedTaxes.total
        }

        return parseFloat(calculateRecordSum()) > parseFloat(price)
    }

    const isAmountValid = (amount) => {
        // check if its number
        if( isNaN(parseFloat(amount)) && amount !== "" ) return false

        if( amount !== "" && parseFloat(amount) < 0 ) return false

        return true
    }

    const evaluateRecords = () => {
        return records.filter(record => (!isAmountValid(record.amount) || record.cost_center === "" || record.category === "")).length > 0
    }

    const handleCategoryOptions = () => {
        let options = []

        for (let i = 0; i < categories.length; i++) {
            let type = invoice.is_issued ? 1 : 2

            // credit notes
            if ( invoice.is_issued && invoice.invoice_type === 4 ) {// issued credit note - expense
                type = 2 // expense
            }

            if ( !invoice.is_issued && invoice.invoice_type === 4 ) {// recieved credit note - income
                type = 1 // income
            }

            // debit notes
            if ( invoice.is_issued && invoice.invoice_type === 5 ) {// issued debit note - income
                type = 1 // income
            }

            if ( !invoice.is_issued && invoice.invoice_type === 5 ) {// recieved debit note - expense
                type = 2 // expense
            }

            if( parseInt(categories[i].type) === parseInt(type) ){
                let textValue = categories[i].title + " (" + categories[i].type_display + ")"
                
                if( categories[i].code !== "" && categories[i].code !== null){
                    textValue = categories[i].code + " - " + categories[i].title + " (" + categories[i].type_display + ")"
                }
    
                options.push({
                    id: categories[i].id,
                    value: categories[i].id,
                    text: textValue
                })
            }
        }

        return options
    }

    const renderName = () => {
        return invoice.is_issued 
            ? `${invoice?.customer_name || ""}${!isEmpty(invoice?.customer_registration_id) ? " - " + invoice?.customer_registration_id : ""}`  
            : `${invoice?.supplier_name || ""}${!isEmpty(invoice?.supplier_registration_id) ? " - " + invoice?.supplier_registration_id : ""}`  
    }

    return (
        <Segment
            style={{ 
                padding: 0, 
                background: "transparent", 
                boxShadow: "none", 
                border: "none",
                minHeight: "10rem",
                marginBottom: "1rem"
            }}
            loading={isFetching}
        >
            { isFetching && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "8rem" }}> { t('loading_records') } </p> }
            { !isFetching && 
                <Form onSubmit={handleSubmit}>
                    <Grid>
                        <Grid.Row verticalAlign="middle" style={{ padding: 0 }}>
                            <Grid.Column>
                                <span>{ t('document') }: <strong>{ invoice.invoice_type_display }</strong></span> 
                                <span style={{ paddingLeft: "1rem" }}>{ t('invoice_number') }: <strong>{ invoice.number }</strong></span>
                                <span style={{ paddingLeft: "1rem" }}>{ t('mode') }: <strong>{ invoice.tax_mode_display }</strong></span>
                                <span style={{ paddingLeft: "1rem" }}>{ invoice.is_issued ? t('account') : t('supplier') }: <strong>{ renderName() }</strong></span>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Divider/>

                    <Grid>
                        <Grid.Row style={{ padding: 0 }} columns={2}>
                            <Grid.Column>
                                <Header as="h4" content={t('tax_setting')}/>
                                <Form.Group widths={"equal"}>
                                    <SuperField as="radio"
                                        disabled={settings.wasSplitted}
                                        label={t("sum_excluding_tax")}
                                        checked={settings.tax === 1}
                                        onChange={() => setSettings({...settings, tax: 1})}
                                    />
                                    <SuperField as="radio"
                                        disabled={invoice.tax_mode === 2 || settings.wasSplitted}
                                        label={t("sum_including_tax")}
                                        checked={settings.tax === 2}
                                        onChange={() => setSettings({...settings, tax: 2})}
                                    />
                                </Form.Group>

                                <Header as="h4" content={t('split_mode')}/>
                                <Form.Group widths={"equal"}>
                                    <SuperField as="radio"
                                        label={t("split")}
                                        checked={settings.split_definition === 1}
                                        onChange={() => setSettings({...settings, split_definition: 1})}
                                    />
                                    <SuperField as="radio"
                                        label={t("full_price")}
                                        checked={settings.split_definition === 2}
                                        onChange={() => {
                                            setSettings({...settings, split_definition: 2})

                                            if( records.length === 0){
                                                let price = 0
                                                if( settings.tax === 1 ){
                                                    price = calculatedTaxes.total_excluding_tax
                                                } else {
                                                    price = calculatedTaxes.total
                                                }

                                                setRecords(prevState => [...prevState, { 
                                                    id: 0,
                                                    type: invoice.is_issued ? 1 : 2,
                                                    title: renderName(),
                                                    amount: price,
                                                    date: invoice.date_supply !== null ? moment(invoice.date_supply).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                                                    category: "",
                                                    cost_center: "",
                                                    processing: false
                                                }])
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </Grid.Column>
                            <Grid.Column>
                                <FlexTable responsive={false}>
                                    <FlexRow background="transparent" borders fontSize="0.9rem" padding="0.5rem">
                                        <FlexHeader content={t("tax_rate")}/>
                                        <FlexHeader content={t("sum_excluding_tax")}/>
                                        { invoice.tax_mode !== 2 && <FlexHeader content={t("sum_including_tax")}/>}
                                    </FlexRow>
                                    { invoice.taxes.map(tax => (
                                        <FlexRow background="transparent" borders fontSize="0.9rem" padding="0.5rem">
                                            <FlexItem bold content={ tax.tax_rate + " %" }/>
                                            <FlexItem> { thousandsSeparators(tax.sum_excluding_tax < 0 ? (tax.sum_excluding_tax * (-1)) : tax.sum_excluding_tax)} </FlexItem> 
                                            { invoice.tax_mode !== 2 && 
                                                <FlexItem> { thousandsSeparators(tax.sum < 0 ? (tax.sum * (-1)) : tax.sum) } </FlexItem> 
                                            }
                                        </FlexRow>
                                    )) }
                                    <FlexRow background="transparent" borders fontSize="0.9rem" padding="0.5rem">
                                        <FlexItem bold content={t('summary')}/>
                                        <FlexItem bold>
                                            <span style={{ color: settings.tax === 1 ? "var(--info)" : "var(--black)" }}> { thousandsSeparators(calculatedTaxes.total_excluding_tax) } </span>  { invoice.currency }
                                        </FlexItem>
                                        { invoice.tax_mode !== 2 && 
                                            <FlexItem bold>
                                                <span style={{ color: settings.tax === 2 ? "var(--info)" : "var(--black)" }}> { thousandsSeparators(calculatedTaxes.total) } </span> { invoice.currency }
                                            </FlexItem>
                                        }
                                    </FlexRow>
                                </FlexTable>
                                
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Divider/>
                    <Grid>
                        <Grid.Row verticalAlign="top" style={{ padding: 0 }} columns={2}>
                            <Grid.Column>
                                <p>
                                    ** { t('manage_your_cost_centers') } <Link to={routes.COST_CENTERS}>{t('in_this_interface')}</Link>. <br/>
                                    ** { t('manage_your_record_categories_in') } <Link to={routes.SETTINGS + "types/cost-centers-record-categories"}>{t("settings")}</Link>.
                                </p>
                            </Grid.Column>
                            <Grid.Column style={{ textAlign: "right" }}>
                                <span><strong>{t('divided')}:</strong><br/> 
                                    { checkPriceIsOver() && 
                                        <Popup 
                                            position='left center'
                                            trigger={
                                                <Icon name="warning circle" style={{ color: "var(--danger)" }}/> 
                                            }
                                            content={ t('total_overcome_invoice_summary') }
                                        />
                                    }
                                    <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                                        { thousandsSeparators(calculateRecordSum()) } / { settings.tax === 1 ? thousandsSeparators(calculatedTaxes.total_excluding_tax) : thousandsSeparators(calculatedTaxes.total) } { invoice.currency } 
                                    </span>
                                </span> <br/><br/>
                                <span>
                                    <strong>{t('remaining')}:</strong><br/>
                                    <span style={{ fontSize: "1.2rem", fontWeight: "bold", color: "var(--primary)" }}>{ thousandsSeparators(difference.diff) } { invoice.currency } - { difference.percentage }%</span>
                                </span>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Divider/>

                    <FlexTable responsive={false}>
                        <FlexRow padding="0.5rem">
                            <FlexHeader>
                                <Form.Group style={{ marginBottom: 0, fontSize: "0.9rem" }}>
                                    <Form.Field width="6" content={t('date')}/>
                                    <Form.Field width="4" content={t('sum')}/>
                                    <Form.Field width="6" content={t('category')}/>
                                    <Form.Field width="6" content={t('cost_center')}/>
                                </Form.Group>
                            </FlexHeader>
                            <FlexHeader basis="10%" textAlign="center" content={""}/>
                        </FlexRow>
                        <EmptyRow length={records.length}/>
                        { records.map((record, key) => (
                            <FlexRow key={key} background="transparent" fontSize="0.9rem" padding="0.5rem" borders>
                                <FlexItem>
                                    <Form.Group style={{ marginBottom: "0.3rem" }}>
                                        <SuperField as="datepicker"
                                            style={{ width: "220px", height: "2.5rem", position: "relative", top:  "0.2rem" }}
                                            size="small"
                                            value={record.date}
                                            onChange={ (e, { value}) => onRecordChange(key, "date", value)}
                                        />
                                        <SuperField as="input"
                                            disabled={record.processing}
                                            width="4"
                                            size="small"
                                            value={record.amount}
                                            style={{ height: "2.5rem", position: "relative", top:  "0.2rem" }}
                                            error={ !isAmountValid(record.amount) ? t('invalid_input') : false }
                                            onChange={ (e, { value }) => onRecordChange(key, "amount", setDotSeparator(value)) }
                                        />
                                        <SuperField as="choice"
                                            search
                                            size="small"
                                            width="6"
                                            value={record.category}
                                            loading={isLoading}
                                            disabled={isLoading || record.processing}
                                            customOptions={handleCategoryOptions()}
                                            onChange={ (e, { value }) => onRecordChange(key, "category", value) }
                                        />
                                        <SuperField as="choice"
                                            search
                                            size="small"
                                            allowAdditions
                                            width="6"
                                            value={record.cost_center}
                                            loading={costCenters.isLoading}
                                            disabled={costCenters.isLoading || record.processing}
                                            customOptions={costCenters.options}
                                            onAddItem={(e, { value }) => handleAdditionCostCenter(value, key)}
                                            onChange={ (e, { value }) => onRecordChange(key, "cost_center", value) }
                                        />
                                    </Form.Group>

                                    <SuperField as="input"
                                        disabled={record.processing}
                                        placeholder={ t('note') + " / "+ t('title') }
                                        size="small"
                                        width="16"
                                        value={record.title}
                                        onChange={ (e, { value }) => onRecordChange(key, "title", value) }
                                    />
                                </FlexItem>
                                <FlexItem basis="10%" textAlign="center">
                                    <Form.Field style={{ marginTop: "0.5rem" }}>
                                        { record.processing 
                                            ? <Loader size="small" className='dark-loader' active inline/>
                                            : <Icon onClick={ () => onRecordRemove(key, record) } name="remove" style={{ cursor: "pointer", color: "var(--danger)" }}/>
                                        }
                                    </Form.Field>
                                </FlexItem>
                            </FlexRow>
                        )) }
                    </FlexTable>

                    { settings.split_definition !== 2 && 
                        <Button 
                            primary 
                            icon="plus" 
                            size="small" 
                            type="button" 
                            iconPosition="left" 
                            content={t('add_record')}
                            style={{ marginTop: "0.5rem" }}
                            onClick={ () => onAddRecord() }
                        />
                    }

                    <Divider/>
                    <Form.Field style={{ textAlign: "right" }}>
                        <ModalCancel onClose={onClose}/>
                        <ModalSubmit
                            text={t('confirm')}
                            loading={isProcessing}
                            disabled={
                                isProcessing || 
                                checkPriceIsOver() || 
                                evaluateRecords() || 
                                records.length === 0
                            }
                        />
                    </Form.Field>
                </Form>
            }
        </Segment>
    );
};

export default InvoiceDivideToCC;