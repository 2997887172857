import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import { Segment, Card } from 'semantic-ui-react'

const StatCard = ({ color, number, description, icon, onClick }) => {
    return (
        <Card style={{ borderRadius: 0 }} color={color} onClick={onClick}>
            <Card.Content>
                <div style={{ display: 'flex', marginBottom: '2rem' }}>
                    <div
                        style={{
                            textAlign: 'left',
                            fontWeight: 'bold',
                            width: '70%',
                            fontSize: '1.15rem',
                            paddingTop: '0.25rem',
                            color: 'var(--dark)',
                        }}
                    >
                        {number}
                    </div>
                    <div style={{ textAlign: 'right', width: '30%' }}>
                        <Icon
                            name={icon}
                            style={{ fontSize: '2rem', fontWeight: 'bold', textAlign: 'left', color: color }}
                        />
                    </div>
                </div>

                <div
                    style={{
                        position: 'absolute',
                        bottom: '1rem',
                        marginTop: '1rem',
                        textTransform: 'uppercase',
                        color: 'var(--dark)',
                        opacity: '0.8',
                    }}
                >
                    {description}
                </div>
            </Card.Content>
        </Card>
    )
}

const ProjectDetailStatHeader = ({ project_params }) => {
    const [isLoading, setIsLoading] = useState(true)
    const { t } = useTranslation()
    const [stats, setStats] = useState([])

    useEffect(() => {
        async function fetchStats() {
            setIsLoading(true)
            const request = await requests.get(API.REPORTS + `projects/issues/stats/?project=${project_params.id}`)
            if (request.status === 200) {
                setStats(request.response)
            }
            setIsLoading(false)
        }
        fetchStats()
        // eslint-disable-next-line
    }, [])

    return (
        <Segment
            loading={isLoading}
            style={{
                padding: 0,
                margin: 0,
                marginBottom: '1rem',
                backgroundColor: 'transparent',
                minHeight: '5rem',
                border: 'none',
                boxShadow: 'none',
            }}
        >
            <Card.Group stackable doubling itemsPerRow={5}>
                <StatCard
                    color="purple"
                    number={stats?.all_issues || '0'}
                    icon="list-outline"
                    description={t('total_issues')}
                />
                <StatCard
                    color="orange"
                    number={stats?.opened_issues || '0'}
                    icon="folder-open-outline"
                    description={t('opened_issues')}
                />
                <StatCard
                    color="purple"
                    number={stats?.closed_issues || '0'}
                    icon="folder-outline"
                    description={t('closed_issues')}
                />
                <StatCard
                    color="orange"
                    number={(stats?.total_estimated_time || '0') + ' ' + t('_hours')}
                    icon="timer-outline"
                    description={t('total_estimated_time')}
                />
                <StatCard
                    color="pink"
                    number={(stats?.total_spent_time || '0') + ' ' + t('_hours')}
                    icon="time-outline"
                    description={t('total_spent_time')}
                />
            </Card.Group>
        </Segment>
    )
}

export default ProjectDetailStatHeader
