import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// store
import { icons } from '@icons';
import { routes } from '@routes';
import { useFetchData } from '@store/services/helpers/hooks'
// components
import BreadcrumbNav from '@components/BreadcrumbNav';
import { Container, Loader, Sidebar } from 'semantic-ui-react';
// module specific components
import IssueTracker from '../v2_components/detail-v2/IssueTracker'
import HeaderSection from '../v2_components/detail-v2/HeaderSection'
import ProjectSidebar from '../v2_components/detail-v2/ProjectSidebar'
import DailyStatsSection from '../v2_components/detail-v2/DailyStatsSection'
import ProjectStatistics from '../v2_components/detail-v2/ProjectStatistics';

import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
import { useParams } from 'react-router-dom';
import IssueStatistics from '../v2_components/detail-v2/IssueStatistics';

const ProjectDetailView = () => {
    const { t } = useTranslation();
    const params = useParams()
    const [sidebar, setSidebar] = useState()

    const [filtered, setFiltered] = useState({ query: `?project=${params.id}`, process: false })
    const [issueStats, setIssueStats] = useState({ isLoading: true, data: null, length: null })
    const [issues, setIssues] = useState({ isLoading: true, data: null, length: null })
    const [view, setView] = useState(0)//0-management 1-project_statistics 2-issues_statistics
    const [state, setState] = useState(1)//1-opened 2-closed 3-all
    const [isLoading, setIsLoading] = useState(true)

    const [project, setProject] = useFetchData(API.PROJECTS + params.id + "/")
    const [stats, setStats] = useFetchData(API.PROJECTS_STATS + params.id + "/stats/")
    const [groups, setGroups] = useFetchData(API.PROJECT_ISSUES_GROUPS)
    const [members, setMembers] = useState({ isLoading: true, data: null, length: null })

    const breadcrumb_items = [
        { 'name': t("projects_list"), 'icon': icons.PROJECTS, 'href': routes.PROJECT_LIST },
        { 'name': project?.data?.name, 'icon': 'file outline', 'href': '' }
    ]

    useEffect(() => {
        async function updateStatsAndIssues() {
            setIsLoading(true)
            const result = await getRequest(API.PROJECT_ISSUES + filtered.query)
            if (result.status === 200) {
                setIssues({ data: result.response, isLoading: false, length: result.response.length })
            }
            const new_stats = await getRequest(API.PROJECTS_ISSUE_STATS + filtered.query)
            if (new_stats.status === 200) {
                setIssueStats((prev) => ({ ...prev, data: new_stats.response, isLoading: false }))
            }
            const resultMembers = await getRequest(API.PROJECT_MEMBERS)
            if (resultMembers.status === 200) {
                const filteredMembers = resultMembers.response.filter((item) => item.project.id === parseInt(params.id));
                setMembers({ data: filteredMembers, isLoading: false, length: filteredMembers.length })
            }
            setIsLoading(false)
        }
        updateStatsAndIssues()
    }, [filtered, params.id])

    return (
        <>
            {(project.isLoading || issues.isLoading || groups.isLoading || stats.isLoading || issueStats.isLoading) ?
                <Loader active={project.isLoading || issues.isLoading || groups.isLoading || stats.isLoading || issueStats.isLoading} />
                :
                <Sidebar.Pushable>
                    <ProjectSidebar
                        visible={sidebar}
                        onHide={() => setSidebar(false)}
                        project={project?.data}
                        setProject={setProject}
                        stats={stats.data}
                        setStats={setStats}
                        members={members}
                        setMembers={setMembers}
                    />
                    <Sidebar.Pusher dimmed={false}>
                        <BreadcrumbNav items={breadcrumb_items} />
                        <Container fluid style={{ minHeight: "90vh" }}>
                            <HeaderSection
                                toggleSidebar={() => setSidebar(!sidebar)}
                                toggleView={(e) => setView(e)}
                                view={view}
                                filtered={filtered}
                            />
                            <DailyStatsSection
                                stats={(view === 0 || view === 2)
                                    ? issueStats.data
                                    : stats?.data}
                                view={view} />
                            {view === 0 &&
                                <IssueTracker
                                    stats={stats?.data}
                                    issues={issues.data}
                                    setIssues={setIssues}
                                    groups={groups}
                                    setGroups={setGroups}
                                    setStats={setIssueStats}
                                    setFiltered={setFiltered}
                                    filtered={filtered}
                                    state={state}
                                    setState={setState}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                />
                            }
                            {view === 1 && <ProjectStatistics stats={stats} />}
                            {view === 2 && <IssueStatistics stats={issueStats.data} isLoading={issueStats.isLoading} />}
                        </Container>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            }
        </>
    );
};

export default ProjectDetailView;
