import React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route } from 'react-router-dom';
// store
import { icons } from '@icons';
import { routes } from '@routes';
// components
import BreadcrumbNav from '@components/BreadcrumbNav';
import CanView from '@components/perms/CanView';
import AwaitingApprovals from './AwaitingApprovals';
import ApprovedApprovals from './ApprovedApprovals';
import DeniedApprovals from './DeniedApprovals';

const ApprovalsList = () => {
    const { t } = useTranslation();

    const breadcrumb_items = [
        { 'name': t("approvals"), 'icon': icons.APPROVALS, 'href': '' }
    ]

    return (
        <CanView permissions={['approvals.c_view_all_approvals', 'approvals.c_view_user_approvals']} redirect>
            <BreadcrumbNav items={breadcrumb_items} style={{ marginBottom: 0 }} />

            <Switch>
                <Route exact path={routes.APPROVALS} component={AwaitingApprovals} />
                <Route path={routes.APPROVALS + "approved/"} component={ApprovedApprovals} />
                <Route path={routes.APPROVALS + "denied/"} component={DeniedApprovals} />
            </Switch>
        </CanView>
    );
};

export default ApprovalsList;
