import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
//components
import ReactPaginate from 'react-paginate'
import { Button, Dropdown, Grid, Icon } from 'semantic-ui-react'

const Paginator = ({ forcePage, forceReload, selected, setSelected, limit, setLimit, onChange, length, noLimit }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()
    forceReload = forceReload === undefined ? true : forceReload
    let forceInitialPage = forcePage !== undefined ? parseInt(forcePage - 1) : 0

    const [currentPage, setCurrentPage] = useState(forceInitialPage)
    const [pageCount, setPageCount] = useState(0)

    const changeLimit = (value) => {
        if (forceReload) {
            history.replace({ pathname: location.pathname, search: `?page=${1}` })
        }
        setCurrentPage(0)
        onChange('&page=1&limit=' + value)
    }

    const limitOptions = [
        {
            key: 10,
            text: 10,
            value: 10,
            content: <Dropdown.Item text="10" onClick={() => setLimit(10)} />,
        },
        {
            key: 20,
            text: 20,
            value: 20,
            content: <Dropdown.Item text="20" onClick={() => setLimit(20)} />,
        },
        {
            key: 30,
            text: 30,
            value: 30,
            content: <Dropdown.Item text="30" onClick={() => setLimit(30)} />,
        },
        {
            key: 50,
            text: 50,
            value: 50,
            content: <Dropdown.Item text="50" onClick={() => setLimit(50)} />,
        },
        {
            key: 100,
            text: 100,
            value: 100,
            content: <Dropdown.Item text="100" onClick={() => setLimit(100)} />,
        },
    ]

    const pageMove = (direction) => {
        let current = currentPage

        if (direction === 'previous') {
            current = currentPage - 1
            setCurrentPage(current)
        } else if (direction === 'next') {
            current = currentPage + 1
            setCurrentPage(current)
        }

        if (forceReload) {
            history.replace({ pathname: location.pathname, search: `?page=${current + 1}` })
        }
        onChange('&page=' + (current + 1))
    }

    function PaginatedItems({ itemsPerPage }) {
        useEffect(() => {
            setPageCount(Math.ceil(length / itemsPerPage))
        }, [itemsPerPage])

        const handlePageClick = (event) => {
            if (forceReload) {
                history.replace({ pathname: location.pathname, search: `?page=${event.selected + 1}` })
            }
            setCurrentPage(event.selected)
            onChange('&page=' + (event.selected + 1))
        }

        return (
            <>
                <ReactPaginate
                    onPageChange={handlePageClick}
                    forcePage={currentPage}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            </>
        )
    }

    return (
        <Grid stackable>
            <Grid.Row
                columns={selected?.length !== undefined ? '3' : '2'}
                verticalAlign="middle"
                className="paginator-grid"
                style={{ paddingTop: '1rem', paddingBottom: 0 }}
            >
                {selected?.length !== undefined && (
                    <Grid.Column>
                        {t('selected') + ': '} <strong> {selected?.length || 0} </strong>{' '}
                        {selected?.length > 0 && (
                            <span
                                className="ref-link"
                                style={{ color: 'var(--primary)', textTransform: 'lowercase' }}
                                onClick={() => setSelected([])}
                            >
                                {t('unselect')}
                            </span>
                        )}
                    </Grid.Column>
                )}
                <Grid.Column textAlign={selected?.length !== undefined ? 'center' : 'left'}>
                    <strong>{currentPage + 1 + ' / ' + pageCount}</strong>
                    {' ' + t('pages')}
                </Grid.Column>
                <Grid.Column textAlign="right">
                    {t('total_records') + ': '}
                    <strong>{length}</strong>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={noLimit ? 1 : 2} className="paginator-flex" style={{ padding: 0 }}>
                <Grid.Column>
                    <Grid>
                        <Grid.Row className="paginator-centered">
                            <Button
                                className="prev"
                                type="button"
                                disabled={currentPage - 1 < 0}
                                style={{ background: 'none', paddingRight: 0, marginRight: 0 }}
                                onClick={() => pageMove('previous')}
                            >
                                <Icon name="angle double left" />
                            </Button>
                            <PaginatedItems itemsPerPage={limit ? limit : 10} style={{ padding: 0 }} />
                            <Button
                                className="next"
                                type="button"
                                disabled={currentPage + 1 >= pageCount}
                                style={{ background: 'none', paddingRight: 0, marginRight: 0 }}
                                onClick={() => pageMove('next')}
                            >
                                <Icon name="angle double right" />
                            </Button>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
                {!noLimit && (
                    <Grid.Column textAlign="right">
                        <p className="pagination-limits">
                            {t('records_on_page') + ': '}
                            {/* <Button type="button" active={limit === length} style={{ background: 'none', paddingRight: 0 }} content={t('all')} onClick={() => changeLimit(length)} /> */}
                            <Button
                                type="button"
                                active={limit === '10'}
                                style={{ background: 'none', paddingRight: 0 }}
                                content={10}
                                onClick={() => changeLimit(10)}
                            />
                            <Button
                                type="button"
                                active={limit === '20'}
                                style={{ background: 'none', paddingRight: 0 }}
                                content={20}
                                onClick={() => changeLimit(20)}
                            />
                            <Button
                                type="button"
                                active={limit === '30'}
                                style={{ background: 'none', paddingRight: 0 }}
                                content={30}
                                onClick={() => changeLimit(30)}
                            />
                            <Button
                                type="button"
                                active={limit === '50'}
                                style={{ background: 'none', paddingRight: 0 }}
                                content={50}
                                onClick={() => changeLimit(50)}
                            />
                            <Button
                                type="button"
                                active={limit === '100'}
                                style={{ background: 'none', paddingRight: 0 }}
                                content={100}
                                onClick={() => changeLimit(100)}
                            />
                        </p>
                        <div className="pagination-dropdown">
                            <span>{t('records_on_page') + ': '}</span>
                            <Dropdown inline style={{ fontSize: '13px' }} defaultValue={limit} options={limitOptions} />
                        </div>
                    </Grid.Column>
                )}
            </Grid.Row>
        </Grid>
    )
}

export default Paginator
