import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { routes } from '@routes'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { tzDateTime } from '@helpers/dates'
import { useHasPermissions, useIsMount } from '@helpers/hooks'
// components
import Icon from '@components/Icon'
import ListView from '@components/ListView'
import UploadFile from '@components/UploadFile'
import AvatarIcon from '@components/AvatarIcon'
import CanView from '@components/perms/CanView'
import Action from '@components/general/Action'
import DocPreview from '@components/DocPreview'
import SuperField from '@components/forms/SuperField'
import { Rating, Label, Button, Popup, Divider, Form, Loader } from 'semantic-ui-react'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { FlexRow, FlexItem } from '@components/tables/FlexTable'
// specific comps
import RecruitersList from './RecruitersList'
//import StartOnboarding from '../forms/StartOnboarding'
import { MoveStageForm } from '../forms/MoveStageForm'
import NewCandidateForm from '../forms/NewCandidateForm'
import HiringRoomStats from '../../views/HiringRoomStats'
import FullHistoryLog from '../activity-log/FullHistoryLog'
import ListSelectedCandidates from './ListSelectedCandidates'
import RejectBlackListForm from '../forms/RejectBlackListForm'
import ContactCell from '../../../candidates/components/tables/ContactCell'
import VisaAndResidenceForm from '../../../foreigners/components/VisaAndResidenceForm'
import CandidateComments from './CandidateComments'
import ContractForm from '../../../contracts/components/ContractForm'

const StagesListView = ({
    stage,
    params,
    room,
    stages,
    setStages,
    rejected,
    setRejected,
    blacklisted,
    setBlacklisted,
    totalCandidates,
    setListView,
    setIsAdded,
    setRejectedState,
    setBlacklistStage,
    setAllStage,
}) => {
    const { t } = useTranslation()

    const allow_jump_over_stages = useSelector((state) => state.allow_jump_over_stages)
    const dateFormat = useSelector((state) => state.date_format)
    const reduxUser = useSelector((state) => state?.user?.profile_id)

    const [selectedStage, setSelectedStage] = useState(stage)

    const isLastStage = () => {
        return stages?.[stages?.length - 1]?.id === selectedStage
    }

    const onCreate = async (response, setData, setTotal) => {
        if (response.length > 0) {
            for (let i = 0; i < response.length; i++) {
                let dataObject = {
                    candidate: response[i].id,
                    //recruiter: reduxUser,
                    recruitment: params.id,
                }

                const recruiter = room.recruiters.find((item) => item?.id === reduxUser)
                let result = null
                if (recruiter) {
                    dataObject = {
                        ...dataObject,
                        recruiter: recruiter.id,
                    }

                    result = await requests.post(API.RECRUITMENT_ASSIGNMENTS, dataObject)
                }

                if (result !== null && result.status === 201) {
                    setData((prev) => [{ ...response[i], candidate_recruiters: [result.response] }, ...prev])
                    setTotal((prev) => prev + 1)
                } else {
                    setData((prev) => [response[i], ...prev])
                    setTotal((prev) => prev + 1)
                }
            }
        } else {
            let dataObject = {
                candidate: response.id,
                //recruiter: reduxUser,
                recruitment: params.id,
            }

            const recruiter = room.recruiters.find((item) => item?.id === reduxUser)
            let result = null
            if (recruiter) {
                dataObject = {
                    ...dataObject,
                    recruiter: recruiter.id,
                }

                result = await requests.post(API.RECRUITMENT_ASSIGNMENTS, dataObject)
            }

            if (result !== null && result.status === 201) {
                setData((prev) => [{ ...response, candidate_recruiters: [result.response] }, ...prev])
                setTotal((prev) => prev + 1)
            } else {
                setData((prev) => [response, ...prev])
                setTotal((prev) => prev + 1)
            }
        }
        setIsAdded(true)
    }

    const PutAvatar = ({ row, setData }) => {
        return (
            <FlexItem basis="80%">
                <FlexRow background="transparent" padding="0" fontSize="0.9rem">
                    <FlexItem basis="10%">
                        <AvatarIcon size={35} name={row?.fullname} src={row?.profile_picture} />
                    </FlexItem>
                    <FlexItem>
                        <div style={{ paddingLeft: '0.5rem' }}>
                            <Link to={routes.CANDIDATES_CARD + row.id} target="_blank">
                                <strong>{row?.fullname}</strong>
                            </Link>

                            <RatingManagement candidate={row} setData={setData} room={room} />
                        </div>
                    </FlexItem>
                </FlexRow>
            </FlexItem>
        )
    }

    const actionUp = (candidate, setData) => {
        const roomID = params.id
        const currentID = candidate?.current_stage?.id

        return (
            <CanView permissions={['candidates.c_manage_candidates_in_recruitment']}>
                <Action
                    as="modal"
                    key={1}
                    type="icon"
                    className="stage-actions"
                    disabled={candidate?.current_stage?.id === stages[stages?.length - 1]?.id}
                    iconColor="var(--dark)"
                    paddingLeft="0.5rem"
                    icon="chevron-up-outline"
                    //tooltip={t('stage_up')}
                    modalSize="tiny"
                    header={t('update_candidate_stage')}
                    modal={
                        <MoveStageForm
                            current={candidate?.current_stage}
                            roomID={roomID}
                            stages={stages}
                            candidateID={candidate.id}
                            isDown={false}
                            onConfirm={(response) => {
                                setStages((prev) =>
                                    prev.filter((item) => {
                                        if (item.id === currentID) {
                                            item.candidate_count = item.candidate_count - 1
                                        } else if (item.id === response.id) {
                                            item.candidate_count = item.candidate_count + 1
                                        }

                                        return item
                                    })
                                )
                                if (selectedStage !== 'all') {
                                    setData((prev) => prev.filter((data) => data.id !== candidate.id))
                                } else {
                                    setData((prev) =>
                                        prev.filter((data) => {
                                            if (data.id === candidate.id) {
                                                data.current_stage = response
                                            }

                                            return data
                                        })
                                    )
                                }
                            }}
                        />
                    }
                />
            </CanView>
        )
    }

    const actionDown = (candidate, setData) => {
        const currentID = candidate?.current_stage?.id
        return (
            <CanView permissions={['candidates.c_manage_candidates_in_recruitment']}>
                <Action
                    as="modal"
                    key={2}
                    type="icon"
                    className="stage-actions"
                    disabled={candidate?.current_stage?.id === stages[0]?.id}
                    iconColor="var(--dark)"
                    paddingLeft="0.5rem"
                    icon="chevron-down-outline"
                    //tooltip={t('stage_down')}
                    modalSize="tiny"
                    header={t('downgrade_candidate_stage')}
                    modal={
                        <MoveStageForm
                            current={candidate?.current_stage}
                            roomID={params.id}
                            stages={stages}
                            isDown={true}
                            candidateID={candidate?.id}
                            onConfirm={(response) => {
                                setStages((prev) =>
                                    prev.filter((item) => {
                                        if (item.id === currentID) {
                                            item.candidate_count = item.candidate_count - 1
                                        } else if (item.id === response.id) {
                                            item.candidate_count = item.candidate_count + 1
                                        }

                                        return item
                                    })
                                )
                                if (selectedStage !== 'all') {
                                    setData((prev) => prev.filter((data) => data.id !== candidate.id))
                                } else {
                                    setData((prev) =>
                                        prev.filter((data) => {
                                            if (data.id === candidate.id) {
                                                data.current_stage = response
                                            }

                                            return data
                                        })
                                    )
                                }
                            }}
                        />
                    }
                />
            </CanView>
        )
    }

    const actionSelect = (candidate, setData) => {
        const currentID = candidate?.current_stage?.id
        return (
            <CanView permissions={['candidates.c_manage_candidates_in_recruitment']}>
                <Action
                    as="modal"
                    key={2}
                    type="icon"
                    className="stage-actions"
                    iconColor="var(--dark)"
                    paddingLeft="0.5rem"
                    paddingBottom="0"
                    paddingTop="0"
                    icon="swap-vertical-outline"
                    //tooltip={t('select_stage')}
                    header={t('move_candidate_to_stage')}
                    modalSize="tiny"
                    modal={
                        <MoveStageForm
                            current={candidate?.current_stage}
                            roomID={params.id}
                            jumpStage={true}
                            candidateID={candidate.id}
                            stages={stages}
                            position={room.job_position}
                            onConfirm={(response) => {
                                if (selectedStage !== 'all') {
                                    setData((prev) => prev.filter((data) => data.id !== candidate.id))
                                } else {
                                    setData((prev) =>
                                        prev.filter((data) => {
                                            if (data.id === candidate.id) {
                                                data.current_stage = response
                                            }

                                            return data
                                        })
                                    )
                                }
                                setStages((prev) =>
                                    prev.filter((item) => {
                                        if (item.id === currentID) {
                                            item.candidate_count = item.candidate_count - 1
                                        } else if (item.id === response.id) {
                                            item.candidate_count = item.candidate_count + 1
                                        }

                                        return item
                                    })
                                )
                            }}
                        />
                    }
                />
            </CanView>
        )
    }

    async function performSelectionAction(type, note, setData, selectedCandidates) {
        let endpoint = ''
        if (type === 'rejected') endpoint = API.CANDIDATES + 'rejections/'
        if (type === 'blacklisted') endpoint = API.CANDIDATES + 'blacklist_records/'

        for (let i = 0; i < selectedCandidates.length; i++) {
            if (
                rejected?.results?.filter((item) => item === selectedCandidates[i].id).length === 0 &&
                blacklisted?.results?.filter((item) => item === selectedCandidates[i].id).length === 0
            ) {
                let currentStage = null
                currentStage = selectedCandidates[i].current_stage.id

                let data = {
                    profile: selectedCandidates[i].id,
                    note: note || '',
                }

                if (type === 'rejected') {
                    data['recruitment'] = params.id
                    data['rejected_by_candidate'] = false
                }

                const request = await requests.post(endpoint, data)
                if (request.status === 201) {
                    setData((prev) => prev.filter((data) => data.id !== selectedCandidates[i].id))

                    setStages((prev) =>
                        prev.filter((item) => {
                            if (item.id === currentStage) {
                                item.candidate_count = item.candidate_count - 1
                            }

                            return item
                        })
                    )

                    if (type === 'rejected') {
                        setRejected((prev) => ({
                            ...prev,
                            count: prev.count + 1,
                            results: [...prev.results, selectedCandidates[i].id],
                        }))
                    }

                    if (type === 'blacklisted') {
                        setBlacklisted((prev) => ({
                            ...prev,
                            count: prev.count + 1,
                            results: [...prev.results, selectedCandidates[i].id],
                        }))
                    }
                }
            }
        }

        // setSelectedCandidates([])
        // setCheckedAll(false)
    }

    const acceptAsEmployee = async (id, setData) => {
        const request = await requests.patch(API.CANDIDATES + id + '/convert/', { profile_type: 2 })

        if (request.status === 200) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('success'),
                description: t('candidate_was_accepted_as_employee'),
                animation: 'bounce',
                time: 5000,
            })
            setData((prevState) =>
                prevState.filter((candidate) => {
                    if (candidate.id === id) {
                        candidate.profile_type = 2
                    }
                    return candidate
                })
            )
        }
    }

    async function convertToEmployee(selectedCandidates, setSelectedCandidates) {
        for (let i = 0; i < selectedCandidates.length; i++) {
            if (
                selectedCandidates[i].current_stage.id ===
                    room?.archived_recruitment_stages?.[room?.archived_recruitment_stages.length - 1]?.id &&
                selectedCandidates[i].profile_type === 1 &&
                selectedCandidates[i].onboardings.length === 0
            ) {
                acceptAsEmployee(selectedCandidates[i].id)
            }
        }

        setSelectedCandidates([])
    }

    async function launchOnboarding(template, deadline, selectedCandidates, setSelectedCandidates, setData) {
        for (let i = 0; i < selectedCandidates.length; i++) {
            if (selectedCandidates[i].profile_type === 1) {
                acceptAsEmployee(selectedCandidates[i].id)
            }

            const requestPatch = await requests.post(API.ONBOARDING_CHECKLIST_CONFIG + template + '/instantiate/', {
                employee: selectedCandidates[i].id,
                deadline: deadline,
            })

            if (requestPatch.status === 201) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('success'),
                    description: t('started_onboarding'),
                    animation: 'bounce',
                    time: 5000,
                })
                setData((prevState) =>
                    prevState.filter((candidate) => {
                        if (candidate.id === selectedCandidates[i].id) {
                            candidate.onboardings = requestPatch.response
                        }
                        return candidate
                    })
                )
            } else {
                toast({
                    type: 'error',
                    icon: 'warning',
                    title: t('error'),
                    description: t('employee_can_not_be_onboarded'),
                    animation: 'bounce',
                    time: 5000,
                })
            }
        }
        setSelectedCandidates([])
    }

    function removeSelectedCandidate(candidate, selectedCandidates, setSelected) {
        setSelected(selectedCandidates.filter((item) => item.id !== candidate.id))
    }

    const handleAdditionalFilter = (stage) => {
        if (stage === 'rejected') {
            setRejectedState('rejected')
            setListView(1)
        }
        if (stage === 'blacklisted') {
            setBlacklistStage('blacklisted')
            setListView(2)
        }

        if (stage === 'all') {
            setAllStage('all')
            setListView(0)
        }
    }

    const handleRejectBlacklist = async (type, note, candidate, setData, setTotal) => {
        let endpoint = ''
        if (type === 'rejected') endpoint = API.CANDIDATES + 'rejections/'
        if (type === 'blacklisted') endpoint = API.CANDIDATES + 'blacklist_records/'

        let currentStage = null
        currentStage = candidate.current_stage.id

        let data = {
            profile: candidate.id,
            note: note || '',
        }

        if (type === 'rejected') {
            data['recruitment'] = params.id
            data['rejected_by_candidate'] = false
        }

        const request = await requests.post(endpoint, data)
        if (request.status === 201) {
            setData((prev) => prev.filter((data) => data.id !== candidate.id))
            if (setTotal) setTotal((prev) => prev - 1)

            setStages((prev) =>
                prev.filter((item) => {
                    if (item.id === currentStage) {
                        item.candidate_count = item.candidate_count - 1
                    }

                    return item
                })
            )

            if (type === 'rejected') {
                setRejected((prev) => ({
                    ...prev,
                    count: prev.count + 1,
                    results: [...prev.results, candidate.id],
                }))
            }

            if (type === 'blacklisted') {
                setBlacklisted((prev) => ({
                    ...prev,
                    count: prev.count + 1,
                    results: [...prev.results, candidate.id],
                }))
            }
        }
    }

    const removeFile = async (candidate, setData) => {
        const request = await requests.del(API.CANDIDATES + candidate.id + '/cv/')

        if (request.status === 204) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('cv_removed'),
                animation: 'pulse',
                time: 2000,
            })

            setData((prev) =>
                prev.map((item) => {
                    if (item.id === candidate.id) {
                        item.cv = null
                    }
                    return item
                })
            )
        }
    }

    const onItemUpdated = (profile, response, setData) => {
        setData((prev) =>
            prev.filter((item) => {
                if (item.id === profile.id) {
                    item.hr_contract = {
                        id: response.id,
                        status: response.status,
                        status_display: response.status_display,
                    }
                }
                return item
            })
        )
    }

    return (
        <>
            <ListView
                as="table"
                allowSearch
                allowSelection
                forceReloadOnPagination={false}
                actionsCellWidth="2"
                removePaddingFromCell={true}
                endpoint={API.CANDIDATES}
                initialFilters={{
                    recruitment: params.id,
                    exclude_rejected: true,
                    exclude_blacklisted: true,
                    include_employees: true,
                    current_stage: selectedStage === 'all' ? '' : selectedStage,
                    gdpr_valid_until_after: '',
                    gdpr_valid_until_before: '',
                    gender: '',
                    assigned_to_recruiter: '',
                    show_candidates_with_cv: '',
                    source: '',
                }}
                query={
                    '&query={id, fullname, cv, source, contacts, onboardings{id, title, status_display}, profile_picture, current_stage, average_rating, date_of_assignment, current_stage_date, is_active, is_foreigner, profile_type, hr_contract}'
                }
                renderFilterFields={(filters, setFilters) => (
                    <>
                        <SuperField
                            as="choice-select"
                            search
                            text="name"
                            label={t('source')}
                            endpoint={API.SOURCES}
                            value={filters.source}
                            onChange={(e, { value }) =>
                                setFilters({
                                    ...filters,
                                    source: value,
                                })
                            }
                        />

                        <SuperField
                            as="choice-select"
                            type="genders"
                            label={t('gender')}
                            value={filters.gender}
                            onChange={(e, { value }) =>
                                setFilters({
                                    ...filters,
                                    gender: value,
                                })
                            }
                        />

                        <SuperField
                            as="choice-select"
                            search
                            text="fullname"
                            label={t('recruiter')}
                            endpoint={API.EMPLOYEES}
                            additionalFilters={'&only_basic_info=true&is_active=true'}
                            value={filters.assigned_to_recruiter}
                            onChange={(e, { value }) =>
                                setFilters({
                                    ...filters,
                                    assigned_to_recruiter: value,
                                })
                            }
                        />
                        <SuperField
                            as="checkbox"
                            label={t('assigned_to_me')}
                            checked={filters.assigned_to_recruiter === reduxUser}
                            onChange={() =>
                                setFilters({
                                    ...filters,
                                    assigned_to_recruiter: filters.assigned_to_recruiter === reduxUser ? '' : reduxUser,
                                })
                            }
                        />
                        <SuperField
                            as="checkbox"
                            label={t('show_foreigner_candidates')}
                            checked={filters.is_foreigner}
                            onChange={() =>
                                setFilters((prev) => ({
                                    ...prev,
                                    is_foreigner:
                                        filters.is_foreigner === '' || filters.is_foreigner === false ? true : '',
                                }))
                            }
                        />
                        <SuperField
                            as="checkbox"
                            label={t('show_candidates_with_cv')}
                            checked={filters.show_candidates_with_cv}
                            onChange={() =>
                                setFilters((prev) => ({
                                    ...prev,
                                    show_candidates_with_cv: filters.show_candidates_with_cv === true ? '' : true,
                                }))
                            }
                        />
                    </>
                )}
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        //name: t('add_candidate'),
                        modalSize: 'small',
                        permissions: ['candidates.c_manage_recruitment'],
                        modal: (
                            <NewCandidateForm
                                btnName={t('confirm')}
                                roomId={params.id}
                                onConfirm={(response, setData, setTotal) => onCreate(response, setData, setTotal)}
                            />
                        ),
                    },
                    {
                        as: 'link',
                        type: 'detail',
                        icon: 'eye-outline',
                        name: t('view_detail'),
                        redirect: (item) => routes.HIRING_ROOM + params.id + routes.CANDIDATES_DETAIL + item.id,
                    },
                    {
                        as: 'modal',
                        type: 'custom',
                        icon: 'chatbubble-ellipses-outline',
                        name: t('comments'),
                        centered: false,
                        customIconColor: 'var(--blue)',
                        permissions: ['candidates.c_view_recruitment'],
                        modalSize: 'tiny',
                        modal: (candidate) => (
                            <CandidateComments
                                room={room.id}
                                stages={stages}
                                candidate={candidate.id}
                                currentStage={candidate.current_stage}
                                header={t('comments') + ' - ' + candidate.fullname}
                            />
                        ),
                    },
                    {
                        as: 'modal',
                        type: 'custom',
                        icon: 'albums-outline',
                        name: t('history'),
                        modalSize: 'tiny',
                        modal: (candidate) => <FullHistoryLog room={params.id} candidate={candidate.id} />,
                    },
                    isLastStage() && {
                        as: 'modal',
                        name: t('create_draft_contract'),
                        icon: 'file-tray-full-outline',
                        modal: (item, setData) => (
                            <ContractForm
                                isSingle
                                hiringRoomsSelected={[item]}
                                room={room.id}
                                order={room.order}
                                position={room.job_position}
                                onItemUpdated={(response, profile) => onItemUpdated(profile, response, setData)}
                            />
                        ),
                        disabled: (item) => item.hr_contract?.id !== undefined,
                        //isHidden: (item) => item.hr_contract?.id !== undefined,
                        permissions: ['contracts.c_manage_draft_contracts'],
                    },
                    {
                        as: 'confirm',
                        type: 'custom',
                        name: t('accept_as_employee'),
                        icon: 'id-card-outline',
                        text: t('confirm_as_employee'),
                        onClick: async (item, setData) => acceptAsEmployee(item.id, setData),
                        //disabled: (item) => item.profile_type === 2,
                        isHidden: (item) => item.profile_type === 2 || !isLastStage(),
                    },
                    {
                        as: 'modal',
                        type: 'custom',
                        icon: 'close-circle-outline',
                        name: t('reject'),
                        modalSize: 'tiny',
                        header: 'test',
                        customIconColor: 'var(--danger)',
                        permissions: ['candidates.c_manage_recruitment'],
                        modal: (candidate, setData, setTotal) => (
                            <RejectBlackListForm
                                header={t('reject_candidate_from_hr')}
                                type="rejected"
                                onPerformAction={(type, note) =>
                                    handleRejectBlacklist(type, note, candidate, setData, setTotal)
                                }
                            />
                        ),
                    },
                    {
                        as: 'modal',
                        type: 'custom',
                        icon: 'document',
                        name: t('blacklist'),
                        modalSize: 'tiny',
                        permissions: ['candidates.c_manage_recruitment'],
                        modal: (candidate, setData, setTotal) => (
                            <RejectBlackListForm
                                header={t('put_candidate_to_blacklist')}
                                type="blacklisted"
                                onPerformAction={(type, note) =>
                                    handleRejectBlacklist(type, note, candidate, setData, setTotal)
                                }
                            />
                        ),
                    },
                ]}
                bulkActions={(selected, setData, setTotal, filters, queryParams, setSelected) => [
                    {
                        as: 'modal',
                        name: t('reject_candidate'),
                        icon: 'close-circle-outline',
                        modal: (
                            <RejectBlackListForm
                                type="rejected"
                                setData={setData}
                                onPerformAction={performSelectionAction}
                                selectedCandidates={selected}
                            />
                        ),
                    },
                    {
                        as: 'modal',
                        name: t('blacklist_candidate'),
                        icon: 'warning-outline',
                        modal: (
                            <RejectBlackListForm
                                type="blacklisted"
                                setData={setData}
                                onPerformAction={performSelectionAction}
                                selectedCandidates={selected}
                            />
                        ),
                    },
                    isLastStage() && {
                        as: 'modal',
                        name: t('accept_as_employee'),
                        icon: 'id-card-outline',
                        modal: (
                            <ListSelectedCandidates
                                type={'convert'}
                                room={room}
                                convertToEmployee={convertToEmployee}
                                launchOnboarding={launchOnboarding}
                                selectedCandidates={selected}
                                setSelected={setSelected}
                                removeSelectedCandidate={removeSelectedCandidate}
                                setData={setData}
                            />
                        ),
                    },
                    isLastStage() && {
                        as: 'modal',
                        modalSize: 'small',
                        name: t('create_draft_contract'),
                        icon: 'file-tray-full-outline',
                        modal: (
                            <ContractForm
                                hiringRoomsSelected={selected}
                                setSelected={setSelected}
                                room={room.id}
                                position={room.job_position}
                                order={room.order}
                                onItemUpdated={(response, profile) => onItemUpdated(profile, response, setData)}
                            />
                        ),
                        permissions: ['contracts.c_manage_draft_contracts'],
                    },
                    {
                        as: 'modal',
                        name: t('start_foreigner_process'),
                        icon: 'earth-outline',
                        modalSize: 'small',
                        permissions: ['foreigners.c_manage_all_foreigners_processes'],
                        modal: <VisaAndResidenceForm selected={selected} setSelected={setSelected} />,
                    },
                ]}
                listAdditionActions={(data, setData, total, setTotal, response) => [
                    {
                        as: 'action',
                        index: 1,
                        name: t('rejected_plural'),
                        count: rejected?.count || 0,
                        onClick: () => handleAdditionalFilter('rejected'),
                    },
                    {
                        as: 'action',
                        index: 2,
                        name: t('blacklisted'),
                        count: blacklisted?.count || 0,
                        onClick: () => handleAdditionalFilter('blacklisted'),
                    },
                ]}
                renderStatisticView={(filters, data, total, params, setFilters, setTotal, setData, fetchData) => (
                    <HiringRoomStats
                        data={data}
                        rejected={rejected}
                        blacklisted={blacklisted}
                        total={totalCandidates}
                        params={params}
                        filters={filters}
                        setFilters={setFilters}
                        fetchData={fetchData}
                        stages={stages}
                        setListView={setListView}
                        setSelectedStage={setSelectedStage}
                    />
                )}
                tableHeaders={[
                    { title: t('name') },
                    { title: t('contact') },
                    { title: t('cv') },
                    { title: t('source') },
                    { title: t('stage') },
                    { title: t('recruiters') },
                    { title: t('applied') },
                    isLastStage() && { title: t('status') },
                ]}
                renderCells={(candidate, setData) => [
                    { content: <PutAvatar row={candidate} setData={setData} room={room} /> },
                    {
                        content: (
                            <ContactCell
                                row={{ phone: candidate?.contacts?.[0]?.phone, email: candidate?.contacts?.[0]?.email }}
                            />
                        ),
                    },
                    {
                        content: candidate?.cv ? (
                            <>
                                <SuperDuperModal
                                    size="large"
                                    trigger={
                                        <Icon
                                            title={t('cv')}
                                            name="document-text-outline"
                                            style={{
                                                cursor: 'pointer',
                                                color: 'var(--dark)',
                                                fontSize: '1.5rem',
                                            }}
                                        />
                                    }
                                    content={<DocPreview uri={candidate?.cv} />}
                                />
                                <CanView permissions={['common.c_manage_all_candidates']}>
                                    <Icon
                                        onClick={() => removeFile(candidate, setData)}
                                        name="close"
                                        style={{
                                            marginLeft: '0.5rem',
                                            color: 'var(--danger)',
                                            cursor: 'pointer',
                                            position: 'relative',
                                            top: '-0.2rem',
                                        }}
                                    />
                                </CanView>
                            </>
                        ) : (
                            <CanView permissions={['common.c_manage_all_candidates']}>
                                <SuperDuperModal
                                    trigger={<Button size="tiny" basic content={t('add_cv')} />}
                                    content={
                                        <UploadFile
                                            field="cv"
                                            isInvoiceImport
                                            endpoint={API.CANDIDATES + candidate.id + '/cv/'}
                                            callback={(response) => {
                                                toast({
                                                    type: 'success',
                                                    icon: 'check circle',
                                                    title: t('cv_added'),
                                                    animation: 'pulse',
                                                    time: 2000,
                                                })

                                                setData((prev) =>
                                                    prev.filter((item) => {
                                                        if (item.id === candidate.id) {
                                                            item.cv = response.cv
                                                        }
                                                        return item
                                                    })
                                                )
                                            }}
                                        />
                                    }
                                />
                            </CanView>
                        ),
                    },
                    {
                        content: <HiringSourceManagement candidate={candidate} setData={setData} />,
                    },
                    {
                        content: (
                            <>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ display: 'inline-block' }}>
                                        {actionUp(candidate, setData)}
                                        <br />
                                        {allow_jump_over_stages && actionSelect(candidate, setData)}
                                        {allow_jump_over_stages && <br />}
                                        {actionDown(candidate, setData)}
                                    </span>
                                    <span
                                        style={{
                                            textAlign: 'left',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            paddingTop: '1.2rem',
                                        }}
                                    >
                                        <Label
                                            size="medium"
                                            style={{
                                                background: candidate?.current_stage?.color + '40',
                                                color: 'var(--dark)',
                                            }}
                                        >
                                            {candidate?.current_stage?.name}
                                        </Label>
                                        <small>
                                            {tzDateTime(candidate?.current_stage_date).format(dateFormat + ' HH:mm')}
                                        </small>
                                    </span>
                                </div>
                            </>
                        ),
                    },
                    {
                        content: (
                            <RecruitersList
                                candidate={candidate.id}
                                recruitment={params.id}
                                recruiters={candidate?.candidate_recruiters}
                                room={room}
                                key={candidate.id}
                            />
                        ),
                    },
                    { content: tzDateTime(candidate?.date_of_assignment).format(dateFormat + ' HH:mm') },
                    isLastStage() && {
                        content: (
                            <>
                                {candidate?.hr_contract?.id
                                    ? '#' +
                                      candidate?.hr_contract?.id +
                                      ' ' +
                                      t('contract') +
                                      ' - ' +
                                      candidate?.hr_contract?.status_display
                                    : candidate.profile_type === 1 &&
                                      candidate.current_stage?.id ===
                                          room?.archived_recruitment_stages?.[
                                              room?.archived_recruitment_stages.length - 1
                                          ]?.id
                                    ? t('non_converted_candidate')
                                    : candidate.profile_type === 2 &&
                                      candidate.current_stage?.id ===
                                          room?.archived_recruitment_stages?.[
                                              room?.archived_recruitment_stages.length - 1
                                          ]?.id &&
                                      candidate.onboardings.length !== 0
                                    ? t('onboarding') + ' - ' + candidate?.onboardings[0]?.status_display
                                    : candidate.profile_type === 2 &&
                                      candidate.current_stage?.id ===
                                          room?.archived_recruitment_stages?.[
                                              room?.archived_recruitment_stages.length - 1
                                          ]?.id &&
                                      candidate.onboardings.length === 0
                                    ? t('converted')
                                    : candidate?.profile_type === 1 && (
                                          <div style={{ fontWeight: 'lighter', fontSize: 12 }}>{t('candidate')}</div>
                                      )}
                            </>
                        ),
                    },
                ]}
            />
        </>
    )
}

const HiringSourceManagement = ({ candidate, setData }) => {
    const { t } = useTranslation()

    const [openPopup, setOpenPopup] = useState(0)
    const [processing, setProcessing] = useState(false)

    const canManage = useHasPermissions(['common.c_manage_all_candidates'])

    const handleChange = async (value) => {
        if (value) {
            setProcessing(true)
            const request = await requests.patch(API.CANDIDATES + candidate.id + '/?query={id, source}', {
                source: value,
            })
            if (request.status === 200) {
                setData((prev) =>
                    prev.filter((item) => {
                        if (item.id === candidate.id) {
                            item.source = request.response.source
                        }
                        return item
                    })
                )
                setOpenPopup(0)
            }
            setProcessing(false)
        }
    }

    return (
        <>
            {canManage ? (
                <Popup
                    hoverable
                    position="bottom left"
                    open={openPopup === candidate.id}
                    onOpen={() => setOpenPopup(candidate.id)}
                    onClose={() => setOpenPopup(0)}
                    trigger={
                        <span>
                            {candidate?.source?.name ? (
                                <Label style={{ background: candidate?.source?.color + '40', color: 'var(--dark)' }}>
                                    {candidate?.source?.name}
                                </Label>
                            ) : (
                                <span style={{ opacity: '0.6' }}>{t('not_specified')}</span>
                            )}
                        </span>
                    }
                    content={
                        <div>
                            <strong>{t('change_source')}:</strong>
                            <Form style={{ width: '200px' }}>
                                <SuperField
                                    as="choice-select"
                                    fluid
                                    search
                                    text="name"
                                    loading={processing}
                                    disabled={processing}
                                    endpoint={API.SOURCES}
                                    onChange={(e, { value }) => handleChange(value)}
                                />
                            </Form>
                        </div>
                    }
                />
            ) : (
                <span>
                    {candidate?.source?.name ? (
                        <Label style={{ background: candidate?.source?.color + '40', color: 'var(--dark)' }}>
                            {candidate?.source?.name}
                        </Label>
                    ) : (
                        <span style={{ opacity: '0.6' }}>{t('not_specified')}</span>
                    )}
                </span>
            )}
        </>
    )
}

const RatingManagement = ({ candidate, setData, room }) => {
    const { t } = useTranslation()

    const isMount = useIsMount()

    const user = useSelector((state) => state.user)
    const [openPopup, setOpenPopup] = useState(0)
    const [processing, setProcessing] = useState(false)
    const [rating, setRating] = useState(0)

    const canManage = useHasPermissions(['common.c_manage_all_candidates'])

    const loadUserRating = async () => {
        if (user?.profile_id) {
            setProcessing(true)
            const request = await requests.get(
                API.CANDIDATES +
                    candidate.id +
                    '/history/?recruitment=' +
                    room?.id +
                    '&archived_recruitment_stage=' +
                    candidate.current_stage?.id +
                    '&created_by=' +
                    user.profile_id +
                    '&action=3'
            )

            if (request.status === 200) {
                if (request.response.length) {
                    setRating(request.response[0].rating)
                } else {
                    setRating(0)
                }
            }
            setProcessing(false)
        }
    }

    const handleRate = async (rating) => {
        setProcessing(true)
        setRating(rating)
        const request = await requests.post(API.CANDIDATES + candidate.id + '/rating/', {
            rating: rating,
            recruitment: room.id,
            archived_recruitment_stage: candidate.current_stage.id,
        })

        if (request.status === 200 || request.status === 201) {
            setData((prev) =>
                prev.filter((item) => {
                    if (item.id === candidate.id) {
                        item.average_rating = request.response.average_in_this_recruitment
                    }
                    return item
                })
            )
            setOpenPopup(0)
        }
        setProcessing(false)
    }

    useEffect(() => {
        if (openPopup === candidate.id) {
            loadUserRating()
        }
    }, [openPopup])

    return (
        <>
            {canManage ? (
                <Popup
                    hoverable
                    position="bottom left"
                    open={openPopup === candidate.id}
                    onOpen={() => setOpenPopup(candidate.id)}
                    onClose={() => setOpenPopup(0)}
                    trigger={
                        <div>
                            <Rating rating={candidate?.average_rating || 0} maxRating={5} disabled />
                            {candidate?.average_rating || ' 0.0'}
                        </div>
                    }
                    content={
                        <div>
                            <div>
                                {t('stage')}: <strong>{candidate.current_stage?.name}</strong> <br />
                            </div>
                            <Divider />
                            <span>{t('set_candidate_rating')}</span>
                            <div style={{ marginTop: '0.5rem' }}>
                                <Rating
                                    size="huge"
                                    maxRating={5}
                                    rating={rating}
                                    disabled={processing}
                                    onRate={(e, data) => handleRate(data.rating)}
                                />
                                {rating || ' 0.0'}
                                {processing && (
                                    <Loader
                                        size="mini"
                                        className="dark-loader"
                                        active
                                        inline
                                        style={{ marginLeft: '1rem', position: 'relative', top: '-0.35rem' }}
                                    />
                                )}
                            </div>
                        </div>
                    }
                />
            ) : (
                <div>
                    <Rating rating={candidate?.average_rating || 0} maxRating={5} disabled />
                    {candidate?.average_rating || ' 0.0'}
                </div>
            )}
        </>
    )
}

export default StagesListView
