import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { setDotSeparator } from '@helpers/functions';
import { thousandsSeparators } from '@helpers/functions';
// components
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Form, Divider, Button, Segment, Header, Popup, Icon as SemanticIcon, Label } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import SuperField from '@components/forms/SuperField';

const isValidNumber = (number) => {
    if (number === "") return false
    if (isNaN(number)) return false
    if (parseFloat(number) < 0) return false

    return true
}

const DistancePriceCalculator = ({ form, setForm, sharedForm, setSharedForm, onClose }) => {
    const { t } = useTranslation()


    const unitPriceInitial = (isValidNumber(form.transport_price) && isValidNumber(sharedForm?.distance)) ? parseFloat(parseFloat(form.transport_price) / parseFloat(sharedForm?.distance)).toFixed(2) : ""

    const [distance, setDistance] = useState(sharedForm?.distance || "")
    const [unitPrice, setUnitPrice] = useState(isValidNumber(unitPriceInitial) ? unitPriceInitial : "")

    const onSubmit = (e) => {
        e.preventDefault()
    }

    const calculate = () => {
        let total = (parseFloat(unitPrice) * parseFloat(distance))

        setForm(prev => ({
            ...prev,
            transport_price: isValidNumber(parseFloat(total.toFixed(2))) ? total.toFixed(2) : ""
        }))

        if (setSharedForm) {
            setSharedForm(prev => ({
                ...prev,
                distance: distance
            }))
        }

        onClose()
    }

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group widths="equal">
                <SuperField as="input"
                    required
                    autoFocus
                    label={t('unit_price')}
                    value={unitPrice}
                    onChange={(e, { value }) => setUnitPrice(value.replace(",", ".")) }
                />
                <SuperField as="input"
                    required
                    label={t('distance')}
                    value={distance}
                    onChange={(e, { value }) => setDistance(value) }
                />
            </Form.Group>
            <div style={{ marginBottom: "0.5rem" }}> 
                <strong>{ t("total_sum") }:</strong> { thousandsSeparators((parseFloat(unitPrice) * parseFloat(distance)) || 0) } { form.currency || "EUR" } 
            </div>

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <Button 
                    primary
                    type="button"
                    content={t('calculate')}
                    onClick={() => calculate()}
                    disabled={!isValidNumber(unitPrice) || !isValidNumber(distance) }
                />
            </Form.Field>
        </Form>
    )
}

const CommodityForm = ({ sharedForm, setSharedForm, order, selectedCommodity, index, setSelectedCommodities, onClose, setHeader }) => {
    const { t } = useTranslation()

    const findCommodity = order.items.find(item => item.id === selectedCommodity?.order_commodity)

    const [additionalInfo, setAdditionalInfo] = useState(false)
    const [orderedItem, setOrderedItem] = useState(order.items.length === 1 ? order.items[0] : findCommodity || "")
    // const item = orderedItem?.item

    const [form, setForm] = useState({
        order: selectedCommodity?.order || order.id,
        order_commodity: selectedCommodity?.order_commodity || orderedItem.id,
        quantity: selectedCommodity?.quantity || 1,
        group: selectedCommodity?.group || "",
        transport_info: selectedCommodity?.transport_info || "",
        type_of_wagon: selectedCommodity?.type_of_wagon || "",
        quality: selectedCommodity?.quality || "",
        is_fragile: selectedCommodity?.is_fragile || false,
        measure_unit: selectedCommodity?.measure_unit || "",
        average_package_weight: selectedCommodity?.average_package_weight || "",
        measurement_z: selectedCommodity?.measurement_z || "",
        measurement_x: selectedCommodity?.measurement_x || "",
        measurement_y: selectedCommodity?.measurement_y || "",
        pallet_count: selectedCommodity?.pallet_count || "",
        weight_without_pallet: selectedCommodity?.weight_without_pallet || "",
        weight_total: selectedCommodity?.weight_total || "",
        package_total: selectedCommodity?.package_total || "",
        temperature_in_celsius: selectedCommodity?.temperature_in_celsius || "",
        addition_description_info: selectedCommodity?.addition_description_info || "",
        loading_price: selectedCommodity?.loading_price || "",
        price_paid: selectedCommodity?.price_paid || "",
        transport_price: selectedCommodity?.transport_price || "",
        currency: selectedCommodity?.currency || "EUR",
        note: selectedCommodity?.note || "",
    })

    useEffect(() => {
        if (orderedItem !== "") {
            let data = {
                order_commodity: orderedItem.id,
                measure_unit: orderedItem.item.measure_unit?.id || "",
                currency: orderedItem.currency,
                // [orderedItem.item.weight_is_primary_measurement ? "weight_total" : "package_total"]: 1
            }

            if (orderedItem.item.weight_is_primary_measurement === true) {
                data['average_package_weight'] = selectedCommodity?.average_package_weight ? selectedCommodity?.average_package_weight : orderedItem.item.average_package_weight || ""
            }
            
            setForm(prev => ({
                ...prev,
                ...data
            }))
        }

        // eslint-disable-next-line
    }, [orderedItem])

    const handleSelect = () => {
        if (selectedCommodity === undefined) {
            setSelectedCommodities(prev => ([...prev, form]))
        } else {
            setSelectedCommodities(prev => prev.map((item, idx) => {
                if (idx === index) {
                    item = form
                }
                return item
            }))
        }
        onClose()
    }

    const calculateTotalPrice = () => {
        let price = 0
        if (isValidNumber(form.quantity)) {
            price = parseFloat(parseFloat(form.quantity) * orderedItem.price_without_tax)
        }
        if (isValidNumber(form.transport_price)) {
            price = price + parseFloat(form.transport_price)
        }
        if (isValidNumber(form.loading_price)) {
            price = price + parseFloat(form.loading_price)
        }

        return parseFloat(price).toFixed(2)
    }

    useEffect(() => {
        if (orderedItem) {
            setForm(prev => ({
                ...prev,
                price_paid: calculateTotalPrice()
            }))
        }
        // eslint-disable-next-line
    }, [orderedItem, form.quantity, form.transport_price, form.loading_price])

    const calculateValues = (quantity, packages, weight, average_weight, toChange) => {
        const isWeightPrimary = orderedItem.item.weight_is_primary_measurement
        packages = !isWeightPrimary ? quantity : packages 
        weight = isWeightPrimary ? quantity : weight

        if (isValidNumber(weight) && isValidNumber(packages) && toChange !== "average_weight") {
            average_weight = parseFloat(parseFloat(weight) / parseFloat(packages)).toFixed(4).replace(".00", "")
        }

        if (isValidNumber(weight) && isValidNumber(average_weight) && toChange !== "packages" && isWeightPrimary) { 
            packages = parseFloat(parseFloat(weight) / parseFloat(average_weight)).toFixed(2).replace(".00", "")
        }

        if (isValidNumber(packages) && isValidNumber(average_weight) && toChange !== "weight" && !isWeightPrimary) { 
            weight = parseFloat(parseFloat(packages) * parseFloat(average_weight)).toFixed(4).replace(".00", "")
        }
        
        return {
            quantity: quantity,
            package_total: packages,
            weight_total: weight,
            average_package_weight: average_weight,
        }
    }


    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            { order.items.length === 0 
                ? 
                    <p style={{ textAlign: "center", fontWeight: "bold" }}>
                        { t('order_does_not_contain_any_commodities') }
                    </p>
                :
                    findCommodity !== undefined 
                    ? ""
                    :
                    <p style={{ textAlign: "left", fontWeight: "bold", marginTop: 0 }}>
                        { t('select_commodity') } <sup style={{ color: "var(--danger)" }}>*</sup>
                    </p>
            }

            { order.items.map(orderItem => (
                <Segment style={{ paddingTop: "0.7rem", paddingBottom: "0.7rem", display: orderedItem.id !== orderItem.id && orderedItem !== "" ? "none" : "block" }}>
                    <div 
                        style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", cursor: "pointer", width: "100%" }} 
                        onClick={() => {
                        if (findCommodity === undefined && orderedItem.id === orderItem.id && order.items.length !== 1){
                            setOrderedItem("")
                        } else {
                            setOrderedItem(orderItem)
                        }
                    }}>
                        <div style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
                            <Form.Field style={{ marginBottom: 0 }}>
                                <SuperField
                                    key={orderItem.id} as="radio"
                                    disabled={orderedItem.id !== orderItem.id && orderedItem !== ""}
                                    style={{ marginRight: "1rem", marginBottom: 0 }}
                                    checked={orderedItem.id === orderItem.id}
                                />
                            </Form.Field>
                            <div>
                                {`${orderItem.item.code ? orderItem.item.code + " - " : ""} ${orderItem.item.title}`}
                                { findCommodity === undefined && orderedItem.id === orderItem.id && order.items.length !== 1 && 
                                    <small style={{ color: "var(--danger)" }}>
                                        <br/> { t('clear_selection') }
                                    </small>
                                }
                            </div>
                        </div>
                        <Label basic size="small">{ t('measurement_type') }: <span style={{ fontWeight: "normal" }}>{ orderedItem?.item?.weight_is_primary_measurement ? t("weight") : t("packages_pieces") }</span></Label>
                    </div>
                    { orderedItem.id === orderItem.id &&
                        <>
                            <Divider style={{ marginTop: "0.5rem" }}/>
                            <Form.Group widths="equal" style={{ marginBottom: "0.5rem" }}>
                                <SuperField as="input"
                                    required
                                    autoFocus
                                    name="quantity"
                                    label={t('quantity')}
                                    value={form.quantity}
                                    error={!isValidNumber(form.quantity) && form.quantity !== "" ? t('not_a_number') : false}
                                    onChange={(e, { value }) => setForm(prev => ({
                                        ...prev,
                                        ...calculateValues(setDotSeparator(value), form.package_total, form.weight_total, form.average_package_weight)
                                    }))}
                                />

                                <SuperField as="input"
                                    name="average_package_weight"
                                    label={t('average_package_weight')}
                                    error={!isValidNumber(form.average_package_weight) && form.average_package_weight !== "" ? t('not_a_number') : false}
                                    value={form.average_package_weight}
                                    onChange={(e, { value }) => setForm(prev => ({
                                        ...prev,
                                        ...calculateValues(form.quantity, form.package_total, form.weight_total, setDotSeparator(value), "average_weight")
                                    }))}
                                />
                            </Form.Group>

                            <Form.Group widths="equal">
                                <SuperField as="input"
                                    label={orderedItem.item.weight_is_primary_measurement === true ? t('num_of_packages') : t('total_weight')}
                                    error={!isValidNumber(orderedItem.item.weight_is_primary_measurement === true ? form.package_total : form.weight_total) && (orderedItem.item.weight_is_primary_measurement === true ? form.package_total : form.weight_total) !== "" ? t('not_a_number') : undefined}
                                    value={orderedItem.item.weight_is_primary_measurement === true ? form.package_total : form.weight_total}
                                    onChange={(e, { value }) => {
                                        let toChange = orderedItem.item.weight_is_primary_measurement === true ? "packages" : "weight"
                                        let weight = orderedItem.item.weight_is_primary_measurement === true ? form.weight_total : setDotSeparator(value) 
                                        let packages = orderedItem.item.weight_is_primary_measurement === true ? setDotSeparator(value)  : form.package_total  
                                        setForm(prev => ({
                                            ...prev, 
                                            ...calculateValues(form.quantity, packages, weight, form.average_package_weight, toChange),
                                        }))
                                    }}
                                />
                                <SuperField as="choice"
                                    search
                                    name="measure_unit"
                                    text="abbreviation"
                                    label={t('measure_unit')}
                                    help={t('measure_unit_hint')}
                                    endpoint={API.COMMON + "measure_units/"}
                                    value={form.measure_unit}
                                    onChange={(e, { value }) => setForm(prev => ({...prev, measure_unit: value}))}
                                />
                            </Form.Group>

                            <>
                                <div 
                                    className="ref-link"
                                    style={{ 
                                        color: "var(--primary)",
                                        marginBottom: "0.5rem"
                                    }}
                                    onClick={() => setAdditionalInfo(!additionalInfo)}
                                >
                                    { additionalInfo ? t('hide_additional_details') : t('show_additional_details') }
                                </div>
                                
                                { additionalInfo &&
                                    <>
                                        <Divider/>
                                        <SuperField as="input"
                                            name="weight_without_pallet"
                                            label={t('weight_without_pallet')}
                                            error={(form.weight_without_pallet < 0 || isNaN(form.weight_without_pallet)) ? t('not_a_number') : false}
                                            value={form.weight_without_pallet}
                                            onChange={(e, { value }) => setForm(prev => ({...prev, weight_without_pallet: setDotSeparator(value)}))}
                                        />
                                        <Form.Group widths="equal">
                                            <SuperField as="input"
                                                label={t('width')}
                                                name="measurement_x"
                                                error={(form.measurement_x < 0 || isNaN(form.measurement_x)) ? t('not_a_number') : false}
                                                value={form.measurement_x}
                                                onChange={(e, { value }) => setForm(prev => ({...prev, measurement_x: setDotSeparator(value)}))}
                                            />
                                            <SuperField as="input"
                                                label={t('height')}
                                                name="measurement_y"
                                                error={(form.measurement_y < 0 || isNaN(form.measurement_y)) ? t('not_a_number') : false}
                                                value={form.measurement_y}
                                                onChange={(e, { value }) => setForm(prev => ({...prev, measurement_y: setDotSeparator(value)}))}
                                            />
                                            <SuperField as="input"
                                                name="measurement_z"
                                                label={t('depth')}
                                                error={(form.measurement_z < 0 || isNaN(form.measurement_z)) ? t('not_a_number') : false}
                                                value={form.measurement_z}
                                                onChange={(e, { value }) => setForm(prev => ({...prev, measurement_z: setDotSeparator(value)}))}
                                            />
                                        </Form.Group>
                                        <Form.Group widths="equal">
                                            <SuperField as="input"
                                                name="quality"
                                                label={t('quality')}
                                                value={form.quality}
                                                onChange={(e, { value }) => setForm(prev => ({...prev, quality: value}))}
                                            />
                                            <SuperField as="input"
                                                label={t('temperature')}
                                                name="temperature_in_celsius"
                                                value={form.temperature_in_celsius}
                                                onChange={(e, { value }) => setForm(prev => ({...prev, temperature_in_celsius: setDotSeparator(value)}))}
                                            />
                                            <SuperField as="input"
                                                name="pallet_count"
                                                label={t('pallet_count')}
                                                error={(form.pallet_count < 0 || isNaN(form.pallet_count)) ? t('not_a_number') : false}
                                                value={form.pallet_count}
                                                onChange={(e, { value }) => setForm(prev => ({...prev, pallet_count: setDotSeparator(value)}))}
                                            />
                                        </Form.Group>
    
                                        <SuperField as="input"
                                            name="addition_description_info"
                                            label={t('addition_description_info')}
                                            value={form.addition_description_info}
                                            onChange={(e, { value }) => setForm(prev => ({...prev, addition_description_info: value}))}
                                        />
    
                                        <SuperField as="checkbox"
                                            name="is_fragile"
                                            label={t('fragile')}
                                            value={form.is_fragile}
                                            onChange={() => setForm(prev => ({...prev, is_fragile: !form.is_fragile}))}
                                        />
    
                                        <Divider/>
                                    </>
                                }
                            </>


                            <Divider/>
                            <Header as="h4" content={t('location_in_transport')}/>
                            <Form.Group widths="equal">
                                <SuperField as="input"
                                    name="transport_info"
                                    help={t('transport_info_hint')}
                                    label={t('trans_number')}
                                    value={form.transport_info}
                                    onChange={(e, { value }) => setForm(prev => ({...prev, transport_info: value}))}
                                />
                                <SuperField as="input"
                                    label={t('logistic_vehicle_type_hint')}
                                    value={form.type_of_wagon}
                                    onChange={(e, { value }) => setForm(prev => ({...prev, type_of_wagon: value}))}
                                />
                                <SuperField as="input"
                                    label={t('group') + " / " + t('RWB')}
                                    value={form.group}
                                    onChange={(e, { value }) => setForm(prev => ({...prev, group: value}))}
                                />
                            </Form.Group>
                            <Divider/>

                            <Header as="h4" content={
                                <div>
                                    { t('price') } <br/>
                                    <span style={{ color: "var(--danger)" }}>{ t('logistic_price_excluding_tax_hint') }!</span>
                                </div>
                            }/>

                            <Form.Group widths="equal">
                                <SuperField as="input"
                                    name={'transport_price'}
                                    label={t('transport_price')}
                                    value={form.transport_price}
                                    error={(form.transport_price < 0 || isNaN(form.transport_price)) ? t('not_a_number') : false}
                                    icon={
                                        <SuperDuperModal 
                                            size="tiny"
                                            header={t('hint_calculate_price_from_distance')}
                                            style={{ marginTop: "2rem" }}
                                            trigger={
                                                <SemanticIcon name="calculator" link>
                                                    <Popup
                                                        trigger={
                                                            <SemanticIcon style={{ paddingTop: "0.8rem", paddingLeft: "0.3rem", opacity: 0 }} name='calculator'/>
                                                        }
                                                        content={t('hint_calculate_price_from_distance')}
                                                    />
                                                </SemanticIcon>
                                            }
                                            content={
                                                <DistancePriceCalculator 
                                                    form={form}
                                                    setForm={setForm}
                                                    sharedForm={sharedForm}
                                                    setSharedForm={setSharedForm}
                                                />
                                            }
                                        />
                                    }
                                    onChange={(e, { value }) => setForm(prev => ({...prev, transport_price: setDotSeparator(value)}))}
                                />
                                <SuperField as="input"
                                    name="loading_price"
                                    error={(form.loading_price < 0 || isNaN(form.loading_price)) ? t('not_a_number') : false}
                                    label={t('loading_price')}
                                    value={form.loading_price}
                                    onChange={(e, { value }) => setForm(prev => ({...prev, loading_price: setDotSeparator(value)}))}
                                />
                            </Form.Group>

                            <Form.Group widths="equal">
                                <Form.Field>
                                    <SuperField as="input"
                                        name="price_paid"
                                        label={t('paid_price')}
                                        help={t('automatic_calculation_based_on_hint')}
                                        error={(form.price_paid < 0 || isNaN(form.price_paid)) ? t('not_a_number') : false}
                                        value={form.price_paid}
                                        onChange={(e, { value }) => setForm(prev => ({...prev, price_paid: setDotSeparator(value)}))}
                                    />
                                    <p style={{ marginTop: "0.5rem" }}>
                                        <strong>{ t('unit_price') }:</strong> { orderedItem.price_without_tax } { form.currency }
                                    </p>
                                </Form.Field>
                                <SuperField as="choice"
                                    search
                                    disabled
                                    clearable={false}
                                    name="currency"
                                    type="currency_codes"
                                    label={t('currency')}
                                    value={form.currency}
                                    onChange={(e, { value }) => setForm(prev => ({...prev, currency: value}))}
                                />
                            </Form.Group>

                            <Divider/>

                            <SuperField as="textarea"
                                label={t('note')}
                                value={form.note}
                                onChange={(e, { value }) => setForm(prev => ({...prev, note: value}))}
                            />
                        </>
                    }
                </Segment>
            ))}

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <Button 
                    primary
                    type="button"
                    disabled={orderedItem === null || orderedItem === ""}
                    onClick={() => handleSelect()}
                >
                    { t('confirm') }
                </Button>
            </Form.Field>
        </Form>
    );
};

export default CommodityForm;