import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next"
// components
import Icon from '@components/Icon';
import { Button, Popup } from 'semantic-ui-react';
import ConfirmModal from '@components/modals/ConfrimModal';
import OutlineAddButton from '@components/buttons/OutlineAddButton'
import SuperDuperModal from '@components/modals/SuperDuperModal';

/*
AS - props `as` that is used to define render type, currently supported:
    - "modal" (SuperDuperModal)
    - "link" (Link)
    - "delete" (Delete confirm modal)
    - "confirm" (Confirm modal)
    - "custom" (To customize action)
TYPE - props `type` is used to define icon or button or buttonWithIcon
    - "button" need content - buttonText
    - "icon" need to define name of icon
    - "buttonWithIcon" need buttonText and icon
    - "addButton" = <OutlineAddButton /> need text
TO - props `to` is used to define URL
ICON - props `icon` is used to define icon name
MODAL - props `modal` is used to define component
BUTTONTEXT - props `buttonText` is used to define content of button
TOOLTIP - props `tooltip` is used to define popup content
TEXT - props `text` is used to define text which is used in component
SIZE - props `size` is used to define size of component
ONCLICK - props `onClick` is used to define action
BACKGROUNDCOLOR - props `backgroundColor` is used to define background color of component style
PADDINGLEFT & PADDINGRIGHT - props `paddingLeft` and `paddingRight` are used to define padding in style of component
*/

const Action = ({ as, type, to, icon, modal, centered, setForceModalOpen, buttonText, tooltip, iconColor, text, size, modalSize, onClick, backgroundColor, opacity, padding, paddingRight, paddingLeft, paddingTop, paddingBottom, disabled, ...rest }) => {

    const { t } = useTranslation();

    const isDisabled = disabled !== undefined ? disabled : false

    const RenderModal = () => {
        return (
            <SuperDuperModal
                centered={centered === undefined ? true : centered}
                trigger={
                    <Button type="button" disabled={isDisabled} style={{ padding: 0, margin: 0, background: "transparent", border: "none", boxShadow: "none" }} onClick={setForceModalOpen ? () => setForceModalOpen() : null}>
                        {
                            type === 'button' ? <Button disabled={isDisabled} type="button" size={size ? size : "small"} primary content={buttonText} style={{
                                paddingRight: paddingRight ? paddingRight : '0.785714em', paddingLeft: paddingLeft ? paddingLeft : '0.785714em', paddingTop: paddingTop ? paddingTop : '0.785714em', paddingBottom: paddingBottom ? paddingBottom : '0.785714em'
                            }} /> :
                                type === 'icon' ? <Button disabled={isDisabled} type="button" size={size ? size : "small"} icon={<Icon name={icon} style={{ color: iconColor ? iconColor : "var(--info)" }} />} style={{
                                    background: "none", paddingRight:
                                        paddingRight ? paddingRight : '0.785714em', paddingLeft: paddingLeft ? paddingLeft : '0.785714em', paddingTop: paddingTop ? paddingTop : '0.785714em', paddingBottom: paddingBottom ? paddingBottom : '0.785714em'
                                }} /> :
                                    type === 'addButton' ? <OutlineAddButton disabled={isDisabled} color="violet" text={buttonText ? buttonText : t('add')} size="small" style={{
                                        paddingRight: paddingRight ? paddingRight : '0.785714em', paddingLeft: paddingLeft ? paddingLeft : '0.785714em'
                                    }} /> :
                                        type === 'customIcon' && <Button disabled={isDisabled} type="button" icon={icon} style={{
                                            background: "none", paddingRight:
                                                paddingRight ? paddingRight : '0', paddingLeft: paddingLeft ? paddingLeft : '0', color: iconColor ? iconColor : "var(--info)",
                                        }} >
                                            <Icon name={icon} /> </Button>
                        }
                    </Button>
                }
                content={modal}
                size={modalSize || "small"}
                disabled={isDisabled} 
                {...rest}
            />
        );
    }

    const RenderConfirm = () => {
        return (
            <ConfirmModal
                description={text}
                onConfirm={onClick}
                button={
                    type === 'icon' ?
                        <Button disabled={isDisabled} size={size ? size : "small"} icon={<Icon name={icon} />} {...rest}
                            style={{
                                background: backgroundColor ? backgroundColor : "none",
                                color: iconColor ? iconColor : "var(--info)",
                                paddingRight: paddingRight ? paddingRight : 0,
                                paddingLeft: paddingLeft ? paddingLeft : 0,
                                paddingTop: paddingTop ? paddingTop : 0,
                                paddingBottom: paddingBottom ? paddingBottom : 0
                            }}
                        />
                        : type === 'button' ?
                            <Button disabled={isDisabled} size={size ? size : "small"} content={buttonText} {...rest}
                                style={{
                                    paddingRight: paddingRight ? paddingRight : 0,
                                    paddingLeft: paddingLeft ? paddingLeft : 0,
                                    paddingTop: paddingTop ? paddingTop : 0,
                                    paddingBottom: paddingBottom ? paddingBottom : 0
                                }}
                            />
                            :
                            <Button disabled={isDisabled} size={size ? size : "small"} icon={<Icon name={icon} />} content={buttonText} {...rest}
                                style={{
                                    background: backgroundColor ? backgroundColor : "none",
                                    color: iconColor ? iconColor : "var(--info)",
                                    paddingRight: paddingRight ? paddingRight : 0,
                                    paddingLeft: paddingLeft ? paddingLeft : 0,
                                    paddingTop: paddingTop ? paddingTop : 0,
                                    paddingBottom: paddingBottom ? paddingBottom : 0
                                }}
                            />
                }
            />
        );
    }

    const RenderCustom = () => {
        return (
            <>
                {type === 'icon' ?
                    <Button className={rest.className} disabled={isDisabled} onClick={onClick} {...rest} size={size ? size : "small"}
                        style={{ background: backgroundColor ? backgroundColor : "none", color: iconColor ? iconColor : "var(--info)", paddingRight: paddingRight ? paddingRight : 0, paddingLeft: paddingLeft ? paddingLeft : 0, paddingTop: paddingTop ? paddingTop : '', paddingBottom: paddingBottom ? paddingBottom : '', opacity: opacity ? opacity : 1 }}
                    > {size ? <Icon name={icon} className={rest.className} /> :
                        <Icon name={icon} className={rest.className}/>
                        }
                    </Button>
                    : type === 'button' ?
                        <Button {...rest}  disabled={isDisabled} onClick={onClick} content={buttonText} size={size ? size : "small"}
                            style={{ background: backgroundColor ? backgroundColor : "none", color: iconColor ? iconColor : "var(--info)", paddingRight: paddingRight ? paddingRight : 0, paddingLeft: paddingLeft ? paddingLeft : 0 }}
                        />
                        :
                        <Button {...rest} disabled={isDisabled} onClick={onClick} content={buttonText} size={size ? size : "small"} icon={<Icon name={icon} />}
                            style={{ background: backgroundColor ? backgroundColor : "none", color: iconColor ? iconColor : "var(--info)", paddingRight: paddingRight ? paddingRight : 0, paddingLeft: paddingLeft ? paddingLeft : 0 }}
                        />
                }
            </>
        );
    }

    const RenderLink = () => {
        return (
            <>
                {type === 'icon' ?
                    <Button {...rest} disabled={isDisabled} icon={icon ? <Icon name={icon} /> : <Icon name={"eye-outline"} />} as={Link} to={to} size={size}
                        style={{ background: "none", color: iconColor ? iconColor : "var(--info)", paddingRight: paddingRight ? paddingRight : 0, paddingLeft: paddingLeft ? paddingLeft : 0, paddingTop: paddingTop ? paddingTop : 0, paddingBottom: paddingBottom ? paddingBottom : 0 }}
                    />
                    : type === 'button' ?
                        <Button {...rest} disabled={isDisabled} as={Link} to={to} content={buttonText} size={size}
                            style={{ background: backgroundColor ? backgroundColor : "none", paddingRight: '1rem', paddingLeft: '1rem' }}
                        />
                        :
                        <Button {...rest} disabled={isDisabled} as={Link} to={to} content={buttonText} size={size} icon={<Icon name={icon} />}
                            style={{ background: backgroundColor ? backgroundColor : "none", paddingRight: '1rem', paddingLeft: '1rem' }}
                        />
                }
            </>
        );
    }

    const RenderDelete = () => {
        return (
            <ConfirmModal
                description={text}
                onConfirm={onClick}
                button={
                    <Button
                        {...rest}
                        size={size ? size : "small"}
                        icon={icon ? <Icon name={icon} /> : <Icon name={"close-outline"} />}
                        style={{
                            background: "none",
                            padding: padding ? padding : '0.785714em',
                            color: iconColor ? iconColor : "var(--danger)",
                            paddingRight: paddingRight ? paddingRight : '0.785714em',
                            paddingLeft: paddingLeft ? paddingLeft : '0.785714em',
                            paddingTop: paddingTop ? paddingTop : 0, 
                            paddingBottom: paddingBottom ? paddingBottom : 0
                        }}
                    />
                }
            />
        );
    }

    const render = () => {
        switch (as) {
            case "modal":
                return (
                    tooltip
                        ? <Popup style={{ zIndex: 2 }} flowing size="small" inverted position="top center" hoverable
                            content={tooltip}
                            trigger={<span> <RenderModal /> </span>} />
                        : <RenderModal />
                );
            case "link":
                return (
                    tooltip
                        ? <Popup style={{ zIndex: 2 }} flowing size="small" inverted position="top center" hoverable
                            content={tooltip}
                            trigger={<span><RenderLink /></span>} />
                        : <RenderLink />
                );
            case "delete":
                return (
                    tooltip
                        ? <Popup style={{ zIndex: 2 }} flowing size="small" inverted position="top center" hoverable
                            content={tooltip}
                            trigger={<span><RenderDelete /></span>} />
                        : <RenderDelete />
                );
            case "confirm":
                return (
                    tooltip
                        ? <Popup style={{ zIndex: 2 }} flowing size="small" inverted position="top center" hoverable
                            content={tooltip}
                            trigger={<span> <RenderConfirm /> </span>} />
                        : <RenderConfirm />
                );
            case "custom":
                return (
                    tooltip
                        ? <Popup style={{ zIndex: 3 }} flowing size="small" inverted position="top center" hoverable
                            content={tooltip}
                            trigger={<span><RenderCustom /></span>} />
                        : <RenderCustom />
                );
            default:
                return <></>;
        }
    };

    return <>{render()}</>;

};

export default Action;