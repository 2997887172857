import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
import { useHistory } from 'react-router';
// store
import { icons } from '@icons';
import { routes } from '@routes';
import { isEmpty } from '@helpers/validation';
import { commonService } from '@services/ServiceCommon';
import { employeesService } from '@services/ServiceEmpoyees';
// components
import Loading from '@components/general/Loading';
import BreadcrumbNav from '@components/BreadcrumbNav';
import { Container, Form, Grid } from 'semantic-ui-react';
// module specific components
import PersonView from '../components/forms/create/views/PersonView';
import SummaryView from '../components/forms/create/views/SummaryView';
import MultiStepWizzard from '../components/forms/create/MultiStepWizzard';
import AddressesView from '../components/forms/create/views/AddressesView';
import BirthInformationView from '../components/forms/create/views/BirthInformationView';
import CanView from '@components/perms/CanView';

const CreateAnEmployeeForm = () => {
    const { t } = useTranslation();

    const breadcrumb_items = [
        { 'name': t("employees"), 'icon': icons.EMPLOYEES, 'href': routes.EMPLYOEES_LIST },
        { 'name': t("hire_employee"), 'icon': 'user', 'href': '' }
    ]
    const [errors, setErrors] = useState([]);

    // Personal Information (Person)
    const [person, setPerson] = useState({
        firstname: '',
        lastname: '',
        middlename: '',
        titleBefore: '',
        titleAfter: '',
        gender: '',
        maritalStatus: '',
        personalNumber: '',
        contact: {
            phone: '',
            phoneType: '',
            email: '',
        },
    });

    // Personal Information (Birth Info)
    const [birthInfo, setBirthInfo] = useState({
        dateOfBirth: '',
        placeOfBirth: '',
        nameAtBirth: '',
        countryOfBirth: '',
        citizenship: '',
        nationality: '',
        allPreviousSurnames: '',
    });

    // Personal Informaton (Contact Info)
    // const [emails, setEmails] = useState([]);
    // const [phones, setPhones] = useState([]);

    // Permanent address
    const [permanentAddress, setPermanentAddress] = useState({
        street: '',
        city: '',
        postcode: '',
        country: '',
        state: '',
        addressLine: '',
    });

    // Temporary address
    const [temporaryAddress, setTemporaryAddress] = useState({
        street: '',
        city: '',
        postcode: '',
        country: '',
        state: '',
        addressLine: '',
    });

    const [isExternal, setIsExternal] = useState(false)


    // Form states:
    // const [isProcessing, setIsProcessing] = useState(false);
    const [currentView, setCurrentView] = useState(1);
    const [isLoading/*, setIsLoading*/] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const history = useHistory();

    const handleSubmit = async () => {
        // setIsCreated(true);
        setIsProcessing(true);

        // 1. Create Birth Information
        const birthInfoResult = await commonService.createBirthInfo({
            date_of_birth: birthInfo.dateOfBirth,
            place_of_birth: birthInfo.placeOfBirth,
            country_of_birth: birthInfo.countryOfBirth,
            citizenship: birthInfo.citizenship,
            nationality: birthInfo.nationality,
            all_previous_surnames: birthInfo.allPreviousSurnames,
            name_at_birth: birthInfo.nameAtBirth
        });


        if (birthInfoResult.status === 201) {
            // 2. Create Person
            const personResult = await commonService.createPerson({
                firstname: person.firstname,
                lastname: person.lastname,
                middlename: person.middlename,
                titles_before: person.titleBefore,
                titles_after: person.titleAfter,
                gender: person.gender,
            })


            // 3. Create Permanent Address
            const permanentAddressResult = await commonService.createAddress({
                street: permanentAddress.street,
                city: permanentAddress.city,
                postcode: permanentAddress.postcode,
                country: permanentAddress.country,
                state: permanentAddress.state,
                address_line: permanentAddress.addressLine,
            })

            // 3.1 Create Temporary Address
            let temporaryAddressResult = null;
            if (temporaryAddress.street) {
                temporaryAddressResult = await commonService.createAddress({
                    street: temporaryAddress.street,
                    city: temporaryAddress.city,
                    postcode: temporaryAddress.postcode,
                    country: temporaryAddress.country,
                    state: temporaryAddress.state,
                    address_line: temporaryAddress.addressLine,
                })
            }

            // 4. Create Personal Information
            if (personResult.status === 201 &&
                birthInfoResult.status === 201 &&
                permanentAddressResult.status === 201
            ) {

                // 4.1 Create Phone/Email
                const phoneResult = await commonService.createPhoneInfo({
                    phone: person.contact.phone,
                    phone_type: person.contact.phoneType
                })

                let emailResult = null;
                if (!isEmpty(person.contact.email)) {
                    emailResult = await commonService.createEmailInfo(person.contact.email)
                }

                // 4.2 Create Contact Info
                let contactInfoResult = null;
                if (phoneResult.status === 201) {
                    let contactInfoData = {
                        phones: { add: [phoneResult.response.id] }
                    }

                    if (emailResult !== null && emailResult.status === 201) {
                        contactInfoData.emails = { add: [emailResult.response.id] }
                    }

                    contactInfoResult = await commonService.createContactInfo(contactInfoData);
                }
                // 4.3 Create Personal Information


                let personalInformationData = {
                    personal_number: person.personalNumber,
                    person: personResult.response.id,
                    birth_info: birthInfoResult.response.id,
                    permanent_address: permanentAddressResult.response.id,
                    marital_status: parseInt(person.maritalStatus),
                    contact_info: contactInfoResult === null ? contactInfoResult : (contactInfoResult.status === 201) ? contactInfoResult.response.id : null,
                    temporary_address: temporaryAddressResult === null ? temporaryAddressResult : (temporaryAddressResult.status === 201) ? temporaryAddressResult.response.id : null
                };

                const resultPersonal = await employeesService.createPersonalInformation(personalInformationData);

                if (resultPersonal.status === 201) {
                    // 5. Create Profile
                    const result = await employeesService.createProfile({
                        profile_type: 2,
                        is_external: isExternal,
                        personal_information: resultPersonal.response.id
                    })

                    if (result.status === 201) {
                        history.push(routes.EMPLYOEES_DETAIL + result.response.id)
                        return;
                    }
                } else {
                    let personalInformationErrors = []
                    if (resultPersonal.response.personal_number) {
                        for (let i = 0; i < resultPersonal.response.personal_number.length; i++) {
                            personalInformationErrors.push(resultPersonal.response.personal_number[i]);
                        }
                    }

                    if (resultPersonal.response.non_field_errors) {
                        for (let i = 0; i < resultPersonal.response.non_field_errors.length; i++) {
                            personalInformationErrors.push(resultPersonal.response.non_field_errors[i]);
                        }
                    }
                    setErrors([...errors, personalInformationErrors]);
                }
            }
        } else {
            let birthInfoErrors = []
            for (let i = 0; i < birthInfoResult.response.date_of_birth.length; i++) {
                birthInfoErrors.push(birthInfoResult.response.date_of_birth[i]);
            }
            setErrors([...errors, birthInfoErrors]);
        }

        setIsProcessing(false);
    }

    return (
        <CanView permissions={["common.c_manage_all_employees"]}>
            <BreadcrumbNav items={breadcrumb_items} />
            {isLoading ? <Loading /> :
                <Container fluid>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer={12} tablet={12} mobile={16}>
                                <Form onSubmit={() => handleSubmit()} style={{ background: "var(--light)", padding: "2rem", paddingBottom: "3.5rem" }}>
                                    <PersonView visible={currentView === 1} person={person} setPerson={setPerson} isExternal={isExternal} setIsExternal={setIsExternal} setView={setCurrentView} />
                                    <BirthInformationView visible={currentView === 2} birthInfo={birthInfo} setBirthInfo={setBirthInfo} setView={setCurrentView} />
                                    <AddressesView visible={currentView === 3} setView={setCurrentView}
                                        permanentAddress={permanentAddress} setPermanentAddress={setPermanentAddress}
                                        temporaryAddress={temporaryAddress} setTemporaryAddress={setTemporaryAddress}
                                    />
                                    <SummaryView
                                        isProcessing={isProcessing}
                                        visible={currentView === 4}
                                        person={person}
                                        isExternal={isExternal}
                                        birthInfo={birthInfo}
                                        permanentAddress={permanentAddress}
                                        temporaryAddress={temporaryAddress}
                                        errors={errors}
                                    />
                                </Form>
                            </Grid.Column>
                            <Grid.Column computer={4} tablet={4} mobile={16}>
                                <MultiStepWizzard
                                    isProcessing={isProcessing}
                                    currentView={currentView}
                                    setCurrentView={setCurrentView}
                                    person={person}
                                    isExternal={isExternal}
                                    birthInfo={birthInfo}
                                    permanentAddress={permanentAddress}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            }
        </CanView>
    );
};

export default CreateAnEmployeeForm;