import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { icons } from '@store/icons'
// components
import { Container, Grid } from 'semantic-ui-react'
import { FlexRow, FlexItem } from '@components/tables/FlexTable'
import BreadcrumbNav from '@components/BreadcrumbNav'
import Loading from '@components/general/Loading'
import ReportSection from '../components/ReportSection'

const ReportList = () => {
    const { t } = useTranslation()
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false)
    const [isHRActive, setIsHRActive] = useState(true)
    const [isPMActive, setIsPMActive] = useState(true)
    const [isSMActive, setIsSMActive] = useState(true)
    const [isOperativeActive, setIsOperativeActive] = useState(true)

    const FilterAction = ({ text, isActive, section, ...rest }) => {
        return (
            <>
                {reports.filter((report) => report.section === section).length > 0 && (
                    <span className={'filter-action ' + (isActive && 'active')} {...rest}>
                        {text}
                    </span>
                )}
            </>
        )
    }

    // eslint-disable-next-line
    const [reports, setReports] = useState([
        {
            slug: 'candidates-by-stage',
            name: t('candidates_by_stage'),
            category: t('hiring_room'),
            section: 'HR',
            isActive: true,
            canView: ['reports.c_view_recruitments_reports'],
        },
        {
            slug: 'candidate-stage-time',
            name: t('candidates_stage_time'),
            category: t('hiring_room'),
            section: 'HR',
            isActive: true,
            canView: ['reports.c_view_recruitments_reports'],
        },
        {
            slug: 'recruiter-stats',
            name: t('recruiter_stats'),
            category: t('hiring_room'),
            section: 'HR',
            isActive: true,
            canView: ['reports.c_view_recruitments_reports'],
        },
        {
            slug: 'candidate-rejections',
            name: t('candidate_rejections'),
            category: t('hiring_room'),
            section: 'HR',
            isActive: true,
            canView: ['reports.c_view_recruitments_reports'],
        },
        {
            slug: 'candidates-interactions',
            name: t('candidates_interactions'),
            category: t('hiring_room'),
            section: 'HR',
            isActive: true,
            canView: ['reports.c_view_recruitments_reports'],
        },
        {
            slug: 'published-by-recruiter',
            name: t('published_by_recruiter'),
            category: t('jobs'),
            section: 'HR',
            isActive: true,
            canView: ['reports.c_view_recruitments_reports'],
        },
        {
            slug: 'publications-sources',
            name: t('publications_sources'),
            category: t('jobs'),
            section: 'HR',
            isActive: true,
            canView: ['reports.c_view_recruitments_reports'],
        },
        // {
        //     slug: "fluctuations",
        //     name: "Fluctuations",
        //     category: t('contracts'),
        //     section: "HR",
        //     isActive: false,
        //     canView: ['reports.c_view_recruitments_reports']
        // },
        {
            slug: 'engagement-termination-tables',
            name: t('engagement_termination_tables'),
            category: t('contracts'),
            section: 'HR',
            isActive: true,
            canView: ['reports.c_view_employees_reports'],
        },
        // {
        //     slug: "closed-projects",
        //     name: "Closed projects",
        //     category: t('projects'),
        //     section: "PM",
        //     isActive: false,
        //     canView: ['reports.c_view_projects_reports']
        // },
        // {
        //     slug: "weekly-progress",
        //     name: "Weekly progress",
        //     category: t('projects'),
        //     section: "PM",
        //     isActive: false,
        //     canView: ['reports.c_view_projects_reports']
        // },
        // {
        //     slug: "weekly-progress",
        //     name: "Weekly progress",
        //     category: t('orders'),
        //     section: "SM",
        //     isActive: false,
        //     canView: ''
        // },
        // {
        //     slug: "call-reports",
        //     name: t('call_reports'),
        //     category: t('integrations'),
        //     section: "OPS",
        //     isActive: true,
        //     canView: ''
        // },
        {
            slug: 'monthly-attendance',
            name: t('monthly-attendance'),
            category: t('attendance'),
            section: 'HR',
            isActive: true,
            canView: ['reports.c_view_attendance_reports'],
        },
        {
            slug: 'attendance-absences',
            name: t('attendance-absences'),
            category: t('attendance'),
            section: 'HR',
            isActive: true,
            canView: ['reports.c_view_attendance_reports'],
        },
    ])

    const breadcrumb_items = [{ name: t('reports'), icon: icons.REPORTS, href: '' }]

    return (
        <>
            <BreadcrumbNav items={breadcrumb_items} />
            {isLoading ? (
                <Loading />
            ) : (
                <Container fluid>
                    <Grid>
                        <Grid.Row>
                            <FlexRow>
                                <FlexItem>
                                    <FilterAction
                                        isActive={isHRActive}
                                        onClick={() => setIsHRActive(!isHRActive)}
                                        text={t('HR')}
                                        section="HR"
                                    />
                                    <FilterAction
                                        isActive={isPMActive}
                                        onClick={() => setIsPMActive(!isPMActive)}
                                        text={t('PM')}
                                        section="PM"
                                    />
                                    <FilterAction
                                        isActive={isSMActive}
                                        onClick={() => setIsSMActive(!isSMActive)}
                                        text={t('SM')}
                                        section="SM"
                                    />
                                    <FilterAction
                                        isActive={isOperativeActive}
                                        onClick={() => setIsOperativeActive(!isOperativeActive)}
                                        text={t('OPS')}
                                        section="OPS"
                                    />
                                </FlexItem>
                                {/* <FlexItem basis="150%">
                                    <Search placeholder={t('search_report_by_name')} className="report-search" />
                                </FlexItem> */}
                                <FlexItem></FlexItem>
                            </FlexRow>
                        </Grid.Row>
                        <ReportSection
                            isActive={isHRActive}
                            reports={reports}
                            section="HR"
                            sectionName={t('hr_long')}
                        />
                        <ReportSection
                            isActive={isPMActive}
                            reports={reports}
                            section="PM"
                            sectionName={t('pm_long')}
                        />
                        <ReportSection
                            isActive={isSMActive}
                            reports={reports}
                            section="SM"
                            sectionName={t('sm_long')}
                        />
                        <ReportSection
                            isActive={isOperativeActive}
                            reports={reports}
                            section="OPS"
                            sectionName={t('ops_long')}
                        />
                    </Grid>
                </Container>
            )}
        </>
    )
}

export default ReportList
