import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { authService } from "../../../store/services/ServiceAuth";

// routes
import { routes } from "../../../store/routes/index";

// components
import ResetPasswordForm from "./ResetPasswordForm";

function ResetPassword() {
    let history = useHistory();
    let dispatch = useDispatch();

    useEffect(() => {
        async function checkUser() {
            let result = await authService.getAccessToken();
            if (result !== false) {
                history.push(routes.DASHBOARD);
            }
        }

        checkUser();
    }, [history, dispatch]);

    return (
        <div className="center-element">
            <ResetPasswordForm email={""} />
        </div>
    );
}

export default ResetPassword;
