import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import { Form, Divider } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import NonFieldErrors from '@components/NonFieldErrors'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const ConvertToAccount = ({ record, setData, onClose, setHeader }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        is_account: false,
        is_supplier: false,
    })

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)
        const request = await requests.post(API.LEADS + record.id + '/convert/', form)

        if (request.status === 400) setErrors(request.response)
        if (request.status === 200) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('convert_finished'),
                animation: 'pulse',
                time: 2000,
            })
            setData((prev) =>
                prev.map((item) => {
                    if (item.id === record.id) {
                        item = request.response
                    }

                    return item
                })
            )
            onClose()
        }
        setProcessing(false)
    }

    useEffect(() => {
        setHeader(t('convert_to_account_or_supplier'))
    }, [])

    return (
        <Form onSubmit={handleSubmit}>
            <NonFieldErrors errors={errors} />
            <p style={{ fontWeight: 'bold', fontSize: '1rem', marginTop: 0 }}>
                {t('select_one_or_both_options_to_perform_convert')}
            </p>

            <SuperField
                as="checkbox"
                label={t('convert_to_account')}
                checked={form.is_account === true}
                onChange={() => setForm((prev) => ({ ...prev, is_account: !form.is_account }))}
            />
            <SuperField
                as="checkbox"
                label={t('convert_to_supplier')}
                checked={form.is_supplier === true}
                onChange={() => setForm((prev) => ({ ...prev, is_supplier: !form.is_supplier }))}
            />

            <Divider />

            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={processing}
                    disabled={processing || (form.is_account === false && form.is_supplier === false)}
                />
            </Form.Field>
        </Form>
    )
}

export default ConvertToAccount
