import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { thousandsSeparators } from '@helpers/functions';
// components
import Icon from '@components/Icon';
import ListView from '@components/ListView';
import CanView from '@components/perms/CanView';
import { Form, Label } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import GenerateDocuments from '@components/GenerateDocuments';
// specific
import CatalogueItemForm from '../components/catalogue/CatalogueItemForm';
import CatalogueItemDetail from '../components/catalogue/CatalogueItemDetail';

const CatalogueItems = () => {
    const { t } = useTranslation()

    return (
        <CanView permissions={['orders.c_view_catalogue']} redirect>
            <div style={{ marginTop: "1rem" }}>
                <ListView
                    as="table"
                    allowSearch
                    allowSelection
                    isSoftDeleteAllowed
                    endpoint={API.CATALOGUE_ITEMS}
                    actionsCellWidth="2"
                    initialFilters={{
                        category: "",
                        is_archived: false,
                        is_fragile: "",
                        is_service: "",
                        owner_id_number: "",
                        code: "",
                        seller: ""
                    }}
                    bulkActions={ (selected) => [
                        { 
                            as: "modal",
                            name: t('generate_documents'),
                            icon: "document-text-outline",
                            modal: <GenerateDocuments source="orders.CatalogueItem" selected={selected} />
                        }
                    ]}
                    listAdditionActions={() => ([
                        {
                            as: "filter",
                            index: 0,
                            name: t('all'),
                            filters: {
                                is_service: ""
                            }
                        },
                        {
                            as: "filter",
                            index: 1,
                            name: t('product'),
                            filters: {
                                is_service: false
                            }
                        },
                        {
                            as: "filter",
                            index: 2,
                            name: t('service'),
                            filters: {
                                is_service: true
                            }
                        },
                    ])}
                    renderFilterFields={(filters, setFilters) => (
                        <>
                            <SuperField as="input"
                                label={t('code')}
                                placeholder={t('code')}
                                value={filters.code}
                                onChange={(e, { value }) => setFilters({ ...filters, code: value })}
                            />
                            <Form.Group widths={'equal'}>
                                <SuperField as="choice"
                                    search
                                    text="title"
                                    label={t('category')}
                                    value={filters.category}
                                    onChange={(e, { value }) => setFilters({ ...filters, category: value })}
                                    endpoint={API.CATALOGUE_ITEM_CATEGORY}
                                />
                            </Form.Group>
                            <Form.Group widths={'equal'}>
                                <SuperField as="choice"
                                    search
                                    text="name"
                                    label={t('seller')}
                                    value={filters.seller}
                                    onChange={(e, { value }) => setFilters({ ...filters, seller: value })}
                                    endpoint={API.ACCOUNTS + `business_details/?query={id, name}&is_supplier=true&is_active=true&exclude_unit=true`}
                                />
                                <SuperField as="choice"
                                    search
                                    text="name"
                                    label={t('owner')}
                                    value={filters.owner}
                                    onChange={(e, { value }) => setFilters({ ...filters, owner: value })}
                                    endpoint={API.UNITS}
                                />
                            </Form.Group>
                            <Form.Group>
                                <SuperField as="checkbox"
                                    label={t('archived')}
                                    checked={filters.is_archived}
                                    onChange={() => setFilters({...filters, is_archived: !filters.is_archived})}
                                />
                                <SuperField as="checkbox"
                                    label={t('fragile')}
                                    checked={filters.is_fragile}
                                    onChange={() => setFilters({...filters, is_fragile: !filters.is_fragile})}
                                />
                            </Form.Group>
                        </>
                    )}
                    actions={[
                        {
                            as: "modal",
                            type: "add",
                            name: t('create_item'),
                            modal: <CatalogueItemForm />,
                            permissions: ['orders.c_manage_catalogue'],
                        },
                        {
                            type: 'sidebar',
                            icon: 'eye-outline',
                            name: t('show_detail'),
                            customIconColor: 'var(--primary)',
                            content: (item, setData, setTotal) => (
                                <CatalogueItemDetail item={item} />
                            ),
                        },
                        {
                            as: "modal",
                            type: "edit",
                            name: t('edit'),
                            modalSize: "small",
                            permissions: ['orders.c_manage_catalogue'],
                            modal: (item, setData) => <CatalogueItemForm
                                isService={item.is_service ? true : false}
                                data={item}
                                setData={setData}
                            />
                        },
                        {
                            name: t('delete'),
                            type: "delete",
                            as: "delete",
                            text: t('delete_item'),
                            permissions: ['orders.c_delete_catalogue'],
                        },
                    ]}
                    tableHeaders={[
                        { title: t('type') },
                        { title: t('code'), orderBy: "code" },
                        { title: t('title'), orderBy: "title" },
                        { title: t('buy_unit_price') },
                        { title: t('sell_unit_price') },
                        { title: t('category') },
                        { title: t('seller') },
                        { title: t('owner') },
                        { title: t('status') },
                    ]}
                    renderCells={(item) => (
                        [
                            { content: item?.is_service ? t('service') : t('product')},
                            { content: item?.code },
                            { content: item?.title },
                            { content: thousandsSeparators(item?.buy_price || 0) + " " + item?.buy_price_currency },
                            { content: thousandsSeparators(item?.sell_price_without_tax || 0) + " " + item?.buy_price_currency },
                            { content: item?.category?.id 
                                ?  
                                <Label style={{ background: item?.category?.color === "" ? "var(--dark)" : item?.category?.color + "B3" }}>
                                    { item?.category?.title }
                                </Label>
                                : "--"
                            },
                            { content: 
                                item?.seller?.id 
                                ?
                                    <Link target="_blank" to={routes.SUPPLIERS + item.seller.id}>
                                        { item.seller?.name }
                                    </Link>
                                : "--"
                            },
                            { content: 
                                item?.owner?.id 
                                ?
                                    <Link target="_blank" to={routes.UNIT_DETAIL + item.owner.id}>
                                        { item.owner?.business_detail?.name }
                                    </Link>
                                : "--"
                            },
                            { content:
                                <div style={{ display: "flex" }}>
                                    <Icon name='ellipse' style={{ color: !item.is_archived ? "var(--success)" : "var(--danger)", marginRight: "0.5rem" }} />
                                    <span style={{ position: "relative", top: "-0.1rem" }}> {!item.is_archived  ? t('active') : t('archived')} </span>
                                </div>
                            }
                    ])}
                />
            </div>
        </CanView>
    );
};

export default CatalogueItems;