import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { routes } from '@store/routes';
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { useFetchData } from '@helpers/hooks';
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import Loading from '@components/general/Loading';
import { Grid, Label, Button, Form, Divider, Container } from 'semantic-ui-react';
import EmptyRow from '@components/tables/EmptyRow';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
import ConfrimModal from '@components/modals/ConfrimModal';
import SuperField from '@components/forms/SuperField';
// module components 
import BudgetDetailView from '../components/BudgetDetailView';
import BudgetForm from '../components/BudgetForm';

const Budgets = () => {
    const { t } = useTranslation()
    // eslint-disable-next-line
    const [budgets, setBudgets] = useFetchData(API.COST_CENTERS_BUDGETS + "?currency=EUR")
    const [filters, setFilters] = useState({
        dateFrom: "",
        dateTo: "",
        isApproved: ""
    })

    const onDelete = async (id) => {
        const request = await requests.post(API.COST_CENTERS_BUDGETS + id + "/delete/")

        if( request.status === 200 ){
            setBudgets(prevState => {
                return {
                    ...prevState,
                    data: prevState.data.filter(item => item.id !== id)
                }
            })
        }
    }

    const onCreate = (response) => {
        setBudgets(prevState => {
            return {
                ...prevState,
                data: [response, ...prevState.data]
            }
        })
    }

    const onUpdate = (response) => {
        setBudgets(prevState => {
            return {
                ...prevState,
                data: prevState.data.filter(budget => {
                    if( response.id === budget.id ){
                        budget.amount = {
                            sum: response.amount.sum,
                            currency: response.amount.currency
                        }
                        budget.name = response.name
                        budget.date_from = response.date_from
                        budget.date_to = response.date_to
                        budget.cost_centers = response.cost_centers
                    }

                    return budget
                })
            }
        })
    }

    const onFilter = async () => {
        setBudgets({...budgets, isLoading: true})
        let params = "?query={*}"
        
        if( filters.dateFrom !== "" ){
            params += "&date_from="+filters.dateFrom
        }
        
        if( filters.dateTo !== "" ){
            params += "&date_from="+filters.dateTo
        }
        
        if( filters.isApproved !== "" || filters.isApproved !== 0 ){
            params += "&is_approved="+filters.isApproved
        }
        
        const request = await requests.get(API.COST_CENTERS_BUDGETS + params)
        if(request.status === 200){
            setBudgets({
                isLoading: false,
                data: request.response
            })
        }
    }

    return (
        <Container fluid>
        <CanView permissions={['cost_centers.c_view_budgets']} route={routes.COST_CENTERS} redirect>
            <Grid>
                <Grid.Row>
                    <Grid.Column computer={10}>
                        <Form onSubmit={onFilter}>
                            <Form.Group widths="equal">
                                <SuperField as="datepicker"
                                    label={t('date_from')}
                                    value={filters.dateFrom}
                                    onChange={(e, { value }) => setFilters(() => ({ ...filters, dateFrom: value}))}
                                />
                                <SuperField as="datepicker"
                                    label={t('date_to')}
                                    value={filters.dateTo}
                                    onChange={(e, { value }) => setFilters(() => ({ ...filters, dateTo: value}))}
                                />
                                <SuperField as="choice"
                                    label={t('status')}
                                    value={filters.isApproved}
                                    onChange={(e, { value }) => setFilters(() => ({ ...filters, isApproved: value}))}
                                    customOptions={[
                                        { key: 0, value: 0, text: t('pending')},
                                        { key: 1, value: true, text: t('approved')},
                                        { key: 2, value: false, text: t('denied')},
                                    ]}
                                />
                                <Form.Field style={{ marginTop: "1.75rem" }}>
                                    <Button 
                                        primary
                                        content={t('confirm')}
                                        loading={budgets.isLoading}
                                        disabled={budgets.isLoading}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                    <Grid.Column computer={6} style={{ textAlign: "right"}}>
                        <CanView permissions={['cost_centers.c_manage_budgets']}>
                            <SuperDuperModal
                                size="small"
                                trigger={<Button primary content={t('add_budget')} style={{ marginTop: "1.75rem" }}/>}
                                content={<BudgetForm onSubmit={onCreate}/>}
                            />
                        </CanView>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider/>
            { budgets.isLoading ? <Loading/> :
                <FlexTable>
                    <FlexRow>
                        <FlexHeader content={t('name')}/>
                        <FlexHeader content={ t('date_from') + " - " + t('date_to') }/>
                        <FlexHeader content={t('amount')}/>
                        <FlexHeader content={t('cost_centers')}/>
                        <FlexHeader content={t('approved')}/>
                        <FlexHeader content={t('actions')}/>
                    </FlexRow>
                    <EmptyRow length={budgets.data.length}/>
                    { budgets.data.map(budget => (
                        <FlexRow key={budget.id}>
                            <FlexItem bold content={budget.name} />
                            <FlexItem>
                                { budget?.date_from && moment(budget?.date_from).format("DD.MM.YYYY") }
                                { budget?.date_from && budget?.date_to ? " - " : "" }
                                { budget?.date_to && moment(budget?.date_to).format("DD.MM.YYYY") }
                            </FlexItem>
                            <FlexItem bold content={ parseFloat(budget?.amount?.sum).toFixed(2) + " " + budget?.amount?.currency }/>
                            <FlexItem>
                                <Label basic size="large"> <Icon name="business-outline" style={{ marginRight: "0.5rem" }}/> <span>{ budget?.cost_centers.length }</span></Label> 
                            </FlexItem>
                            <FlexItem content={ budget.approval === null ? t('pending') : budget?.approval?.is_approved ? t('yes') : t('no') }/>
                            <FlexItem>
                                <FlexRow background="transparent" padding="0">
                                    <FlexItem>
                                        <SuperDuperModal
                                            size="large"
                                            trigger={
                                                <Icon name="eye-outline" style={{ color: "var(--primary)", marginLeft: "1rem", cursor: "pointer" }}/>
                                            }
                                            content={<BudgetDetailView budget={budget}/>}
                                        />
                                    </FlexItem>
                                    <CanView permissions={['cost_centers.c_manage_budgets']}>
                                        <FlexItem>
                                            <SuperDuperModal
                                                size="small"
                                                trigger={<Icon name="pencil-outline" style={{ color: "var(--dark)", marginLeft: "1rem", cursor: "pointer" }}/>}
                                                content={<BudgetForm budget={ budget } onSubmit={onUpdate}/>}
                                            />
                                            
                                        </FlexItem>
                                        <FlexItem>
                                            <ConfrimModal
                                                description={t('are_you_sure')}
                                                onConfirm={() => onDelete(budget.id)}
                                                button={
                                                    <Icon name="close-outline" style={{ color: "var(--danger)", marginLeft: "1rem", cursor: "pointer" }}/>
                                                }
                                            />
                                        </FlexItem>
                                    </CanView>
                                </FlexRow>
                            </FlexItem>
                        </FlexRow>
                    )) }
                </FlexTable>
            }
        </CanView>
        </Container>
    );
};

export default Budgets;