import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// store
import { routes } from '@routes';
// components
import Icon from '@components/Icon';
import { Header, Button } from 'semantic-ui-react';

const BlockedViewAccess = () => {
    const { t } = useTranslation()

    return (
        <div style={{ width: "100%", textAlign: "center", marginTop: "2rem"}}>
            <Header as="h3" content={
                <>
                    <span style={{ fontSize: "1.5rem" }}> 
                        { t('cant_see_invoices_message') } 
                    </span> <br/>
                    { t('contact_your_administrator') }.
                </>
            }/>
            <Link to={routes.DASHBOARD}>
                <Button primary size="large">
                    <Icon
                        name="arrow-back-outline"
                        style={{ marginRight: "0.5rem" }}
                    /> 
                    <span 
                        style={{ 
                            position: "relative",
                            top: "-0.2rem"
                        }}
                    >
                        { t('back_to_dashboard') }
                    </span>
                </Button>
            </Link>
        </div>
    )
}

export default BlockedViewAccess;