import React from 'react';
// components
import Icon from '@components/Icon';
import { Button } from 'semantic-ui-react';

const OutlineAddButton = ({ text, size, ...rest }) => {
    // text => text inside button
    // color => color variant (from semantic UI)
    // size => enum (tiny, small, big, large etc... from semantic UI)
    return (
        <Button type="button" primary  size={size} {...rest}>
            <Icon name="add-outline" style={{ marginRight: "0.5rem", fontSize: "1.1rem" }}/> 
            <span style={{ position: "relative", top: "-0.2rem" }}> {text} </span>
        </Button>
    );
};

export default OutlineAddButton;