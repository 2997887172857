import React from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { useFetchData } from '@helpers/hooks';
// components 
import { Segment, Icon, Button, Grid, Form, Header, Divider } from 'semantic-ui-react';
import EmptyRow from '@components/tables/EmptyRow';
import { FlexTable, FlexRow, FlexItem, FlexHeader } from '@components/tables/FlexTable';

const IssuedEmployeeItems = ({ employee, issuedItems, setIssuedItems, onClose }) => {
    const { t } = useTranslation();
    const [employeeItems] = useFetchData(API.ASSET_ISSUED_ITEMS + "status/?employee=" + employee + "&ordering=stored_item__code")

    function checkIfWasIssued(storedItem){
        return issuedItems.find(item => item.storedItem.id === storedItem.id) !== undefined
    }

    const handleIssueItem = (issuedItem) => {
        if( checkIfWasIssued(issuedItem.stored_item) ){ // remove
            setIssuedItems(prev => prev.filter(item => item.storedItem.id !== issuedItem.stored_item.id))
        } else { // issue
            setIssuedItems(prev => [
                {
                    id: 0,
                    quantity: issuedItem.stored_item.sn !== null ? 1 : issuedItem.quantity,
                    valid_until: "",
                    note: "",
                    isProcessing: false,
                    storedItem: {
                        id: issuedItem.stored_item.id,
                        code: issuedItem.stored_item.code,
                        sn: issuedItem.stored_item.sn,
                        name: issuedItem.stored_item?.item?.title,
                        stock: issuedItem.quantity,
                    }
                },
                ...prev
            ])
        }
    }

    const preSelectAll = () => {
        const items = employeeItems.data//.data[0].stored_items.sort((a, b) => a.code > b.code ? 1 : -1)
        
        for (let i = 0; i < items.length; i++) {
            if (items[i].quantity > 0) {
                if (!checkIfWasIssued(items[i].stored_item)) {
                    handleIssueItem(items[i])
                }
            }
        }
    }

    const handleItemSelection = (item) => {
        let items = employeeItems.data.filter(listItem => listItem.stored_item?.group === item.stored_item.id)

        if (items.length > 0) {
            for (let i = 0; i < items.length; i++) {
                if (items[i].quantity > 0) {
                    if (!checkIfWasIssued(items[i].stored_item)) {
                        handleIssueItem(items[i])
                    }
                }
            }
        }

        handleIssueItem(item)
    }

    return (
        <Segment loading={employeeItems.isLoading} style={{ 
                padding: 0, 
                background: "transparent", 
                boxShadow: "none", 
                border: "none",
                marginBottom: "1rem"
            }}>
                { employeeItems.isLoading  && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "6rem" }}> { t('message_loading_assigned_items') } </p> }
                { !employeeItems.isLoading && 
                <>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer={10} table={16} mobile={16}>
                                <Header as="h2" style={{ marginTop: "2rem" }} content={t('items_issued_to_an_employee')}/>
                            </Grid.Column>
                            <Grid.Column computer={6} table={16} mobile={16}>
                                <Form.Field  style={{ marginTop: "1.7rem", float: "right", fontSize: "1.1rem"}}>
                                    <Button fluid onClick={onClose} style={{ background: "var(--black)", color: "var(--white)" }}>
                                        <Icon name="angle left"/>  { t('return_to_return_card') }
                                    </Button>
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Divider/>
                    <FlexTable>
                        <FlexRow>
                            <FlexHeader basis="40%" content={t('code')}/>
                            <FlexHeader basis="80%" content={t('item')}/>
                            <FlexHeader basis="40%" content={t('serial_number')}/>
                            <FlexHeader content={t('quantity')}/>
                            <FlexHeader>
                                <FlexRow padding="0">
                                    <FlexItem basis="20%">
                                        { t('actions') }
                                    </FlexItem>
                                    <FlexItem>
                                        <div className="ref-link" style={{ textAlign: "right", fontWeight: "bold", color: "var(--primary)", fontSize: "0.9rem" }} onClick={() => preSelectAll()}>
                                            { t('select_all_items') }
                                        </div>
                                    </FlexItem>
                                </FlexRow>
                            </FlexHeader>
                        </FlexRow>
                        <EmptyRow length={employeeItems.data.length}/>
                        {/* {employeeItems.sort((a, b) => a.stored_itemcode > b.code ? 1 : -1).map(storedItem => ( */}
                        {employeeItems.data.map(item => (
                            <FlexRow fontSize="0.9rem" borders key={item.stored_item.id}>
                                <FlexItem basis="40%">
                                    { item.stored_item?.code || "" }
                                </FlexItem>
                                <FlexItem basis="80%">
                                    <strong>{ item.stored_item?.item?.title }</strong> <br/>
                                </FlexItem>
                                <FlexItem basis="40%">
                                    { item.stored_item?.sn || "" }
                                </FlexItem>
                                <FlexItem content={item.quantity}/>
                                <FlexItem>
                                    <Button 
                                        size="small"
                                        fluid 
                                        disabled={item.quantity === 0} 
                                        onClick={() => handleItemSelection(item)}
                                        style={{
                                            color: "var(--white)",
                                            background: checkIfWasIssued(item.stored_item) ? "var(--dark)" : "var(--primary)"
                                        }}
                                    >
                                        { item.quantity === 0 
                                            ? t('not_in_possession')
                                            : checkIfWasIssued(item.stored_item) 
                                                ? <><Icon name="checkmark"/> { t('selected') } <br/> <small>{ t('click_to_remove') }</small> </>
                                                : t('select_item')
                                        }
                                    </Button>
                                </FlexItem>
                            </FlexRow>
                        ))}
                    </FlexTable>
                </>
                }
        </Segment>
    );
};

export default IssuedEmployeeItems;