import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Divider, Form } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalSubmit from '@components/buttons/ModalSubmit';
import ModalCancel from '@components/buttons/ModalCancel';
import moment from 'moment';

const PlannedCourseForm = ({ plannedCourse, setData, onClose, setTotal, employees }) => {
    const { t } = useTranslation();

    const [startDate, setStartDate] = useState({
        date: plannedCourse?.start_date ? plannedCourse.start_date.slice(0, -9) : '',
        time: plannedCourse?.start_date ? plannedCourse.start_date.slice(-8, -3) : ''
    })

    const [endDate, setEndDate] = useState({
        date: plannedCourse?.end_date ? plannedCourse.end_date.slice(0, -9) : '',
        time: plannedCourse?.end_date ? plannedCourse.end_date.slice(-8, -3) : '',
    })
    
    const [form, setForm] = useState({
        course: plannedCourse ? plannedCourse.course.id : null,
        responsible_person: plannedCourse ? plannedCourse?.responsible_person?.id : null,
        participants: plannedCourse ? plannedCourse.participants.map((el) => el.id) : [],
        is_approved: plannedCourse ? plannedCourse.is_approved : false,
        is_closed: plannedCourse ? plannedCourse.is_closed : false,
        start_date: startDate ? startDate?.date : '',
        end_date: endDate ? endDate?.date : '',
    });

    // Validate Form
    useEffect(() => {
        if (form.end_date && form.start_date) {
            if (moment(form.start_date).isAfter(form.end_date)) {
                setInvalidDateRange(t('invalid_date_should_be_higher'));
            } else {
                setInvalidDateRange(false);
            }
        }
        // eslint-disable-next-line
    }, [form]);

    const [isProcessing, setIsProcessing] = useState(false);
    const [invalidDateRange, setInvalidDateRange] = useState(false);

    const onSubmit = async () => {
        setIsProcessing(true);
        let new_participants = {};
        if (plannedCourse?.id) {
            new_participants = {
                remove: plannedCourse?.participants ? plannedCourse.participants.map((el) => el.id) : [],
                add: form.participants,
            };
        } else {
            new_participants = {
                add: form.participants,
            };
        }
        const formForSubmittion = {
            ...form,
            participants: new_participants,
            start_date: startDate.date !== '' ? startDate.date + 'T' + startDate.time : null,
            end_date: endDate.date !== '' ? endDate.date + 'T' + endDate.time : null,
        };

        if (plannedCourse?.id) {
            const request = await requests.put(API.PLANNED_COURSES + plannedCourse.id + '/', formForSubmittion);
            if (request.status === 200) {
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === plannedCourse.id) {
                            item = request.response;
                        }

                        return item;
                    })
                );
                onClose()
            }
        } else {
            const request = await requests.post(API.PLANNED_COURSES, formForSubmittion);
            if (request.status === 201) {
                setData((prev) => [request.response, ...prev]);
                setTotal((prev) => prev + 1);
                onClose()
            }
        }
        setIsProcessing(false);
    };

    return (
        <Form onSubmit={onSubmit}>
            <SuperField
                search
                as="choice"
                required
                label={t('courses')}
                endpoint={API.COURSES}
                value={form.course}
                text="title"
                onChange={(e, { value }) => setForm({ ...form, course: value })}
            />

            <Form.Group widths="equal">
                <SuperField
                    as="datepicker"
                    required
                    hideMobileKeyboard
                    closeOnMouseLeave
                    clearable
                    placeholder={t('start_date')}
                    label={t('start_date')}
                    value={startDate.date}
                    onChange={(e, { value }) => setStartDate({ ...startDate, date: value })}
                />
                <SuperField 
                    as="timepicker"
                    required
                    hideMobileKeyboard
                    closeOnMouseLeave
                    clearable
                    label={t('time')}
                    placeholder={t('start_time')}
                    value={startDate.time}
                    onChange={(e, { value }) => setStartDate({ ...startDate, time: value })}
                /> 
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField
                    as="datepicker"
                    required
                    clearable
                    hideMobileKeyboard
                    closeOnMouseLeave
                    placeholder={t('end_date')}
                    label={t('end_date')}
                    error={invalidDateRange}
                    value={endDate.date}
                    onChange={(e, { value }) => setEndDate({ ...endDate, date: value })}
                />
                <SuperField 
                    as="timepicker"
                    required
                    hideMobileKeyboard
                    closeOnMouseLeave
                    clearable
                    label={t('time')}
                    placeholder={t('end_time')}
                    value={endDate.time}
                    onChange={(e, { value }) => setEndDate({ ...endDate, time: value })}
                /> 
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="choice"
                    required
                    search
                    label={t('responsible_person')}
                    customOptions={employees}
                    value={form.responsible_person}
                    onChange={(e, { value }) => {
                        setForm({ ...form, responsible_person: value });
                    }}
                />

                <SuperField as="choice"
                    search
                    multiple
                    label={t('employees')}
                    text="fullname"
                    // disabled={employee.isLoading}
                    // loading={employee.isLoading}
                    customOptions={employees}
                    value={form.participants}
                    onChange={(e, { value })  => {
                        setForm({ ...form, participants: value });
                    }}
                />
            </Form.Group>            

            {/*<Form.Group widths="equal">*/}
            {/*    <SuperField*/}
            {/*        as="checkbox"*/}
            {/*        label={t("is_approved")}*/}
            {/*        checked={form.is_approved}*/}
            {/*        onChange={(e) => {*/}
            {/*            setIsApproved(!isApproved);*/}
            {/*            setForm({ ...form, is_approved: !isApproved });*/}
            {/*        }}*/}
            {/*    />*/}
            {/*    <SuperField*/}
            {/*        as="checkbox"*/}
            {/*        label={t("is_closed")}*/}
            {/*        checked={form.is_closed}*/}
            {/*        onChange={(e) => {*/}
            {/*            setIsClosed(!isClosed);*/}
            {/*            setForm({ ...form, is_closed: !isClosed });*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</Form.Group>*/}

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || form.course === '' || form.responsible_person === '' || form.participants === 0}
                    text={t('save')}
                />
            </Form.Field>
        </Form>
    );
};

export default PlannedCourseForm;
