import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { isEmailValid, isPhoneNumberValid } from '@helpers/validation'
// components
import Icon from '@components/Icon'
import CanView from '@components/perms/CanView'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ConfirmModal from '@components/modals/ConfrimModal'
import EmailField from '@components/forms/common/EmailField'
import PhoneField from '@components/forms/common/PhoneField'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Form, Button, Message, Divider, Header } from 'semantic-ui-react'

const ContactForm = ({ contact, setContacts, index, onClose }) => {
    const { t } = useTranslation()

    const [form, setForm] = useState({
        id: contact?.id || 0,
        phone: contact?.phone || '',
        email: contact?.email || '',
        is_primary: contact?.is_primary || false,
        // is_invoicing: contact?.is_invoicing || false,
        // contact_person_name: contact?.contact_person_name || "",
    })

    const handleSubmit = async () => {
        if (index === undefined) {
            setContacts((prev) => [...prev, form])
        } else {
            setContacts((prev) =>
                prev.map((item, idx) => {
                    if (idx === index) {
                        item = form
                    }
                    return item
                })
            )
        }
        onClose()
    }

    return (
        <Form>
            <Message
                info
                visible
                content={
                    <strong>
                        <Icon
                            name="information-circle"
                            style={{ marginRight: '0.5rem', fontSize: '1.2rem', position: 'relative', top: '0.2rem' }}
                        />
                        {t('contact_form_hint')}.
                    </strong>
                }
            />
            <PhoneField
                hideType
                placeholder={t('phone')}
                value={form.phone}
                setValue={(e, { value }) => setForm((prev) => ({ ...prev, phone: value }))}
            />

            <EmailField
                label={t('email')}
                placeholder={t('email')}
                value={form.email}
                setValue={(e, { value }) => setForm((prev) => ({ ...prev, email: value }))}
            />
            {/* <SuperField as="input"
                label={t('contact_person_name')}
                value={form.contact_person_name}
                onChange={(e, { value }) => setForm(prev => ({...prev, contact_person_name: value}))}
            />

            <SuperField as="checkbox"
                label={t('used_for_invoicing')}
                checked={form.is_invoicing}
                onChange={() => setForm(prev => ({...prev, is_invoicing: !form.is_invoicing}))}
            /> */}

            {/* <SuperField as="checkbox"
                label={t('used_as_primary')}
                checked={form.is_primary}
                onChange={() => setForm(prev => ({...prev, is_primary: !form.is_primary}))}
            /> */}
            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <Button
                    primary
                    type="button"
                    onClick={() => handleSubmit()}
                    disabled={
                        (form.phone === '' && form.email === '') ||
                        (!isEmailValid(form.email) && form.email !== '') ||
                        (!isPhoneNumberValid(form.phone) && form.phone !== '')
                    }
                >
                    {t('confirm')}
                </Button>
            </Form.Field>
        </Form>
    )
}

function ProfileCardHeaderForm({ data, onClose, onConfirm, patchEndpoint }) {
    const { t } = useTranslation()

    const [contacts, setContacts] = useState(
        data?.contacts.map((item) => ({
            id: item.id,
            phone: item?.phone || '',
            email: item?.email || '',
            is_primary: item?.is_primary || false,
        })) || []
    )

    const [form, setForm] = useState({
        expected_start_date: data?.expected_start_date || null,
        custom_id_prefix: data?.custom_id_prefix || '',
        custom_id: data?.custom_id || '',
        id_card_number: data?.id_card_number || '',
        authorization_chip: data?.authorization_chip || '',
    })

    const [errors, setErrors] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)

    const handleSubmit = async () => {
        setIsProcessing(true)
        let dataset = form
        if (data.id_card_number === dataset.id_card_number) {
            delete dataset.id_card_number
        }

        // handle contacts
        let dataContacts = {
            toCreate: contacts.filter((item) => item.id === 0),
            toUpdate: contacts.filter((item) => item.id > 0),
        }

        if (dataContacts.toCreate.length === 0 && dataContacts.toUpdate.length === 0) {
            dataset.contacts = null
        }

        dataset.contacts = {}
        if (dataContacts.toCreate.length > 0) {
            dataset.contacts['create'] = []
            const toCreate = dataContacts.toCreate
            for (let i = 0; i < toCreate.length; i++) {
                dataset.contacts['create'].push({
                    type: data.profile_type === 2 ? 3 : 2, // employee/candidate
                    phone: toCreate[i].phone,
                    email: toCreate[i].email,
                })
            }
        }

        if (dataContacts.toUpdate.length > 0) {
            const toUpdate = dataContacts.toUpdate
            dataset.contacts['update'] = {}
            for (let i = 0; i < toUpdate.length; i++) {
                dataset.contacts['update'][toUpdate[i].id] = {
                    type: data.profile_type === 2 ? 3 : 2, // employee/candidate
                    phone: toUpdate[i].phone,
                    email: toUpdate[i].email,
                }
            }
        }

        const request = await requests.patch(patchEndpoint + `${data?.id}/`, {
            ...dataset,
        })

        if (request.status === 200) {
            onConfirm(request.response)
            onClose()
        } else if (request.status === 400) {
            setErrors(request.response)
        }
        setIsProcessing(false)
    }

    const handleContactRemove = async (contact, idx) => {
        if (contact.id > 0) {
            // permanently remove
            const request = await requests.del(API.CONTACTS + 'records/' + contact.id + '/')
            if (request.status === 204) {
                setContacts((prev) => prev.filter((item) => item.id !== contact.id))
            }
        } else {
            // remove from view (local delete)
            setContacts((prev) => prev.filter((item, index) => index !== idx))
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors?.non_field_errors?.length > 0}
                header={t('error_submission_message')}
                list={errors?.non_field_errors}
            />

            <Form.Group widths="equal">
                {(data?.start_date === '' || data?.start_date === null) && (
                    <SuperField
                        as="datepicker"
                        label={t('expected_start_date')}
                        error={errors?.expected_start_date?.[0] || false}
                        value={form?.expected_start_date}
                        onChange={(e, { value }) =>
                            setForm((prev) => ({
                                ...prev,
                                expected_start_date: value,
                            }))
                        }
                    />
                )}
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField
                    as="input"
                    label={t('identity_card_number')}
                    help={t('identity_card_number_hint')}
                    placeholder={t('id_card_number')}
                    error={errors?.id_card_number?.[0] || false}
                    value={form?.id_card_number}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({
                            ...prev,
                            id_card_number: value,
                        }))
                    }
                />
                <SuperField
                    as="input"
                    label={t('authorization_chip')}
                    help={t('authorization_chip_hint')}
                    placeholder={t('authorization_chip')}
                    error={errors?.authorization_chip?.[0] || false}
                    value={form?.authorization_chip}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({
                            ...prev,
                            authorization_chip: value,
                        }))
                    }
                />
            </Form.Group>
            <Form.Group widths={2}>
                <SuperField
                    as="input"
                    label={t('custom_id_prefix')}
                    placeholder={t('custom_id_prefix')}
                    error={errors?.custom_id_prefix?.[0] || false}
                    value={form?.custom_id_prefix}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({
                            ...prev,
                            custom_id_prefix: value,
                        }))
                    }
                />
                <SuperField
                    as="input"
                    label={t('custom_id')}
                    placeholder={t('custom_id')}
                    error={errors?.custom_id?.[0] || false}
                    value={form?.custom_id}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({
                            ...prev,
                            custom_id: value,
                        }))
                    }
                />
            </Form.Group>
            <Divider />

            <Form.Field>
                <div>
                    <Header as="h3" content={t('contacts')} />
                    <span
                        style={{
                            color: contacts.length > 0 ? 'var(--dark)' : 'var(--danger)',
                            fontWeight: contacts.length > 0 ? 'normal' : 'bold',
                        }}
                    >
                        {contacts.length > 0 ? (
                            contacts.map((contact, idx) => (
                                <div key={idx}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            itemsAlign: 'start',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div>
                                            {contact.contact_person_name !== '' && (
                                                <strong>
                                                    {contact.contact_person_name} <br />
                                                </strong>
                                            )}
                                            {contact.phone !== '' ? (
                                                <>
                                                    {contact.phone} <br />
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {contact.email !== '' ? <>{contact.email} </> : ''}
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            <span
                                                style={{
                                                    fontWeight: 'bold',
                                                    opacity: 0.5,
                                                    marginRight: '1rem',
                                                    position: 'relative',
                                                    top: '-0.1rem',
                                                }}
                                            >
                                                #{idx + 1}.
                                            </span>
                                            <SuperDuperModal
                                                centered={false}
                                                header={t('update_contact')}
                                                trigger={
                                                    <Icon
                                                        name="pencil"
                                                        style={{ marginRight: '1rem', cursor: 'pointer' }}
                                                    />
                                                }
                                                content={
                                                    <ContactForm
                                                        setContacts={setContacts}
                                                        contact={contact}
                                                        index={idx}
                                                    />
                                                }
                                            />
                                            <CanView permissions={['contacts.c_delete_contacts']}>
                                                <ConfirmModal
                                                    description={t('are_you_sure_that_you_want_to_remove_this_contact')}
                                                    onConfirm={() => handleContactRemove(contact, idx)}
                                                    button={
                                                        <Icon
                                                            name="close"
                                                            style={{ cursor: 'pointer', color: 'var(--danger)' }}
                                                        />
                                                    }
                                                />
                                            </CanView>
                                        </div>
                                    </div>
                                    <Divider />
                                </div>
                            ))
                        ) : (
                            <>
                                {t('no_active_contact')} <br />
                            </>
                        )}
                    </span>
                    <SuperDuperModal
                        centered={false}
                        header={t('add_contact')}
                        trigger={
                            <strong className="ref-link" style={{ color: 'var(--primary)' }}>
                                {t('add_contact')}
                            </strong>
                        }
                        content={<ContactForm setContacts={setContacts} />}
                    />
                </div>
            </Form.Field>

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <Button primary loading={isProcessing} type="submit" disabled={isProcessing}>
                    {t('confirm')}
                </Button>
            </Form.Field>
        </Form>
    )
}

export default ProfileCardHeaderForm
