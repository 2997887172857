import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import { Form, Divider, Message } from 'semantic-ui-react'
import NonFieldErrors from '@components/NonFieldErrors'

const UnitChange = ({ selected, setSelected, setData, onClose }) => {
    const { t } = useTranslation()

    const contracts = selected
        .filter((item) => item.active_contracts.length === 1)
        .map((item) => ({ contract_id: item.active_contracts[0].id, employee_id: item.id }))
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)

    const [form, setForm] = useState({
        unit: '',
    })

    const handleSubmit = async () => {
        setProcessing(true)

        const request = await requests.patch(API.UNITS + form.unit + '/bulk/assign_employees/', {
            contracts: contracts.map((item) => item.contract_id),
        })
        if (request.status === 200) {
            const updateContracts = request.response
            const updateContractsIds = request.response.map((item) => item.profile_id)
            setData((prev) =>
                prev.filter((item) => {
                    if (updateContractsIds.includes(item.id)) {
                        const updateContract = updateContracts.find((contract) => contract.profile_id === item.id)
                        if (updateContract !== undefined) {
                            if (!item.units.find((unit) => unit.id === updateContract.unit)) {
                                if (item.units.length === 1) {
                                    item.units = [
                                        {
                                            id: updateContract.unit,
                                            name: updateContract.unit_name,
                                            company: updateContract?.company_id,
                                        },
                                    ]
                                } else {
                                    item.units = [
                                        ...item.units,
                                        {
                                            id: updateContract.unit,
                                            name: updateContract.unit_name,
                                            company: updateContract?.company_id,
                                        },
                                    ]
                                }
                            }
                        }
                    }
                    return item
                })
            )
            setSelected([])
            onClose()
        } else if (request.status === 400) {
            setErrors(request.response)
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message info visible>
                <Icon name="information-circle-outline" /> {t('bulk_unit_employee_info_hint')}
            </Message>
            <NonFieldErrors errors={errors} />
            <SuperField
                as="choice-select"
                label={t('unit')}
                search
                endpoint={API.UNITS + '?query={id, name}&only_from_active_company=true'}
                text="name"
                value={form.unit}
                onChange={(e, { value }) =>
                    setForm({
                        ...form,
                        unit: value,
                    })
                }
            />
            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit text={t('confirm')} loading={processing} disabled={processing || form.unit === ''} />
            </Form.Field>
        </Form>
    )
}

export default UnitChange
