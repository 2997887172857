import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
// components
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable'
import { Container, Button, Header, Form, Divider } from 'semantic-ui-react';
import AvatarWithName from '@components/general/AvatarWithName';
import SuperField from '@components/forms/SuperField'
import Loading from '@components/general/Loading';

const CommonTable = ({ type, employees, units, positions }) => {
    const { t } = useTranslation();
    const [result, setResult] = useState([])
    const [employee, setEmployee] = useState("")
    const [unit, setUnit] = useState("")
    const [position, setPosition] = useState("")
    const [endAfter, setEndAfter] = useState("")
    const [endBefore, setEndBefore] = useState("")
    const [startAfter, setStartAfter] = useState("")
    const [startBefore, setStartBefore] = useState("")
    const [loading, setLoading] = useState(false)

    const fetchData = async (params) => {
        let request
        if (type === "engagement") {
            request = await getRequest(API.CONTRACTS_ENGAGEMENTS_TABLE + params)
        } else if( type === "termination" ){
            request = await getRequest(API.CONTRACTS_TERMINATIONS_TABLE + params)
        }

        if (request !== undefined) {
            if (request.status === 200) {
                setResult(request.response)
            }
        }
    }

    async function filters() {
        setLoading(true)
        let params = "?employee=" + employee

        if (type === "engagement") {
            if (startAfter !== "") {
                params += "&start_after=" + startAfter
            }
            if (startBefore !== "") {
                params += "&start_before=" + startBefore
            }
        }
        if (type === "termination") {
            if (endAfter !== "") {
                params += "&end_after=" + endAfter
            }
            if (endBefore !== "") {
                params += "&end_before=" + endBefore
            }
        }

        if (unit !== "") {
            params += "&unit=" + unit
        }
        if (position !== "") {
            params += "&position=" + position
        }

        await fetchData(params)
        setLoading(false)
    }

    return (
        <Container fluid style={{ paddingTop: "2rem" }}>
            <Form padding="0.5rem">
                <Form.Group widths="equal">
                    {
                        type === "engagement" &&
                        <>
                            <SuperField as="datepicker"
                                style={{ height: "2.8rem" }}
                                dateFormat="YYYY-MM-DD"
                                label={t('start_before') + ": "}
                                placeholder={t('start_before')}
                                value={startBefore}
                                iconPosition="left"
                                onChange={(e, data) => setStartBefore(data.value)}
                                />
                            <SuperField as="datepicker"
                                style={{ height: "2.8rem" }}
                                dateFormat="YYYY-MM-DD"
                                label={t('start_after') + ": "}
                                placeholder={t('start_after')}
                                value={startAfter}
                                iconPosition="left"
                                onChange={(e, data) => setStartAfter(data.value)}
                                />
                        </>
                    }
                    {
                        type === "termination" &&
                        <>
                            <SuperField as="datepicker"
                                style={{ height: "2.8rem" }}
                                dateFormat="YYYY-MM-DD"
                                label={t('end_before') + ": "}
                                placeholder={t('end_before')}
                                value={endBefore}
                                iconPosition="left"
                                onChange={(e, data) => setEndBefore(data.value)}
                                />
                            <SuperField as="datepicker"
                                style={{ height: "2.8rem" }}
                                dateFormat="YYYY-MM-DD"
                                label={t('end_after') + ": "}
                                placeholder={t('end_after')}
                                value={endAfter}
                                iconPosition="left"
                                onChange={(e, data) => setEndAfter(data.value)}
                                />
                        </>
                    }

                    <SuperField as="choice"
                        size="small"
                        label={t('employee') + ": "}
                        loading={employees.isLoading}
                        disabled={employees.isLoading}
                        customOptions={employees.options}
                        search
                        value={employee}
                        onChange={(e, data) => setEmployee(data.value)}
                        />
                    <SuperField as="choice"
                        size="small"
                        label={t('units') + ": "}
                        loading={units.isLoading}
                        disabled={units.isLoading}
                        customOptions={units.options}
                        search
                        value={unit}
                        onChange={(e, data) => setUnit(data.value)}
                        />
                    <SuperField as="choice"
                        size="small"
                        label={t('positions') + ": "}
                        loading={positions.isLoading}
                        disabled={positions.isLoading}
                        customOptions={positions.options}
                        search
                        value={position}
                        onChange={(e, data) => setPosition(data.value)}
                    />
                    <Form.Field style={{ marginTop: "1.7rem" }}>
                        <Button loading={loading} disabled={loading} primary content={t('generate_report')} onClick={() => filters()} />
                    </Form.Field>
                </Form.Group>
                <Divider />
            </Form>

            { loading ? <Loading /> :
                <>
                    { result.length === 0 
                    ? <FlexRow padding="0" background="transparent"><FlexItem textAlign="center" content={t('generate_report')}/></FlexRow>
                    : 
                    <FlexTable hoverable={false}>
                        <FlexRow>
                            <FlexHeader content={t('employee')} basis="150%" />
                            {type === "engagement" && <FlexHeader content={t('start')} />}
                            {type === "termination" && <FlexHeader content={t('end')} />}
                            <FlexHeader content={t('unit')} />
                            <FlexHeader content={t('position')} />
                        </FlexRow>

                        {result?.map((contract, index) => (
                            <FlexRow key={index} fontSize="1rem" borders padding="0">
                                <FlexItem bold basis="150%">
                                    {
                                        contract?.employee?.fullname &&
                                        <AvatarWithName className='team-member' size={20} name={contract?.employee?.fullname} avatar={contract?.employee?.profile_picture} />
                                    }
                                </FlexItem>
                                <FlexItem bold >
                                    {
                                        contract?.start ? <div>{contract.start}</div> : contract?.end ? <div>{contract.end}</div> : ""
                                    }
                                </FlexItem>
                                <FlexItem bold >
                                    {
                                        contract?.unit?.name &&
                                        <div>{contract.unit.name}</div>
                                    }
                                </FlexItem>
                                <FlexItem bold >
                                    {
                                        contract?.position?.name &&
                                        <div>{contract.position.name}</div>
                                    }
                                </FlexItem>

                            </FlexRow>
                        ))}
                        {result?.data?.length === 0 &&
                            <FlexRow>
                                <FlexItem textAlign="center">
                                    <Header as="h2" content={t('no_results')} />
                                </FlexItem>
                            </FlexRow>
                        }
                    </FlexTable>
                    }
                </>
            }
        </Container>
    );
};


export default CommonTable;