import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import { Form } from 'semantic-ui-react'
import ListView from '@components/ListView'
import ShortenText from '@components/ShortenText'
import SuperField from '@components/forms/SuperField'
import AccessCardForm from '../components/forms/AccessCardForm'
import DatesForm from '../components/forms/DatesForm'
import PermissionsForm from '../components/forms/PermissionsForm'

const AccessCards = () => {
    const { t } = useTranslation()

    const dateFormat = useSelector((state) => state.date_format)
    const language = useSelector((state) => state.language)
    const today = moment().format('YYYY-MM-DD')

    const resolveExpiration = (dateTo) => {
        if (dateTo === null || dateTo === undefined || dateTo === '') {
            return t('no_expiration')
        }

        if (moment().isAfter(dateTo)) {
            return <span style={{ color: 'var(--danger)' }}>{t('expired')}</span>
        }

        return moment(dateTo).locale(language).endOf('day').fromNow()
    }

    const CheckActivity = ({ active }) => {
        return (
            <div>
                {active ? (
                    <Icon name="ellipse" style={{ color: 'var(--success)', marginRight: '0.5rem' }} />
                ) : (
                    <Icon name="ellipse" style={{ color: 'var(--danger)', marginRight: '0.5rem' }} />
                )}
                {active ? t('active') : t('inactive')}
            </div>
        )
    }

    async function activation(id, setData, action) {
        const request = await requests.patch(API.ACCESS_CARD + id + '/', {
            is_active: action === 'deactivation' ? false : true,
        })
        if (request.status === 200) {
            setData((prev) => prev.filter((data) => data.id !== id))
        }
    }

    async function bulkAction(selected, setData, action) {
        for (let i = 0; i < selected.length; i++) {
            await activation(selected[i].id, setData, action)
        }
    }

    return (
        <ListView
            as="table"
            allowSearch
            allowSelection
            endpoint={API.ACCESS_CARD}
            actionsCellWidth="2"
            initialFilters={{
                profile: '',
                valid_from_before: '',
                valid_to_after: '',
                category: '',
                is_active: true,
                no_expiration: '',
                note: '',
                profile_type: 2,
            }}
            listAdditionActions={() => [
                {
                    as: 'filter',
                    index: 0,
                    name: t('active'),
                    filters: {
                        valid_to_before: '',
                        no_expiration: '',
                        is_active: true,
                    },
                },
                {
                    as: 'filter',
                    index: 1,
                    name: t('inactive'),
                    filters: {
                        valid_to_before: '',
                        no_expiration: '',
                        is_active: false,
                    },
                },
                {
                    as: 'filter',
                    index: 2,
                    name: t('expired'),
                    filters: {
                        valid_to_before: today,
                        no_expiration: false,
                        is_active: '',
                    },
                },
                {
                    as: 'filter',
                    index: 3,
                    name: t('all'),
                    filters: {
                        valid_to_before: '',
                        no_expiration: '',
                        is_active: '',
                    },
                },
            ]}
            tableHeaders={[
                { title: t('employee') },
                { title: t('category') },
                { title: t('code') },
                { title: t('valid_from') },
                { title: t('valid_to') },
                { title: t('expiration') },
                { title: t('note') },
                { title: t('status') },
            ]}
            renderFilterFields={(filters, setFilters) => (
                <>
                    <SuperField
                        as="choice-select"
                        search
                        label={t('employee')}
                        endpoint={API.EMPLOYEES + '?only_basic_info=true'}
                        text="fullname"
                        value={filters.profile}
                        onChange={(e, { value }) =>
                            setFilters({
                                ...filters,
                                profile: value,
                            })
                        }
                    />
                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            label={t('code')}
                            value={filters.code}
                            onChange={(e, { value }) =>
                                setFilters({
                                    ...filters,
                                    code: value,
                                })
                            }
                        />
                        <SuperField
                            as="choice-select"
                            search
                            endpoint={API.ACCESS_CARD_CATEGORIES}
                            text="title"
                            label={t('category')}
                            value={filters.category}
                            onChange={(e, { value }) =>
                                setFilters({
                                    ...filters,
                                    category: value,
                                })
                            }
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField
                            as="datepicker"
                            clearable
                            label={t('valid_from')}
                            value={filters.valid_from_before}
                            onChange={(e, { value }) => setFilters({ ...filters, valid_from_before: value })}
                        />
                        <SuperField
                            as="datepicker"
                            clearable
                            label={t('valid_to')}
                            value={filters.valid_to_before}
                            onChange={(e, { value }) => setFilters({ ...filters, valid_to_before: value })}
                        />
                    </Form.Group>
                    <SuperField
                        as="checkbox"
                        label={t('no_expiration_checkbox')}
                        checked={filters.no_expiration}
                        onChange={(e) => {
                            setFilters({ ...filters, no_expiration: !filters.no_expiratione })
                        }}
                    />
                </>
            )}
            bulkActions={(selected, setData, setTotal, filters, queryParams, setSelected) => [
                {
                    as: 'custom',
                    name: t('card_deactivation'),
                    icon: 'close-circle',
                    onClick: () => bulkAction(selected, setData, 'deactivation'),
                    permissions: ['employees.c_manage_access_cards', 'employees.c_manage_user_access_cards'],
                },
                {
                    as: 'custom',
                    name: t('card_activation'),
                    icon: 'checkmark-circle-outline',
                    onClick: () => bulkAction(selected, setData, 'activation'),
                    permissions: ['employees.c_manage_access_cards', 'employees.c_manage_user_access_cards'],
                },
                {
                    as: 'modal',
                    name: t('validity_change'),
                    icon: 'calendar-outline',
                    modal: <DatesForm selected={selected} setSelected={setSelected} setData={setData} />,
                    permissions: ['employees.c_manage_access_cards', 'employees.c_manage_user_access_cards'],
                },
                {
                    as: 'modal',
                    name: t('permissions_change'),
                    icon: 'checkbox-outline',
                    modal: <PermissionsForm selected={selected} setSelected={setSelected} setData={setData} />,
                    permissions: ['employees.c_manage_access_cards', 'employees.c_manage_user_access_cards'],
                },
            ]}
            actions={[
                {
                    as: 'modal',
                    type: 'add',
                    name: t('add'),
                    modal: <AccessCardForm />,
                    permissions: ['employees.c_manage_access_cards', 'employees.c_manage_user_access_cards'],
                },
                {
                    as: 'modal',
                    type: 'edit',
                    name: t('edit'),
                    modalSize: 'small',
                    modal: (item, setData) => <AccessCardForm data={item} setData={setData} />,
                    permissions: ['employees.c_manage_access_cards', 'employees.c_manage_user_access_cards'],
                },
                {
                    name: t('delete'),
                    type: 'delete',
                    as: 'delete',
                    text: t('delete'),
                    permissions: ['employees.c_manage_access_cards', 'employees.c_manage_user_access_cards'],
                },
            ]}
            renderCells={(card) => [
                { content: card?.profile?.fullname },
                { content: card?.category?.title },
                { content: card?.code },
                { content: card?.valid_from ? moment(card.valid_from).format(dateFormat) : '--' },
                { content: card?.valid_to ? moment(card.valid_to).format(dateFormat) : '--' },
                { content: resolveExpiration(card.valid_to) },
                { content: <ShortenText text={card.note} length={20} /> },
                { content: <CheckActivity active={card.is_active} /> },
            ]}
        />
    )
}

export default AccessCards
