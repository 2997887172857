import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
// components
import { Label } from 'semantic-ui-react';
import ListView from '@components/ListView';

const ContractsSection = ({ employee }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector(state => state.date_format)

    return (
        <ListView
            as="table"
            endpoint={API.CONTRACTS}
            allowSearch
            forceReloadOnPagination={false}
            query={`&employee=${employee}&ordering=-valid_from`}
            tableHeaders={[
                { title: t('contract_type') },
                { title: t('position') },
                { title: t('employer') },
                { title: t('valid_from') },
                { title: t('valid_until') },
                { title: t('status') },
            ]}
            renderCells={(contract) => [
                { content: contract?.type?.title },
                { content: contract?.work_position?.title || "--" },
                { content: contract?.employer?.name || "--" },
                { content: moment(contract.valid_from).format(dateFormat) },
                { content: contract?.valid_until ? moment(contract?.valid_until).format(dateFormat) : "--" },
                { content: 
                    <>
                        { contract.status === 4 && <Label basic>{t('draft')}</Label> }
                        { contract.status === 3 && 
                            <>
                                <Label style={{ background: "var(--blue)", color: "var(--white)", textAlign: "center" }}>
                                    {t('active') + " - " + t('in_notice_period')} 
                                </Label>
                            </>
                        }
                        { contract.status === 2 && <Label style={{ background: "var(--danger)", color: "var(--white)", textAlign: "center" }}>{t('terminated')} </Label> }
                        { contract.status === 1 && <Label style={{ background: "var(--success)", color: "var(--white)" }}>{t('active')}</Label> }
                    </>
                },
            ]}
        />
    );
};

export default ContractsSection;