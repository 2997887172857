import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { routes } from '@store/routes';
// components
import Icon from '@components/Icon';
import { Form } from 'semantic-ui-react';
import ListView from '@components/ListView';
import DocPreview from '@components/DocPreview';
import ShortenText from '@components/ShortenText';
import SuperField from '@components/forms/SuperField';
import SuperDuperModal from '@components/modals/SuperDuperModal';

const IdentityDocuments = () => {
    const { t } = useTranslation()

    const dateFormat = useSelector(state => state.date_format)

    const CheckDateValidity = ({ inputDate }) => {
        const currentDate = moment();
        const givenDate = moment(inputDate);
        let isBefore = "";

        if (givenDate.isBefore(currentDate)) {
            isBefore = true;
        } else {
            isBefore = false; 
        }
        return (
            <div>
                {!isBefore ?
                    <Icon name='ellipse' style={{ color: "var(--success)", marginRight: "0.5rem" }} />
                    :
                    <Icon name='ellipse' style={{ color: "var(--danger)", marginRight: "0.5rem" }} />
                }
                {!isBefore ? t('valid') : t('expired')}
            </div>
        );
    }

    return (
        <ListView
            as="table"
            allowSearch
            endpoint={API.IDENTITY_DOCUMENTS}
            actionsCellWidth="2"
            initialFilters={{
                profile: "",
                type: "",
                issued_country: "",
                valid_until_after: "",
                valid_until_before: "",
                is_foreigner: ""
            }}
            listAdditionActions={() => ([
                {
                    as: "filter",
                    index: 0,
                    name: t('all'),
                    filters: {
                        is_expired: ""
                    },
                },
                {
                    as: "filter",
                    index: 1,
                    name: t('valid'),
                    filters: {
                        is_expired: false
                    },
                },
                {
                    as: "filter",
                    index: 2,
                    name: t('expired'),
                    filters: {
                        is_expired: true
                    },
                },
            ])}
            tableHeaders={[
                { title: t("holder") },
                { title: t("type") },
                { title: t("document_number") },
                { title: t("issue_date") },
                { title: t("valid_until") },
                { title: t("issue_country") },
                { title: t("doc_preview") },
                { title: t("note") },
                { title: t("valid") },
            ]}
            renderFilterFields={(filters, setFilters) => (
                <>
                    <SuperField
                        as="choice-select"
                        search
                        label={t('holder')}
                        endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                        text="fullname"
                        value={filters.profile}
                        onChange={(e, { value }) => setFilters({
                            ...filters,
                            profile: value
                        })}
                    />
                    <Form.Group widths="equal">
                        <SuperField as="choice-select"
                            label={ t('issue_country') }
                            type="countries"
                            search
                            value={ filters.issued_country }
                            onChange={ (e, { value }) => setFilters({
                                ...filters, 
                                issued_country: value
                            })}
                        />
                        <SuperField as="choice-select"
                            search
                            type="identity_document_types"
                            label={t('type')}
                            value={filters.type}
                            onChange={(e, { value }) => setFilters({
                                ...filters,
                                type: value
                            })}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField as="datepicker"
                            clearable
                            label={t('valid_from')}
                            value={filters.valid_until_after}
                            onChange={(e, { value }) => setFilters({ ...filters, valid_until_after: value })}
                        />
                        <SuperField as="datepicker"
                            clearable
                            label={t('valid_to')}
                            value={filters.valid_until_before}
                            onChange={(e, { value }) => setFilters({ ...filters, valid_until_before: value })}
                        />
                    </Form.Group>

                    <SuperField as="checkbox"
                        label={t('show_only_foreigners')}
                        checked={filters.is_foreigner === true}
                        onChange={() => setFilters({ ...filters, is_foreigner: filters.is_foreigner === true ? "" : true })}
                    />
                </>
            )}
            actions={[
                {
                    as: "link",
                    type: "detail",
                    icon: "id-card-outline",
                    name: t('view_profile_card'),
                    redirect: (item) => routes.EMPLYOEES_DETAIL + item.profile.id + "/?view=identity",
                },
            ]}
            renderCells={(identity) => ([
                {content: identity.profile.fullname},
                {content: identity.type_display},
                {content: identity.document_number},
                {content: identity.issued_date ? moment(identity.issued_date).format(dateFormat) : "--" },
                {content: identity.valid_until ? moment(identity.valid_until).format(dateFormat) : "--"},
                {content: identity.issued_country_display},
                
                {
                    width: 3, content:
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            { identity?.image_front && 
                                <SuperDuperModal
                                    size="large"
                                    trigger={
                                        <div className="ref-link" style={{ color: "var(--primary)" }}>
                                            { t('show_preview') + " 1" }
                                        </div>
                                    }
                                    content={
                                        <DocPreview uri={identity.image_front}/>
                                    }
                                />
                            }
                            { identity?.image_back && 
                                <SuperDuperModal
                                    size="large"
                                    trigger={
                                        <div className="ref-link" style={{ color: "var(--primary)" }}>
                                            { t('show_preview') + " 2" }
                                        </div>
                                    }
                                    content={
                                        <DocPreview uri={identity.image_back}/>
                                    }
                                />
                            }
                            { (identity.image_back === null && identity.image_front === null) && 
                                <div style={{ fontWeight: "bold", color: "var(--danger)" }}>
                                    { t('no_document_attached') }
                                </div>
                            }
                        </div>
                },
                {content: <ShortenText text={identity.note} length={20}/>},
                {content: <CheckDateValidity inputDate={identity.valid_until} /> },
            ])}
        />
    );
};

export default IdentityDocuments;