import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { projectsService } from '../../../store/services/ServiceProjects';
// components
import { Form } from 'semantic-ui-react';


const ProjectDropdownField = ({label, value, onChange, loading, currentuser, ...rest}) => {
    const { t } = useTranslation();
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true)

    useEffect( () => {
        async function fetchProjects(){
            // get projects
            const projectsResult = await projectsService.getProjects(
                "?query={id, name, identifier}&?only_current_user="+currentuser
            );

            let projects = []

            if(projectsResult.status === 200){
                const responseProjects = projectsResult.response;
                for (let i = 0; i < responseProjects.length; i++) {
                    let text_value = responseProjects[i].name;
                    projects.push({
                        key: responseProjects[i].id, text: text_value, value: responseProjects[i].id
                    });
                }
                setOptions(projects);
            }

            setIsLoading(false);
        }

        fetchProjects();
    },[currentuser])

    return (
        <Form.Select
            {...rest}
            fluid
            clearable
            loading={loading || isLoading}
            label={label ? label : t('project')}
            value={value}
            options={options}
            placeholder='---------'
            onChange={onChange}
        />
    );
};

export default ProjectDropdownField;