import React from 'react';
import { useTranslation } from "react-i18next";
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
// store
import { routes } from '@routes';
// components
import CanView from '@components/perms/CanView';
import BreadcrumbNav from '@components/BreadcrumbNav';
import { icons } from '@store/icons';
import CasesList from './views/CasesList';

const Cases = () => {
    const { t } = useTranslation();

    const breadcrumb_items = [
        { 'name': t("cases"), 'icon': icons.CASES, 'href': '' }
    ]

    return (
        <CanView permissions={["cases.c_view_all_cases", "cases.c_view_assigned_user_cases"]} redirect>
            <BreadcrumbNav items={breadcrumb_items} style={{ marginBottom: 0 }} />
            <Switch>
                <Route exact path={routes.CASES} component={CasesList} />
            </Switch>
        </CanView>
    );
};

export default withRouter(Cases);