import React from 'react';
import { useTranslation } from "react-i18next";
// store
import { isEmailValid, isEmpty } from '@services/helpers/validation';
// components
import { Form } from 'semantic-ui-react';


const EmailField = ({ value, setValue, ...rest}) => {
    const { t } = useTranslation();

    return (
        <Form.Field>
            <Form.Input
                {...rest}
                label={rest.label === null ? null : t('email')}
                value={value}
                onChange={setValue}
                error={!isEmailValid(value) && !isEmpty(value) ? t('email_validation_error') : false }
            />
        </Form.Field>
    );
};

export default EmailField;