import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useLocation } from 'react-router-dom'
// store
import { routes } from '@store/routes'
import { icons } from '@store/icons'
// components
import { Container } from 'semantic-ui-react'
import BreadcrumbNav from '@components/BreadcrumbNav'
import Fluctuations from '../components/stats/hr/Fluctuations'
import RecruiterStats from '../components/stats/hr/RecruiterStats'
import CandidatesByStage from '../components/stats/hr/CandidatesByStage'
import CallReports from '../components/stats/ops/call-reports/CallReports'
import PublicationSources from '../components/stats/hr/PublicationSources'
import CandidateStageTime from '../components/stats/hr/CandidateStageTime'
import CandidateRejections from '../components/stats/hr/CandidateRejections'
import PublishedByRecruiter from '../components/stats/hr/PublishedByRecruiter'
import CandidatesInteractions from '../components/stats/hr/CandidatesInteractions'
import MonthlyAttendanceReport from '../components/stats/hr/MonthlyAttendanceReport'
import AbsenceAttendanceReport from '../components/stats/hr/AbsenceAttendanceReport'
import EngagementTerminationTables from '../components/stats/hr/engagement-terminations/EngagementTerminationTables'

function queryParams(location) {
    return new URLSearchParams(location.search)
}

const ReportView = () => {
    const { t } = useTranslation()
    const params = useParams()
    const location = useLocation()
    const query = queryParams(location)

    const breadcrumb_items = [
        { name: t('reports'), icon: icons.REPORTS, href: routes.REPORTS },
        { name: t(params.slug), icon: icons.REPORTS, href: '' },
    ]

    return (
        <>
            <BreadcrumbNav items={breadcrumb_items} />
            <Container fluid>
                {params.slug === 'candidate-stage-time' && <CandidateStageTime />}
                {params.slug === 'candidates-by-stage' && <CandidatesByStage room={query.get('room')} />}
                {params.slug === 'candidate-rejections' && <CandidateRejections room={query.get('room')} />}
                {params.slug === 'recruiter-stats' && <RecruiterStats recruiter={query.get('recruiter')} />}
                {params.slug === 'publications-sources' && <PublicationSources room={query.get('room')} />}
                {params.slug === 'candidates-interactions' && <CandidatesInteractions />}
                {params.slug === 'published-by-recruiter' && <PublishedByRecruiter />}
                {params.slug === 'fluctuations' && <Fluctuations />}
                {params.slug === 'engagement-termination-tables' && <EngagementTerminationTables />}
                {params.slug === 'call-reports' && <CallReports />}
                {params.slug === 'monthly-attendance' && <MonthlyAttendanceReport />}
                {params.slug === 'attendance-absences' && <AbsenceAttendanceReport />}
            </Container>
        </>
    )
}

export default ReportView
