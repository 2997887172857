import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import CanView from '@components/perms/CanView';
import Action from '@components/general/Action';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Table, Grid, Divider, Header, Segment } from 'semantic-ui-react';
// custom
import InspectionsForm from '../components/InspectionsForm';

const InspectionsView = ({ vehicle }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector(store => store?.date_format || "YYYY-MM-DD")
    const [fetching, setFetching] = useState(true)
    const [data, setData] = useState([])

    useEffect(() => {
        async function fetchData(){
            setFetching(true)
            const request = await requests.get(API.VEHICLES + `inspections/?vehicle=${vehicle.id}&ordering=-date_of_inspection`)
            if (request.status === 200) {
                setData(request.response)
            }
            setFetching(false)
        }

        fetchData()
    }, [vehicle.id])

    const onDelete = async (record) => {
        const request = await requests.del(API.VEHICLES + "inspections/" + record.id + "/")
        if (request.status === 204) {
            setData(prev => prev.filter(data => data.id !== record.id))
        }
    }

    return (
        <>
            <Grid>
                <Grid.Row verticalAlign="middle" style={{ padding: 0 }}>
                    <Grid.Column style={{ display: "flex", padding: 0 }}>
                        <div style={{ display: "flex" }}>
                            <Header as="h4" content={t('inspections')} style={{ marginTop: "1rem" }}/>
                            <CanView permissions={['vehicles.c_manage_all_vehicles']}>
                                <Action as="modal" type="icon"
                                    tooltip={t('add')}
                                    size="huge"
                                    iconColor="var(--dark)"
                                    header={t('add_inspection')}
                                    icon="add-outline"
                                    modal={ 
                                        <InspectionsForm
                                            vehicle={vehicle}
                                            setData={setData}
                                        />
                                    }
                                />
                            </CanView>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider style={{ marginTop: 0 }}/>

            <Segment loading={fetching} style={{ padding: 0, margin: 0, backgroundColor: "transparent", minHeight: "10rem", border: "none", boxShadow: "none" }}>
                { fetching && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "8rem" }}> { t('loading_inspections') } </p> }
                { !fetching && 
                    <div style={{ padding: 0, overflow: "auto", width: "100%" }}>
                        <Table size="small" striped selectable unstackable className="table responsive">
                            <Table.Header>
                                <Table.Row verticalAlign="middle">
                                    <Table.HeaderCell> {t('type')} </Table.HeaderCell>
                                    <Table.HeaderCell> {t('date_of_inspection')} </Table.HeaderCell>
                                    <Table.HeaderCell> {t('valid_from')} </Table.HeaderCell>
                                    <Table.HeaderCell> {t('valid_to')} </Table.HeaderCell>
                                    <Table.HeaderCell> {t('status')} </Table.HeaderCell>
                                    <Table.HeaderCell width="3"> { t('actions')} </Table.HeaderCell>
                                </Table.Row>
                                { data.length > 0 
                                    ? 
                                        data.map(inspection => (
                                            <Table.Row verticalAlign="middle" key={inspection.id}>
                                                <Table.Cell> { inspection?.type_display || "--"} </Table.Cell>
                                                <Table.Cell> { inspection.date_of_inspection ? moment(inspection.date_of_inspection).format(dateFormat) : "--" }</Table.Cell>
                                                <Table.Cell> { inspection.valid_from ? moment(inspection.valid_from).format(dateFormat) : "--" }</Table.Cell>
                                                <Table.Cell> { inspection.valid_to ? moment(inspection.valid_to).format(dateFormat) : "--" }</Table.Cell>
                                                <Table.Cell style={{ color: inspection.passed ? "var(--success)" : "var(--danger)", fontWeigth: "bold" }}> 
                                                    { inspection.passed ? t('passed') : t('not_passed') }
                                                </Table.Cell>
                                                <Table.Cell width="3">
                                                    <FlexRow padding="0" background="transparent">
                                                        <CanView permission={['vehicles.c_manage_all_vehicles']}>
                                                            <FlexItem>
                                                                <Action
                                                                    type='icon'
                                                                    size="huge"
                                                                    icon={"pencil-outline"}
                                                                    as={"modal"}
                                                                    paddingTop="0"
                                                                    paddingBottom="0"
                                                                    tooltip={t('edit')}
                                                                    modal={
                                                                        <InspectionsForm
                                                                            vehicle={vehicle}
                                                                            record={inspection}
                                                                            setData={setData}
                                                                        />}
                                                                    iconColor={"var(--dark)"}
                                                                />
                                                            </FlexItem>
                                                        </CanView>
                                                        <CanView permission={['vehicles.c_manage_all_vehicles']}>
                                                            <FlexItem>
                                                                <Action
                                                                    as='delete'
                                                                    size="huge"
                                                                    tooltip={t('delete')}
                                                                    text={t('are_you_sure_that_you_want_to_delete_this_inspection')}
                                                                    onClick={() => onDelete(inspection)}
                                                                />
                                                            </FlexItem>
                                                        </CanView>
                                                    </FlexRow>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))
                                    :
                                    <Table.Row verticalAlign="middle">
                                        <Table.Cell colspan={6} textAlign="center" style={{ fontWeight: 'bold' }}>
                                            { t('no_data') }
                                        </Table.Cell>
                                    </Table.Row>
                                }
                            </Table.Header>
                        </Table>
                    </div>
                }
            </Segment>   
        </>
    );
};

export default InspectionsView;