import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
// eslint-disable-next-line
import Icon from '@components/Icon'
import AvatarIcon from '@components/AvatarIcon'
// eslint-disable-next-line
import Action from '@components/general/Action'
import Address from '@components/forms/Address'
import { Segment, Grid } from 'semantic-ui-react'

const ProfilePreview = ({ record, setData, isCandidate }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [profile, setProfile] = useState(null)
    // eslint-disable-next-line
    const [editMode, setEditMode] = useState(false)

    useEffect(() => {
        async function getProfile() {
            setLoading(true)
            const request = await requests.get((isCandidate ? API.CANDIDATES : API.EMPLOYEES) + record.id + '/')
            if (request.status === 200) {
                setProfile(request.response)
            }

            setLoading(false)
        }

        getProfile()
    }, [record.id, isCandidate])

    return (
        <Segment
            loading={loading}
            style={{ padding: 0, margin: 0, backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
        >
            {loading && (
                <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '8rem' }}>
                    {' '}
                    {t('loading_profile_information')}{' '}
                </p>
            )}
            {!loading && (
                <div className="profile-preview">
                    <AvatarIcon
                        style={{ margin: '0 auto' }}
                        size={100}
                        name={profile.fullname}
                        src={profile.profile_picture}
                    />
                    <div
                        style={{
                            background: 'var(--primary)',
                            color: 'var(--white)',
                            textAlign: 'center',
                            position: 'relative',
                            top: '-2rem',
                            paddingTop: '2.5rem',
                            paddingBottom: '1rem',
                            fontWeight: 'bold',
                            fontSize: '1.3rem',
                        }}
                    >
                        {profile?.titles_before || ''} {profile.fullname} {profile?.titles_after || ''}
                        <br />
                        <div style={{ textAlign: 'center', fontSize: '1rem', fontWeight: 'normal' }}>
                            {profile.active_contracts.map((contract, index) =>
                                profile.active_contracts.length === index + 1 ? (
                                    <span>{contract.work_position_title}</span>
                                ) : (
                                    <span>
                                        {contract.work_position_title} {', '}
                                    </span>
                                )
                            )}
                            <div>
                                {profile.active_contracts.length === 0 && (
                                    <strong style={{ fontSize: '0.9rem', color: 'var(--danger)' }}>
                                        {t('no_active_contract')}
                                    </strong>
                                )}
                                {profile.active_contracts.map((contract, index) =>
                                    profile.active_contracts.length === index + 1 ? (
                                        <strong style={{ fontSize: '0.9rem' }}>{contract.employer_name}</strong>
                                    ) : (
                                        <strong style={{ fontSize: '0.9rem' }}>
                                            {contract.employer_name} {', '}
                                        </strong>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                    <Grid stackable>
                        <Grid.Row columns={2} style={{ padding: 0 }}>
                            <Grid.Column>
                                <p>
                                    <strong>{t('custom_id')}</strong> <br />
                                    {profile?.custom_id_prefix || '--'}
                                    {profile?.custom_id || '--'}
                                </p>
                                <p>
                                    <strong>{t('identity_card_number')}</strong> <br />
                                    {profile?.id_card_number || '--'}
                                </p>
                                <p>
                                    <strong>{t('personal_number')}</strong> <br />
                                    {profile?.personal_number || '--'}
                                </p>
                                <p>
                                    <strong>{t('phones')}</strong> <br />
                                    {profile.contacts?.map(
                                        (item, index) =>
                                            item.phone !== '' && (
                                                <>
                                                    <a href={'mailto:' + item.phone}> {item.phone}</a>
                                                    <br />
                                                </>
                                            )
                                    )}
                                </p>
                            </Grid.Column>
                            <Grid.Column>
                                <p>
                                    <strong>{t('department')}</strong> <br />
                                    {profile.unit?.name || '--'}
                                </p>
                                <p>
                                    <strong>{t('permanent_address')}</strong> <br />
                                    <Address address={profile?.permanent_address} />
                                </p>

                                <p>
                                    <strong>{t('authorization_chip')}</strong> <br />
                                    {profile.authorization_chip || '--'}
                                </p>

                                <p>
                                    <strong>{t('emails')}</strong> <br />
                                    {profile.contacts?.emails?.map(
                                        (item, index) =>
                                            item.email !== '' && (
                                                <>
                                                    <a href={'mailto:' + item.email}> {item.email}</a> <br />
                                                </>
                                            )
                                    )}
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )}
        </Segment>
    )
}

export default ProfilePreview
