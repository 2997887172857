import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store 
import { API } from '@store/config';
import { useSelectOptions } from '@helpers/hooks';
// components
import { Button, Container } from 'semantic-ui-react'
import CommonTable from './CommonTable';

const EngagementTerminationTables = () => {
    const { t } = useTranslation();

    const [employees] = useSelectOptions(API.EMPLOYEES + "?query={id, fullname}", "fullname") 
    const [units] = useSelectOptions(API.UNITS + "?query={id, name}", "name") 
    const [positions] = useSelectOptions(API.JOB_POSITIONS + "?query={id, title}", "title") 

    const [view, setView] = useState(1);

    return (
        <>
            <Container fluid >
                <Button.Group basic size='small' style={{ marginLeft: '1rem', borderRadius: 0 }}>
                    <Button style={{ color: 'var(--primary)' }} active={view === 1} onClick={() => setView(1)}>{t('engagement')}</Button>
                    <Button style={{ color: 'var(--primary)' }} active={view === 2} onClick={() => setView(2)}>{t('termination')}</Button>
                </Button.Group>

                {
                    view === 1 && 
                    <CommonTable 
                        type="engagement" 
                        employees={employees}
                        units={units}
                        positions={positions}
                    />
                }
                {   
                    view === 2 && 
                    <CommonTable 
                        type="termination"  
                        employees={employees}
                        units={units}
                        positions={positions}
                    />
                }
            </Container>
        </>
    );
};

export default EngagementTerminationTables;