import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import { Form, Divider } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const FuelCardForm = ({ record, setData, setTotal, onClose, setHeader, setSize }) => {
    const { t } = useTranslation()

    const [isProcessing, setIsProcessing] = useState(false)

    const [errors, setErrors] = useState(null)

    const [form, setForm] = useState({
        card_number: record?.card_number || '',
        expiration_date: record?.expiration_date || '',
        provider: record?.provider || '',
        cost_center: record?.cost_center?.id || '',
    })

    const [dataVehicle, setDataVehicle] = useState(null)
    const [vehicle, setVehicle] = useState(record?.vehicle ? parseInt(record?.vehicle) : '')
    const [carPlateNumber, setCarPlateNumber] = useState(record?.car_plate_number || '')

    const fetchVehicle = async () => {
        const request = await requests.get(
            API.VEHICLES + parseInt(record?.vehicle) + '/?query={id, title, car_plate_number}'
        )
        if (request.status) {
            setDataVehicle(request.response)
        }
    }

    useEffect(() => {
        if (record?.id) {
            setHeader(t('edit_card'))
            if (record?.vehicle) {
                fetchVehicle()
            }
        }
        // eslint-disable-next-line
    }, [])

    const isFormValid = () => {
        if (form.card_number === '') return false

        return true
    }

    const handleSubmit = async () => {
        setIsProcessing(true)
        setErrors(null)
        const formData = {
            is_active: true,
            card_number: form.card_number,
            expiration_date: form?.expiration_date || null,
            provider: form.provider,
            cost_center: form?.cost_center || null,
        }

        if (record?.id) {
            // update
            const request = await requests.patch(API.VEHICLES + 'fuel_cards/' + record?.id + '/', formData)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                let card = request.response
                if (vehicle || carPlateNumber) {
                    if (record?.assignment_id) {
                        const updateAssignment = await requests.patch(
                            API.VEHICLES + 'fuel_card_assignments/' + record?.assignment_id + '/',
                            {
                                vehicle: vehicle || null,
                                car_plate_number: carPlateNumber || '',
                            }
                        )

                        if (updateAssignment) {
                            const requestCard = await requests.get(API.VEHICLES + 'fuel_cards/' + card.id + '/')
                            if (requestCard.status === 200) {
                                card = requestCard.response
                            }
                        }
                    } else {
                        const createAssignment = await requests.post(API.VEHICLES + 'fuel_card_assignments/', {
                            fuel_card: request.response.id,
                            vehicle: vehicle || null,
                            car_plate_number: carPlateNumber || '',
                        })

                        if (createAssignment) {
                            const requestCard = await requests.get(API.VEHICLES + 'fuel_cards/' + card.id + '/')
                            if (requestCard.status === 200) {
                                card = requestCard.response
                            }
                        }
                    }
                }

                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === card.id) {
                            item = card
                        }
                        return item
                    })
                )
                onClose()
            }
        } else {
            // create
            const request = await requests.post(API.VEHICLES + 'fuel_cards/', formData)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                let card = request.response
                if (vehicle || carPlateNumber) {
                    const createAssignment = await requests.post(API.VEHICLES + 'fuel_card_assignments/', {
                        fuel_card: request.response.id,
                        vehicle: vehicle || null,
                        car_plate_number: carPlateNumber || '',
                    })

                    if (createAssignment) {
                        const requestCard = await requests.get(API.VEHICLES + 'fuel_cards/' + card.id + '/')
                        if (requestCard.status === 200) {
                            card = requestCard.response
                        }
                    }
                }

                setTotal((prev) => prev + 1)
                setData((prev) => [card, ...prev])
                onClose()
            }
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
                <SuperField
                    as="input"
                    autoFocus
                    required
                    error={errors?.card_number?.[0] || false}
                    label={t('card_number')}
                    value={form.card_number}
                    onChange={(e, { value }) =>
                        setForm({
                            ...form,
                            card_number: value,
                        })
                    }
                />
                <SuperField
                    as="datepicker"
                    clearable
                    error={errors?.expiration_date?.[0] || false}
                    label={t('expiration_date')}
                    value={form.expiration_date}
                    onChange={(e, { value }) =>
                        setForm({
                            ...form,
                            expiration_date: value,
                        })
                    }
                />
            </Form.Group>
            <SuperField
                as="input"
                label={t('provider')}
                value={form.provider}
                onChange={(e, { value }) =>
                    setForm({
                        ...form,
                        provider: value,
                    })
                }
            />
            <Form.Group widths="equal">
                <SuperField
                    as="choice-select"
                    search
                    label={t('cost_center')}
                    endpoint={API.COST_CENTERS}
                    text="title"
                    value={form.cost_center}
                    onChange={(e, { value }) =>
                        setForm({
                            ...form,
                            cost_center: value,
                        })
                    }
                    initialOptions={{
                        attribute: 'title',
                        source: record?.cost_center,
                    }}
                />
            </Form.Group>
            <Divider />

            <SuperField
                as="choice-select"
                search
                key={dataVehicle?.id}
                label={t('vehicle')}
                endpoint={API.VEHICLES}
                additionalFilters={'&query={id, title, car_plate_number	}'}
                text={(item) => `${item.car_plate_number} - ${item.title}`}
                value={vehicle}
                onChange={async (e, data) => {
                    setVehicle(data.value)

                    if (data.value) {
                        const request = await requests.get(API.VEHICLES + data.value + '/?query={id, car_plate_number}')
                        if (request.status === 200) {
                            setCarPlateNumber(request.response.car_plate_number)
                        }
                    }
                }}
                initialOptions={{
                    attribute: 'title',
                    source: dataVehicle,
                }}
            />
            <SuperField
                as="input"
                label={t('car_plate_number')}
                value={carPlateNumber}
                onChange={(e, { value }) => setCarPlateNumber(value)}
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit loading={isProcessing} disabled={!isFormValid() || isProcessing} />
            </Form.Field>
        </Form>
    )
}

export default FuelCardForm
