import React, { useState /*, useEffect*/ } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import moment from 'moment'
import '@store/languages'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { setLocaleLanguage, handleTimeInput, isTimeValid, tzDateTime } from '@helpers/dates'
// components
import SuperField from '@components/forms/SuperField'
import ModalSubmit from '@components/buttons/ModalSubmit'
import ModalCancel from '@components/buttons/ModalCancel'
import { Form, Divider, Message } from 'semantic-ui-react'

const PlannerRecordForm = ({ onClose, shifts, setRecords, data }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)
    const planner_record_types = useSelector((state) => state.choices?.attendance_record_planner_types)

    let recordTypes = []
    Object.entries(planner_record_types).forEach(([key, value], index) => {
        if (!['4', '5', '6', '7', '8', '9', '10', '11'].includes(key)) {
            recordTypes.push({ id: key, value: key, text: value })
        }
    })

    const [isProcessing, setIsProcessing] = useState(false)
    const [form, setForm] = useState({
        date: data.day.date,
        time_from: '',
        time_to: '',
        type: 3,
        is_approved: true,
        employee: data.contract.profile_id,
        contract: data.contract.id,
        shift: '',
    })

    const calculateTime = (start, end, as) => {
        let time = 0
        as = as || 'hours'
        let day = moment().format('YYYY-MM-DD')
        let start_time = start ? tzDateTime(moment(day + ' ' + start).format('YYYY-MM-DD HH:mm'), true) : null
        let end_time = end ? tzDateTime(moment(day + ' ' + end).format('YYYY-MM-DD HH:mm'), true) : null

        if (start_time !== null && end_time !== null) {
            // Check if end_time is higher, add 1 day if true
            if (end_time.isBefore(start_time)) {
                end_time.add(1, 'day')
            }

            // Calculate the difference
            if (as === 'minutes') {
                time = moment.duration(end_time.diff(start_time)).asMinutes()
            } else {
                time = moment.duration(end_time.diff(start_time)).asHours()
            }
        }

        return as === 'hours' ? time.toFixed(2) : time
    }

    const handleBreakDeduction = (time_from, time_to, shift) => {
        let duration = calculateTime(time_from, time_to, 'minutes')
        if (shift?.pause_length !== '' && parseFloat(shift?.pause_length) > 0) {
            duration = duration - parseFloat(shift?.pause_length || 0)
        }

        if (shift?.second_pause_length !== '' && parseFloat(shift?.second_pause_length) > 0) {
            duration = duration - parseFloat(shift?.second_pause_length || 0)
        }

        return duration > 0 ? parseFloat(duration / 60) : 0
    }

    const handleSubmit = async () => {
        setIsProcessing(true)
        const defaultFormat = 'YYYY-MM-DD HH:mm'
        const datetime_from = form.time_from ? moment(form.date + ' ' + form.time_from, defaultFormat) : null
        const datetime_to = form.time_from ? moment(form.date + ' ' + form.time_to, defaultFormat) : null

        if (datetime_to && datetime_from) {
            if (datetime_to.isBefore(datetime_from)) {
                datetime_to.add(1, 'day')
            }
        }

        const request = await requests.post(API.ATTENDANCE_BASE + 'planner_records/', {
            date: form.date,
            type: parseInt(form.type),
            is_approved: true,
            employee: form.employee,
            contract: form.contract,
            shift: form.shift,

            // handle later
            datetime_from: datetime_from
                ? tzDateTime(datetime_from.format(defaultFormat), true).format(defaultFormat)
                : null,
            datetime_to: datetime_to ? tzDateTime(datetime_to.format(defaultFormat), true).format(defaultFormat) : null,
        })

        if (request.status === 201) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('plan_added'),
                animation: 'pulse',
                time: 2000,
            })

            const shift = shifts.find((item) => item.id === form.shift)
            const duration = handleBreakDeduction(form.time_from, form.time_to, shift)
            setRecords((prev) => [
                ...prev,
                {
                    id: request.response.id,
                    contract: form.contract,
                    employee: form.employee,
                    date: form.date,
                    week: data.day.week,
                    time_from: form.time_from,
                    time_to: form.time_to,
                    type: parseInt(form.type),
                    type_display: planner_record_types[parseInt(form.type)],
                    shift: shift?.id || null,
                    color: shift?.color || '#000000',
                    duration: parseFloat(duration),
                    duration_display: duration.toString().replace('.00', ''),
                },
            ])
            onClose()
        }

        setIsProcessing(false)
    }

    const isValidForm = () => {
        if (form.type === '') return false
        if ((form.time_from === '' || !isTimeValid(form.time_from)) && [3, 4].includes(parseInt(form.type)))
            return false
        if ((form.time_to === '' || !isTimeValid(form.time_to)) && [3, 4].includes(parseInt(form.type))) return false
        return true
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                info
                visible
                content={
                    <div>
                        {t('selected_day')}:{' '}
                        <strong>
                            {moment(data.day.date).format(dateFormat)} -{' '}
                            <span style={{ textTransform: 'capitalize' }}>
                                {moment(data.day.date).locale(setLocaleLanguage()).format('dddd')}
                            </span>{' '}
                            {data.day.holiday !== null && '(' + t('holiday') + ')'}
                            <br />
                        </strong>
                        {t('employee')}: <strong>{data.contract.fullname}</strong>
                    </div>
                }
            />
            <SuperField
                as="choice"
                search
                required
                clearable={false}
                customOptions={recordTypes}
                info={t('selected_work_shift_will_autofill_time_preference')}
                label={t('record_type')}
                value={form.type?.toString() || ''}
                onChange={(e, { value }) =>
                    setForm((prev) => ({
                        ...prev,
                        type: value,
                    }))
                }
            />

            {[3, 4].includes(parseInt(form.type)) && (
                <SuperField
                    as="choice"
                    search
                    info={t('selected_work_shift_will_autofill_time_preference')}
                    label={t('work_shift')}
                    customOptions={shifts.map((item) => ({
                        key: item.id,
                        value: item.id,
                        text: `${item.title}`,
                    }))}
                    value={form.shift}
                    onChange={(e, { value }) => {
                        let shift = null
                        if (value !== '') {
                            shift = shifts.find((item) => item.id === value)
                        }

                        setForm((prev) => ({
                            ...prev,
                            shift: value,
                            time_from: shift?.time_from ? shift?.time_from : form.time_from,
                            time_to: shift?.time_to ? shift?.time_to : form.time_to,
                        }))
                    }}
                />
            )}

            {![1, 2].includes(parseInt(form.type)) && (
                <>
                    <Form.Group widths="equal">
                        <SuperField
                            as="timepicker"
                            required
                            label={t('time_from')}
                            value={form.time_from}
                            error={!isTimeValid(form.time_from) ? true : false}
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    time_from: handleTimeInput(value),
                                }))
                            }
                        />
                        <SuperField
                            as="timepicker"
                            required
                            label={t('time_to')}
                            value={form.time_to}
                            error={!isTimeValid(form.time_to) ? true : false}
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    time_to: handleTimeInput(value),
                                }))
                            }
                        />
                    </Form.Group>
                    <p>
                        {' '}
                        {t('duration')}:{' '}
                        <strong>
                            {handleBreakDeduction(
                                form.time_from,
                                form.time_to,
                                shifts.find((item) => item.id === form.shift)
                            )}{' '}
                            {t('hours_shortcut')}.
                        </strong>{' '}
                    </p>
                </>
            )}

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit loading={isProcessing} disabled={isProcessing || !isValidForm()} />
            </Form.Field>
        </Form>
    )
}

export default PlannerRecordForm
