import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { useHasPermissions } from '@helpers/hooks'
// components
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Progress, Divider, Label, Button, Form, Loader } from 'semantic-ui-react'

const TaskList = ({ tasks, record, setData, onClose, setHeader }) => {
    const { t } = useTranslation()

    const [processingItem, setProcessingItem] = useState(null)

    const canManageTasks = useHasPermissions(['tasks.c_manage_tasks', 'tasks.c_manage_user_tasks'])

    useEffect(() => {
        setHeader(t('tasks') + ' - ' + record?.profile?.fullname_with_titles)
        // eslint-disable-next-line
    }, [])

    const calculateCompleteTasks = (tasks) => {
        let total = tasks.length
        let completed = tasks.filter((item) => item.is_closed === true).length
        let percentage = 0

        if (total > 0) {
            percentage = (completed / total) * 100
        }

        percentage = Math.round(percentage)

        return percentage
    }

    const handleCheckbox = async (task) => {
        if (!canManageTasks) {
            toast({
                type: 'error',
                icon: 'warning',
                title: t('you_dont_have_permissions_for_this_action'),
                animation: 'pulse',
                time: 5000,
            })
            return
        }
        setProcessingItem(task.id)
        const request = await requests.patch(API.TASKS + task.id + '/?query={id, is_closed}', {
            is_closed: !task.is_closed,
        })

        if (request.status === 200) {
            setData((prev) =>
                prev.filter((process) => {
                    if (process.id === record.id) {
                        process.tasks = process.tasks.map((taskItem) => {
                            if (taskItem.id === task.id) {
                                taskItem.is_closed = request.response.is_closed
                            }

                            return taskItem
                        })
                    }
                    return process
                })
            )
            setProcessingItem(null)
        }
    }

    return (
        <div>
            <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <strong>
                        {tasks.filter((task) => task.is_closed === true).length} / {tasks.length}
                    </strong>
                    <strong>({calculateCompleteTasks(tasks)}%)</strong>
                </div>
                <div style={{ marginTop: '0.25rem' }}>
                    <Progress percent={calculateCompleteTasks(tasks)} indicating />
                </div>
            </div>
            <Divider />
            {tasks.length === 0 && <p style={{}}>{t('no_data')}</p>}
            {tasks.map((task) => (
                <Label
                    fluid
                    basic
                    style={{
                        padding: '1rem',
                        width: '100%',
                        marginBottom: '0.5rem',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                    onClick={() => handleCheckbox(task)}
                >
                    <div style={{ display: 'flex' }}>
                        <SuperField
                            disabled={!canManageTasks}
                            as="checkbox"
                            checked={task.is_closed}
                            style={{ marginRight: '1rem' }}
                        />
                        <div style={task.is_closed ? { textDecoration: 'line-through', opacity: 0.5 } : {}}>
                            <div style={{ fontWeight: 'bold', fontSize: '1rem', marginTop: '0.1rem' }}>
                                {task.title}
                            </div>
                        </div>
                    </div>
                    <div>
                        {task.id === processingItem && <Loader size="tiny" className="dark-loader" active inline />}
                    </div>
                </Label>
            ))}

            {canManageTasks && (
                <>
                    <Divider />
                    <SuperDuperModal
                        header={t('add_task')}
                        trigger={<Button size="small" primary type="button" content={t('add_task')} />}
                        content={<TaskForm record={record} setData={setData} />}
                    />
                    <SuperDuperModal
                        header={t('add_tasks_by_template')}
                        trigger={
                            <Button active basic size="small" type="button" content={t('add_tasks_by_template')} />
                        }
                        content={<TaskForm isTemplate={true} record={record} setData={setData} />}
                    />
                </>
            )}
        </div>
    )
}

const TaskForm = ({ isTemplate, record, setData, onClose }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [title, setTitle] = useState('')
    const [selectedTemplate, setSelectedTemplate] = useState('')

    const handleSubmit = async () => {
        setProcessing(true)
        let tasksToCreate = []
        if (isTemplate && selectedTemplate) {
            // fetch tasks by template
            const requestTemplate = await requests.get(API.FOREIGNERS + 'task_templates/' + selectedTemplate + '/')
            if (requestTemplate.status === 200) {
                let tasks = requestTemplate.response.tasks.split('__')
                if (tasks.length > 0) {
                    for (let i = 0; i < tasks.length; i++) {
                        if (tasks[i] !== '') {
                            tasksToCreate.push({
                                title: tasks[i],
                            })
                        }
                    }
                }
            }
        } else {
            tasksToCreate = [{ title: title }]
        }

        const request = await requests.patch(API.FOREIGNERS + 'processes/' + record.id + '/?query={id, tasks}', {
            tasks: {
                create: tasksToCreate,
            },
        })
        if (request?.status === 200) {
            setData((prev) =>
                prev.filter((item) => {
                    if (item.id === record.id) {
                        item.tasks = request.response.tasks
                    }

                    return item
                })
            )
            onClose()
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            {isTemplate ? (
                <SuperField
                    search
                    text="title"
                    as="choice-select"
                    label={t('select_template')}
                    value={selectedTemplate}
                    endpoint={API.FOREIGNERS + 'task_templates/'}
                    onChange={(e, { value }) => setSelectedTemplate(value)}
                />
            ) : (
                <SuperField
                    as="input"
                    autoFocus
                    label={t('title')}
                    value={title}
                    onChange={(e, { value }) => setTitle(value)}
                />
            )}

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit loading={processing} disabled={processing || (title === '' && selectedTemplate === '')} />
            </Form.Field>
        </Form>
    )
}

export default TaskList
