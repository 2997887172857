import React from 'react'
import { useTranslation } from 'react-i18next'
// components
import { FlexRow, FlexItem } from '@components/tables/FlexTable'

const EmptyRow = ({ length, align, message }) => {
    const { t } = useTranslation()

    align = align || 'center'

    return (
        <>
            {length === 0 && (
                <FlexRow background="var(--white)" fontSize="1rem" borders>
                    <FlexItem bold textAlign={align}>
                        {message || t('no_data')}
                    </FlexItem>
                </FlexRow>
            )}
        </>
    )
}

export default EmptyRow
