import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// store
import { useIsMount } from '@helpers/hooks';
// components
import { Form } from 'semantic-ui-react';
import DatePresets from '@components/DatePresets';
import SuperField from '@components/forms/SuperField';

const EmployedPersonsFilter = ({ filters, setFilters }) => {
    const { t } = useTranslation()
    const isMount = useIsMount()
    const [dateType, setDateType] = useState(0)
    const [dateFrom, setDateFrom] = useState(dateType === 0 ? filters.valid_from_after : filters.valid_to_after)
    const [dateTo, setDateTo] = useState(dateType === 0 ? filters.valid_from_before : filters.valid_to_before)

    useEffect(() => {
        if (!isMount) {
            setFilters(prev => ({
                ...prev,
                valid_from_after: dateType === 0 ? dateFrom : "",
                valid_from_before: dateType === 0 ? dateTo : "",
                valid_to_after: dateType === 0 ? "" : dateFrom,
                valid_to_before: dateType === 0 ? "" : dateTo,
            }))
        }

        // eslint-disable-next-line
    }, [dateType, dateFrom, dateTo])

    return (
        <>
            <Form.Group>
                <SuperField as="radio"
                    label={t('employment_start')}
                    checked={dateType === 0}
                    onChange={() => setDateType(0)}
                />
                <SuperField as="radio"
                    label={t('employment_end')}
                    checked={dateType === 1}
                    onChange={() => setDateType(1)}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    size="small"
                    label={t('valid_from')}
                    value={dateFrom}
                    style={{ height: "2.75rem" }}
                    onChange={(e, { value }) => setDateFrom(value)}
                />
                <SuperField as="datepicker"
                    size="small"
                    label={t('valid_to')}
                    value={dateTo}
                    style={{ height: "2.75rem" }}
                    onChange={(e, { value }) => setDateTo(value)}
                />

            </Form.Group>

            <DatePresets
                to={dateTo}
                from={dateFrom}
                onSelection={ (firstMonthDay, lastMonthDay, year) => {
                    setDateFrom(firstMonthDay)
                    setDateTo(lastMonthDay)
                }}
            />
        </>
    );
};

export default EmployedPersonsFilter;