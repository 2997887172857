import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
//store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
//other
import Address from '@components/forms/Address'
import SuperField from '@components/forms/SuperField'
import { Form, Button, Message, Grid, Header } from 'semantic-ui-react'

function BusinessDetailForm({ data, setView, onConfirm, patchEndpoint }) {
    const { t } = useTranslation()

    const business_detail_data = {
        name: data?.business_detail?.name || '',
        identification_number: data?.business_detail?.identification_number || '',
        dit_number: data?.business_detail?.dit_number || '',
        vat_number: data?.business_detail?.vat_number || '',
        business_register_folder: data?.business_detail?.business_register_folder || '',
        tax_mode: data?.business_detail?.tax_mode || null,
        executive_manager: data?.business_detail?.executive_manager || '',
        reinvoice: data?.business_detail?.reinvoice || false,
        nace_code: data?.business_detail?.nace_code?.id || null,
        business_activities: data?.business_detail?.business_activities || '',
        // billing_address
        // shipping_address
    }

    const [billingAddress, setBillingAddress] = useState({
        street: data?.business_detail?.billing_address?.street || '',
        state: data?.business_detail?.billing_address?.state || '',
        number: data?.business_detail?.billing_address?.number || '',
        orientation_number: data?.business_detail?.billing_address?.orientation_number || '',
        postcode: data?.business_detail?.billing_address?.postcode || '',
        city: data?.business_detail?.billing_address?.city || '',
        country: data?.business_detail?.billing_address?.country || null,
        address_line: data?.business_detail?.billing_address?.address_line || '',
    })

    const [shippingAddress, setShippingAddress] = useState({
        street: data?.business_detail?.shipping_address?.street || '',
        postcode: data?.business_detail?.shipping_address?.postcode || '',
        city: data?.business_detail?.shipping_address?.city || '',
        country: data?.business_detail?.shipping_address?.country || null,
        state: data?.business_detail?.shipping_address?.state || '',
        number: data?.business_detail?.shipping_address?.number || '',
        orientation_number: data?.business_detail?.shipping_address?.orientation_number || '',
        address_line: data?.business_detail?.shipping_address?.address_line || '',
    })

    const [form, setForm] = useState({
        business_detail: business_detail_data,
    })

    const [errors, setErrors] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsProcessing(true)
        let dataset = form
        dataset.business_detail['billing_address'] = billingAddress
        dataset.business_detail['shipping_address'] = shippingAddress
        dataset.business_detail['is_supplier'] = true
        dataset.business_detail['is_freelancer'] = true

        const request = await requests.patch(patchEndpoint + `${data?.id}/`, dataset)

        if (request.status === 200) {
            onConfirm(request.response)
            setView(0)
        } else if (request.status === 400) {
            setErrors(request.response)
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={(e) => handleSubmit(e)}>
            <Message
                error
                visible={errors?.non_field_errors?.length > 0}
                header={t('error_submission_message')}
                list={errors?.non_field_errors}
            />
            <Form.Group widths={4} style={{ paddingTop: '0.6rem', paddingBottom: '0.6rem' }}>
                <SuperField
                    as="input"
                    required
                    autoFocus
                    label={t('name')}
                    placeholder={t('name')}
                    error={errors?.business_detail?.name?.[0] || false}
                    value={form?.business_detail?.name}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({
                            ...prev,
                            business_detail: {
                                ...prev.business_detail,
                                name: value,
                            },
                        }))
                    }
                />
                <SuperField
                    as="input"
                    required
                    label={t('id_number')}
                    placeholder={t('id_number')}
                    error={errors?.business_detail?.identification_number?.[0] || false}
                    value={form?.business_detail?.identification_number}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({
                            ...prev,
                            business_detail: {
                                ...prev.business_detail,
                                identification_number: value,
                            },
                        }))
                    }
                />
                <SuperField
                    as="input"
                    label={t('dit_number')}
                    placeholder={t('dit_number')}
                    error={errors?.business_detail?.dit_number?.[0] || false}
                    value={form?.business_detail?.dit_number}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({
                            ...prev,
                            business_detail: {
                                ...prev.business_detail,
                                dit_number: value,
                            },
                        }))
                    }
                />
                <SuperField
                    as="input"
                    label={t('vat_number')}
                    placeholder={t('vat_number')}
                    error={errors?.business_detail?.vat_number?.[0] || false}
                    value={form?.business_detail?.vat_number}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({
                            ...prev,
                            business_detail: {
                                ...prev.business_detail,
                                vat_number: value,
                            },
                        }))
                    }
                />
            </Form.Group>

            <Form.Group widths="equal" style={{ paddingTop: '0.6rem', paddingBottom: '0.6rem' }}>
                <SuperField
                    as="choice-select"
                    type="tax_mode"
                    label={t('tax_mode')}
                    value={form?.business_detail?.tax_mode?.toString() || ''}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({
                            ...prev,
                            business_detail: {
                                ...prev.business_detail,
                                tax_mode: value,
                            },
                        }))
                    }
                    initialOptions={{
                        attribute: 'tax_mode',
                        as_display: true,
                        source: data?.business_detail,
                    }}
                />
                <SuperField
                    as="input"
                    label={t('executive_manager')}
                    placeholder={t('executive_manager')}
                    error={errors?.business_detail?.executive_manager?.[0] || false}
                    value={form?.business_detail?.executive_manager}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({
                            ...prev,
                            business_detail: {
                                ...prev.business_detail,
                                executive_manager: value,
                            },
                        }))
                    }
                />
            </Form.Group>

            <Form.Group widths="equal" style={{ paddingTop: '0.6rem', paddingBottom: '0.6rem' }}>
                <SuperField
                    as="choice-select"
                    search
                    label={t('nace_code')}
                    endpoint={API.NACE_CODE}
                    text="name"
                    value={form.business_detail.nace_code}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({
                            ...prev,
                            business_detail: {
                                ...prev.business_detail,
                                nace_code: value,
                            },
                        }))
                    }
                    initialOptions={{
                        attribute: 'name',
                        source: data?.business_detail?.nace_code,
                    }}
                />
                <SuperField
                    as="input"
                    label={t('register_folder')}
                    placeholder={t('register_folder')}
                    error={errors?.business_detail?.business_register_folder?.[0] || false}
                    value={form?.business_detail?.business_register_folder}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({
                            ...prev,
                            business_detail: {
                                ...prev.business_detail,
                                business_register_folder: value,
                            },
                        }))
                    }
                />
            </Form.Group>

            <SuperField
                as="input"
                label={t('business_activities')}
                error={errors?.business_detail?.business_activities?.[0] || false}
                value={form?.business_detail?.business_activities}
                onChange={(e, { value }) =>
                    setForm((prev) => ({
                        ...prev,
                        business_detail: {
                            ...prev.business_detail,
                            business_activities: value,
                        },
                    }))
                }
            />

            <Grid stackable>
                <Grid.Row columns={1}>
                    <Grid.Column style={{ padding: 0 }}>
                        <Header as="h4" content={t('billing_address')} />
                        <Address isForm address={billingAddress} setAddress={setBillingAddress} />
                    </Grid.Column>
                    <Grid.Column style={{ padding: 0, marginTop: '1rem' }}>
                        <Header as="h4" content={t('shipping_address')} />
                        <Address isForm address={shippingAddress} setAddress={setShippingAddress} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Form.Field style={{ textAlign: 'right' }}>
                <Button primary loading={isProcessing} type="submit" disabled={isProcessing}>
                    {t('confirm')}
                </Button>
            </Form.Field>
        </Form>
    )
}

export default BusinessDetailForm
