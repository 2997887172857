import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { requests } from '@helpers/requests'
import { API } from '@store/config'
// components
import { Form, Button, Header, Divider } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const RegistersBulk = ({ selected, setSelected, setData, onClose }) => {
    const { t } = useTranslation()
    
    const [isProcessing, setIsProcessing] = useState(false)
    const [register, setRegister] = useState([])

    const [form, setForm] = useState({
        register_of_penalties: false,
        register_of_penalties_valid_from: "",
        register_of_penalties_valid_to: "",
    })
    const initialRegister = {
        country: '',
        valid_from: "",
        valid_to: ""
    }

    const onSubmit = async () => {
        setIsProcessing(true)
        let updated = []

        let additional = {}
        if (form.register_of_penalties) {
            additional = {
                register_of_penalties: form.register_of_penalties,
                register_of_penalties_valid_from: form?.register_of_penalties_valid_from ? form?.register_of_penalties_valid_from : null,
                register_of_penalties_valid_to: form?.register_of_penalties_valid_to ? form?.register_of_penalties_valid_to : null,
            }
        }

        for(let i = 0; i < selected.length; i++){
            const formData = {
                other_country_register: { create: register.filter(item => item.country !== "").map(item => ({
                    country: item.country,
                    valid_from: item.valid_from ? item.valid_from : null,
                    valid_to: item.valid_to ? item.valid_to : null,
                })) },
                ...additional
            }
            const request = await requests.patch(API.FOREIGNERS + "processes/" + selected[i].id + "/", formData)
            if (request.status === 200) {
                updated.push(request.response) 
            }
        }

        setData(prev => prev.filter(item => {
            const itemToUpdate = updated.find(updatedItem => updatedItem.id === item.id)
            if (item.id === itemToUpdate?.id) {
                item.other_country_register = itemToUpdate.other_country_register
                item.register_of_penalties = itemToUpdate.register_of_penalties
                item.register_of_penalties_valid_from = itemToUpdate.register_of_penalties_valid_from
                item.register_of_penalties_valid_to = itemToUpdate.register_of_penalties_valid_to
            }
            return item
        }))

        onClose()
        setSelected([])
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={onSubmit}>
            <SuperField
                as="checkbox"
                label={t('has_register_of_penalties_from_his_country')}
                checked={form.register_of_penalties}
                onChange={() => setForm(prev => ({ ...prev, register_of_penalties: !form.register_of_penalties }))}
            />
            {form.register_of_penalties &&
                <Form.Group widths="equal">
                    <SuperField
                        search
                        as="datepicker"
                        label={t('valid_from')}
                        value={form.register_of_penalties_valid_from}
                        onChange={(e, { value }) => setForm(prev => ({ ...prev, register_of_penalties_valid_from: value })) }
                    />
                    <SuperField
                        search
                        as="datepicker"
                        label={t('valid_to')}
                        value={form.register_of_penalties_valid_to}
                        onChange={(e, { value }) => setForm(prev => ({ ...prev, register_of_penalties_valid_to: value })) }
                    />
                </Form.Group>
            }

            <Header as={'h5'} content={t('registers_in_other_country')} />
            <Divider />

            <div>
                {register.map((item, index) => (
                    <div key={index}>
                        <Form.Group widths="equal">
                            <SuperField
                                as="choice-select"
                                search
                                label={t('country')}
                                type="countries"
                                value={item.country}
                                onChange={(e, { value }) => setRegister(prev => prev.filter((itemRegister, idx) => {
                                    if (idx === index) {
                                        itemRegister.country = value
                                    }
                                    return itemRegister
                                }))}
                            />
                            <SuperField
                                search
                                as="datepicker"
                                label={t('valid_from')}
                                value={item.valid_from}
                                onChange={(e, { value }) => setRegister(prev => prev.filter((itemRegister, idx) => {
                                    if (idx === index) {
                                        itemRegister.valid_from = value
                                    }
                                    return itemRegister
                                }))}
                            />
                            <SuperField
                                search
                                as="datepicker"
                                label={t('valid_to')}
                                value={item.valid_to}
                                onChange={(e, { value }) => setRegister(prev => prev.filter((itemRegister, idx) => {
                                    if (idx === index) {
                                        itemRegister.valid_to = value
                                    }
                                    return itemRegister
                                }))}
                            />
                            <Form.Field width={'1'} style={{ textAlign: 'right' }}>
                                <Button type="button" icon='remove' style={{ cursor: "pointer", color: "var(--danger)", background: "none", paddingTop: "2.3rem" }} 
                                    onClick={() => setRegister(prev => prev.filter((item, idx) => idx !== index))}
                                />
                            </Form.Field>
                        </Form.Group>
                    </div>
                ))}
                <Button type="button" primary size="small" content={t('add_register')} onClick={() => setRegister(prev => [...prev, initialRegister])} />
            </div>
            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    disabled={isProcessing}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    )
}

export default RegistersBulk