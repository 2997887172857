import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import EmptyRow from '@components/tables/EmptyRow';
import { Form, Card, Divider, Header, Segment } from 'semantic-ui-react';

const InterruptionReasonForm = ({ attendance, setInterruptions, setAttendance, onClose }) => {
    const { t } = useTranslation()
    const [reasons, setReasons] = useState([])
    const [loading, setLoading] = useState(true)
    const [processing, setProcessing] = useState(false)

    useEffect(() => {
        async function fetchReasons(){
            const request = await requests.get(API.ATTENDANCE_INTERRUPTION_REASONS)
            
            if (request.status === 200){
                setReasons(request.response)
            }
            setLoading(false)
        }

        fetchReasons()
    }, [])

    const onReasonSelect = async (reason) => {
        setProcessing(true)
        const request = await requests.post(API.ATTENDANCE_INTERRUPTIONS, {
            reason: reason,
            record: attendance.id,
            start: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
            date: moment().format("YYYY-MM-DD")
        })
        
        if (request.status === 201) {
            setInterruptions(prev => ([...prev, request.response]))
            const fetchAttendance = await requests.get(API.ATTENDANCE + attendance.id + "/")
            if (fetchAttendance.status === 200) {
                setAttendance(fetchAttendance.response)
            }
            onClose()
        }
        
        setProcessing(false)
    }

    return (
        <Form>
            <Header as="h2" content={t('interruption_reasons')} style={{ textAlign: 'center' }}/>
            <Divider/>
            <Segment 
                loading={loading || processing}
                style={{ 
                    padding: 0, 
                    background: "transparent", 
                    boxShadow: "none", 
                    border: "none",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                }}
            >
                { (loading || processing) && 
                    <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "6rem" }}> 
                        { loading && t('loading_interruption_reasons') } 
                        { processing && t('processing_selected_reason') } 
                    </p>
                }
                { (!loading && !processing) && 
                    <Card.Group stackable itemsPerRow={3}>
                        <EmptyRow length={reasons.length}/>
                        { reasons.map(reason => (
                            <Card key={reason.id} onClick={ () => onReasonSelect(reason.id) }>
                                <Card.Content style={{ textAlign: 'center', cursor: "pointer", fontWeight: 'bold' }} verticalAlign="middle">
                                    { reason.title }
                                </Card.Content>
                            </Card>
                        )) }
                    </Card.Group>
                }
            </Segment>
        </Form>
    );
};

export default InterruptionReasonForm;