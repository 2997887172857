import React from "react";
//components
import { Comment } from "semantic-ui-react";
import AvatarIcon from '@components/AvatarIcon';

const CommentItem = (props) => {

    return (
        <div style={{ paddingLeft: "1rem", paddingBottom: "1rem" }}>
            <Comment>
                <Comment.Avatar src={<AvatarIcon size={40} name={props.autor} />} />
                <Comment.Content>
                    <Comment.Author as='a'>{props.autor}</Comment.Author>
                    <Comment.Metadata>
                        <div>{props.date}</div>
                    </Comment.Metadata>
                    <Comment.Text>{props.desc}</Comment.Text>
                </Comment.Content>
            </Comment>
        </div>
    );
};

export default CommentItem;
