import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux';
// store
import { addGlobalPreferences } from '@store/actions';
// components
import { Divider, Form, Header, Container } from 'semantic-ui-react';
import ColorPickerField from '@components/forms/settings/ColorPickerField';

const ColorPreferences = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const colors = useSelector(state => state.global_pref.colors);

    // color preference state:
    // eslint-disable-next-line
    const [defaultColor, setDefaultColor] = useState(colors.default);
    const [dark, setDark] = useState(colors.dark);
    const [light, setLight] = useState(colors.light);

    // main colors
    const [primary, setPrimary] = useState(colors.primary);
    const [info, setInfo] = useState(colors.info);
    const [success, setSuccess] = useState(colors.success);
    const [warning, setWarning] = useState(colors.warning);
    const [danger, setDanger] = useState(colors.danger);

    // secondary colors
    const [blue, setBlue] = useState(colors.blue);
    const [iceblue, setIceblue] = useState(colors.iceblue);

    // eslint-disable-next-line
    const setColorPreferences = () => {
        dispatch(addGlobalPreferences({
            colors: {
                // base 0
                default: defaultColor,
                white: "#ffffff",
                black: "#292929",
                dark: dark,
                light: light,

                // base 1
                primary: primary,
                info: info,
                success: success,
                warning: warning,
                danger: danger,

                // others
                blue: blue,
                iceblue: iceblue,
            }
        }))
    }

    useEffect(() => {
        setColorPreferences();
        // eslint-disable-next-line
    }, [primary, info, success, warning, danger, defaultColor, dark, light, blue, iceblue])

    return (
        <Container fluid style={{ marginTop: "1rem" }}>
            <Form>
                <Header style={{ padding: '1rem', paddingBottom: 0 }} as="h3">{t('base_colors')}</Header>
                <Divider />
                <Form.Group style={{ padding: '1rem', paddingBottom: 0 }}>
                    <ColorPickerField label={t('default_color')} color={defaultColor} setColor={setDefaultColor} />
                    <ColorPickerField label={t('dark_color')} color={dark} setColor={setDark} />
                    <ColorPickerField label={t('light_color')} color={light} setColor={setLight} />
                </Form.Group>

                <Header style={{ padding: '1rem', paddingBottom: 0 }} as="h3">{t('main_colors')}</Header>
                <Divider />
                <Form.Group style={{ padding: '1rem', paddingBottom: 0 }}>
                    <ColorPickerField label={t('primary_color')} color={primary} setColor={setPrimary} />
                    <ColorPickerField label={t('info_color')} color={info} setColor={setInfo} />
                    <ColorPickerField label={t('success_color')} color={success} setColor={setSuccess} />
                    <ColorPickerField label={t('warning_color')} color={warning} setColor={setWarning} />
                    <ColorPickerField label={t('danger_color')} color={danger} setColor={setDanger} />
                </Form.Group>

                <Header as="h3" style={{ padding: '1rem', paddingBottom: 0 }}>{t('secondary_colors')}</Header>
                <Divider />
                <Form.Group style={{ padding: '1rem', paddingBottom: 0 }}>
                    <ColorPickerField label={t('blue_color')} color={blue} setColor={setBlue} />
                    <ColorPickerField label={t('ice_blue_color')} color={iceblue} setColor={setIceblue} />
                </Form.Group>
            </Form>
        </Container>
    );
};

export default ColorPreferences;