import React from 'react'
import { Divider, Header, Icon } from 'semantic-ui-react'

const CustomFormDivider = ({ header, headerIcon, headerSize, content, description, divider, ...rest }) => {
    return (
        <>
            {divider && <Divider />}
            {header && (
                <Header as={headerSize ? headerSize : 'h4'} {...rest}>
                    {' '}
                    {headerIcon && <Icon name={headerIcon} />} {header}{' '}
                </Header>
            )}
            {description && <p> {description}. </p>}
            <Divider />

            {content}
        </>
    )
}

export default CustomFormDivider
