import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { tzDateTime } from '@helpers/dates'
// components
import CanView from '@components/perms/CanView'
import AvatarIcon from '@components/AvatarIcon'
import SuperField from '@components/forms/SuperField'
import SpinnerSegment from '@components/SpinnerSegment'
import { Divider, Form, Button, Comment } from 'semantic-ui-react'

const CandidateComments = ({ candidate, currentStage, room, onClose, setHeader, header }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    const [loading, setloading] = useState(true)
    const [processing, setProcessing] = useState(false)
    const [comment, setComment] = useState('')

    const [comments, setComments] = useState([])

    useEffect(() => {
        async function fetchComments() {
            setloading(true)
            const request = await requests.get(
                API.CANDIDATES + `${candidate}/history/?action=2&recruitment=${room}&ordering=-created_on`
            )
            if (request.status === 200) {
                let commentsArray = []
                let response = request.response
                for (let i = 0; i < response.length; i++) {
                    commentsArray.push({
                        id: response[i].id,
                        note: response[i].note,
                        created_on: response[i].created_on,
                        created_by: response[i].created_by,
                        stage: response[i].archived_recruitment_stage,
                    })
                }
                setComments(commentsArray)
            }
            setloading(false)
        }

        if (header) {
            setHeader(header)
        }

        fetchComments()
    }, [])

    const handleSubmit = async () => {
        setProcessing(true)

        const data = {
            note: comment,
            recruitment: room,
            archived_recruitment_stage: currentStage.id,
        }

        const request = await requests.post(API.CANDIDATES + candidate + '/comment/', data)
        if (request.status === 201) {
            setComments((prev) => [
                {
                    id: request.response.id,
                    note: request.response.note,
                    created_on: request.response.created_on,
                    created_by: request.response.created_by,
                    stage: request.response.archived_recruitment_stage,
                },
                ...prev,
            ])

            const inputElement = document.getElementById('comment-box')
            if (inputElement) {
                inputElement.focus()
            }
            setComment('')
        }

        setProcessing(false)
    }

    return (
        <SpinnerSegment
            loading={loading}
            loadingMessage={t('loading_candidate_comments')}
            marginBottom={loading ? '1rem' : '0'}
        >
            {!loading && (
                <>
                    <CanView permissions={['candidates.c_manage_recruitment']}>
                        <Form onSubmit={handleSubmit}>
                            <SuperField
                                autoFocus
                                as="textarea"
                                id="comment-box"
                                value={comment}
                                placeholder={t('enter_comment')}
                                onChange={(e, { value }) => setComment(value)}
                            />
                            <Form.Field style={{ textAlign: 'left' }}>
                                <Button primary content={t('confirm')} disabled={processing} loading={processing} />
                            </Form.Field>
                        </Form>
                        <Divider />
                    </CanView>

                    {comments.length === 0 && (
                        <strong
                            style={{
                                display: 'block',
                                textAlign: 'center',
                                fontSize: '1.2rem',
                                paddingBottom: '2rem',
                                paddingTop: '2rem',
                            }}
                        >
                            {t('no_data')}
                        </strong>
                    )}

                    {comments.length > 0 && (
                        <div style={{ maxHeight: '470px', overflow: 'scroll', paddingRight: '0.5rem' }}>
                            <Comment.Group style={{ paddingBottom: '0.5rem', marginBottom: '0.5rem' }}>
                                {comments.map((item, idx) => (
                                    <div
                                        key={idx}
                                        style={{
                                            //paddingLeft: '1rem',
                                            marginTop: idx === 0 ? '0rem' : '1rem',
                                            borderRight: `10px solid ${item.stage.color + '40'}`,
                                            background: 'var(--light)',
                                            padding: '0.5rem',
                                            borderTopRightRadius: '7px',
                                            borderBottomRightRadius: '7px',
                                        }}
                                    >
                                        <Comment style={{ paddingLeft: '0.5rem' }}>
                                            <Comment.Avatar
                                                src={
                                                    <AvatarIcon
                                                        size={40}
                                                        src={item.created_by?.profile_picture || null}
                                                        name={item.created_by?.name || 'JD'}
                                                    />
                                                }
                                            />
                                            <Comment.Content>
                                                <Comment.Author as="a">{item.created_by?.name}</Comment.Author>
                                                <Comment.Metadata>
                                                    <div>
                                                        {tzDateTime(item.created_on).format(dateFormat + ' HH:mm:ss')}
                                                    </div>
                                                </Comment.Metadata>
                                                <Comment.Text>{item.note}</Comment.Text>
                                            </Comment.Content>
                                        </Comment>
                                    </div>
                                ))}
                            </Comment.Group>
                        </div>
                    )}
                </>
            )}
        </SpinnerSegment>
    )
}

export default CandidateComments
