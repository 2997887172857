import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
// components
import SuperField from '@components/forms/SuperField';
import { Segment, Header, Grid } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable'

const CandidateRejections = ({ room }) => {
    const { t } = useTranslation()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedFilter, setSelectedFilter] = useState(1)
    const [filters, setFilters] = useState({
        created_on_after: "",
        created_on_before: "",
        room: room ? room : "",
        isRejected: false
    })

    useEffect(() => {
        async function fetchHiringStats() {
            if (filters.room !== "") {
                setLoading(true)
                const request = await getRequest(API.CANDIDATES + `rejections/?rejected_by_candidate=${filters.isRejected}&recruitment=${filters.room}&created_on_after=${filters.created_on_after}&created_on_before=${filters.created_on_before}`)

                if (request.status === 200) {
                    setData(request.response)
                }
                setLoading(false)
            }
        }

        fetchHiringStats()
    }, [filters])

    useEffect(() => {
        if (selectedFilter === 1) {
            setFilters({...filters, isRejected: false})
        }
        else if (selectedFilter === 2) {
            setFilters({...filters, isRejected: true})
        }

        // eslint-disable-next-line
    }, [selectedFilter])

    const FilterAction = ({ text, isActive, ...rest }) => {
        return (
            <span className={"filter-action " + (isActive && "active")}  {...rest}>
                {text}
            </span>
        )
    }

    return (
        <>
            <FlexRow padding="0,5rem">
                <FlexItem>
                    <Header as="h2" textAlign="left" content={t('candidate_rejections')} />
                </FlexItem>
                <FlexItem textAlign="right" basis="50%">
                    <SuperField
                        as='datepicker'
                        placeholder={ t('date_from') }
                        value={filters.created_on_after}
                        onChange={(e, { value }) => setFilters({ ...filters, created_on_after: value })}
                    />
                </FlexItem>
                <FlexItem textAlign="right" basis="50%">
                    <SuperField
                        as='datepicker'
                        placeholder={ t('date_to') }
                        value={filters.created_on_before}
                        onChange={(e, { value }) => setFilters({ ...filters, created_on_before: value })}
                    />
                </FlexItem>
                <FlexItem textAlign="right" basis="50%">
                    <SuperField as="choice"
                        search
                        endpoint={API.HIRING_ROOM + "?query={id, name}"}
                        text={"name"}
                        value={parseInt(filters.room)}
                        onChange={(e, { value }) => setFilters({ ...filters, room: value })}
                    />
                </FlexItem>
            </FlexRow>

            {filters.room === "" &&
                <Grid centered style={{ marginTop: "2rem" }}>
                    <Header as="h2" content={ t('select_hiring_room_first') } textAlign="center" />
                </Grid>
            }
            <Segment basic loading={loading}>
                { !loading && filters.room &&
                    <>
                        <FlexRow padding="0.5rem">
                            <FlexItem basis="300%">
                                <FilterAction isActive={selectedFilter === 1} onClick={() => setSelectedFilter(1)} text={t('rejected_by_recruiter')} />
                                <FilterAction isActive={selectedFilter === 2} onClick={() => setSelectedFilter(2)} text={t('rejected_by_candidate')} />
                            </FlexItem>
                        </FlexRow>
                        <FlexTable>

                            <FlexRow>
                                <FlexHeader content="#" basis="10%" />
                                <FlexHeader content={ t('recruiter') } basis="50%" />
                                <FlexHeader content={ t('candidate') } basis="50%" />
                                <FlexHeader content={ t('rejected_on') } basis="40%" />
                                <FlexHeader content={ t('reason') } basis="200%" />
                            </FlexRow>
                            { data.length === 0 &&
                                <FlexRow fontSize="1rem" borders>
                                    <FlexItem bold textAlign="center" content={ t('no_records') } />
                                </FlexRow>
                            }
                            {data.map((rejection, index) => (
                                <FlexRow key={rejection.id} fontSize="1rem" borders>
                                    <FlexItem bold basis="10%" content={index + 1 + "."} />
                                    <FlexItem basis="50%" content={rejection.created_by?.name} />
                                    <FlexItem basis="50%" content={rejection.profile?.fullname} />
                                    <FlexItem basis="40%" content={rejection.created_on} />
                                    <FlexItem basis="200%" content={rejection.note} />
                                </FlexRow>
                            ))}
                        </FlexTable>
                    </>
                }
            </Segment>
        </>
    );
};

export default CandidateRejections;