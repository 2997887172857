import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { toast } from 'react-semantic-toasts';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Divider, Form, Button } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';

const DeleteGroupForm = ({ group, groupDocs, setGroupDocs, deleteGroup, onClose }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [removeDocs, setRemoveDocs] = useState(false)

    const onDelete = async () => {
        setProcessing(true)

        if (removeDocs === true) {
            for(let i = 0; i < groupDocs.length; i++){
                const request = await requests.del(API.ATTACHMENTS + groupDocs[i]?.id + "/")
                if (request.status === 204) {
                    setGroupDocs(prev => prev.filter(item => item.id !== groupDocs[i]?.id))
                }
            }
        }

        const request = await requests.post(API.ATTACHMENT_GROUPS + group.id + "/delete/")
        if (request.status === 200) {
            deleteGroup(group.id, removeDocs, groupDocs)
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('folder_removed'),
                animation: 'pulse',
                time: 2000,
            })
        }

        onClose()

        setProcessing(false)
    }

    return (
        <div>
            <p style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                { t('confirm_your_decision_and_choose_actions_to_perform') }:
            </p>
            <SuperField as="checkbox"
                disabled
                checked={true}
                label={t('remove_folder') + ": " + group.name }
            />

            <SuperField as="checkbox"
                style={{ marginTop: "1rem" }}
                checked={removeDocs}
                onChange={() => setRemoveDocs(!removeDocs)}
                label={t('remove_content_of_the_folder')}
            />

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <Button 
                    primary
                    type="button"
                    loading={processing}
                    disabled={processing}
                    content={t('confirm')}
                    onClick={() => onDelete()}
                />
            </Form.Field>
        </div>
    )
}

export default DeleteGroupForm;