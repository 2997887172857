import React from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line
import moment from 'moment';
// store
import { thousandsSeparators } from '@helpers/functions';
// components
import { Card, Grid, Container, Divider/*, Header*/ } from 'semantic-ui-react';


const Overview = ({ bank_account, connection }) => {
    const { t } = useTranslation()
    
    const printBalance = (type) => {
        const balance = bank_account?.balances?.find(balance => balance?.balanceType === type)?.balanceAmount?.amount || 0
        return thousandsSeparators(balance)
    }

    return (
        <Container fluid>
            <Card fluid style={{ borderRadius: 0, textAlign: 'left' }}>
                <Card.Content>
                    <Grid>
                        <Grid.Row verticalAlign="top">
                            <Grid.Column computer={5} tablet={8} mobile={16}>
                                <div>
                                    <strong style={{ fontSize: "1.2rem" }}>{ t('iban_and_currency') }</strong> <br/>
                                    <p>{ bank_account?.accountReference?.iban || "" } / <strong>{ bank_account?.accountReference?.currency || "" }</strong></p>
                                </div>

                                <div style={{ marginTop: "2rem" }}>
                                    <strong style={{ fontSize: "1.2rem" }}>{ t('bic') }</strong> <br/>
                                    <p>{ bank_account?.bic || "" }</p>
                                </div>
                                
                                {/* <div style={{ marginTop: "2rem" }}>
                                    <strong style={{ fontSize: "1.2rem" }}>Last update</strong> <br/>
                                    <p>{ moment(bank_account.dateUpdated).format("DD.MM.YYYY HH:mm:ss") }</p>
                                </div> */}
                            </Grid.Column>
                            <Grid.Column computer={5} tablet={8} mobile={16}>
                                <div>
                                    <strong style={{ fontSize: "1.2rem" }}> { t('name') } </strong> <br/>
                                    <p>{ bank_account?.displayName || "" }</p>
                                </div>
                                <div style={{ marginTop: "2rem" }}>
                                    <strong style={{ fontSize: "1.2rem" }}>{ t('bank_orga') } / { t('bank_code') }</strong> <br/>
                                    <p>{ bank_account?.bankName || "" } / { bank_account?.bankCode || "" }</p>
                                </div>
                            </Grid.Column>
                            <Grid.Column computer={6} tablet={16} mobile={16} style={{ fontSize: "1.2rem" }}>
                                { bank_account?.permissions?.can_view_account_balances && 
                                    <>
                                        <div><strong>Available balance: </strong> <br/>{ printBalance("interimAvailable") }</div>
                                        <div style={{ marginTop: "2rem" }}><strong>Booked balance: </strong> <br/>{ printBalance("interimBooked") } </div>
                                        <div style={{ marginTop: "2rem" }}><strong>Closing Booked balance: </strong> <br/>{ printBalance("closingBooked") } </div>
                                    </>
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card.Content>
            </Card>
            <Divider/>
            {/* <Header as="h3" textAlign='left'> Monthly transactions </Header> */}
        </Container>
    );
};

export default Overview;