import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import '@store/languages'
import { setLocaleLanguage } from '@helpers/dates'
//components
import { Grid } from 'semantic-ui-react'
import CommentItem from './CommentItem'

const CommentList = ({ comments }) => {
    const { t } = useTranslation()

    return comments.length ? (
        comments.map((item, key) => (
            <CommentItem
                key={key}
                autor={item.created_by}
                desc={item.note}
                date={moment(item.created_on).locale(setLocaleLanguage()).fromNow()}
            />
        ))
    ) : (
        <Grid centered>{t('no_comment')}</Grid>
    )
}

export default CommentList
