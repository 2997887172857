import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Divider, Button, Popup } from 'semantic-ui-react';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import IssueForm from '../forms/IssueForm';
import UltimateIssueFilter from './UltimateIssueFilter';


const IssueFilters = ({ stats, selectedView, setSelectedView, setIssues, setStats, setFiltered, state, setState }) => {
    const { t } = useTranslation();

    const [openFilters, setOpenFilters] = useState(false)


    // Component is used to change Issue Views
    const ViewChanger = ({ icon, changeTo, label, ...rest }) => {
        return (
            <Popup
                content={label}
                position='top center'
                inverted
                trigger={
                    <Icon
                        {...rest}
                        onClick={() => setSelectedView(changeTo)}
                        className={selectedView === changeTo ? "toggle-button active" : "toggle-button"}
                        size="large"
                        name={icon}
                    />
                }
            />
        )
    }
    const FilterEnableIcon = () => {
        return (
            <Popup
                content={"Filter"}
                position='top center'
                inverted
                trigger={
                    <Icon
                        onClick={() => setOpenFilters(!openFilters)}
                        className={openFilters ? "toggle-button active" : "toggle-button"}
                        size="large"
                        name={'filter'}
                    />
                }
            />
        )
    }

    return (
        <>
            <FlexRow padding="0">
                <FlexItem>
                    <Button.Group basic size='small' style={{ borderRadius: 0 }}>
                        <Button active={state === 1} onClick={() => setState(1)}> {t('opened')} </Button>
                        <Button active={state === 2} onClick={() => setState(2)}> {t('closed')} </Button>
                        <Button active={state === 3} onClick={() => setState(3)}> {t('all')} </Button>
                    </Button.Group>
                </FlexItem>
                <FlexItem textAlign="right">
                    <FilterEnableIcon />
                    <ViewChanger
                        icon="list"
                        changeTo="list"
                        label="List view"
                        style={{ marginLeft: "1rem" }}
                    />
                    <ViewChanger
                        disabled
                        icon="th"
                        changeTo="kanban"
                        label="Kanban view"
                        style={{ marginLeft: "1rem", marginRight: "1rem" }}
                    />
                </FlexItem>
                <FlexItem basis="30%" textAlign="center">
                    <SuperDuperModal
                        size="small"
                        closeIcon
                        trigger={
                            <Button style={{ width: "100%" }} primary content={t('add')} />
                        }
                        content={
                            <IssueForm employees={stats} setIssues={setIssues} setStats={setStats} />
                        }
                    />
                </FlexItem>
            </FlexRow>
            {openFilters && <UltimateIssueFilter setFiltered={setFiltered} />}
            <Divider />
        </>
    );
};

export default IssueFilters;