import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// components
import { Button, Form, Header, Divider, List, Icon } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import SuperFieldSet from '@components/forms/SuperFieldSet';

const Experiences = ({ setData }) => {
    const { t } = useTranslation();
    const educationInit = {
        title: "",
        employer: "",
    }

    const [experiences, setExperiences] = useState([])
    const [experience, setExperience] = useState(educationInit)

    const [isHovered, setIsHovered] = useState(false)
    const [isFormHovered, setIsFormHovered] = useState(false)
    const [isFormFocused, setIsFormFocused] = useState(false)

    useEffect( () => {
        let experienceList = []
        if( experiences.length > 0 ){
            for (let i = 0; i < experiences.length; i++){
                experienceList.push({
                    job_title: experiences[i].title, employer: experiences[i].employer,
                })
            }
        }
        
        let experienceArray = {
            create: experienceList
        }

        setData(prevState => ({
            ...prevState,
            experiences: experienceArray
        }))

    }, [experiences, setData])

    const addExperience = () => {
        if( experience.name !== "" && experience.type !== "" ){
            setExperiences([...experiences, experience])
            setExperience(educationInit)
        }
    }

    return (
        <SuperFieldSet legend={ t('experiences') } onClick={() => setIsFormHovered(true)} onMouseOver={() => setIsFormHovered(true)} onMouseLeave={() => setIsFormHovered(false)} >
            {/* Generate List of added experiences */}
            {
                experiences.length > 0
                ?
                <List selection verticalAlign='middle'>
                    { experiences.map( (experience, index) => (
                        <List.Item key={index} style={{ padding: "1rem" }} onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                            <List.Icon name='briefcase' size='large' verticalAlign='middle' />
                            <List.Content>
                                <List.Content verticalAlign='middle' floated='right'>
                                    <Icon
                                        size="large"
                                        name="close"
                                        style={{
                                            display: isHovered ? "block" : "none",
                                            color: "var(--danger)",
                                            marginTop: "0.4rem"
                                        }}
                                        onClick={ () => setExperiences(experiences.filter( (item, listIndex) => listIndex !== index )) }
                                    />
                                </List.Content>
                                <List.Header>{ experience.title }</List.Header>
                                <List.Description>{ experience.employer }</List.Description>
                            </List.Content>
                        </List.Item>
                    )) }
                </List>
                :
                <Header
                    textAlign="center"
                    as="h4"
                    content={ t('mouse_or_click_experiences') }
                    style={{
                        display: (isFormHovered && experiences.length === 0) || isFormFocused ? "none" : "block",
                        opacity: ".7"
                    }}
                />
            }

            {/* Create simple form to add experience */}
            <div style={{ display: isFormHovered || isFormFocused ? "block" : "none" }}>
                <Form.Group widths="equal">
                    <SuperField as="input"
                        label={ t('position') }
                        value={experience.title}
                        onChange={(e) => setExperience({
                            ...experience,
                            title: e.target.value
                        })}
                        onClick={ () => setIsFormFocused(true)}
                    />
                    <SuperField as="input"
                        label={ t('employer') }
                        value={experience.employer}
                        onChange={(e) => setExperience({
                            ...experience,
                            employer: e.target.value
                        })}
                        onClick={ () => setIsFormFocused(true)}
                    />
                </Form.Group>
                <Divider />
                <Button.Group>
                    <Button
                        primary
                        size="small"
                        type="button"
                        content={ t("add") }
                        disabled={ experience.title === "" || experience.employer === "" }
                        onClick={(e) => addExperience() }
                    />
                    <Button
                        style={{ marginLeft: "0.2rem" }}
                        size="small"
                        type="button"
                        content={ t("close") }
                        onClick={(e) => {
                            setIsFormHovered(false)
                            setIsFormFocused(false)
                        }}
                    />
                </Button.Group>
            </div>
        </SuperFieldSet>

    );
};

export default Experiences;