import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";
//stores
import { isEmpty } from '@helpers/validation';
import { API } from '@store/config';
import { routes } from '@store/routes/index';
import { createRequest, getRequest } from '@services/ServiceCommon';
// components
import { Form, Message, Divider, Icon } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import CanView from '@components/perms/CanView';
// others
import moment from 'moment';

const LaunchHiringRoom = ({ record, onClose }) => {
    const offer = record
    const { t } = useTranslation();
    const [isProcessing, setIsProcessing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const history = useHistory()

    const [orders, setOrders] = useState([])

    const [hiringRoom, setHiringRoom] = useState({
        name: (offer && offer.title && offer.title) || '',
        process: "",
        job_position: (offer && offer.id && offer.id) || 0,
        dateFrom: (offer && offer.date_from && offer.date_from) || '',
        dateTo: (offer && offer.date_to && offer.date_to) || '',
        hrorder: (offer && offer.hrorder && offer.hrorder.id) || "",
        target: (offer && offer.target && offer.target) || null,
        active: (offer && offer.is_active && offer.is_active) || true,
    });

    const [invalidDateRange, setInvalidDateRange] = useState(false);

    // Validate Form
    useEffect(() => {
        if (hiringRoom.dateFrom !== '' && hiringRoom.dateTo !== '') {
            if (moment(hiringRoom.dateFrom).isAfter(hiringRoom.dateTo)) {
                setInvalidDateRange(t('invalid_date_should_be_higher'));
            } else {
                setInvalidDateRange(false);
            }
        }
        // eslint-disable-next-line
    }, [hiringRoom]);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true)
            const request = await getRequest(API.ORDERS + "?query={id, name, target, date_from, date_to}&resource_type=hrorder&is_active=true")

            if (request.status === 200) {
                let options = []
                for (let i = 0; i < request.response.length; i++) {
                    options.push({ key: request.response[i].id, value: request.response[i].id, text: request.response[i].name, target: request.response[i].amount, date_from: request.response[i].start_date, date_to: request.response[i].end_date })
                }

                setOrders(options)
            }
            setIsLoading(false)
        }

        fetchData()
        // eslint-disable-next-line
    }, [])

    const handleSubmit = async () => {
        setIsProcessing(true);
        setErrorList([]);

        const data = {
            name: hiringRoom.name,
            process: hiringRoom.process,
            date_from: hiringRoom.dateFrom !== '' ? hiringRoom.dateFrom : null,
            date_to: hiringRoom.dateTo !== '' ? hiringRoom.dateTo : null,
            is_active: hiringRoom.active,
            target: hiringRoom.target,
            hrorder: hiringRoom.hrorder !== "" ? hiringRoom.hrorder : null,
            job_position: hiringRoom.job_position,
        }

        const request = await createRequest(API.HIRING_ROOM, data)

        if (request.status === 201) {
            history.push(routes.HIRING_ROOMS + request.response.id)
        } else {
            setErrorList([...errorList, request.response.detail]);
        }

        setIsProcessing(false);
    }

    const autoCompleteData = (value) => {
        setHiringRoom(prev => ({
            ...prev,
            hrorder: value
        }))

        const selected_order = orders.find(order => order.key === value)

        if (selected_order !== undefined) {
            setHiringRoom(prev => ({
                ...prev,
                name: selected_order.text,
                target: selected_order.target,
                dateFrom: selected_order.date_from,
                dateTo: selected_order.date_to
            }))
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            {errorList.length > 0 &&
                (<Message negative>
                    {errorList.map((error, index) =>
                        <p key={index}> {error} </p>
                    )}
                </Message>)
            }

            <CanView permissions={["orders.c_view_order"]}>
                <SuperField as="choice"
                    search
                    label={t('order')}
                    help={t('help_hrorder')}
                    loading={isLoading}
                    disabled={isLoading}
                    customOptions={orders}
                    value={hiringRoom.hrorder}
                    onChange={(e, { value }) =>
                        autoCompleteData(value)
                    }
                />
                <Divider/>
            </CanView>

            <SuperField as="input"
                autoFocus
                required
                label={t('name')}
                placeholder={t('name')}
                value={hiringRoom.name}
                onChange={(e) => setHiringRoom({
                    ...hiringRoom,
                    name: e.target.value
                })}
            />

            <Form.Field>
                <SuperField as="choice"
                    required
                    search
                    endpoint={API.PROCESSES + "?query={id, name, process_type}"}
                    text="name"
                    label={
                        <div style={{ display: "inline-block", width: "100%", marginBottom: "0.3rem" }}>
                            <span style={{ fontWeight: "bold" }}> {t('process')} </span>
                            <Link to={routes.HIRING_PROCESSES} style={{ float: "right" }}>
                                <Icon name="cog" style={{ color: "var(--primary)" }} /> {t('manage_hiring_processes')}
                            </Link>
                        </div>
                    }
                    value={hiringRoom.process}
                    onChange={(e, { value }) => setHiringRoom({
                        ...hiringRoom,
                        process: value
                    })}
                />
            </Form.Field>
            <SuperField as="input"
                type="number"
                min="1"
                pattern="[0-9]*"
                label={t("target")}
                value={hiringRoom.target}
                onChange={(e, { value }) => setHiringRoom({
                    ...hiringRoom,
                    target: value
                })}
            />

            <Divider />
            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    dateFormat="YYYY-MM-DD"
                    name='date'
                    label={t('date_from')}
                    placeholder={hiringRoom.dateFrom}
                    value={hiringRoom.dateFrom}
                    iconPosition="left"
                    onChange={(e, value) => setHiringRoom({
                        ...hiringRoom,
                        dateFrom: value.value
                    })}

                />
                <SuperField as="datepicker"
                    dateFormat="YYYY-MM-DD"
                    name='date'
                    label={t('date_to')}
                    placeholder={hiringRoom.dateTo}
                    value={hiringRoom.dateTo}
                    error={invalidDateRange}
                    iconPosition="left"
                    onChange={(e, value) => setHiringRoom({
                        ...hiringRoom,
                        dateTo: value.value
                    })}
                />
            </Form.Group>

            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} disabled={isProcessing} />
                <ModalSubmit
                    disabled={isProcessing || isEmpty(hiringRoom.name) || hiringRoom.process === ""}
                    loading={isProcessing}
                    text={t("confirm")}
                />
            </Form.Field>

        </Form>
    );
};

export default LaunchHiringRoom;