import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import '@store/languages';
import moment from 'moment';
import { setLocaleLanguage } from '@helpers/dates';
// components
import Icon from '@components/Icon';
import { Container, Grid, Header, Modal, Popup, Segment, Table } from 'semantic-ui-react';
import GuestsForm from '../components/GuestsForm';
import OutlineAddButton from '@components/buttons/OutlineAddButton';
import { DateInput } from 'semantic-ui-calendar-react'

const Calendar = ({ stats, handleCellClick, selectedDay, stepBack, stepNext, date, setDate, isLoading, setIsLoading, accommodationID, accommodation, onAddGuest }) => {
    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [popupDate, setPopupDate] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    
    const toggleCalendar = () => {
        setIsOpen(!isOpen);
    };

    const handleDateChange = (event, { value }) => {
        setDate(moment(value, 'YYYY-MM'));
    };
  
    useEffect(() => {
        setIsLoading(false);
    }, [stats, setIsLoading]);
  
    const putColor = (occupied, vacant, date) => {
        if(date){
            if (selectedDay === date) {
                return 'var(--primary)';
            } else if (occupied === 0) {
                return 'white';
            } else if (vacant === 0) {
                return '#FF4C78';
            } else {
                return '#EEE722';
            }
        }
        else{
            return '#cccfcd'
        }
    };

    const getOccuppied = (occupied, vacant, date) => {
        if(date){
            if (occupied === 0) {
                return t('unoccupied');
            } else if (vacant === 0) {
                return t('fully_occupied');
            } else {
                return t('partially_occupied');
            }
        }
    };
  
    const getWeeks = () => {
        const weeks = [];
        const firstDayOfMonth = moment(stats[0].date).startOf('month');
        const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');
        const lastDayOfMonth = moment(stats[stats.length - 1].date).endOf('month');
        const lastDayOfWeek = lastDayOfMonth.clone().endOf('week');

    
        let weekStart = firstDayOfWeek;

        while (weekStart.isBefore(lastDayOfWeek) && weekStart.isBefore(lastDayOfMonth)) {
            const week = [];
            for (let i = 0; i < 7; i++) {
                if (weekStart.isBefore(firstDayOfMonth) || weekStart.isAfter(lastDayOfMonth)) {
                    week.push(null);
                } 
                else {
                    const day = stats.find((day) => moment(day.date).isSame(weekStart, 'day'));
                    week.push(day);
                }
                weekStart.add(1, 'day');
            }
            weeks.push(week);
        }
    
        return weeks;
    };
  
    const weeks = getWeeks();

    const buttonClick = (date) => {
        setIsModalOpen(true);
        setPopupDate(date);
    }

    const PopupCellInfo = ({day}) => (
        <>
        <Popup
            trigger={
                <Table.Cell
                    width={1}
                    onClick={() => handleCellClick(day)}
                    disabled={!day}
                    style={{
                        backgroundColor: putColor(day?.occupied_bed_count, day?.vacant_bed_count, day?.date),
                        height: '3rem',
                        border: 'solid 0.1px #eeeeee',
                        fontWeight: 'bold',
                        cursor: "pointer",
                        color: selectedDay === day?.date ? 'var(--light)' : 'var(--black)',
                    }}
                >
                    {day ? moment(day?.date).format('DD') : ''}
                </Table.Cell>
            } 
            flowing 
            position='top center'
            hoverable
        >
            <>
                <Header as='h4'>{getOccuppied(day?.occupied_bed_count, day?.vacant_bed_count, day?.date)}</Header>
                <p>
                    <b>{day?.occupied_bed_count}</b><span>{" " + t('occupied') + " / "}</span><b>{day?.vacant_bed_count}</b><span>{" " + t('vacant')}</span>
                </p>
                <OutlineAddButton text={t('add_guest')} size="tiny" onClick={() => buttonClick(day?.date)} />
            </>
        </Popup>
        </>
    )
  
    const TableRow = ({ days }) => {
        return (
            <Table.Row cells={7}>
                {days?.map((day) => ( <PopupCellInfo day={day} /> ))}
            </Table.Row>
        );
    };

    const InfoColorsRow = () => {
        return (
            <Grid>
                <Grid.Row textAlign='right' style={{ paddingTop: 0, paddingBottom: 0  }}>
                    <Icon name="square-outline" style={{ marginRight: '0.5rem', marginTop: "0.2rem" }} /><span>{t('unoccupied')}</span>
                    <Icon name="square" color={"#EEE722"} style={{ marginLeft: '1rem', marginRight: '0.5rem', marginTop: "0.2rem" }} /><span>{t('partially_occupied')}</span>
                    <Icon name="square" color={"#FF4C78"} style={{ marginLeft: '1rem', marginRight: '0.5rem', marginTop: "0.2rem" }} /><span>{t('fully_occupied')}</span>
                </Grid.Row>
            </Grid>
        );
    };
  
    return (
        <>
            <Container>
                <Grid>
                    <Grid.Row columns={4} style={{ paddingTop: 0 }}>
                        <Grid.Column width={11} style={{ paddingLeft: 0 }}>
                            <Header content={t('calendar')} />
                        </Grid.Column>
                        <Grid.Column width={1}>
                            <Icon
                                name="arrow-back-outline"
                                onClick={() => stepBack()}
                                style={{ marginRight: '2rem', cursor: 'pointer' }}
                            />
                        </Grid.Column>
                        <Grid.Column width={3} textAlign="center">
                            <Popup
                                on="click"
                                position="bottom center"
                                open={isOpen}
                                onClose={toggleCalendar}
                                trigger={<div><Header content={moment(stats[0].date).locale(setLocaleLanguage()).format('MMMM, YYYY')} onClick={toggleCalendar} style={{ cursor: "pointer" }} /></div>}
                                content={
                                    <DateInput
                                        inline
                                        startMode="month"
                                        onChange={handleDateChange}
                                        value={moment(date).format('YYYY-MM')}
                                        dateFormat="YYYY-MM"
                                        localization={setLocaleLanguage()}
                                    />
                                }
                            />
                        </Grid.Column>
                        <Grid.Column width={1}>
                            <Icon
                                name="arrow-forward-outline"
                                onClick={() => stepNext()}
                                style={{ marginRight: '2rem', cursor: 'pointer' }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
        
                <Segment loading={isLoading} style={{ padding: 0, margin: 0, backgroundColor: "transparent", minHeight: "auto", border: "none", boxShadow: "none" }}>
                    <Table columns={7} stackable textAlign="center">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{moment().day(1).locale(setLocaleLanguage()).format('dd')}</Table.HeaderCell>
                                <Table.HeaderCell>{moment().day(2).locale(setLocaleLanguage()).format('dd')}</Table.HeaderCell>
                                <Table.HeaderCell>{moment().day(3).locale(setLocaleLanguage()).format('dd')}</Table.HeaderCell>
                                <Table.HeaderCell>{moment().day(4).locale(setLocaleLanguage()).format('dd')}</Table.HeaderCell>
                                <Table.HeaderCell>{moment().day(5).locale(setLocaleLanguage()).format('dd')}</Table.HeaderCell>
                                <Table.HeaderCell>{moment().day(6).locale(setLocaleLanguage()).format('dd')}</Table.HeaderCell>
                                <Table.HeaderCell>{moment().day(0).locale(setLocaleLanguage()).format('dd')}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {weeks.map((week, index) => (
                                <TableRow key={index} days={week} />
                            ))}
                        </Table.Body>
                    </Table>
                    {isModalOpen &&
                        <Modal
                            onClose={() => setIsModalOpen(false)}
                            onOpen={() => setIsModalOpen(true)}
                            open={isModalOpen}
                            size='small'
                        >
                            <Modal.Header>{t('add_guest')}</Modal.Header>
                            <Modal.Content>
                                <GuestsForm
                                    accommodationID={accommodationID}
                                    accommodation={accommodation}
                                    selectedDay={popupDate}
                                    currency={accommodation?.data?.currency}
                                    handleRequest={(response) => onAddGuest(response)}
                                    onClose={() => setIsModalOpen(false)}
                                />
                            </Modal.Content>
                        </Modal>
                    }
                    <InfoColorsRow />
                </Segment>
            </Container>
        </>
    );
  };
  
export default Calendar;
  