import React, { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react'
import { useTranslation } from "react-i18next"

function ConfrimModal(props) {
    const [open, setOpen] = useState(false)
    const { t } = useTranslation();

    const onConfirm = () => {
        props.onConfirm();
        setOpen(false);
    }

    const onRefuse = () => {
        if (props.onRefuse) {
            props.onRefuse()
        }
        setOpen(false)
    }

    return (

        <Modal
            style={{ border: "2px solid var(--danger)" }}
            size="mini"
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={props.button}
        >

            <Modal.Description style={{ textAlign: "center", borderColor: "white", padding: "2rem", fontSize: "1.5rem", lineHeight: "2rem" }}>
                {props.description}
            </Modal.Description>

            <Modal.Actions>
                <Button.Group fluid>
                    <Button
                        onClick={onConfirm}
                        content={<strong>{t('yes')}</strong>}
                        style={{ fontWeight: "normal", padding: "1rem", marginRight: "0.3rem", backgroundColor: 'var(--danger)', color: "var(--white)" }}
                    />
                    <Button
                        style={{ fontWeight: "normal", marginLeft: "0.3rem", color: "var(--black)", backgroundColor: 'var(--variant5)' }}
                        content={<strong>{t('no')}</strong>}
                        onClick={onRefuse}
                    />
                </Button.Group>
            </Modal.Actions>
        </Modal>



    );
}
export default ConfrimModal;
