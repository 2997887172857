import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { toast } from 'react-semantic-toasts';
import { useSelector } from 'react-redux';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { useIsMount } from '@helpers/hooks';
// components
import Icon from '@components/Icon';
import DatePresets from '@components/DatePresets';
import EmptyRow from '@components/tables/EmptyRow';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import TariffForm from '@components/forms/TariffForm';
import { Form, Header, Divider, Segment, Icon as SemanticIcon, Grid, Table, Button, Checkbox, Popup, Loader } from 'semantic-ui-react';

const InvalidCostCenterCategoryForm = ({ worker, activity, setWorkers }) => {
    const { t } = useTranslation()
    const [processing, setProcessing] = useState(false)
    const [category, setCategory] = useState("")

    const handleChange = async () => {
        setProcessing(true)
        const request = await requests.patch(API.ORDERS + "tariffs/" + activity.tariff.id + "/", { 
            cost_center_category: category
        })

        if (request.status === 200) {
            // update worker activities
            setWorkers(prev => prev.filter(item => {
                if (item.id === worker.id) {
                    item.activities = item.activities.filter(act => {
                        if (act.id === activity.id) {
                            act.tariff = request.response
                        }

                        return act
                    })
                }

                return item
            }))

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('cost_center_category_changed'),
                animation: 'pulse',
                time: 2000,
            })
        }
        setProcessing(false)
    }
    return (
        <Popup
            wide
            hoverable
            position="left center"
            trigger={
                <span>
                    { processing 
                        ? 
                        <Loader size="mini" className='dark-loader' active inline style={{ marginRight: "0.5rem" }}/>
                        : 
                        <SemanticIcon  name="warning circle" style={{ color: "var(--danger)", marginRight: "0.5rem" }}/>
                    }
                </span>
            }
            content={
                <div>
                    <p>{ `${t('tariff_is_not_properly_configured_to_fix_it_add_category')}` }</p>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <SuperField
                            search
                            as="choice"
                            label={t('cost_center_record_category')}
                            help={t('cost_center_record_category_hint')}
                            endpoint={API.COST_CENTERS_RECORD_CATEGORIES + "?type=2"}
                            text="title"
                            value={category}
                            onChange={(e, { value }) => setCategory(value)}
                        />
                        <Button 
                            fluid
                            primary
                            type="button"
                            disabled={processing}
                            content={t('confirm')}
                            onClick={() => handleChange()}
                        />
                    </Form>
                </div>
            }
        />
    )
}

const CostCenterForm = ({ costCenter, costCenters, setCostCenters, order, setOrder }) => {
    const { t } = useTranslation()
    const [cc, setCC] = useState(costCenter?.id || "")
    const [processing, setProcessing] = useState(false)
    let isMount = useIsMount()

    const handleChange = async (value) => {
        setProcessing(true)
        // call API to change Cost Center for Order
        const request = await requests.patch(API.ORDERS + order.id + "/", {
            resourcetype: "ServiceOrder",
            cost_center: value !== "" ? value : null
        })
        
        // update order state by using setOrder for new Cost Center
        if (request.status === 200) {
            setOrder(prev => ({
                ...prev,
                cost_center: request.response.cost_center
            }))
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('cost_center_changed'),
                animation: 'pulse',
                time: 2000,
            })
        } else {
            toast({
                type: 'error',
                icon: 'warning',
                title: t('unable_to_change_cost_center'),
                animation: 'pulse',
                time: 5000,
            })
        }
        setProcessing(false)
    } 

    const handleAddCostCenter = async (value) => {
        const request = await requests.post(API.COST_CENTERS, {
            title: value,
            unit: order?.owner?.id || null
        })

        if (request.status === 201) {
            setCC(request.response.id)
            setCostCenters(prev => [...prev, request.response])
        } else {
            toast({
                type: 'error',
                icon: 'warning',
                title: t('unable_to_create_cost_center'),
                animation: 'pulse',
                time: 5000,
            })
        }
    }

    useEffect(() => {
        async function handleCostCenterChange() {
            if (cc !== order.cost_center?.id && !isNaN(cc)) {
                await handleChange(cc)
            }
        }

        if (!isMount){
            handleCostCenterChange()
        }
        // eslint-disable-next-line
    }, [cc])


    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            <SuperField as="choice"
                value={cc}
                search
                allowAdditions
                disabled={processing}
                loading={processing}
                onAddItem={(e, { value }) => handleAddCostCenter(value)}
                onChange={(e, { value }) => setCC(value)}
                customOptions={costCenters.map(item => ({ key: item.id, text: item.title, value: item.id }))}
            />
        </Form> 
    )
}

const ActivityList = ({ dates, setSize, costCenter, costCenters, setCostCenters, order, setOrder, timesheet, loading, onCancel, onClose }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector(state => state.date_format)

    // eslint-disable-next-line
    const [workers, setWorkers] = useState([])
    const [processing, setProcessing] = useState(false)

    const handleFinalConfirmation = async () => {
        setProcessing(true)
        setSize("tiny")

        const fulfillments = [] // upon create add new fulfillment to this array
        const dataset = []
        for (let i = 0; i < workers.length; i++) {
            const data = {
                order: order.id,
                resourcetype: "ServiceFulfillment",
                date_from: dates.from,
                date_to: dates.to,
                profile: workers[i].profile_id,
                timesheet_ids: workers[i].activities.map(item => item.id)
            }

            dataset.push(data)

            // create fulfillments
            const request = await requests.post(API.ORDERS + "fulfillments/", data)
            if ( request.status === 201 ) fulfillments.push(request.response)
        }

        if (costCenter?.id !== undefined) { // create cost center records if available
            let records = [] 
            for (let i = 0; i < workers.length; i++) {
                // create list of categories to create based on activities and worker as record owner
                let categories = []
                const activities = workers[i].activities
                for (let x = 0; x < activities.length; x++) { // find all groups and create a list
                    categories.push(activities[x].tariff.cost_center_category.id)
                }
    
                categories = [...new Set(categories)] // remove duplicated from the list and override original array
    
                // loop over all categories and calculate totals
                for (let y = 0; y < categories.length; y++) {
                    let activitiesForCategory = activities.filter(item => item.tariff.cost_center_category.id === categories[y])
                    if (activitiesForCategory.length > 0) {
                        let sum = activitiesForCategory.reduce((total, item) => total + parseFloat(item.total_fee), 0)
                        sum = sum.toFixed(2)
    
                        // create record for this category
                        const record = {
                            date: moment().format("YYYY-MM-DD"),
                            category: categories[y], 
                            cost_center: costCenter.id,
                            owner: workers[i].profile_id,
                            amount: {
                                sum: sum,
                                currency: "EUR" // !! to be changed
                            },
                            fulfillment: fulfillments.find(item => item.profile.id === workers[i].profile_id)?.id || null
                        }   
                        
                        records.push(record)
                        // create 
                        await requests.post(API.COST_CENTERS_RECORDS, record)
                    }
                }
            }
        }

        if (fulfillments.length === dataset.length) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('fulfillments_created'),
                animation: 'pulse',
                time: 2000,
            })
            setSize("mini")
            onClose()
        } else {
            setSize("large")
        }

        setProcessing(false)
    }

    useEffect(() => {
        if (!loading) {
            setWorkers(timesheet?.workers || [])
        }
        // eslint-disable-next-line
    }, [loading])

    const sumHours = (activities) => {
        let total = 0
        for (let i = 0; i < activities.length; i++) {
            total += parseFloat(activities[i].hours)
        }
        return total.toFixed(2)
    }

    const sumFee = (activities) => {
        let total = 0
        for (let i = 0; i < activities.length; i++) {
            if (activities[i].total_fee !== null && activities[i].total_fee !== ""){
                    total += parseFloat(activities[i].total_fee)
            }
        }
        return total.toFixed(2)
    }

    // eslint-disable-next-line
    const sumTariff = (activities) => {
        let total = 0
        for (let i = 0; i < activities.length; i++) {
            if (activities[i]?.tariff) {
                total += parseFloat(activities[i]?.tariff?.amount) || 0
            }
        }
        return total.toFixed(2)
    }

    const sumTotalFee = (workers) => {
        let total = 0

        for (let i = 0; i < workers.length; i++) {
            total += parseFloat(sumFee(workers[i].activities))
        }

        return total.toFixed(2)
    }

    const isDisabled = () => {
        return workers.length === 0
    }

    const isValid = () => {
        let valid = true

        for (let i = 0; i < workers.length; i++) {
            for (let x = 0; x < workers[i].activities.length; x++) {
                const activityTariff = workers[i].activities[x].tariff
                if (activityTariff === null) return false
                if (costCenter?.id !== undefined && activityTariff.cost_center_category === null) return false
            }
        }

        return valid
    }

    const WorkerActivityList = ({ costCenter, worker, setWorkers, index }) => {
        const { t } = useTranslation()
        const [isReviewed, setIsReviewed] = useState(false)

        const areActivitiesValid = () => {
            let valid = true
    
            for (let x = 0; x < worker.activities.length; x++) {
                const activityTariff = worker.activities[x].tariff
                if (activityTariff === null) return false
                if (costCenter?.id !== undefined && activityTariff.cost_center_category === null) return false
            }
    
            return valid
        }

        const isValidForCostCenter = (tariff) => {
            if (costCenter?.id === undefined) return true
            if (tariff === undefined || tariff === null) return false
            return tariff.cost_center_category !== null ? true : false
        }

        const areValid = areActivitiesValid()
        
        return (
            <>
                <Grid>
                    <Grid.Row columns="2" verticalAlign='bottom' style={{ padding: 0 }}>
                        <Grid.Column style={{ padding: 0 }}>
                            <Header as="h4" style={{ marginTop: "0rem" }}>
                                {worker.fullname}: <small style={{ fontWeight: "normal" }}>{ `${t('fulfillment')} #${index + 1}` }</small>
                            </Header>
                        </Grid.Column>
                        <Grid.Column style={{ textAlign: "right", padding: 0 }}>
                            <div style={{ display: "flex", justifyContent: "right" }}>
                                { !areValid && 
                                    <div style={{ color: "var(--danger)", marginRight: "1rem" }}>
                                        <SemanticIcon name="warning circle" style={{ marginRight: "0.5rem" }}/>
                                        <span>{t('list_containing_errors')}</span>
                                    </div>
                                }
                                <div>
                                    <Checkbox 
                                        disabled={!areValid}
                                        checked={isReviewed}
                                        label={t("mark_as_reviewed")}
                                        onClick={() => areValid ? setIsReviewed(!isReviewed) : null}
                                    />
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                
                <Table key={index}>
                    <Table.Body>
                        <Table.Row verticalAlign="middle" style={{ background: "var(--light)" }}>
                            <Table.Cell width="1" style={{ fontWeight: "bold" }} content={"#"}/>
                            <Table.Cell style={{ fontWeight: "bold" }} content={t("activity")}/>
                            <Table.Cell style={{ fontWeight: "bold" }} content={t("date")}/>
                            <Table.Cell style={{ fontWeight: "bold" }} content={t("hours")}/>
                            <Table.Cell style={{ fontWeight: "bold" }} content={t("tariff")}/>
                            <Table.Cell style={{ fontWeight: "bold" }} content={t("total")}/>
                        </Table.Row>
                        { !isReviewed && 
                            <>
                                { worker.activities.map((activity, idx) => (
                                    <Table.Row key={idx}>
                                        <Table.Cell>{idx + 1}.</Table.Cell>
                                        <Table.Cell content={activity?.category?.title || "--"}/>
                                        <Table.Cell content={moment(activity?.date).format(dateFormat)}/>
                                        <Table.Cell content={activity?.hours || "--"}/>
                                        <Table.Cell>
                                            { (!isValidForCostCenter(activity.tariff) && activity.tariff !== null) &&
                                                <InvalidCostCenterCategoryForm setWorkers={setWorkers} worker={worker} activity={activity}/>
                                            }
                                            <span>
                                                { activity.tariff !== null 
                                                    ? 
                                                        <Popup
                                                            position="left center"
                                                            trigger={
                                                                <span>
                                                                    {activity?.tariff?.amount} {activity?.tariff?.currency}
                                                                </span>
                                                            }
                                                            content={`${activity?.tariff.type_display}`}
                                                        />
                                                    : 
                                                    <Popup
                                                        position="left center"
                                                        trigger={
                                                            <SemanticIcon name="warning circle" style={{ color: "var(--danger)" }}/>
                                                        }
                                                        content={`${t('tariff_is_not_assigned')}!`}
                                                    />
                                                }
                                            </span>
                                            <SuperDuperModal
                                                size="tiny"
                                                content={
                                                    <TariffForm
                                                        costCenter={costCenter}
                                                        record={activity}
                                                        setWorkers={setWorkers}
                                                        profile={worker.profile_id}
                                                        activity={activity.category.id}
                                                        currentTariff={activity.tariff}
                                                    />
                                                }
                                                trigger={
                                                    <Icon className="action-hover" name="pencil-outline" style={{ color: "var(--dark)", marginLeft: "0.5rem" }}/>
                                                }
                                            />
                                            
                                        </Table.Cell>
                                        <Table.Cell style={{ fontWeight: "bold" }} content={activity.total_fee}/>
                                    </Table.Row>
                                ))}
                            </>
                        }
                        <Table.Row style={{ fontWeight: "bold", background: "var(--light)" }}>
                            <Table.Cell width="1" content={t('total')}/>
                            <Table.Cell content={worker.activities.length}/>
                            <Table.Cell content={""}/>
                            <Table.Cell content={sumHours(worker.activities)}/>
                            <Table.Cell content={""}/>
                            <Table.Cell content={sumFee(worker.activities)}/>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <Divider/>
            </>
        )
    }

    const isEmptyList = () => {
        let totalActivities = 0
        for (let i = 0; i < workers.length; i++) {
            totalActivities += workers[i].activities.length
        }

        return totalActivities
    }

    const totalActivities = isEmptyList()

    return (
        <Segment loading={loading || processing} style={{ padding: 0, marginTop: 0, border: "none", boxShadow: "none" }}>
            { (loading || processing) && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "8rem" }}> { processing ? t('processing_request') : t('loading_timesheet_activities') } </p>}
            { (!loading && !processing) && 
                <>
                    <Grid stackable>
                        <Grid.Row columns="3" verticalAlign="top" style={{ padding: 0 }}>
                            <Grid.Column>
                                <strong>{t('period')}:</strong><br/>
                                <span style={{ fontSize: "1.4rem" }}>{`${moment(timesheet.date_from).format(dateFormat)} - ${moment(timesheet.date_to).format(dateFormat)}`}</span>
                            </Grid.Column>    
                            {/* <Grid.Column>
                                {t('order')}: <strong>{`${timesheet.order.name}`}</strong>   
                            </Grid.Column>     */}
                            <Grid.Column>
                                <strong>{t('total_fee')}:</strong><br/>
                                <span style={{ fontSize: "1.4rem" }}>{sumTotalFee(workers)}  </span>
                            </Grid.Column>    
                            <Grid.Column style={{ display: "flex" }}>
                                <div>
                                    <strong>{t('cost_center')}:</strong>
                                </div>
                                <CostCenterForm
                                    order={order}
                                    setOrder={setOrder}
                                    costCenter={costCenter}
                                    costCenters={costCenters}
                                    setCostCenters={setCostCenters}
                                />
                            </Grid.Column>    
                        </Grid.Row>    
                    </Grid>
                    <Divider/>  
                    <EmptyRow length={totalActivities}/>
                    {   totalActivities > 0 && (
                            workers.map((worker, index) => (
                                <WorkerActivityList worker={worker} index={index} key={index} costCenter={costCenter} setWorkers={setWorkers}/>
                            ))
                        )
                    }
                    <Form.Field style={{ textAlign: "right" }}>
                        <Button type="button" simple 
                            content={t('cancel')}
                            disabled={processing}
                            onClick={() => onCancel()}
                        />
                                
                        <Button type="button" primary 
                            loading={processing}
                            content={t('confirm_and_close')} 
                            onClick={() => handleFinalConfirmation()}
                            disabled={isDisabled() || !isValid() || processing || totalActivities === 0} 
                        />
                    </Form.Field>
                </>
            }
        </Segment>
    )
}

const ServiceOrderFulfillment = ({ record, onClose, setSize }) => {
    const { t } = useTranslation()
    // eslint-disable-next-line
    const dateFormat = useSelector(state => state.date_format)

    const firstDay = moment().startOf('month').format("YYYY-MM-DD")
    const lastDay = moment().endOf('month').format("YYYY-MM-DD")

    const initialValues = {
        from: firstDay,
        to: lastDay,
    }

    const [view, setView] = useState(1)
    const [order, setOrder] = useState(record)
    const [processing, setProcessing] = useState(false)
    const [timesheet, setTimesheet] = useState({})
    const [form, setForm] = useState(initialValues)
    const [costCenters, setCostCenters] = useState([])

    const handleSubmit = async () => {
        setProcessing(true)
        setView(2)

        const request = await requests.get(API.ORDERS + order.id + "/service_workers/?date_from=" + form.from + "&date_to=" + form.to + "&exclude_records_with_fulfillments=true")
        if (request.status === 200) {
            setTimesheet(request.response)
            setSize("large")
        } else {
            setView(1)
            setSize("tiny")
        }

        setProcessing(false)
    }

    useEffect(() => {
        async function fetchCostCenters() {
            const request = await requests.get(API.COST_CENTERS + "?query={id, title}")
            if (request.status === 200) setCostCenters(request.response)
        }

        fetchCostCenters()
    }, [])

    return (
        <>
            { view === 1 // initial form
                ? 
                    <Form onSubmit={handleSubmit}>
                        <Header as="h3" content={t('generate_timesheet_overview')}/>
                        <Divider/>
                        <Form.Group widths="equal">
                            <SuperField as="datepicker"
                                required
                                label={t('date_from')}
                                value={form.from}
                                onChange={(e, { value }) => setForm({ ...form, from: value })}
                            />
                            <SuperField as="datepicker"
                                required
                                label={t('date_to')}
                                value={form.to}
                                onChange={(e, { value }) => setForm({ ...form, to: value })}
                            />
                        </Form.Group>
                        <DatePresets
                            open={true}
                            from={form.from}
                            to={form.to}
                            onSelection={ (firstMonthDay, lastMonthDay) => {
                                setForm({ 
                                    ...form, 
                                    from: firstMonthDay,
                                    to: lastMonthDay,
                                })
                            }}
                        />
                        <Divider/>
                        <Form.Field style={{ textAlign: 'right' }}>
                            <ModalCancel onClose={onClose}/>
                            <ModalSubmit
                                text={t('confirm')}
                                loading={processing}
                                disabled={processing || form.profile === "" || form.tariff === "" || form.from === "" || form.to === ""}
                            />
                        </Form.Field>
                    </Form>
                :
                <ActivityList 
                    setSize={setSize}
                    onClose={onClose}
                    timesheet={timesheet}
                    loading={processing}
                    costCenter={order.cost_center}
                    order={order}
                    dates={form}
                    setOrder={setOrder}
                    costCenters={costCenters}
                    setCostCenters={setCostCenters}
                    onCancel={() => { 
                        setView(1)
                        setSize("tiny")
                    }}
                />
            }
        </>
    );
};

export default ServiceOrderFulfillment;