import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import moment from 'moment'
// store
import { routes } from '@routes'
import { API } from '@store/config'
// import { tzDateTime } from '@helpers/dates'
import { renderAddress } from '@helpers/functions'
import { useHasPermissions } from '@helpers/hooks'
// components
import Icon from '@components/Icon'
import AvatarList from '@components/AvatarList'
import CanView from '@components/perms/CanView'
import LabelsList from '@components/lists/LabelsList'
import BreadcrumbNav from '@components/BreadcrumbNav'
import { Label, Popup, Progress, Icon as SemanticIcon } from 'semantic-ui-react'
import Attachments from '@components/Attachments'
import GenerateDocuments from '@components/GenerateDocuments'
import ListView from '@components/ListView'
import VisaAndResidenceForm from './components/VisaAndResidenceForm'
import VisaAndResidencessFilters from './components/VisaAndResidencessFilters'
import QuickNotes from '../../sm/leads/components/QuickNotes'
import LabelsBulk from './components/LabelsBulk'
import AccommodationBulk from './components/AccommodationBulk'
import SubmissionBulk from './components/SubmissionBulk'
import PromiseBulk from './components/PromiseBulk'
import WorkplaceBulk from './components/WorkplaceBulk'
import RegistersBulk from './components/RegistersBulk'
import StatusBulk from './components/StatusBulk'
import TaskTemplateConfigurator from './components/TaskTemplateConfigurator'
import TaskList from './components/TaskList'

const VisasAndResidences = () => {
    const { t } = useTranslation()
    const canManage = useHasPermissions(['foreigners.c_manage_all_foreigners_processes'])
    const [attachmentsOpen, setAttachmentsOpen] = useState(0)
    const dateFormat = useSelector((state) => state.date_format)
    const countries = useSelector((state) => state.choices.countries)

    const [forceModalOpen, setForceModalOpen] = useState(0)

    const calculateCompleteTasks = (tasks) => {
        let total = tasks.length
        let completed = tasks.filter((item) => item.is_closed === true).length
        let percentage = 0

        if (total > 0) {
            percentage = (completed / total) * 100
        }

        percentage = Math.round(percentage)

        return percentage
    }

    const calculateSuspendedDaysLeft = (item) => {
        if (item.process_status === 4) {
            let today = moment()
            let suspendedUntil = item.suspension_of_proceedings_to ? moment(item.suspension_of_proceedings_to) : null

            if (suspendedUntil && suspendedUntil.isValid()) {
                // Calculate the number of days until the suspension ends
                let daysLeft = suspendedUntil.diff(today, 'days')

                // You may want to ensure that the number of days left is non-negative
                // If the suspension end date has passed, it should return 0
                daysLeft = daysLeft < 0 ? 0 : daysLeft

                return daysLeft
            } else {
                // Handle the case where the suspension end date is not valid
                return null
            }
        } else {
            // If the process status is not 4, return null or some default value
            return null
        }
    }

    return (
        <CanView redirect permissions={['foreigners.c_view_all_foreigners_processes']}>
            <BreadcrumbNav items={[{ name: t('visas_and_stays'), icon: '', href: '' }]} mBottom="0rem" />

            <ListView
                as="table"
                allowSearch
                allowSelection
                allowRowExport
                //showStatistic
                actionsCellWidth="2"
                endpoint={API.FOREIGNERS + 'processes/'}
                bulkActions={(selected, setData, setTotal, filters, queryParams, setSelected) => [
                    {
                        as: 'modal',
                        name: t('generate_documents'),
                        icon: 'document-text-outline',
                        modal: <GenerateDocuments source="foreigners.ForeignerProcess" selected={selected} />,
                    },
                    {
                        as: 'modal',
                        name: t('update_process_status'),
                        icon: 'code-working-outline',
                        modalSize: 'tiny',
                        modal: (
                            <StatusBulk
                                selected={selected}
                                setSelected={setSelected}
                                setData={setData}
                                filters={filters}
                            />
                        ),
                        permissions: ['foreigners.c_manage_all_foreigners_processes'],
                    },
                    {
                        as: 'modal',
                        name: t('add_labels'),
                        icon: 'pricetag-outline',
                        modal: <LabelsBulk selected={selected} setSelected={setSelected} setData={setData} />,
                        permissions: ['foreigners.c_manage_all_foreigners_processes'],
                    },
                    {
                        as: 'modal',
                        name: t('add_accommodation'),
                        icon: 'home-outline',
                        modal: <AccommodationBulk selected={selected} setSelected={setSelected} setData={setData} />,
                        permissions: ['foreigners.c_manage_all_foreigners_processes'],
                    },
                    {
                        as: 'modal',
                        name: t('add_submission'),
                        icon: 'business-outline',
                        modal: <SubmissionBulk selected={selected} setSelected={setSelected} setData={setData} />,
                        permissions: ['foreigners.c_manage_all_foreigners_processes'],
                    },
                    {
                        as: 'modal',
                        name: t('add_employment_details'),
                        modalSize: 'small',
                        icon: 'briefcase-outline',
                        modal: <PromiseBulk selected={selected} setSelected={setSelected} setData={setData} />,
                        permissions: ['foreigners.c_manage_all_foreigners_processes'],
                    },
                    {
                        as: 'modal',
                        name: t('add_workplace'),
                        modalSize: 'small',
                        icon: 'briefcase-outline',
                        modal: <WorkplaceBulk selected={selected} setSelected={setSelected} setData={setData} />,
                        permissions: ['foreigners.c_manage_all_foreigners_processes'],
                    },
                    {
                        as: 'modal',
                        name: t('add_register'),
                        icon: 'document-outline',
                        modalSize: 'small',
                        modal: <RegistersBulk selected={selected} setSelected={setSelected} setData={setData} />,
                        permissions: ['foreigners.c_manage_all_foreigners_processes'],
                    },
                ]}
                exports={[
                    {
                        endpoint: API.EXPORTS + 'dynamic_foreigner_process/',
                        permissions: ['foreigners.c_view_all_foreigners_processes'],
                        apply_filters: true,
                        type: 'xlsx',
                        filename: 'foreigner_processes',
                    },
                ]}
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        name: t('add_foreigner_process'),
                        modal: <VisaAndResidenceForm />,
                        permissions: ['foreigners.c_manage_all_foreigners_processes'],
                    },
                    {
                        name: t('tasks'),
                        type: 'custom',
                        as: 'modal',
                        icon: 'list-outline',
                        permissions: [
                            'foreigners.c_manage_all_foreigners_processes',
                            'tasks.c_manage_tasks',
                            'tasks.c_manage_user_tasks',
                        ],
                        modalSize: 'tiny',
                        forceModalOpen: forceModalOpen,
                        setForceModalOpen: setForceModalOpen,
                        modal: (item, setData) => <TaskList record={item} tasks={item.tasks} setData={setData} />,
                    },
                    {
                        name: t('edit'),
                        type: 'edit',
                        as: 'modal',
                        icon: 'pencil-outline',
                        customIconColor: 'var(--dark)',
                        modal: (record, setData, setTotal) => (
                            <VisaAndResidenceForm singleItem={record} setData={setData} setTotal={setTotal} />
                        ),
                        permissions: ['foreigners.c_manage_all_foreigners_processes'],
                    },
                    {
                        name: t('delete'),
                        type: 'delete',
                        as: 'delete',
                        text: t('are_you_sure_that_you_want_to_delete_this_process'),
                        permissions: ['foreigners.c_manage_all_foreigners_processes'],
                    },
                ]}
                tableHeaders={[
                    { title: t('fullname') + '/' + t('citizenship'), orderBy: 'fullname' },
                    { title: t('purpose_of_stay') + '/' + t('type_of_residence') },
                    { title: t('accommodation') },
                    { title: t('employment_details') },
                    { title: t('registers') },
                    { title: t('labels') },
                    { title: t('tasks') },
                    { title: t('notes') },
                    { title: t('attachments') },
                    { title: t('responsible') },
                    { title: t('state') },
                    { title: t('additional_information') },
                ]}
                initialFilters={{
                    accommodation: '',
                    date_of_submission: '',
                    hiring_room: '',
                    is_approved: '',
                    profile: '',
                    purpose_of_stay: '',
                    register_of_penalties: '',
                    short_position: '',
                    significant_investor: '',
                    type_of_residence: '',
                    valid_from_after: '',
                    valid_to_before: '',
                    citizenship: '',
                    have_valid_pass: '',
                    labels: [],
                    ordering: ['-created_on'],
                    process_status: 1,
                }}
                listAdditionActions={(data, setData, total, setTotal, response) => [
                    {
                        as: 'filter',
                        index: 0,
                        name: t('open'),
                        filters: {
                            process_status: 1,
                            process_status__all: '',
                        },
                    },
                    {
                        as: 'filter',
                        index: 1,
                        name: t('submitted'),
                        filters: {
                            process_status: 2,
                            process_status__all: '',
                        },
                    },
                    {
                        as: 'filter',
                        index: 2,
                        name: t('granted'),
                        filters: {
                            process_status: 3,
                            process_status__all: '',
                        },
                    },
                    {
                        as: 'filter',
                        index: 3,
                        name: t('suspended'),
                        filters: {
                            process_status: 4,
                            process_status__all: '',
                        },
                    },
                    {
                        as: 'filter',
                        index: 4,
                        name: t('denied'),
                        filters: {
                            process_status: 5,
                            process_status__all: '',
                        },
                    },
                    {
                        as: 'filter',
                        index: 5,
                        name: t('all'),
                        filters: {
                            process_status: '',
                            process_status__all: null,
                        },
                    },
                ]}
                renderFilterFields={(filters, setFilters) => (
                    <VisaAndResidencessFilters filters={filters} setFilters={setFilters} />
                )}
                settings={[
                    {
                        permissions: ['foreigners.c_manage_all_foreigners_processes'],
                        as: 'link',
                        // target: "_blank",
                        linkTo: routes.SETTINGS + 'types/purposes-of-stay',
                        name: t('purpose_of_stay_management'),
                        icon: 'briefcase-outline',
                    },
                    {
                        permissions: ['foreigners.c_manage_all_foreigners_processes'],
                        as: 'link',
                        // target: "_blank",
                        linkTo: routes.SETTINGS + 'types/types-of-residence',
                        name: t('type_of_residence_management'),
                        icon: 'home-outline',
                    },
                    {
                        permissions: ['foreigners.c_manage_all_foreigners_processes'],
                        as: 'link',
                        // target: "_blank",
                        linkTo: routes.SETTINGS + 'submission-offices',
                        name: t('submission_offices_management'),
                        icon: 'business-outline',
                    },
                    {
                        permissions: ['foreigners.c_manage_all_foreigners_processes'],
                        as: 'modal',
                        // target: "_blank",
                        modal: <TaskTemplateConfigurator />,
                        name: t('task_template_configutor'),
                        icon: 'settings-outline',
                    },
                ]}
                renderCells={(item, setData) => [
                    {
                        content: (
                            <>
                                <Link
                                    target="_blank"
                                    to={
                                        item.profile?.profile_type === 1
                                            ? routes.CANDIDATES_CARD + item.profile.id
                                            : routes.EMPLYOEES_DETAIL + item.profile.id
                                    }
                                >
                                    <strong style={{ fontSize: '1rem' }}>{item?.profile?.fullname_with_titles}</strong>{' '}
                                </Link>
                                <br />
                                {item?.profile?.citizenship_display ? (
                                    <strong>{item?.profile?.citizenship_display}</strong>
                                ) : (
                                    <span style={{ opacity: '0.8' }}>{t('not_specified').toLocaleLowerCase()}</span>
                                )}
                            </>
                        ),
                    },
                    {
                        content: (
                            <>
                                <strong>{item?.purpose_of_stay?.title}</strong>
                                <br />
                                {item?.type_of_residence?.title}
                            </>
                        ),
                    },
                    {
                        content: (
                            <div>
                                <b>
                                    {item?.accommodation_valid_from
                                        ? moment(item?.accommodation_valid_from).format(dateFormat)
                                        : '--'}
                                </b>
                                <span> / </span>
                                <b>
                                    {item?.accommodation_valid_to
                                        ? moment(item?.accommodation_valid_to).format(dateFormat)
                                        : '--'}
                                </b>
                                <br />
                                {item?.accommodation?.address?.street
                                    ? renderAddress(item?.accommodation?.address, true)
                                    : item?.accommodation?.name || ''}
                            </div>
                        ),
                    },
                    {
                        content: (
                            <>
                                {item?.employer && (
                                    <div>
                                        {t('employer')}: <strong>{item?.employer?.name}</strong>
                                    </div>
                                )}
                                {item?.user_employer && (
                                    <div>
                                        {t('user_employer_short')}: <strong>{item?.user_employer?.name}</strong>
                                    </div>
                                )}
                                {item?.position && (
                                    <div>
                                        {t('position')}: <strong>{item?.position?.title}</strong>
                                        {item?.id_vpm && ' (' + item?.id_vpm + ')'}
                                    </div>
                                )}
                            </>
                        ),
                    },
                    {
                        content: (
                            <>
                                {item?.register_of_penalties && (
                                    <div>
                                        <b>
                                            {item.register_of_penalties_valid_from
                                                ? moment(item.register_of_penalties_valid_from).format(dateFormat)
                                                : '--'}
                                        </b>
                                        <span> / </span>
                                        <b>
                                            {item?.register_of_penalties_valid_to
                                                ? moment(item?.register_of_penalties_valid_to).format(dateFormat)
                                                : '--'}
                                        </b>
                                        <br />
                                        {countries?.['SK']}
                                    </div>
                                )}
                                {item?.other_country_register?.length > 0 &&
                                    item?.other_country_register?.map((register, idx) => (
                                        <div>
                                            <b>
                                                {register.valid_from
                                                    ? moment(register.valid_from).format(dateFormat)
                                                    : '--'}
                                            </b>
                                            <span> / </span>
                                            <b>
                                                {register?.valid_to
                                                    ? moment(register?.valid_to).format(dateFormat)
                                                    : '--'}
                                            </b>
                                            <br />
                                            {countries?.[register.country]}
                                        </div>
                                    ))}
                            </>
                        ),
                    },
                    {
                        content: (
                            <LabelsList
                                key={item.id}
                                rowID={item.id}
                                labels={item.labels}
                                endpoint={API.FOREIGNERS + 'processes/'}
                                endpointLabels={API.FOREIGNERS_LABELS}
                                label_key="labels"
                                managePerms={['foreigners.c_manage_all_foreigners_processes']}
                            />
                        ),
                    },
                    {
                        content: (
                            <div style={{ width: '100px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <strong>
                                        {item.tasks.filter((task) => task.is_closed === true).length} /{' '}
                                        {item.tasks.length}
                                    </strong>
                                    <strong>({calculateCompleteTasks(item.tasks)}%)</strong>
                                </div>
                                <div style={{ marginTop: '0.25rem' }}>
                                    <Progress percent={calculateCompleteTasks(item.tasks)} indicating />
                                </div>
                            </div>
                        ),
                    },
                    {
                        content: (
                            <QuickNotes
                                lead={item}
                                setData={setData}
                                endpointData={API.FOREIGNERS + 'processes/'}
                                endpointNotes={API.FOREIGNERS_NOTES}
                                hasPerms="foreigners.c_manage_all_foreigners_processes"
                            />
                        ),
                    },
                    {
                        content: (
                            <Label basic>
                                <Popup
                                    basic
                                    style={{ zIndex: '1000', minWidth: '450px' }}
                                    open={attachmentsOpen === item.id}
                                    onOpen={() => setAttachmentsOpen(item.id)}
                                    hoverable
                                    position="left center"
                                    trigger={
                                        <div style={{ cursor: 'pointer' }}>
                                            <Icon name="reader-outline" style={{ marginRight: '0.5rem' }} />
                                            <span style={{ position: 'relative', top: '-0.1rem' }}>
                                                {item?.files?.length}
                                            </span>
                                        </div>
                                    }
                                    content={
                                        <Attachments
                                            record={item}
                                            attribute="files"
                                            setData={setData}
                                            viewOnly={!canManage}
                                            moduleName={'foreigners'}
                                            sourceEndpoint={API.FOREIGNERS + 'processes/'}
                                            closeView={() => setAttachmentsOpen(0)}
                                        />
                                    }
                                />
                            </Label>
                        ),
                    },
                    {
                        content: item?.responsible_person && (
                            <AvatarList
                                size="25"
                                alignDirection="center"
                                persons={[
                                    {
                                        id: item?.responsible_person.id,
                                        name: item?.responsible_person.fullname,
                                        avatar: item?.responsible_person.profile_picture,
                                        displayName:
                                            item?.responsible_person.fullname_with_titles ||
                                            item.responsible_person.fullname,
                                    },
                                ]}
                            />
                        ),
                    },
                    {
                        content: (
                            <div>
                                {item.process_status === 1 && (
                                    <>
                                        <SemanticIcon
                                            name="circle"
                                            style={{ color: 'var(--info)', position: 'relative' }}
                                        />{' '}
                                        <strong>{t('open')}</strong>
                                    </>
                                )}
                                {item.process_status === 2 && (
                                    <>
                                        <SemanticIcon
                                            name="circle"
                                            style={{ color: 'var(--warning)', position: 'relative' }}
                                        />{' '}
                                        <strong>{t('submitted')}</strong>
                                    </>
                                )}
                                {item.process_status === 3 && (
                                    <>
                                        <SemanticIcon
                                            name="circle"
                                            style={{ color: 'var(--success)', position: 'relative' }}
                                        />{' '}
                                        <strong>{t('granted')}</strong>
                                    </>
                                )}
                                {item.process_status === 4 && (
                                    <>
                                        <SemanticIcon
                                            name="circle"
                                            style={{ color: 'var(--dark)', position: 'relative' }}
                                        />{' '}
                                        <strong>{t('suspended')}</strong>
                                    </>
                                )}
                                {item.process_status === 5 && (
                                    <>
                                        <SemanticIcon
                                            name="circle"
                                            style={{ color: 'var(--danger)', position: 'relative' }}
                                        />{' '}
                                        <strong>{t('denied')}</strong>
                                    </>
                                )}
                            </div>
                        ),
                    },
                    {
                        content: (
                            <div>
                                {item.process_status === 4 ? (
                                    <>
                                        <strong>
                                            {item.suspension_of_proceedings_from
                                                ? moment(item.suspension_of_proceedings_from).format(dateFormat)
                                                : '--'}
                                        </strong>{' '}
                                        /{' '}
                                        <strong>
                                            {item.suspension_of_proceedings_to
                                                ? moment(item.suspension_of_proceedings_to).format(dateFormat)
                                                : '--'}
                                        </strong>
                                        <br />
                                        {t('time_left')}:{' '}
                                        <strong>
                                            {calculateSuspendedDaysLeft(item)} {t('days')}
                                        </strong>
                                        {item.suspension_of_proceedings_reason && (
                                            <>
                                                <br />
                                                {t('reason')}: <strong>{item.suspension_of_proceedings_reason}</strong>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {t('created')}:{' '}
                                        <strong>
                                            {item.created_on ? moment(item.created_on).format(dateFormat) : '--'}
                                        </strong>
                                        <br />
                                        {t('submitted')}:{' '}
                                        <strong>
                                            {item.date_of_submission
                                                ? moment(item.date_of_submission).format(dateFormat)
                                                : '--'}
                                        </strong>
                                        <br />
                                        {item.process_status === 3 && (
                                            <>
                                                {t('granted')}:{' '}
                                                <strong>
                                                    {item.granted_at
                                                        ? moment(item.granted_at).format(dateFormat)
                                                        : '--'}
                                                </strong>
                                            </>
                                        )}
                                        {item.process_status === 5 && (
                                            <>
                                                {t('denied')}:{' '}
                                                <strong>
                                                    {item.denied_at ? moment(item.denied_at).format(dateFormat) : '--'}
                                                </strong>
                                                <br />
                                                {t('reason')}: <strong>{item.denied_reason}</strong>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        ),
                    },
                ]}
            />
        </CanView>
    )
}

export default VisasAndResidences
