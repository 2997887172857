import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
// store
// import { API } from '@store/config';
// import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon'
import { Form, Button, Divider, Icon as SemanticIcon } from 'semantic-ui-react'
import DatePresets from '@components/DatePresets'
import SuperField from '@components/forms/SuperField'

const InvoiceFilterForm = ({ filters, setFilters, type, choices }) => {
    const { t } = useTranslation()
    const currentYear = moment().format('YYYY')
    const [isVisible, setIsVisible] = useState(false)
    const [dateType, setDateType] = useState(1)
    const [dateFrom, setDateFrom] = useState(`${currentYear}-01-01`)
    const [dateTo, setDateTo] = useState(`${currentYear}-12-31`)
    const [orderByDirection, setOrderByDirection] = useState('desc')

    const handleDateChange = (value, dateField) => {
        let attributes = {
            // [dateField]: value,
            [dateField === 'date_from' ? 'date_issue_after' : 'date_issue_before']: dateType === 1 ? value : '',
            [dateField === 'date_from' ? 'date_supply_after' : 'date_supply_before']: dateType === 2 ? value : '',
            [dateField === 'date_from' ? 'date_due_after' : 'date_due_before']: dateType === 3 ? value : '',
            [dateField === 'date_from' ? 'date_approved_after' : 'date_approved_before']: dateType === 4 ? value : '',
        }

        return attributes
    }

    useEffect(() => {
        setFilters((prev) => ({
            ...prev,
            ...handleDateChange(dateFrom, 'date_from'),
            ...handleDateChange(dateTo, 'date_to'),
        }))
        // eslint-disable-next-line
    }, [dateType, dateFrom, dateTo])

    const populateOptions = (type) => {
        const optionList = choices !== undefined ? Object.entries(choices?.[type] || {}) : []
        const options = []
        for (let i = 0; i < optionList.length; i++) {
            if (['1', '4', '5'].includes(optionList[i][0])) {
                options.push({ key: i + 1, text: optionList[i][1], value: optionList[i][0] })
            }
        }

        return options
    }

    const handleSplitRatio = () => {
        if (filters.split_ratio === '' && filters.split_ratio_from === '' && filters.split_ratio_to === '') return ''

        if (filters.split_ratio === '100') return 'not'
        if (filters.split_ratio === '0') return 'fully'
        if (filters.split_ratio_from === '1') return 'partially'
    }

    return (
        <>
            <Form.Group widths="equal">
                <SuperField
                    as="datepicker"
                    label={t('date_from')}
                    value={dateFrom}
                    onChange={(e, { value }) => {
                        // setFilters((prev) => ({ ...prev, ...handleDateChange(value, 'date_from')}))
                        setDateFrom(value)
                    }}
                />
                <SuperField
                    as="datepicker"
                    label={t('date_to')}
                    value={dateTo}
                    onChange={(e, { value }) => {
                        setDateTo(value)
                        // setFilters((prev) => ({ ...prev, ...handleDateChange(value, 'date_to') }))
                    }}
                />
            </Form.Group>
            <DatePresets
                from={dateFrom}
                to={dateTo}
                onSelection={(firstMonthDay, lastMonthDay, year) => {
                    setDateFrom(firstMonthDay)
                    setDateTo(lastMonthDay)
                    // setFilters(prev => ({
                    //     ...prev,
                    //     date_from: firstMonthDay,
                    //     date_to: lastMonthDay,
                    //     ...handleDateChange(firstMonthDay, 'date_from'),
                    //     ...handleDateChange(lastMonthDay, 'date_to')
                    // }))
                }}
            />

            <p style={{ fontWeight: 'bold', fontSize: '1.1rem', marginBottom: '0.25rem', marginTop: 0 }}>
                {t('select_date_to_be_used_in_filter')}:
            </p>
            <Button.Group fluid widths="equal" basic compact>
                <Button active={dateType === 1} content={t('date_issue')} onClick={() => setDateType(1)} />
                <Button active={dateType === 2} content={t('date_supply')} onClick={() => setDateType(2)} />
                <Button active={dateType === 3} content={t('date_due')} onClick={() => setDateType(3)} />
                <Button active={dateType === 4} content={t('approval_date')} onClick={() => setDateType(4)} />
            </Button.Group>
            <Divider />

            <Form.Group widths="equal">
                <SuperField
                    as="input"
                    label={t('invoice_number')}
                    value={filters.number}
                    onChange={(e, { value }) => setFilters({ ...filters, number: value })}
                />
                <SuperField
                    as="choice"
                    label={t('type')}
                    customOptions={populateOptions('invoice_type_choices')}
                    value={filters.invoice_type}
                    onChange={(e, { value }) => setFilters({ ...filters, invoice_type: value })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="input"
                    label={t('variable_symbol')}
                    value={filters.variable_symbol}
                    onChange={(e, { value }) => setFilters({ ...filters, variable_symbol: value })}
                />
                <SuperField
                    as="input"
                    label={t('specific_symbol')}
                    value={filters.specific_symbol}
                    onChange={(e, { value }) => setFilters({ ...filters, specific_symbol: value })}
                />
                <SuperField
                    as="input"
                    label={t('constant_symbol')}
                    width="14"
                    value={filters.constant_symbol}
                    onChange={(e, { value }) => setFilters({ ...filters, constant_symbol: value })}
                />
            </Form.Group>

            <Divider />

            <div style={{ marginBottom: '0.5rem', cursor: 'pointer' }} onClick={() => setIsVisible(!isVisible)}>
                <Icon
                    name={!isVisible ? 'chevron-forward-outline' : 'chevron-down-outline'}
                    style={{ marginRight: '0.5rem' }}
                />
                <span style={{ position: 'relative', top: '-0.1rem', fontWeight: 'bold', fontSize: '1.2rem' }}>
                    {type === 'issued' ? t('customer_information') : t('supplier_information')}
                </span>
            </div>
            {isVisible && (
                <>
                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            label={t('name')}
                            value={type === 'issued' ? filters.customer_name : filters.supplier_name}
                            onChange={(e, { value }) => {
                                if (type === 'issued') {
                                    setFilters({ ...filters, customer_name: value, supplier_name: '' })
                                } else {
                                    setFilters({ ...filters, customer_name: '', supplier_name: value })
                                }
                            }}
                        />
                        <SuperField
                            as="input"
                            label={t('registration_id')}
                            value={
                                type === 'issued' ? filters.customer_registration_id : filters.supplier_registration_id
                            }
                            onChange={(e, { value }) => {
                                if (type === 'issued') {
                                    setFilters({
                                        ...filters,
                                        customer_registration_id: value,
                                        supplier_registration_id: '',
                                    })
                                } else {
                                    setFilters({
                                        ...filters,
                                        customer_registration_id: '',
                                        supplier_registration_id: value,
                                    })
                                }
                            }}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            label={t('tax_id')}
                            value={type === 'issued' ? filters.customer_tax_id : filters.supplier_tax_id}
                            onChange={(e, { value }) => {
                                if (type === 'issued') {
                                    setFilters({ ...filters, customer_tax_id: value, supplier_tax_id: '' })
                                } else {
                                    setFilters({ ...filters, customer_tax_id: '', supplier_tax_id: value })
                                }
                            }}
                        />
                        <SuperField
                            as="input"
                            label={t('vat_id')}
                            value={type === 'issued' ? filters.customer_vat_id : filters.supplier_vat_id}
                            onChange={(e, { value }) => {
                                if (type === 'issued') {
                                    setFilters({ ...filters, customer_vat_id: value, supplier_vat_id: '' })
                                } else {
                                    setFilters({ ...filters, customer_vat_id: '', supplier_vat_id: value })
                                }
                            }}
                        />
                    </Form.Group>

                    {/* City/Country */}
                    <Form.Group widths="equal">
                        <SuperField
                            as="choice"
                            label={t('country')}
                            type="countries"
                            search
                            value={type === 'issued' ? filters.customer_country : filters.supplier_country}
                            onChange={(e, { value }) => {
                                if (type === 'issued') {
                                    setFilters({ ...filters, customer_country: value, supplier_country: '' })
                                } else {
                                    setFilters({ ...filters, customer_country: '', supplier_country: value })
                                }
                            }}
                        />
                        <SuperField
                            as="input"
                            label={t('city')}
                            value={type === 'issued' ? filters.customer_city : filters.supplier_city}
                            onChange={(e, { value }) => {
                                if (type === 'issued') {
                                    setFilters({ ...filters, customer_city: value, supplier_city: '' })
                                } else {
                                    setFilters({ ...filters, customer_city: '', supplier_city: value })
                                }
                            }}
                        />
                    </Form.Group>
                </>
            )}

            <Divider />
            <Form.Group widths="equal">
                <SuperField
                    as="choice"
                    label={t('split_ratio_status')}
                    value={handleSplitRatio()}
                    customOptions={[
                        { key: 1, value: 'not', text: t('not_splitted') },
                        { key: 2, value: 'partially', text: t('partially_splitted') },
                        { key: 3, value: 'fully', text: t('fully_splitted') },
                    ]}
                    onChange={(e, { value }) => {
                        if (value === '') {
                            setFilters({
                                ...filters,
                                split_ratio: '',
                                split_ratio_from: '',
                                split_ratio_to: '',
                            })
                        } else if (value === 'not') {
                            setFilters({
                                ...filters,
                                split_ratio: '100',
                                split_ratio_from: '',
                                split_ratio_to: '',
                            })
                        } else if (value === 'partially') {
                            setFilters({
                                ...filters,
                                split_ratio: '',
                                split_ratio_from: '1',
                                split_ratio_to: '99',
                            })
                        } else if (value === 'fully') {
                            setFilters({
                                ...filters,
                                split_ratio: '0',
                                split_ratio_from: '',
                                split_ratio_to: '',
                            })
                        }
                    }}
                />
                <SuperField
                    as="choice"
                    label={t('status')}
                    customOptions={[
                        { key: 1, value: '4', text: t('drafts') },
                        { key: 2, value: '1', text: t('outstanding') },
                        {
                            key: 3,
                            value: '1&date_due_before=' + moment().add('-1', 'days').format('YYYY-MM-DD'),
                            text: t('past_due'),
                        },
                        { key: 4, value: '3', text: t('paid') },
                    ]}
                    value={filters.status}
                    onChange={(e, { value }) => setFilters({ ...filters, status: value })}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField
                    as="choice"
                    label={t('approval_status')}
                    customOptions={[
                        { key: 1, value: 1, text: t('approved') },
                        { key: 2, value: 2, text: t('awaiting_approval') },
                        { key: 3, value: 3, text: t('denied') },
                    ]}
                    value={filters.approval}
                    onChange={(e, { value }) => setFilters({ ...filters, approval: value })}
                />
                <SuperField
                    as="choice"
                    label={t('payment_verify_status')}
                    customOptions={[
                        { key: 1, value: true, text: t('verified') },
                        { key: 2, value: false, text: t('not_verified') },
                    ]}
                    value={filters.payment_is_approved}
                    onChange={(e, { value }) => setFilters({ ...filters, payment_is_approved: value })}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField
                    as="choice"
                    search
                    label={t('currency')}
                    type="currency_codes"
                    value={filters.currency}
                    onChange={(e, { value }) => setFilters({ ...filters, currency: value })}
                />
                <Form.Field style={{ display: 'flex' }}>
                    <SuperField
                        as="choice"
                        label={t('order_by')}
                        value={filters.ordering.replace('-', '')}
                        clearable={false}
                        onChange={(e, { value }) =>
                            setFilters((prev) => ({
                                ...prev,
                                ordering: orderByDirection === 'desc' ? `-${value}` : value,
                            }))
                        }
                        customOptions={[
                            { key: 1, value: 'created_at', text: t('created_at') },
                            { key: 2, value: 'date_approved', text: t('date_of_approval') },
                            { key: 3, value: 'date_issue', text: t('date_of_issue') },
                            { key: 4, value: 'date_supply', text: t('date_of_supply') },
                            { key: 5, value: 'date_due', text: t('date_due') },
                            { key: 6, value: 'date_paid', text: t('date_paid') },
                            { key: 7, value: 'number', text: t('invoice_number') },
                        ]}
                    />
                    <Button
                        style={{
                            background: 'var(--dark)',
                            color: 'var(--white)',
                            marginTop: '1.7rem',
                            marginBottom: '1rem',
                        }}
                        title={orderByDirection === 'asc' ? t('ascending') : t('descending')}
                        onClick={() => {
                            if (orderByDirection === 'asc') {
                                setOrderByDirection('desc')
                                setFilters((prev) => ({ ...prev, ordering: `-${prev.ordering.replace('-', '')}` }))
                            } else {
                                setFilters((prev) => ({ ...prev, ordering: `${prev.ordering.replace('-', '')}` }))
                                setOrderByDirection('asc')
                            }
                        }}
                        icon={
                            <SemanticIcon name={orderByDirection === 'asc' ? 'sort amount up' : 'sort amount down'} />
                        }
                    />
                </Form.Field>
            </Form.Group>
        </>
    )
}

export default InvoiceFilterForm
