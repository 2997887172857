import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { hiringService } from '@services/ServiceHiring'
// components
import { Header, Segment } from 'semantic-ui-react'
import SpinnerSegment from '@components/SpinnerSegment'
import HistoryStageRecordList from '../../hiring-rooms/components/activity-log/HistoryStageRecordList'

const CandidateHistory = ({ candidate }) => {
    const { t } = useTranslation()
    const [history, setHistory] = useState([])
    const [loading, setLoading] = useState(true)
    const [filterHistory, setFilterHistory] = useState(0)

    const loadHistoryLog = async () => {
        let filter = ''
        if (filterHistory !== 0) {
            filter = '?action=' + filterHistory
        }
        const result = await hiringService.getHistory(candidate, filter)
        if (result.status === 200) {
            setHistory(result.response)
        }
    }

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            await loadHistoryLog()
            setLoading(false)
        }

        fetchData()
        // eslint-disable-next-line
    }, [candidate, filterHistory])

    return (
        <Segment style={{ border: 'none' }}>
            <Header as="h3" dividing textAlign="center">
                {t('hiring_room_activity_log')}
            </Header>
            <SpinnerSegment loading={loading}>
                <HistoryStageRecordList
                    history={history}
                    setFilterHistory={setFilterHistory}
                    filterHistory={filterHistory}
                    setHistory={setHistory}
                />
            </SpinnerSegment>
        </Segment>
    )
}

export default CandidateHistory
