import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { HuePicker } from 'react-color';
// store
import { requests } from '@helpers/requests';
import { toOptionsLabels } from '@store/services/helpers/utils';
// components
import SuperField from '@components/forms/SuperField';
import { Segment, Button } from 'semantic-ui-react'


const SuperLabelField = ({ value, assignedLabels, onChange, endpoint, mark }) => {
    const { t } = useTranslation();
    const colors = useSelector((state) => state.global_pref.colors)
    const [values, setValues] = useState(value ? value : [])
    const [labelOptions, setLabelOptions] = useState([])
    const [options, setOptions] = useState([])
    const [error, setError] = useState(false)
    const [process, setProcess] = useState({
        loading: false,
        working: false
    })
    const [addition, setAddition] = useState(
        {
            name: '',
            color: colors.primary,
        }
    )

    useEffect(() => {
        async function fetchOptions() {
            let request = await requests.get(endpoint)
            if (request.status === 200) {
                setOptions(request.response)
                setLabelOptions(toOptionsLabels(request.response))
            }
        }
        fetchOptions()
        // eslint-disable-next-line
    }, [])

    async function createNewLabel() {
        setProcess({ ...process, loading: true })
        if (mark !== undefined) addition[mark] = true
        let request = await requests.post(endpoint, addition)
        if (request.status === 201) {
            handleAddNewLabel(request.response)
            setProcess({ loading: false, working: false })
            setAddition({
                name: '',
                color: colors.primary
            })
        } else {
            setError(`${t("error_label_first_half")} "${addition.name}" ${t("error_label_second_half")}`)
            setProcess({ loading: false, working: true })
        }
    }

    function handleChangeSelect(input) {
        onChange(input, options.filter(option => input.includes(option.id)))
        setValues(input)
    }
    function handleAddNewLabel(input) {
        setLabelOptions([...labelOptions, { key: input.id, value: input.id, text: <span style={{ position: "relative", top: "-0.3rem" }}>{ input.name }</span>, color: input.color }])
        onChange([...values, input.id], [...options, input].filter(option => [...values, input.id].includes(option.id)))
        setValues([...values, input.id])
    }


    const ColorPicker = ({ onChange, value }) => {
        const default_color = useSelector((state) => state.global_pref.colors.primary)
        const [color, setColor] = useState(value ? value : default_color)

        function handleChange(input) {
            setColor(input)
            onChange(input, options.filter(option => input.includes(option.id)))
        }
        return (
            <div style={{ display: 'flex', flexDirection: "column" }}>
                <HuePicker
                    color={color}
                    onChange={(e) => handleChange(e.hex)}
                    width={'100%'}
                />
                <div
                    style={{ backgroundColor: color, padding: '1rem' }}
                />
            </div>
        );
    };

    return (
        <Segment loading={process.loading}>
            {!process.working ?
                <>
                    <SuperField
                        className="label-super-picker"
                        multiple
                        search
                        as='choice-select'
                        value={values}
                        label={t("labels")}
                        customOptions={labelOptions}
                        addOptions={assignedLabels?.length > 0 ? assignedLabels.map(item => ({ id: item.id, value: item.id, text: item.name, label: item.name, color: item.color })) : []}
                        onChange={(e, { value }) => handleChangeSelect(value)}
                        style={{ margin: 0 }}
                    />
                    <Button
                        type="button"
                        icon="add"
                        content={t("create_label")}
                        onClick={() => setProcess({ ...process, working: true })}
                        style={{ backgroundColor: colors.primary, color: colors.white, marginBottom: '0.2rem' }} />
                </>
                :
                <>
                    <SuperField
                        label={t("name_label")}
                        as='input'
                        value={addition.name}
                        onChange={(e, { value }) => {
                            setError(false)
                            setAddition({ ...addition, name: value })
                        }}
                        error={error}
                    />
                    <ColorPicker onChange={(value) => {
                        setError(false)
                        setAddition({ ...addition, color: value })
                    }} value={addition.color} />
                    <div style={{ margin: '.2rem 0' }}>
                        <Button content={t("cancel")} onClick={() => setProcess({ ...process, working: false })} />
                        <Button
                            type="button"
                            icon="add"
                            content={t("add")}
                            onClick={() => createNewLabel()}
                            style={{ backgroundColor: colors.primary, color: colors.white }} disabled={addition.name === ''} />
                    </div>
                </>
            }
        </Segment>
    )
}
export default SuperLabelField