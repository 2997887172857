import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import ConfrimModal from '@components/modals/ConfrimModal'
import ExperienceForm from '@components/forms/ExperienceForm'
import { FlexItem, FlexRow } from '@components/tables/FlexTable'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Header, Divider, Grid, Button } from 'semantic-ui-react'

const ExperienceSection = ({ isManageable, result, setResult, patchEndpoint, canManage }) => {
    const { t } = useTranslation()

    const [experiences, setExperiences] = useState(result?.experiences || [])

    const onConfirm = async (newExperience) => {
        const data = {
            experiences: {
                add: [newExperience.id],
            },
        }

        const request = await requests.patch(patchEndpoint + result?.id + '/', data)

        if (request.status === 200) {
            setExperiences([...experiences, newExperience])
            setResult({
                ...result,
                data: {
                    ...result.data,
                    experiences: [...experiences, newExperience],
                },
            })
        }
    }

    const onDelete = async (id) => {
        const result = await requests.del(API.EXPERIENCES + id + '/')
        if (result.status === 204) {
            const newExperiencesList = experiences.filter((item) => item.id !== id)
            setExperiences(newExperiencesList)
            setResult({
                ...result,
                data: {
                    ...result.data,
                    experiences: newExperiencesList,
                },
            })
        }
    }

    const onUpdate = (updateItem) => {
        const newExperiencesList = experiences.filter((item) => {
            if (item.id === updateItem.id) {
                item.employer = updateItem.employer
                item.job_title = updateItem.job_title
                item.description = updateItem.description
                item.date_from = updateItem.date_from
                item.date_to = updateItem.date_to
            }

            return item
        })

        setExperiences(newExperiencesList)
        setResult({
            ...result,
            data: {
                ...result.data,
                experiences: newExperiencesList,
            },
        })
    }

    return (
        <Fragment>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column
                        only="computer"
                        computer="3"
                        stretched
                        style={{ borderRadius: '4px', paddingTop: '1rem', backgroundColor: 'var(--light)' }}
                    >
                        <FlexRow background="transparent" padding="0">
                            <FlexItem textAlign="center">
                                <Header icon>
                                    <Icon name="business-outline" style={{ fontSize: '5rem' }} />
                                    <h3>{t('experiences')}</h3>
                                </Header>
                            </FlexItem>
                        </FlexRow>
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="16" mobile="16">
                        <Grid columns="2" style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <Grid.Column width="10" style={{ paddingTop: '0.8rem', paddingBottom: 0 }}>
                                <Header floated="left" as="h3" style={{ marginBottom: 0 }}>
                                    <Icon name="business-outline" style={{ marginRight: '0.5rem' }} />
                                    <span style={{ position: 'relative', top: '-0.3rem' }}>{t('experiences')}</span>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width="6" textAlign="right" style={{ padding: 0 }}>
                                {canManage && (
                                    <SuperDuperModal
                                        header={t('add_experience')}
                                        trigger={
                                            <Header
                                                style={{ padding: 0, cursor: 'pointer', paddingTop: '1rem' }}
                                                floated="right"
                                                as="h4"
                                            >
                                                <Icon
                                                    name="add-outline"
                                                    style={{ fontSize: '1.2rem', marginRight: '0.5rem' }}
                                                />
                                                <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                    {t('add_experience')}
                                                </span>
                                            </Header>
                                        }
                                        content={
                                            <ExperienceForm
                                                experience={{}}
                                                onConfirm={(newExperience) => onConfirm(newExperience)}
                                            />
                                        }
                                    />
                                )}
                            </Grid.Column>
                        </Grid>

                        <Divider />
                        {experiences.length > 0 ? (
                            <Grid stretched style={{ margin: '1rem' }}>
                                {experiences.map((item, index) => (
                                    <Grid.Column key={index} width={16} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                        <Fragment>
                                            <Grid
                                                style={{
                                                    backgroundColor: 'var(--default)',
                                                    marginTop: '.5rem',
                                                    borderRadius: 0,
                                                }}
                                            >
                                                <Grid.Row>
                                                    <Grid.Column width="14" style={{ paddingLeft: 0 }}>
                                                        <h4>{item.job_title}</h4>
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <SuperDuperModal
                                                            trigger={
                                                                <Button
                                                                    icon={<Icon name="pencil-outline" />}
                                                                    style={{
                                                                        backgroundColor: 'transparent',
                                                                        padding: 0,
                                                                    }}
                                                                />
                                                            }
                                                            header={t('update_experience')}
                                                            content={
                                                                <ExperienceForm
                                                                    experience={item}
                                                                    onConfirm={(updatedExperience) =>
                                                                        onUpdate(updatedExperience)
                                                                    }
                                                                />
                                                            }
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <ConfrimModal
                                                            onConfirm={() => onDelete(item.id)}
                                                            button={
                                                                <Button
                                                                    icon={
                                                                        <Icon
                                                                            name="close-outline"
                                                                            style={{ color: 'var(--danger)' }}
                                                                        />
                                                                    }
                                                                    style={{
                                                                        backgroundColor: 'transparent',
                                                                        color: 'var(--danger)',
                                                                        padding: 0,
                                                                    }}
                                                                />
                                                            }
                                                            description={t('delete_experience')}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                            <Grid style={{ padding: 0 }}>
                                                <Grid.Row style={{ padding: 0 }}>
                                                    <Grid.Column
                                                        style={{ padding: 0 }}
                                                        computer="10"
                                                        tablet="16"
                                                        mobile="16"
                                                    >
                                                        {item.description !== '' && (
                                                            <p style={{ color: 'var(--variant2)', marginBottom: 5 }}>
                                                                {item.description}
                                                            </p>
                                                        )}
                                                        <p style={{ color: 'var(--variant2)', marginBottom: 5 }}>
                                                            {item.employer}
                                                        </p>
                                                        <p style={{ color: 'var(--variant2)', marginBottom: 5 }}>
                                                            {item.date_from} - {item.date_to}
                                                        </p>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Fragment>
                                        <Divider />
                                    </Grid.Column>
                                ))}
                            </Grid>
                        ) : (
                            <Grid centered>
                                <Header as="h4">{t('nothing')}</Header>
                            </Grid>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Fragment>
    )
}

export default ExperienceSection
