import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import { Card, Grid, Header } from 'semantic-ui-react';


const StatCard = ({ label, value }) => {

    return (
        <Grid.Column computer={4} tablet={8} mobile={16}>
            <Card style={{ width: "100%", borderRadius: "0", marginBottom: "0.5rem", borderBottom: "3px solid var(--dark)" }}>
                <Card.Content>
                    <span style={{ fontWeight: "bolder", fontSize: "2.5rem" }}> {value} </span>
                    <Header style={{ marginBottom: "0", marginTop: "1rem" }} as="h3" content={label} />
                </Card.Content>
            </Card>
        </Grid.Column>
    )
}

const DailyStatsSection = ({ stats, view }) => {
    const { t } = useTranslation()

    function getProgress() {
        const result = Math.floor((stats.closed_issues / stats.all_issues) * 100)
        if (result) return result + "%"
        return "0%"
    }

    if (view === 0 || view === 2) {
        return (
            <Grid relaxed style={{ marginTop: "1rem" }}>
                <Grid.Row>
                    <StatCard label={t('all')} value={stats?.all_issues} />
                    <StatCard label={t('opened')} value={stats?.opened_issues} />
                    <StatCard label={t('closed')} value={stats?.closed_issues} />
                    <StatCard label="Progress" value={getProgress()} />
                </Grid.Row>
            </Grid>
        )
    }

    return (
        <Grid relaxed style={{ marginTop: "1rem" }}>
            <Grid.Row>
                <StatCard label={t('all')} value={stats?.issues_count} />
                <StatCard label={t('opened')} value={stats?.open_issues_count} />
                <StatCard label={t('closed')} value={stats?.closed_issues_count} />
                <StatCard label="Progress" value={stats?.progress + "%"} />
            </Grid.Row>
        </Grid>
    );
};

export default DailyStatsSection;