import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { tzDateTime } from '@helpers/dates'
import { truncateString } from '@helpers/functions'
import { requests, fileDownload } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import SuperField from '@components/forms/SuperField'
import SpinnerSegment from '@components/SpinnerSegment'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { FlexRow, FlexItem, FlexTable } from '@components/tables/FlexTable'
import { Header, Grid, Form, Divider, Button, Container, Checkbox, Message, Dropdown, Popup } from 'semantic-ui-react'

const DynamicFieldExports = ({
    module,
    configModule,
    initFilename,
    onClose,
    selected,
    endpoint,
    type,
    handleParams,
    IDStringArray,
}) => {
    const { t } = useTranslation()
    const today = moment.utc()
    const dateFormat = useSelector((state) => state.date_format)

    initFilename = initFilename || module

    const [presets, setPresets] = useState([{ key: 0, value: 'default', text: t('default_export'), fields: [] }])

    const [selectedPreset, setSelectedPreset] = useState(presets?.[0]?.value || '')
    const [fetching, setFetching] = useState(true)
    const [loading, setLoading] = useState(false)
    const [filename, setFilename] = useState(initFilename + ' - ' + tzDateTime(today).format(dateFormat + ' HH:mm'))
    const [fields, setFields] = useState([])

    let handlePresetChange = (preset) => {
        let fieldsToSelect = []
        if (preset) {
            if (preset === '.') {
                fieldsToSelect = []
            } else {
                fieldsToSelect = preset
            }
        }

        setFields((prev) =>
            prev.map((item) => {
                if (fieldsToSelect.length === 0) {
                    item.isChecked = false
                } else {
                    if (fieldsToSelect.includes(item.name)) {
                        item.isChecked = true
                    } else {
                        item.isChecked = false
                    }
                }
                return item
            })
        )
    }

    const handleFieldsToExclude = (fieldsToExclude, moduleFields) => {
        // split them into array
        let excludeFields = fieldsToExclude.split(',').filter((item) => item.trim() !== '')

        // get allowed (toggled) fields:
        return moduleFields.filter((item) => !excludeFields.includes(item.name)).map((item) => item.name)
    }

    useEffect(() => {
        async function fetchDynamicData() {
            setFetching(true)
            let moduleFields = []
            let modulePresets = []
            let moduleDefaultPreset = []
            const request = await requests.get(API.EXPORTS + 'dynamic_fields/?module=' + configModule)
            if (request.status === 200) {
                moduleFields = request.response.map((item) => ({
                    name: item.field,
                    displayName: item.field_display,
                    isChecked: item.default,
                }))
                moduleDefaultPreset = request.response.filter((item) => item.default).map((item) => item.field) || []
            }

            const requestPresets = await requests.get(API.EXPORTS + 'dynamic_preferences/?source=' + module)
            if (requestPresets.status === 200) {
                modulePresets = requestPresets.response.map((item) => ({
                    key: item.id,
                    value: item.id,
                    text: item.title,
                    fields: handleFieldsToExclude(item.fields_to_exclude, moduleFields),
                }))
            }

            setPresets((prev) => [{ ...prev[0], fields: moduleDefaultPreset }, ...modulePresets])
            setFields(moduleFields)
            handlePresetChange(moduleDefaultPreset)
            setFetching(false)
        }

        fetchDynamicData()
    }, [])

    const handleExport = async () => {
        setLoading(true)
        if (filename) {
            const excludeFields = [...fields.filter((field) => !field.isChecked).map((field) => field.name)]

            const excludeQuery = '&exclude=' + excludeFields.join(',')
            let paramQuery = ''
            if (selected.length > 0) {
                paramQuery = '&ids=' + IDStringArray
            } else {
                paramQuery = handleParams()
            }

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('generating_export') + '...',
                description: t('wait_a_moment_until_its_finished') + '.',
                animation: 'bounce',
                time: 3000,
            })

            await fileDownload('GET', endpoint + '?query={*}' + excludeQuery + paramQuery, type, `${filename}.${type}`)
                .then((result) => {})
                .catch((error) => {
                    toast({
                        type: 'error',
                        icon: 'warning circle',
                        title: t('unable_to_generate_file'),
                        animation: 'bounce',
                        time: 5000,
                    })
                })
        }
        setLoading(false)
    }

    const getPresetDisplay = (selectedPreset) => {
        const findPreset = presets.find((item) => item.value === selectedPreset)
        if (findPreset) {
            let selectedFields = fields
                .filter((item) => item.isChecked)
                .map((item) => item.name)
                .sort()
            let presetFields = Array.isArray(findPreset.fields) ? findPreset.fields.sort() : []

            if (selectedFields.length !== presetFields.length) {
                return t('custom_preset')
            } else {
                let isSame = selectedFields.every((item, index) => {
                    return item === presetFields[index]
                })

                if (!isSame) {
                    return t('custom_preset')
                }
            }

            // compare selected fields with preset fields and determinate originality if not return as "custom preset"
            return truncateString(findPreset?.text || '--', 30)
        }

        return t('custom_preset')
    }

    const onPresetDelete = async (selectedPreset) => {
        setLoading(true)
        if (selectedPreset !== 'default') {
            const request = await requests.del(API.EXPORTS + 'dynamic_preferences/' + parseInt(selectedPreset) + '/')
            if (request.status === 204) {
                handlePresetChange(presets.find((item) => item.value === 'default')?.fields || [])
                setPresets((prev) => prev.filter((item) => item.value !== selectedPreset))
                setSelectedPreset('default')
            }
        }
        setLoading(false)
    }

    return (
        <div style={{ textAlign: 'left' }}>
            <Grid style={{ background: 'var(--light)', marginBottom: '1rem' }}>
                <Grid.Row columns={2} verticalAlign="middle">
                    <Grid.Column>
                        <Header as="h2" content={t('export_settings')} style={{ textAlign: 'left' }} />
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Icon
                            name="close-outline"
                            style={{
                                cursor: 'pointer',
                                color: 'var(--danger)',
                                marginTop: '0.5rem',
                                fontSize: '1.5rem',
                            }}
                            onClick={() => onClose()}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Container fluid>
                <Message info visible>
                    <Icon
                        name="information-circle-outline"
                        style={{ marginRight: '0.5rem', position: 'relative', top: '0.2rem' }}
                    />
                    {t('applied_filters_or_selected_rows_will_be_used_during_export')}
                </Message>
                <Form onSubmit={(e) => e.preventDefault()}>
                    <SuperField
                        as="input"
                        required
                        label={t('filename')}
                        value={filename}
                        onChange={(e, { value }) => setFilename(value)}
                    />
                    <Divider />

                    <SpinnerSegment loading={fetching}>
                        {!fetching && (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginBottom: '1rem',
                                    }}
                                >
                                    <div>
                                        {t('presets')}:{' '}
                                        <Dropdown text={getPresetDisplay(selectedPreset)} inline>
                                            <Dropdown.Menu>
                                                {presets.map((item) => (
                                                    <Dropdown.Item
                                                        key={item.key}
                                                        onClick={() => {
                                                            setSelectedPreset(item.value)
                                                            handlePresetChange(
                                                                presets.find((preset) => preset.value === item.value)
                                                                    ?.fields
                                                            )
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                            }}
                                                        >
                                                            <div>{item.text}</div>

                                                            <div>
                                                                {item.value === 'default' ? (
                                                                    <Popup
                                                                        position="left center"
                                                                        trigger={
                                                                            <Icon
                                                                                name="lock-closed-outline"
                                                                                style={{
                                                                                    marginLeft: '1rem',
                                                                                    position: 'relative',
                                                                                    top: '0.15rem',
                                                                                }}
                                                                            />
                                                                        }
                                                                        content={t('can_not_be_changed_or_removed')}
                                                                    />
                                                                ) : (
                                                                    <Popup
                                                                        position="left center"
                                                                        trigger={
                                                                            <Icon
                                                                                name="people-outline"
                                                                                style={{
                                                                                    marginLeft: '1rem',
                                                                                    position: 'relative',
                                                                                    top: '0.15rem',
                                                                                }}
                                                                            />
                                                                        }
                                                                        content={t(
                                                                            'global_preset_shared_for_all_users'
                                                                        )}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {presets
                                            .filter((item) => item.key !== 0)
                                            .find((item) => item.value === selectedPreset) && (
                                            <>
                                                {getPresetDisplay(selectedPreset) !== t('custom_preset') && (
                                                    <Popup
                                                        position="top center"
                                                        trigger={
                                                            <Icon
                                                                name="close-outline"
                                                                style={{
                                                                    color: 'var(--danger)',
                                                                    cursor: 'pointer',
                                                                    position: 'relative',
                                                                    top: '0.2rem',
                                                                    marginLeft: '0.5rem',
                                                                }}
                                                                onClick={() => onPresetDelete(selectedPreset)}
                                                            />
                                                        }
                                                        content={t('delete_preset')}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {fields.filter((item) => !item.isChecked).length > 0 && (
                                            <div
                                                className="ref-link"
                                                style={{
                                                    cursor: 'pointer',
                                                    color: 'var(--primary)',
                                                    marginRight: '1rem',
                                                }}
                                                onClick={() =>
                                                    setFields((prev) =>
                                                        prev.filter((item) => {
                                                            if (!item.isChecked) {
                                                                item.isChecked = true
                                                            }

                                                            return item
                                                        })
                                                    )
                                                }
                                            >
                                                {t('select_all')}
                                            </div>
                                        )}
                                        {fields.filter((item) => item.isChecked).length > 0 && (
                                            <div
                                                className="ref-link"
                                                style={{ cursor: 'pointer', color: 'var(--danger)' }}
                                                onClick={() =>
                                                    setFields((prev) =>
                                                        prev.filter((item) => {
                                                            item.isChecked = false

                                                            return item
                                                        })
                                                    )
                                                }
                                            >
                                                {t('clear_selection')}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <FlexTable stripped hoverable responsive={false}>
                                    {fields.map((field, idx) => (
                                        <FlexRow borders key={idx} padding="0.7rem">
                                            <FlexItem>
                                                <Header as="h4">
                                                    <Header.Content>
                                                        {field.displayName}
                                                        {field.description && (
                                                            <Header.Subheader>{field.description}</Header.Subheader>
                                                        )}
                                                    </Header.Content>
                                                </Header>
                                            </FlexItem>
                                            <FlexItem basis="20%" textAlign="right">
                                                <Checkbox
                                                    toggle
                                                    checked={field.isChecked}
                                                    onClick={() =>
                                                        setFields((prev) =>
                                                            prev.filter((item) => {
                                                                if (item.name === field.name) {
                                                                    item.isChecked = !field.isChecked
                                                                }

                                                                return item
                                                            })
                                                        )
                                                    }
                                                />
                                            </FlexItem>
                                        </FlexRow>
                                    ))}
                                </FlexTable>

                                <Divider style={{ marginBottom: 0 }} />
                                <Form.Field
                                    style={{
                                        position: 'sticky',
                                        bottom: 0,
                                        paddingTop: '1rem',
                                        paddingBottom: '1rem',
                                        width: '100%',
                                        background: 'white',
                                        zIndex: 2,
                                    }}
                                >
                                    {getPresetDisplay(selectedPreset) === t('custom_preset') ? (
                                        <SuperDuperModal
                                            size="mini"
                                            trigger={
                                                <Button
                                                    primary
                                                    type="button"
                                                    disabled={
                                                        loading ||
                                                        filename === '' ||
                                                        fields.filter((field) => field.isChecked === true).length === 0
                                                    }
                                                    loading={loading}
                                                >
                                                    <Icon name="download-outline" style={{ marginRight: '0.5rem' }} />
                                                    {t('export_xlsx')}
                                                </Button>
                                            }
                                            content={
                                                <StoreAsPresetForm
                                                    module={module}
                                                    handleExport={handleExport}
                                                    setSelectedPreset={setSelectedPreset}
                                                    setPresets={setPresets}
                                                    handleFieldsToExclude={handleFieldsToExclude}
                                                    moduleFields={fields}
                                                />
                                            }
                                        />
                                    ) : (
                                        <Button
                                            primary
                                            type="button"
                                            disabled={
                                                loading ||
                                                filename === '' ||
                                                fields.filter((field) => field.isChecked === true).length === 0
                                            }
                                            loading={loading}
                                            onClick={() => handleExport()}
                                        >
                                            <Icon name="download-outline" style={{ marginRight: '0.5rem' }} />
                                            {t('export_xlsx')}
                                        </Button>
                                    )}
                                </Form.Field>
                            </>
                        )}
                    </SpinnerSegment>
                </Form>
            </Container>
        </div>
    )
}

const StoreAsPresetForm = ({
    onClose,
    moduleFields,
    setPresets,
    setSelectedPreset,
    handleFieldsToExclude,
    module,
    handleExport,
}) => {
    const { t } = useTranslation()
    const [processing, setProcessing] = useState(false)
    const [title, setTitle] = useState('')

    const selectedFields = moduleFields.filter((item) => item.isChecked !== true).map((item) => item.name)

    const handleSubmit = async () => {
        setProcessing(true)
        const request = await requests.post(API.EXPORTS + 'dynamic_preferences/', {
            title: title,
            source: module,
            fields_to_exclude: selectedFields.join(','),
        })

        if (request.status === 201) {
            setSelectedPreset(request.response.id)
            setPresets((prev) => [
                ...prev,
                {
                    key: request.response.id,
                    value: request.response.id,
                    text: request.response.title,
                    fields: handleFieldsToExclude(request.response.fields_to_exclude, moduleFields),
                },
            ])
            onClose()
            handleExport()
        }
        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Header as="h3" content={t('save_as_preset') + '?'} />
            <SuperField
                as="input"
                autoFocus
                placeholder={t('enter_name')}
                onChange={(e, { value }) => setTitle(value)}
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <Button
                    basic
                    type="button"
                    onClick={() => {
                        onClose()
                        handleExport()
                    }}
                >
                    {t('no_just_export')}
                </Button>
                <Button primary disabled={title === '' || processing} loading={processing}>
                    {t('save')}
                </Button>
            </Form.Field>
        </Form>
    )
}

export default DynamicFieldExports
