import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line
import { toast } from 'react-semantic-toasts';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import ListView from '@components/ListView';
import CanView from '@components/perms/CanView';
// eslint-disable-next-line
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
// import NonFieldErrors from '@components/NonFieldErrors';
import { Container, Divider, Form, Table, Button, Loader, Icon, Popup, Message } from 'semantic-ui-react';

const TariffListing = ({ dataset, setData, setTotal, activities, employees, categories }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [dataList, setDataList] = useState(dataset)
    const [saved, setSaved] = useState([])

    const handleSubmit = async () => {
        const createdTariffs = []
        for (let i = 0; i < dataList.length; i++) {
            // set loading state
            setDataList(prev => prev.filter((item, idx) => {
                if (i === idx) {
                    dataList[i].processing = true
                }

                return item
            }))

            // handle object creation/update/errors
            let tariff = dataList[i]
            let request = null
            if (dataList[i].id === 0) { // create
                request = await requests.post(API.ORDERS + "tariffs/", tariff)
            } else { // update
                request = await requests.put(API.ORDERS + "tariffs/" + dataList[i].id + "/", tariff)
            }

            if (request?.status === 400) {
                setDataList(prev => prev.filter((item, idx) => {
                    if (i === idx) {
                        dataList[i].errors = request?.response || null
                    }
    
                    return item
                }))
            }

            if ([200, 201].includes(request?.status)) {
                createdTariffs.push(request.response)
                setDataList(prev => prev.filter((item, idx) => {
                    if (i === idx) {
                        dataList[i].id = request?.response?.id || 0
                    }
    
                    return item
                }))
            }

            // disable loading state
            setDataList(prev => prev.filter((item, idx) => {
                if (i === idx) {
                    dataList[i].processing = false
                }

                return item
            }))

            if (createdTariffs.length > 0) {
                setSaved(createdTariffs)
            }
        }
    }

    useEffect(() => {
        async function processRequest() {
            if (dataList.length > 0) {
                setProcessing(true)
                await handleSubmit()
                setProcessing(false)
            }
        }

        processRequest()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Table striped selectable unstackable className="table">
                <Table.Header>
                    <Table.Row verticalAlign="middle">
                        <Table.HeaderCell content={t('type')}/>
                        <Table.HeaderCell content={t('sum')}/>
                        <Table.HeaderCell content={t('activity')}/>
                        <Table.HeaderCell content={t('person')}/>
                        <Table.HeaderCell content={t('category')}/>
                        <Table.HeaderCell content={"#"} style={{ textAlign: "center" }}/>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    { dataList.map((item, idx) => (
                        <Table.Row verticalAlign="middle" key={idx}>
                            <Table.Cell style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                { item.type === 1 && t('per_hour_payments') }
                                { item.type === 2 && t('one_time_payments') }
                                { item.type === 3 && t('piece_work_payments') }
                            </Table.Cell>
                            <Table.Cell style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                { item.amount }{" "}{ item.currency }
                            </Table.Cell>
                            <Table.Cell style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                { activities.find(activity => activity.value === item.timesheet_category)?.text || "--" }
                            </Table.Cell>
                            <Table.Cell style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                { employees.find(employee => employee.value === item.profile)?.text || "--" }
                            </Table.Cell>
                            <Table.Cell style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                { categories.find(category => category.value === item.cost_center_category)?.text || "--" }
                            </Table.Cell>
                            <Table.Cell style={{ paddingTop: "1rem", paddingBottom: "1rem", textAlign: "center" }}>
                                { 
                                    item.processing 
                                    ? <Loader size="small" className='dark-loader' active inline/>
                                    : 
                                    <>
                                        { item.errors === null
                                            ? item.id === 0 ? <Icon name="question circle" style={{ color: "var(--warning)" }}/> : <Icon name="check circle" style={{ color: "var(--success)" }}/>
                                            : <Popup position='left center' simple trigger={<Icon name="warning circle" style={{ color: "var(--danger)" }}/>} content={item.errors?.non_field_errors?.[0] || ""}/>
                                        }
                                    </> 
                                }
                            </Table.Cell>
                        </Table.Row>
                    )) }
                </Table.Body>
            </Table>
            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <Button 
                    primary 
                    type="button"
                    onClick={() => {
                        setTotal(prev => prev + saved.length)
                        setData(prev => ([...saved, ...prev]))
                    }}
                    loading={processing}
                    disabled={processing}
                >
                    { t('confirm') }
                </Button>
            </Form.Field>
        </>
    )
} 

const TariffForm = ({ onClose, employees, categories, activities, setData, setTotal, setSize }) => {
    const { t } = useTranslation()

    const initialForm = {
        amount: "",
        currency: "EUR",
        type: 1,
        employees: [],
        activities: [],
        category: "",
    }

    const [isProcessing, setIsProcessing] = useState(false)
    const [form, setForm] = useState(initialForm)
    const [dataset, setDataset] = useState([])

    useEffect(() => {
        if (dataset.length > 0) {
            setSize('large')
        } else {
            setSize('tiny')
        }

        // eslint-disable-next-line
    }, [dataset])

    const isFormValid = () => {
        let isValid = true
        if (form.amount <= 0 || isNaN(form.amount) || form.amount === "" || form.currency === "" || form.type === "") isValid = false
        if (form.activities.length === 0) isValid = false

        return isValid
    }

    const handleSubmit = async () => {
        setIsProcessing(true)
        const data = []
        const activityList = form.activities
        const employeeList = form.employees
        for (let i = 0; i < activityList.length; i++) {
            let item = {
                id: 0,
                amount: form.amount,
                type: form.type,
                currency: form.currency,
                timesheet_category: activityList[i],
                cost_center_category: form.category === "" ? null : form.category,
                processing: false,
                errors: null
            }
            if (employeeList.length > 0) {
                for (let x = 0; x < employeeList.length; x++) {
                    data.push({...item, profile: employeeList[x]})
                }
            } else {
                data.push(item)
            }
        }

        setDataset(data)
        setIsProcessing(false)
    }

    return (
        <>
            { dataset.length > 0 
                ? 
                    <TariffListing
                        dataset={dataset}
                        setDataset={setDataset}
                        setInitialForm={() => setForm(initialForm)}
                        setSize={setSize}
                        employees={employees}
                        activities={activities}
                        categories={categories}
                        setData={setData}
                        setTotal={setTotal}
                    />
                : 
                    <Form onSubmit={handleSubmit}>
                        <Form.Group widths="equal">
                            <SuperField as="input"
                                required
                                autoFocus
                                label={t('sum')}
                                value={form.amount.replace(',', '.')}
                                error={((form.amount <= 0 || isNaN(form.amount)) && form.amount !== "") ? t('value_higher_then_zero') : false}
                                onChange={(e, { value }) => setForm(prev => ({...prev, amount: value.replace(',', '.')}))}
                            />
                            <SuperField as="choice"
                                search
                                required
                                type="currency_codes"
                                label={t('currency')}
                                value={form.currency}
                                onChange={(e, { value }) => setForm(prev => ({...prev, currency: value === "" ? "EUR" : value}))}
                            />
                        </Form.Group>
                        <SuperField as="choice"
                            required
                            label={t('type')}
                            customOptions={[
                                { key: 1, value: 1, text: t('per_hour_payments') },
                                { key: 2, value: 2, text: t('one_time_payments') },
                                { key: 3, value: 3, text: t('piece_work_payments') }
                            ]}
                            value={form.type}
                            onChange={(e, { value }) => setForm(prev => ({...form, type: value === "" ? 1 : value}))}
                        />

                        <Divider/>

                        <SuperField  as="choice"
                            search
                            multiple
                            required
                            label={t('assign_to_one_or_multiple_activities')}
                            customOptions={activities}
                            value={form.activities}
                            onChange={(e, { value }) => setForm({ ...form, activities: value })}
                        />

                        <SuperField as="choice"
                            search
                            multiple
                            label={t('assign_to_one_or_multiple_employees')}
                            value={form.employees}
                            customOptions={employees}
                            onChange={(e, { value }) => setForm(prev => ({...prev, employees: value}))}
                        />

                        <Divider/>

                        <SuperField
                            as="choice"
                            search
                            label={t('cost_center_record_category')}
                            help={t('cost_center_record_category_hint')}
                            customOptions={categories}
                            value={form.category}
                            onChange={(e, { value }) => setForm({ ...form, category: value })}
                        />

                        <Divider />
                        <Form.Field style={{ textAlign: "right" }}>
                            <ModalCancel onClose={onClose} />
                            <ModalSubmit
                                loading={isProcessing}
                                disabled={isProcessing || !isFormValid()}
                                text={t('save')} />
                        </Form.Field>
                    </Form>
            }
        </>
    )
}

const TariffsManagement = () => {
    const { t } = useTranslation()
    const [employees, setEmployees] = useState([])
    const [categories, setCategories] = useState([])
    const [activities, setActivities] = useState([])

    useEffect(() => {
        async function fetchEmployees(){
            const request = await requests.get(API.EMPLOYEES + "?only_basic_info=true&is_active=true")
            if (request.status === 200) setEmployees(request.response)
        }

        async function fetchCategories(){
            const request = await requests.get(API.COST_CENTERS_RECORD_CATEGORIES + "?type=2")
            if( request.status === 200 ) setCategories(request.response)
        }

        async function fetchActivities(){
            const request = await requests.get(API.TIMESHEETS + "categories/")
            if( request.status === 200 ) setActivities(request.response)
        }

        fetchEmployees()
        fetchCategories()
        fetchActivities()
    }, [])

    const handleCategoryOptions = () => {
        let options = []

        options = categories.map(category => {
            let textValue = category.title + " (" + category.type_display + ")"
            
            if( category.code !== "" && category.code !== null){
                textValue = category.code + " - " + category.title + " (" + category.type_display + ")"
            }

            return {
                id: category.id,
                value: category.id,
                text: textValue
            }
        })

        return options
    }

    const employeesOptions = employees?.map(item => ({ key: item.id, value: item.id, text: item.fullname_with_titles })) || []
    const activitiesOptions = activities?.map(item => ({ key: item.id, value: item.id, text: item.title })) || []
    const categoriesOptions = handleCategoryOptions()

    const onDelete = async (item, setData, setTotal) => {
        const request = await requests.patch(API.ORDERS + "tariffs/" + item + "/", {
            is_archived: true
        })

        if (request.status === 200) {
            setTotal(prev => prev - 1)
            setData(prev => prev.filter(tariff => tariff.id !== item))
        }
    }


    return (
        <CanView permission={['orders.c_view_order_tariffs']} redirect>
            <Container fluid style={{ marginTop: "2rem" }}>
                <Message>
                    <Message.Header>{t('tariffs_management')}</Message.Header>
                    <Message.List>
                        <Message.Item>{t('manage_your_tariffs_for')}: <strong>{t('per_hour_payments')}</strong> = {t('sum')} * {t('hours')}, <strong>{t('one_time_payments')}</strong> = {t('sum')}, <strong>{t('piece_work_payments')}</strong> = {t('sum')} * {t('amount')}</Message.Item>
                        <Message.Item>{t('you_are_allowed_to_combine_tariffs_for')}: <strong>{t('employee_and_their_activities')}</strong>, <strong>{t('activities_only')}</strong></Message.Item>
                    </Message.List>
                </Message>

                <Divider/>

                <ListView
                    as="table"
                    allowSearch
                    actionsCellWidth="2"
                    endpoint={API.ORDERS + "tariffs/"}
                    tableHeaders={[
                        { title: t('type'), orderBy: "type" },
                        { title: t('sum'), orderBy: "amount" },
                        { title: t('activity'), orderBy: "timesheet_category__title" },
                        { title: t('person'), orderBy: "profile__person__lastname" },
                        { title: t('category'), orderBy: "cost_center_category__title" },
                    ]}
                    initialFilters={{
                        profile: "",
                        type: "",
                        timesheet_category: "",
                        cost_center_category: "",
                        is_archived: false,
                    }}
                    listAdditionActions={() => ([
                        {
                            as: "filter",
                            index: 0,
                            name: t('all'),
                            filters: {
                                type: "",
                                is_archived: false,
                            },
                        },
                        {
                            as: "filter",
                            index: 1,
                            name: t('per_hour_payments'),
                            filters: {
                                type: 1,
                            },
                        },
                        {
                            as: "filter",
                            index: 2,
                            name: t('one_time_payments'),
                            filters: {
                                type: 2,
                            },
                        },
                        {
                            as: "filter",
                            index: 3,
                            name: t('piece_work_payments'),
                            filters: {
                                type: 3,
                            },
                        },
                        {
                            as: "filter",
                            index: 4,
                            name: t('archived'),
                            filters: {
                                is_archived: true,
                            },
                        }
                    ])}
                    renderFilterFields={(filters, setFilters) => (
                        <>
                            <SuperField as="choice"
                                search
                                label={t('person')}
                                value={filters.profile}
                                customOptions={employeesOptions}
                                onChange={(e, { value }) => setFilters({ ...filters, profile: value })}
                            />
                            <SuperField as="choice"
                                search
                                label={t('activity')}
                                value={filters.timesheet_category}
                                customOptions={activitiesOptions}
                                onChange={(e, { value }) => setFilters({ ...filters, timesheet_category: value })}
                            />
                            <SuperField as="choice"
                                search
                                label={t('cost_center_record_category')}
                                value={filters.cost_center_category}
                                customOptions={categoriesOptions}
                                onChange={(e, { value }) => setFilters({ ...filters, cost_center_category: value })}
                            />
                        </>
                    )}
                    renderCells={(tariff, setData) => ([
                        { content: tariff.type_display },
                        { content: `${tariff.amount} ${tariff.currency}` },
                        { content: tariff.timesheet_category?.title || "--" },
                        { content: tariff.profile?.fullname_with_titles || "--" },
                        { content: tariff.cost_center_category ? `${tariff.cost_center_category?.code} - ${tariff.cost_center_category?.title}` : "--" },
                    ])}
                    actions={[
                        {
                            as: "modal",
                            type: "add",
                            modalSize: "tiny",
                            name: t('create_tariff'),
                            modal: <TariffForm employees={employeesOptions} activities={activitiesOptions} categories={categoriesOptions}/>,
                            permissions: ['orders.c_manage_order_tariffs'],
                        },
                        // {
                        //     as: "modal",
                        //     type: "edit",
                        //     name: t('edit'),
                        //     modalSize: "tiny",
                        //     permissions: ['orders.c_manage_order_tariffs'],
                        //     modal: <TariffForm employees={employeesOptions}/>
                        // },
                        {
                            name: t('delete'),
                            type: "delete",
                            as: "delete",
                            text: t('delete_tariff'),
                            permissions: ['orders.c_manage_order_tariffs'],
                            isHidden: (item) => item.is_archived,
                            customDelete: (item, setData, setTotal) => onDelete(item.id, setData, setTotal)
                        },
                    ]}
                />
            </Container>
        </CanView>
    );
};

export default TariffsManagement;