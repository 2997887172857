import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from "react-i18next"
// store 
import { API } from '@store/config';
import { deleteRequest, patchRequest } from '@services/ServiceCommon';
// components
import SuperDuperModal from '@components/modals/SuperDuperModal';
import CanView from '@components/perms/CanView';
import { FlexRow, FlexItem } from '@components/tables/FlexTable'
import { Button, Grid, Header, Icon, Label, Divider } from 'semantic-ui-react';
import LabelsForm from '../forms/LabelsForm';
import ConfrimModal from '@components/modals/ConfrimModal';


const Labels = ({ labels, setProject }) => {
    const { t } = useTranslation();
    const params = useParams()

    const onAdd = async (response) => {
        const request = await patchRequest(API.PROJECTS + params.id + "/", { labels: { add: [response.id] } })
 
         if (request.status === 200) {
             setProject((prevState) => {
                 return {
                     ...prevState,
                     data: {
                         ...prevState.data,
                         labels: [...prevState.data.labels, response]
                     }
                 }
             })
         }
 
    }

    const onUpdate = async (response) => {
        setProject((prevState) => {
            return {
                ...prevState,
                data: {
                    ...prevState.data,
                    labels: prevState.data.labels.filter(label => {
                        if (label.id === response.id) {
                            label.name = response.name
                            label.color = response.color
                        }
                        return label
                    })
                }
            }
        })
    }

    const onDelete = async (id) => {
        const request = await deleteRequest(API.PROJECT_LABELS + id + "/");

        if (request.status === 204) {
            setProject((prevState) => {
                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        labels: prevState.data.labels.filter(label => label.id !== id)
                    }
                }
            })
        }
    }

    const LabelView = ({ label, onDelete, onUpdate }) => {
        const [showActions, setShowActions] = useState(false)

        return (
            <FlexRow 
                borders
                padding="0.4rem" 
                background="transparent"
                onMouseOver={ () => setShowActions(true) }
                onMouseLeave={ () => setShowActions(false) }
            >
                <FlexItem basis="400%">
                    <Label
                        number={label.name}
                        style={{ color: 'white', backgroundColor: label.color }}>
                        {label.name}
                    </Label>
                </FlexItem>
                { showActions && 
                    <CanView permissions={["projects.c_manage_labels"]}>
                        <FlexRow padding="0" background="transparent">
                            <FlexItem textAlign="center">
                                <SuperDuperModal
                                    size='small'
                                    header={t("update_tag")}
                                    trigger={
                                        <Icon name="pencil" style={{ marginRight: 0, color: "var(--dark)", cursor: "pointer" }} />
                                    }
                                    content={
                                        <LabelsForm
                                            labelItem={label}
                                            onConfirm={(response) => onUpdate(response)}
                                        />
                                    }
                                />
                            </FlexItem>
                            <FlexItem textAlign="center">
                                <ConfrimModal
                                    onConfirm={() => onDelete(label.id)}
                                    description={t("delete_tag")}
                                    button={
                                        <Icon name="remove" style={{ marginRight: 0, color: "var(--danger)", cursor: "pointer" }} />
                                    }
                                />
                            </FlexItem>
                        </FlexRow>
                    </CanView>
                }
            </FlexRow>
        )
    }

    return (
        <Grid fluid >
            <Grid.Row>
                <Grid.Column>
                    <Header as='h2' style={{ marginBottom: 0}}>
                        {t("labels")}
                        <CanView permissions={["projects.c_manage_labels"]}>
                            <SuperDuperModal
                                size='small'
                                header={t("create_tag")}
                                trigger={
                                    <Button primary style={{ marginRight: 0, float: "right"}} size="tiny">
                                        <Icon name="plus" style={{ marginRight: 0 }} /> Create label
                                    </Button>
                                }
                                content={
                                    <LabelsForm onConfirm={(response) => onAdd(response)} />
                                }
                            />
                        </CanView>
                    </Header>   
                    <Divider /> 
                    {labels.map((label, index) =>
                        <LabelView 
                            key={label.id}
                            label={label}
                            onDelete={onDelete}
                            onUpdate={onUpdate}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default Labels;