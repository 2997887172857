import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Switch, Route, useHistory } from 'react-router-dom';
// store
import { routes } from '@routes';
import { icons } from '@icons';
// components
import CanView from '@components/perms/CanView';
import BreadcrumbNav from '@components/BreadcrumbNav';
// module specific components
import HiringRoomListView from './views/HiringRoomListView';
// import HiringRoomDetailView from './views/HiringRoomDetailView';
import HiringProcessesListView from './views/HiringProcessesListView';
import HiringRoomDetail from './views/HiringRoomDetail';


const HiringRooms = () => {
    const { t } = useTranslation();
    const history = useHistory()
    
    const [items, setItems] = useState([
        { 'name': t('hiring_rooms'), 'icon': icons.HIRING_ROOM }
    ])

    const isDetail = parseInt(window.location.pathname.replace(routes.HIRING_ROOMS, '')) > 0 ? true : false

    useEffect(() => {
        if( window.location.pathname === routes.HIRING ){
            history.push(routes.HIRING_ROOMS)
        } else if( window.location.pathname === routes.HIRING_PROCESSES ){
            setItems([
                { 'name': t('hiring_rooms'), 'icon': icons.HIRING_ROOM, 'href': routes.HIRING_ROOMS },
                { 'name': t('processes'), 'icon': icons.HIRING_PROCESSES }
            ])
        } else if( window.location.pathname === routes.HIRING_ROOMS ){
            setItems([
                { 'name': t('hiring_rooms'), 'icon': icons.HIRING_ROOM },
            ])
        } else if (isDetail) {
            setItems([
                { 'name': t('hiring_rooms'), 'icon': icons.HIRING_ROOM, 'href': routes.HIRING_ROOMS },
            ])
        }

        // eslint-disable-next-line
    }, [window.location.pathname])

    return (
        <CanView permissions={['candidates.c_view_recruitment']} redirect>
            <BreadcrumbNav items={items}/>
            <Switch>
                <Route exact path={routes.HIRING_ROOMS}>
                    <HiringRoomListView />
                </Route>

                <Route exact path={routes.HIRING_ROOMS + ":id"}>
                    {/* <HiringRoomDetailView /> */}
                    <HiringRoomDetail setItems={setItems}/>
                </Route>

                <Route exact path={routes.HIRING_PROCESSES}>
                    <HiringProcessesListView />
                </Route>
            </Switch>
        </CanView>
    );
};

export default HiringRooms;