import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Form, Divider, Button, Grid, Icon, Popup } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import NonFieldErrors from '@components/NonFieldErrors';

const handleCodeAutocomplete = (storedGroupedItem, length) => {
    let split_values = storedGroupedItem.code.split('-')
    let total_items = length
    let code = ""
    if (split_values.length > 1){
        let second_value = split_values?.[1] // expecting code value that should be increased
        if (second_value !== undefined && second_value !== ""){
            second_value = parseInt(second_value)
            code = second_value + total_items + 1
        }
    }

    if (code === "") {
        code = total_items + 1
    }

    return split_values[0] + " - " + code
}

const StoredItemGroupForm = ({ setResponse, onClose, setSize }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [parentItem, setParentItem] = useState(null)
    const [assignedItems, setAssignedItems] = useState([])
    const [items, setItems] = useState([])
    const [form, setForm] = useState({
        sn: null,
        quantity: 1,
        code: "",
        note: "",
        item: {
            title: "",
            description: "",
            is_vehicle: false,
            category: null,
        },
        warehouse: null
      })

    const handleSubmit = async () => {
        setErrors(null)
        setIsProcessing(true)

        if (parentItem === null) {
            const requestItem = await requests.post(API.ASSET_ITEMS, form.item)
            if (requestItem.status === 201) {
                let storedItem = form
                storedItem.item = requestItem.response.id
                const request = await requests.post(API.ASSET_STORED_ITEMS, storedItem)
                if (request.status === 201) {
                    // setResponse({
                    //     type: "stored_create",
                    //     result: request.response
                    // })
                    setParentItem(request.response)
                } else if (request.status === 400) {
                    setErrors(request.response)
                } 

            } else if (requestItem.status === 400) {
                await requests.del(API.ASSET_ITEMS + requestItem.response.id + "/")
                setErrors(requestItem.response)
            } 
        } else if (parentItem?.id !== undefined) {
            let total = 0
            let total_invalid = 0
            for (let i = 0; i < assignedItems.length; i++) {
                if ( assignedItems[i].item !== "") {
                    if (assignedItems[i].id === 0) { // create stored item
                        const request = await requests.post(API.ASSET_STORED_ITEMS, {
                            ...assignedItems[i],
                            group: parentItem.id,
                            warehouse: parentItem?.warehouse?.id || null,
                        })
    
                        if (request.status === 400) onItemChange(i, request.response, "errors")
                        if (request.status === 201) {
                            onItemChange(i, request.response.id, "id")
                            total += 1
                        }
                    } else { // update existing stored item
                        const request = await requests.put(API.ASSET_STORED_ITEMS + assignedItems[i].id + "/", {
                            ...assignedItems[i],
                            group: parentItem.id,
                            warehouse: parentItem?.warehouse?.id || null,
                        })
    
                        if (request.status === 400) onItemChange(i, request.response, "errors")
                        if (request.status === 200) {
                            total += 1
                        }
                    }
                } else {
                    total_invalid += 1
                }
            }

            if ((assignedItems.length - total_invalid) === total){
                const getParentItem = await requests.get(API.ASSET_STORED_ITEMS + parentItem.id + "/")
                if (getParentItem.status === 200) {
                    setResponse({
                        type: "stored_create",
                        result: getParentItem.response
                    })
                }
                onClose()
            }
        }

        setIsProcessing(false)
    }

    const addItem = () => {
        setAssignedItems(prev => ([...prev, {
            id: 0, 
            code: handleCodeAutocomplete(parentItem, prev.length),
            item: "",
            sn: null,
            quantity: 1,
            note: "",
            errors: null,
            loading: false,
        }]))
    }

    const onItemChange = (index, value, attribute) => {
        setAssignedItems(prev => prev.filter((item, idx) => {
            if (index === idx) item[attribute] = value
            return item
        }))
    }

    const isValidForm = () => {
        if (parentItem === null) {
            if (form.item.title === "") return false
            if (form.code === "") return false
            if (form.warehouse === null) return false
            return true
        } else {
            
            return true
        }
    }

    const fetchItems = async () => {
        const request = await requests.get(API.ASSET_ITEMS + "?query={id, title}")

        if (request.status === 200) {
            setItems(request.response.map(item => ({ key: item.id, value: item.id, text: item.title})))
        }
    }

    useEffect( () => {
        if( parentItem !== null ){
            setSize("large")
            setAssignedItems(prev => ([{
                id: 0,
                code: handleCodeAutocomplete(parentItem, prev.length),
                item: "",
                sn: null,
                quantity: 1,
                note: "",
                errors: null,
                loading: false,
            }]))

            fetchItems()
        } else {
            setSize("small")
        }
        // eslint-disable-next-line
    }, [parentItem])

    return (
        <Form onSubmit={handleSubmit}>
            <p style={{ marginTop: 0, textAlign: 'left' }}>
                { parentItem === null && 
                    <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "1.2rem" }}> 
                        { t('create_grouped_item_and_assign_existing_hint_message') } 
                    </div>
                }
                { parentItem?.id !== undefined &&
                    <Grid>
                        <Grid.Row columns="4">
                            <Grid.Column>
                                <div style={{ fontSize: "1.2rem" }}><strong>{ t('code') }:</strong></div>
                                <div>{ parentItem.code }</div>
                            </Grid.Column>
                            <Grid.Column>
                                <div style={{ fontSize: "1.2rem" }}><strong>{ t('title') }:</strong></div>
                                <div>{ parentItem.item.title }</div>
                            </Grid.Column>
                            <Grid.Column>
                                <div style={{ fontSize: "1.2rem" }}><strong>{ t('category') }:</strong></div>
                                <div>{ parentItem.item.category?.title || "--" }</div>
                            </Grid.Column>
                            <Grid.Column>
                                <div style={{ fontSize: "1.2rem" }}><strong>{ t('warehouse') }:</strong></div>
                                <div>{ parentItem.warehouse.title }</div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                }
            </p>
            <Divider/>

            <NonFieldErrors errors={errors}/>
            { parentItem === null && 
                <>
                    <Form.Group widths="equal">
                        <SuperField as="input"
                            autoFocus
                            required
                            label={t('title')}
                            error={errors?.item?.title || false}
                            value={ form.item.title }
                            onChange={ (e, { value }) => setForm(prev => ({
                                ...prev, item: { ...prev.item, title: value }
                            }))}
                        />
                        <SuperField as="choice"
                            required
                            search
                            label={t('warehouse')}
                            endpoint={API.ASSET_WAREHOUSES + "?query={id, title}"}
                            text="title"
                            error={errors?.warehouse || false}
                            value={ form.warehouse }
                            onChange={ (e, { value }) => setForm(prev => ({
                                ...prev, warehouse: value
                            }))}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField as="input"
                            required
                            label={t('code')}
                            error={errors?.code || false}
                            value={ form.code }
                            onChange={ (e, { value }) => setForm(prev => ({
                                ...prev, code: value
                            }))}
                        />
                        <SuperField as="input"
                            label={t('serial_number')}
                            value={ form.sn }
                            error={errors?.sn || false}
                            onChange={ (e, { value }) => setForm(prev => ({
                                ...prev, sn: (value !== "" ? value : null)
                            }))}
                        />
                    </Form.Group>
                    <SuperField as="choice"
                        search
                        label={t('category')}
                        endpoint={API.ASSET_CATEGORIES + "?query={id, title}"}
                        text="title"
                        value={ form.item.category }
                        error={errors?.item?.category || false}
                        onChange={ (e, { value }) => setForm(prev => ({
                            ...prev, item: { ...prev.item, category: value }
                        }))}
                    />
                    <SuperField as="textarea"
                        label={t('note')}
                        value={ form.note }
                        error={errors?.note || false}
                        onChange={ (e, { value }) => setForm(prev => ({
                            ...prev, note: value
                        }))}
                    />
                </>
            }

            { parentItem?.id !== undefined && 
            <>
                <Form.Group size="small" widths="equal">
                    <Form.Field style={{ fontWeight: 'bold' }} width="2">#</Form.Field>
                    <Form.Field style={{ fontWeight: 'bold' }}>{t('code')}</Form.Field>
                    <Form.Field style={{ fontWeight: 'bold' }}>{t('item')}</Form.Field>
                    <Form.Field style={{ fontWeight: 'bold' }}>{t('serial_number')}</Form.Field>
                    <Form.Field style={{ fontWeight: 'bold' }}>{t('quantity')}</Form.Field>
                    <Form.Field style={{ fontWeight: 'bold' }}>{t('note')}</Form.Field>
                </Form.Group>

                { assignedItems.map((item, index) => (
                    <>
                        <Form.Group size="small" widths="equal" key={index}>
                            <Form.Field style={{ fontWeight: 'bold' }} width="2">
                                { item.errors !== null || item.errors?.non_field_errors?.length > 0 
                                    ? <Popup basic trigger={<Icon name="warning circle" style={{ color: "var(--danger)", marginTop: "0.7rem", cursor: "pointer" }}/>} content={<div>{ item.errors?.non_field_errors?.map(err => err) }<br/></div>}/>  
                                    : item.loading ? <Icon name="spinner" loading={item.loading} style={{ marginTop: "0.7rem" }}/> : <div style={{ marginTop: "0.7rem" }}>{ (index + 1) + "." }</div>
                                } 
                            </Form.Field>
                            <SuperField as="input" placeholder={t('code')} value={item.code} onChange={(e, { value }) => onItemChange(index, value, "code")} error={item.errors?.code || false}/>
                            <SuperField as="choice" search customOptions={items} placeholder={t('item')} value={item.item} onChange={(e, { value }) => onItemChange(index, value, "item")} error={item.errors?.item || false}/>
                            <SuperField as="input" placeholder={t('serial_number')} value={item.sn} onChange={(e, { value }) => onItemChange(index, (value !== "" ? value : null), "sn")} error={item.errors?.sn || false}/>
                            <SuperField as="input" type="number" placeholder={t('quantity')} value={item.quantity} onChange={(e, { value }) => onItemChange(index, value, "quantity")} error={item.errors?.quantity || false}/>
                            <SuperField as="input" placeholder={t('note')} value={item.note} onChange={(e, { value }) => onItemChange(index, value, "note")} error={item.errors?.note || false}/>
                        </Form.Group>
                    </>
                ))}
                <Divider/>
                <Button type="button" size="small" primary content={t('add_item')} onClick={() => addItem()}/>
            </>
            }

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit 
                    content={t('confirm')}
                    disabled={isProcessing || !isValidForm()}
                    loading={isProcessing}
                />
            </Form.Field>
        </Form>
    );
};

export default StoredItemGroupForm;