const imports = [
    {
        type: 'employees',
        permissions: ['common.c_manage_all_employees'],
        schemas: [
            {
                id: 'import-employees',
                title: 'import_employees',
                config: {
                    headers: [
                        {
                            title: 'import_id',
                            required: 'true',
                        },
                        {
                            title: 'firstname',
                            required: 'true',
                        },
                        {
                            title: 'lastname',
                            required: 'true',
                        },
                        {
                            title: 'gender',
                            required: 'true',
                            dataType: 'number',
                        },
                        { title: 'middlename' },
                        { title: 'titles_before' },
                        { title: 'titles_after' },
                        { title: 'unit' },
                        { title: 'personal_number' },
                        { title: 'id_card_number' },
                        { title: 'marital_status', dataType: 'number' },
                        { title: 'date_of_birth', dataType: 'date' },
                        { title: 'gdpr_valid_until', dataType: 'date' },
                        { title: 'number_of_children', dataType: 'number' },
                        { title: 'name_at_birth' },
                        { title: 'phone', dataType: 'phone' },
                        { title: 'email', dataType: 'email' },
                        { title: 'place_of_birth' },
                        { title: 'country_of_birth' },
                        { title: 'citizenship' },
                        { title: 'nationality' },
                        { title: 'permanent_address_street' },
                        { title: 'permanent_address_city' },
                        { title: 'permanent_address_postcode' },
                        { title: 'permanent_address_country' },
                        { title: 'permanent_address_state' },
                        { title: 'permanent_address_address_line' },
                        { title: 'permanent_address_region' },
                        { title: 'permanent_address_lat' },
                        { title: 'permanent_address_long' },
                        { title: 'temporary_address_street' },
                        { title: 'temporary_address_city' },
                        { title: 'temporary_address_postcode' },
                        { title: 'temporary_address_country' },
                        { title: 'temporary_address_state' },
                        { title: 'temporary_address_address_line' },
                        { title: 'temporary_address_region' },
                        { title: 'temporary_address_lat' },
                        { title: 'temporary_address_long' },
                        { title: 'iban' },
                        { title: 'last_job' },
                        { title: 'is_external', type: 'checkbox', valueVariable: 'checked' },
                        { title: 'is_employed_by_agencies', type: 'checkbox', valueVariable: 'checked' },
                        { title: 'handicap_level', dataType: 'number' },
                        { title: 'last_country_of_residence' },
                        { title: 'acting_by' },
                        { title: 'is_foreigner', type: 'checkbox', valueVariable: 'checked' },
                        { title: 'driver_licences' },
                        { title: 'health_insurance' },
                        { title: 'health_insurance_code' },
                        { title: 'tags' },
                        { title: 'skills' },
                        { title: 'workplace_country' },
                        { title: 'business_name' },
                        { title: 'identification_number' },
                        { title: 'dit_number' },
                        { title: 'vat_number' },
                        { title: 'business_register_folder' },
                        { title: 'nace_code' },
                        { title: 'billing_address_street' },
                        { title: 'billing_address_city' },
                        { title: 'billing_address_postcode' },
                        { title: 'billing_address_country' },
                        { title: 'billing_address_state' },
                        { title: 'billing_address_address_line' },
                        { title: 'interests' },
                        { title: 'shirt_size' },
                        { title: 'trousers_size' },
                        { title: 'shoe_size' },
                        { title: 'jacket_size' },
                        { title: 'emergency_name' },
                        { title: 'emergency_text' },
                        { title: 'emergency_phone', dataType: 'phone' },
                        { title: 'social_number' },
                        { title: 'insurance_number' },
                    ],
                    endpoint: 'employees/',
                    source: 'EMPLYOEES_LIST',
                    permissions: ['common.c_manage_all_employees'],
                },
            },
            {
                id: 'import-employees-contracts',
                title: 'import_employees_contracts',
                config: {
                    headers: [
                        { title: 'import_id', required: 'true' },
                        { title: 'firstname', required: 'true' },
                        { title: 'lastname', required: 'true' },
                        { title: 'gender', required: 'true', dataType: 'number' },
                        { title: 'date_of_signature', dataType: 'date', required: 'true' },
                        { title: 'employer_name', required: 'true' },
                        { title: 'employer_identification_number', required: 'true' },
                        { title: 'middlename' },
                        { title: 'titles_before' },
                        { title: 'titles_after' },
                        { title: 'unit' },
                        { title: 'personal_number' },
                        { title: 'id_card_number' },
                        { title: 'marital_status' },
                        { title: 'date_of_birth', dataType: 'date' },
                        { title: 'gdpr_valid_until', dataType: 'date' },
                        { title: 'number_of_children', dataType: 'number' },
                        { title: 'name_at_birth' },
                        { title: 'phone', dataType: 'phone' },
                        { title: 'email', dataType: 'email' },
                        { title: 'place_of_birth' },
                        { title: 'country_of_birth' },
                        { title: 'citizenship' },
                        { title: 'nationality' },
                        { title: 'permanent_address_street' },
                        { title: 'permanent_address_city' },
                        { title: 'permanent_address_postcode' },
                        { title: 'permanent_address_country' },
                        { title: 'permanent_address_state' },
                        { title: 'permanent_address_address_line' },
                        { title: 'permanent_address_region' },
                        { title: 'permanent_address_lat' },
                        { title: 'permanent_address_long' },
                        { title: 'temporary_address_street' },
                        { title: 'temporary_address_city' },
                        { title: 'temporary_address_postcode' },
                        { title: 'temporary_address_country' },
                        { title: 'temporary_address_state' },
                        { title: 'temporary_address_address_line' },
                        { title: 'temporary_address_region' },
                        { title: 'temporary_address_lat' },
                        { title: 'temporary_address_long' },
                        { title: 'iban' },
                        { title: 'last_job' },
                        { title: 'is_external', type: 'checkbox', valueVariable: 'checked' },
                        { title: 'is_employed_by_agencies', type: 'checkbox', valueVariable: 'checked' },
                        { title: 'handicap_level', dataType: 'number' },
                        { title: 'last_country_of_residence' },
                        { title: 'acting_by' },
                        { title: 'is_foreigner', type: 'checkbox', valueVariable: 'checked' },
                        { title: 'driver_licences' },
                        { title: 'health_insurance' },
                        { title: 'health_insurance_code' },
                        { title: 'tags' },
                        { title: 'skills' },
                        { title: 'workplace_country' },
                        { title: 'business_name' },
                        { title: 'identification_number' },
                        { title: 'dit_number' },
                        { title: 'vat_number' },
                        { title: 'business_register_folder' },
                        { title: 'nace_code' },
                        { title: 'billing_address_street' },
                        { title: 'billing_address_city' },
                        { title: 'billing_address_postcode' },
                        { title: 'billing_address_country' },
                        { title: 'billing_address_state' },
                        { title: 'billing_address_address_line' },
                        { title: 'interests' },
                        { title: 'shirt_size' },
                        { title: 'trousers_size' },
                        { title: 'shoe_size' },
                        { title: 'jacket_size' },
                        { title: 'emergency_name' },
                        { title: 'emergency_text' },
                        { title: 'emergency_phone', dataType: 'phone' },
                        { title: 'social_number' },
                        { title: 'insurance_number' },
                        { title: 'type' },
                        { title: 'salary', dataType: 'floatNumber' },
                        { title: 'salary_per_unit' },
                        { title: 'salary_currency' },
                        { title: 'work_position' },
                        { title: 'workplace_address_street' },
                        { title: 'workplace_address_city' },
                        { title: 'workplace_address_postcode' },
                        { title: 'workplace_address_country' },
                        { title: 'workplace_address_state' },
                        { title: 'workplace_address_address_line' },
                        { title: 'valid_from', dataType: 'date' },
                        { title: 'valid_until', dataType: 'date' },
                        { title: 'fond', dataType: 'number' },
                        { title: 'work_level', dataType: 'number' },
                        { title: 'is_working_shifts', type: 'checkbox', valueVariable: 'checked' },
                    ],
                    endpoint: 'employees/?include_contracts=true',
                    source: 'EMPLYOEES_LIST',
                    permissions: ['contracts.c_manage_all_contracts'],
                },
            },
        ],
    },
    {
        type: 'candidates',
        permissions: ['common.c_manage_all_candidates'],
        schemas: [
            {
                id: 'import-candidates',
                title: 'import_candidates',
                config: {
                    headers: [
                        { title: 'import_id', required: 'true' },
                        { title: 'firstname', required: 'true' },
                        { title: 'lastname', required: 'true' },
                        { title: 'gender', required: 'true', dataType: 'number' },
                        { title: 'middlename' },
                        { title: 'titles_before' },
                        { title: 'titles_after' },
                        { title: 'unit' },
                        { title: 'personal_number' },
                        { title: 'id_card_number' },
                        { title: 'marital_status' },
                        { title: 'date_of_birth', dataType: 'date' },
                        { title: 'gdpr_valid_until', dataType: 'date' },
                        { title: 'number_of_children', dataType: 'number' },
                        { title: 'name_at_birth' },
                        { title: 'phone', dataType: 'phone' },
                        { title: 'email', dataType: 'email' },
                        { title: 'place_of_birth' },
                        { title: 'country_of_birth' },
                        { title: 'citizenship' },
                        { title: 'nationality' },
                        { title: 'permanent_address_street' },
                        { title: 'permanent_address_city' },
                        { title: 'permanent_address_postcode' },
                        { title: 'permanent_address_country' },
                        { title: 'permanent_address_state' },
                        { title: 'permanent_address_address_line' },
                        { title: 'permanent_address_region' },
                        { title: 'permanent_address_lat' },
                        { title: 'permanent_address_long' },
                        { title: 'temporary_address_street' },
                        { title: 'temporary_address_city' },
                        { title: 'temporary_address_postcode' },
                        { title: 'temporary_address_country' },
                        { title: 'temporary_address_state' },
                        { title: 'temporary_address_address_line' },
                        { title: 'temporary_address_region' },
                        { title: 'temporary_address_lat' },
                        { title: 'temporary_address_long' },
                        { title: 'iban' },
                        { title: 'last_job' },
                        { title: 'is_external', type: 'checkbox', valueVariable: 'checked' },
                        { title: 'is_employed_by_agencies', type: 'checkbox', valueVariable: 'checked' },
                        { title: 'handicap_level', dataType: 'number' },
                        { title: 'last_country_of_residence' },
                        { title: 'acting_by' },
                        { title: 'is_foreigner', type: 'checkbox', valueVariable: 'checked' },
                        { title: 'driver_licences' },
                        { title: 'health_insurance' },
                        { title: 'health_insurance_code' },
                        { title: 'tags' },
                        { title: 'skills' },
                        { title: 'workplace_country' },
                        { title: 'business_name' },
                        { title: 'identification_number' },
                        { title: 'dit_number' },
                        { title: 'vat_number' },
                        { title: 'business_register_folder' },
                        { title: 'nace_code' },
                        { title: 'billing_address_street' },
                        { title: 'billing_address_city' },
                        { title: 'billing_address_postcode' },
                        { title: 'billing_address_country' },
                        { title: 'billing_address_state' },
                        { title: 'billing_address_address_line' },
                        { title: 'interests' },
                        { title: 'shirt_size' },
                        { title: 'trousers_size' },
                        { title: 'shoe_size' },
                        { title: 'jacket_size' },
                        { title: 'emergency_name' },
                        { title: 'emergency_text' },
                        { title: 'emergency_phone', dataType: 'phone' },
                        { title: 'social_number' },
                        { title: 'insurance_number' },
                    ],
                    endpoint: 'candidates/',
                    source: 'CANDIDATES_LIST',
                    permissions: ['common.c_manage_all_candidates'],
                },
            },
        ],
    },
    // {
    //     type: 'leads_&_opportunities',
    //     permissions: [],
    //     schemas: [
    //         {
    //             id: 'import-leads',
    //             title: 'import_leads_&_opportunities',
    //             config: {
    //                 headers: [
    //                     { title: 'import_id', required: 'true' },
    //                     { title: 'firstname', required: 'true' },
    //                     { title: 'lastname', required: 'true' },
    //                     { title: 'company_name' },
    //                     { title: 'company_id' },
    //                     { title: 'owner_id' },
    //                     { title: 'assigned_to' },
    //                     { title: 'email', dataType: 'email' },
    //                     { title: 'phone', dataType: 'phone' },
    //                     { title: 'title' },
    //                     { title: 'notes' },
    //                     { title: 'description' },
    //                     { title: 'source' },
    //                     { title: 'tags' },
    //                     { title: 'state', dataType: 'number' },
    //                     { title: 'stage', dataType: 'number' },
    //                     { title: 'unit' },
    //                     { title: 'is_opportunity', type: 'checkbox', valueVariable: 'checked' },
    //                     { title: 'last_contacted' },
    //                 ],
    //                 endpoint: 'leads/',
    //                 source: 'LEADS',
    //                 permissions: [],
    //             },
    //         },
    //     ],
    // },
    {
        type: 'cost_center_records',
        permissions: ['cost_centers.c_manage_incomes_expenses'],
        schemas: [
            {
                id: 'import-cost-center-records',
                title: 'import_cost_center_records',
                sheetName: 'Records',
                config: {
                    headers: [
                        { title: 'date', required: 'true', dateType: 'date' },
                        { title: 'category', required: 'true' },
                        { title: 'cost_center', required: 'true' },
                        { title: 'amount', required: 'true', dataType: 'floatNumber' },
                        { title: 'currency', required: 'true' },
                        { title: 'employee_id' },
                        { title: 'employee_name' },
                        { title: 'account' },
                        { title: 'identification_number' },
                        { title: 'note' },
                    ],
                    endpoint: 'cost_center_records/',
                    source: 'COST_CENTERS',
                    permissions: [],
                },
            },
        ],
    },
    {
        type: 'accounts_and_suppliers',
        permissions: ['accounts.c_manage_all_accounts'],
        schemas: [
            {
                id: 'import-accounts-and-suppliers',
                title: 'import_accounts_and_suppliers',
                sheetName: 'Accounts',
                config: {
                    headers: [
                        { title: 'name', required: 'true' },
                        { title: 'identification_number', required: 'true' },
                        { title: 'dit_number' },
                        { title: 'vat_number' },
                        { title: 'business_register_folder' },
                        { title: 'nace_code' },
                        { title: 'iban' },
                        { title: 'is_supplier', type: 'checkbox', valueVariable: 'checked' },
                        { title: 'is_account', type: 'checkbox', valueVariable: 'checked' },
                        { title: 'is_freelancer', type: 'checkbox', valueVariable: 'checked' },
                        { title: 'is_non_business_client', type: 'checkbox', valueVariable: 'checked' },
                        { title: 'description' },
                        { title: 'billing_address_street' },
                        { title: 'billing_address_city' },
                        { title: 'billing_address_postcode' },
                        { title: 'billing_address_country' },
                        { title: 'billing_address_state' },
                        { title: 'billing_address_address_line' },
                        { title: 'shipping_address_street' },
                        { title: 'shipping_address_city' },
                        { title: 'shipping_address_postcode' },
                        { title: 'shipping_address_country' },
                        { title: 'shipping_address_state' },
                        { title: 'shipping_address_address_line' },
                        { title: 'tags' },
                        { title: 'unit' },
                    ],
                    endpoint: 'account_business_detail/',
                    source: 'ACCOUNTS',
                    permissions: ['accounts.c_manage_all_accounts'],
                },
            },
        ],
    },
    {
        type: 'timesheet_records',
        permissions: ['timesheets.c_manage_all_records'],
        schemas: [
            {
                id: 'import-timesheet-records',
                title: 'import_timesheet_records',
                sheetName: 'Timesheet records',
                config: {
                    headers: [
                        { title: 'employee_id', required: 'true' },
                        { title: 'date', required: 'true', dateType: 'date' },
                        { title: 'activity', required: 'true' },
                        { title: 'hours', required: 'true', dateType: 'floatNumber' },
                        { title: 'piece_work_count', dateType: 'number' },
                        { title: 'datetime_from', dateType: 'datetime' },
                        { title: 'datetime_to', dateType: 'datetime' },
                        { title: 'project' },
                        { title: 'milestone' },
                        { title: 'note' },
                    ],
                    endpoint: 'timesheets/',
                    source: 'TIMESHEETS',
                    permissions: ['timesheets.c_manage_all_records'],
                },
            },
        ],
    },
]

export default imports
