import React from 'react';
import * as icons from "react-icons/io5";

const Icon = ({ name, ...rest }) => {
    /*
        ## This is universal component for react-icons datasets. We are currently using only this one:
        https://react-icons.github.io/react-icons/icons?name=io5

        #!! Make sure to use `name` as property for icon name with syntax: 'book-outline' (all lowercase and separated with dashes)

        ## Other examples based on provided link above:
        - 'IoBookmarkOutline' will be set as 'bookmark-outline' make sure to avoid using 'Io' as prefix!!!!!
        - 'IoBuildSharp' will be set as 'build-sharp' make sure to avoid using 'Io' as prefix!!!!!
        - 'IoBulb' will be set as 'bulb' make sure to avoid using 'Io' as prefix!!!!!

        ## Usage example:

        <Icon name="bookmark-outline"/ style={{ fontSize: "5rem", color: "var(--dark)" }}/>
        <Icon name="build-sharp" style={{ color: "var(--danger)" }}/>
        <Icon name="bulb" style={{ fontWeight: "bold" }}/>
    */

    const splitName = name.split('-')

    let iconName = splitName[0].charAt(0).toUpperCase() + splitName[0].slice(1)
    if (splitName.length > 1) {
        iconName = ""
        for (let i = 0; i < splitName.length; i++) {
            iconName += splitName[i].charAt(0).toUpperCase() + splitName[i].slice(1);
        }
    }

    const fullIconName = "Io" + iconName
    const Component = icons?.[fullIconName]

    if (icons?.[fullIconName] !== undefined){
        return (
            <Component {...rest}/>
        )
    }
    
    return (<></>)
    
};

export default Icon;