import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { thousandsSeparators } from '@helpers/functions'
// components
import Icon from '@components/Icon'
import { Container, Card, Segment } from 'semantic-ui-react'

const StatCard = ({ color, borderColor, isCustomColor, number, description, icon, onClick }) => {
    return (
        <Card
            style={
                isCustomColor
                    ? {
                          borderRadius: 0,
                          boxShadow: `0 0 0 1px #d4d4d5,0 2px 0 0 ${borderColor},0 1px 3px 0 #d4d4d5`,
                          background: color,
                      }
                    : { borderRadius: 0 }
            }
            color={isCustomColor ? undefined : color}
            onClick={onClick}
        >
            <Card.Content>
                <div style={{ display: 'flex', marginBottom: '2rem' }}>
                    <div
                        style={{
                            textAlign: 'left',
                            fontWeight: 'bold',
                            width: '70%',
                            fontSize: '1.15rem',
                            paddingTop: '0.25rem',
                            color: 'var(--dark)',
                        }}
                    >
                        {number}
                    </div>
                    <div style={{ textAlign: 'right', width: '30%' }}>
                        <Icon
                            name={icon}
                            style={{ fontSize: '2rem', fontWeight: 'bold', textAlign: 'left', color: 'var(--dark)' }}
                        />
                    </div>
                </div>

                <div
                    style={{
                        position: 'absolute',
                        bottom: '1rem',
                        marginTop: '1rem',
                        textTransform: 'uppercase',
                        color: 'var(--dark)',
                        opacity: '0.8',
                    }}
                >
                    {description}
                </div>
            </Card.Content>
        </Card>
    )
}

const ListStatsView = ({ filters, data, total, setFilters, params, connection, journal, type, fetchData }) => {
    const { t } = useTranslation()

    const colors = useSelector((state) => state.global_pref.colors)

    // eslint-disable-next-line
    const [loading, setLoading] = useState(true)
    // eslint-disable-next-line
    const [stats, setStats] = useState({})

    const currency = filters?.currency || 'all'

    useEffect(() => {
        async function fetchStats() {
            setLoading(true)
            const request = await requests.get(
                API.INVOICES +
                    'invoice_statistics/?connection=' +
                    connection.connection_id +
                    '&account_ids=' +
                    journal.id +
                    '&is_issued=' +
                    (type === 'issued' ? true : false) +
                    params
            )

            if (request.status === 200) {
                setStats(request.response)
            }
            setLoading(false)
        }

        fetchStats()
        // eslint-disable-next-line
    }, [])

    const getTotals = (type) => {
        if (loading) return <StatNumber number={0} />
        if (stats?.length === 0) return <StatNumber number={0 + ' EUR'} />
        if (!Array.isArray(stats) && currency === 'all') return <StatNumber number={0 + ' EUR'} />

        if (currency !== 'all') {
            if (type === 'all')
                return (
                    <StatNumber number={(parseFloat(stats?.all || 0).toFixed(2) || 0) + ' ' + stats?.currency || ''} />
                )
            if (type === 'draft')
                return (
                    <StatNumber
                        number={(parseFloat(stats?.draft || 0).toFixed(2) || 0) + ' ' + stats?.currency || ''}
                    />
                )
            if (type === 'outstanding')
                return (
                    <StatNumber
                        number={(parseFloat(stats?.outstanding || 0).toFixed(2) || 0) + ' ' + stats?.currency || ''}
                    />
                )
            if (type === 'past_due')
                return (
                    <StatNumber
                        number={(parseFloat(stats?.past_due || 0).toFixed(2) || 0) + ' ' + stats?.currency || ''}
                    />
                )
            if (type === 'paid')
                return (
                    <StatNumber number={(parseFloat(stats?.paid || 0).toFixed(2) || 0) + ' ' + stats?.currency || ''} />
                )
        } else {
            if (type === 'all')
                return (
                    <>
                        {stats?.map((stat, index) => (
                            <StatNumber
                                key={index}
                                number={(parseFloat(stat.all).toFixed(2) || 0) + ' ' + stat.currency || ''}
                            />
                        ))}
                    </>
                )

            if (type === 'draft')
                return (
                    <>
                        {stats?.map((stat, index) => (
                            <StatNumber
                                key={index}
                                number={(parseFloat(stat.draft).toFixed(2) || 0) + ' ' + stat.currency || ''}
                            />
                        ))}
                    </>
                )

            if (type === 'outstanding')
                return (
                    <>
                        {stats?.map((stat, index) => (
                            <StatNumber
                                key={index}
                                number={(parseFloat(stat.outstanding).toFixed(2) || 0) + ' ' + stat.currency || ''}
                            />
                        ))}
                    </>
                )

            if (type === 'past_due')
                return (
                    <>
                        {stats?.map((stat, index) => (
                            <StatNumber
                                key={index}
                                number={(parseFloat(stat.past_due).toFixed(2) || 0) + ' ' + stat.currency || ''}
                            />
                        ))}
                    </>
                )

            if (type === 'paid')
                return (
                    <>
                        {stats?.map((stat, index) => (
                            <StatNumber
                                key={index}
                                number={(parseFloat(stat.paid).toFixed(2) || 0) + ' ' + stat.currency || ''}
                            />
                        ))}
                    </>
                )
        }
    }

    const getCounts = (type) => {
        if (loading) return 0
        if (stats?.length === 0) return 0
        if (!Array.isArray(stats) && currency === 'all') return 0

        if (currency !== 'all') {
            if (type === 'all') return stats?.all_count || 0
            if (type === 'draft') return stats?.draft_count || 0
            if (type === 'outstanding') return stats?.outstanding_count || 0
            if (type === 'past_due') return stats?.past_due_count || 0
            if (type === 'paid') return stats?.paid_count || 0
        } else {
            if (type === 'all') return stats?.map((stat) => stat.all_count).reduce((partialSum, a) => partialSum + a, 0)
            if (type === 'draft')
                return stats?.map((stat) => stat.draft_count).reduce((partialSum, a) => partialSum + a, 0)
            if (type === 'outstanding')
                return stats?.map((stat) => stat.outstanding_count).reduce((partialSum, a) => partialSum + a, 0)
            if (type === 'past_due')
                return stats?.map((stat) => stat.past_due_count).reduce((partialSum, a) => partialSum + a, 0)
            if (type === 'paid')
                return stats?.map((stat) => stat.paid_count).reduce((partialSum, a) => partialSum + a, 0)
        }

        return 0
    }

    const StatNumber = ({ number }) => {
        return (
            <>
                {thousandsSeparators(number)} <br />
            </>
        )
    }

    const renderOnClick = async (status) => {
        setFilters((prev) => ({ ...prev, status: status }))
        await fetchData('&status=' + status, true)
    }

    return (
        <Container fluid>
            <Segment
                loading={loading}
                style={{
                    padding: 0,
                    margin: 0,
                    backgroundColor: 'transparent',
                    minHeight: '5rem',
                    border: 'none',
                    boxShadow: 'none',
                    marginBottom: '1rem',
                }}
            >
                <Card.Group stackable doubling itemsPerRow={5}>
                    <StatCard
                        isCustomColor
                        color={colors.dark + '26'}
                        borderColor={colors.dark}
                        number={getTotals('all')}
                        icon="document-text-outline"
                        description={t('all') + ' - ' + getCounts('all')}
                        onClick={() => renderOnClick('')}
                    />
                    <StatCard
                        isCustomColor
                        color={'#0101f833'}
                        borderColor={'#0101f8'}
                        number={getTotals('draft')}
                        icon="document-outline"
                        description={t('drafts') + ' - ' + getCounts('draft')}
                        onClick={() => renderOnClick('4')}
                    />
                    <StatCard
                        isCustomColor
                        color={'#faa30233'}
                        borderColor={'#faa302'}
                        number={getTotals('outstanding')}
                        icon="alert-circle-outline"
                        description={t('outstanding') + ' - ' + getCounts('outstanding')}
                        onClick={() => renderOnClick('1')}
                    />
                    <StatCard
                        isCustomColor
                        color={'#db282833'}
                        borderColor={'#db2828'}
                        number={getTotals('past_due')}
                        icon="warning-outline"
                        description={t('past_due') + ' - ' + getCounts('past_due')}
                        onClick={() =>
                            renderOnClick('1&date_due_before=' + moment().add('-1', 'days').format('YYYY-MM-DD'))
                        }
                    />
                    <StatCard
                        isCustomColor
                        color={'#21ba4533'}
                        borderColor={'#21ba45'}
                        number={getTotals('paid')}
                        icon="checkmark-outline"
                        description={t('paid') + ' - ' + getCounts('paid')}
                        onClick={() => renderOnClick('3')}
                    />
                </Card.Group>
            </Segment>
        </Container>
    )
}

export default ListStatsView
