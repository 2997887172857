import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import { useSelector } from 'react-redux'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { useHasPermissions } from '@helpers/hooks'
import { tzDateTime } from '@helpers/dates'
// components
import Icon from '@components/Icon'
import { Form, Divider, Button, Card } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import DocPreview from '@components/DocPreview'

const InsuranceRegistrationForm = ({ onClose, contract, setData }) => {
    const { t } = useTranslation()
    const [view, setView] = useState(0) // social first
    const canManage = useHasPermissions(['contracts.c_manage_contract_registrations'])

    return (
        <>
            <Button.Group fluid basic>
                <Button
                    active={view === 0}
                    onClick={() => setView(0)}
                    style={{ fontWeight: view === 0 ? 'bold' : 'normal' }}
                >
                    {t('social_insurance_institution')}
                </Button>
                <Button
                    active={view === 1}
                    onClick={() => setView(1)}
                    style={{ fontWeight: view === 1 ? 'bold' : 'normal' }}
                >
                    {t('health_insurance_institution')}
                </Button>
            </Button.Group>
            <Divider />

            {view === 0 ? (
                <>
                    <RegistrationForm
                        key="social_reg"
                        type="social"
                        contract={contract}
                        onClose={onClose}
                        setData={setData}
                        canManage={canManage}
                    />
                    <DeregistrationForm
                        key="social_unreg"
                        isAllowed={contract?.social_registration?.registration_file}
                        type="social"
                        contract={contract}
                        onClose={onClose}
                        setData={setData}
                        canManage={canManage}
                    />
                </>
            ) : (
                <>
                    <RegistrationForm
                        key="health_reg"
                        type="health"
                        contract={contract}
                        onClose={onClose}
                        setData={setData}
                        canManage={canManage}
                    />
                    <DeregistrationForm
                        key="health_unreg"
                        isAllowed={contract?.insurance_registration?.registration_file}
                        type="health"
                        contract={contract}
                        onClose={onClose}
                        setData={setData}
                        canManage={canManage}
                    />
                </>
            )}
        </>
    )
}

const RegistrationForm = ({ type, onClose, contract, setData, canManage }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)
    const user = useSelector((state) => state.user)

    let insuranceRecord = type === 'social' ? contract.social_registration : contract.insurance_registration
    if (!insuranceRecord) {
        insuranceRecord = undefined
    }

    const [errors, setErrors] = useState(null)
    const [open, setOpen] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [form, setForm] = useState({
        id: insuranceRecord?.id || 0,
        // registration data
        registration_date: insuranceRecord?.registration_date
            ? moment(insuranceRecord?.registration_date).format('YYYY-MM-DD')
            : '',

        // dont touch
        is_social: type === 'social' ? true : false,
        is_insurance: type === 'health' ? true : false,
        contract: contract.id,
    })

    const [registrationFile, setRegistrationFile] = useState(null)

    const handleFileInputChange = (event) => {
        const selectedFiles = event.target.files
        const newFiles = Array.from(selectedFiles).filter((file) => file.size <= 2 * 1024 * 1024)

        if (newFiles.length > 0) {
            setRegistrationFile(newFiles[0])
        } else {
            toast({
                type: 'error',
                icon: 'warning circle',
                title: t('Please select files with a size of 2 MB or less.'),
                description: '',
                animation: 'bounce',
                time: 5000,
            })
        }
    }

    const isFormValid = () => {
        // if not exist
        if (form.registration_date === '' || registrationFile === null) return false

        return true
    }

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)

        let attachmentID = null

        const formData = new FormData()
        formData.append('files', registrationFile)

        const createAttachment = await requests.post(API.ATTACHMENTS_BULK, formData)
        if (createAttachment.status === 400) setErrors(createAttachment.response) // zisti jak funguju errory pre multiple
        if (createAttachment.status === 201) {
            attachmentID = createAttachment.response[0]?.id
        }

        if (attachmentID === null) {
            setProcessing(false)
            return
        }

        // override or create
        let request = null
        if (insuranceRecord?.id === undefined) {
            // post
            request = await requests.post(API.CONTRACTS + 'contract_registrations/', {
                is_active: true,
                contract: form.contract,
                is_social: form.is_social,
                is_insurance: form.is_insurance,
                registration_date: form.registration_date + ' 10:00:00',
                registration_file: attachmentID,
                registration_action_performed_by: user?.id || null,
                registration_action_performed_on: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
            })
        } else {
            // patch
            request = await requests.patch(API.CONTRACTS + 'contract_registrations/' + insuranceRecord?.id + '/', {
                registration_date: form.registration_date + ' 10:00:00',
                registration_file: attachmentID,
                is_active: true,
                registration_action_performed_by: user?.id || null,
                registration_action_performed_on: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
            })
        }

        if (request?.status === 200 || request?.status === 201) {
            setData((prev) =>
                prev.filter((item) => {
                    if (item.id === contract.id) {
                        if (type === 'social') {
                            item.social_registration = request?.response
                        } else {
                            item.insurance_registration = request?.response
                        }
                    }
                    return item
                })
            )
            setOpen(false)
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <h4>{t('insurance_registration')}</h4>

            <Card fluid>
                <Card.Content>
                    <div
                        onClick={
                            insuranceRecord?.is_active === true
                                ? undefined
                                : () => (canManage ? setOpen(!open) : undefined)
                        }
                        style={{
                            display: 'flex',
                            alignItems: insuranceRecord?.is_active === true ? 'flex-start' : 'center',
                            justifyContent: 'space-between',
                            cursor: insuranceRecord?.is_active === true ? 'default' : 'pointer',
                        }}
                    >
                        <div>
                            {insuranceRecord?.is_active === true ? (
                                <>
                                    <div style={{ fontSize: '1rem' }}>
                                        {t('insurance_registration_date')}:{' '}
                                        <strong>
                                            {tzDateTime(insuranceRecord?.registration_date).format(dateFormat)}
                                        </strong>
                                    </div>

                                    <div
                                        style={{ display: 'flex', gap: '5px', alignItems: 'center', fontSize: '1rem' }}
                                    >
                                        {t('confirmation_file')}:{' '}
                                        <SuperDuperModal
                                            size="small"
                                            trigger={
                                                <div
                                                    className="ref-link"
                                                    style={{
                                                        color: 'var(--primary)',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {t('show_preview')}
                                                </div>
                                            }
                                            content={<DocPreview uri={insuranceRecord?.registration_file.file} />}
                                        />
                                    </div>

                                    <div
                                        style={{
                                            opacity: 0.8,
                                            fontSize: '0.9rem',
                                            marginTop: '0.4rem',
                                            paddingTop: '0.5rem',
                                            borderTop: '1px solid var(--light)',
                                        }}
                                    >
                                        {t('recorded_by')}{' '}
                                        <strong>{insuranceRecord?.registration_action_performed_by?.name}</strong>{' '}
                                        {t('at')}{' '}
                                        <strong>
                                            {tzDateTime(insuranceRecord?.registration_action_performed_on).format(
                                                dateFormat + ' HH:mm'
                                            )}
                                        </strong>
                                    </div>
                                </>
                            ) : (
                                <strong style={{ color: 'var(--danger)', fontWeight: 'bold' }}>
                                    {t('not_registered_insurance')}
                                </strong>
                            )}
                        </div>
                        <div>
                            {canManage && (
                                <Icon
                                    style={{ cursor: 'pointer', fontSize: '1.2rem' }}
                                    onClick={canManage ? () => setOpen(!open) : undefined}
                                    name={
                                        insuranceRecord?.is_active === true
                                            ? 'pencil-outline'
                                            : open
                                            ? 'remove-outline'
                                            : 'add-outline'
                                    }
                                />
                            )}
                        </div>
                    </div>
                </Card.Content>
                {open && (
                    <Card.Content extra>
                        <Form.Group widths="equal">
                            <SuperField
                                as="datepicker"
                                required
                                label={t('insurance_registration_date')}
                                value={form.registration_date}
                                onChange={(e, { value }) => setForm((prev) => ({ ...prev, registration_date: value }))}
                            />
                        </Form.Group>

                        <SuperField
                            as="input"
                            type="file"
                            required
                            label={t('confirmation_file')}
                            onChange={(e) => handleFileInputChange(e)}
                            error={errors ? errors?.file?.[0] : false}
                        />
                        <Divider />
                        <Form.Field style={{ textAlign: 'right' }}>
                            <ModalCancel onClose={() => setOpen(false)} />
                            <ModalSubmit loading={processing} disabled={processing || !isFormValid()} />
                        </Form.Field>
                    </Card.Content>
                )}
            </Card>
        </Form>
    )
}

const DeregistrationForm = ({ isAllowed, type, onClose, contract, setData, canManage }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)
    const user = useSelector((state) => state.user)

    let insuranceRecord = type === 'social' ? contract.social_registration : contract.insurance_registration
    if (!insuranceRecord) {
        insuranceRecord = undefined
    }

    const [errors, setErrors] = useState(false)
    const [open, setOpen] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [form, setForm] = useState({
        id: insuranceRecord?.id || 0,
        deregistration_date: '',

        // dont touch
        is_social: type === 'social' ? true : false,
        is_insurance: type === 'health' ? true : false,
        contract: contract.id,
    })

    const [deregistrationFile, setDeregistrationFile] = useState(null)

    const handleFileInputChange = (event) => {
        const selectedFiles = event.target.files

        const newFiles = Array.from(selectedFiles).filter((file) => file.size <= 2 * 1024 * 1024)

        if (newFiles.length > 0) {
            setDeregistrationFile(newFiles[0])
        } else {
            toast({
                type: 'error',
                icon: 'warning circle',
                title: t('Please select files with a size of 2 MB or less.'),
                description: '',
                animation: 'bounce',
                time: 5000,
            })
        }
    }

    const isFormValid = () => {
        // if not exist
        if (form.deregistration_date === '' || deregistrationFile === null) return false

        return true
    }

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)

        if (insuranceRecord?.id !== undefined) {
            let attachmentID = null

            const formData = new FormData()
            formData.append('files', deregistrationFile)

            const createAttachment = await requests.post(API.ATTACHMENTS_BULK, formData)
            if (createAttachment.status === 400) setErrors(createAttachment.response) // zisti jak funguju errory pre multiple
            if (createAttachment.status === 201) {
                attachmentID = createAttachment.response[0]?.id
            }

            if (attachmentID === null) {
                setProcessing(false)
                return
            }

            // override
            let request = null
            request = await requests.patch(API.CONTRACTS + 'contract_registrations/' + insuranceRecord?.id + '/', {
                deregistration_date: form.deregistration_date + ' 10:00:00',
                deregistration_file: attachmentID,
                is_archived: true,
                deregistration_action_performed_by: user?.id || null,
                deregistration_action_performed_on: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
            })

            if (request?.status === 200) {
                setData((prev) =>
                    prev.filter((item) => {
                        if (item.id === contract.id) {
                            if (type === 'social') {
                                item.social_registration = request?.response
                            } else {
                                item.insurance_registration = request?.response
                            }
                        }
                        return item
                    })
                )
                setOpen(false)
            }
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit} style={{ marginTop: '1.5rem' }}>
            <h4>{t('insurance_unregistration')}</h4>

            {!isAllowed ? (
                <Card fluid>
                    <Card.Content style={{ fontWeight: 'bold' }}>{t('record_is_not_registered_yet')}</Card.Content>
                </Card>
            ) : (
                <Card fluid>
                    <Card.Content>
                        <div
                            onClick={
                                insuranceRecord?.is_archived === true
                                    ? undefined
                                    : canManage
                                    ? () => setOpen(!open)
                                    : undefined
                            }
                            style={{
                                display: 'flex',
                                alignItems: insuranceRecord?.is_archived === true ? 'flex-start' : 'center',
                                justifyContent: 'space-between',
                                cursor: insuranceRecord?.is_archived === true ? 'default' : 'pointer',
                            }}
                        >
                            <div>
                                {insuranceRecord?.is_archived === true ? (
                                    <>
                                        <div style={{ fontSize: '1rem' }}>
                                            {t('insurance_unregistration_date')}:{' '}
                                            <strong>
                                                {moment(insuranceRecord?.deregistration_date).format(dateFormat)}
                                            </strong>
                                        </div>

                                        <div
                                            style={{
                                                display: 'flex',
                                                gap: '5px',
                                                alignItems: 'center',
                                                fontSize: '1rem',
                                            }}
                                        >
                                            {t('confirmation_file')}:{' '}
                                            <SuperDuperModal
                                                size="small"
                                                trigger={
                                                    <div
                                                        className="ref-link"
                                                        style={{
                                                            color: 'var(--primary)',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {t('show_preview')}
                                                    </div>
                                                }
                                                content={<DocPreview uri={insuranceRecord?.deregistration_file.file} />}
                                            />
                                        </div>

                                        <div
                                            style={{
                                                opacity: 0.8,
                                                fontSize: '0.9rem',
                                                marginTop: '0.4rem',
                                                paddingTop: '0.5rem',
                                                borderTop: '1px solid var(--light)',
                                            }}
                                        >
                                            {t('recorded_by')}{' '}
                                            <strong>{insuranceRecord?.deregistration_action_performed_by?.name}</strong>{' '}
                                            {t('at')}{' '}
                                            <strong>
                                                {tzDateTime(insuranceRecord?.deregistration_action_performed_on).format(
                                                    dateFormat + ' HH:mm'
                                                )}
                                            </strong>
                                        </div>
                                    </>
                                ) : (
                                    <strong style={{ color: 'var(--danger)', fontWeight: 'bold' }}>
                                        {t('not_unregistered_insurance')}
                                    </strong>
                                )}
                            </div>
                            <div>
                                {canManage && (
                                    <Icon
                                        style={{ cursor: 'pointer', fontSize: '1.2rem' }}
                                        onClick={canManage ? () => setOpen(!open) : undefined}
                                        name={
                                            insuranceRecord?.is_archived === true
                                                ? 'pencil-outline'
                                                : open
                                                ? 'remove-outline'
                                                : 'add-outline'
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    </Card.Content>
                    {open && (
                        <Card.Content extra>
                            <Form.Group widths="equal">
                                <SuperField
                                    as="datepicker"
                                    required
                                    label={t('insurance_unregistration_date')}
                                    value={form.deregistration_date}
                                    onChange={(e, { value }) =>
                                        setForm((prev) => ({ ...prev, deregistration_date: value }))
                                    }
                                />
                            </Form.Group>

                            <SuperField
                                as="input"
                                type="file"
                                required
                                label={t('confirmation_file')}
                                onChange={(e) => handleFileInputChange(e)}
                                error={errors ? errors?.file?.[0] : false}
                            />
                            <Divider />
                            <Form.Field style={{ textAlign: 'right' }}>
                                <ModalCancel onClose={() => setOpen(false)} />
                                <ModalSubmit loading={processing} disabled={processing || !isFormValid()} />
                            </Form.Field>
                        </Card.Content>
                    )}
                </Card>
            )}
        </Form>
    )
}

export default InsuranceRegistrationForm
