import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
// import { useFetchData } from '@store/services/helpers/hooks';
import { updateRequest } from '@services/ServiceCommon';
// components
import { Accordion, Form, Button, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import moment from 'moment'
// import SuperCheckboxField from '@components/forms/SuperCheckboxField';
import AccordionItem from '@components/AccordionItem';

const Settings = ({ project, setProject }) => {
    // eslint-disable-next-line
    const { t } = useTranslation();
    const [active, setActive] = useState(null)

    const ProjectBaseForm = ({ project, setProject }) => {
        const { t } = useTranslation();
        const [isProcessing, setIsProcessing] = useState(false)

        const [formData, setFormData] = useState({
            name: project.name,
            identifier: project.identifier,
            description: project.description,
            project_manager: project.project_manager ? project.project_manager.id : null,
            teams: project.teams ? project.teams.map( team => { return team.id } ) : [],
            from_date: project.from_date ? project.from_date : "",
            due_date: project.due_date ? project.due_date : ""
        })

        const [invalidDateRange, setInvalidDateRange] = useState(false)

        // Validate Form
        useEffect( () => {
            if( formData.from_date !== "" && formData.due_date !== ""){
                if( moment(formData.from_date).isAfter(formData.due_date) ){
                    setInvalidDateRange(t('invalid_date_should_be_higher'))
                } else {
                    setInvalidDateRange(false)
                }
            }
            // eslint-disable-next-line
        }, [formData])

        const handleSubmit = async () => {
            setIsProcessing(true)
            const oldTeams = project.teams ? project.teams.map( team => { return team.id } ) : []

            const request = await updateRequest(API.PROJECTS + project.id + "/", {
                ...formData,
                teams: { remove: oldTeams, add: formData.teams },
                from_date: formData.from_date !== "" ? formData.from_date : null,
                due_date: formData.due_date !== "" ? formData.due_date : null
            })

            if( request.status === 200){
                setProject((prevState) => {
                    return {
                        ...prevState,
                        data: request.response
                    }
                })
            }
            setIsProcessing(false)
        }

        return (
            <Form onSubmit={handleSubmit}>
                <Form.Group widths="equal">
                    <SuperField as="input"
                        required
                        label={ t('name')}
                        value={ formData.name }
                        onChange={(e, { value }) => setFormData({ ...formData, name: value })}
                    />
                    <SuperField as="input"
                        required
                        label={ t('identifier')}
                        value={ formData.identifier }
                        onChange={(e, { value }) => setFormData({ ...formData, identifier: value })}
                    />
                </Form.Group>

                <SuperField as="textarea"
                    label={ t('desc') }
                    value={ formData.description }
                    onChange={(e, { value }) => setFormData({ ...formData, description: value })}
                />

                <Form.Group widths="equal">
                    <SuperField as="datepicker" 
                        label={ t('valid_from') }
                        value={formData.from_date}
                        onChange={ (e, { value }) => setFormData({...formData, from_date: value})}
                    />
                    <SuperField as="datepicker" 
                        label={ t('valid_until') }
                        error={invalidDateRange}
                        value={formData.due_date}
                        onChange={ (e, { value }) => setFormData({...formData, due_date: value})}
                    />
                </Form.Group>

                <Divider/>

                <Form.Group widths="equal">
                    <SuperField as="choice"
                        search
                        text="fullname"
                        value={ formData.project_manager }
                        label={ t('manager')}
                        endpoint={API.EMPLOYEES + "?query={id, fullname}"}
                        onChange={(e, { value }) => setFormData({ ...formData, project_manager: value })}
                    />
                    {/* <SuperField as="choice"
                        multiple
                        text="name"
                        value={ formData.teams }
                        label={ t('teams')}
                        endpoint={API.TEAMS + "?query={id, name}"}
                        onChange={(e, { value }) => setFormData({ ...formData, teams: value })}
                    /> */}
                    
                    {/* <SuperCheckboxField 
                        endpoint={API.TEAMS + "?query={id, name}"}
                        text="name"
                        value={[]}
                        onChange={ (selected) => console.log(selected) }
                    /> */}
                </Form.Group>

                <Form.Field floated="right">
                    <Button disabled={isProcessing} loading={isProcessing} primary> { t('save') } </Button>
                </Form.Field>
            </Form>
        )
    }

    const GitlabIntegration = () => {
        return (
            <p style={{ textAlign: "center" }}>
                We are still working on this feature. Bear with us!
            </p>
        )
    }

    return (
        <>
            <Accordion fluid styled>
                <AccordionItem 
                    index={0}
                    isActive={active === 0}
                    onClick={ () => setActive(active === 0 ? null : 0) }
                    title={t('configuration')}
                >
                    <ProjectBaseForm project={project} setProject={setProject}/>
                </AccordionItem>

                <AccordionItem 
                    index={2}
                    isActive={active === 2}
                    onClick={ () => setActive(active === 2 ? null : 2) }
                    title="Integration - Gitlab"
                >
                    <GitlabIntegration/>
                </AccordionItem>
            </Accordion>
        </>
    );
};

export default Settings;