import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { patchRequest, createRequest } from '@services/ServiceCommon';
// components
import ModalSubmit from '@components/buttons/ModalSubmit';
import ModalCancel from '@components/buttons/ModalCancel';
import SuperField from '@components/forms/SuperField';
import NonFieldErrors from '@components/NonFieldErrors';
import { Divider, Form, Header } from 'semantic-ui-react';

const CertificateForm = ({ data, setData, setTotal, unit, handleRequest, onClose }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState(null)

    const [form, setForm] = useState({
        id: data?.id || "",
        title: data?.title || '',
        type: data?.type ? data.type.id : '',
        unit: unit ? unit : data?.unit ? data.unit.id : '',
        valid_from: data?.valid_from ? data.valid_from : '',
        valid_to: data?.valid_to ? data.valid_to : '',
        responsible_person: data?.responsible_person ? data.responsible_person.id : '',
        status: data?.status ? data.status : '',
        additional_info: data?.additional_info || '',
    })

    async function handleSubmit() {
        setLoading(true)
        setErrors(null)

        let new_data = {
            title: form.title,
            unit: form.unit,
            additional_info: form.additional_info,
        }
        if (form.type !== '') new_data = { ...new_data, type: form.type }
        if (form.responsible_person !== '') new_data = { ...new_data, responsible_person: form.responsible_person }
        if (form.valid_from !== '') new_data = { ...new_data, valid_from: form.valid_from }
        if (form.valid_to !== '') new_data = { ...new_data, valid_to: form.valid_to }
        if (form.status !== '') new_data = { ...new_data, status: form.status }

        if (!form.id || form.id === 0) {
            let request = await createRequest(API.COMPANY_CERTIFICATES, new_data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                handleRequest(request.response, setData, setTotal)
                onClose()
            } 
        }
        else {
            let request = await patchRequest(`${API.COMPANY_CERTIFICATES}${data.id}/`, new_data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                handleRequest(request.response, setData, setTotal)
                onClose()
            } 
        }
        setLoading(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            { data?.id !== undefined && 
                <>
                    <Header as="h3" content={t('update')}/>
                    <Divider/>
                </>
            }
            <NonFieldErrors errors={errors} />
            <SuperField
                autoFocus
                label={t("title")}
                required
                as='input'
                value={form.title}
                error={errors?.title?.[0] || false}
                onChange={(e, { value }) => setForm({ ...form, title: value })}
            />

            <Form.Group widths={"equal"}>
                <SuperField 
                    as="datepicker"
                    clearable
                    label={t('valid_from')}
                    error={errors?.valid_from?.[0] || false}
                    value={form.valid_from}
                    onChange={(e, { value }) => setForm({ 
                        ...form, 
                        valid_from: value 
                    })}
                />
                <SuperField 
                    as="datepicker"
                    clearable
                    label={t('valid_to')}
                    error={errors?.valid_to?.[0] || false}
                    value={form.valid_to}
                    onChange={(e, { value }) => setForm({ 
                        ...form, 
                        valid_to: value 
                    })}
                />
            </Form.Group>

            <Form.Group widths={"equal"}>
                {!unit &&
                    <SuperField
                        as="choice"
                        search
                        required
                        label={t('unit')}
                        endpoint={API.UNITS}
                        text="name"
                        value={form.unit}
                        error={errors?.unit?.[0] || false}
                        onChange={(e, { value }) => setForm({
                            ...form,
                            unit: value
                        })}
                    />
                }
                <SuperField
                    as="choice"
                    search
                    label={t('responsible_person')}
                    endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                    text="fullname"
                    error={errors?.responsible_person?.[0] || false}
                    value={form.responsible_person}
                    onChange={(e, { value }) => setForm({
                        ...form,
                        responsible_person: value
                    })}
                />
            </Form.Group>
            <Form.Group widths={"equal"}>
                <SuperField
                    as="choice"
                    search
                    label={t('type')}
                    endpoint={API.COMPANY_CERTIFICATE_TYPES}
                    text="title"
                    error={errors?.type?.[0] || false}
                    value={form.type}
                    onChange={(e, { value }) => setForm({
                        ...form,
                        type: value
                    })}
                />
                <SuperField
                    as="choice"
                    label={t('status')}
                    type="certificate_statuses"
                    error={errors?.status?.[0] || false}
                    value={form.status.toString() || ""}
                    onChange={(e, { value }) => setForm({
                        ...form,
                        status: value
                    })}
                />
            </Form.Group>
            

            <SuperField
                label={t("note")}
                as='textarea'
                value={form.additional_info}
                error={errors?.additional_info?.[0] || false}
                onChange={(e, { value }) => setForm({ ...form, additional_info: value })}
            />

            <Divider/>

            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    text={t("confirm")}
                    disabled={form.title === "" || form.unit === ""}
                    loading={loading}
                />
            </Form.Field>
        </Form>
    );
};

export default CertificateForm;