import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import '@store/languages'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { thousandsSeparators } from '@helpers/functions'
// components
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts'
import { Segment, Grid, Header } from 'semantic-ui-react'

const ContractStats = ({ listData, params }) => {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(false)

    const [activeIndex1, setActiveIndex1] = useState(0)
    const [activeIndex2, setActiveIndex2] = useState(0)
    const [activeIndex3, setActiveIndex3] = useState(0)
    const [activeIndex4, setActiveIndex4] = useState(0)
    const [activeIndex5, setActiveIndex5] = useState(0)

    const [positionsContractsCount, setPositionsContractsCount] = useState([])
    const [typeContractsCount, setTypeContractsCount] = useState([])
    const [genderCount, setGenderCount] = useState([])
    const [period, setPeriod] = useState([])
    const [unitContractsCount, setUnitContractsCount] = useState([])

    const renderActiveShape = (props) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props

        return (
            <g>
                <text x={cx} y={cy - 10} dy={8} textAnchor="middle">
                    {thousandsSeparators(value || 0)}
                </text>
                <text x={cx} y={cy + 10} dy={8} textAnchor="middle">
                    {`(${t('rate_value')} ${(percent * 100).toFixed(2)}%)`}
                </text>
                <text x={cx} y={cy + 105} dy={8} textAnchor="middle">
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
            </g>
        )
    }

    useEffect(() => {
        async function fetchData() {
            setLoading(true)

            const request = await requests.get(API.CONTRACTS_STATS + '?query={*}' + params)
            if (request.status === 200) {
                let piePositions = []
                for (let i in request.response.job_positions) {
                    const position = request.response.job_positions[i]
                    if (position.contract_count > 0) {
                        piePositions.push({
                            id: position.title,
                            name: position.title,
                            value: position.contract_count,
                            fill: getColorById(position.title),
                        })
                    }
                }
                setPositionsContractsCount(piePositions)

                let pieTypes = []
                for (let j in request.response.contract_types) {
                    const type = request.response.contract_types[j]
                    if (type.contract_count > 0) {
                        pieTypes.push({
                            id: type.title,
                            name: type.title,
                            value: type.contract_count,
                            fill: getColorById(type.title),
                        })
                    }
                }
                setTypeContractsCount(pieTypes)

                let pieGenders = []
                if (request.response.gender_male_count) {
                    if (request.response.gender_male_count > 0) {
                        pieGenders.push({
                            id: 'male',
                            name: t('male'),
                            value: request.response.gender_male_count,
                            fill: 'var(--info)',
                        })
                    }
                }
                if (request.response.gender_female_count) {
                    if (request.response.gender_female_count > 0) {
                        pieGenders.push({
                            id: 'female',
                            name: t('female'),
                            value: request.response.gender_female_count,
                            fill: 'var(--danger)',
                        })
                    }
                }
                setGenderCount(pieGenders)

                let piePeriod = []
                if (request.response.indefinite_period_count) {
                    if (request.response.indefinite_period_count > 0) {
                        piePeriod.push({
                            id: 'indefinite_period_count',
                            name: t('indefined_period'),
                            value: request.response.indefinite_period_count,
                            fill: 'orange',
                        })
                    }
                }
                if (request.response.definite_period_count) {
                    if (request.response.definite_period_count > 0) {
                        piePeriod.push({
                            id: 'definite_period_count',
                            name: t('defined_period'),
                            value: request.response.definite_period_count,
                            fill: 'var(--success)',
                        })
                    }
                }
                setPeriod(piePeriod)

                let pieUnits = []
                for (let i in request.response.units) {
                    const unit = request.response.units[i]
                    if (unit.contract_count > 0) {
                        pieUnits.push({
                            id: unit.name,
                            name: unit.name,
                            value: unit.contract_count,
                            fill: getColorById(unit.name),
                        })
                    }
                }
                setUnitContractsCount(pieUnits)
            }
            setLoading(false)
        }

        fetchData()
        // eslint-disable-next-line
    }, [listData])

    const onPieEnter1 = (_, index) => {
        setActiveIndex1(index)
    }
    const onPieEnter2 = (_, index) => {
        setActiveIndex2(index)
    }
    const onPieEnter3 = (_, index) => {
        setActiveIndex3(index)
    }
    const onPieEnter4 = (_, index) => {
        setActiveIndex4(index)
    }
    const onPieEnter5 = (_, index) => {
        setActiveIndex5(index)
    }

    const getColorById = (id) => {
        const letters = '0123456789ABCDEF'
        let color = '#'
        for (let i = 0; i < 6; i++) {
            const charIndex = id.charCodeAt(i % id.length) % 16
            color += letters[charIndex]
        }
        return color
    }

    return (
        <Segment
            loading={loading}
            style={{
                padding: 0,
                marginTop: 0,
                background: 'transparent',
                boxShadow: 'none',
                border: 'none',
            }}
        >
            {loading && (
                <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '6rem' }}>
                    {t('loading_statistics')}
                </p>
            )}
            {!loading && (
                <Grid centered stackable>
                    <Grid.Row columns={5} style={{ paddingTop: 0 }}>
                        <Grid.Column tablet={6} computer={3} style={{ textAlign: 'center' }}>
                            <ResponsiveContainer width="100%" height={250}>
                                <PieChart>
                                    <Pie
                                        activeIndex={activeIndex1}
                                        activeShape={renderActiveShape}
                                        data={positionsContractsCount}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                        overflow={'visible'}
                                        onMouseEnter={onPieEnter1}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                            <Header as={'h5'} style={{ margin: 0, marginBottom: '1rem' }}>
                                {t('position')}
                            </Header>
                        </Grid.Column>
                        <Grid.Column tablet={6} computer={3} style={{ textAlign: 'center' }}>
                            <ResponsiveContainer width="100%" height={250}>
                                <PieChart>
                                    <Pie
                                        activeIndex={activeIndex2}
                                        activeShape={renderActiveShape}
                                        data={typeContractsCount}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={80}
                                        fill="black"
                                        dataKey="value"
                                        onMouseEnter={onPieEnter2}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                            <Header as={'h5'} style={{ margin: 0, marginBottom: '1rem' }}>
                                {t('contract_type')}
                            </Header>
                        </Grid.Column>
                        <Grid.Column tablet={6} computer={3} style={{ textAlign: 'center' }}>
                            <ResponsiveContainer width="100%" height={250}>
                                <PieChart>
                                    <Pie
                                        activeIndex={activeIndex3}
                                        activeShape={renderActiveShape}
                                        data={unitContractsCount}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                        onMouseEnter={onPieEnter3}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                            <Header as={'h5'} style={{ margin: 0, marginBottom: '1rem' }}>
                                {t('department')}
                            </Header>
                        </Grid.Column>
                        <Grid.Column tablet={6} computer={3} style={{ textAlign: 'center' }}>
                            <ResponsiveContainer width="100%" height={250}>
                                <PieChart>
                                    <Pie
                                        activeIndex={activeIndex4}
                                        activeShape={renderActiveShape}
                                        data={genderCount}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                        onMouseEnter={onPieEnter4}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                            <Header as={'h5'} style={{ margin: 0, marginBottom: '1rem' }}>
                                {t('gender')}
                            </Header>
                        </Grid.Column>
                        <Grid.Column tablet={6} computer={3} style={{ textAlign: 'center' }}>
                            <ResponsiveContainer width="100%" height={250}>
                                <PieChart>
                                    <Pie
                                        activeIndex={activeIndex5}
                                        activeShape={renderActiveShape}
                                        data={period}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                        onMouseEnter={onPieEnter5}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                            <Header as={'h5'} style={{ margin: 0, marginBottom: '1rem' }}>
                                {t('contract_length')}
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </Segment>
    )
}

export default ContractStats
