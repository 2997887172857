import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-semantic-toasts'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { useHasPermissions } from '@helpers/hooks'
// components
import { Form, Divider, Message } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import NonFieldErrors from '@components/NonFieldErrors'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const AssignAttendanceBonusForm = ({ onClose, monthList, contract, month, year }) => {
    const { t } = useTranslation()

    const profile = useSelector((store) => store.user.profile)
    const canApprove = useHasPermissions(['approvals.c_manage_all_approvals' /*, 'approvals.c_manage_user_approvals'*/])

    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)

    const lastDayOfMonth = moment(year + '-' + month + '-01')
        .endOf('month')
        .format('YYYY-MM-DD')

    const [form, setForm] = useState({
        contract: contract.id,
        bonus_type: '',
        billing_month: month,
        billing_year: year,
        billing_period: lastDayOfMonth,
        amount: 1,
        type: 4, // rewards
        currency: 'EUR',
        note: '',
    })

    const [loadingApprovers, setLoadingApprovers] = useState(false)
    const [approvers, setApprovers] = useState([])
    const [responsiblePerson, setResponsiblePerson] = useState('')

    async function fetchApprovers() {
        setLoadingApprovers(true)
        const request = await requests.get(API.EMPLOYEES + '?only_basic_info=true&is_active=true&has_user=true')

        if (request.status === 200) {
            // demanova cheat:
            const isDemanovaInstance =
                window.location.hostname === 'www.demanovarezort.pulsawork.com' ||
                window.location.hostname === 'demanovarezort.pulsawork.com'
            if (isDemanovaInstance) {
                // find julia (profile_id = 176)
                const julia = request.response.find((item) => item.id === 176)
                if (julia) {
                    setApprovers([{ key: julia.id, value: julia.id, text: julia.fullname_with_titles }])
                    setResponsiblePerson(julia.id)
                }
            } else {
                // other instance because demanova is a must have clie
                const approversList = request.response
                    .filter((item) => item.id !== profile?.id)
                    .map((item) => ({
                        key: item.id,
                        value: item.id,
                        text: item.fullname_with_titles,
                    }))
                setApprovers(approversList)
                if (approversList.length === 1) setResponsiblePerson(approversList[0].value)
            }
        }
        setLoadingApprovers(false)
    }

    useEffect(() => {
        fetchApprovers()
        // eslint-disable-next-line
    }, [])

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)

        const data = form
        if (!canApprove) data['responsible_person'] = responsiblePerson
        const request = await requests.post(API.PAYROLLS + 'bonus_payments/', data)

        if (request.status === 400) setErrors(request.response)
        if (request.status === 201) {
            if (request.response.is_approved === false) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('bonus_assigned_require_approval'),
                    animation: 'pulse',
                    time: 5000,
                })
                onClose()
            } else {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('bonus_assigned_and_approved'),
                    animation: 'pulse',
                    time: 5000,
                })
                onClose()
            }
        }

        setProcessing(false)
    }

    const isFormValid = () => {
        if (form.amount <= 0 || form.amount === '') return false
        if (form.currency === '') return false

        // if you dont have permission for approval then require to select responsible person for approval
        if (!canApprove && responsiblePerson === '') return false
        return true
    }

    return (
        <Form onSubmit={handleSubmit}>
            <NonFieldErrors errors={errors} />

            <Message info visible style={{ marginTop: errors?.length > 0 ? '1rem' : 0 }}>
                {t('billing_period')}:{' '}
                <strong>
                    {month}/{year} · {monthList.find((item) => item.value === month)?.text || ''}
                </strong>
            </Message>

            <Form.Group widths="equal">
                <SuperField
                    as="input"
                    autoFocus
                    required
                    label={t('sum')}
                    value={form.amount}
                    error={errors?.amount?.[0] || false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, amount: value.replace(',', '.') }))}
                />
                <SuperField
                    as="choice"
                    search
                    required
                    clearable={false}
                    label={t('currency')}
                    type="currency_codes"
                    value={form.currency}
                    error={errors?.currency?.[0] || false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, currency: value }))}
                />
            </Form.Group>

            {!canApprove && (
                <SuperField
                    as="choice"
                    search
                    style={{ marginBottom: '0' }}
                    required={!canApprove}
                    value={responsiblePerson}
                    // text="fullname_with_titles"
                    // exclude={profile ? [profile.id] : []}
                    label={t('responsible_person_for_approval')}
                    error={errors?.responsible_person?.[0] || false}
                    onChange={(e, { value }) => setResponsiblePerson(value)}
                    customOptions={approvers}
                    loading={loadingApprovers}
                    disabled={loadingApprovers}
                    // endpoint={API.EMPLOYEES + '?only_basic_info=true&is_active=true&has_user=true'}
                />
            )}

            <SuperField
                as="choice"
                search
                text="title"
                label={t('bonus_type')}
                value={form.bonus_type}
                error={errors?.bonus_type?.[0] || false}
                endpoint={API.PAYROLLS + 'bonus_types/?only_basic_info=true'}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, bonus_type: value }))}
            />

            <SuperField
                as="textarea"
                label={t('note')}
                value={form.note}
                error={errors?.note?.[0] || false}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, note: value }))}
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={processing}
                    disabled={processing || !isFormValid()}
                    text={canApprove ? t('confirm') : t('request_approval')}
                />
            </Form.Field>
        </Form>
    )
}

export default AssignAttendanceBonusForm
