import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// store
import { routes } from '@store/routes/index';
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { useHasPermissions } from '@helpers/hooks';
// components
import Icon from '@components/Icon';
import { Container, Tab, Button } from 'semantic-ui-react'
import BreadcrumbNav from '@components/BreadcrumbNav';
import UnitHeader from '../components/units/UnitHeader';
import EmployeesAssignedList from '../views/EmployeesAssignedList';
import UnitDetailTeams from '../components/units/teams-tab/UnitDetailTeams';
import UnitDetailUnits from '../components/units/units-tab/UnitDetailUnits';
import Loading from '@components/general/Loading';
import { CompanyForm } from "@components/common/CompanyForm";
import ConfrimModal from '@components/modals/ConfrimModal';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import CanView from '@components/perms/CanView';
import DetailView from '../components/units/home-tab/DetailView';
import CertificatesList from '../components/company/certificates-tab/CertificatesList';


const UnitDetail = () => {
    const params = useParams();
    const company = useSelector(state => state.company);
    const history = useHistory()
    const { t } = useTranslation()

    const [data, setData] = useState({});
    const [tree, setTree] = useState({});
    const [teams, setTeams] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const canViewHomeTab = useHasPermissions(['company.c_view_units']);
    const canViewUnitTab = useHasPermissions(['company.c_view_company_tree']);
    const canViewTeamTab = useHasPermissions(['teams.c_view_user_teams', 'teams.c_view_all_teams']);
    const canViewEmployeeTab = useHasPermissions(['common.c_view_all_employees']);
    const canViewCertificatesTab = useHasPermissions(['company.c_view_certificates']);


    useEffect(() => {
        async function fetchData() {
            setIsLoading(true)

            const result = await requests.get(API.COMPANIES + API.UNIT + params.unit_id + '/')

            if (result.status === 403) {
                history.push(routes.DASHBOARD)
                return
            }

            if (result.status === 200) {
                setData(result.response);

            }
            const resultTree = await requests.get(API.COMPANIES + API.UNIT + params.unit_id + '/tree/')

            if (resultTree.status === 200) {
                setTree(resultTree.response);
            }
            const teams = await requests.get(API.TEAMS + "?query={id,name,description}")

            if (teams.status === 200) {
                setTeams(teams.response);
            }

            setIsLoading(false);
        };

        fetchData();

    }, [params.unit_id, history]);


    const action = [
        <CanView permissions={["company.c_manage_units"]}>
            <SuperDuperModal
                key={params.unit_id}
                trigger={
                    <Button style={{ backgroundColor: "transparent", paddingLeft: "2rem" }} icon>
                        <Icon name="pencil-outline" />
                    </Button>
                }
                size="large"
                header={t('update_unit')}
                content={
                    <CompanyForm
                        btnName="Update"
                        data={data}
                        isUnit={true}
                        onConfirm={(updated_item) => setData(updated_item)}
                    />
                }
            />
        </CanView>
    ];

    const breadcrumb_items = [
        { 'name': company.name, 'icon': '', 'href': routes.COMPANY },
        { 'name': data && data.name, 'icon': '', 'href': '' }
    ]
    const panes = [
        {
            menuItem: canViewHomeTab && t('home'),
            render: () =>
                <Tab.Pane>
                    <DetailView unit={data} setUnit={setData} />
                </Tab.Pane>
        },
        {
            menuItem: canViewUnitTab && t('units'),
            render: () =>
                <Tab.Pane><UnitDetailUnits data={tree} /></Tab.Pane>
        },
        {
            menuItem: canViewEmployeeTab && t('employees'),
            render: () =>
                <Tab.Pane><EmployeesAssignedList unitID={params.unit_id} /></Tab.Pane>
        },
        {
            menuItem: canViewTeamTab && t('teams'),
            render: () =>

                <Tab.Pane><UnitDetailTeams id={params.unit_id} data={teams} teams={data.teams} /></Tab.Pane>
        },
        {
            menuItem: canViewCertificatesTab && t('certificates'),
            render: () => <Tab.Pane><CertificatesList unit={params.unit_id} /></Tab.Pane>
        },
    ]

    const actions = [
        {
            component:
                <CanView permissions={["company.c_delete_units"]}>
                    <ConfrimModal
                        onConfirm={async () => {
                            const result = await requests.post(API.COMPANIES + API.UNIT + params.unit_id + "/delete/");

                            if (result.status === 200) {
                                history.push(routes.COMPANY)
                            }
                        }}
                        button={
                            <Button size="tiny" color="red" style={{ margin: 0 }}>
                                <Icon style={{ color: "var(--light)", marginRight: "0.5rem" }} name="close-outline" />
                                <span style={{ position: "relative", top: "-0.2rem" }}>
                                    {t('remove_unit')}
                                </span>
                            </Button>
                        }
                        description={t('delete_unit')}
                    />
                </CanView>
        }
    ]

    return (
        <Fragment >
            <BreadcrumbNav items={breadcrumb_items} actions={actions} />
            { isLoading ? <Loading /> :
                <Container fluid>
                    <UnitHeader action={action} tree={tree} data={data} setData={setData} />
                    <Tab panes={panes} />
                </Container>
            }
        </Fragment>
    )
}

export default UnitDetail
