import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";

import SuperField from '@components/forms/SuperField';
import { Segment, Button, Label } from 'semantic-ui-react'
import ColorPicker from './ColorPicker'

import { API } from '@store/config';
import { createRequest, getRequest } from '@services/ServiceCommon';
import { toOptionsLabels } from '@store/services/helpers/utils';



const LabelInput = ({ value, onChange }) => {
    const { t } = useTranslation();
    const colors = useSelector((state) => state.global_pref.colors)
    const [values, setValues] = useState(value ? value : [])
    const [labelOptions, setLabelOptions] = useState([])
    const [error, setError] = useState(false)
    const [process, setProcess] = useState({
        loading: false,
        working: false
    })
    const [addition, setAddition] = useState({
        name: '',
        color: colors.primary
    })

    useEffect(() => {
        async function fetchOptions() {
            let result = await getRequest(API.CASES_LABELS)
            if (result.status === 200) {
                setLabelOptions(toOptionsLabels(result.response))
            }
        }
        fetchOptions()
    }, [])

    async function createNewLabel() {
        setProcess({ ...process, loading: true })
        let result = await createRequest(API.CASES_LABELS, addition)
        if (result.status === 201) {
            handleAddNewLabel(result.response)
            setProcess({ loading: false, working: false })
            setAddition({
                name: '',
                color: colors.primary
            })
        } else {
            setError(`${t("error_label_first_half")} "${addition.name}" ${t("error_label_second_half")}`)
            setProcess({ loading: false, working: true })
        }
    }

    function handleChangeSelect(input) {
        onChange(input)
        setValues(input)
    }
    function handleAddNewLabel(input) {
        setLabelOptions([...labelOptions, { key: input.id, value: input.id, text: input.name, label: <Label circular style={{ backgroundColor: input.color }} /> }])
        setValues([...values, input.id])
    }

    return (

        <Segment loading={process.loading}>
            {!process.working ?
                <>
                    <SuperField
                        multiple
                        label={t("labels")}
                        as='choice'
                        text="name"
                        customOptions={labelOptions}
                        value={values}
                        onChange={(e, { value }) => handleChangeSelect(value)}
                        style={{ margin: 0 }}
                    />
                    <Button
                        icon="add"
                        content={t("add_label")}
                        onClick={() => setProcess({ ...process, working: true })}
                        style={{ backgroundColor: colors.primary, color: colors.white, marginBottom: '0.2rem' }} />
                </>
                :
                <>
                    <SuperField
                        label={t("name_label")}
                        as='input'
                        value={addition.name}
                        onChange={(e, { value }) => {
                            setError(false)
                            setAddition({ ...addition, name: value })
                        }}
                        error={error}
                    />
                    <ColorPicker onChange={(value) => {
                        setError(false)
                        setAddition({ ...addition, color: value })
                    }} value={addition.color} />
                    <div style={{ margin: '.2rem 0' }}>
                        <Button content={t("cancel")} onClick={() => setProcess({ ...process, working: false })} />
                        <Button
                            icon="add"
                            content={t("add")}
                            onClick={() => createNewLabel()}
                            style={{ backgroundColor: colors.primary, color: colors.white }} disabled={addition.name === ''} />
                    </div>
                </>
            }
        </Segment>
    )
}
export default LabelInput
