import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests';
import { isEmpty } from '@helpers/validation';
//components
import { Form } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const CreatePermissionGroup = ({ group, onClose, onSubmit }) => {
    const { t } = useTranslation();
    const [isProcessing, setIsProcessing] = useState(false);

    const [form, setForm] = useState({ name: group ? group.name : "" });

    const handleSubmit = async () => {
        setIsProcessing(true);

        if (group) {
            const request = await requests.put(API.PERMISSION_GROUPS + group.id + '/', form);

            if (request.status === 200) {
                onSubmit(request.response, "update")
                onClose()
            }
        } else {
            const request = await requests.post(API.PERMISSION_GROUPS, form);

            if (request.status === 201) {
                onSubmit(request.response, "create")
                onClose()
            }
        }

        setIsProcessing(false);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Field>
                <Form.Input
                    autoFocus
                    required
                    label={t('title')}
                    placeholder={t('title')}
                    value={form.name}
                    onChange={(e) => setForm({
                        ...group,
                        name: e.target.value
                    })}
                />
            </Form.Field>

            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel disabled={isProcessing} onClose={onClose} />
                <ModalSubmit
                    disabled={isProcessing || isEmpty(form.name)}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>

        </Form>
    );
};

export default CreatePermissionGroup