import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// components
import SuperField from '@components/forms/SuperField';

const Interests = ({ setData }) => {
    const { t } = useTranslation();
    const [selectedInterests, setSelectedInterests] = useState([]);
    const [interestsList, setInterestsList] = useState([]);

    useEffect(() => {
        let interestsList = []
        if( selectedInterests.length > 0 ){
            for (let i = 0; i < selectedInterests.length; i++){
                interestsList.push({
                    name: selectedInterests[i],
                })
            }
        }
        let interests = {
            create: interestsList
        }

        setData(prevState => ({
            ...prevState,
            interests: interests
        }))
    }, [selectedInterests, setData])

    return (
        <SuperField as="dropdown"
            placeholder={ t('type_to_add_interests') }
            fluid
            search
            selection
            multiple
            label={ t('interests') }
            allowAdditions
            additionLabel={ t('add') + " " }
            options={interestsList}
            value={selectedInterests}
            onChange={(e, { value }) => setSelectedInterests(value)}
            onAddItem={(event, data) => {
                setInterestsList([...interestsList, {key: data.value, text: data.value, value: data.value}])
            }}
        />
    );
};

export default Interests;