import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom';
// store
import { routes } from '@store/routes';
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
// components
import Icon from '@components/Icon';
import { Grid, Placeholder } from 'semantic-ui-react'
import AvatarList from '@components/general/AvatarList';

const TeamCard = ({ id, name, description }) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [members, setMembers] = useState([]);
    const [projectCount, setProjectCount] = useState(0);

    useEffect(() =>{
        async function fetchData(){
            const request = await getRequest(API.TEAMMEMBERS + "?query={id, is_leader, profile{id, fullname, profile_picture}}&team=" + id);
            if(request.status === 200){
                const names = []
                for (let i = 0; i < request.response.length; i++) {
                    names.push(request.response[i].profile.fullname)
                  }
                setMembers(names);
            }
            
            const stats = await getRequest(API.TEAMS_STATS + id + "/stats/");
            if(stats.status === 200){
                setProjectCount(stats.response.project_count || 0);
            }

            setIsLoading(false);
        }

        fetchData()
    }, [id])

    return (
        <Grid style={styles.container} className="project-card" as={Link} to={routes.TEAMS_DETAIL+id}>
            <Grid.Row style={styles.content}>
                <Grid.Column mobile={16} tablet={16} computer={11} style={{paddingTop: "1rem"}}>
                    <Grid.Row>
                        <span style={styles.header}>{name}</span><br />
                    </Grid.Row>
                    <Grid.Row style={{ paddingTop: 10 }}>
                        {
                            description.length < 50
                            ? description
                            : description.slice(0, 60)
                        }
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column className="project-graph" mobile={16} tablet={16} computer={5} style={{ display: 'flex', justifyContent: "center", paddinggRight: "2rem" }}>
                    <Icon size="huge" name="people-outline" style={{ color: "var(--black)", marginTop:"1rem" }}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} style={styles.footer}>
                { isLoading
                    ?   <Grid.Column computer={16} tablet={16} mobile={16}>
                            <Placeholder>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line/>
                                    <Placeholder.Line length='full' />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Grid.Column>
                    :
                    <>
                    <Grid.Column mobile={16} tablet={16} computer={12} style={{ fontWeight: "bolder", padding: "0.1rem", paddingLeft: "2rem", width: "auto", fontSize: "1.2rem", textAlign: "left" }}>
                        {t('active_projects')} {projectCount}
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={2} style={{ display: "flex", margin: "1rem", marginLeft: "1rem"}}>
                        <AvatarList size={30} members={members}/>
                    </Grid.Column>
                    </>
                }
            </Grid.Row>
        </Grid>
    )
}
const light_blue_grey = "#f1f5f8"
const styles = {
    container: {
        borderColor: "var(--border)",
        borderWidth: 1,
        borderStyle: "solid",
        padding: 0,
    },
    content: {
        backgroundColor: "var(--default)",
        margin: 0,
        paddingLeft: "1rem",
        paddingRight: "1rem",
    },
    footer: {
        borderTopColor: "var(--border)",
        borderTopWidth: 1,
        borderTopStyle: "solid",
        backgroundColor: light_blue_grey,
        display: "flex",
        alignItems: "center",
    },
    header: {
        fontSize: "1.2rem",
        fontWeight: "bold",
        paddingBottom: "0.5rem"
    },
    description: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
    }

}

export default TeamCard
