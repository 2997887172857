import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { icons } from '@icons';
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Container, Button, Dropdown } from 'semantic-ui-react';
import MyRecords from './MyRecords';
// import Timesheets from './Timesheets';
import BreadcrumbNav from '@components/BreadcrumbNav';
import CanView from '@components/perms/CanView';
import DayOverview from './DayOverview';
import GroupTimesheet from './GroupTimesheet';

const TimesheetsView = () => {
    const { t } = useTranslation();
    const [view, setView] = useState(0)
    const [team, setTeam] = useState(null)
    const [teams, setTeams] = useState([])
    const [loading, setLoading] = useState(false)
    
    // fetch teams (basic info only)
    useEffect(() => {
        async function fetchTeams() {
            setLoading(true)
            const request = await requests.get(API.TEAMS + "?only_basic_info=true")
            if (request.status === 200) {
                setTeams(request.response.map(item => ({ id: item.id, name: item.name, total_members: item.team_member_count })))
            }
            setLoading(false)
        }

        fetchTeams()
    }, [])

    const handleTeamSelection = (item) => {
        setView(1)
        setTeam(item.id)
    }

    useEffect(() => {
        if (view !== 1) {
            setTeam(null)
        }
    }, [view])
    
    return (
        <CanView permissions={["timesheets.c_view_user_records", "timesheets.c_view_all_records", "company.c_manage_only_assigned_unit_employees"]} redirect>
            <BreadcrumbNav items={[
                { 'name': t('timesheets'), 'icon': icons.TIMESHEETS, 'href': '' }
            ]} />
            <Container fluid>
                <Button.Group basic size="small" style={{ borderRadius: "0" }} className='custom-filter-button-group'>
                    <CanView permissions={["timesheets.c_view_user_records"]}>
                        <Button active={view === 0} content={t('my_records')} onClick={() => setView(0)} />
                    </CanView>
                    <CanView permissions={["timesheets.c_manage_all_records"]}>
                        <Button active={view === 2} content={t('day_overview')} onClick={() => setView(2)} />
                    </CanView>
                    <CanView permissions={["timesheets.c_view_all_records", "company.c_manage_only_assigned_unit_employees"]}>
                        <Dropdown 
                            simple
                            as={Button}
                            loading={loading}
                            disabled={loading || teams.length === 0} 
                            text={`${t('manage_group')}${team !== null ? " - " + teams.find(item => item.id === team)?.name || "" : ""}`}
                            className={ team !== null && "button-active" }
                            style={{ textAlign: "center" }}
                        >
                            <Dropdown.Menu style={{ borderRadius: "0" }}>
                                { teams.map(item => (
                                    <Dropdown.Item 
                                        active={team === item.id} 
                                        disabled={item.total_members === 0}
                                        onClick={() => handleTeamSelection(item)}
                                        className={ item.total_members === 0 && "not-allowed-cursor opacity-80" }
                                    >
                                        { item.name } ({ item.total_members })
                                    </Dropdown.Item>
                                )) }
                            </Dropdown.Menu>
                        </Dropdown>
                        {/* <Button active={view === 1} content={t('group')}/> */}
                    </CanView>
                </Button.Group>
                { view === 0 && <MyRecords />} 
                { view === 2 && <DayOverview />}
                { view === 1 && <GroupTimesheet team={team} /> }
            </Container>
        </CanView>
    );
};

export default TimesheetsView;