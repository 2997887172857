import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { isEmpty } from '@helpers/validation'
import { requests } from '@helpers/requests'
import { API } from '@store/config'
// components
import { Form, Message } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import CustomFormDivider from '@components/general/CustomFormDivider'

const MilestoneForm = ({ record, setData, setTotal, onClose, project, milestone }) => {
    const { t } = useTranslation()

    const [isProcessing, setIsProcessing] = useState(false) // true, false
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        name: record?.name || null,
        description: record?.description || null,
        is_active: record?.is_active || null,
        from_date: record?.from_date || null,
        due_date: record?.due_date || null,
        project: project.id,
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        if (record?.id === undefined) {
            const request = await requests.post(API.PROJECT_MILESTONES, form)
            if (request.status === 201) {
                setData((prev) => [...prev, request.response])
                setTotal((prev) => prev + 1)
                onClose()
            } else {
                setErrors(request.response)
            }
            setIsProcessing(false)
        } else {
            const request = await requests.patch(API.PROJECT_MILESTONES + `${record?.id}/`, form)
            if (request.status === 200) {
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === request.response.id) item = request.response
                        return item
                    })
                )
                onClose()
            } else {
                setErrors(request.response)
            }
            setIsProcessing(false)
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors?.non_field_errors?.length > 0}
                header={t('error_submission_message')}
                list={errors?.non_field_errors}
            />
            <SuperField
                as="input"
                required
                autoFocus
                label={t('name')}
                placeholder={t('name')}
                value={form.name}
                onChange={(e, { value }) => setForm({ ...form, name: value })}
            />
            <SuperField
                as="textarea"
                label={t('description')}
                placeholder={t('description')}
                value={form?.description}
                onChange={(e, { value }) => setForm({ ...form, description: value })}
            />
            <CustomFormDivider
                header={t('milestone_span')}
                divider={true}
                content={
                    <>
                        <Form.Group widths="2">
                            <SuperField
                                as="datepicker"
                                label={t('from_date')}
                                placeholder={t('from_date')}
                                error={errors === null ? false : errors?.from_date}
                                value={form?.from_date}
                                onChange={(e, { value }) => {
                                    value === '' || value === null
                                        ? setForm({ ...form, from_date: null })
                                        : setForm({ ...form, from_date: value })
                                }}
                            />
                            <SuperField
                                as="datepicker"
                                label={t('due_date')}
                                placeholder={t('due_date')}
                                error={errors === null ? false : errors?.due_date}
                                value={form?.due_date}
                                onChange={(e, { value }) => {
                                    value === '' || value === null
                                        ? setForm({ ...form, due_date: null })
                                        : setForm({ ...form, due_date: value })
                                }}
                            />
                        </Form.Group>
                    </>
                }
            />
            {record?.id && (
                <SuperField
                    as="checkbox"
                    label={record?.is_active ? t('close') : t('open_btn')}
                    value={form?.is_active}
                    onChange={(e, { value }) =>
                        setForm({
                            ...form,
                            is_active: !value,
                        })
                    }
                />
            )}
            <br />
            <Form.Field style={{ textAlign: 'right', margin: '1rem' }}>
                <ModalCancel text={t('cancel')} onClose={onClose} disabled={isProcessing} />
                <ModalSubmit
                    text={record?.id ? t('save') : t('create')}
                    disabled={isProcessing || isEmpty(form.name)}
                    loading={isProcessing}
                />
            </Form.Field>
        </Form>
    )
}

export default MilestoneForm
