import React, { useState, useEffect, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import '@store/languages'
// store
import { API } from '@store/config'
import { useHasPermission } from '@helpers/hooks'
import { getTimezoneAsString } from '@helpers/dates'
import { requests, fileDownload } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import SuperField from '@components/forms/SuperField'
import { Table, Header, Form, Divider, Segment, Button, Grid, Popup } from 'semantic-ui-react'

const MonthlyAttendanceReport = () => {
    const { t } = useTranslation()
    // eslint-disable-next-line
    const canManageOnlyAssignedEmployees = useHasPermission(
        'attendance.c_can_manage_only_employees_as_responsible_person'
    )
    const language = useSelector((state) => state.language)
    const dateFormat = useSelector((state) => state.date_format)
    const profile_id = useSelector((state) => state?.user?.profile_id || null)
    const [firstLoad, setFirstLoad] = useState(true)
    const [loading, setLoading] = useState(false)
    const [showCorrectedRecords, setShowCorrectedRecords] = useState(false)
    const [viewVoucher, setViewVoucher] = useState(false)
    const [weeks, setWeeks] = useState([])
    const [shifts, setShifts] = useState([])
    const [filters, setFilters] = useState({
        month: parseInt(moment().format('M')),
        year: parseInt(moment().format('YYYY')),
        unit: '',
        position_group: '',
    })
    const [weekStats, setWeekStats] = useState([])
    const [days, setDays] = useState(30)
    const [data, setData] = useState(null)

    const months = [
        { key: 1, value: 1, text: t('january') },
        { key: 2, value: 2, text: t('february') },
        { key: 3, value: 3, text: t('march') },
        { key: 4, value: 4, text: t('april') },
        { key: 5, value: 5, text: t('may') },
        { key: 6, value: 6, text: t('june') },
        { key: 7, value: 7, text: t('july') },
        { key: 8, value: 8, text: t('august') },
        { key: 9, value: 9, text: t('september') },
        { key: 10, value: 10, text: t('october') },
        { key: 11, value: 11, text: t('november') },
        { key: 12, value: 12, text: t('december') },
    ]

    const calculateHoursPerWeek = (weekDates, data) => {
        let hours = 0
        for (let i = 0; i < weekDates.length; i++) {
            let day = data.find((item) => item.date === weekDates[i])
            if (day) {
                hours += parseFloat(day?.work_hours || 0)
            }
        }

        return hours
    }

    function generateWeeks(month, year) {
        month = month < 10 ? `0${month}` : `${month}`
        const firstDay = moment(year + '-' + month + '-01').startOf('month')
        const lastDay = moment(year + '-' + month + '-01').endOf('month')

        let weeks = []
        let currentWeek = firstDay.week()
        let currentWeekDates = []

        for (let m = firstDay.clone(); m.isBefore(lastDay) || m.isSame(lastDay); m.add(1, 'days')) {
            if (m.week() !== currentWeek) {
                weeks.push({ week: currentWeek, dates: currentWeekDates })
                currentWeek = m.week()
                currentWeekDates = []
            }
            currentWeekDates.push(m.format('YYYY-MM-DD'))
        }
        weeks.push({ week: currentWeek, dates: currentWeekDates })

        return weeks
    }

    function generateWeeklyStats(month, year, contracts) {
        let contractList = []
        let weeks = generateWeeks(month, year)

        for (let i = 0; i < contracts.length; i++) {
            contractList.push({
                id: contracts[i].id,
                name: contracts[i].name,
                weeks: weeks.map((item) => ({
                    last_day: item.dates[item.dates.length - 1],
                    week_num: item.week,
                    hours: calculateHoursPerWeek(item.dates, contracts[i].days),
                })),
            })
        }

        return contractList
    }

    async function fetchMonthlyAttendance(e) {
        e.preventDefault()
        setFirstLoad(false)
        setData(null)
        setLoading(true)

        const lastDay = moment(filters.year + '-' + filters.month + '-01')
            .endOf('month')
            .format('YYYY-MM-DD')

        // get me current day and first day in month from previous month
        let currentDay = moment()
        if (currentDay.isAfter(moment(lastDay))) {
            currentDay = moment(lastDay)
        }

        const previousMonth = currentDay.clone().subtract(1, 'months')
        const contract_date_to = currentDay.format('YYYY-MM-DD')
        const contract_date_from = previousMonth.clone().startOf('month').format('YYYY-MM-DD')

        let queryParams = ''
        if (filters.unit !== '') queryParams += `&unit=${filters.unit}`
        if (filters.position_group !== '') queryParams += `&position_group=${filters.position_group}`
        let correctedRecordsQuery = '&ignore_attendance_correction_preference=true'
        if (showCorrectedRecords) correctedRecordsQuery = ''
        const request = await requests.get(
            API.REPORTS +
                `attendance/contract_monthly_statistics/?month=${filters.month}&year=${filters.year}&valid_contract_based_on_date_from=${contract_date_from}&valid_contract_based_on_date_to=${contract_date_to}${correctedRecordsQuery}${queryParams}`
        )
        const month = filters.month < 10 ? `0${filters.month}` : `${filters.month}`
        setDays(moment(`${filters.year}-${month}-01`).daysInMonth())

        if (request.status === 200) {
            setData(request.response)
            if (request.response?.shift?.length > 0) {
                setShifts((prev) => [...request.response.shift, { id: null, custom_id: t('unclassified') }])
            }

            const weeks = generateWeeks(filters.month, filters.year)
            const result = generateWeeklyStats(
                filters.month,
                filters.year,
                request.response.employee_data_list.map((item) => ({ id: item.id, name: item.name, days: item.days }))
            )

            setWeekStats(result)
            setWeeks(weeks)
        }

        setLoading(false)
        setFirstLoad(false)
    }

    const renderDayCells = () => {
        const cells = []
        const month = filters.month < 10 ? `0${filters.month}` : `${filters.month}`
        const returnDay = (i) => {
            if (i < 10) {
                return `0${i}`
            }
            return i
        }

        for (let i = 1; i <= days; i++) {
            const date = `${filters.year}-${month}-${returnDay(i)}`
            const lastDayOfWeek = weeks.find((item) => item.dates[item.dates.length - 1] === date)
            cells.push(
                <Fragment key={i}>
                    <Table.HeaderCell textAlign="center" width={1} key={i}>
                        <span style={{ textTransform: 'uppercase' }}>
                            {moment(date).locale(language).format('ddd')}
                            <br />
                        </span>
                        <span style={{ fontWeight: 'normal' }}>{i}</span>
                    </Table.HeaderCell>
                    {lastDayOfWeek && (
                        <Table.HeaderCell textAlign="center" width={1} key={i} singleLine>
                            <span>
                                {t('week')} {lastDayOfWeek.week}
                            </span>
                            <br />
                            <span>{t('count').toLocaleLowerCase()}</span>
                        </Table.HeaderCell>
                    )}
                </Fragment>
            )
        }

        return cells
    }

    const findWorkingDay = (days, day, shift) => {
        if (shift !== null) {
            if (shift?.id > 0) {
                return days.find((item) => {
                    if (item.date === day && item.shift?.id === shift.id) {
                        return item
                    }

                    return undefined
                })
            } else if (shift.id === null) {
                return days.find((item) => item.date === day && item.shift === null)
            } else {
                return undefined
            }
        }

        return days.find((item) => item.date === day)
    }

    const replaceDot = (input) => {
        if (input === undefined || input === '') return input
        return input?.toString()?.replace('.', ',')
    }

    const calculateAbsences = (absences) => {
        let total = 0

        for (let i = 0; i < absences.length; i++) {
            if (absences[i].source !== 'vacation') total += parseFloat(absences[i].value)
        }

        return parseFloat(total).toFixed(2)
    }

    const calculateVacationDays = (absences) => {
        let total = 0

        for (let i = 0; i < absences.length; i++) {
            if (absences[i].source === 'vacation') total += parseFloat(absences[i].vacation_type)
        }

        return parseFloat(total).toFixed(2)
    }

    const calculateVacationHours = (absences) => {
        let total = 0

        for (let i = 0; i < absences.length; i++) {
            if (absences[i].source === 'vacation') total += parseFloat(absences[i].value)
        }

        return parseFloat(total).toFixed(2)
    }

    const renderHourCells = (employee, shift) => {
        shift = shift || null
        const cells = []
        for (let i = 1; i <= days; i++) {
            let dayValue = i < 10 ? '0' + i : i
            let monthValue = parseInt(filters.month) < 10 ? '0' + filters.month : filters.month
            let day = `${filters.year}-${monthValue}-${dayValue}`
            const workingDay = findWorkingDay(employee.days, day, shift)
            const lastDayOfWeek = weeks.find((item) => item.dates[item.dates.length - 1] === day)

            let weekHours = 0
            if (lastDayOfWeek) {
                let employeeStatsWeeks = weekStats.find((item) => item.id === employee.id)?.weeks || []
                weekHours = employeeStatsWeeks.find((item) => item.week_num === lastDayOfWeek?.week)?.hours || 0
            }

            cells.push(
                <Fragment key={i}>
                    <Table.Cell
                        singleLine
                        className="number"
                        style={{
                            background: [0, 6].includes(moment(day).day()) ? 'var(--light)' : 'transparent',
                            textAlign: 'center',
                        }}
                    >
                        {workingDay?.work_hours > 0 && (
                            <>
                                {replaceDot(workingDay?.work_hours)}
                                {workingDay?.absention > 0 ? (
                                    <>
                                        {' '}
                                        + <strong style={{ color: 'red' }}>{replaceDot(workingDay?.absention)}</strong>
                                    </>
                                ) : (
                                    ''
                                )}
                            </>
                        )}

                        {workingDay?.vacation > 0 && workingDay?.work_hours === null && workingDay?.work_hours <= 0 && (
                            <strong style={{ color: 'green' }}>{replaceDot(workingDay?.vacation)}</strong>
                        )}
                        {workingDay?.sick_day > 0 && workingDay?.work_hours === null && workingDay?.work_hours <= 0 && (
                            <strong style={{ color: 'orange' }}>{replaceDot(workingDay?.sick_day)}</strong>
                        )}
                        {workingDay?.absention > 0 &&
                            workingDay?.work_hours === null &&
                            workingDay?.work_hours <= 0 && (
                                <strong style={{ color: 'red' }}>{replaceDot(workingDay?.absention)}</strong>
                            )}
                        {workingDay?.paragraph > 0 &&
                            workingDay?.work_hours === null &&
                            workingDay?.work_hours <= 0 && (
                                <strong style={{ color: 'blue' }}>{replaceDot(workingDay?.paragraph)}</strong>
                            )}
                    </Table.Cell>
                    {lastDayOfWeek && (
                        <Table.Cell style={{ textAlign: 'center', background: '#FFFB94', fontWeight: 'bold' }}>
                            {replaceDot(weekHours)}
                        </Table.Cell>
                    )}
                </Fragment>
            )
        }

        return cells
    }

    // eslint-disable-next-line
    const calculateShiftHours = (employee, shift) => {
        let shiftWorkingDays = []
        let total = 0

        if (shift.id === null) {
            shiftWorkingDays = employee.days.filter((item) => item.shift === null)
        } else if (shift.id > 0) {
            shiftWorkingDays = employee.days.filter((item) => item.shift?.id === shift.id)
        }

        for (let i = 0; i < shiftWorkingDays.length; i++) {
            if (shiftWorkingDays[i].work_hours !== null) {
                total += parseFloat(shiftWorkingDays[i].work_hours)
            }
        }

        return total > 0 ? parseFloat(total).toFixed(2) : '--'
    }

    const generateXLSXReport = async (unitID) => {
        let correctedRecordsQuery = '&ignore_attendance_correction_preference=true'
        if (showCorrectedRecords) correctedRecordsQuery = ''
        let year = filters.year
        let month = filters.month
        const firstDay = moment(year + '-' + month + '-01')
            .startOf('month')
            .format('YYYY-MM-DD')
        const lastDay = moment(year + '-' + month + '-01')
            .endOf('month')
            .format('YYYY-MM-DD')
        await fileDownload(
            'GET',
            API.API_URL +
                `/exports/simplified_dynamic_attendance/?is_blob=true&unit=${unitID}&date_from=${firstDay}&date_to=${lastDay}&timezone=${getTimezoneAsString()}${correctedRecordsQuery}`,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            `${t('monthly_attendance')} - ${t('employees')} - ${filters.month + '-' + filters.year}.xlsx`
        )
    }

    useEffect(() => {
        const fetchPreferences = async () => {
            const requestVoucherPref = await requests.get(
                API.PREFERENCES + 'attendance_preferences__global_lunch_voucher_preferences/'
            )
            if (requestVoucherPref.status === 200) {
                setViewVoucher(parseInt(requestVoucherPref?.response?.value || 0) > 0)
            }
        }

        fetchPreferences()
    }, [])

    useEffect(() => {
        setFirstLoad(true)
    }, [filters])

    return (
        <>
            <Grid stackable>
                <Grid.Row verticalAlign="middle" columns={2}>
                    <Grid.Column width={14}>
                        <Form onSubmit={fetchMonthlyAttendance}>
                            <Form.Group widths={6}>
                                <SuperField
                                    as="yearpicker"
                                    width="2"
                                    value={filters.year}
                                    label={t('year')}
                                    dateFormat="YYYY"
                                    onChange={(e, { value }) => setFilters({ ...filters, year: value })}
                                />
                                <SuperField
                                    as="choice"
                                    search
                                    width="2"
                                    label={t('month')}
                                    clearable={false}
                                    value={filters.month}
                                    customOptions={months}
                                    onChange={(e, { value }) => setFilters({ ...filters, month: value })}
                                />

                                <SuperField
                                    as="choice-select"
                                    search
                                    required
                                    text="name"
                                    label={t('department')}
                                    value={filters.unit}
                                    onChange={(e, { value }) => setFilters((prev) => ({ ...prev, unit: value }))}
                                    endpoint={API.UNITS}
                                    additionalFilters={
                                        '?query={id, name}&only_basic_info=true&only_from_active_company=true' +
                                        (canManageOnlyAssignedEmployees && profile_id
                                            ? '&responsible_persons=' + profile_id
                                            : '')
                                    }
                                />
                                <SuperField
                                    as="choice-select"
                                    search
                                    text="title"
                                    label={t('work_position_group')}
                                    value={filters.position_group}
                                    endpoint={API.POSITIONS_GROUPS}
                                    additionalFilters={'&query={id, title}&only_basic_info=true'}
                                    onChange={(e, { value }) =>
                                        setFilters((prev) => ({ ...prev, position_group: value }))
                                    }
                                />

                                <Form.Field style={{ marginTop: '1.7rem' }}>
                                    <Button
                                        primary
                                        content={t('confirm')}
                                        disabled={filters.month === '' || filters.year === '' || filters.unit === ''}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <SuperField
                                as="checkbox"
                                label={t('show_corrected_records')}
                                checked={showCorrectedRecords}
                                onChange={() => setShowCorrectedRecords(!showCorrectedRecords)}
                            />
                        </Form>
                    </Grid.Column>
                    <Grid.Column textAlign="right" width={2}>
                        {!loading && !firstLoad && (
                            <>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="table-to-excel-action"
                                    table="table-to-xls"
                                    filename={t('monthly_attendance') + ' - ' + filters.month + '-' + filters.year}
                                    sheet={t('monthly_attendance') + ' - ' + filters.month + '-' + filters.year}
                                    buttonText={
                                        <Popup
                                            position="left center"
                                            trigger={<Icon name="download-outline" />}
                                            content={
                                                t('download_summary') +
                                                ' - ' +
                                                t('monthly_attendance') +
                                                ' - ' +
                                                filters.month +
                                                '/' +
                                                filters.year
                                            }
                                        />
                                    }
                                />

                                <Popup
                                    position="left center"
                                    trigger={
                                        <Icon
                                            name="documents-outline"
                                            style={{
                                                cursor: 'pointer',
                                                marginLeft: '1rem',
                                                fontSize: '1.5rem',
                                            }}
                                            onClick={() => generateXLSXReport(filters.unit)}
                                        />
                                    }
                                    content={
                                        t('download_individual_records') +
                                        ' - ' +
                                        t('monthly_attendance') +
                                        ' - ' +
                                        filters.month +
                                        '/' +
                                        filters.year
                                    }
                                />
                            </>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider />

            {firstLoad && (
                <Header as="h3" textAlign="center">
                    {t('choose_options_to_generate_report')}
                </Header>
            )}
            {!firstLoad && (
                <div style={{ padding: 0, overflow: 'auto', width: '100%' }}>
                    <Segment
                        loading={loading}
                        style={{
                            padding: 0,
                            margin: 0,
                            backgroundColor: 'transparent',
                            minHeight: '10rem',
                            border: 'none',
                            boxShadow: 'none',
                        }}
                    >
                        {loading && (
                            <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '8rem' }}>
                                {' '}
                                {t('loading_monthly_attendance')}{' '}
                            </p>
                        )}
                        {!loading && (
                            <Table
                                id="table-to-xls"
                                unstackable
                                className="table responsive"
                                style={{ fontSize: '0.9rem' }}
                            >
                                <Table.Header>
                                    <Table.Row verticalAlign="middle">
                                        <Table.HeaderCell
                                            colSpan={
                                                (viewVoucher ? 16 : 15) +
                                                weeks.length +
                                                days +
                                                (shifts.length !== 0 ? 2 : 0)
                                            }
                                            style={{ textAlign: 'center' }}
                                        >
                                            <Header as="h2">
                                                {t('monthly_attendance')} -{' '}
                                                <span style={{ textTransform: 'capitalize' }}>
                                                    {moment()
                                                        .locale(language)
                                                        .month(filters.month - 1)
                                                        .format('MMMM')}
                                                </span>{' '}
                                                {filters.year}
                                            </Header>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row verticalAlign="middle">
                                        <Table.HeaderCell width={2} singleLine>
                                            {t('employee_number')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3} singleLine>
                                            {t('fullname')}
                                        </Table.HeaderCell>
                                        {shifts.length > 0 && (
                                            <Table.HeaderCell
                                                width={2}
                                                singleLine
                                                style={{ textAlign: 'center', textTransform: 'capitalize' }}
                                            >
                                                {t('shift')}
                                            </Table.HeaderCell>
                                        )}

                                        {renderDayCells()}

                                        {shifts.length > 0 && (
                                            <Table.HeaderCell textAlign="center">{t('shift_hours')}</Table.HeaderCell>
                                        )}
                                        <Table.HeaderCell textAlign="center">{t('working_hours')}</Table.HeaderCell>
                                        {/* <Table.HeaderCell textAlign="center">{t('bonus')}</Table.HeaderCell> */}
                                        {/* <Table.HeaderCell textAlign="center">
                                            {t('overtimes')} ({t('hours_shortcut')}.)
                                        </Table.HeaderCell> */}
                                        <Table.HeaderCell textAlign="center">
                                            {t('saturday_shortcut')} ({t('hours_shortcut')}.)
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign="center">
                                            {t('sunday_shortcut')} ({t('hours_shortcut')}.)
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign="center">
                                            {t('holidays')} ({t('hours_shortcut')}.)
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign="center">
                                            {t('night')} ({t('hours_shortcut')}.)
                                        </Table.HeaderCell>
                                        {viewVoucher && (
                                            <Table.HeaderCell textAlign="center">{t('lunch_voucher')}</Table.HeaderCell>
                                        )}
                                        <Table.HeaderCell textAlign="center">{t('vacation')} (dni)</Table.HeaderCell>
                                        <Table.HeaderCell textAlign="center">
                                            {t('vacation')} ({t('hours_shortcut')}.)
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign="center">
                                            {t('work_absence')} ({t('hours_shortcut')}.)
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign="center">
                                            {t('total')} ({t('hours_shortcut')}.)
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {data?.employee_data_list?.map((employee) => (
                                        <Table.Row key={employee.id}>
                                            <Table.Cell width={2} singleLine style={{ fontWeight: 'bold' }}>{`${
                                                employee.custom_id_prefix || ''
                                            }${employee.custom_id || ''}`}</Table.Cell>
                                            <Table.Cell width={3} singleLine style={{ fontWeight: 'bold' }}>
                                                {employee.name} <br />
                                                <small>
                                                    {employee?.employer_name} ({employee?.position_title})
                                                </small>
                                            </Table.Cell>
                                            {renderHourCells(employee)}
                                            <Table.Cell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                {replaceDot(employee?.work_hours) || 0}
                                            </Table.Cell>
                                            <Table.Cell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                {replaceDot(employee?.saturday_hours) || 0}
                                            </Table.Cell>
                                            <Table.Cell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                {replaceDot(employee?.sunday_hours) || 0}
                                            </Table.Cell>
                                            <Table.Cell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                {replaceDot(employee?.holidays) || 0}
                                            </Table.Cell>
                                            <Table.Cell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                {replaceDot(employee?.night_hours) || 0}
                                            </Table.Cell>

                                            {viewVoucher && (
                                                <Table.Cell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                    {replaceDot(employee?.lunch_vouchers) || 0}
                                                </Table.Cell>
                                            )}
                                            <Table.Cell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                {replaceDot(calculateVacationDays(employee?.absences || [])) || 0}
                                            </Table.Cell>
                                            <Table.Cell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                {replaceDot(calculateVacationHours(employee?.absences || [])) || 0}
                                            </Table.Cell>
                                            <Table.Cell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                {replaceDot(calculateAbsences(employee?.absences || [])) || 0}
                                            </Table.Cell>
                                            <Table.Cell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                {replaceDot(employee?.total) || 0}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                                <Table.Footer>
                                    <Table.Row>
                                        <Table.HeaderCell
                                            colSpan={2 + days + weeks.length + (shifts.length !== 0 ? 2 : 0)}
                                            style={{ textAlign: 'right', fontWeight: 'bold', fontSize: '1.3rem' }}
                                        >
                                            {t('total')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {replaceDot(data?.overall_work_hours) || 0}
                                        </Table.HeaderCell>
                                        {/* <Table.HeaderCell
                                            style={{ fontWeight: 'bold', textAlign: 'center' }}
                                        ></Table.HeaderCell>
                                        <Table.HeaderCell
                                            style={{ fontWeight: 'bold', textAlign: 'center' }}
                                        ></Table.HeaderCell> */}
                                        <Table.HeaderCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {replaceDot(data?.overall_saturday_hours) || 0}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {replaceDot(data?.overall_sunday_hours) || 0}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {replaceDot(data?.overall_holiday_hours) || 0}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {replaceDot(data?.overall_night_hours) || 0}
                                        </Table.HeaderCell>
                                        {viewVoucher && (
                                            <Table.HeaderCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                {replaceDot(data?.overall_lunch_vouchers) || 0}
                                            </Table.HeaderCell>
                                        )}
                                        <Table.HeaderCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {replaceDot(calculateVacationDays(data?.total_absences || [])) || 0}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {replaceDot(calculateVacationHours(data?.total_absences || [])) || 0}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {replaceDot(calculateAbsences(data?.total_absences || [])) || 0}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {replaceDot(data?.total) || 0}
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Footer>
                            </Table>
                        )}
                    </Segment>
                </div>
            )}
        </>
    )
}

export default MonthlyAttendanceReport
