import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Grid, Header, Icon } from 'semantic-ui-react'
import { Pie, HorizontalBar } from "react-chartjs-2";
import { FlexRow, FlexItem } from '@components/tables/FlexTable'
import SuperField from '@components/forms/SuperField'


const MilestonesStats = ({ milestones }) => {
    const { t } = useTranslation()
    const [opened, setOpened] = useState(null)

    const TextStatRow = ({ name, value }) => (
        <FlexRow padding='0'>
            <FlexItem>{name}</FlexItem>
            <FlexItem><b>{value}</b></FlexItem>
        </FlexRow>
    )
    const MilestoneHeader = () => {
        return (
            <FlexRow style padding='1rem' background={'var(--primary)'}>
                <FlexItem><b style={{ color: 'var(--white)' }}>{t('name')}</b></FlexItem>
                <FlexItem><b style={{ color: 'var(--white)' }}>{t('date_from')}</b></FlexItem>
                <FlexItem><b style={{ color: 'var(--white)' }}>{t('due_date')}</b></FlexItem>
                <FlexItem><b style={{ color: 'var(--white)' }}>{t('issues_count')}</b></FlexItem>
                <FlexItem basis="10%" />
            </FlexRow>
        )
    }
    const MilestoneRow = (props) => {
        const {
            closed_issues_count,
            due_date,
            from_date,
            id,
            issues_count,
            name,
            open_issues_count,
            participants,
            progress,
            total_estimated_time,
            total_time_spent
        } = props


        const colors = useSelector(state => state.global_pref.colors)
        const [type, setType] = useState('minutes')

        function handleTimeFormat(time) {
            if (type === "hours") return time / 60
            if (type === "minutes") return time
        }

        const pie_data = {
            labels: [t('estimated_time'), t('time_spent')],
            datasets: [
                {
                    data: [handleTimeFormat(total_estimated_time), handleTimeFormat(total_time_spent)],
                    backgroundColor: [
                        colors.primary,
                        colors.warning,
                    ],
                },
            ],
        };

        const labels = participants.map(({ fullname }) => fullname)
        const horizontalbar_data = {
            labels,
            datasets: [
                {
                    label: 'Hours',
                    data: participants.map(({ hours }) => hours),
                    backgroundColor: colors.primary,
                },
            ],
        };
        return (
            <>
                <FlexRow padding='1rem' onClick={() => setOpened(opened === id ? null : id)} customClassName="project-stats-hoverable">
                    <FlexItem>{name}</FlexItem>
                    <FlexItem>{from_date}</FlexItem>
                    <FlexItem>{due_date}</FlexItem>
                    <FlexItem>{issues_count}</FlexItem>
                    <Icon name={`angle ${opened === id ? "up" : "down"}`} onClick={() => setOpened(opened === id ? null : id)} />
                </FlexRow>
                {opened === id &&
                    <Grid style={{ width: '100%', padding: '1rem', border: '1px solid var(--border)', }}>
                        <Grid.Row>
                            <Grid.Column width={8} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                <TextStatRow name={t('issues_count')} value={issues_count} />
                                <TextStatRow name={t('open')} value={open_issues_count} />
                                <TextStatRow name={t('closed')} value={closed_issues_count} />
                                <TextStatRow name={t('progress')} value={`${progress}${progress ? '%' : ''}`} />
                            </Grid.Column>
                            {(total_estimated_time || total_time_spent) &&
                                <Grid.Column width={8}>
                                    <Pie data={pie_data} />
                                    <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem 0' }}>
                                        <SuperField
                                            style={{ marginRight: '1rem' }}
                                            as="radio"
                                            label={t('hours')}
                                            checked={type === "hours"}
                                            onChange={() => setType("hours")}
                                        />
                                        <SuperField
                                            as="radio"
                                            label={t('minutes')}
                                            checked={type === "minutes"}
                                            onChange={() => setType("minutes")}
                                        />
                                    </div>
                                </Grid.Column>
                            }
                        </Grid.Row>
                        <Grid.Row>
                            <FlexRow padding='0'>
                                <Header content={t('participants')} as="h2" />
                            </FlexRow>
                            {participants.length > 0 &&
                                <HorizontalBar data={horizontalbar_data}
                                    width={80}
                                    height={participants.length * 4}
                                />
                            }
                        </Grid.Row>
                    </Grid>
                }
            </>
        )
    }

    return (
        <Grid style={{ width: '100%', padding: 0, }}>
            <MilestoneHeader />
            {milestones?.map((item, key) => (
                <MilestoneRow {...item} key={key} />
            ))}
        </Grid>
    );
};

export default MilestonesStats;