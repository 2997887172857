

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Divider, Button, Icon, Input } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable'
import EmptyRow from '@components/tables/EmptyRow';


const CallReportsTable = ({datas, setVisible, visible, search, setSearch}) => {
    const { t } = useTranslation()

    return (
        <>
        <FlexRow padding="0">
            <FlexItem>
                <Header as="h2">
                    <strong style={{ float: "left" }}>{t("list_of_calls")}</strong>
                </Header>
            </FlexItem>
            <FlexItem>
                <Input size="small" icon='search' value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t('search')} style={{ width: "100%" }} />
            </FlexItem>
            <FlexItem basis="20%" textAlign="right">
                <Button
                    size="small"
                    style={{
                    background: "transparent",
                    color: "var(--black)",
                    paddingLeft: "1rem",
                    paddingRight: "1rem"
                    }}
                    onClick={() => setVisible(!visible)}
                >
                    <Icon name='filter' style={{ margin: 0 }} />
                </Button>

            </FlexItem>
        </FlexRow>

        <br />
        <Divider />
            <FlexTable>
                <FlexRow>
                    <FlexHeader content={t("agent")} />
                    <FlexHeader content={t("number")} basis="100%" />
                    <FlexHeader content={t("type")} />
                    <FlexHeader content={t("duration")} />
                    <FlexHeader content={t("start")} />
                    <FlexHeader content={t("end")} />
                </FlexRow>
                <EmptyRow length={datas?.data?.results?.length} />
                {datas?.data?.results?.map(object =>
                    <FlexRow key={object.id} fontSize="1rem" borders>
                        <FlexItem>
                            <strong>{object?.agent_firstname} {object?.agent_lastname}</strong> <br />
                            <small>{object?.agent}</small>
                        </FlexItem>
                        <FlexItem>
                            <strong>{object?.number}</strong> <br />
                            <small>{object?.name}</small>
                        </FlexItem>
                        <FlexItem>{object?.call_type} </FlexItem>
                        <FlexItem>{object?.duration} </FlexItem>
                        <FlexItem>{object?.call_start_time_utc} </FlexItem>
                        <FlexItem>{object?.call_end_time_utc} </FlexItem>
                    </FlexRow>
                )}
            </FlexTable>
        </>
    );
};

export default CallReportsTable;