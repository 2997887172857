import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import SuperField from '@components/forms/SuperField';
import NonFieldErrors from '@components/NonFieldErrors';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import { Button, Divider, Form } from 'semantic-ui-react';

const StoreAdditionalItemToGroup = ({ onClose, storedGroupedItem, setStoredItems }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [view, setView] = useState(1)
    const [storedItem, setStoredItem] = useState("")
    const [items, setItems] = useState([])
    
    const handleCodeAutocomplete = (storedGroupedItem) => {
        let split_values = storedGroupedItem.code.split('-')
        let total_items = storedGroupedItem.all_children.length
        let code = ""
        if (split_values.length > 1){
            let second_value = split_values?.[1] // expecting code value that should be increased
            if (second_value !== undefined && second_value !== ""){
                second_value = parseInt(second_value)
                code = second_value + total_items + 1
            }
        }

        if (code === "") {
            code = total_items + 1
        }

        return split_values[0] + " - " + code
    }

    const [form, setForm] = useState({
        code: handleCodeAutocomplete(storedGroupedItem),
        item: "",
        sn: null,
        quantity: 1,
        note: "",
    })

    useEffect(() => {
        async function fetchStoredItems(){
            const handleOptionText = (storedItem) => {
                let code = storedItem?.code ? storedItem?.code + ":" : ""
                let title = storedItem?.item?.title || ""
                let serial_number = (storedItem.sn !== "" && storedItem.sn !== null && storedItem.sn !== undefined) ? "(" + storedItem.sn + ")" : ""
                return code + " " + title + " " + serial_number
            }

            let warehouse_filter = ""
            if (storedGroupedItem.warehouse?.id !== undefined){
                warehouse_filter = "&warehouse=" + storedGroupedItem.warehouse.id
            }
            const request = await requests.get(API.ASSET_STORED_ITEMS + "?ordering=code&query={id, code, sn, group, item {title}}&only_groups=false" + warehouse_filter)
            if (request.status === 200) {
                setItems(request.response.filter(item => item.group === null).map(storedItem => ({
                    key: storedItem.id, value: storedItem.id,
                    text: handleOptionText(storedItem)
                })))
            }
        }

        fetchStoredItems()
    }, [storedGroupedItem])

    const handleSubmit = async () => {
        setIsProcessing(true)

        const request = await requests.post(API.ASSET_STORED_ITEMS, {
            ...form,
            group: storedGroupedItem.id,
            warehouse: storedGroupedItem?.warehouse?.id || null,
        })

        if (request.status === 400) setErrors(request.response)
        if (request.status === 201){
            const getStoredItem = await requests.get(API.ASSET_STORED_ITEMS + storedGroupedItem.id + "/")
            if (getStoredItem.status === 200){
                setStoredItems(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        results: prev.data.results.map(storedItem => {
                            if( storedItem.id === storedGroupedItem.id ){
                                storedItem = getStoredItem.response
                            }

                            return storedItem
                        })
                    }
                }))
                onClose()
            }
        } 

        setIsProcessing(false)
    }

    const handleSelectedItem = async () => {
        setErrors(null)
        setIsProcessing(true)

        const request = await requests.patch(API.ASSET_STORED_ITEMS + storedItem + "/", {
            group: storedGroupedItem.id
        })

        if (request.status === 400) setErrors(request.response)
        if (request.status === 200){
            const getStoredItem = await requests.get(API.ASSET_STORED_ITEMS + storedGroupedItem.id + "/")
            if (getStoredItem.status === 200){
                setStoredItems(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        results: prev.data.results.map(storedItem => {
                            if( storedItem.id === storedGroupedItem.id ){
                                storedItem = getStoredItem.response
                            }

                            return storedItem
                        })
                    }
                }))
                onClose()
            }
        } 
        setIsProcessing(false)
    }

    return (
        <>
            <Button.Group basic fluid size="small">
                <Button active={view === 1} onClick={() =>setView(1)}> {t('new_item')} </Button>
                <Button active={view === 2} onClick={() =>setView(2)}> {t('existing_stored_item')} </Button>
            </Button.Group>
            <Divider/>
            { view === 1 && 
                <Form onSubmit={handleSubmit}>
                    <SuperField as="input" 
                        style={{ opacity: "0.9" }}
                        required
                        label={t('code')}
                        placeholder={t('code')}
                        value={form.code}
                        onChange={(e, { value }) => setForm(prev => ({...prev, code: value}))}
                    />

                    <SuperField as="choice"
                        search 
                        required
                        text="title"
                        label={t('item')}
                        value={form.item}
                        placeholder={t('item')}
                        error={errors?.item || false}
                        endpoint={API.ASSET_ITEMS + "?query={id, title}"}
                        onChange={(e, { value }) => setForm(prev => ({...prev, item: value}))}
                    />

                    <SuperField as="input"
                        label={t('serial_number')}
                        value={form.sn}
                        placeholder={t('serial_number')}
                        onChange={(e, { value }) => setForm(prev => ({...prev, sn: (value !== "" ? value : null)}))}
                        error={errors?.sn || false}
                    />

                    <SuperField as="input"
                        type="number"
                        value={form.quantity}
                        label={t('quantity')}
                        placeholder={t('quantity')}
                        error={errors?.quantity || false}
                        onChange={(e, { value }) => setForm(prev => ({...prev, quantity: value}))}
                    />

                    <SuperField as="textarea"
                        label={t('note')}
                        value={form.note}
                        placeholder={t('note')}
                        error={errors?.note || false}
                        onChange={(e, { value }) => setForm(prev => ({...prev, note: value}))}
                    />

                    <Divider/>
                    <Form.Field style={{ textAlign: 'right' }}>
                        <ModalCancel 
                            onClose={onClose}
                            disabled={isProcessing}
                        />
                        <ModalSubmit
                            loading={isProcessing}
                            content={t('confirm')}
                            disabled={isProcessing || form.item === "" || form.item === null}
                        />
                    </Form.Field>
                </Form>
            }
            { view === 2 && 
                <Form onSubmit={handleSelectedItem}>
                    <NonFieldErrors errors={errors} />
                    <SuperField as="choice"
                        search
                        label={t('stored_item')}
                        customOptions={items}
                        value={storedItem}
                        error={errors?.group || false}
                        onChange={(e, { value }) => setStoredItem(value)}
                    />

                    <Divider/>
                    <Form.Field style={{ textAlign: 'right' }}>
                        <ModalCancel 
                            onClose={onClose}
                            disabled={isProcessing}
                        />
                        <ModalSubmit
                            loading={isProcessing}
                            content={t('confirm')}
                            disabled={isProcessing || storedItem === "" || storedItem === null}
                        />
                    </Form.Field>
                </Form>
            }
        </>
    );
};

export default StoreAdditionalItemToGroup;