import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import EmptyRow from '@components/tables/EmptyRow';
import SuperField from '@components/forms/SuperField';
import SortedFileRenderer from '@components/SortedFileRenderer';
import { Form, Button, Divider, Header } from 'semantic-ui-react';


const VehicleDocuments = ({ vehicleID, attachments, setData, onClose }) => {
    const { t } = useTranslation()
    const [files, setFiles] = useState([])
    const [isProcessing, setIsProcessing] = useState(false)

    const onSubmit = async () => {
        setIsProcessing(true)
        const formData = new FormData()
        for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i])
        }
        
        const request = await requests.post(API.ATTACHMENTS_BULK, formData)
        if (request.status === 201) {
            setFiles([])
            document.getElementById('fileInput').value = null

            // patch request for vehicles to update attachments
            const requestUpdate = await requests.patch(API.VEHICLES + vehicleID + "/", { 
                attachments: { add: request.response.map(item => item.id) },
            })

            if (requestUpdate.status === 200) {
                setData(prev => prev.filter(item => {
                    if (item.id === vehicleID) {
                        item.attachments = requestUpdate.response.attachments
                    }
                    return item
                }))
            }
        }
        setIsProcessing(false)
    }

    const onDelete = async (id) => {
        const request = await requests.del(API.ATTACHMENTS + id + '/')

        if (request.status === 204) {
            setData(prev => prev.filter(item => {
                if (item.id === vehicleID) {
                    item.attachments = attachments.filter(attachment => attachment.id !== id)
                }

                return item
            }))
        }
    }

    return (
        <div>
            <CanView permissions={['vehicles.c_manage_all_vehicles']}>
                <Form onSubmit={onSubmit}>
                    <Form.Group>
                        <SuperField as="input"
                            multiple={true}
                            type="file"
                            width="10"
                            id="fileInput"
                            ref={(el) => console.log(el)}
                            label={t('upload_files')}
                            onChange={(event) => {
                                if( event.target?.files?.length > 0 ){
                                    setFiles(event.target.files)
                                }
                            }}
                        />
                        <Form.Field width="6">
                            <Button
                                fluid
                                primary
                                style={{ marginTop: "1.8rem" }}
                                loading={isProcessing}
                                disabled={isProcessing}
                                content={t('confirm')}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
                <Divider/>
            </CanView>
            <Header as="h3" content={`${t('documents')} (${attachments.length})`}/>
            <EmptyRow length={attachments.length}/>
            { attachments.map(attachment => (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "middle" }}>
                    <SortedFileRenderer name={attachment.name} file={attachment.file} />
                    <Icon
                        name="close"
                        style={{
                            marginLeft: "1rem",
                            fontSize: "1.2rem",
                            color: "var(--danger)",
                            cursor: "pointer"
                        }}
                        onClick={() => onDelete(attachment.id)}/>
                </div>
            ))}
        </div>
    );
};

export default VehicleDocuments;