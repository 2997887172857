import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next"
// store
//components
import SuperField from '@components/forms/SuperField';
import CustomFormDivider from '@components/general/CustomFormDivider';
import { Button, Form, List, Header  } from 'semantic-ui-react';
import moment from 'moment';

const CallReportsFilterForm = ({ fetchDatas, setVisible, initState, filters, setFilters }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const history = useHistory()

    const [processing, setProcessing] = useState(false)
    const [invalidDateTimeRange, setInvalidDateTimeRange] = useState(false)

    // Validate Form
    useEffect( () => {
        if( filters.callEndTimeUtc !== "" && filters.callStartTimeUtc){
            if( moment(filters.callStartTimeUtc).isAfter(filters.callEndTimeUtc) ){
                setInvalidDateTimeRange(t('invalid_date_should_be_higher'))
            } else {
                setInvalidDateTimeRange(false)
            }
        }

        // eslint-disable-next-line
    }, [filters])

    const onFilter = async () => {
        setProcessing(true)
        history.replace({ pathname: location.pathname, search: `?page=${1}` });
        await fetchDatas("", true)
        setProcessing(false)
    }

    const resetFilters = () => {
        setFilters(initState)
    }

    return (
        <div>
            <List divided verticalAlign='middle'>
                <List.Item>
                    <List.Content>
                        <Header as="h2" content={t('advanced_filter')} style={{ marginTop: "1rem", paddingLeft: "1rem", textAlign: "left" }} />
                    </List.Content>
                </List.Item>
            </List>
            <Form style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "left" }}>
                <Form.Group widths="equal">
                    <SuperField as="choice"
                        search
                        value={filters.callType}
                        type="call_type"
                        label={t('call_type')}
                        onChange={(e, { value }) => setFilters({
                            ...filters,
                            callType: value
                        })}
                    />
                    <SuperField as="choice"
                        search
                        value={filters.callDirection}
                        type="call_direction"
                        label={t('call_direction')}
                        onChange={(e, { value }) => setFilters({
                            ...filters,
                            callDirection: value
                        })}
                    />
                </Form.Group>

                <CustomFormDivider
                    header = {t('valid_from_range')}
                    divider = {true}
                    content = {
                        <Form.Group widths="equal">
                        <SuperField as="datetimepicker"
                            label={ t('call_start') }
                            value={filters.callStartTimeUtc}
                            onChange={ (e, { value }) => setFilters({...filters, callStartTimeUtc: value})}
                            />
                        <SuperField as="datetimepicker"
                            label={ t('call_end') }
                            error={invalidDateTimeRange}
                            value={filters.callEndTimeUtc}
                            onChange={ (e, { value }) => setFilters({...filters, callEndTimeUtc: value})}
                        />
                        </Form.Group>
                    }
                />
                <Form.Field style={{ textAlign: "right"}}>
                    <Button size="medium" content={t('reset')} style={{}} onClick={() => resetFilters()}/>
                    <Button loading={processing} disabled={processing} size="medium" content={t('apply')}
                        style={{backgroundColor: "var(--primary)", color:"white"}}
                        onClick={() => {
                            onFilter()
                            setVisible(false)}
                    }/>
                </Form.Field>
            </Form>
        </div>
    );
};

export default CallReportsFilterForm;