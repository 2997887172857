const filtersReducer = (filters = {
    cost_centers: {},  
}, action) => {
    switch (action.type) {
        case "ADD_FILTERS":
            return {
                ...filters,
                cost_centers: action.payload.cost_centers
            };
        default:
            return filters;
    }
};

export default filtersReducer;