import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import { Form, Divider, Message } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import NonFieldErrors from '@components/NonFieldErrors'

const ContractTerminationForm = ({ record, setData, setHeader, onClose }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [removeFromOrder, setRemoveFromOrder] = useState(false)
    const [errors, setErrors] = useState([])
    const [form, setForm] = useState({
        contract: record.id,
        terminated_by: '',
        type: '',
        notice_period: '',
        note: '',
        reason: '',
    })

    useEffect(() => {
        setHeader(t('termination') + ' ' + record.employee.fullname_with_titles)
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsProcessing(true)
        setErrors([])

        const request = await requests.post(API.CONTRACTS_TERMINATIONS, {
            ...form,
            notice_period: form.notice_period === '' ? null : form.notice_period,
            remove_from_order: removeFromOrder,
        })

        if (request.status === 400) setErrors(request.response)
        if (request.status === 201) {
            const requestContract = await requests.patch(API.CONTRACTS + record.id + '/', {
                status:
                    !moment(form.notice_period).isValid() === ''
                        ? 2
                        : !moment(form.notice_period).isBefore(moment(), 'day')
                        ? 3
                        : 2,
            })
            if (requestContract.status === 200) {
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === record.id) {
                            item = requestContract.response
                        }

                        return item
                    })
                )
                onClose()
            }
        }

        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <NonFieldErrors errors={errors} />

            {record.employment_item && (
                <>
                    <Message info visible style={{ marginTop: 0 }}>
                        <strong>
                            {t('termination_order_remove_hint_1')} {record.order_name},{' '}
                            {t('termination_order_remove_hint_2')}
                        </strong>
                        <Form.Group style={{ marginTop: '1rem' }}>
                            <SuperField
                                as="checkbox"
                                checked={removeFromOrder === true}
                                label={t('yes')}
                                onChange={() => setRemoveFromOrder(true)}
                            />
                            <SuperField
                                as="checkbox"
                                checked={removeFromOrder === false}
                                label={t('no')}
                                onChange={() => setRemoveFromOrder(false)}
                            />
                        </Form.Group>

                        <span>
                            {t('termination_order_remove_hint_3')} <br /> {t('termination_order_remove_hint_4')}
                        </span>
                    </Message>
                    <Divider />
                </>
            )}

            <Form.Group widths="equal">
                <SuperField
                    as="choice"
                    required
                    label={t('terminated_by')}
                    type="contract_termination_actors"
                    value={form.terminated_by}
                    onChange={(e, { value }) => setForm({ ...form, terminated_by: value })}
                    error={errors?.terminated_by?.[0] || false}
                />
                <SuperField
                    as="choice"
                    required
                    label={t('termination_method')}
                    type="contract_termination_types"
                    value={form.type}
                    onChange={(e, { value }) => setForm({ ...form, type: value })}
                    error={errors?.type?.[0] || false}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="datepicker"
                    required
                    closable
                    label={t('notice_period')}
                    value={form.notice_period}
                    onChange={(e, { value }) => setForm({ ...form, notice_period: value })}
                    error={errors?.notice_period?.[0] || false}
                />
                <SuperField
                    as="choice-select"
                    search
                    text="title"
                    allowAdditions
                    value={form.reason}
                    label={t('reason')}
                    error={errors?.reason?.[0] || false}
                    endpoint={API.CONTRACTS_TERMINATION_REASONS}
                    additionsConfig={{
                        attribute: 'title',
                        endpoint: API.CONTRACTS_TERMINATION_REASONS,
                    }}
                    help={t('reasons_type_and_hit_enter_to_create_hint')}
                    onChange={(e, { value }) => setForm({ ...form, reason: value })}
                />
            </Form.Group>
            <SuperField
                as="textarea"
                label={t('note')}
                value={form.note}
                onChange={(e, { value }) => setForm({ ...form, note: value })}
                error={errors?.note?.[0] || false}
            />

            <Divider />
            <Form.Group style={{ marginBottom: 0 }}>
                <Form.Field width="8" />
                <Form.Field width="8" style={{ textAlign: 'right' }}>
                    <ModalCancel onClose={onClose} />
                    <ModalSubmit
                        loading={isProcessing}
                        disabled={
                            isProcessing || form.terminated_by === '' || form.type === '' || form.notice_period === ''
                        }
                        text={t('confirm')}
                    />
                </Form.Field>
            </Form.Group>
        </Form>
    )
}

export default ContractTerminationForm
