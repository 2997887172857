import React from 'react';
import { useTranslation } from "react-i18next"
//components
import { Form } from 'semantic-ui-react';
import ChoiceField from './ChoiceField';

const AddressFieldGroup = ({ address, setAddress, isDisabled, countries }) => {
    const { t } = useTranslation();

    return (
        <div>
            { countries !== undefined 
                ?
                    <ChoiceField
                        search
                        required
                        disabled={isDisabled}
                        label={t('country')}
                        customOptions={countries}
                        value={address.country}
                        onChange={(e, data) => setAddress({
                            ...address,
                            country: data.value,
                        })}
                    />
                : 
                    <ChoiceField
                        search
                        required
                        disabled={isDisabled}
                        type="countries"
                        label={t('country')}
                        value={address.country}
                        onChange={(e, data) => setAddress({
                            ...address,
                            country: data.value,
                        })}
                    />
            }

            <Form.Group widths='equal'>
                <Form.Input
                    required
                    disabled={isDisabled}
                    label={t('street')}
                    placeholder={t('street')}
                    value={address.street}
                    onChange={(e) => setAddress({
                        ...address,
                        street: e.target.value
                    })
                    }
                />

                <Form.Input
                    fluid
                    disabled={isDisabled}
                    label={t('address_line')}
                    placeholder={t('address_line')}
                    value={address.addressLine}
                    onChange={(e) => setAddress({
                        ...address,
                        addressLine: e.target.value
                    })
                    }
                />
            </Form.Group>

            <Form.Group>
                <Form.Input
                    required
                    width="4"
                    disabled={isDisabled}
                    label={t('postcode')}
                    placeholder={t('postcode')}
                    value={address.postcode}
                    onChange={(e) => setAddress({
                        ...address,
                        postcode: e.target.value
                    })
                    }
                />

                <Form.Input
                    required
                    width="6"
                    disabled={isDisabled}
                    label={t('city')}
                    placeholder={t('city')}
                    value={address.city}
                    onChange={(e) => setAddress({
                        ...address,
                        city: e.target.value
                    })
                    }
                />

                <Form.Input
                    width="6"
                    label={t('region')}
                    disabled={isDisabled}
                    value={address.state}
                    onChange={(e) => setAddress({
                        ...address,
                        state: e.target.value
                    })}
                />

            </Form.Group>
        </div>
    );
};

export default AddressFieldGroup;