import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import Cookies from "js-cookie";
// store
import { routes } from '@routes';
import { requests } from '@helpers/requests';
import { authService } from '@services/ServiceAuth';
import { addTrackerData } from '@store/actions/'
// custom components
import WarehousingVerification from './components/WarehousingVerification';
import WarehouseManagement from './components/WarehouseManagement';

const WarehouseTracker = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const tracker = useSelector(state => state.tracker)

    const [mode, setMode] = useState(tracker?.mode || 1)
    const [employee, setEmployee] = useState(tracker?.employee || null)
    const [buildVersion, setBuildVersion] = useState("")

    useEffect(() => {
        async function fetchBuildVersion() {
            const request = await requests.get('/meta.json')
            if (request.status === 200) {
                setBuildVersion(request.response.buildDate)
            }
        }

        fetchBuildVersion()
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            async function checkUser() {
                const result = await authService.validateToken();
                if( result === false ){
                    const refresh = Cookies.get("refreshToken");
                    // attempt to refreshLogin with refresh token, if true then return new access token
                    if (refresh) {
                        const refreshAttempt = await authService.refreshLogin(refresh);
                        if (!refreshAttempt) {
                            history.push(routes.LOGIN);
                        }
                    } else {
                        history.push(routes.LOGIN);
                    }
                }
            }
    
            checkUser();
        }, 60000) // verify access token each minute (60 seconds)

        return () => clearInterval(interval)
    })

    const handleTrackerChange = (newData) => {
        const prev = tracker
        dispatch(addTrackerData({...prev, ...newData}))
    }

    return (
        <>
            { mode === 1 && 
                <WarehousingVerification
                    setMode={setMode}
                    setEmployee={setEmployee}
                    buildVersion={buildVersion}
                    handleTrackerChange={handleTrackerChange}
                />
            }
            { mode === 2 && 
                <WarehouseManagement
                    setMode={setMode}
                    employee={employee}
                    setEmployee={setEmployee}
                    tracker={tracker}
                    handleTrackerChange={handleTrackerChange}
                />
            }
        </>
    )
}

export default WarehouseTracker;