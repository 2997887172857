import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import CanView from '@components/perms/CanView';
import Action from '@components/general/Action';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Table, Grid, Divider, Header, Segment } from 'semantic-ui-react';
// custom
import AllowedUserForm from '../components/AllowedUserForm';
import AllowedUserDocumentsForm from '../components/AllowedUserDocumentsForm';

const ResponsiblePersonsView = ({ vehicle }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector(store => store?.date_format || "YYYY-MM-DD")
    const [fetching, setFetching] = useState(true)
    const [data, setData] = useState([])

    useEffect(() => {
        async function fetchData(){
            setFetching(true)
            const query = "&query={id, valid_until, contract{id, employee}}"
            const request = await requests.get(API.VEHICLES + `allowed_users/?vehicle=${vehicle.id}&ordering=-valid_until${query}`)
            if (request.status === 200) {
                setData(request.response)
            }
            setFetching(false)
        }

        fetchData()
    }, [vehicle.id])

    const onDelete = async (record) => {
        const request = await requests.del(API.VEHICLES + "allowed_users/" + record.id + "/")
        if (request.status === 204) {
            setData(prev => prev.filter(data => data.id !== record.id))
        }
    }

    return (
        <>
            <Grid>
                <Grid.Row verticalAlign="middle" style={{ padding: 0 }}>
                    <Grid.Column style={{ display: "flex", padding: 0 }}>
                        <div style={{ display: "flex" }}>
                            <Header as="h4" content={t('responsible_persons')} style={{ marginTop: "1rem" }}/>
                            <CanView permissions={['vehicles.c_manage_all_vehicles']}>
                                <Action as="modal" type="icon"
                                    modalSize="tiny"
                                    tooltip={t('add')}
                                    size="huge"
                                    iconColor="var(--dark)"
                                    header={t('add_responsible_person')}
                                    icon="add-outline"
                                    modal={ 
                                        <AllowedUserForm
                                            vehicle={vehicle}
                                            setData={setData}
                                        />
                                    }
                                />
                            </CanView>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider style={{ marginTop: 0 }}/>
            <Segment loading={fetching} style={{ padding: 0, margin: 0, backgroundColor: "transparent", minHeight: "10rem", border: "none", boxShadow: "none" }}>
                { fetching && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "8rem" }}> { t('loading_responsible_persons') } </p> }
                { !fetching && 
                    <div style={{ padding: 0, overflow: "auto", width: "100%" }}>
                        <Table size="small" striped selectable unstackable className="table responsive">
                            <Table.Header>
                                <Table.Row verticalAlign="middle">
                                    <Table.HeaderCell> {t('fullname')} </Table.HeaderCell>
                                    <Table.HeaderCell> {t('valid_until')} </Table.HeaderCell>
                                    <Table.HeaderCell width="3"> { t('actions')} </Table.HeaderCell>
                                </Table.Row>
                                { data.length > 0 
                                    ? 
                                        data.map(responsible_person => (
                                            <Table.Row verticalAlign="middle" key={responsible_person.id}>
                                                <Table.Cell> { responsible_person?.contract?.employee?.fullname || "--" } </Table.Cell>
                                                <Table.Cell> { responsible_person.valid_until ? moment(responsible_person.valid_until).format(dateFormat) : "--" } </Table.Cell>
                                                <Table.Cell width="3"> 
                                                    <FlexRow padding="0" background="transparent">
                                                        <CanView permission={['vehicles.c_manage_all_vehicles']}>
                                                            <FlexItem>
                                                                <Action
                                                                    as={"modal"}
                                                                    type='icon'
                                                                    size="huge"
                                                                    paddingTop="0"
                                                                    paddingBottom="0"
                                                                    modalSize="tiny"
                                                                    icon={"document-text-outline"}
                                                                    tooltip={t('generate_documents')}
                                                                    header={t('generate_documents')}
                                                                    modal={
                                                                        <AllowedUserDocumentsForm
                                                                            vehicle={vehicle}
                                                                            record={responsible_person}
                                                                        />}
                                                                    iconColor={"var(--dark)"}
                                                                />
                                                            </FlexItem>
                                                        </CanView>
                                                        <CanView permission={['vehicles.c_manage_all_vehicles']}>
                                                            <FlexItem>
                                                                <Action
                                                                    modalSize="tiny"
                                                                    type='icon'
                                                                    size="huge"
                                                                    icon={"pencil-outline"}
                                                                    as={"modal"}
                                                                    paddingTop="0"
                                                                    paddingBottom="0"
                                                                    tooltip={t('edit')}
                                                                    modal={
                                                                        <AllowedUserForm
                                                                            vehicle={vehicle}
                                                                            record={responsible_person}
                                                                            setData={setData}
                                                                        />}
                                                                    iconColor={"var(--dark)"}
                                                                />
                                                            </FlexItem>
                                                        </CanView>
                                                        <CanView permission={['vehicles.c_manage_all_vehicles']}>
                                                            <FlexItem>
                                                                <Action
                                                                    as='delete'
                                                                    size="huge"
                                                                    tooltip={t('delete')}
                                                                    text={t('are_you_sure_that_you_want_to_delete_this_responsible_person')}
                                                                    onClick={() => onDelete(responsible_person)}
                                                                />
                                                            </FlexItem>
                                                        </CanView>
                                                    </FlexRow>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))
                                    :
                                        <Table.Row verticalAlign="middle">
                                            <Table.Cell colspan={3} textAlign="center" style={{ fontWeight: 'bold' }}>
                                                { t('no_data') }
                                            </Table.Cell>
                                        </Table.Row>    
                                }
                            </Table.Header>
                        </Table>
                    </div>
                }
            </Segment>
        </>
    );
};

export default ResponsiblePersonsView;