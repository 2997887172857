import React, { Fragment, useState } from 'react'
import { useTranslation } from "react-i18next"
//store
import { isEmpty, isPasswordError, isRepeatPasswordError } from "@helpers/validation"
import { requests } from '@helpers/requests';
import { API } from '@store/config';
//components
import { Form, Button, Message } from 'semantic-ui-react'

function ChangePasswordForm(props) {
    const { t } = useTranslation();

    const [new_password, setNewPassword] = useState("");
    const [re_new_password, setRepeatPassword] = useState("");
    const [current_password, setCurrentPassword] = useState("");
    const [errors, setErrors] = useState([]); // empty array for error messages
    const [isProcessing, setIsProcessing] = useState(false); // this is for handling if form is currently processing our request

    // Form HandleSubmit Function (Async/Await):
    const handleSubmit = async (event) => {
        event.preventDefault(); // to prevent default behaviour for form submission

        setIsProcessing(true); // form processing started
        let errorsArray = [];  // for error response if there are any on failure
        // create data object for API Call:
        const data = {
            new_password: new_password,
            current_password: current_password
        }

        // call API Endpoint using async/await function from ServiceUser.js
        const result = await requests.post(API.SET_PASSWORD, data);

        // DOC: https://djoser.readthedocs.io/en/latest/base_endpoints.html#set-password
        if (result.status === 204) { // 204 means success with empty response object (based on documentation)
            // on success return new password that was changed
            props.onConfirm(data.new_password);
            props.onClose() // close modal window
        } else {
            // password change wasn't successfull handle error response
            if (result.response.new_password) { // if there is error for new_password
                errorsArray.push(result.response.new_password)
            }

            if (result.response.current_password) { // if there is error for new_password
                errorsArray.push(result.response.current_password)
            }

            setErrors(errorsArray); // we are setting errors state with error messages if there are any
        }

        setIsProcessing(false); // form processing ended
    }


    // Simple Component for error message handling (just for example... usage could be improved)
    const ErrorMessages = () => {
        return (
            <Fragment>
                {errors.length > 0 ?
                    <Message negative>
                        {   // this is like forloop cycle we are looping over error array to print errors
                            errors.map((error, index) =>
                                <p key={index}>
                                    {error}
                                </p>
                            )
                        }
                    </Message>
                    : ''}
            </Fragment>
        );
    }

    return (
        <Form onSubmit={handleSubmit}>

            {errors.length > 0 // print error messages if there are any
                ? <ErrorMessages />
                : ''
            }

            <Form.Field>

                <Form.Input
                    autoFocus
                    type='password'
                    required
                    label={props.labelNewPassword}
                    placeholder={props.placeholderNewPassword}
                    value={new_password}
                    onChange={(e) =>
                        setNewPassword(e.target.value)
                    }
                    error={
                        !isEmpty(new_password)
                            ? isPasswordError(new_password)
                            : false
                    }
                />
                <Form.Input
                    type='password'
                    required
                    label={props.labelRepeatPassword}
                    placeholder={props.placeholderRepeatPassword}
                    value={re_new_password}
                    onChange={(e) =>
                        setRepeatPassword(e.target.value)
                    }
                    error={
                        !isEmpty(re_new_password)
                            ? isRepeatPasswordError(new_password, re_new_password)
                            : false
                    }
                />
                <Form.Input
                    required
                    type='password'
                    label={props.labelOldPassword}
                    placeholder={props.placeholderOldPassword}
                    value={current_password}
                    onChange={(e) =>
                        setCurrentPassword(e.target.value)
                    }
                // error={
                //     !isEmpty(current_password)
                //         ? false
                //         : true
                // }
                />
            </Form.Field>
            <Form.Field style={{ textAlign: "right" }}>
                <Button
                    basic
                    type="button"
                    onClick={props.onClose}>
                    {t('cancel')}
                </Button>
                <Button
                    primary
                    loading={isProcessing}
                    type="submit"
                    disabled={isProcessing || isEmpty(current_password) || isEmpty(new_password) || isEmpty(re_new_password) ||
                        isRepeatPasswordError(new_password, re_new_password) || isPasswordError(new_password)}
                >
                    {t('confirm')}
                </Button>
            </Form.Field>
        </Form>
    )
}

export default ChangePasswordForm