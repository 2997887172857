import React from 'react';
// components
import { Accordion,Icon } from 'semantic-ui-react';

const AccordionItem = ({ index, isActive, title, content, children, onClick }) => {
    return (
        <>
            <Accordion.Title
                index={index}
                active={isActive}
                onClick={ onClick }
            >
                <Icon name='dropdown' />
                { title }
            </Accordion.Title>
            <Accordion.Content active={isActive}>
                { content ? content : children }
            </Accordion.Content>
        </>
    )
}

export default AccordionItem;