import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
// store
import { requests } from '@helpers/requests';
import { API } from '@store/config';
// components
import { Form, Message, Divider } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperField from '@components/forms/SuperField';

const MoveFileForm = ({ options, setGroupOptions, setGroups, file, onClose, onConfirm, moduleName }) => {
    const { t } = useTranslation();

    const [data, setData] = useState({
        id: file?.id,
        group: file?.group?.id || null
    })

    const [isProcessing, setIsProcessing] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleAddGroup = async (item) => {
        const request = await requests.post(API.ATTACHMENT_GROUPS, {
            name: item,
            module: moduleName
        })

        if (request.status === 201) {
            setGroupOptions([...options, { key: request.response.id, value: request.response.id, text: request.response.name }])
            setData({...data, group: request.response.id})
            setGroups((prev) => [request.response, ...prev])
        }
    }

    const handleSuccess = (response) => {
        onConfirm(file.id, file, response);
        onClose()
    }

    const handleErrors = (response) => {
        let errorsArray = [];
        if (response.non_field_errors) {
            errorsArray.push(response.non_field_errors)
        }
        if (response.deital) {
            errorsArray.push(response.detail)
        }
        if (response.name) {
            errorsArray.push(response.field_name)
        }
        setErrors(errorsArray);
    }

    const handleSubmit = async () => {
        setErrors([]);
        setIsProcessing(true);
        
        const formData = new FormData()
        formData.append('group', data.group)

        const request = await requests.patch(API.ATTACHMENTS + data.id + "/", formData)
        if (request.status === 200) {
            handleSuccess(request.response)
        } else {
            handleErrors(request.response)
        }

        setIsProcessing(false);
    }

    return (
        <Form onSubmit={handleSubmit}>
            {file.group && <p style={{ paddingTop: 0, marginTop: 0 }}><b>{t('from') + ": "}</b>{file.group.name}</p>}
            <Message
                error
                visible={errors.length > 0}
                header={t("error_submission_message")}
                list={errors}
            />
            <SuperField
                as="choice"
                allowAdditions
                help={ t('help_group_create') }
                search
                clearable
                onAddItem={(e, { value }) => handleAddGroup(value)}
                customOptions={options}
                label={t('att_group')}
                value={data.group}
                onChange={(e, { value }) => setData({...data, group: value})}
            />

            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing}
                    text={t('save')} />
            </Form.Field>
        </Form>
    );
};

export default MoveFileForm;