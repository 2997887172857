import React, { Fragment, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
// store
import { API } from '@store/config'
import { useSelectOptions } from '@helpers/hooks'
import { getRequest } from '@services/ServiceCommon'
//compponents
import AvatarIcon from '@components/AvatarIcon'
import { Divider, Grid, Header, Icon, Label, Segment } from 'semantic-ui-react'

const ProfileCard = ({ candidateData, candidateID }) => {
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true)
            if (candidateID !== undefined) {
                const request = await getRequest(API.CANDIDATES + candidateID + '/')
                if (request.status === 200) {
                    setData(request.response)
                }
            } else {
                setData(candidateData)
            }
            setIsLoading(false)
        }

        fetchData()
        // eslint-disable-next-line
    }, [])

    const { t } = useTranslation()
    const genders = useSelector((state) => state.choices?.genders)
    const educationTypes = useSelector((state) => state.choices?.education_type_choices)
    const [language_names] = useSelectOptions(API.COMMON + 'language_names', 'title')
    const langauges = language_names.options
    const languageLevels = useSelector((state) => state.choices?.language_level_choices)

    const EducationLine = ({ line }) => {
        return (
            <Fragment>
                <Header as="h4" content={line.name} style={{ marginBottom: 0, marginTop: '1rem' }} />
                <Header.Subheader content={educationTypes?.[line.education_type]} />
                <Divider />
            </Fragment>
        )
    }

    const ExperienceLine = ({ line }) => {
        return (
            <Grid.Row style={{ padding: 0 }}>
                <Header as="h4" content={line?.job_title} style={{ marginBottom: 0 }} />
                {line?.description !== '' && <Header.Subheader content={line?.description} />}
                <p>{' - ' + line?.employer}</p>
            </Grid.Row>
        )
    }

    const InterestsLine = ({ line }) => {
        return <>{line?.name + ', '}</>
    }

    const SkillsLine = ({ line }) => {
        return <Label content={line?.name} style={{ marginBottom: '0.3rem' }} />
    }

    const LanguagesLine = ({ line }) => {
        return (
            <Label
                content={langauges.find((item) => item.value === line?.language_name)?.text || ''}
                detail={languageLevels?.[line?.level]}
                style={{ marginBottom: '0.3rem' }}
            />
        )
    }

    const IconLine = ({ iconName, line }) => {
        return (
            <Grid style={{ paddingBottom: 0 }}>
                <Grid.Row style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Icon name={iconName} style={{ color: 'var(--primary)' }} />
                    <div style={{ paddingLeft: '1rem' }}>{line}</div>
                </Grid.Row>
            </Grid>
        )
    }

    const GetFullname = ({ data }) => {
        return (
            <p>
                {(data?.titles_before ? data?.titles_before + ' ' : '') +
                    data?.firstname +
                    ' ' +
                    data?.lastname +
                    ' ' +
                    (data?.titles_after ? data?.titles_after : '')}
            </p>
        )
    }

    const GetRow = ({ header, data }) => {
        return (
            <Grid.Row style={{ paddingTop: 0 }}>
                <Grid>
                    <Grid.Row style={{ paddingTop: 0, paddingBottom: '0.5rem' }}>
                        <b>
                            <Header as="h4" content={header} style={{ color: 'var(--primary)' }} />
                        </b>
                    </Grid.Row>
                    <Grid.Row style={{ paddingTop: 0, paddingLeft: '1rem', paddingBottom: 0 }}>
                        <Grid>{data}</Grid>
                    </Grid.Row>
                </Grid>
            </Grid.Row>
        )
    }

    return (
        <Segment
            loading={isLoading}
            style={{
                padding: 0,
                background: 'transparent',
                boxShadow: 'none',
                border: 'none',
                marginBottom: '1rem',
            }}
        >
            {!isLoading && (
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <AvatarIcon size={100} name={data?.firstname + ' ' + data?.lastname} />
                            <Header
                                as="h3"
                                content={<GetFullname data={data} />}
                                style={{ marginBottom: 0, alignItems: 'center', textAlign: 'center' }}
                            />
                            <Header.Subheader content={data?.personal_number} style={{ textAlign: 'center' }} />
                        </Grid.Column>
                        <Grid.Column>
                            <IconLine iconName="user" line={genders?.[data?.gender] || t('not_specified')} />
                            <IconLine iconName="birthday" line={data?.date_of_birth || t('not_specified')} />
                            {candidateID !== undefined ? (
                                <IconLine iconName="mail" line={data?.contacts?.[0]?.email || t('not_specified')} />
                            ) : (
                                <IconLine
                                    iconName="mail"
                                    line={data?.contacts?.create?.[0]?.email || t('not_specified')}
                                />
                            )}
                            {candidateID !== undefined ? (
                                <IconLine iconName="phone" line={data?.contacts?.[0]?.phone || t('not_specified')} />
                            ) : (
                                <IconLine
                                    iconName="phone"
                                    line={data?.contacts?.create?.[0]?.phone || t('not_specified')}
                                />
                            )}
                            {data?.permanent_address && (
                                <IconLine
                                    iconName="home"
                                    line={data?.permanent_address?.city + ', ' + data?.permanent_address?.country}
                                />
                            )}
                            {data?.temporary_address && (
                                <IconLine
                                    iconName="map marker alternate"
                                    line={data?.temporary_address?.city + ', ' + data?.temporary_address?.country}
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>
                    <Divider style={{ margin: 0 }} />
                    <Grid.Row style={{ paddingBottom: 0 }}>
                        <Grid>
                            {data?.educations && (
                                <GetRow
                                    header={t('education')}
                                    data={
                                        candidateID === undefined
                                            ? data?.educations?.create?.length > 0
                                                ? data?.educations?.create?.map((line, i) => (
                                                      <EducationLine key={i} line={line} />
                                                  ))
                                                : t('data_not_provided')
                                            : data?.educations?.length > 0
                                            ? data?.educations?.map((line, i) => <EducationLine key={i} line={line} />)
                                            : t('data_not_provided')
                                    }
                                />
                            )}
                            {data?.experiences && (
                                <GetRow
                                    header={t('experiences')}
                                    data={
                                        candidateID === undefined
                                            ? data?.experiences?.create?.length > 0
                                                ? data?.experiences?.create?.map((line, i) => (
                                                      <ExperienceLine key={i} line={line} />
                                                  ))
                                                : t('data_not_provided')
                                            : data?.experiences?.length > 0
                                            ? data?.experiences?.map((line, i) => (
                                                  <ExperienceLine key={i} line={line} />
                                              ))
                                            : t('data_not_provided')
                                    }
                                />
                            )}
                            {data?.languages && (
                                <GetRow
                                    header={t('languages')}
                                    data={
                                        candidateID === undefined
                                            ? data?.languages?.create?.length > 0
                                                ? data?.languages?.create?.map((line, i) => (
                                                      <LanguagesLine key={i} line={line} />
                                                  ))
                                                : t('data_not_provided')
                                            : data?.languages?.length > 0
                                            ? data?.languages?.map((line, i) => <LanguagesLine key={i} line={line} />)
                                            : t('data_not_provided')
                                    }
                                />
                            )}
                            {data?.interests && (
                                <GetRow
                                    header={t('interests')}
                                    data={
                                        candidateID === undefined
                                            ? data?.interests?.create?.length > 0
                                                ? data?.interests?.create?.map((line, i) => (
                                                      <InterestsLine key={i} line={line} />
                                                  ))
                                                : t('data_not_provided')
                                            : data?.interests?.length > 0
                                            ? data?.interests?.map((line, i) => <InterestsLine key={i} line={line} />)
                                            : t('data_not_provided')
                                    }
                                />
                            )}
                            {data?.skills && (
                                <GetRow
                                    header={t('skills')}
                                    data={
                                        candidateID === undefined
                                            ? data?.skills?.create?.length > 0
                                                ? data?.skills?.create?.map((line, i) => (
                                                      <SkillsLine key={i} line={line} />
                                                  ))
                                                : t('data_not_provided')
                                            : data?.skills?.length > 0
                                            ? data?.skills?.map((line, i) => <SkillsLine key={i} line={line} />)
                                            : t('data_not_provided')
                                    }
                                />
                            )}
                        </Grid>
                    </Grid.Row>
                </Grid>
            )}
        </Segment>
    )
}

export default ProfileCard
