import React, { useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useHistory, Link } from 'react-router-dom'
// store
import { icons } from '@icons'
import { routes } from '@routes'
// components
import Icon from '@components/Icon'
import CanView from '@components/perms/CanView'
import { Button, Container, Divider, Grid, Dropdown } from 'semantic-ui-react'
import BreadcrumbNav from '@components/BreadcrumbNav'
// import Action from '@components/general/Action'
// module specific components
import DayOverview from './views/DayOverview'
import MyAttendance from './views/MyAttendance'
import ManageEmployee from './views/ManageEmployee'
import AttendancePlanner from './views/AttendancePlanner'
// import ImportAttendance from './components/ImportAttendance'
import AttendanceCompareView from './views/AttendanceCompareView'
import CorrectionsView from './views/CorrectionsView'

const Attendance = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const [view, setView] = useState(() => {
        let current_view = 1
        if (window.location.pathname === routes.ATTENDANCE + 'corrections') current_view = 7
        if (window.location.pathname === routes.ATTENDANCE + 'compare') current_view = 6
        if (window.location.pathname === routes.ATTENDANCE + 'planner') current_view = 5
        if (window.location.pathname === routes.ATTENDANCE + 'employee') current_view = 2
        if (window.location.pathname === routes.ATTENDANCE + 'groups') current_view = 3
        if (window.location.pathname === routes.ATTENDANCE + 'day') current_view = 4

        return current_view
    })

    const setViewType = (to, view) => {
        setView(view)
        history.push(to)
    }

    const printSelectedView = (view) => {
        if (view === 2) return t('manage_employee')
        if (view === 5) return t('attendance_planner')
        if (view === 6) return t('compare_plan_vs_reality')
        if (view === 7) return t('corrections')
        return t('select_option_to_manage_attendance')
    }

    return (
        <CanView permissions={['attendance.c_view_all_records', 'attendance.c_view_user_records']} redirect>
            <BreadcrumbNav
                style={{ marginBottom: 0 }}
                items={[{ name: t('attendance'), icon: icons.ATTENDANCE, href: '' }]}
            />
            <Container fluid>
                <Grid stackable>
                    <Grid.Row style={{ padding: 0, marginBottom: '0.5rem' }} columns={1}>
                        <Grid.Column style={{ padding: 0 }}>
                            <Button.Group
                                basic
                                stackable
                                size="small"
                                style={{ borderRadius: 0, padding: 0 }}
                                className="custom-filter-button-group"
                            >
                                <Button
                                    active={view === 1}
                                    style={{ fontWeight: view === 1 ? 'bold' : 'normal' }}
                                    content={t('my_attendance')}
                                    onClick={() => setViewType(routes.ATTENDANCE, 1)}
                                />
                                <Button
                                    active={view === 4}
                                    style={{ fontWeight: view === 4 ? 'bold' : 'normal' }}
                                    content={t('day_overview')}
                                    onClick={() => setViewType(routes.ATTENDANCE + 'day', 4)}
                                />
                                <Dropdown
                                    simple
                                    as={Button}
                                    text={`${printSelectedView(view)}`}
                                    style={{ textAlign: 'center' }}
                                    className={[5, 6, 7, 2].includes(view) && 'button-active'}
                                >
                                    <Dropdown.Menu style={{ borderRadius: '0' }}>
                                        <CanView permissions={['attendance.c_manage_all_records']}>
                                            <Dropdown.Item
                                                active={view === 2}
                                                style={{ fontWeight: view === 2 ? 'bold' : 'normal' }}
                                                onClick={() => setViewType(routes.ATTENDANCE + 'employee', 2)}
                                            >
                                                <Icon
                                                    name="person-circle-outline"
                                                    style={{
                                                        marginRight: '0.5rem',
                                                        fontSize: '1.2rem',
                                                    }}
                                                />{' '}
                                                <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                    {t('manage_employee')}
                                                </span>
                                            </Dropdown.Item>
                                        </CanView>
                                        <CanView permissions={['attendance.c_manage_all_planner_records']}>
                                            <Dropdown.Item
                                                active={view === 5}
                                                style={{ fontWeight: view === 5 ? 'bold' : 'normal' }}
                                                onClick={() => setViewType(routes.ATTENDANCE + 'planner', 5)}
                                            >
                                                <Icon
                                                    name="calendar-outline"
                                                    style={{
                                                        marginRight: '0.5rem',
                                                        fontSize: '1.2rem',
                                                    }}
                                                />{' '}
                                                <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                    {t('attendance_planner')}
                                                </span>
                                            </Dropdown.Item>
                                        </CanView>
                                        <CanView permissions={['attendance.c_can_create_corrections']}>
                                            <Dropdown.Item
                                                active={view === 7}
                                                style={{ fontWeight: view === 7 ? 'bold' : 'normal' }}
                                                onClick={() => setViewType(routes.ATTENDANCE + 'corrections', 7)}
                                            >
                                                <Icon
                                                    name="shield-checkmark-outline"
                                                    style={{
                                                        marginRight: '0.5rem',
                                                        fontSize: '1.2rem',
                                                    }}
                                                />{' '}
                                                <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                    {t('corrections')}
                                                </span>
                                            </Dropdown.Item>
                                        </CanView>

                                        <Dropdown.Item as={Link} to={routes.REPORTS + 'monthly-attendance/'}>
                                            <Icon
                                                name="stats-chart-outline"
                                                style={{
                                                    marginRight: '0.5rem',
                                                    fontSize: '1.2rem',
                                                }}
                                            />{' '}
                                            <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                {t('monthly_attendance')}
                                            </span>
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to={routes.REPORTS + 'attendance-absences/'}>
                                            <Icon
                                                name="stats-chart-outline"
                                                style={{
                                                    marginRight: '0.5rem',
                                                    fontSize: '1.2rem',
                                                }}
                                            />{' '}
                                            <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                {t('absences_report')}
                                            </span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Button.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider style={{ margin: 0, marginBottom: '1rem' }} />
                <Switch>
                    <Route exact path={routes.ATTENDANCE} component={MyAttendance} />
                    <Route exact path={routes.ATTENDANCE + 'corrections'} component={CorrectionsView} />
                    <Route exact path={routes.ATTENDANCE + 'compare'} component={AttendanceCompareView} />
                    <Route exact path={routes.ATTENDANCE + 'planner'} component={AttendancePlanner} />
                    <Route exact path={routes.ATTENDANCE + 'employee'} component={ManageEmployee} />
                    <Route exact path={routes.ATTENDANCE + 'day'} component={DayOverview} />
                </Switch>
            </Container>
        </CanView>
    )
}

export default Attendance
