import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// components
import Icon from '@components/Icon';
import { Form, Divider, Message } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalSubmit from '@components/buttons/ModalSubmit';
import ModalCancel from '@components/buttons/ModalCancel';

const BulkCopyForm = ({ onClose, config, records, setRecords, contracts,  selectedRows, setSelectedRows,  }) => {
    const { t } = useTranslation()
    const [processing, setProcessing] = useState(false)
    const [source, setSource] = useState("")

    const handleSubmit = () => {
        setProcessing(true)

        let existingRecords = records.slice()
        const findSourceRecords = existingRecords.filter(item => item.contract === source && item.is_deleted === false).slice()

        for (let i = 0; i < selectedRows.length; i++) {
            const row = selectedRows[i];
            if (row.id !== parseInt(source)) {
                // Find and update or remove events that match the contract condition of row
                existingRecords = existingRecords.map(record => {
                    if (record.contract === row.id && record.id > 0) {
                        record.is_deleted = true
                    } else if (record.contract === row.id && record.id === 0) {
                        return null
                    }

                    return record
                });

                // Remove null or undefined entries from the array
                existingRecords = existingRecords.filter(record => record !== null && record !== undefined)

                // push new records based on findSourceRecords array
                for (let i = 0; i < findSourceRecords.length; i++) {
                    existingRecords.push({
                        ...findSourceRecords[i],
                        id: 0,
                        is_deleted: false,
                        contract: row.id,
                        employee: row.profile_id
                    })
                }
            }
        }

        setRecords(existingRecords)
        setSelectedRows([])
        onClose()
        setProcessing(false)
    }

    const excludeContract = (contracts) => {
        const selected = selectedRows.map(item => item.id)
        return contracts.filter(item => !selected.includes(item.id))
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message visible info style={{ fontWeight: "bold" }} content={ t('this_will_copy_records_from_selected_source_but_all_existing_will_be_replaced') }/>

            <SuperField as="choice"
                search
                value={source}
                label={t("source")}
                style={{ marginBottom: 0 }}
                customOptions={excludeContract(contracts).filter(item => [...new Set(records.map(item => item.contract))].includes(item.id)).map(item => ({
                    key: item.id,
                    value: item.id,
                    text: item.fullname
                }))}
                onChange={(e, { value }) => setSource(value)}
            />
            <div>
                <Icon name="information-circle-outline" style={{ marginRight: "0.3rem" }}/>
                <span style={{ position: "relative", top: "-0.2rem" }}>{t('if_the_list_is_empty_there_is_probably_no_source_to_copy_from')}.</span>
            </div>

            <Divider/>
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit loading={processing} disabled={processing || source === ""} />
            </Form.Field>
        </Form>
    );
};

export default BulkCopyForm;