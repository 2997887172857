import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// store
import { addChoices, addLanguage } from "@actions";
import { languageOptions } from "@services/helpers/settings";
import { userService } from "@services/ServiceUser";
import { commonService } from "@services/ServiceCommon";
// components
import { Dropdown } from "semantic-ui-react";

const LanguageSelector = () => {
    const { i18n } = useTranslation();
    let dispatch = useDispatch();
    const language = useSelector((state) => state.language);

    const [initLang, setInitLang] = useState(language);

    const changeLanguage = async (event, data) => {
        setInitLang(data.value);
        let language = data.value;

        // temporary fix
        if( data.value === "en" ){
            language = "en-us"; // API requirement for English - will be changed to "en" in the future
        } else if (data.value === "cz") {
            language = "cs"
        }

        const result = await userService.setPreference('language__user_language_preferences', language);

        if( result.status === 200 ){
            dispatch(addLanguage(data.value));
            i18n.changeLanguage(data.value);

            // fetch new choices based on selected language
            const choices = await commonService.getChoices();
            if( choices.status === 200 ){
                dispatch(addChoices(choices.response));
            } 

        }

    };

    return (
        <Dropdown
            inline
            floating
            direction="left"
            options={languageOptions}
            value={initLang}
            onChange={changeLanguage}
        />
    );
};

export default LanguageSelector;
