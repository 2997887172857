import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// components
import SuperField from '@components/forms/SuperField';

const Skills = ({ setData }) => {
    const { t } = useTranslation();
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [skillList, setSkillList] = useState([]);

    useEffect(() => {
        let skillList = []
        if( selectedSkills.length > 0 ){
            for (let i = 0; i < selectedSkills.length; i++){
                skillList.push({
                    name: selectedSkills[i],
                })
            }
        }
        
        let skills = {
            create: skillList
        }

        setData(prevState => ({
            ...prevState,
            skills: skills
        }))
    }, [selectedSkills, setData])

    return (
        <SuperField as="dropdown"
            placeholder={ t('type_to_add_skills') }
            fluid
            search
            selection
            multiple
            label={ t('skills') }
            allowAdditions
            additionLabel={ t('add') + " " }
            options={skillList}
            value={selectedSkills}
            onChange={(e, { value }) => setSelectedSkills(value)}
            onAddItem={(event, data) => {
                setSkillList([...skillList, {key: data.value, text: data.value, value: data.value}])
            }}
        />
    );
};

export default Skills;