import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
// store
import { API } from '@store/config'
import { routes } from '@routes/index'
// components
import { Container } from 'semantic-ui-react'
import GdprForm from './GdprForm'
import CanView from '@components/perms/CanView'
import ListView from '@components/ListView'

const Gdpr = () => {
    const { t } = useTranslation()

    const types = useSelector((state) => state.choices?.gdpr_types)

    return (
        <Container fluid style={{ marginTop: '1rem' }}>
            <CanView permissions={['common.c_view_gdpr']} redirect>
                <ListView
                    as="table"
                    isSoftDeleteAllowed
                    endpoint={API.GDPRS}
                    tableHeaders={[
                        { title: t('title') },
                        { title: t('type') },
                        { title: t('length_in_months') },
                    ]}
                    actions={[
                        {
                            as: 'modal',
                            type: 'add',
                            name: t('add_gdpr'),
                            permissions: ['common.c_manage_gdpr'],
                            modal: <GdprForm />,
                        },
                        {
                            as: 'link',
                            type: 'custom',
                            icon: 'eye-outline',
                            customIconColor: 'var(--primary)',
                            name: t('show_detail'),
                            redirect: (item) => routes.GDPRS + item.id + "/",
                        },
                        {
                            as: 'modal',
                            type: 'edit',
                            name: t('edit'),
                            icon: 'pencil-outline',
                            permissions: ['common.c_manage_gdpr'],
                            modal:  (item, setData) =>  <GdprForm gdpr={item} setData={setData}/>,
                        },
                        {
                            name: t('delete'),
                            type: 'delete',
                            as: 'delete',
                            text: t('delete_this'),
                            permissions: ['common.c_delete_gdpr'],
                        },
                    ]}
                    renderCells={(item) => [
                        { content: item.title },
                        { content: types[item.type] },
                        { content: item.lenght_in_months }
                    ]}
                />
            </CanView>
        </Container>
    )
}

export default Gdpr
