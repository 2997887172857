import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
// store
import { API } from '@store/config'
import { icons } from '@store/icons'
import { routes } from '@routes/index'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import UserAvatar from 'react-user-avatar'
import CanView from '@components/perms/CanView'
import AvatarList from '@components/AvatarList'
import BreadcrumbNav from '@components/BreadcrumbNav'
import FilesTab from '@components/documents/FilesTab'
import SpinnerSegment from '@components/SpinnerSegment'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Container, Grid, Header, Tab, Button, Popup } from 'semantic-ui-react'
// module specific
import ProjectUpdateForm from '../components/forms/ProjectUpdateForm'
import IssueTableView from '../components/detail/IssueTableView'
import ProjectMemberCreateForm from '../components/forms/ProjectMemberCreateForm'
import ProjectDetailStatHeader from '../components/detail/ProjectDetailStatHeader'
import ProjectMembersTab from '../components/detail/project-members-tab/ProjectMembersTab'
import ProjectMilestonesTab from '../components/detail/milestones-tab/ProjectMilestonesTab'

const DetailView = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dateFormat = useSelector((state) => state.date_format)
    const project_params = useParams()

    const [loading, setLoading] = useState(true)
    const [project, setProject] = useState({})
    const [members, setMembers] = useState([])

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            const request = await requests.get(API.PROJECTS + project_params.id + '/')

            if (request.status === 403) {
                history.push(routes.DASHBOARD)
                return
            }

            if (request.status === 200) {
                setProject(request.response)

                const requestMembers = await requests.get(API.PROJECT_MEMBERS + '?project=' + project_params.id)
                if (request.status === 200) {
                    setMembers(requestMembers.response)
                }
            }
            setLoading(false)
        }

        fetchData()

        // eslint-disable-next-line
    }, [])

    const panes = [
        {
            menuItem: { content: t('issues'), className: 'project-tab-text' },
            render: () => (
                <>
                    <ProjectDetailStatHeader project_params={project_params} />
                    <IssueTableView project={project_params.id} />
                </>
            ),
        },

        {
            menuItem: { content: t('milestones'), className: 'project-tab-text' },
            render: () => <ProjectMilestonesTab project={project} setProject={setProject} />,
        },
        {
            menuItem: { content: t('members'), className: 'project-tab-text' },
            render: () => (
                <ProjectMembersTab
                    project={project}
                    setProject={setProject}
                    members={members}
                    setMembers={setMembers}
                />
            ),
        },
        {
            menuItem: { content: t('documents'), className: 'project-tab-text' },
            render: () => (
                <FilesTab
                    module={'project'}
                    updateEndpoit={API.PROJECTS}
                    getEndpoint={API.PROJECTS}
                    //group_params={`&project=${project.id}`}
                    attachment_params={`?project=${project.id}`}
                    hideCreate={true}
                />
            ),
        },
        {
            menuItem: { content: t('settings'), className: 'project-tab-text' },
            render: () => <ProjectUpdateForm project={project} setProject={setProject} />,
        },
    ]

    return (
        <CanView permissions={['projects.c_view_all_projects', 'projects.c_view_user_projects']} redirect>
            <BreadcrumbNav
                items={[
                    { name: t('projects'), icon: icons.PROJECTS, href: routes.PROJECT_LIST },
                    { name: project?.name, icon: '', href: '' },
                ]}
            />
            <SpinnerSegment loading={loading}>
                {!loading && (
                    <Container fluid>
                        <Grid style={{ paddingBottom: '1rem' }}>
                            <Grid.Row columns={'2'} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                <Grid.Column textAlign="left">
                                    <div>
                                        <Header as={'h2'} style={{ marginBottom: '0.5rem' }} content={project?.name} />
                                        <div style={{ marginBottom: '0.5rem' }}>
                                            {moment(project.from_date).format(dateFormat) +
                                                ' - ' +
                                                moment(project.end_date).format(dateFormat)}
                                        </div>
                                        <Header.Subheader content={project.description} />
                                    </div>
                                </Grid.Column>
                                <Grid.Column textAlign="right" style={{ textAlign: 'right' }}>
                                    <div>
                                        <Icon
                                            name="ellipse"
                                            style={{
                                                color: project.is_active ? 'green' : 'red',
                                                fontSize: '1.2rem',
                                                marginRight: '0.25rem',
                                                position: 'relative',
                                                top: '-0.1rem',
                                            }}
                                        />
                                        <span
                                            style={{
                                                position: 'relative',
                                                fontSize: '1.2rem',
                                                top: '-0.2rem',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {project.is_active ? t('active_him') : t('inactive_him')}
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            float: 'right',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            paddingRight: 0,
                                            marginRight: 0,
                                        }}
                                    >
                                        {project?.project_manager && (
                                            <Popup
                                                position="top center"
                                                trigger={
                                                    <div
                                                        style={{
                                                            cursor: 'normal',
                                                            marginTop: '1.5rem',
                                                            marginRight: members?.length === 0 ? '0.5rem' : '0',
                                                        }}
                                                    >
                                                        <UserAvatar
                                                            style={{
                                                                color: 'white',
                                                                paddingLeft: '0rem',
                                                                fontSize: 25 / 2.5 + 'px',
                                                                borderRadius: '50%',
                                                                border: '2px solid white',
                                                            }}
                                                            size={25}
                                                            name={project?.project_manager?.fullname || 'JD'}
                                                            // color={getRandomColor()}
                                                            src={project?.project_manager?.profile_picture}
                                                        />
                                                        <Icon
                                                            name="ribbon"
                                                            style={{
                                                                position: 'relative',
                                                                bottom: 0,
                                                                top: '-0.7rem',
                                                                left: '0.1rem',
                                                                fontSize: '1rem',
                                                                borderRadius: '50%',
                                                                border: '2px solid white',
                                                                background: 'var(--light)',
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                content={
                                                    <span>
                                                        {t('order_manager') + ': '}{' '}
                                                        <strong>{project?.project_manager?.fullname}</strong>
                                                    </span>
                                                }
                                            />
                                        )}
                                        {members?.length > 0 && (
                                            <>
                                                <div
                                                    style={{
                                                        paddingLeft: '0.3rem',
                                                        paddingRight: '0.3rem',
                                                        paddingTop: '0.3rem',
                                                        opacity: 0.5,
                                                        userSelect: 'none',
                                                    }}
                                                >
                                                    {' '}
                                                    •{' '}
                                                </div>
                                                <AvatarList
                                                    size="25"
                                                    allowAdditions
                                                    alignDirection="start"
                                                    canManage={'projects.c_manage_user_projects'}
                                                    persons={members
                                                        ?.map((item) => item.profile)
                                                        ?.map((item) => ({
                                                            ID: item?.id,
                                                            id: item?.id,
                                                            name: item?.fullname,
                                                            avatar: item?.profile_picture,
                                                            displayName: item?.fullname,
                                                        }))}
                                                    // addForm={<ProjectMemberCreateForm project={project} setData={setMembers} />}
                                                />
                                            </>
                                        )}
                                        <CanView
                                            permissions={[
                                                'projects.c_manage_all_projects',
                                                'projects.c_manage_user_projects',
                                            ]}
                                        >
                                            <SuperDuperModal
                                                header={t('add_member')}
                                                trigger={
                                                    <Button size="small" primary>
                                                        <Icon name="add-outline" /> {t('add_member')}
                                                    </Button>
                                                }
                                                content={
                                                    <ProjectMemberCreateForm
                                                        isDetail
                                                        project={project}
                                                        setData={setMembers}
                                                        members={members}
                                                    />
                                                }
                                            />
                                        </CanView>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
                    </Container>
                )}
            </SpinnerSegment>
        </CanView>
    )
}

export default DetailView
