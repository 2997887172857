import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { isEmpty } from "@helpers/validation";
import { isValidNumber } from '@helpers/functions'
// components
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const ServiceRecordForm = ({ onClose, vehicle, record, setData }) => {
    const { t } = useTranslation()
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        title: record?.title || "",
        date: record?.date || "",
        description: record?.description || "",
        currency: record?.currency || "EUR",
        supplier: record?.supplier?.id|| "",
        total_cost: record?.total_cost|| "",
        vehicle: vehicle.id,
    })

    const handleSubmit = async () => {
        setErrors(null)
        setProcessing(true)
        
        let request = undefined
        let data = form
        if (data.date === "") data.date = null
        if (data.supplier === "") data.supplier = null
        if (record?.id === undefined) { // create logic
            request = await requests.post(API.SERVICES + "records/", data)
        } else { // update logic
            request = await requests.patch(API.SERVICES + "records/" + record?.id + "/", data)
        }

        if (request !== undefined){
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201){
                setData(prev => [request.response, ...prev])
                onClose()
            }
            if (request.status === 200) {
                setData(prev => prev.map(item => {
                    if (item.id === record?.id) {
                        item = request.response
                    }

                    return item
                }))
                onClose()
            }
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="input"
                autoFocus
                required
                label={t('title')}
                value={form.title}
                error={errors?.title?.[0] || false}
                onChange={(e, { value }) => setForm({ ...form, title: value })}
            />

            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    required
                    label={t('date')}
                    value={form.date}
                    error={errors?.date?.[0] || false}
                    onChange={(e, { value }) => setForm({ ...form, date: value })}
                />
                <SuperField as="choice"
                    search
                    text="name"
                    value={form.supplier}
                    label={t('supplier')}
                    error={errors?.supplier?.[0] || false}
                    onChange={(e, { value }) => setForm(prev => ({...prev, supplier: value}))}
                    endpoint={API.ACCOUNTS + `business_details/?query={id, name}&is_supplier=true&is_active=true&exclude_unit=true`}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="input"
                    required
                    label={t('total_cost')}
                    value={form.total_cost}
                    error={errors !== null ? errors?.total_cost?.[0] : !isValidNumber(form.total_cost, true)
                        ? t('invalid_entry')
                        : false}
                    onChange={(e, { value }) => setForm(prev => ({...prev, total_cost: value.replace(",", ".") })) }
                />
                <SuperField as="choice"
                    search
                    required
                    clearable={false}
                    label={t('currency')}
                    value={form.currency}
                    type="currency_codes"
                    onChange={(e, { value }) => setForm(prev => ({...prev, currency: value })) }
                />
            </Form.Group>

            <SuperField as="textarea"
                label={t('description')}
                value={form.description}
                error={errors?.description?.[0] || false}
                onChange={(e, { value }) => setForm({ ...form, description: value })}
            />
            
            
            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    disabled={processing ||
                        isEmpty(form.title) ||
                        isEmpty(form.date) ||
                        isEmpty(form.total_cost) ||
                        !isValidNumber(form.total_cost, true) ||
                        isEmpty(form.currency)}
                    loading={processing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default ServiceRecordForm;