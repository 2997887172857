import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { isEmpty } from "@helpers/validation";
// components
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const VignetteForm = ({ onClose, vehicle, record, setData }) => {
    const { t } = useTranslation()
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        date_from: record?.date_from || "",
        date_to: record?.date_to || "",
        country: record?.country || "SK",
        vehicle: vehicle.id,
    })

    const handleSubmit = async () => {
        setErrors(null)
        setProcessing(true)
        
        let request = undefined
        let data = form
        if (data.country === "") data.country = null
        if (data.date_from === "") data.date_from = null
        if (data.date_to === "") data.date_to = null
        if (record?.id === undefined) { // create logic
            request = await requests.post(API.VEHICLES + "vignette/", data)
        } else { // update logic
            request = await requests.patch(API.VEHICLES + "vignette/" + record?.id + "/", data)
        }

        if (request !== undefined){
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201){
                setData(prev => [request.response, ...prev])
                onClose()
            }
            if (request.status === 200) {
                setData(prev => prev.map(item => {
                    if (item.id === record?.id) {
                        item = request.response
                    }

                    return item
                }))
                onClose()
            }
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="choice"
                search
                required
                type="countries"
                label={t('country')}
                value={form.country}
                error={errors?.country?.[0] || false}
                onChange={(e, { value }) => setForm({ ...form, country: value })}
            />

            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    required
                    label={t('date_from')}
                    value={form.date_from}
                    error={errors?.date_from?.[0] || false}
                    onChange={(e, { value }) => setForm({ ...form, date_from: value })}
                />
                <SuperField as="datepicker"
                    required
                    label={t('date_to')}
                    value={form.date_to}
                    error={errors?.date_to?.[0] || false}
                    onChange={(e, { value }) => setForm({ ...form, date_to: value })}
                />
            </Form.Group>
            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    disabled={processing || isEmpty(form.date_to) || isEmpty(form.date_from) || isEmpty(form.country)}
                    loading={processing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default VignetteForm;