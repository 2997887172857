import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests, fileDownload } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import SpinnerSegment from '@components/SpinnerSegment'
import ModalCancel from '@components/buttons/ModalCancel'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Form, Divider, Button, Message, Header, Icon as SemanticIcon } from 'semantic-ui-react'

const ListViewImport = ({ onClose, endpoint, setAllowClose, module, configModule, fetchData }) => {
    const { t } = useTranslation()

    const [downloading, setDownloading] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [file, setFile] = useState(null)

    const [response, setResponse] = useState(null)

    const handleImport = async () => {
        setProcessing(true)
        setAllowClose(false)
        const formData = new FormData()
        formData.append('file', file)
        const request = await requests.post(endpoint, formData)

        if (request.status === 400) {
            if (request.response?.detail) {
                toast({
                    type: 'error',
                    icon: 'warning circle',
                    title: request.response?.detail,
                    animation: 'bounce',
                    time: 5000,
                })
            }
            if (request.response?.file) {
                toast({
                    type: 'error',
                    icon: 'warning circle',
                    title: request.response?.file,
                    animation: 'bounce',
                    time: 5000,
                })
            }

            setResponse(request.response)
        }

        if (request.status === 201 || request.status === 200) {
            let ids = ''

            let records = request.response?.records || request.response?.new_records || []
            if (records.length > 0) {
                if (records[0]?.id) {
                    // its serialized object
                    let idsArray = records.map((item) => item.id)
                    ids = '&ids=' + idsArray.join(',')
                } else {
                    // its list item (ID)
                    ids = '&ids=' + records.join(',')
                }
            }

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('import_successful'),
                animation: 'pulse',
                time: 3000,
            })
            if (ids !== '') {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('list_containing_imported_data'),
                    animation: 'pulse',
                    time: 5000,
                })
            }

            await fetchData('', true, ids)
            onClose()
        }

        setAllowClose(true)
        setProcessing(false)
    }

    const hiddenFileInput = React.useRef(null)
    const handleFrontClick = (event) => {
        hiddenFileInput.current.click()
    }

    const handleFileInputChange = (event) => {
        const selectedFiles = event.target.files

        const newFiles = Array.from(selectedFiles).filter((file) => file.size <= 8 * 1024 * 1024)

        if (newFiles.length > 0) {
            setFile(newFiles[0])
        } else {
            toast({
                type: 'error',
                icon: 'warning circle',
                title: t('Please select files with a size of 8 MB or less.'),
                description: '',
                animation: 'bounce',
                time: 5000,
            })
        }
    }

    const showFieldsDocumentation = async () => {
        setDownloading(true)
        await fileDownload(
            'GET',
            API.EXPORTS + 'fields_documentation/?is_blob=true&module=' + configModule,
            'application/pdf',
            `${module}_documentation.pdf`
        )
            .then((result) => {})
            .catch((error) => {
                toast({
                    type: 'error',
                    icon: 'warning circle',
                    title: t('unable_to_generate_template'),
                    animation: 'bounce',
                    time: 5000,
                })
            })

        setDownloading(false)
    }

    const downloadExampleFile = async () => {
        setDownloading(true)
        await fileDownload(
            'GET',
            API.EXPORTS + 'template_example/?is_blob=true&module=' + configModule,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            `${module}_template.xlsx`
        )
            .then((result) => {})
            .catch((error) => {
                toast({
                    type: 'error',
                    icon: 'warning circle',
                    title: t('unable_to_generate_template'),
                    animation: 'bounce',
                    time: 5000,
                })
            })

        setDownloading(false)
    }

    return (
        <>
            {processing ? (
                <SpinnerSegment loading={processing} loadingMessage={t('processing_request') + '...'} />
            ) : (
                <Form onSubmit={handleImport}>
                    <Message info visible>
                        <div
                            style={{
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div>
                                <Icon
                                    name="information-circle-outline"
                                    style={{ marginRight: '0.5rem', position: 'relative', top: '0.2rem' }}
                                />
                                {t('download_example_template_or_check_documentation')}
                            </div>
                            <div>
                                <Button
                                    basic
                                    size="tiny"
                                    type="button"
                                    loading={downloading}
                                    disabled={downloading}
                                    onClick={() => showFieldsDocumentation()}
                                >
                                    {t('show_documentation')}
                                </Button>
                                <Button
                                    primary
                                    type="button"
                                    loading={downloading}
                                    disabled={downloading}
                                    size="tiny"
                                    onClick={() => downloadExampleFile()}
                                >
                                    {t('download_template')}
                                </Button>
                            </div>
                        </div>
                    </Message>

                    <Divider />

                    <div style={{ textAlign: 'center' }}>
                        <input
                            type="file"
                            id="fileInput"
                            ref={hiddenFileInput}
                            onChange={handleFileInputChange}
                            style={{ display: 'none' }}
                        />
                        <div
                            style={{
                                padding: '0.7rem',
                                cursor: 'pointer',
                                border: '1px solid var(--light-grey)',
                            }}
                            onClick={handleFrontClick}
                        >
                            <Header as="h4" style={{ marginTop: '0.5rem', marginBottom: '0.5rem', fontSize: '15px' }}>
                                <span>
                                    <SemanticIcon
                                        name="upload"
                                        size="tiny"
                                        style={{ color: 'var(--primary)', fontSize: '15px', marginRight: '0.4rem' }}
                                    />
                                    {t('upload_file')}
                                </span>
                                {file ? (
                                    <Header.Subheader content={file?.name || t('one_file_selected')} />
                                ) : (
                                    <Header.Subheader content={t('no_file_selected')} />
                                )}
                            </Header>
                        </div>
                    </div>

                    <RenderErrors type="candidates" response={response} />

                    <Divider />
                    <Form.Field style={{ textAlign: 'right' }}>
                        {!processing && <ModalCancel onClose={onClose} />}
                        <Button primary disabled={file === null || processing} loading={processing}>
                            <Icon name="push-outline" style={{ marginRight: '0.5rem' }} />
                            {t('import')}
                        </Button>
                    </Form.Field>
                </Form>
            )}
        </>
    )
}

const RenderErrors = ({ type, response }) => {
    const { t } = useTranslation()
    const invalidRecords = response?.result?.invalid || response?.invalid || response?.[type]?.invalid || []

    return (
        <>
            {invalidRecords?.length !== 0 && (
                <>
                    <Divider />
                    <Message visible warning>
                        <div style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                            <Icon
                                name="information-circle-outline"
                                style={{
                                    marginRight: '0.8rem',
                                    fontSize: '1.5rem',
                                    position: 'relative',
                                    top: '0.1rem',
                                }}
                            />
                            <span style={{ position: 'relative', top: '-0.2rem' }}>
                                {t('some_records_contain_errors_see_list_down_bellow')}:
                            </span>
                        </div>

                        <Divider />

                        {Array.isArray(invalidRecords) && (
                            <div style={{ color: 'var(--dark)' }}>
                                {invalidRecords.map((error, idx) => (
                                    <div key={idx}>
                                        <strong>
                                            {t('invalid_row')} {error.row}:
                                        </strong>
                                        <ul style={{ paddingLeft: '1rem', marginTop: '0.5rem' }}>
                                            {error.errors.map((item, idx) => (
                                                <>
                                                    {typeof item === 'object' ? (
                                                        Array.isArray(item) ? (
                                                            item?.map((message, index) => (
                                                                <li key={index}>
                                                                    {' '}
                                                                    {message} <br />
                                                                </li>
                                                            ))
                                                        ) : item !== undefined && item !== null ? (
                                                            Object.keys(item)?.map((key) => (
                                                                <>
                                                                    <strong> {key}: </strong> <br />
                                                                    <ul style={{ paddingLeft: '1.5rem' }}>
                                                                        {Array.isArray(item[key]) ? (
                                                                            item[key].map((message) => (
                                                                                <li> {message} </li>
                                                                            ))
                                                                        ) : (
                                                                            <li> {item[key]} </li>
                                                                        )}
                                                                    </ul>
                                                                </>
                                                            ))
                                                        ) : null
                                                    ) : (
                                                        <li key={idx}>
                                                            {' '}
                                                            {item} <br />
                                                        </li>
                                                    )}
                                                </>
                                            ))}
                                        </ul>
                                        <Divider />
                                    </div>
                                ))}
                            </div>
                        )}
                    </Message>
                </>
            )}
        </>
    )
}

export default ListViewImport
