import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
// store 
import { API } from '@store/config';
import { routes } from '@routes';
import { patchRequest, deleteRequest } from '@services/ServiceCommon';
// components 
import AvatarIcon from '@components/AvatarIcon';
import { Sidebar, Header, Label, Tab, Icon, Button, Popup } from 'semantic-ui-react';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
// module specific components
// import MemberList from './sidebar/Members';
import Labels from './sidebar/Labels';
import Milestones from './sidebar/Milestones';
import Settings from './sidebar/Settings';
import ConfrimModal from '@components/modals/ConfrimModal';
import moment from 'moment';
import ProjectMembers from '../detail-v2/sidebar/ProjectMembers';

const ProjectSidebar = ({ visible, onHide, project, setProject, stats, setStats, members, setMembers }) => {
    const { t } = useTranslation();
    const history = useHistory()

    const onProjectStatusChange = async (status) => {
        const request = await patchRequest(API.PROJECTS + project?.id + "/", {
            is_active: status,
            end_data: status === false ? moment(new Date()).format("YYYY.MM.DD") : null
        })

        if (request.status === 200) {
            setProject((prevState) => {
                return {
                    ...prevState,
                    data: request.response
                }
            })
        }
    }

    const onDelete = async () => {
        const request = await deleteRequest(API.PROJECTS + project?.id + "/", {})

        if (request.status === 204) {
            history.push(routes.PROJECT_LIST)
        }
    }

    const panes = [
        // {
        //     menuItem: `${t('members')} (${stats?.people_on_project_count})`,
        //     render: () => <MemberList teams={project?.teams} members={stats?.people_on_project} />,
        // },
        {
            menuItem: `${t('labels')} (${project?.labels.length})`,
            render: () => <Labels labels={project?.labels} setProject={setProject} />,
        },
        {
            menuItem: `${t('milestones')} (${stats?.milestones_count})`,
            render: () => <Milestones milestones={stats?.milestones} setStats={setStats} />,
        },
        {
            menuItem: `${t('members')} (${members.length})`,
            render: () => <ProjectMembers members={members} setMembers={setMembers} />,
        },
        {
            menuItem: t('settings'),
            render: () => <Settings project={project} setProject={setProject} />,
        },
    ]

    return (
        <Sidebar
            style={{ width: "40%", minWidth: "500px" }}
            animation="overlay"
            direction="right"
            visible={visible}
        >
            {/* Header Section */}
            <FlexRow padding="2rem">
                <FlexItem basis="10%">
                    <Popup
                        content="Hide Information Panel"
                        position='left center'
                        inverted
                        trigger={
                            <Icon
                                className="toggle-button"
                                circular size="large"
                                name="chevron right"
                                onClick={onHide}
                                style={{ cursor: "pointer", marginRight: "1rem" }}
                            />
                        }
                    />
                </FlexItem>
                <FlexItem>
                    <Header as="h3">
                        {project?.name}<br />
                        <small> {project?.identifier} </small>
                    </Header>
                </FlexItem>
                <FlexItem textAlign="right">
                    <div style={{ marginBottom: "0.2rem" }}>
                        <Label size="large" style={{
                            background: project?.is_active ? "var(--success)" : "var(--danger)",
                            color: project?.is_active ? "var(--black)" : "var(--white)",
                        }}>
                            {project?.is_active ? t('active') : t('closed')}
                        </Label>
                    </div>
                    <div>
                        <Label>{moment(project?.from_date).format("DD.MM.YYYY")}</Label>
                    </div>
                </FlexItem>
            </FlexRow>
            {project?.project_manager ?
                <div style={{ width: "100%", paddingLeft: "2rem", paddingRight: "2rem" }}>
                    <FlexRow padding="0">
                        <FlexItem basis="30%">
                            <AvatarIcon
                                size={40}
                                name={project?.project_manager?.fullname}
                                // src={avatar} // avatar/profile_picture
                                uploadable={false}
                            />
                        </FlexItem>
                        <FlexItem>
                            <Header as="h4" style={{ marginLeft: "1rem" }}>
                                {project?.project_manager?.fullname} <br />
                                <small> {t('manager')} </small>
                            </Header>
                        </FlexItem>

                        <FlexItem />

                        <FlexItem>
                            <Button.Group fluid style={{ paddingRight: "0", paddingLeft: "2rem" }}>
                                <Button
                                    type="button"
                                    onClick={() => onProjectStatusChange(!project?.is_active)}
                                    style={{ color: "var(--light)", background: project?.is_active ? "var(--dark)" : "var(--primary)" }}
                                >
                                    {project?.is_active ? t('close') : t('open')}
                                </Button>
                                <ConfrimModal
                                    onConfirm={() => onDelete()}
                                    description={t("delete_project")}
                                    button={
                                        <Button
                                            type="button"
                                            style={{ color: "var(--light)", background: "var(--danger)" }}
                                        >
                                            <Icon style={{ marginRight: "0" }} name="remove circle" />
                                        </Button>
                                    }
                                />
                            </Button.Group>
                        </FlexItem>
                    </FlexRow>
                </div>
                : <p style={{ paddingLeft: "2rem" }}>{t('no_manager')}</p>}

            {/* Tabs Section */}
            <Tab panes={panes}
                style={{
                    marginTop: "2rem",
                    marginBottom: 0
                }}
                menu={{ secondary: true, pointing: true }}
            />
        </Sidebar>
    );
};

export default ProjectSidebar;