import React from 'react'
import { useTranslation } from 'react-i18next'
// components
import { Grid, List, Button, Divider } from 'semantic-ui-react'
import HistoryRecord from './HistoryRecord'

const HistoryStageRecordList = ({ history, filterHistory, setFilterHistory, setHistory }) => {
    const { t } = useTranslation()

    return (
        <Grid>
            {filterHistory !== undefined && setFilterHistory !== undefined && (
                <>
                    <Grid.Row centered>
                        <Button active={filterHistory === 1} size="mini" onClick={() => setFilterHistory(1)}>
                            {t('assign_to_recruitment')}
                        </Button>
                        <Button active={filterHistory === 2} size="mini" onClick={() => setFilterHistory(2)}>
                            {t('comment')}
                        </Button>
                        <Button active={filterHistory === 3} size="mini" onClick={() => setFilterHistory(3)}>
                            {t('rate')}
                        </Button>
                        <Button active={filterHistory === 4} size="mini" onClick={() => setFilterHistory(4)}>
                            {t('move_stage')}
                        </Button>
                        <Button active={filterHistory === 7} size="mini" onClick={() => setFilterHistory(7)}>
                            {t('reject')}
                        </Button>
                        <Button active={filterHistory === 13} size="mini" onClick={() => setFilterHistory(13)}>
                            {t('contract')}
                        </Button>
                    </Grid.Row>
                    <Divider />
                </>
            )}

            <Grid.Row>
                <List relaxed>
                    {history.map((item, key) => (
                        <HistoryRecord key={key} history={item} />
                    ))}
                </List>
            </Grid.Row>
        </Grid>
    )
}

export default HistoryStageRecordList
