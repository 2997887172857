import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { deleteRequest } from '@services/ServiceCommon'
// components
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { FlexRow, FlexItem } from '@components/tables/FlexTable'
import { Button, Grid, Header, Icon, Divider } from 'semantic-ui-react'
import ConfrimModal from '@components/modals/ConfrimModal'
import MemberForm from '../forms/MemberForm'
import AvatarIcon from '@components/AvatarIcon'

const ProjectMembers = ({ members, setMembers }) => {
    const { t } = useTranslation()

    const onDelete = async (id) => {
        const request = await deleteRequest(API.PROJECT_MEMBERS + id + '/')

        if (request.status === 204) {
            setMembers((prevState) => {
                return {
                    ...prevState,
                    data: prevState.data.filter((member) => member.id !== id),
                }
            })
        }
    }

    const MemberView = ({ member, onDelete }) => {
        const [showActions, setShowActions] = useState(false)
        return (
            <FlexRow
                borders
                padding="0.4rem"
                background="transparent"
                onMouseOver={() => setShowActions(true)}
                onMouseLeave={() => setShowActions(false)}
            >
                <FlexItem basis="400%">
                    <Grid style={{ padding: 0 }}>
                        <Grid.Row style={{ padding: 0 }}>
                            <Icon.Group style={{ marginRight: '0.5rem', padding: '0.5rem' }}>
                                <AvatarIcon
                                    name={member.profile.fullname}
                                    src={member.profile.profile_picture}
                                    size={35}
                                />
                            </Icon.Group>
                            <span style={{ display: 'flex', flexDirection: 'column', paddingTop: '1rem' }}>
                                <strong>{member.profile.fullname}</strong>
                            </span>
                        </Grid.Row>
                    </Grid>
                </FlexItem>
                {showActions && (
                    <FlexRow padding="0" background="transparent">
                        {/* <FlexItem textAlign="center">
                            <SuperDuperModal
                                trigger={
                                    <Icon name="pencil" style={{ marginRight: 0, color: "var(--dark)", cursor: "pointer" }} />
                                }
                                size='small'
                                header={t("update_member")}
                                content={
                                    <MemberForm
                                        member={member}
                                        members={members}
                                        setMembers={setMembers}
                                    />
                                }
                            />
                        </FlexItem> */}
                        <FlexItem textAlign="center">
                            <ConfrimModal
                                onConfirm={() => onDelete(member.id)}
                                description={t('delete_member')}
                                button={
                                    <Icon
                                        name="remove"
                                        style={{ marginRight: 0, color: 'var(--danger)', cursor: 'pointer' }}
                                    />
                                }
                            />
                        </FlexItem>
                    </FlexRow>
                )}
            </FlexRow>
        )
    }

    return (
        <Grid fluid>
            <Grid.Row>
                <Grid.Column>
                    <Header as="h2" style={{ marginBottom: 0 }}>
                        {t('members')}
                        <SuperDuperModal
                            size="small"
                            header={t('add_member')}
                            trigger={
                                <Button primary style={{ marginRight: 0, float: 'right' }} size="tiny">
                                    <Icon name="plus" style={{ marginRight: 0 }} />
                                    {t('add_member')}
                                </Button>
                            }
                            content={<MemberForm setMembers={setMembers} />}
                        />
                    </Header>
                    <Divider />

                    {members?.data.map((member, index) => (
                        <MemberView key={index} member={member} onDelete={onDelete} />
                    ))}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default ProjectMembers
