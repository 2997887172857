import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { isEmailValid, isPhoneNumberValid } from '@helpers/validation'
// components
import { Form, Divider } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import EmailField from '@components/forms/common/EmailField'
import PhoneField from '@components/forms/common/PhoneField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const AccountantForm = ({ id, data, onUpdate, onClose }) => {
    const { t } = useTranslation()
    const [processing, setProcessing] = useState(false)
    const [view, setView] = useState(data?.external_accountant?.id ? 1 : 0)
    const [form, setForm] = useState({
        accountant_email: data?.accountant_email || '', // required
        accountant_phone: data?.accountant_phone || '',
        accountant_name: data?.accountant_name || '',
        external_accountant: data?.external_accountant?.id || '',
    })

    const handleSubmit = async () => {
        setProcessing(true)
        const request = await requests.patch(API.ACCOUNTS + 'business_details/' + id + '/', {
            ...form,
            external_accountant: view === 0 ? null : form.external_accountant || null,
        })

        if (request.status === 200) {
            onUpdate(request.response)
            onClose()
        }
        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group>
                <SuperField
                    as="radio"
                    label={t('internal_singular')}
                    checked={view === 0}
                    onChange={() => {
                        setView(0)
                        setForm((prev) => ({ ...prev, external_accountant: '' }))
                    }}
                />
                <SuperField
                    as="radio"
                    label={t('external_singular')}
                    checked={view === 1}
                    onChange={() => setView(1)}
                />
            </Form.Group>

            <Divider />

            {view === 1 && (
                <>
                    <SuperField
                        search
                        required
                        as="choice-select"
                        text="name"
                        label={t('select_supplier')}
                        value={form.external_accountant}
                        endpoint={API.ACCOUNTS + 'business_details/'}
                        additionalFilters={'&query={id, name}&is_supplier=true&exclude_unit=true&only_basic_info=true'}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, external_accountant: value }))}
                        initialOptions={{
                            attribute: 'name',
                            source: data?.external_accountant,
                        }}
                    />
                    <Divider />
                </>
            )}

            <h3>{t('contact_person')}</h3>

            <SuperField
                required
                as="input"
                label={t('person_name')}
                value={form.accountant_name}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, accountant_name: value }))}
            />

            <Form.Group widths="equal">
                <EmailField
                    required
                    label={t('email')}
                    value={form.accountant_email}
                    setValue={(e, { value }) => setForm((prev) => ({ ...prev, accountant_email: value }))}
                />
                <PhoneField
                    hideType
                    placeholder={t('enter_number')}
                    value={form.accountant_phone}
                    setValue={(e, { value }) => setForm((prev) => ({ ...prev, accountant_phone: value }))}
                />
            </Form.Group>
            <Divider />

            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={processing}
                    disabled={
                        form.accountant_email === '' ||
                        form.accountant_name === '' ||
                        !isEmailValid(form.accountant_email) ||
                        !isPhoneNumberValid(form.accountant_phone) ||
                        (view === 1 && form.external_accountant === '') ||
                        processing
                    }
                />
            </Form.Field>
        </Form>
    )
}

export default AccountantForm
