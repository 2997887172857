import React, { useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
// components
import SuperField from '@components/forms/SuperField';

const LastJob = ({ setData }) => {
    const { t } = useTranslation();
    const [lastJob, setLastJob] = useState("")

    useEffect( () => {
        setData(prevState => ({
            ...prevState,
            last_job: lastJob
        }))

    }, [lastJob, setData])

    return (
        <SuperField as="input"
            label={ t("last_job") }
            width="8"
            placeholder={ t("enter_last_job") }
            value={ lastJob }
            onChange={ (e) => setLastJob(e.target.value) }
        />
    );
};

export default LastJob;