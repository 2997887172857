import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { tzDateTime } from '@helpers/dates'
import { requests } from '@helpers/requests'
// components
import ListView from '@components/ListView'
import { Form, Divider } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const BankModalView = ({ config, modalBank, contract, onClose, setContracts, source, bankValue }) => {
    const [view] = useState(modalBank.action === 'history' ? 1 : 2)

    const determinateType = (source) => {
        if (source === 'bank_overtime') return 1
        if (source === 'bank_holiday') return 2
        if (source === 'bank_saturday') return 3
        if (source === 'bank_sunday') return 4
        if (source === 'bank_night') return 5
        if (source === 'bank_hours') return 6
    }

    const type = determinateType(source)

    return (
        <div>
            {view === 1 && <HistoryView modalBank={modalBank} type={type} />}
            {view === 2 && (
                <SettingsView
                    contract={contract}
                    source={source}
                    setContracts={setContracts}
                    onClose={onClose}
                    type={type}
                />
            )}
        </div>
    )
}

const HistoryView = ({ modalBank, type }) => {
    const { t } = useTranslation()

    const dateFormat = useSelector((state) => state.date_format)

    return (
        <ListView
            as="table"
            isModal
            endpoint={API.CONTRACTS + 'bank_history/'}
            initialFilters={{
                type: type,
                contract: modalBank.contract.id,
                ordering: ['-created_on'],
            }}
            tableHeaders={[
                { title: t('operation') },
                { title: t('hours') },
                { title: t('record') },
                { title: t('reason') },
                { title: t('created_by') },
            ]}
            renderCells={(history, setData) => [
                {
                    content: history.operation === 1 ? t('increased_volume') : t('decreased_volume'),
                },
                {
                    content: history.amount + ' ' + t('hours_shortcut') + '.',
                },
                {
                    content: history.attendance_record ? (
                        <>{tzDateTime(history.attendance_record?.date).format(dateFormat)}</>
                    ) : null,
                },
                {
                    content: history.note === 'correction_record_create' ? t(history.note) : history.note,
                },
                {
                    content: (
                        <div>
                            {tzDateTime(history.created_on).format(dateFormat + ' HH:mm')} <br />
                            <span>
                                {t('created_by')} <strong>{history.created_by?.name || '--'}</strong>
                            </span>
                        </div>
                    ),
                },
            ]}
        />
    )
}

const SettingsView = ({ contract, source, setContracts, onClose, type }) => {
    const { t } = useTranslation()
    const [processing, setProcessing] = useState(false)
    const [form, setForm] = useState({
        action: '',
        hours: '',
        reason: '',
    })

    const handleSubmit = async () => {
        setProcessing(true)
        const request = await requests.post(API.CONTRACTS + 'bank_history/', {
            type: type,
            operation: form.action,
            amount: parseFloat(form.hours),
            contract: contract.id,
            note: form.reason,
        })

        if (request.status === 201) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: form.action === 1 ? t('hours_were_increased') : t('hours_were_decreased'),
                animation: 'pulse',
                time: 2000,
            })
            const updatedContract = request.response.contract
            setContracts((prev) =>
                prev.filter((item) => {
                    if (item.id === updatedContract.id) {
                        item[source] = updatedContract[source]
                    }
                    return item
                })
            )
            onClose()
        }

        setProcessing(false)
    }

    const isHourEntryInvalid = () => {
        if (form.action === 2) {
            // decrease
            if (source !== 'bank_hours' && parseFloat(form.hours) > parseFloat(contract[source])) {
                return false
            }
        }

        return true
    }

    const isFormValid = () => {
        if (form.action === '') return false
        if (form.reason === '') return false

        // validate hours input
        let hours = form.hours
        if (hours === '') return false
        if (hours <= 0 || isNaN(hours) || !isHourEntryInvalid()) return false

        return true
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField
                as="choice"
                label={t('operation')}
                clearable={false}
                customOptions={[
                    { key: 1, value: 1, text: t('increase') },
                    { key: 2, value: 2, text: t('decrease') },
                ]}
                value={form.action}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, action: value }))}
            />

            <div style={{ marginBottom: '1rem' }}>
                <strong>{t('available_hours')}</strong>: {contract[source]} {t('hours_shortcut').toLocaleLowerCase()}.
            </div>
            <Divider />

            <SuperField
                as="input"
                required
                disabled={form.action === ''}
                value={form.hours}
                label={t('enter_hours')}
                placeholder={t('enter_hours')}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, hours: value.replace(',', '.') }))}
                error={
                    (form.hours <= 0 || isNaN(form.hours) || !isHourEntryInvalid()) && form.hours !== ''
                        ? t('invalid_entry')
                        : false
                }
            />

            <SuperField
                as="textarea"
                required
                disabled={form.action === ''}
                label={t('reason')}
                value={form.reason}
                placeholder={t('enter_reason')}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, reason: value }))}
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit loading={processing} disabled={processing || !isFormValid()} />
            </Form.Field>
        </Form>
    )
}

export default BankModalView
