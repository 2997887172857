const choicesReducer = (choices = {}, action) => {
    switch (action.type) {
        case "ADD_CHOICES":
            return action.choices;
        default:
            return choices;
    }
};

export default choicesReducer;
