import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import '@store/languages'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { thousandsSeparators } from '@helpers/functions'
// components
import {
    PieChart,
    Pie,
    Sector,
    Bar,
    XAxis,
    YAxis,
    ReferenceLine,
    Tooltip,
    Legend,
    ResponsiveContainer,
    BarChart,
} from 'recharts'
import { Segment, Grid, Header, Divider } from 'semantic-ui-react'

const CategoryStats = ({ isDetail, filters, listData }) => {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState(null)
    const [data, setData] = useState([])

    const [activeIndex1, setActiveIndex1] = useState(0)
    const [activeIndex2, setActiveIndex2] = useState(0)
    const [activeBar, setActiveBar] = useState(null)

    const [categoryNames, setCategoryNames] = useState([])

    const year = filters?.year || parseInt(moment().format('YYYY'))
    const monthStart = filters?.monthStart || 1
    const monthEnd = filters?.monthEnd || 12
    const currency = filters?.currency || 'EUR'

    const [incomePie, setIncomePie] = useState([])
    const [expensePie, setExpensePie] = useState([])

    const [percentValue1, setPercentValue1] = useState(0)
    const [percentValue2, setPercentValue2] = useState(0)

    const renderActiveShape1 = (props) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent } = props

        if (percent) setPercentValue1(percent)

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
            </g>
        )
    }
    const renderActiveShape2 = (props) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent } = props

        if (percent) setPercentValue2(percent)

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
            </g>
        )
    }

    useEffect(() => {
        async function fetchData() {
            setData([])
            setResponse(null)
            setActiveBar(null)
            setLoading(true)
            let graphData = []

            // fetch data from endpoint for year overview
            let extraParams = ''
            if (filters.cost_center !== '') extraParams += `&cost_center=${filters.cost_center}`
            if (filters.cost_centers !== '') extraParams += `&cost_centers=${filters.cost_centers}`
            if (filters?.[isDetail ? 'tags' : 'cost_center_tags']?.length > 0) {
                for (let i = 0; i < filters?.[isDetail ? 'tags' : 'cost_center_tags'].length; i++) {
                    extraParams += `&${isDetail ? 'tags' : 'cost_center_tags'}=${
                        filters?.[isDetail ? 'tags' : 'cost_center_tags'][i]
                    }`
                }
            }
            if (filters.unit !== '') extraParams += `&unit=${filters.unit}`
            if (filters.date_from !== '' && filters.date_to !== '') {
                extraParams += `&date_after=${filters.date_from}`
                extraParams += `&date_before=${filters.date_to}`
            }

            const categoryNameList = []
            const requestCategories = await requests.get(API.COST_CENTERS_RECORD_CATEGORIES)
            if (requestCategories.status === 200) {
                for (let a = 0; a < requestCategories.response.length; a++) {
                    categoryNameList.push(requestCategories.response[a].title)
                }

                setCategoryNames(categoryNameList)
            }

            const request = await requests.get(
                API.REPORTS +
                    `cost_centers/category_monthly_ratios/?year=${year}&month_start=${monthStart}&month_end=${monthEnd}&currency=${currency}${extraParams}`
            )
            if (request.status === 200) {
                setResponse(request.response)

                const selectedYear = request.response.year_data

                for (let i = 0; i < selectedYear.length; i++) {
                    let graphDataObject = []

                    graphDataObject['name'] = selectedYear[i].month

                    let categories = selectedYear[i].categories
                    let total_income = 0
                    let total_expense = 0

                    for (let j = 0; j < categories.length; j++) {
                        total_income += categories[j].total_converted_incomes
                        total_expense += categories[j].total_converted_expenses
                        graphDataObject[categories[j].category_name + '_income'] = categories[j].total_converted_incomes
                        graphDataObject[categories[j].category_name + '_expense'] =
                            categories[j].total_converted_expenses
                    }
                    graphDataObject['total_income'] = total_income
                    graphDataObject['total_expense'] = total_expense
                    graphDataObject['total'] = total_income - total_expense

                    graphData.push(graphDataObject)
                }

                setData(graphData)
            }
            setLoading(false)
        }

        fetchData()
        // eslint-disable-next-line
    }, [listData])

    useEffect(() => {
        async function fetchData() {
            const selectedYear = response.year_data

            if (activeBar) {
                const selectedMonth = selectedYear[activeBar - 1]
                let categories = selectedMonth.categories

                let pieIncome = []
                let pieExpense = []

                for (let j = 0; j < categories.length; j++) {
                    const income = categories[j].total_converted_incomes
                    const expense = categories[j].total_converted_expenses
                    const categoryName = categories[j].category_name

                    if (income > 0) {
                        pieIncome.push({
                            id: categoryName,
                            name: categoryName,
                            value: income,
                            fill: getColorById(categoryName),
                        })
                    }
                    if (expense > 0) {
                        pieExpense.push({
                            id: categoryName,
                            name: categoryName,
                            value: expense,
                            fill: getColorById(categoryName),
                        })
                    }
                }
                setIncomePie(pieIncome)
                setExpensePie(pieExpense)
            } else {
                let total = []
                let pieIncome = []
                let pieExpense = []

                for (let i = 0; i < selectedYear.length; i++) {
                    let categories = selectedYear[i].categories

                    for (let j = 0; j < categories.length; j++) {
                        const income = categories[j].total_converted_incomes
                        const expense = categories[j].total_converted_expenses
                        const categoryName = categories[j].category_name
                        if (total[j]) {
                            total[j].income += income
                            total[j].expense += expense
                        } else {
                            total.push({
                                name: categoryName,
                                income: income,
                                expense: expense,
                            })
                        }
                    }
                }
                for (let k = 0; k < total.length; k++) {
                    const income = total[k].income
                    const expense = total[k].expense
                    const categoryName = total[k].name

                    if (income > 0) {
                        pieIncome.push({
                            id: categoryName,
                            name: categoryName,
                            value: income,
                            fill: getColorById(categoryName),
                        })
                    }
                    if (expense > 0) {
                        pieExpense.push({
                            id: categoryName,
                            name: categoryName,
                            value: expense,
                            fill: getColorById(categoryName),
                        })
                    }
                }
                setIncomePie(pieIncome)
                setExpensePie(pieExpense)
            }
        }
        if (response) fetchData()
    }, [activeBar, response])

    const onPieEnter1 = (_, index) => {
        setActiveIndex1(index)
    }
    const onPieEnter2 = (_, index) => {
        setActiveIndex2(index)
    }

    const onBarClick = (_, index) => {
        if (_?.activeLabel === undefined) return
        setActiveBar(_?.activeLabel)
    }

    const getColorById = (id) => {
        const letters = '0123456789ABCDEF'
        let color = '#'
        for (let i = 0; i < 6; i++) {
            const charIndex = id.charCodeAt(i % id.length) % 16
            color += letters[charIndex]
        }
        return color
    }

    const CustomTooltip = ({ active, payload, label }) => {
        const { t } = useTranslation()

        if (active && payload && payload.length) {
            const filteredData = payload.filter((item) => item.value > 0)
            const filteredIncomeData = filteredData.filter((item) => item.dataKey.endsWith('_income'))
            const filteredExpenseData = filteredData.filter((item) => item.dataKey.endsWith('_expense'))

            const getNameFromDataKey = (dataKey) => {
                return dataKey.replace('_income', '').replace('_expense', '')
            }

            const renderedIncomeData = filteredIncomeData.map((item) => (
                <p style={{ paddingBottom: 0 }}>
                    <b>{getNameFromDataKey(item.dataKey)}</b>
                    {': ' + item.value + ' ' + currency}
                </p>
            ))

            const renderedExpenseData = filteredExpenseData.map((item) => (
                <p style={{ marginBottom: 0 }}>
                    <b>{getNameFromDataKey(item.dataKey)}</b>
                    {': ' + item.value + ' ' + currency}
                </p>
            ))

            return (
                <div style={{ padding: '0.5rem', background: 'var(--white)', border: '1px solid var(--light)' }}>
                    {filteredIncomeData.length === 0 && filteredExpenseData.length === 0 && <p>{t('no_data')}</p>}

                    {filteredIncomeData.length > 0 && (
                        <>
                            <strong style={{ color: 'var(--success)' }}>{t('income')}</strong>
                            <Divider style={{ margin: 0 }} />
                            {renderedIncomeData}
                        </>
                    )}
                    {filteredExpenseData.length > 0 && (
                        <>
                            <strong style={{ color: 'var(--danger)' }}>{t('expense')}</strong>
                            <Divider style={{ margin: 0 }} />
                            {renderedExpenseData}
                        </>
                    )}
                </div>
            )
        }

        return null
    }

    const CustomPieTooltip = ({ number, active, payload }) => {
        if (active && payload && payload.length) {
            const fill = payload[0]?.payload?.payload?.fill || '#fff'
            return (
                <div
                    style={{
                        padding: '0.5rem',
                        background: 'var(--white)',
                        border: '1px solid var(--light)',
                        textAlign: 'left',
                    }}
                >
                    <p
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'left',
                            justifyContent: 'left center',
                            textAlign: 'left',
                            marginBottom: 0,
                        }}
                    >
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                background: fill,
                                marginRight: '0.25rem',
                                marginTop: '0.35rem',
                            }}
                        ></div>
                        <div style={{ fontWeight: 'bold' }}>{payload[0].name}</div>
                    </p>
                    <p style={{ margin: 0 }}>
                        {`${thousandsSeparators(payload[0].value.toFixed(2) || 0)} ${filters.currency || currency}`} (
                        {(number === 1 ? percentValue1 * 100 : percentValue2 * 100).toFixed(2) + '%'})
                    </p>
                </div>
            )
        }

        return null
    }

    return (
        <Segment
            loading={loading}
            style={{
                padding: 0,
                marginTop: 0,
                background: 'transparent',
                boxShadow: 'none',
                border: 'none',
            }}
        >
            {loading && <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '6rem' }}>{t('loading')}</p>}
            {!loading && (
                <Grid stackable>
                    <Grid.Row columns={3} style={{ padding: '0rem' }}>
                        <Grid.Column tablet={16} computer={8}>
                            <ResponsiveContainer width="90%" height={250}>
                                <BarChart
                                    activeIndex={activeBar}
                                    onClick={onBarClick}
                                    data={data}
                                    barSize={20}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Legend />
                                    <Tooltip content={<CustomTooltip />} />
                                    <ReferenceLine y={0} stroke="#000" />
                                    <>
                                        {categoryNames.map((categoryItem) => (
                                            <React.Fragment key={categoryItem}>
                                                <Bar
                                                    dataKey={categoryItem + '_income'}
                                                    name={categoryItem}
                                                    stackId={1}
                                                    fill={getColorById(categoryItem)}
                                                />
                                                <Bar
                                                    dataKey={categoryItem + '_expense'}
                                                    legendType="none"
                                                    stackId={2}
                                                    fill={getColorById(categoryItem)}
                                                />
                                            </React.Fragment>
                                        ))}
                                    </>
                                </BarChart>
                            </ResponsiveContainer>
                        </Grid.Column>
                        <Grid.Column tablet={6} computer={4} style={{ textAlign: 'center' }}>
                            <ResponsiveContainer width="100%" height={200}>
                                <PieChart>
                                    <Pie
                                        activeIndex={activeIndex1}
                                        activeShape={renderActiveShape1}
                                        data={incomePie}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                        overflow={'visible'}
                                        onMouseEnter={onPieEnter1}
                                    />
                                    <Tooltip content={<CustomPieTooltip number={1} />} />
                                </PieChart>
                            </ResponsiveContainer>
                            <Header as={'h5'} style={{ margin: 0, marginBottom: '1rem' }}>
                                {t('income')}
                            </Header>
                        </Grid.Column>
                        <Grid.Column tablet={6} computer={4} style={{ textAlign: 'center' }}>
                            <ResponsiveContainer width="100%" height={200}>
                                <PieChart>
                                    <Pie
                                        activeIndex={activeIndex2}
                                        activeShape={renderActiveShape2}
                                        data={expensePie}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                        overflow={'visible'}
                                        onMouseEnter={onPieEnter2}
                                    />
                                    <Tooltip content={<CustomPieTooltip number={2} />} />
                                </PieChart>
                            </ResponsiveContainer>
                            <Header as={'h5'} style={{ margin: 0, marginBottom: '1rem' }}>
                                {t('expense')}
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </Segment>
    )
}

export default CategoryStats
