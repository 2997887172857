import React from 'react';
import { useSelector } from 'react-redux';

import { HorizontalBar } from 'react-chartjs-2';
import { Grid } from 'semantic-ui-react'
// import { FlexRow, FlexItem } from '@components/tables/FlexTable'

const MembersStats = ({ people_on_project, people_on_project_count }) => {
    const colors = useSelector(state => state.global_pref.colors)

    const labels = people_on_project?.map(({ fullname }) => fullname)

    const data = {
        labels,
        datasets: [
            {
                label: 'Hours',
                data: people_on_project?.map(({ hours }) => hours),
                backgroundColor: colors.primary,
            },
        ],
    };



    return (
        <Grid style={{ width: '100%', padding: 0 }}>
            {people_on_project_count > 0 &&
                <HorizontalBar data={data}
                    width={80}
                    height={people_on_project_count * 4}
                />
            }
        </Grid>
    );
};

export default MembersStats;


