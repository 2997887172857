import React from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { renderAddress } from '@helpers/functions'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import InfoRow from '@components/InfoRow'
import Action from '@components/general/Action'
import CanView from '@components/perms/CanView'
import DocPreview from '@components/DocPreview'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { FlexRow, FlexItem } from '@components/tables/FlexTable'
import { Divider, Header, Button, Card } from 'semantic-ui-react'
import BusinessDetailBankForm from './BusinessDetailBankForm'
import SignatureForm from './SignatureForm'
import AccountantForm from './AccountantForm'

const BusinessDetailView = ({
    data,
    allowSignature,
    allowPayrollProvider,
    isDirect,
    updateState,
    endpoint,
    permissions,
}) => {
    const { t } = useTranslation()

    const onDelete = async (id) => {
        const request = await requests.post(API.BANK_ACCOUNTS + id + '/delete/')
        if (request.status === 200) {
            if (isDirect) {
                updateState((prev) => ({
                    ...prev,
                    bank_accounts: prev.bank_accounts.filter((item) => item.id !== id),
                }))
            } else {
                updateState((prevState) => ({
                    ...prevState,
                    business_detail: {
                        ...prevState.business_detail,
                        bank_accounts: prevState.business_detail.bank_accounts.filter((item) => item.id !== id),
                    },
                }))
            }
        }
    }

    return (
        <>
            <InfoRow isVisible={data?.name !== undefined} text={t('company_name')} content={data?.name} />
            <InfoRow isVisible={true} text={t('tax_mode')} content={data?.tax_mode_display} />
            <InfoRow
                isVisible={data?.identification_number !== undefined}
                text={t('id_number')}
                content={data?.identification_number || '--'}
            />
            <InfoRow isVisible={true} text={t('dit_number')} content={data?.dit_number || '--'} />
            <InfoRow isVisible={true} text={t('vat_number')} content={data?.vat_number || '--'} />
            <InfoRow isVisible={true} text={t('executive_manager')} content={data?.executive_manager || '--'} />
            <InfoRow isVisible={true} text={t('register_folder')} content={data?.business_register_folder || '--'} />
            <InfoRow
                isVisible={true}
                text={t('nace_code')}
                content={data?.nace_code ? data?.nace_code?.code + ' - ' + data?.nace_code?.name : '--'}
            />
            <InfoRow isVisible={true} text={t('billing_address')} content={renderAddress(data?.billing_address)} />
            <InfoRow isVisible={true} text={t('shipping_address')} content={renderAddress(data?.shipping_address)} />
            {allowSignature && (
                <InfoRow
                    isVisible={true}
                    text={t('signature')}
                    content={
                        <div>
                            {data?.signature === null ? (
                                <SuperDuperModal
                                    size="tiny"
                                    header={t('add_business_signature')}
                                    trigger={
                                        <span className="ref-link" style={{ color: 'var(--primary)' }}>
                                            {t('add_business_signature')}
                                        </span>
                                    }
                                    content={
                                        <SignatureForm
                                            id={data.id}
                                            onUpload={(response) =>
                                                updateState((prev) => ({
                                                    ...prev,
                                                    business_detail: {
                                                        ...prev.business_detail,
                                                        signature: response.signature,
                                                    },
                                                }))
                                            }
                                        />
                                    }
                                />
                            ) : (
                                <div style={{ display: 'flex' }}>
                                    <SuperDuperModal
                                        size="small"
                                        trigger={
                                            <div
                                                className="ref-link"
                                                style={{ color: 'var(--primary)', fontWeight: 'bold' }}
                                            >
                                                {t('show_preview')}
                                            </div>
                                        }
                                        content={<DocPreview uri={data.signature} />}
                                    />
                                    <SuperDuperModal
                                        size="tiny"
                                        header={t('add_business_signature')}
                                        trigger={
                                            <small
                                                style={{ marginLeft: '0.5rem', opacity: '0.7' }}
                                                className="ref-link"
                                            >
                                                ({t('change_signature')})
                                            </small>
                                        }
                                        content={
                                            <SignatureForm
                                                id={data.id}
                                                onUpload={(response) =>
                                                    updateState((prev) => ({
                                                        ...prev,
                                                        business_detail: {
                                                            ...prev.business_detail,
                                                            signature: response.signature,
                                                        },
                                                    }))
                                                }
                                            />
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    }
                />
            )}
            {allowPayrollProvider && (
                <InfoRow
                    isVisible={true}
                    text={t('accountant')}
                    content={
                        <div>
                            {!data?.accountant_email ? (
                                <SuperDuperModal
                                    size="tiny"
                                    header={t('add_accountant')}
                                    trigger={
                                        <span className="ref-link" style={{ color: 'var(--primary)' }}>
                                            {t('add_accountant')}
                                        </span>
                                    }
                                    content={
                                        <AccountantForm
                                            id={data.id}
                                            onUpdate={(response) =>
                                                updateState((prev) => ({
                                                    ...prev,
                                                    business_detail: {
                                                        ...prev.business_detail,
                                                        accountant_email: response.accountant_email,
                                                        accountant_phone: response.accountant_phone,
                                                        accountant_name: response.accountant_name,
                                                        external_accountant: response.external_accountant || null,
                                                    },
                                                }))
                                            }
                                        />
                                    }
                                />
                            ) : (
                                <div style={{ display: 'flex' }}>
                                    <div style={{ fontWeight: 'normal' }}>
                                        <strong>{data.accountant_name}</strong>{' '}
                                        {data.external_accountant?.name ? ' - ' + data.external_accountant?.name : ''}
                                        <br />
                                        {data.accountant_email} <br /> {data.accountant_phone} <br />
                                    </div>
                                    <SuperDuperModal
                                        size="tiny"
                                        header={t('update_accountant')}
                                        trigger={
                                            <small
                                                style={{ marginLeft: '0.5rem', opacity: '0.7' }}
                                                className="ref-link"
                                            >
                                                ({t('update_accountant')})
                                            </small>
                                        }
                                        content={
                                            <AccountantForm
                                                id={data.id}
                                                data={data}
                                                onUpdate={(response) =>
                                                    updateState((prev) => ({
                                                        ...prev,
                                                        business_detail: {
                                                            ...prev.business_detail,
                                                            accountant_email: response.accountant_email,
                                                            accountant_phone: response.accountant_phone,
                                                            accountant_name: response.accountant_name,
                                                            external_accountant: response.external_accountant || null,
                                                        },
                                                    }))
                                                }
                                            />
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    }
                />
            )}

            <CanView permissions={[permissions.view]}>
                <div style={{ marginTop: '2rem' }}></div>
                <FlexRow background="transparent" padding="0">
                    <FlexItem>
                        <Header as="h3" content={t('bank_accounts')} />
                    </FlexItem>
                    <FlexItem textAlign="right">
                        <CanView permissions={[permissions.manage]}>
                            <SuperDuperModal
                                size="small"
                                header={t('add_bank_account')}
                                trigger={
                                    <Button basic size="small" style={{ fontWeight: 'bold' }}>
                                        <Icon name="add-outline" style={{ marginRight: '0.5rem' }} />
                                        <span style={{ position: 'relative', top: '-0.2rem' }}>
                                            {t('add_bank_account')}
                                        </span>
                                    </Button>
                                }
                                content={
                                    <BusinessDetailBankForm
                                        isDirect={isDirect}
                                        endpoint={endpoint}
                                        updateState={updateState}
                                    />
                                }
                            />
                        </CanView>
                    </FlexItem>
                </FlexRow>
                <Divider />
                {data?.bank_accounts?.length > 0 ? (
                    <>
                        {data?.bank_accounts?.filter((item) => item.default)?.length === 0 && (
                            <p style={{ color: 'var(--danger)', fontWeight: 'bold' }}>
                                {' '}
                                {t('there_no_default_bank_is_selected')}{' '}
                            </p>
                        )}
                        <Card.Group>
                            {data?.bank_accounts.map((bank) => (
                                <Card fluid style={{ borderRadius: 0 }} key={bank.id}>
                                    <Card.Content>
                                        <Card.Header>
                                            <FlexRow background="transparent" padding="0">
                                                <FlexItem bold>{bank.name}</FlexItem>
                                                <FlexItem basis="10%" textAlign="right">
                                                    <FlexRow background="transparent" padding="0">
                                                        <FlexItem textAlign="center">
                                                            <CanView permissions={[permissions.manage]}>
                                                                <Action
                                                                    as="modal"
                                                                    iconColor="var(--dark)"
                                                                    type="icon"
                                                                    icon="pencil-outline"
                                                                    tooltip={t('edit')}
                                                                    modal={
                                                                        <BusinessDetailBankForm
                                                                            isDirect={isDirect}
                                                                            endpoint={endpoint}
                                                                            updateState={updateState}
                                                                            data={bank}
                                                                        />
                                                                    }
                                                                />
                                                            </CanView>
                                                        </FlexItem>
                                                        <FlexItem textAlign="center">
                                                            <CanView permissions={[permissions.delete]}>
                                                                <Action
                                                                    as="delete"
                                                                    tooltip={t('delete')}
                                                                    text={t('delete_bank')}
                                                                    onClick={() => onDelete(bank.id)}
                                                                />
                                                            </CanView>
                                                        </FlexItem>
                                                    </FlexRow>
                                                </FlexItem>
                                            </FlexRow>
                                        </Card.Header>
                                        <Card.Meta>
                                            <strong>
                                                {bank.iban} | {bank.swift_code} | {bank.currency}
                                                {bank.default && ' | '}
                                                <span
                                                    style={{ color: bank.default ? 'var(--primary)' : 'var(--dark)' }}
                                                >
                                                    {bank.default ? t('as_default') : ''}
                                                </span>
                                            </strong>
                                        </Card.Meta>
                                        {bank.bank_address && (
                                            <Card.Description>
                                                <Card.Meta>{t('bank_address')}</Card.Meta>
                                                {renderAddress(bank.bank_address)}
                                            </Card.Description>
                                        )}
                                    </Card.Content>
                                </Card>
                            ))}
                        </Card.Group>
                    </>
                ) : (
                    <p style={{ textAlign: 'center', fontWeight: 'bold' }}> {t('bank_accounts_not_provided')} </p>
                )}
            </CanView>
        </>
    )
}

export default BusinessDetailView
