import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { toast } from 'react-semantic-toasts'
import { dates } from '@store/services/helpers/dates'
// store
import { routes } from '@routes'
import { API } from '@store/config'
import { getRequest, patchRequest } from '@services/ServiceCommon'
// components
import { Form, Divider, Icon, Popup, Button, Header } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import BudgetDetailView from '../../cost-centers/components/BudgetDetailView'
import InvoicePreviewPDF from '../../../sm/invoices/components/InvoicePreviewPDF'

const onPerformAction = async (status, approval, setData, setTotal, note, user, dateFormat) => {
    if (approval.resourcetype === 'InvoiceApproval') {
        let approvalData = {}

        if (user.profile_id === approval.responsible_person?.id) {
            approvalData['approved_by_responsible_person'] = status
            if (note !== undefined) {
                approvalData['responsible_person_note'] = note
            }
        }

        if (user.profile_id === approval.second_responsible_person?.id) {
            approvalData['approved_by_second_responsible_person'] = status
            if (note !== undefined) {
                approvalData['second_responsible_person_note'] = note
            }
        }

        if (user.profile_id === approval.reviewer?.id) {
            approvalData['approved_by_reviewer'] = status
            if (note !== undefined) {
                approvalData['reviewer_note'] = note
            }
        }

        if (user.profile_id === approval.second_reviewer?.id) {
            approvalData['approved_by_second_reviewer'] = status
            if (note !== undefined) {
                approvalData['second_reviewer_note'] = note
            }
        }

        const request = await patchRequest(API.APPROVALS + 'invoices/' + approval.id + '/', approvalData)

        if (request.status === 200) {
            const approvalStatus = request.response?.is_approved
            const approval_date = request.response?.approval_datetime
                ? moment(dates.convertUTCDateToLocalDate(request.response.approval_datetime)).format(
                      `${dateFormat} HH:mm`
                  )
                : null
            if (approvalStatus === true || approvalStatus === false) {
                const connectionRequest = await getRequest(API.INVOICES + 'invoice_connections/')
                if (connectionRequest.status === 200) {
                    const invoice_id = approval.invoice_reference.split(';')[1]
                    let connection = connectionRequest?.response?.[0]
                    await patchRequest(API.INVOICES + invoice_id + '/?connection=' + connection.connection_id, {
                        approval: approvalStatus === true ? 1 : 3,
                        date_approved: approvalStatus === true ? approval_date : null,
                    })

                    // if is_approved is set to TRUE, try to change status from Draft to Pending
                    if (approvalStatus === true) {
                        await patchRequest(API.INVOICES + invoice_id + '/?connection=' + connection.connection_id, {
                            status: 1,
                        })
                    }
                }
            }

            if ([true, false].includes(approvalStatus)) {
                setTotal((prev) => prev - 1)
                setData((prev) => prev.filter((data) => data.id !== approval.id))
            } else {
                setData((prev) =>
                    prev.filter((item) => {
                        if (item.id === approval.id) {
                            item.is_approved = request.response.is_approved
                            item.automatic_approval = request.response.automatic_approval
                            item.approved_by_responsible_person = request.response.approved_by_responsible_person
                            item.approved_by_second_responsible_person =
                                request.response.approved_by_second_responsible_person
                            item.approved_by_reviewer = request.response.approved_by_reviewer
                            item.approved_by_second_reviewer = request.response.approved_by_second_reviewer
                            item.responsible_person_note = request.response.responsible_person_note
                            item.second_responsible_person_note = request.response.second_responsible_person_note
                            item.reviewer_note = request.response.reviewer_note
                            item.second_reviewer_note = request.response.second_reviewer_note
                            item.responsible_person = request.response.responsible_person
                            item.second_responsible_person = request.response.second_responsible_person
                            item.reviewer = request.response.reviewer
                            item.second_reviewer = request.response.second_reviewer
                        }

                        return item
                    })
                )
            }
            toast({
                type: 'success',
                icon: 'check circle',
                title: status ? 'approved' : 'denied',
                description: '#' + approval.id,
                animation: 'bounce',
                time: 5000,
            })
        } else if (request.status === 400) {
            toast({
                type: 'error',
                icon: 'warning circle',
                title: 'error - #' + approval.id,
                description: request.response?.non_field_errors?.[0] || '',
                animation: 'bounce',
                time: 5000,
            })
        }
    } else {
        const request = await patchRequest(API.APPROVALS + approval.id + '/', {
            is_approved: status,
        })

        if (request.status === 200) {
            setTotal((prev) => prev - 1)
            setData((prev) => prev.filter((data) => data.id !== approval.id))
        } else if (request.status === 400) {
            toast({
                type: 'error',
                icon: 'warning circle',
                title: 'error',
                description: request.response.detail,
                animation: 'bounce',
                time: 5000,
            })
        }
    }
}

const InvoiceApprover = ({ approver, decision, note, type }) => {
    const { t } = useTranslation()
    return (
        <div>
            {approver?.fullname ? (
                <>
                    {decision === null && <Icon name="circle outline" style={{ color: 'transparent' }} />}
                    {decision === true && <Icon name="checkmark" style={{ color: 'var(--success)' }} />}
                    {decision === false && <Icon name="close" style={{ color: 'var(--danger)' }} />}
                    <Link
                        className="ref-link"
                        title={t(type)}
                        to={routes.EMPLYOEES_DETAIL + approver?.id}
                        target="_blank"
                    >
                        {approver?.fullname}
                    </Link>{' '}
                    {note !== '' && note !== null && (
                        <Popup
                            basic
                            trigger={
                                <Icon
                                    name="file alternate outline"
                                    style={{ paddingLeft: '0.5rem', color: 'var(--dark)' }}
                                />
                            }
                            content={note}
                        />
                    )}
                </>
            ) : (
                ''
            )}
        </div>
    )
}

const RecruitmentStageApproval = ({ approval }) => {
    const { t } = useTranslation()
    return (
        <>
            {t('recruitment')} - {t('move_to_stage_request')}:<br />
            <small>
                {t('from')} <u>{approval.stage_from.name}</u> {t('to')} <u>{approval.stage_to.name}</u> <br />
            </small>
            <Link
                className="ref-link"
                to={routes.HIRING_ROOM + approval.recruitment.id + routes.CANDIDATES_DETAIL + approval.candidate.id}
                target="_blank"
            >
                {t('show_details')}
            </Link>
        </>
    )
}

const TimesheetApproval = ({ approval }) => {
    const { t } = useTranslation()
    return (
        <>
            {t('timesheets')} - {t('approve_hours')}:<br />
            {approval.stats.map((stat) => (
                <>
                    <strong>
                        {stat.fullname} - {stat.total_hours}
                    </strong>{' '}
                    <br />
                </>
            ))}
        </>
    )
}

const ProjectApproval = ({ approval }) => {
    const { t } = useTranslation()
    return (
        <>
            {t('project')}:{' '}
            <Link className="ref-link" to={routes.PROJECT_DETAIL + approval.project.id + '/'} target="_blank">
                {approval?.project?.name}
            </Link>
        </>
    )
}

const BudgetApproval = ({ approval }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    return (
        <>
            {t('budget')}:{' '}
            <SuperDuperModal
                size="large"
                trigger={
                    <span style={{ color: 'var(--primary)' }} className="ref-link">
                        {moment(dates.convertUTCDateToLocalDate(approval?.budget?.date_from)).format(
                            `${dateFormat} HH:mm`
                        )}{' '}
                        -{' '}
                        {moment(dates.convertUTCDateToLocalDate(approval?.budget?.date_to)).format(
                            `${dateFormat} HH:mm`
                        )}
                    </span>
                }
                content={<BudgetDetailView budget={approval.budget} />}
            />
        </>
    )
}

const InvoiceApproval = ({ approval, user }) => {
    const { t } = useTranslation()
    const reference = approval.invoice_reference.split(';')
    return (
        <>
            {t('recieved_invoice')}:{' '}
            <SuperDuperModal
                size="large"
                trigger={
                    <span style={{ color: 'var(--primary)' }} className="ref-link">
                        {reference?.[2]}
                    </span>
                }
                content={
                    <InvoicePreviewPDF
                        id={reference?.[1]}
                        approvalActions={
                            approval?.approved_by?.id !== user?.profile?.id && (
                                <>
                                    <InvoiceApprovalConfirmation approval={approval} confirmType={'approve'} />
                                    <InvoiceApprovalConfirmation approval={approval} confirmType={'deny'} />
                                </>
                            )
                        }
                    />
                }
            />
            {reference.length > 4 && (
                <>
                    <br />
                    {reference?.[3] + ', ' + reference?.[4] + ', ' + reference?.[5]}
                </>
            )}
        </>
    )
}

const PlannedCourseApproval = ({ approval }) => {
    const { t } = useTranslation()
    return (
        <>
            {t('planned_course')}:{' '}
            <Link className="ref-link" to={routes.COURSES} target="_blank">
                {approval?.planned_course?.course?.title}
            </Link>
        </>
    )
}

const RecruitmentApproval = ({ approval }) => {
    const { t } = useTranslation()
    return (
        <>
            {t('recruitment')}:{' '}
            <Link className="ref-link" to={routes.HIRING_ROOMS + approval?.recruitment?.id + '/'} target="_blank">
                {approval?.recruitment?.name}
            </Link>
        </>
    )
}

const BonusPaymentApproval = ({ approval }) => {
    const { t } = useTranslation()
    return (
        <>
            <strong>{t('reward')}:</strong> {approval?.bonus?.amount} {approval?.bonus?.currency}{' '}
            {approval?.bonus?.bonus_type ? '(' + approval?.bonus?.bonus_type?.title + ')' : ''} <br />
            <strong>{t('billing_period')}:</strong> {approval?.bonus?.billing_month}/{approval?.bonus?.billing_year}{' '}
            <br />
            <strong>{t('employee')}:</strong> {approval?.bonus?.contract?.employee?.fullname} <br />
            {approval?.bonus?.note}
        </>
    )
}

const AdvancePaymentApproval = ({ approval }) => {
    const { t } = useTranslation()
    return (
        <>
            <strong>{t('advance')}:</strong> {approval?.advance?.amount} {approval?.advance?.currency} <br />
            <strong>{t('billing_period')}:</strong> {approval?.advance?.billing_month}/{approval?.advance?.billing_year}{' '}
            <br />
            <strong>{t('employee')}:</strong> {approval?.advance?.contract?.employee?.fullname} <br />
            {approval?.advance?.note}
        </>
    )
}

const DeductionPaymentApproval = ({ approval }) => {
    const { t } = useTranslation()
    return (
        <>
            <strong>{t('deduction')}:</strong> {approval?.deduction?.amount} {approval?.deduction?.currency} <br />
            <strong>{t('billing_period')}:</strong> {approval?.deduction?.billing_month}/
            {approval?.deduction?.billing_year} <br />
            <strong>{t('employee')}:</strong> {approval?.deduction?.contract?.employee?.fullname} <br />
            {approval?.deduction?.note}
        </>
    )
}

const isAssignedUser = (approval, hasPermToManageAll, user) => {
    if (approval.resourcetype === 'InvoiceApproval') {
        if (user.profile_id === approval?.responsible_person?.id) {
            return true
        }

        if (user.profile_id === approval?.second_responsible_person?.id) {
            return true
        }

        if (user.profile_id === approval?.reviewer?.id) {
            return true
        }

        if (user.profile_id === approval?.second_reviewer?.id) {
            return true
        }
    } else {
        if (hasPermToManageAll) {
            return true
        } else {
            if (user.profile_id === approval?.responsible_person?.id) {
                return true
            }
        }
    }

    return false
}

const InvoiceApprovalConfirmation = ({ approval, confirmType, hasPermToManageAll }) => {
    const user = useSelector((state) => state.user)

    const ConfirmType = ({ confirmType, approval, hasPermToManageAll, ...rest }) => {
        const { t } = useTranslation()
        if (confirmType === 'approve') {
            return (
                <Button
                    {...rest}
                    disabled={!isAssignedUser(approval, hasPermToManageAll, user) || isConfirmed(approval, true, user)}
                    primary
                >
                    {t(confirmType)}
                </Button>
            )
        } else {
            return (
                <Button
                    {...rest}
                    disabled={!isAssignedUser(approval, hasPermToManageAll, user) || isConfirmed(approval, false, user)}
                    style={{ background: 'var(--danger)', color: 'var(--white)' }}
                >
                    {t('deny')}
                </Button>
            )
        }
    }

    const ConfirmForm = ({ onConfirm, onClose, approval, setData, setTotal, confirmType }) => {
        const [isProcessing, setIsProcessing] = useState(false)
        const [note, setNote] = useState('')
        const { t } = useTranslation()

        const handleSubmit = async () => {
            setIsProcessing(true)
            if (confirmType === 'approve') {
                await onConfirm(true, approval, setData, setTotal, note)
            } else {
                await onConfirm(false, approval, setData, setTotal, note)
            }

            onClose()
            setIsProcessing(false)
        }

        return (
            <Form onSubmit={handleSubmit}>
                <Header as="h3" content={t('are_you_sure')} />
                <Divider />
                <SuperField
                    fluid
                    as="textarea"
                    value={note}
                    onChange={(e, { value }) => setNote(value)}
                    label={t('note')}
                />
                <Divider />
                <Form.Field style={{ textAlign: 'right' }}>
                    <ModalCancel onClose={onClose} disabled={isProcessing} />

                    <ModalSubmit disabled={isProcessing} loading={isProcessing} text={t('confirm')} />
                </Form.Field>
            </Form>
        )
    }

    return (
        <SuperDuperModal
            trigger={
                <ConfirmType confirmType={confirmType} approval={approval} hasPermToManageAll={hasPermToManageAll} />
            }
            content={<ConfirmForm onPerformAction={onPerformAction} approval={approval} confirmType={confirmType} />}
        />
    )
}

const isConfirmed = (approval, status, user) => {
    if (approval.resourcetype === 'InvoiceApproval') {
        if (
            user.profile_id === approval?.responsible_person?.id &&
            approval?.approved_by_responsible_person === status
        ) {
            return true
        }

        if (
            user.profile_id === approval?.second_responsible_person?.id &&
            approval?.approved_by_second_responsible_person === status
        ) {
            return true
        }

        if (user.profile_id === approval?.reviewer?.id && approval?.approved_by_reviewer === status) {
            return true
        }

        if (user.profile_id === approval?.second_reviewer?.id && approval?.approved_by_second_reviewer === status) {
            return true
        }
    } else {
        return approval.is_approved === status
    }
}

const ConfirmForm = ({ onConfirm, onClose, approval, confirmType, setData, setTotal, isNotInvoice }) => {
    const [isProcessing, setIsProcessing] = useState(false)
    const [note, setNote] = useState('')
    const { t } = useTranslation()

    const handleSubmit = async () => {
        setIsProcessing(true)
        if (confirmType === 'approve') {
            await onConfirm(true, approval, setData, setTotal, note)
        } else {
            await onConfirm(false, approval, setData, setTotal, note)
        }

        onClose()
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Header as="h3" content={t('are_you_sure')} />
            <Divider />
            <SuperField
                fluid
                as="textarea"
                value={note}
                onChange={(e, { value }) => setNote(value)}
                label={t('note')}
            />
            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} disabled={isProcessing} />
                <ModalSubmit disabled={isProcessing} loading={isProcessing} text={t('confirm')} />
            </Form.Field>
        </Form>
    )
}

export {
    ProjectApproval,
    BudgetApproval,
    InvoiceApproval,
    TimesheetApproval,
    RecruitmentStageApproval,
    RecruitmentApproval,
    PlannedCourseApproval,
    BonusPaymentApproval,
    AdvancePaymentApproval,
    DeductionPaymentApproval,
    InvoiceApprover,
    ConfirmForm,
    onPerformAction,
}
