import { routes } from '@store/routes'
import Swal from 'sweetalert2'
import { isEmpty } from './validation'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export function alertDialog(
    title,
    time,
    handleClose,
    handleRestore,
    endActivityDisplayText,
    cancelActionDisplayText,
    countDownTimer
) {
    let dialogCountDownTimer = countDownTimer === undefined ? 3 : countDownTimer
    let timerInterval
    MySwal.fire({
        allowOutsideClick: false,
        allowEscapeKey: false,
        title: title,
        showConfirmButton: false,
        html:
            '<p style="font-size: 1.5rem; font-weight: bold;">' +
            time +
            '</p>' +
            endActivityDisplayText +
            ' <strong></strong>...',
        timer: dialogCountDownTimer * 1000,
        timerProgressBar: true,
        didOpen: () => {
            MySwal.hideLoading()
            const b = MySwal.getHtmlContainer().querySelector('strong')
            timerInterval = setInterval(() => {
                b.textContent = Math.ceil(MySwal.getTimerLeft() / 1000)
            }, 100)

            document.getElementById('stop').addEventListener('click', async function () {
                MySwal.stopTimer()
                clearInterval(timerInterval)
                await handleRestore()
                MySwal.close()
            })
        },
        willClose: () => {
            clearInterval(timerInterval)
        },
        footer: '<h3 id="stop" style="cursor: pointer; text-align: center;">' + cancelActionDisplayText + '</h3>',
    }).then((result) => {
        if (result.dismiss === MySwal.DismissReason.timer) {
            handleClose()
        }
    })
}

export function generateNumberCombination() {
    const minLength = 8
    const maxLength = 10
    const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength
    let result = ''

    for (let i = 0; i < length; i++) {
        result += Math.floor(Math.random() * 10)
    }

    return result
}

export function truncateString(str, size) {
    return str.length > size ? str.substring(0, size - 3) + '...' : str
}

export const toggleSidebar = () => {
    // find element by classname
    let wrapper = document.querySelector('.wrapper')
    let toggle_icon = document.querySelector('.toggle-icon')

    // check if contain specific classname
    if (wrapper.classList.contains('collapsed')) {
        // remove
        wrapper.classList.remove('collapsed')
        toggle_icon.classList.remove('right')
        toggle_icon.classList.add('left')
    } else {
        // add
        wrapper.classList.add('collapsed')
        toggle_icon.classList.remove('left')
        toggle_icon.classList.add('right')
    }
}

export function getRandomColor() {
    var letters = '0123456789ABCDEF'
    var color = '#'
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
    }
    return color
}

export function forbidenHandler(request, history) {
    if (request.status === 403) {
        history.push(routes.DASHBOARD)
    }
}

export function string_to_slug(str) {
    str = str.replace(/^\s+|\s+$/g, '') // trim
    str = str.toLowerCase()

    // remove accents, swap ñ for n, etc
    var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
    var to = 'aaaaeeeeiiiioooouuuunc------'
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    str = str
        .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-') // collapse dashes

    return str
}

export function getValue(object, key) {
    function f(o) {
        if (!o || typeof o !== 'object') {
            return
        }
        if (key in o) {
            reference = o
            return true
        }
        Object.keys(o).some(function (k) {
            return f(o[k])
        })
    }

    let reference
    f(object)
    return reference
}

export function parseJsonStringToObject(jsonString) {
    jsonString = jsonString.replace(/'/g, '"')
    jsonString = jsonString.replace(/None/g, null)
    jsonString = jsonString.replace(/""/g, null)

    return JSON.parse(jsonString)
}

export function dataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
}
export function makeQuery(property, regexp) {
    return function (elem, index, array) {
        return elem[property].search(regexp) !== -1
    }
}

export const getParams = (urlPath) => {
    if (urlPath === null || urlPath === undefined) return null

    const url = new URL(urlPath)
    return url.search
}

export const setDotSeparator = (input) => {
    return input.replace(',', '.')
}

export const isValidNumber = (number, allow_blank, allow_negative) => {
    if (number === '' && allow_blank !== true) return false
    if (isNaN(number)) return false
    if (parseFloat(number) < 0 && allow_negative !== true) return false

    return true
}

export const isDescendantElement = (parent, child) => {
    let node = child.parentNode

    while (node !== null) {
        if (node === parent) {
            return true
        }
        node = node.parentNode
    }

    return false
}

export function getQueryParam(name, url = window.location.href) {
    // eslint-disable-next-line
    name = name.replace(/[\[\]]/g, '\\$&')
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function getUrlParameters(url = window.location.href) {
    let retObject = {},
        parameters

    if (url.indexOf('?') === -1) {
        return null
    }

    url = url.split('?')[1]

    parameters = url.split('&')

    for (var i = 0; i < parameters.length; i++) {
        retObject[parameters[i].split('=')[0]] = parameters[i].split('=')[1]
    }

    return retObject
}

export function removeURLParameter(url, parameter) {
    var prefix = encodeURIComponent(parameter) + '='
    var pars = url.split(/[&;]/g)

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0; ) {
        //idiom for string.startsWith
        if (pars[i].startsWith(prefix)) {
            pars.splice(i, 1)
        }
    }

    return pars.join('&')
}

export function renderShortLongAddress(address) {
    if (address === null || address === undefined) return '--'

    if (!isEmpty(address.street)) {
        let street = address.street

        if (!isEmpty(address.number) && isEmpty(address.orientation_number)) {
            street += ` ${address.number}`
        } else if (isEmpty(address.number) && !isEmpty(address.orientation_number)) {
            street += ` ${address.orientation_number}`
        } else if (!isEmpty(address.number) && !isEmpty(address.orientation_number)) {
            street += ` ${address.orientation_number}/${address.number}`
        }

        return (
            street +
            ', ' +
            address.postcode +
            ' ' +
            address.city +
            ', ' +
            (address?.country_display || address?.country)
        )
    } else {
        return address.city ? address.city + ', ' + (address?.country_display || address?.country) : '--'
    }
}

export function renderAddress(address, returnBlank) {
    if (address === null || address === undefined) return returnBlank ? '' : '--'
    if (isEmpty(address.street)) return returnBlank ? '' : '--'

    let street = address.street

    if (!isEmpty(address.number) && isEmpty(address.orientation_number)) {
        street += ` ${address.number}`
    } else if (isEmpty(address.number) && !isEmpty(address.orientation_number)) {
        street += ` ${address.orientation_number}`
    } else if (!isEmpty(address.number) && !isEmpty(address.orientation_number)) {
        street += ` ${address.orientation_number}/${address.number}`
    }

    // Nemcovce 91, 082 12 Nemcovce, Slovensko
    return street + ', ' + address.postcode + ' ' + address.city + ', ' + (address?.country_display || address?.country)
}

export function thousandsSeparators(value, separator) {
    if (separator == null) {
        separator = ' '
    }

    // split decimals
    var parts = value.toString().split('.')
    // format whole numbers
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator)
    // put them back together
    value = parts[1] ? parts.join('.') : parts[0]

    return value
}

export function handleURL(url) {
    if (url === undefined || url === '' || url === null) return ''

    url = url.replace('http://', 'https://')

    // check if there is https:// included
    if (url.includes('https://')) {
        return url
    } else {
        return `https://${url}`
    }
}

export function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export function normalizeString(input) {
    if (input) {
        return input
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
    }

    return input
}

export function isValidTaxRate(taxRate) {
    if (isNaN(parseFloat(taxRate)) || taxRate === '' || parseFloat(taxRate) < 0) return false

    return true
}

export function calculateTaxRate(priceWithoutTax, taxAmount) {
    if (priceWithoutTax <= 0) {
        return 0
    }

    // Calculate the tax rate
    const taxRate = (taxAmount / priceWithoutTax) * 100

    return parseFloat(taxRate.toFixed(2))
}

export function fullAddress(address) {
    if (!address) return ''

    const street = address?.street || ''
    const num = address?.number ? address?.number : ''
    const or_num = address?.orientation_number ? address?.orientation_number : ''

    return street + ' ' + (num + (or_num ? '/' + or_num : ''))
}

export function getCountryCodesAlpha3() {
    return {
        AFG: 'AF',
        ALB: 'AL',
        DZA: 'DZ',
        ASM: 'AS',
        AND: 'AD',
        AGO: 'AO',
        AIA: 'AI',
        ATA: 'AQ',
        ATG: 'AG',
        ARG: 'AR',
        ARM: 'AM',
        ABW: 'AW',
        AUS: 'AU',
        AUT: 'AT',
        AZE: 'AZ',
        BHS: 'BS',
        BHR: 'BH',
        BGD: 'BD',
        BRB: 'BB',
        BLR: 'BY',
        BEL: 'BE',
        BLZ: 'BZ',
        BEN: 'BJ',
        BMU: 'BM',
        BTN: 'BT',
        BOL: 'BO',
        BES: 'BQ',
        BIH: 'BA',
        BWA: 'BW',
        BVT: 'BV',
        BRA: 'BR',
        IOT: 'IO',
        BRN: 'BN',
        BGR: 'BG',
        BFA: 'BF',
        BDI: 'BI',
        CPV: 'CV',
        KHM: 'KH',
        CMR: 'CM',
        CAN: 'CA',
        CYM: 'KY',
        CAF: 'CF',
        TCD: 'TD',
        CHL: 'CL',
        CHN: 'CN',
        CXR: 'CX',
        CCK: 'CC',
        COL: 'CO',
        COM: 'KM',
        COG: 'CG',
        COD: 'CD',
        COK: 'CK',
        CRI: 'CR',
        CIV: 'CI',
        HRV: 'HR',
        CUB: 'CU',
        CUW: 'CW',
        CYP: 'CY',
        CZE: 'CZ',
        DNK: 'DK',
        DJI: 'DJ',
        DMA: 'DM',
        DOM: 'DO',
        ECU: 'EC',
        EGY: 'EG',
        SLV: 'SV',
        GNQ: 'GQ',
        ERI: 'ER',
        EST: 'EE',
        SWZ: 'SZ',
        ETH: 'ET',
        FLK: 'FK',
        FRO: 'FO',
        FJI: 'FJ',
        FIN: 'FI',
        FRA: 'FR',
        GUF: 'GF',
        PYF: 'PF',
        ATF: 'TF',
        GAB: 'GA',
        GMB: 'GM',
        GEO: 'GE',
        DEU: 'DE',
        GHA: 'GH',
        GIB: 'GI',
        GRC: 'GR',
        GRL: 'GL',
        GRD: 'GD',
        GLP: 'GP',
        GUM: 'GU',
        GTM: 'GT',
        GGY: 'GG',
        GIN: 'GN',
        GNB: 'GW',
        GUY: 'GY',
        HTI: 'HT',
        HMD: 'HM',
        HND: 'HN',
        HKG: 'HK',
        HUN: 'HU',
        ISL: 'IS',
        IND: 'IN',
        IDN: 'ID',
        IRN: 'IR',
        IRQ: 'IQ',
        IRL: 'IE',
        IMN: 'IM',
        ISR: 'IL',
        ITA: 'IT',
        JAM: 'JM',
        JPN: 'JP',
        JEY: 'JE',
        JOR: 'JO',
        KAZ: 'KZ',
        KEN: 'KE',
        KIR: 'KI',
        PRK: 'KP',
        KOR: 'KR',
        KWT: 'KW',
        KGZ: 'KG',
        LAO: 'LA',
        LVA: 'LV',
        LBN: 'LB',
        LSO: 'LS',
        LBR: 'LR',
        LBY: 'LY',
        LIE: 'LI',
        LTU: 'LT',
        LUX: 'LU',
        MAC: 'MO',
        MDG: 'MG',
        MWI: 'MW',
        MYS: 'MY',
        MDV: 'MV',
        MLI: 'ML',
        MLT: 'MT',
        MHL: 'MH',
        MTQ: 'MQ',
        MRT: 'MR',
        MUS: 'MU',
        MYT: 'YT',
        MEX: 'MX',
        FSM: 'FM',
        MDA: 'MD',
        MCO: 'MC',
        MNG: 'MN',
        MNE: 'ME',
        MSR: 'MS',
        MAR: 'MA',
        MOZ: 'MZ',
        MMR: 'MM',
        NAM: 'NA',
        NRU: 'NR',
        NPL: 'NP',
        NLD: 'NL',
        NCL: 'NC',
        NZL: 'NZ',
        NIC: 'NI',
        NER: 'NE',
        NGA: 'NG',
        NIU: 'NU',
        NFK: 'NF',
        MKD: 'MK',
        MNP: 'MP',
        NOR: 'NO',
        OMN: 'OM',
        PAK: 'PK',
        PLW: 'PW',
        PSE: 'PS',
        PAN: 'PA',
        PNG: 'PG',
        PRY: 'PY',
        PER: 'PE',
        PHL: 'PH',
        PCN: 'PN',
        POL: 'PL',
        PRT: 'PT',
        PRI: 'PR',
        QAT: 'QA',
        REU: 'RE',
        ROU: 'RO',
        RUS: 'RU',
        RWA: 'RW',
        BLM: 'BL',
        SHN: 'SH',
        KNA: 'KN',
        LCA: 'LC',
        MAF: 'MF',
        SPM: 'PM',
        VCT: 'VC',
        WSM: 'WS',
        SMR: 'SM',
        STP: 'ST',
        SAU: 'SA',
        SEN: 'SN',
        SRB: 'RS',
        SYC: 'SC',
        SLE: 'SL',
        SGP: 'SG',
        SXM: 'SX',
        SVK: 'SK',
        SVN: 'SI',
        SLB: 'SB',
        SOM: 'SO',
        ZAF: 'ZA',
        SGS: 'GS',
        SSD: 'SS',
        ESP: 'ES',
        LKA: 'LK',
        SDN: 'SD',
        SUR: 'SR',
        SJM: 'SJ',
        SWE: 'SE',
        CHE: 'CH',
        SYR: 'SY',
        TWN: 'TW',
        TJK: 'TJ',
        TZA: 'TZ',
        THA: 'TH',
        TLS: 'TL',
        TGO: 'TG',
        TKL: 'TK',
        TON: 'TO',
        TTO: 'TT',
        TUN: 'TN',
        TUR: 'TR',
        TKM: 'TM',
        TCA: 'TC',
        TUV: 'TV',
        UGA: 'UG',
        UKR: 'UA',
        ARE: 'AE',
        GBR: 'GB',
        USA: 'US',
        UMI: 'UM',
        URY: 'UY',
        UZB: 'UZ',
        VUT: 'VU',
        VEN: 'VE',
        VNM: 'VN',
        VGB: 'VG',
        VIR: 'VI',
        WLF: 'WF',
        ESH: 'EH',
        YEM: 'YE',
        ZMB: 'ZM',
        ZWE: 'ZW',
    }
}
