import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import { Form, Divider, Message } from 'semantic-ui-react'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const RemoveCorrectedRecord = ({ day, contract, onClose, record, planRecord, setContracts, setAttendanceRecords }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const handleSubmit = async () => {
        setProcessing(true)

        const request = await requests.del(API.ATTENDANCE_BASE + 'records/' + record.id + '/')
        if (request.status === 204) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('correct_record_has_been_removed'),
                animation: 'pulse',
                time: 2000,
            })
            setAttendanceRecords((prev) => prev.filter((item) => item.id !== record.id))
            onClose()
        } else {
            toast({
                type: 'error',
                icon: 'warning',
                title: t('corrected_record_can_not_be_removed'),
                animation: 'pulse',
                time: 5000,
            })
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message visible info>
                <strong>
                    {t(
                        'record_will_be_removed_but_if_any_hours_were_moved_to_bank_it_will_persist_perform_additional_action_to_remove_hours_from_the_bank'
                    )}
                </strong>
            </Message>

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit loading={processing} disabled={processing} />
            </Form.Field>
        </Form>
    )
}

export default RemoveCorrectedRecord
