import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
// import { API } from '@store/config';
// import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import EmptyRow from '@components/tables/EmptyRow'; 
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import { Form, Divider, Popup, Segment, Button } from 'semantic-ui-react';

const TasksForm = ({ index, stage, onClose, setStages }) => {
    const { t } = useTranslation();

    const [isProcessing, setIsProcessing] = useState(false)
    const [tasks, setTasks] = useState(stage?.requirements?.length > 0 ? stage?.requirements?.map((item) => ({
        id: item.id,
        task: item.task,
        description: item.description,
        is_required: item.is_required,
        is_deleted: item?.is_deleted || false
    })) : [])

    const onItemChange = (index, updatedKey, value) => {
        setTasks(prevState => prevState.filter((record, key) => {
            if( index === key ){
                record[updatedKey] = value
            }

            return record
        }))
    }

    const handleSubmit = async () => {
        setIsProcessing(true);
        setStages(prev => prev.filter((record, idx) => {
            if( idx === index ){
                record['requirements'] = tasks.filter(item => item.task !== "")
            }
            return record
        }))

        setIsProcessing(false);
        onClose()
    }

    const onRemove = async (item, index) => {
        if (item === 0) { // remove from list
            setTasks(prev => prev.filter((task, idx) => idx !== index))
        } else {
            setTasks(prev => prev.filter((task, idx) => {
                if (idx === index) {
                    task.is_deleted = true
                }
                return task
            }));
        }

    }

    const addTask = () => {
        setTasks(prev => [...prev, {
            id: 0,
            task: "",
            description: "",
            is_required: false,
            is_deleted: false,
        }])
    }

    return (
        <Form onSubmit={(e) => e.preventDefault()}>

            <Form.Group unstackable style={{ paddingLeft: "1rem"}}>
                <Form.Field width={4} required style={{ fontWeight: "bold" }} content={t('title')} />
                <Form.Field width={7} required style={{ fontWeight: "bold" }} content={t('description')} />
                <Form.Field width={2} style={{ fontWeight: "bold" }} content={t('required')} />
                <Form.Field width={2} style={{ fontWeight: "bold" }} content={t('actions')} />
            </Form.Group>
            
            <Segment>
                <EmptyRow length={tasks?.length || 0}/>
                { tasks?.filter(item => item.is_deleted === false)?.map((task, idx) => (
                    <Form.Group style={{ paddingTop: 0, paddingBottom: 0 }} key={idx}>
                        <SuperField as="input"
                            width={4}
                            size="small"
                            value={task.task}
                            onChange={ (e, { value }) => onItemChange(idx, "task", value)}
                        />
                        <SuperField as="input"
                            width={7}
                            size="small"
                            value={task.description}
                            onChange={ (e, { value }) => onItemChange(idx, "description", value)}
                        />
                        <Form.Field size="small" width={2} style={{ textAlign: "center", marginTop: "1rem" }}>
                            <SuperField as="checkbox"
                                checked={task.is_required}
                                onChange={ () => onItemChange(idx, "is_required", !task.is_required)}
                            />
                        </Form.Field>
                        <Form.Field width={2} style={{ textAlign: "center", marginTop: "0.8rem" }}>
                            <Popup
                                basic
                                position="top center"
                                trigger={
                                    <Icon name="close-outline" onClick={ () => onRemove(task.id, idx) } style={{ color: "var(--danger)", cursor: "pointer" }}/>
                                }
                                content={t('remove_task')}
                            />
                        </Form.Field>
                    </Form.Group>
                ))}

                <Button type="button" primary onClick={() => addTask()}>
                    { t('add_task') }
                </Button>
            </Segment>
            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <Button primary content={t('confirm')} type="button" loading={isProcessing} disabled={isProcessing} onClick={() => handleSubmit()}/>
            </Form.Field>
            
        </Form>
    );
};

export default TasksForm;