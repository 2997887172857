import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { renderShortLongAddress } from '@helpers/functions'
// components
import ListView from '@components/ListView';
import CanView from '@components/perms/CanView';
import { Form, Divider } from 'semantic-ui-react';
import PhoneField from '@components/forms/common/PhoneField';
import EmailField from '@components/forms/common/EmailField';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import AddressFieldGroup from '@components/forms/common/AddressFieldGroup';
import ContactCell from '../../../hr/candidates/components/tables/ContactCell';

const SubmissionOffices = () => {
    const { t } = useTranslation()

    return (
        <CanView permissions={['common.c_view_all_candidates' || 'common.c_view_all_employees']} redirect>
            <div style={{ marginTop: "1rem" }}></div>
            <ListView as="table"
                allowSearch
                isSoftDeleteAllowed
                endpoint={API.SUBMISSION_OFFICES}
                tableHeaders={[
                    { title: t('title'), orderBy: "title" },
                    { title: t('contact') },
                    { title: t('address') },
                ]}
                renderCells={(item) => ([
                    { content: item?.title },
                    { content: <ContactCell row={{ phone: item?.phone, email: item?.email}} />},
                    { content: renderShortLongAddress(item?.address) },
                ])}
                actions={[
                    {
                        as: "modal",
                        type: "add",
                        modalSize: "tiny",
                        name: t('create'),
                        modal: <SubmissionOfficesForm/>,
                        permissions: ['common.c_manage_all_candidates' || 'common.c_manage_all_employees'],
                    },
                    {
                        as: "modal",
                        type: "edit",
                        name: t('edit'),
                        modalSize: "tiny",
                        permissions: ['common.c_manage_all_candidates' || 'common.c_manage_all_employees'],
                        modal: (item, setData, setTotal) => <SubmissionOfficesForm item={item} setData={setData} setTotal={setTotal} />
                    },
                    {
                        as: "delete",
                        name: t('delete'),
                        type: "delete",
                        text: t('delete'),
                        permissions: ['common.c_manage_all_candidates' || 'common.c_manage_all_employees'],
                    },
                ]}
            />
        </CanView>
    );
};

const SubmissionOfficesForm = ({item, setData, setTotal, onClose}) => {
    const { t } = useTranslation()
    
    const [isProcessing, setIsProcessing] = useState(false)
    const [itemAddress, setItemAddress] = useState(item?.address || {
        street: '',
        city: '',
        postcode: '',
        country: '',
        state: '',
        addressLine: '',
    });
    const [formData, setFormData] = useState({
        title: item?.title || "",
        phone: item?.phone || "",
        email: item?.email || "",
        address: itemAddress || {}
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        const resultAddress = await requests.post(API.ADDRESSES, itemAddress);

        if( item?.id ){ 
            const request = await requests.patch(API.SUBMISSION_OFFICES + item.id + "/", {
                title: formData.title,
                phone: formData.phone,
                email: formData.email,
                address: resultAddress?.response?.id || {}
            })
            if( request.status === 200 ){
                setData(prev => prev.filter(office => {
                    if( office.id === item.id ){
                        office.title = request.response.title;
                        office.phone = request.response.phone;
                        office.email = request.response.email;
                        office.address = request.response.address;
                    }
                    
                    return office;
                }))

                onClose()
            }
        } else {
            const request = await requests.post(API.SUBMISSION_OFFICES, {
                title: formData.title,
                phone: formData.phone,
                email: formData.email,
                address: resultAddress?.response?.id || {}
            })
            if(request.status === 201){
                setData(prev => [...prev, request.response])
                setTotal(count => count + 1)
                onClose()
            }
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="input"
                required
                autoFocus
                label={t('title')}
                value={formData.title}
                onChange={ (e, { value }) => setFormData({
                    ...formData,
                    title: value
                }) }
            />
            <Form.Group widths="equal">
                <PhoneField
                    hideType
                    placeholder={t('enter_number')}
                    value={formData.phone}
                    setValue={(e, { value }) => setFormData({
                        ...formData,
                        phone: value.replaceAll(" ", "")
                    })}
                />
                <EmailField
                    label={t('email')}
                    placeholder={t('enter_email')}
                    value={formData.email}
                    setValue={(e, { value }) => setFormData({
                        ...formData,
                        email: value
                    })}
                />
            </Form.Group>

            <AddressFieldGroup address={itemAddress} setAddress={setItemAddress}/>

            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || formData.title === ""}
                    text={t('save')} />
            </Form.Field>
        </Form>
    );
};

export default SubmissionOffices;