import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// Components
import { Form, Message } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperField from '@components/forms/SuperField';

const AssignMemberToIssue = ({ id, projectID, data, fieldName, patchEndpoint, query, onClose, onConfirm }) => {
    query = query || ""

    // data are data .... in array format
    // patchEndpoint is object endpoint for example API.PROJECT
    // query is optional parameter in case you want to limit patchEndpoint results

    const { t } = useTranslation();
    const [assignee, setAssignee] = useState(data.map(item => item.id))
    const [errors, setErrors] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false)

    const onSubmit = async () => {
        setIsProcessing(true)
        const request = await requests.patch(patchEndpoint + id + "/" + (query ? ( `?query=${query}`): "") , {
            [fieldName]: {
                add: assignee
            }
        })

        if (request.status === 200) {
            onConfirm(id, request.response?.[fieldName])
            onClose()
        } else if( request.status === 400 ) {
            setErrors(request.response)
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={onSubmit}>
            <Message
                error
                visible={errors?.non_field_errors?.length > 0}
                header={t("error_submission_message")}
                list={errors?.non_field_errors}
            />
            <SuperField as="choice"
                search
                multiple
                style={{marginTop: '4px'}}
                text="fullname"
                label={t('employee')}
                value={assignee}
                error={ errors === null ? false : errors?.profile }
                onChange={(e, { value }) =>
                    setAssignee( value )
                }
                endpoint={API.EMPLOYEES + `?project=${projectID}&only_basic_info_with_project=true`}
            />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    disabled={isProcessing}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default AssignMemberToIssue;