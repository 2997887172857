import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { tzDateTime } from '@helpers/dates'
// components
import Icon from '@components/Icon'
import ListView from '@components/ListView'

const FuelCardTransactions = ({ setHeader, record }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    useEffect(() => {
        setHeader(t('transactions') + ' - ' + record.card_number)
    }, [])

    return (
        <ListView
            as="table"
            isModal
            noContainer
            mBottom="0"
            forceReloadOnPagination={false}
            endpoint={API.INTEGRATIONS + 'webdispetching/car_costs/'}
            initialFilters={{
                search: record.card_number,
                ordering: ['-date'],
            }}
            tableHeaders={[
                { title: t('date') },
                { title: t('description') },
                { title: t('amount') },
                { title: t('total_price') },
                { title: t('card_number') },
                { title: t('OBU ID') },
                { title: t('status') + ' - ' + t('cost_center') },
            ]}
            renderCells={(transaction) => [
                { content: transaction.date ? tzDateTime(transaction.date).format(dateFormat + ' HH:mm') : '--' },
                { content: transaction.description },
                { content: transaction.amount },
                { content: transaction.price_total + ' ' + transaction.currency },
                { content: transaction.card_number },
                { content: transaction.obu_id },
                {
                    content: (
                        <>
                            {transaction.cost_center_record ? (
                                <Icon name="ellipse" style={{ color: 'var(--success)', marginRight: '0.5rem' }} />
                            ) : (
                                <Icon name="ellipse" style={{ color: 'var(--danger)', marginRight: '0.5rem' }} />
                            )}
                            <strong>
                                {transaction.cost_center_record ? t('assigned_to_cc') : t('unassigned_to_cc')}
                            </strong>
                        </>
                    ),
                },
            ]}
        />
    )
}

export default FuelCardTransactions
