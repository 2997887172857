import React, { useState }from 'react';
import { useTranslation } from "react-i18next";
// store 
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const TerminationForm = ({ record, setData, onClose }) => {
    const { t } = useTranslation();
    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState([])
    const [form, setForm] = useState({
        notice_period: record?.notice_period || "",
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        setErrors([])
        const request = await requests.patch(API.CONTRACTS_TERMINATIONS + record.id + "/", {
            ...form,
            notice_period: form.notice_period !== "" ? form.notice_period : null,
        })

        if (request.status === 400) setErrors(request.response)
        if( request.status === 200 ){
            setData(prev => prev.filter(item => {
                if(item.id === record.id){
                    item.notice_period = request.response.notice_period
                    item.is_archived = request.response.is_archived
                }

                return item
            }))
            onClose()
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    required
                    closable
                    label={ t('notice_period') }
                    value={form.notice_period}
                    error={errors?.notice_period?.[0] || false}
                    onChange={(e, { value }) => setForm({...form, notice_period: value})}
                />
            </Form.Group>
            <Divider/>
            <Form.Group style={{ marginBottom: 0 }}>
                <Form.Field width="8"/>
                <Form.Field width="8" style={{ textAlign: "right" }}>
                    <ModalCancel onClose={onClose} />
                    <ModalSubmit
                        loading={isProcessing}
                        disabled={isProcessing}
                        text={t('confirm')} />
                </Form.Field>
            </Form.Group>
        </Form>
    );
};

export default TerminationForm;