import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { routes } from '@routes'
import { isEmpty } from '@helpers/validation'
import { requests, fileDownload } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import Editor from '@monaco-editor/react'
import CanView from '@components/perms/CanView'
import SpinnerSegment from '@components/SpinnerSegment'
import SuperField from '@components/forms/SuperField'
import { Form, Grid, Divider, Container, Button, Label, Popup } from 'semantic-ui-react'
import BreadcrumbNav from '@components/BreadcrumbNav'
import Placeholders from '@components/editors/template-editor/Placeholders'

const DocumentForm = () => {
    const { t } = useTranslation()
    const { id } = useParams()

    const [loading, setLoading] = useState(true) // true, false

    const [isProcessing, setIsProcessing] = useState(false) // true, false

    // form formdata
    const [title, setTitle] = useState('')
    const [source, setSource] = useState('')
    const [content, setContent] = useState('')

    const [dataSource, setDataSource] = useState('')
    const [sourceEndpoint, setSourceEndpoint] = useState('')
    const [sourceFilters, setSourceFilters] = useState('')

    useEffect(() => {
        async function fetchDocument() {
            setLoading(true)
            const request = await requests.get(API.TEMPLATING + id + '/')
            if (request.status === 200) {
                let model = request.response.source

                setTitle(request.response.title)
                setContent(request.response.content)
                setSource(model)

                if (model === 'contracts.Contract') {
                    setSourceEndpoint(API.CONTRACTS)
                    setSourceFilters('&query={id,employee{fullname_with_titles}}&ordering=-id')
                }

                if (model === 'contracts.BusinessContract') {
                    setSourceEndpoint(API.B2B_CONTRACTS + 'contracts/')
                    setSourceFilters('&query={id,title}&ordering=-id')
                }

                if (model === 'contracts.ContractTermination') {
                    setSourceEndpoint(API.CONTRACTS_TERMINATIONS)
                    setSourceFilters('&query={id,contract{employee{fullname_with_titles}}}&ordering=-id')
                }

                if (model === 'foreigners.ForeignerProcess') {
                    setSourceEndpoint(API.FOREIGNERS + 'processes/')
                    setSourceFilters('&query={id,profile{fullname_with_titles}}&ordering=-id')
                }

                if (model === 'payrolls.Payroll') {
                    setSourceEndpoint(API.PAYROLLS)
                    setSourceFilters('&query={id}&ordering=-id')
                }

                if (model === 'payrolls.BonusPayment') {
                    setSourceEndpoint(API.PAYROLLS + 'bonus_payments/')
                    setSourceFilters('&query={id}&ordering=-id')
                }

                if (model === 'payrolls.AdvancePayment') {
                    setSourceEndpoint(API.PAYROLLS + 'advance_payments/')
                    setSourceFilters('&query={id}&ordering=-id')
                }

                if (model === 'payrolls.DeductionPayment') {
                    setSourceEndpoint(API.PAYROLLS + 'deduction_payments/')
                    setSourceFilters('&query={id}&ordering=-id')
                }
            }
            setLoading(false)
        }

        fetchDocument()
    }, [id])

    const handleSubmit = async () => {
        setIsProcessing(true)
        const data = {
            title: title,
            content: content,
            is_xml: false,
            language: 'sk',
        }

        const requestSave = await requests.patch(API.TEMPLATING + id + '/', data)
        if (requestSave.status === 200) {
            if (dataSource) {
                const request = await requests.get(API.TEMPLATING + `${id}/fill/${source}/${dataSource}`)
                if (request.status === 200) {
                    toast({
                        type: 'success',
                        icon: 'check circle',
                        title: t('generating_document') + '...',
                        description: t('wait_a_moment_until_its_finished') + '.',
                        animation: 'bounce',
                        time: 5000,
                    })
                    await fileDownload('POST', API.EXPORTS_HTML_TO_PDF + '?q=', 'application/pdf', `${title}.pdf`, {
                        content: request.response.html,
                    })
                } else {
                    toast({
                        type: 'error',
                        icon: 'warning',
                        title: t('error_while_generating_template_check_template_definition'),
                        animation: 'pulse',
                        time: 5000,
                    })
                }
            } else {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('template_saved'),
                    animation: 'pulse',
                    time: 2000,
                })
            }
        }

        setIsProcessing(false)
    }

    const modelTerms = {
        Contract: t('contract'),
        BusinessContract: t('business_contract'),
        ContractTermination: t('termination'),
        ForeignerProcess: t('foreigners'),
        Payroll: t('payroll_records'),
        BonusPayment: t('bonuses_and_rewards'),
        DeductionPayment: t('deductions'),
        AdvancePayment: t('advances'),
    }

    const getSource = (fullSource) => {
        let splitSource = fullSource.split('.')

        return modelTerms[splitSource[1]]
    }

    const getTextDisplay = (item) => {
        if (source === 'contracts.Contract') {
            return '#' + item.id + ': ' + (item.employee.fullname_with_titles || '--')
        }

        if (source === 'contracts.BusinessContract') {
            return '#' + item.id + ': ' + (item.title || '--')
        }

        if (source === 'contracts.ContractTermination') {
            return '#' + item.id + ': ' + (item?.contract?.employee.fullname_with_titles || '--')
        }

        if (source === 'foreigners.ForeignerProcess') {
            return '#' + item.id + ': ' + (item.profile.fullname_with_titles || '--')
        }

        return 'Record #' + item.id
    }

    return (
        <CanView permissions={['templating.c_manage_documents']} redirect>
            <SpinnerSegment loading={loading}>
                <BreadcrumbNav
                    items={[
                        {
                            name: t('templates'),
                            icon: 'document-text-outline',
                            href: routes.SETTINGS + 'templates/',
                        },
                        { name: title, icon: 'document-text-outline', href: '' },
                    ]}
                />
                <Container fluid>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group widths="equal">
                            <SuperField
                                as="input"
                                autoFocus
                                required
                                label={t('title')}
                                placeholder={t('title')}
                                value={title}
                                onChange={(e, { value }) => setTitle(value)}
                            />
                            <Form.Field width="4">
                                <SuperField
                                    as="choice-select"
                                    help={t('select_document_source_to_generate_preview_hint')}
                                    value={dataSource}
                                    label={getSource(source)}
                                    endpoint={sourceEndpoint}
                                    additionalFilters={sourceFilters}
                                    text={(item) => getTextDisplay(item)}
                                    onChange={(e, { value }) => setDataSource(value)}
                                />
                            </Form.Field>
                            <Form.Field width="4">
                                <Button
                                    primary
                                    fluid
                                    style={{ marginTop: '1.7rem' }}
                                    loading={isProcessing}
                                    disabled={isProcessing || isEmpty(title) || isEmpty(source)}
                                    content={
                                        dataSource
                                            ? t('save') + ' ' + t('&') + ' ' + t('generate_document')
                                            : t('save_template')
                                    }
                                />
                            </Form.Field>
                        </Form.Group>

                        <Divider />

                        <Grid>
                            <Grid.Column width={13} style={{ padding: 0 }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: '1.3rem',
                                            marginBottom: '1rem',
                                            marginRight: '1rem',
                                        }}
                                    >
                                        {t('document_content')}
                                    </div>
                                    <div style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                                        <Popup
                                            position="top center"
                                            trigger={
                                                <Label style={{ color: 'white', background: 'var(--danger-hover)' }}>
                                                    <Icon
                                                        name="code-slash-outline"
                                                        style={{
                                                            position: 'relative',
                                                            top: '0.05rem',
                                                            marginRight: '0.25rem',
                                                        }}
                                                    />
                                                    {'HTML'}
                                                </Label>
                                            }
                                            content={t('input_format_HTML')}
                                        />
                                        <Popup
                                            position="top center"
                                            trigger={
                                                <Label style={{ color: 'white', background: 'var(--blue)' }}>
                                                    <Icon
                                                        name="document-text-outline"
                                                        style={{
                                                            position: 'relative',
                                                            top: '0.05rem',
                                                            marginRight: '0.25rem',
                                                        }}
                                                    />{' '}
                                                    {'PDF'}
                                                </Label>
                                            }
                                            content={t('output_format_PDF')}
                                        />
                                        <Popup
                                            position="top center"
                                            trigger={
                                                <Label basic>
                                                    <Icon
                                                        name="pricetag-outline"
                                                        style={{
                                                            position: 'relative',
                                                            top: '0.05rem',
                                                            marginRight: '0.25rem',
                                                        }}
                                                    />{' '}
                                                    {getSource(source)}
                                                </Label>
                                            }
                                            content={t('data_source')}
                                        />
                                    </div>
                                </div>
                                <div style={{ border: '1px solid var(--light)' }}>
                                    <Editor
                                        height="900px"
                                        language="html"
                                        theme="vs-light"
                                        value={content}
                                        options={{
                                            inlineSuggest: false,
                                            fontSize: '13px',
                                            formatOnType: true,
                                            autoClosingBrackets: true,
                                            minimap: { scale: 1 },
                                        }}
                                        onChange={(data) => {
                                            setContent(data)
                                        }}
                                    />
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <div
                                    style={{
                                        marginTop: '1.5rem',
                                        borderLeft: '1px solid var(--light)',
                                    }}
                                >
                                    <Placeholders key={source} model={source} />
                                </div>
                            </Grid.Column>
                        </Grid>
                    </Form>
                </Container>
            </SpinnerSegment>
        </CanView>
    )
}
export default DocumentForm
