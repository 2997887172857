import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import SuperField from '@components/forms/SuperField';
import { Segment, Button } from 'semantic-ui-react'

const RecruitersNewForm = ({ value, onChange, options, setOptions, recruiters, room }) => {
    const { t } = useTranslation();
    const [values, setValues] = useState(value ? value : [])
    const [process, setProcess] = useState({
        loading: false,
        working: false
    })
    const [newRecruiters, setNewRecruiters] = useState([])

    async function createNewLabel() {
        setProcess({ ...process, loading: true })
        const request = await requests.patch(API.HIRING_ROOMS + room.id + "/", {
            recruiters: { add: newRecruiters }
        })

        if (request.status === 200) {
            handleAddNewRecruiter(request.response.recruiters)
            setProcess({ loading: false, working: false })
        }else {
            setProcess({ loading: false, working: true })
        }
    }

    function handleChangeSelect(input) {
        onChange(input)
        setValues(input)
    }

    function handleAddNewRecruiter(input) {
        let optionsArray = []
        for (let i = 0; i < input?.length; i++) {
            if(!room.recruiters.some(item => item.id === input[i].id)){
                optionsArray.push({
                    key: input[i].id, text: input[i].fullname, value: input[i].id
                });
            }
        }

        onChange([...value, ...newRecruiters])
        setValues([...value, ...newRecruiters])
        setOptions([...options, ...optionsArray])
    }

    return (
        <Segment loading={process.loading}>
            {!process.working &&
                <>
                    <SuperField as="choice"
                        search
                        multiple
                        customOptions={options}
                        value={values}
                        key={values}
                        exclude={recruiters?.map(item => item.id)}
                        label={t('recruiters')}
                        onChange={(e, { value }) => handleChangeSelect(value)}
                    />
                    <Button
                        type="button"
                        icon="add"
                        content={t("add_recruiter_to_hr")}
                        onClick={() => setProcess({ ...process, working: true })}
                        style={{ backgroundColor: "var(--primary)", color: "white", marginBottom: '0.2rem' }} />
                </>
            }
            {process.working &&
                <>
                    <SuperField as="choice"
                        search
                        clearable
                        placeholder={t('room_recruiters')}
                        multiple
                        endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                        exclude={room.recruiters.map(item => item.id)}
                        text="fullname"
                        label={t('room_recruiters')}
                        value={newRecruiters}
                        onChange={(e, { value }) => setNewRecruiters(value)}
                    />
                    <div style={{ margin: '.2rem 0' }}>
                        <Button content={t("cancel")} onClick={() => setProcess({ ...process, working: false })} />
                        <Button
                            type="button"
                            icon="add"
                            content={t("add")}
                            onClick={() => createNewLabel()}
                            style={{ backgroundColor: "var(--primary)", color: "white" }} />
                    </div>
                </>
            }
        </Segment>
    )
}
export default RecruitersNewForm