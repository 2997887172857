import React from 'react';
import { Link } from 'react-router-dom';
// components
import Icon from '@components/Icon';
import { Card, Header } from 'semantic-ui-react';

const PlusCardButton = ({text, to,onClick, ...rest }) => {

    // template
    return (
        <Card
        
            as={ to ? Link : null }
            to={ to ? to : null } 
            onClick={ onClick ? onClick : null}    
            fluid
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 0,
                height: "100%",
                cursor: "pointer"
            }}
            {...rest}
        >
            <Card.Content style={{ textAlign: "center", flexGrow: 0 }}>
                <Icon
                    name="add-circle-outline"
                    style={{ width: "100%", fontSize: "4.5rem", color: "var(--primary)" }}
                />
                <Header as="h3" style={{ marginTop: "1rem" }}>
                    {text}
                </Header>
            </Card.Content>
        </Card>
    );

};

export default PlusCardButton;