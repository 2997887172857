import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
// store
import { API } from '@store/config'
import { getRequest } from '@services/ServiceCommon'
// components
import Loading from '@components/general/Loading'
import FormPreview from '../components/apply-forms/FormPreview'
import { FlexRow, FlexItem } from '@components/tables/FlexTable'
import { Container, Header, Image, Divider, Icon, Grid, Message } from 'semantic-ui-react'

const PublicApplyForm = () => {
    // eslint-disable-next-line
    const { t, i18n } = useTranslation()
    const params = useParams()
    const logoFromRedux = useSelector((state) => state.logo)
    const [isLoading, setIsLoading] = useState(true)
    const [submitted, setSubmitted] = useState(false)
    const [result, setResult] = useState({})
    const [selected, setSelected] = useState([])
    const [applyForm, setApplyForm] = useState({})

    useEffect(() => {
        let language = navigator?.language || 'en'
        if (language === 'en-us') language = 'en'
        i18n.changeLanguage(language)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        async function getData() {
            const request = await getRequest(API.PUBLICATIONS + params.id + '/apply/', false)

            if (request.status === 200) {
                setResult(request.response)
                setApplyForm(request.response?.application_form_config)
            }
            setIsLoading(false)
        }

        getData()
    }, [params.id])

    useEffect(() => {
        if (applyForm?.fields) {
            let fields = []
            for (let i = 0; i < applyForm.fields.length; i++) {
                fields.push(applyForm.fields[i].name)
            }
            setSelected(fields)
        }
    }, [applyForm])

    return (
        <Container>
            {isLoading ? (
                <Loading />
            ) : (
                <div style={{ marginTop: '2rem' }}>
                    <FlexRow padding="0">
                        <FlexItem>
                            <Header as="h1" style={{ marginBottom: 0 }}>
                                <span>
                                    <Icon name="briefcase" /> {result?.job_offer?.title}
                                </span>{' '}
                                <br />
                                <span>
                                    <small style={{ fontSize: '1.2rem' }}>
                                        {' '}
                                        {t('valid_until')}:{' '}
                                        {result?.valid_until ? moment(result?.valid_until).format('DD.MM.YYYY') : ''}{' '}
                                    </small>
                                </span>
                            </Header>
                        </FlexItem>
                        <FlexItem>
                            <Image floated="right" size="tiny" src={logoFromRedux} />
                        </FlexItem>
                    </FlexRow>

                    <Divider />

                    {!submitted ? (
                        moment(result.valid_until).isSameOrAfter(moment(moment().format('YYYY-MM-DD'))) ? (
                            <FormPreview
                                isTemplate={false}
                                selected={selected}
                                setSubmitted={setSubmitted}
                                applyForm={applyForm}
                                publication={params.id}
                                publicationData={result}
                            />
                        ) : (
                            <Header
                                as="h3"
                                style={{ textAlign: 'center' }}
                                content={t('this_offer_is_no_longer_active')}
                            />
                        )
                    ) : (
                        <Grid centered>
                            <Message positive style={{ width: '100%' }}>
                                <Message.Header>
                                    <Header as="h2">{t('reaction_registered')}</Header>
                                </Message.Header>
                                <p> {t('recruiters_check')} </p>
                            </Message>
                        </Grid>
                    )}
                </div>
            )}
        </Container>
    )
}

export default PublicApplyForm
