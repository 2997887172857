import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'

// routes
import { routes } from '../../store/routes/index'

// components
import { Button, Icon } from 'semantic-ui-react'

function Home() {
    const { t } = useTranslation()
    const history = useHistory()

    history.push(routes.DASHBOARD)

    return (
        <div className="center-element" style={styles}>
            <h1>{t('greetings')}</h1>
            <Link to={routes.LOGIN}>
                <Button primary size="huge">
                    <Icon name="sign in" />
                    {t('enter_dashboard')}
                </Button>
            </Link>
        </div>
    )
}

const styles = {
    textAlign: 'center',
}

export default Home
