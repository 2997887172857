import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useFetchData } from '@helpers/hooks'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { routes } from '@routes'
// components
import CanView from '@components/perms/CanView'
import BreadcrumbNav from '@components/BreadcrumbNav'
import Calendar from '../components/Calendar'
import { Container, Grid, Header, List } from 'semantic-ui-react'
import Loading from '@components/general/Loading'
import StatsHeader from '../components/StatsHeader'
import ListView from '@components/ListView'
import HeaderInfo from '../components/HeaderInfo'
import Action from '@components/general/Action'
import Icon from '@components/Icon'
import GuestsForm from '../components/GuestsForm'
import RoomForm from '../components/RoomForm'

const AccommodationDetail = () => {
    const { t } = useTranslation()
    const params = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingList, setIsLoadingList] = useState(true)
    const [isLoadingCalendar, setIsLoadingCalendar] = useState(false)

    const accommodationID = params.id
    const [accommodation] = useFetchData(API.ACCOMMODATIONS + params.id + '/')
    const [stats, setStats] = useState({})
    const [totalStats, setTotalStats] = useState({})
    const [guests, setGuests] = useState({})
    const [guestsFiltered, setGuestsFiltered] = useState({})
    const [rooms, setRooms] = useState([])
    const [roomsFiltered, setRoomsFiltered] = useState([])

    const [dateFrom, setDateFrom] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [dateTo, setDateTo] = useState(moment().endOf('month').format('YYYY-MM-DD'))
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))

    const [selectedDay, setSelectedDay] = useState('')
    const [selectedRoom, setSelectedRoom] = useState('')

    const breadcrumb_items = [
        { name: t('accommodation'), icon: '', href: routes.ACCOMMODATIONS },
        { name: accommodation?.data?.name, icon: 'file outline', href: '' },
    ]

    const findRoomNumber = (guestId, data) => {
        let roomNumber = null
        let roomID = null

        // eslint-disable-next-line
        data.rooms.map((room) => {
            // eslint-disable-next-line
            room.guests.map((guest) => {
                if (guest.profile.id === guestId) {
                    roomNumber = room.number
                    roomID = room.id
                }
            })
        })

        return [roomID, roomNumber]
    }

    async function fetchStats() {
        setIsLoadingCalendar(true)

        const result = await requests.get(
            API.REPORTS + `accommodations/stats/?date_from=${dateFrom}&date_to=${dateTo}&id=${accommodationID}`
        )
        if (result.status === 200) {
            setStats(result.response[0].vacant_bed_count)
            setTotalStats(result.response[0])

            const resultRooms = await requests.get(API.ACCOMMODATIONS + `rooms/?accommodation=${accommodationID}`)
            if (resultRooms.status === 200) {
                setRooms(resultRooms.response)
                setRoomsFiltered(resultRooms.response)
            }

            const guestsWithDates = []
            // eslint-disable-next-line
            result.response[0].guests &&
                // eslint-disable-next-line
                result.response[0].guests.map((guest) => {
                    const id = guest.profile.id
                    const name = guest.profile.fullname_with_titles
                    const dateFrom = guest.from_date
                    const dateTo = guest.to_date
                    const guestID = guest.id

                    const roomID = findRoomNumber(id, result.response[0])[0]
                    const roomName = findRoomNumber(id, result.response[0])[1]

                    const guestWithDate = { guestID, id, name, dateFrom, dateTo, roomID, roomName }
                    guestsWithDates.push(guestWithDate)
                })

            setGuests(guestsWithDates)
            setGuestsFiltered(guestsWithDates)
        }
        setIsLoadingList(false)
        setIsLoadingCalendar(false)
        setIsLoading(false)
    }

    useEffect(() => {
        fetchStats()
        // eslint-disable-next-line
    }, [dateFrom])

    async function fetchGuests() {
        setIsLoadingList(true)

        let filteredData = []
        if (guests.length > 0 && selectedDay) {
            filteredData = guests.filter((guest) => {
                return selectedDay >= guest.dateFrom && selectedDay <= guest.dateTo
            })
        } else if (guests.length > 0 && selectedDay === '') {
            filteredData = guests
        }
        if (guests.length > 0 && selectedRoom) {
            filteredData = filteredData.filter((guest) => {
                return selectedRoom === guest.roomID
            })
        }

        setGuestsFiltered(filteredData)
        setIsLoadingList(false)
    }

    useEffect(() => {
        if (guests.length > 0) {
            fetchGuests()
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        fetchGuests()
        // eslint-disable-next-line
    }, [selectedDay, guests, selectedRoom])

    useEffect(() => {
        setIsLoadingCalendar(true)
        setDateFrom(moment(date).startOf('month').format('YYYY-MM-DD'))
        setDateTo(moment(date).endOf('month').format('YYYY-MM-DD'))
        setIsLoadingCalendar(false)
    }, [date])

    const handleCellClick = (day) => {
        if (selectedDay === day.date) {
            setSelectedDay('')
            setRoomsFiltered(rooms)
        } else {
            setSelectedDay(day.date)
            const mergedRooms = day?.rooms?.map((item, index) => ({
                ...item,
                bed_count: rooms[index].bed_count || 0,
            }))
            setRoomsFiltered(mergedRooms)
        }
    }

    const handleRoomClick = (roomID) => {
        if (selectedRoom === roomID) {
            setSelectedRoom('')
        } else {
            setSelectedRoom(roomID)
        }
    }

    const stepBack = () => {
        setIsLoadingCalendar(true)

        setDate(moment(dateFrom).subtract(1, 'month'))
        setDate(moment(dateFrom).subtract(1, 'month'))

        setIsLoadingCalendar(false)
    }

    const stepNext = () => {
        setIsLoadingCalendar(true)

        setDate(moment(dateFrom).add(1, 'month'))
        setDate(moment(dateFrom).add(1, 'month'))

        setIsLoadingCalendar(false)
    }

    const onAddGuest = (response, setData, setTotal) => {
        setData((prev) => [
            {
                dateFrom: response.from_date,
                dateTo: response.to_date,
                name: response.profile.fullname_with_titles,
                roomName: response?.room?.number || null,
                guestID: response.id,
                id: response.profile.id,
            },
            ...prev,
        ])
        setTotal((prev) => prev + 1)
        setGuests((prev) => [
            {
                dateFrom: response.from_date,
                dateTo: response.to_date,
                name: response.profile.fullname_with_titles,
                roomName: response?.room?.number || null,
                guestID: response.id,
                id: response.profile.id,
            },
            ...prev,
        ])
        setStats((prev) =>
            prev.map((item) => {
                if (item.date >= response.from_date && item.date < response.to_date) {
                    item.occupied_bed_count += 1
                }
                return item
            })
        )
    }

    const onAddGuestPopup = (response) => {
        setGuests((prev) => [
            {
                dateFrom: response.from_date,
                dateTo: response.to_date,
                name: response.profile.fullname_with_titles,
                roomName: response?.room?.number || null,
                guestID: response.id,
                id: response.profile.id,
            },
            ...prev,
        ])
        setStats((prev) =>
            prev.map((item) => {
                if (item.date >= response.from_date && item.date < response.to_date) {
                    item.occupied_bed_count += 1
                }
                return item
            })
        )
    }

    async function onDelete(guest) {
        const result = await requests.del(API.ACCOMMODATIONS + 'guests/' + guest.guestID + '/')

        if (result.status === 204) {
            setGuestsFiltered(guestsFiltered.filter((item) => item.id !== guest.id))
        }
    }

    async function onDeleteRoom(room) {
        const result = await requests.del(API.ACCOMMODATIONS + 'rooms/' + room + '/')

        if (result.status === 204) {
            setRoomsFiltered(roomsFiltered.filter((item) => item.id !== room))
        }
    }

    return (
        <CanView permissions={['accommodations.c_view_accommodation']} redirect>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <BreadcrumbNav items={breadcrumb_items} />
                    <Container fluid>
                        <HeaderInfo accommodation={accommodation.data} />
                        <StatsHeader stats={totalStats} rooms={rooms.length} accommodation={accommodation.data} />
                        <Grid style={{ background: 'var(--white)' }}>
                            <Grid.Row columns={2} divided>
                                <Grid.Column width={4}>
                                    <Header content={t('rooms')} />
                                    <List divided selection>
                                        {roomsFiltered?.map((room) => (
                                            <>
                                                <List.Item
                                                    style={{
                                                        padding: '0.5rem',
                                                        backgroundColor:
                                                            selectedRoom === room.id ? 'var(--primary)' : 'transparent',
                                                        color:
                                                            selectedRoom === room.id ? 'var(--light)' : 'var(--black)',
                                                    }}
                                                >
                                                    <List.Content
                                                        floated="right"
                                                        size="tiny"
                                                        style={{
                                                            padding: 0,
                                                            paddingTop: '0.5rem',
                                                            margin: 0,
                                                            width: '10%',
                                                        }}
                                                    >
                                                        <Action
                                                            as="delete"
                                                            size="huge"
                                                            tooltip={t('delete')}
                                                            text={t('delete')}
                                                            onClick={() => onDeleteRoom(room.id)}
                                                        />
                                                    </List.Content>
                                                    <List.Content
                                                        floated="right"
                                                        style={{ padding: 0, margin: 0, width: '10%' }}
                                                    >
                                                        <Action
                                                            as="modal"
                                                            type="icon"
                                                            icon="pencil-outline"
                                                            iconColor={
                                                                selectedRoom === room.id
                                                                    ? 'var(--light)'
                                                                    : 'var(--primary)'
                                                            }
                                                            tooltip={t('edit')}
                                                            text={t('edit')}
                                                            modal={
                                                                <RoomForm
                                                                    data={room}
                                                                    accommodation={accommodation.data}
                                                                    accommodationID={accommodationID}
                                                                    setRooms={setRooms}
                                                                    setRoomsFiltered={setRoomsFiltered}
                                                                />
                                                            }
                                                        />
                                                    </List.Content>
                                                    <List.Content
                                                        floated="left"
                                                        onClick={() => handleRoomClick(room.id)}
                                                    >
                                                        <List.Header
                                                            style={{
                                                                color:
                                                                    selectedRoom === room.id
                                                                        ? 'var(--light)'
                                                                        : 'var(--black)',
                                                            }}
                                                        >
                                                            {t('room') + ' '}
                                                            {room.gender === 1 && (
                                                                <span>
                                                                    {
                                                                        <Icon
                                                                            name={'man'}
                                                                            color="blue"
                                                                            style={{ marginRight: '1rem' }}
                                                                        />
                                                                    }
                                                                </span>
                                                            )}
                                                            {room.gender === 2 && (
                                                                <span>
                                                                    {
                                                                        <Icon
                                                                            name={'woman'}
                                                                            color="red"
                                                                            style={{ marginRight: '1rem' }}
                                                                        />
                                                                    }
                                                                </span>
                                                            )}
                                                            {room.gender === 3 && (
                                                                <>
                                                                    <span>{<Icon name={'woman'} color="red" />}</span>
                                                                    <span>{<Icon name={'man'} color="blue" />}</span>
                                                                </>
                                                            )}
                                                        </List.Header>
                                                        <span>{room.number}</span>
                                                    </List.Content>
                                                    <List.Content onClick={() => handleRoomClick(room.id)}>
                                                        <List.Header
                                                            style={{
                                                                textAlign: 'center',
                                                                color:
                                                                    selectedRoom === room.id
                                                                        ? 'var(--light)'
                                                                        : 'var(--black)',
                                                            }}
                                                        >
                                                            {t('free') + ' / ' + t('total')}
                                                        </List.Header>
                                                        <div style={{ textAlign: 'center' }}>
                                                            {(room.free_beds || '-') + ' / ' + (room.bed_count || '-')}
                                                        </div>
                                                    </List.Content>
                                                </List.Item>
                                            </>
                                        ))}
                                    </List>
                                    <Action
                                        as="modal"
                                        closeOnDimmerClick={false}
                                        type="addButton"
                                        modalSize="tiny"
                                        size="tiny"
                                        buttonText={t('add_room')}
                                        modal={
                                            <RoomForm
                                                accommodationID={accommodationID}
                                                setRooms={setRooms}
                                                setRoomsFiltered={setRoomsFiltered}
                                            />
                                        }
                                    />
                                </Grid.Column>
                                <Grid.Column width={12}>
                                    {isLoadingCalendar ? (
                                        <Loading />
                                    ) : (
                                        <Calendar
                                            stats={stats}
                                            handleCellClick={(date) => handleCellClick(date)}
                                            selectedDay={selectedDay}
                                            stepBack={() => stepBack()}
                                            stepNext={() => stepNext()}
                                            date={date}
                                            setDate={setDate}
                                            isLoading={isLoadingCalendar}
                                            setIsLoading={setIsLoadingCalendar}
                                            accommodationID={accommodationID}
                                            accommodation={accommodation}
                                            currency={accommodation.data.currency}
                                            onAddGuest={onAddGuestPopup}
                                        />
                                    )}
                                    {isLoadingList ? (
                                        <Loading />
                                    ) : (
                                        <div>
                                            {guestsFiltered && !isLoadingList && (
                                                <ListView
                                                    as="table"
                                                    title={
                                                        !selectedDay
                                                            ? t('guests')
                                                            : t('guests') +
                                                              ' - ' +
                                                              moment(selectedDay).format('DD.MM.YYYY')
                                                    }
                                                    key={guestsFiltered}
                                                    externalData={guestsFiltered}
                                                    tableHeaders={[
                                                        { title: t('name'), orderBy: 'name' },
                                                        { title: t('date') },
                                                        { title: t('room') },
                                                    ]}
                                                    actions={[
                                                        {
                                                            as: 'modal',
                                                            type: 'add',
                                                            name: t('add_guest'),
                                                            modalSize: 'small',
                                                            permissions: ['orders.c_manage_offer'],
                                                            modal: (
                                                                <GuestsForm
                                                                    accommodationID={accommodationID}
                                                                    accommodation={accommodation}
                                                                    selectedDay={selectedDay}
                                                                    currency={accommodation.data.currency}
                                                                    handleRequest={(response, setData, setTotal) =>
                                                                        onAddGuest(response, setData, setTotal)
                                                                    }
                                                                />
                                                            ),
                                                        },
                                                        {
                                                            as: 'custom',
                                                            type: 'icon',
                                                            tooltip: t('delete'),
                                                            icon: 'close-outline',
                                                            customIconColor: 'var(--danger)',
                                                            onClick: (item) => onDelete(item),
                                                            permissions: ['accommodations.c_manage_accommodation'],
                                                        },
                                                    ]}
                                                    renderCells={(guest) => [
                                                        { content: guest.name },
                                                        {
                                                            content:
                                                                moment(guest.dateFrom).format('DD.MM.YYYY') +
                                                                ' - ' +
                                                                moment(guest.dateTo).format('DD.MM.YYYY'),
                                                        },
                                                        { content: guest.roomName },
                                                    ]}
                                                />
                                            )}
                                        </div>
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </>
            )}
        </CanView>
    )
}

export default AccommodationDetail
