import userReducer from './user'
import logoReducer from './logo'
import journalReducer from './journal'
import companyReducer from './company'
import invoiceCurrencyReducer from './invoice_currency'
import invoicePairingStatusReducer from './invoice_pairing_status'
import prefSwitchToCandidate from './pref_switch_to_candidate'
import currentBankAccountReducer from './current_bank_account'
import themeReducer from './theme'
import choicesReducer from './choices'
import languageReducer from './language'
import autoTimesheetApprovalReducer from './auto_timesheet_approval'
import permissionReducer from './permissions'
import urlReducer from './settings_return_url'
import allowedModulesReducer from './allowed_modules'
import filtersReducer from './filters'
import globalPrefReducer from './global_preferences'
import { combineReducers } from 'redux'
import dateFormatReducer from './date'
import timezoneFormatReducer from './timezone'
import preferrableWidgetReducer from './preferrable_widget'
import allowJumpOverStagesReducer from './allow_jump_over_stages'
import allowEmailNotificationsReducer from './allow_email_notifications'
import allowQRScanner from './allow_qr_scanner'
import allowedOrderTypesReducer from './allowed_order_types'
import defaultOrderTypesReducer from './default_order_types'
import modulePreferencesReducer from './module_preferences'
import allowSequentialNumberingLogisticRecordReducer from './allow_sequential_numbering_logistic_record'
import toggledSidebarReducer from './toggled_sidebar'
import warehousingConfigReducer from './warehousing_config'
import trackerReducer from './tracker'

const allReducers = combineReducers({
    user: userReducer,
    choices: choicesReducer,
    global_pref: globalPrefReducer,
    logo: logoReducer,
    language: languageReducer,
    allowed_modules: allowedModulesReducer,
    filters: filtersReducer,
    permissions: permissionReducer,
    settings_return_url: urlReducer,
    company: companyReducer,
    journal: journalReducer,
    invoice_currency: invoiceCurrencyReducer,
    pairing_status: invoicePairingStatusReducer,
    date_format: dateFormatReducer,
    theme: themeReducer,
    bank_account: currentBankAccountReducer,
    switch_to_candidate: prefSwitchToCandidate,
    automatic_timesheet_approval: autoTimesheetApprovalReducer,
    timezone: timezoneFormatReducer,
    widget: preferrableWidgetReducer,
    allow_jump_over_stages: allowJumpOverStagesReducer,
    allow_email_notifications: allowEmailNotificationsReducer,
    allow_qr_scanner: allowQRScanner,
    allowed_order_types: allowedOrderTypesReducer,
    default_order_types: defaultOrderTypesReducer,
    module_preferences: modulePreferencesReducer,
    toggled_sidebar: toggledSidebarReducer,
    allow_sequential_numbering_logistic_record: allowSequentialNumberingLogisticRecordReducer,
    warehousing_config: warehousingConfigReducer,
    tracker: trackerReducer,
})

export default allReducers
