import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
// store
import { isEmpty } from '@services/helpers/validation';
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Form, Message, Divider, Header, Button } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import SuperField from '@components/forms/SuperField';
import PhoneField from '@components/forms/common/PhoneField';
import EmailField from '@components/forms/common/EmailField';
import AddressFieldGroup from '@components/forms/common/AddressFieldGroup';

const BranchesForm = ({data, account, onConfirm, setData, setTotal, onClose}) => {
    const { t } = useTranslation();

    const [brancheAddress, setBrancheAddress] = useState(data?.address || {
            street: '',
            city: '',
            postcode: '',
            country: '',
            state: '',
            addressLine: '',
        }
    );
    
    const [branche, setBranche] = useState({
        id: data?.id || 0,
        title: data?.title || "",
        email: data?.email || "",
        phone: data?.phone || "",
        contact_person_name: data?.contact_person_name || "",
        note: data?.note || "",
        address: brancheAddress || {},
        business_detail: account
    })

    const [isProcessing, setIsProcessing] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleSuccess = (response) => {
        onConfirm(response, setData, setTotal);
        onClose()
    }

    const handleErrors = (response) => {
        let errorsArray = [];
        if (response.non_field_errors) {
            errorsArray.push(response.non_field_errors)
        }
        setErrors(errorsArray);
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrors([]);
        setIsProcessing(true);

        const resultAddress = await requests.post(API.ADDRESSES, brancheAddress);

        const data = {
            title: branche.title,
            email: branche.email,
            phone: branche.phone,
            contact_person_name: branche.contact_person_name,
            note: branche.note,
            address: resultAddress.response.id || branche.address.id,
            business_detail: branche.business_detail
        }

        if (!branche.id || branche.id === 0) {
            const request = await requests.post(API.BRANCHES, data);

            if (request.status === 201) {
                handleSuccess(request.response)
            } else {
                handleErrors(request.response)
            }
        } else {
            const request = await requests.patch(API.BRANCHES + branche.id + '/', data);

            if (request.status === 200) {
                handleSuccess(request.response)
            } else {
                handleErrors(request.response)
            }
        }

        setIsProcessing(false);
    }

    return (
        <Form>
            <Message
                error
                visible={errors.length > 0}
                header={t("error_submission_message")}
                list={errors}
            />
            <SuperField as="input"
                required
                autoFocus
                label={t('title')}
                placeholder={t('title')}
                value={branche.title}
                onChange={(e) => setBranche({
                    ...branche,
                    title: e.target.value
                })}
            />
            <Form.Group widths="equal">
                <PhoneField
                    hideType
                    placeholder={t('enter_number')}
                    value={branche.phone}
                    setValue={(e, { value }) => setBranche({
                        ...branche,
                        phone: value.replaceAll(" ", "")
                    })}
                />
                <EmailField
                    label={t('email')}
                    placeholder={t('enter_email')}
                    value={branche.email}
                    setValue={(e, { value }) => setBranche({
                        ...branche,
                        email: value
                    })}
                />
            </Form.Group>
            <Form.Input
                label={t('contact_person_name')}
                placeholder={t('contact_person_name')}
                value={branche.contact_person_name}
                onChange={(e) => setBranche({
                    ...branche,
                    contact_person_name: e.target.value
                })}
            />
            <SuperField as="textarea"
                label={t('note')}
                value={branche.note}
                onChange={(e, { value }) => setBranche({
                    ...branche, 
                    note: value
                })}
            />

            <Divider />
            <Header as={'h4'} content={t('address')} style={{ marginTop: 0 }} />
            <AddressFieldGroup address={brancheAddress} setAddress={setBrancheAddress}/>
            <Divider />

            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <Button
                    primary
                    type="button"
                    content={t('save')}
                    onClick={(e) => handleSubmit(e)}
                    loading={isProcessing}
                    disabled={isProcessing || isEmpty(branche.title)}
                />
            </Form.Field>
        </Form>
    );
};

export default BranchesForm;