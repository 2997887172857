export const languageOptions = [
    { key: 1, text: 'English', value: 'en', flag: 'uk' },
    { key: 2, text: 'Slovak', value: 'sk', flag: 'sk' },
    { key: 3, text: 'Czech', value: 'cz', flag: 'cz' },
    { key: 4, text: 'Ukraine', value: 'ua', flag: 'ua' },
    { key: 5, text: 'Russian', value: 'ru', flag: 'ru' },
    // { key: 6, text: "Hungary", value: "hu", flag: "hu" },
    // { key: 7, text: "Persian", value: "fa", flag: "ir" },
    // { key: 8, text: "Arabic", value: "ar", flag: "ar" },
    // { key: 9, text: "Romanian", value: "ro", flag: "ro" },
    // { key: 10, text: "Serbian", value: "sr", flag: "sr" },
    { key: 11, text: 'Germany', value: 'de', flag: 'de' },
]
