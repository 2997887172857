import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import { Divider, Form, Message } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import NonFieldErrors from '@components/NonFieldErrors'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const AssignToHiringForm = ({ candidate, setData, onClose }) => {
    const { t } = useTranslation()
    const [room, setRoom] = useState('')
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)

    const handleSubmit = async () => {
        setErrors(null)
        setProcessing(true)

        // handle assign candidate to recruitment
        const request = await requests.post(API.CANDIDATES + candidate + '/assign_to_recruitment/', {
            recruitment: room,
        })

        if (request.status === 400) setErrors(request.response)
        if (request.status === 200) {
            // get candidate
            const requestCandidate = await requests.get(
                API.CANDIDATES +
                    candidate +
                    '/?query={id, skills, is_active, dts, source, fullname, profile_picture, cv, contact_labels, contacts, notes, hiring_rooms, in_active_recruitment}'
            )
            if (requestCandidate.status === 200) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('assigned_to_selected_hiring_room'),
                    animation: 'pulse',
                    time: 2000,
                })
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === candidate) {
                            item = requestCandidate.response
                        }
                        return item
                    })
                )
                onClose()
            }
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                visible
                info
                content={<span style={{ color: 'var(--dark)' }}>{t('hiring_room_empty_listing_hint')}</span>}
            />

            <NonFieldErrors errors={errors} />
            <SuperField
                as="choice-select"
                search
                text="name"
                label={t('hiring_room')}
                value={room}
                error={errors?.detail || false}
                onChange={(e, { value }) => setRoom(value)}
                endpoint={API.CANDIDATES + `recruitments/`}
                additionalFilters={`&query={id,name}&is_active=true&exclude_by_candidate=${candidate}`}
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit text={t('confirm')} loading={processing} disabled={processing || room === ''} />
            </Form.Field>
        </Form>
    )
}

export default AssignToHiringForm
