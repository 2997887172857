import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    :root{
        --default: ${props => props.theme.colors.default};
        --white: ${props => props.theme.colors.white};
        --black: ${props => props.theme.colors.black};
        --dark: ${props => props.theme.colors.dark};
        --light: ${props => props.theme.colors.light};
        
        --primary: ${props => props.theme.colors.primary};
        --info: ${props => props.theme.colors.info};
        --success: ${props => props.theme.colors.success};
        --warning: ${props => props.theme.colors.warning};     
        --danger: ${props => props.theme.colors.danger};      
        
        --blue: ${props => props.theme.colors.blue};
        --iceblue: ${props => props.theme.colors.iceblue};
        
        --dark-hover: #1a1a1a;
        /* --light-hover: #F2F4F6; */
        --light-grey: #eeeeee;
        --primary-hover: #552fe4;
        --info-hover: #1bafd1;
        --success-hover: #16c8b6;
        --warning-hover: #c9c313;
        --danger-hover: #e73863;  
        --border: #ccc;
        --radius: 0.0625rem;
        --radius-circled: 2rem;
        --body: --black;
        
        --variant1: #313131;
        --variant2: #6C757D;
        --variant3: #9F9F9F;
        --variant4: #C6C6C6;
        --variant5: #e7e7e7;
    }
`;
 
export default GlobalStyle;