import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { isEmpty } from "@helpers/validation";
import { requests } from '@helpers/requests';
// components
import CanView from '@components/perms/CanView';
import { Form, Header, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import NonFieldErrors from '@components/NonFieldErrors';
import ModalSubmit from '@components/buttons/ModalSubmit';
import ModalCancel from '@components/buttons/ModalCancel';

const VehicleForm = ({ record, onClose, setData, setTotal }) => {
    const { t } = useTranslation()
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)

    const [form, setForm] = useState({
        title: record?.title || "",
        description: record?.description || "",
        fuel_type: record?.fuel_type || null,
        vin: record?.vin || "",
        car_plate_number: record?.car_plate_number || "",
        type: record?.type?.id || "",
        owner: record?.owner?.id || "",
        item: record?.item?.id || ""
    })

    const handleSubmit = async (e) => {
        e.preventDefault()

        setProcessing(true)
        let request = undefined
        let data = form
        if (data.fuel_type === "") data.fuel_type = null
        if (data.owner === "") data.owner = null
        if (data.item === "") data.item = null
        if (data.type === "") data.type = null
        if (record?.id === undefined) { // create logic
            request = await requests.post(API.VEHICLES, data)
        } else { // update logic
            request = await requests.patch(API.VEHICLES + record?.id + "/", data)
        }

        if (request !== undefined){
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201){
                setTotal(prev => prev + 1)
                setData(prev => [request.response, ...prev])
                onClose()
            }
            if (request.status === 200) {
                setData(prev => prev.map(item => {
                    if (item.id === record?.id) {
                        item = request.response
                    }

                    return item
                }))
                onClose()
            }
        }
        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <NonFieldErrors errors={errors}/>
            <SuperField as="input"
                autoFocus
                required
                label={t('title')}
                value={form.title}
                error={errors?.title?.[0] || false}
                onChange={(e, { value }) => setForm({ ...form, title: value })}
            />

            <Form.Group widths="equal">
                <SuperField as="input"
                    required
                    label={t('car_plate_number')}
                    value={form.car_plate_number}
                    error={errors?.car_plate_number?.[0] || false}
                    onChange={(e, { value }) => setForm({ ...form, car_plate_number: value })}
                />
                <SuperField as="input"
                    required
                    label={t('vin')}
                    value={form.vin}
                    error={errors?.vin?.[0] || false}
                    onChange={(e, { value }) => setForm({ ...form, vin: value })}
                />
            </Form.Group>
            <SuperField as="choice-select"
                search
                label={t('owner')}
                value={form?.owner || ""}
                error={errors?.owner?.[0] || false}
                endpoint={API.BUSINESS_DETAIL}
                text={(item) => item.name + ' - ' + item.identification_number}
                additionalFilters={'&query={id, name, identification_number}&is_employer=true'}
                onChange={(e, { value }) => setForm({ ...form, owner: value })}
                initialOptions={{
                    attribute: "name",
                    source: record?.owner
                }}
            />

            <Header as="h3" content={t('additional_information')}/>
            <Divider/>
            <Form.Group widths="equal">
                <SuperField as="choice-select"
                    label={t('fuel_type')}
                    type="fuel_type"
                    value={form.fuel_type?.toString() || ""}
                    error={errors?.fuel_type?.[0] || false}
                    onChange={(e, { value }) => setForm({ ...form, fuel_type: value })}
                    initialOptions={{
                        attribute: "fuel_type",
                        as_display: true,
                        source: record
                    }}
                />
                <SuperField as="choice-select"
                    search
                    label={t('vehicle_type')}
                    endpoint={API.VEHICLES + "types/"}
                    settings="types/vehicle-types"
                    text="title"
                    value={form.type}
                    error={errors?.type?.[0] || false}
                    onChange={(e, { value }) => setForm({ ...form, type: value })}
                    initialOptions={{
                        attribute: "title",
                        source: record?.type
                    }}
                />
            </Form.Group>

            <SuperField as="textarea"
                label={t('description')}
                value={form.description}
                error={errors?.description?.[0] || false}
                onChange={(e, { value }) => setForm({ ...form, description: value })}
            />

            <CanView permissions={['assets.c_view_items']}>
                <Divider/>
                <SuperField as="choice-select"
                    search
                    text={(item) => item?.item?.title}
                    value={form.item}
                    error={errors?.item?.[0] || false}
                    label={t('connect_with_asset_vehicle')}
                    endpoint={API.ASSET_STORED_ITEMS}
                    additionalFilters={"&is_vehicle=true&query={id, item{id, title}}"}
                    onChange={(e, { value }) => setForm({ ...form, item: value })}
                    initialOptions={{
                        attribute: "title",
                        source: record?.item?.item
                    }}
                />
            </CanView>

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    disabled={processing || isEmpty(form.title) || isEmpty(form.vin) || isEmpty(form.car_plate_number)}
                    loading={processing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default VehicleForm;