import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { requests } from '@helpers/requests'
import { API } from '@store/config'
// components
import { Divider, Form } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const SubmissionBulk = ({ selected, setSelected, setData, onClose }) => {
    const { t } = useTranslation()
    
    const [isProcessing, setIsProcessing] = useState(false)
    const [form, setForm] = useState({
        place_of_submission: '',
        date_of_submission: ""
    })

    const onSubmit = async () => {
        setIsProcessing(true)
        let updated = []

        for(let i = 0; i < selected.length; i++){
            const request = await requests.patch(API.FOREIGNERS + "processes/" + selected[i].id + "/", {
                place_of_submission: form.place_of_submission ? form.place_of_submission : null,
                date_of_submission: form.date_of_submission ? form.date_of_submission : null
            })
            if (request.status === 200) {
                updated.push(request.response) 
            }
        }

        setData(prev => prev.filter(item => {
            const itemToUpdate = updated.find(updatedItem => updatedItem.id === item.id)
            if (item.id === itemToUpdate?.id) {
                item.place_of_submission = itemToUpdate.place_of_submission
                item.date_of_submission = itemToUpdate.date_of_submission
            }
            return item
        }))

        onClose()
        setSelected([])
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group widths="equal">
                <SuperField
                    search
                    as="datepicker"
                    label={t('date_of_submission')}
                    value={form.date_of_submission}
                    onChange={(e, { value }) => {
                        setForm(prev => ({ ...prev, date_of_submission: value }))
                    }}
                />
                <SuperField as="choice-select"
                    text="title"
                    label={t('place_of_submission')}
                    value={form.place_of_submission}
                    onChange={(e, { value }) => setForm(prev => ({ ...prev, place_of_submission: value }))}
                    endpoint={API.FOREIGNERS + 'submission_offices/'}
                />
            </Form.Group>
            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    disabled={isProcessing}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    )
}

export default SubmissionBulk