import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import { Button } from 'semantic-ui-react';

const ModalSubmit = ({text, disabled, loading, ...rest}) => {
    const { t } = useTranslation()

    /*
        ** text is used for text definition for button
        ** disabled is button disabled state (is not clickable on disabled = true)
        ** loadnig is button loadnig state (loading animaton will appier when loading = true)
    */
    return (
        <Button
            {...rest}
            primary
            disabled={disabled}
            loading={loading}
        >
            {text || t('confirm')}
        </Button>
    );
};

export default ModalSubmit;