import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { thousandsSeparators } from '@helpers/functions';
// components
import Icon from '@components/Icon';
import { Card, Divider, Popup } from 'semantic-ui-react';
import SpinnerSegment from '@components/SpinnerSegment';

const StatCard = ({ width, color, number, opacity, description, icon, onClick }) => {
    return (
        <Card style={{ borderRadius: 0, background: `${color}${opacity}`, borderBottom: `1px solid ${color}` }} onClick={onClick}>
            <Card.Content>
                <div style={{ display: "flex", marginBottom: "2rem" }}>
                    <div style={{ textAlign: "left", fontWeight: "bold", width: "calc(100% - 35px)", fontSize: "1rem", paddingTop: "0.25rem", color: "var(--dark)" }}>
                        { number }
                    </div>
                    <div style={{ textAlign: "right", width: "30px" }}>
                        <Icon name={icon} style={{ fontSize: "2rem", fontWeight: "bold", textAlign: "left", color: color }}/>
                    </div>
                </div>

                <div style={{ position: "absolute", bottom: "1rem", marginTop: "1rem", textTransform: "uppercase", color: "var(--dark)", opacity:"0.8" }}>
                    { description }
                </div>
            </Card.Content>
        </Card>
    )
}

const OrderOperationStats = ({ setFilters, data, fetchData, params, currency }) => {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(true)
    // eslint-disable-next-line
    const [stats, setStats] = useState({})

    useEffect(() => {
        async function fetchStats(){
            setLoading(true)
            const request = await requests.get(API.REPORTS + "salestage/stats/?query={*}" + params)
            
            if( request.status === 200 ){
                setStats(request.response)
            }
            setLoading(false)
        }

        fetchStats()
        // eslint-disable-next-line
    }, [data])

    const getValueByCurrency = (currency, list) => {
        return list.find(item => item.sum_currency === currency)?.sum || 0
    }

    const getTotals = (type) => {
        if( loading ) return 0

        // do we have specified currency?
        if (currency) { // if yes:
            let expected_profit = stats?.total_converted_profit || 0
            let reality_profit = stats?.total_expected_converted_profit || 0
            let expected_loss = stats?.total_converted_los || 0
            let reality_loss = stats?.total_expected_converted_los || 0

            if (type === "profit") {
                return (
                    <>
                        <Popup
                            position="top center"
                            trigger={
                                <span style={{ fontWeight: "normal" }}>{ thousandsSeparators(parseFloat(reality_profit).toFixed(2)) } /</span>
                            }
                            content={t('fulfilled')}
                        />
                        { " " } 
                        <Popup
                            position="top center"
                            trigger={
                                <span>
                                    { thousandsSeparators(parseFloat(expected_profit).toFixed(2)) + " " + currency }
                                </span>
                                
                            }
                            content={t('expected')}
                        />
                        { " " }
                        <span style={{ fontWeight: "normal" }}>
                            <small>
                                ({ (reality_profit > 0 && expected_profit > 0) ? parseFloat((reality_profit / expected_profit)*100).toFixed(2): "0" }%)
                            </small>
                        </span>
                    </>
                )
            }
            if (type === "loss") {
                return (
                    <>
                        <Popup
                            position="top center"
                            trigger={
                                <span style={{ fontWeight: "normal" }}>
                                    { thousandsSeparators(parseFloat(reality_loss).toFixed(2)) } /
                                </span> 
                            }
                            content={t('fulfilled')}
                        />
                        { " " }
                        <Popup
                            position="top center"
                            trigger={
                                <span>
                                    { thousandsSeparators(parseFloat(expected_loss).toFixed(2)) + " " + currency } 
                                </span>
                            }
                            content={t('expected')}
                        />
                        { " " }
                        <span style={{ fontWeight: "normal" }}>
                            <small>
                                ({ (reality_loss > 0 && expected_loss > 0) ? parseFloat((reality_loss / expected_loss)*100).toFixed(2): "0" }%)
                            </small>
                        </span>
                    </>
                )
            }
            if (type === "margin") {
                return (
                    <>
                        <Popup
                            position="top center"
                            trigger={
                                <span style={{ fontWeight: "normal" }}>
                                    { thousandsSeparators(parseFloat(reality_profit - reality_loss).toFixed(2)) } /
                                </span> 
                            }
                            content={t('fulfilled')}
                        />
                        { " " }
                        <Popup
                            position="top center"
                            trigger={
                                <span>
                                    { thousandsSeparators(parseFloat(expected_profit - expected_loss).toFixed(2)) + " " + currency } 
                                </span> 
                            }
                            content={t('expected')}
                        />
                        
                        { " " }
                        <span style={{ fontWeight: "normal" }}>
                            <small>
                                ({ ((reality_profit - reality_loss) > 0 && (expected_profit - expected_loss) > 0) ? parseFloat(((reality_profit - reality_loss) / (expected_profit - expected_loss))*100).toFixed(2): "0" }%)
                            </small>
                        </span>
                    </>
                )
            }
        } else { // if no:
            // find all currencies
            let currencies = []
            const real_profits = stats.total_profits
            const expected_profits = stats.total_expected_profits
            const real_losses = stats.total_losses
            const expected_losses = stats.total_expected_losses

            for (let i = 0; i < real_profits.length; i++) {
                if (!currencies.includes(real_profits[i].sum_currency)) {
                    currencies.push(real_profits[i].sum_currency)
                }
            }

            for (let i = 0; i < expected_profits.length; i++) {
                if (!currencies.includes(expected_profits[i].sum_currency)) {
                    currencies.push(expected_profits[i].sum_currency)
                }
            }

            for (let i = 0; i < real_losses.length; i++) {
                if (!currencies.includes(real_losses[i].sum_currency)) {
                    currencies.push(real_losses[i].sum_currency)
                }
            }

            for (let i = 0; i < expected_losses.length; i++) {
                if (!currencies.includes(expected_losses[i].sum_currency)) {
                    currencies.push(expected_losses[i].sum_currency)
                }
            }

            if (type === "profit") {
                return (
                    <div>
                        { currencies.length === 0 && "0" }

                        { currencies?.map((currency, index) => (
                            <div key={index}>
                                <Popup
                                    position="top center"
                                    trigger={
                                        <span style={{ fontWeight: "normal" }}>
                                            { thousandsSeparators(parseFloat(getValueByCurrency(currency, real_profits)).toFixed(2)) } /
                                        </span>
                                    }
                                    content={t('fulfilled')}
                                />
                                { " " }
                                <Popup
                                    position="top center"
                                    trigger={
                                        <span>
                                            { thousandsSeparators(parseFloat(getValueByCurrency(currency, expected_profits)).toFixed(2)) + " " + currency}
                                        </span>
                                    }
                                    content={t('expected')}
                                />
                                { " " }
                                <small style={{ fontWeight: "normal" }}>
                                    ({ (getValueByCurrency(currency, real_profits) > 0 && getValueByCurrency(currency, expected_profits) > 0)
                                        ? parseFloat((getValueByCurrency(currency, real_profits)/getValueByCurrency(currency, expected_profits))*100).toFixed(2)
                                        : "0" 
                                    } %)
                                </small>
                            </div>
                        )) }
                    </div>
                )
            }
            if (type === "loss") {
                return (
                    <div>
                        { currencies.length === 0 && "0" }

                        { currencies?.map((currency, index) => (
                            <div key={index}>
                                <Popup
                                    position="top center"
                                    trigger={
                                        <span style={{ fontWeight: "normal" }}>
                                            { thousandsSeparators(parseFloat(getValueByCurrency(currency, real_losses)).toFixed(2)) } /
                                        </span>
                                    }
                                    content={t('fulfilled')}
                                />
                                { " " }
                                <Popup
                                    position="top center"
                                    trigger={
                                        <span>
                                            { thousandsSeparators(parseFloat(getValueByCurrency(currency, expected_losses)).toFixed(2)) + " " + currency}
                                        </span>
                                    }
                                    content={t('expected')}
                                />
                                { " " }
                                <small style={{ fontWeight: "normal" }}>
                                    ({ (getValueByCurrency(currency, real_losses) > 0 && getValueByCurrency(currency, expected_losses) > 0)
                                        ? parseFloat((getValueByCurrency(currency, real_losses)/getValueByCurrency(currency, expected_losses))*100).toFixed(2)
                                        : "0" 
                                    } %)
                                </small>
                            </div>
                        )) }
                    </div>
                )
            }
            if (type === "margin") {
                return (
                    <div>
                        { currencies.length === 0 && "0" }

                        { currencies?.map((currency, index) => (
                            <div key={index}>
                                <Popup
                                    position="top center"
                                    trigger={
                                        <span style={{ fontWeight: "normal" }}>
                                            { thousandsSeparators(parseFloat(getValueByCurrency(currency, real_profits) - getValueByCurrency(currency, real_losses)).toFixed(2)) } /
                                        </span>
                                    }
                                    content={t('current_margin')}
                                />
                                { " " }
                                <Popup
                                    position="top center"
                                    trigger={
                                        <span>
                                            { thousandsSeparators(parseFloat(getValueByCurrency(currency, expected_profits) - getValueByCurrency(currency, expected_losses)).toFixed(2)) + " " + currency}
                                        </span>
                                    }
                                    content={t('expected_margin')}
                                />
                            </div>
                        )) }
                    </div>
                )
            }
        }
    }

    const renderOnClick = async (status) => {
        let isLoss = "" 
        if ( status === "profit" ) isLoss = false
        if ( status === "loss" ) isLoss = true
        setFilters(prev => ({...prev, is_purchase_type: isLoss}))
        await fetchData("&is_purchase_type=" + isLoss, true)
    }


    return (
        <SpinnerSegment loading={loading} loadingMessage={t('loading_statistics')}>
            <div style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}>
                <Card.Group stackable doubling itemsPerRow={4}>
                    <StatCard
                        color="#EEE722"
                        opacity="1A"
                        number={ stats?.total_records || 0 }
                        icon="list-outline"
                        description={t("total_records")}
                        onClick={() => renderOnClick("all")}
                    />
                    <StatCard
                        color="#fe0000"
                        opacity="1A"
                        number={ getTotals('loss')}
                        icon="trending-down-outline"
                        description={t("buy") + " - " + stats?.total_loss_orders || 0}
                        onClick={() => renderOnClick("loss")}
                    />
                    <StatCard
                        color="#008000"
                        opacity="1A"
                        number={ getTotals('profit') }
                        icon="trending-up-outline"
                        description={t("sell") + " - " + stats?.total_profit_orders || 0}
                        onClick={() => renderOnClick("profit")}
                    />
                    <StatCard
                        color="#31C6E8"
                        opacity="1A"
                        number={ getTotals('margin') }
                        icon="wallet-outline"
                        description={t("margin")}
                    />
                </Card.Group>
                <Divider/>
            </div>
        </SpinnerSegment>
    );
};

export default OrderOperationStats;