import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { handleURL } from '@helpers/functions'
import { useSelectOptions, useHasPermission } from '@helpers/hooks'
import { isEmailValid, isPhoneNumberValid } from '@helpers/validation'
// components
import Icon from '@components/Icon'
import CanView from '@components/perms/CanView'
import SuperField from '@components/forms/SuperField'
import ConfirmModal from '@components/modals/ConfrimModal'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Form, Divider, Header, Message, Button } from 'semantic-ui-react'
import PhoneField from '@components/forms/common/PhoneField'
import EmailField from '@components/forms/common/EmailField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import NonFieldErrors from '@components/NonFieldErrors'

const OpportunityForm = ({ isOpportunity, data, setData, setTotal, handleRequest, onClose }) => {
    const { t } = useTranslation()

    const canManageSources = useHasPermission('orders.c_manage_sale_source')

    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [sourceTypes, setSourceTypes] = useSelectOptions(API.ORDERS + 'sale_sources/?query={id, title}', 'title')
    const [metricValues, setMericValues] = useState([])

    const [form, setForm] = useState({
        id: data?.id || '',
        title: data?.title || '',
        firstname: data?.firstname || '',
        lastname: data?.lastname || '',
        company_id: data?.company_id || '',
        company_name: data?.company_name || '',
        number_of_employees: data?.number_of_employees || '',
        count_number_value: data?.count_number_value || '',
        count_number: data?.count_number || '',
        dit_number: data?.dit_number || '',
        vat_number: data?.vat_number || '',
        //email: data?.email || '',
        //phone: data?.phone || '',
        url: data?.url || '',
        source: data?.source?.id || '',
        assigned_to: data?.assigned_to?.map((item) => item.id) || [],
        description: data?.description || '',
        // note: data?.note || "",
        unit: data?.unit?.id || '',
        stage: data?.stage?.toString() || '1',
        state: data?.state?.toString() || '1',
    })

    const [contacts, setContacts] = useState(
        data?.contacts.map((item) => ({
            id: item.id,
            phone: item?.phone || '',
            email: item?.email || '',
            is_primary: item?.is_primary || false,
            is_invoicing: item?.is_invoicing || false,
            contact_person_name: item?.contact_person_name || '',
        })) || []
    )

    useEffect(() => {
        async function fetchMetricValues() {
            const request = await requests.get(API.ORDERS + 'lead_count_number_values/')
            if (request.status === 200) {
                setMericValues(request.response.filter((item) => item !== ''))
            }
        }

        fetchMetricValues()
    }, [])

    const isFormValid = () => {
        if (form.firstname !== '' || form.lastname !== '' || form.company_name !== '') return true

        return false
    }

    const handleAddItem = async (value) => {
        setSourceTypes({ ...sourceTypes, isLoading: true })
        const request = await requests.post(API.ORDERS + 'sale_sources/', {
            title: value,
        })

        if (request.status === 201) {
            setSourceTypes((prev) => {
                return {
                    isLoading: false,
                    options: [
                        {
                            key: request.response.id,
                            value: request.response.id,
                            text: request.response.title,
                        },
                        ...prev.options,
                    ],
                }
            })
            setForm((prev) => ({ ...prev, source: request.response.id }))
        }
    }

    const handleSubmit = async () => {
        setIsProcessing(true)
        setErrors(null)

        let assign = {
            remove: data?.assigned_to ? data.assigned_to.map((item) => item.id) : [],
            add: form.assigned_to,
        }

        if (data?.id === undefined) {
            delete assign.remove
        }

        let formData = {
            title: form.title,
            description: form.description,
            firstname: form.firstname,
            lastname: form.lastname,
            company_id: form.company_id,
            company_name: form.company_name,
            number_of_employees: form.number_of_employees,
            count_number_value: form.count_number_value,
            count_number: form.count_number > 0 || null,
            dit_number: form.dit_number,
            vat_number: form.vat_number,
            //email: form.email,
            //phone: form.phone,
            url: handleURL(form.url),
            unit: form.unit !== '' ? form.unit : null,
            stage: form.stage !== '' ? form.stage : null,
            state: form.state !== '' ? form.state : null,
            source: form.source,
            assigned_to: assign,
            // note: form.note,
        }

        // handle contacts
        let dataContacts = {
            toCreate: contacts.filter((item) => item.id === 0),
            toUpdate: contacts.filter((item) => item.id > 0),
        }

        if (dataContacts.toCreate.length === 0 && dataContacts.toUpdate.length === 0) {
            formData.contacts = null
        }

        formData.contacts = {}
        if (dataContacts.toCreate.length > 0) {
            formData.contacts['create'] = []
            const toCreate = dataContacts.toCreate
            for (let i = 0; i < toCreate.length; i++) {
                formData.contacts['create'].push({
                    type: 4, // account/supplier
                    phone: toCreate[i].phone,
                    email: toCreate[i].email,
                    is_invoicing: toCreate[i].is_invoicing,
                    contact_person_name: toCreate[i].contact_person_name,
                })
            }
        }

        if (dataContacts.toUpdate.length > 0) {
            const toUpdate = dataContacts.toUpdate
            formData.contacts['update'] = {}
            for (let i = 0; i < toUpdate.length; i++) {
                formData.contacts['update'][toUpdate[i].id] = {
                    type: 4, // account/supplier
                    phone: toUpdate[i].phone,
                    email: toUpdate[i].email,
                    is_invoicing: toUpdate[i].is_invoicing,
                    contact_person_name: toUpdate[i].contact_person_name,
                }
            }
        }

        if (!isOpportunity) {
            // formData['is_opportunity'] = false;

            if (form.assigned_to.length > 0 && parseInt(form.state) === 1) {
                formData['state'] = 2
            }

            if (form.assigned_to.length === 0 && parseInt(form.state) === 2) {
                formData['state'] = 1
            }
        } else {
            formData['is_opportunity'] = true
            formData['state'] = 5
        }

        let request = undefined

        if (!form.id || form.id === 0) {
            request = await requests.post(API.LEADS, formData)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                handleRequest(request.response, setData, setTotal)
                onClose()
            }
        } else {
            request = await requests.patch(API.LEADS + data.id + '/', formData)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                handleRequest(request.response, setData, setTotal)
                onClose()
            }
        }

        setIsProcessing(false)
    }

    const handleContactRemove = async (contact, idx) => {
        if (contact.id > 0) {
            // permanently remove
            const request = await requests.del(API.CONTACTS + 'records/' + contact.id + '/')
            if (request.status === 204) {
                setContacts((prev) => prev.filter((item) => item.id !== contact.id))
            }
        } else {
            // remove from view (local delete)
            setContacts((prev) => prev.filter((item, index) => index !== idx))
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            {data?.id !== undefined && (
                <>
                    <Header as="h3" content={t('update')} />
                    <Divider />
                </>
            )}
            <Message
                info
                visible
                content={
                    <strong>
                        <Icon
                            name="information-circle"
                            style={{ marginRight: '0.5rem', fontSize: '1.2rem', position: 'relative', top: '0.2rem' }}
                        />
                        {t('lead_form_hint')}.
                    </strong>
                }
            />
            <NonFieldErrors errors={errors} />
            <SuperField
                as="input"
                autoFocus
                label={t('position')}
                value={form.title}
                error={errors?.title?.[0]}
                onChange={(e, { value }) => setForm({ ...form, title: value })}
            />
            <Form.Group widths="equal">
                <SuperField
                    as="input"
                    label={t('firstname')}
                    value={form.firstname}
                    error={errors?.firstname?.[0]}
                    onChange={(e, { value }) => setForm({ ...form, firstname: value })}
                />
                <SuperField
                    as="input"
                    label={t('lastname')}
                    value={form.lastname}
                    error={errors?.lastname?.[0]}
                    onChange={(e, { value }) => setForm({ ...form, lastname: value })}
                />
            </Form.Group>

            <Header as="h3" content={t('company_information')} />

            <Form.Group widths="equal">
                <SuperField
                    as="input"
                    label={t('company_name')}
                    value={form.company_name}
                    error={errors?.company_name?.[0]}
                    onChange={(e, { value }) => setForm({ ...form, company_name: value })}
                />

                <SuperField
                    search
                    as="choice"
                    width="6"
                    label={t('number_of_employees')}
                    value={form.number_of_employees}
                    error={errors?.number_of_employees?.[0]}
                    type="company_size_choices"
                    onChange={(e, { value }) => setForm({ ...form, number_of_employees: value })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="input"
                    width="10"
                    label={t('registration_id')}
                    value={form.company_id}
                    error={errors?.company_id?.[0]}
                    onChange={(e, { value }) => setForm({ ...form, company_id: value })}
                />
                <SuperField
                    as="input"
                    width="10"
                    label={t('dit_number')}
                    value={form.dit_number}
                    error={errors?.dit_number?.[0]}
                    onChange={(e, { value }) => setForm({ ...form, dit_number: value })}
                />
                <SuperField
                    as="input"
                    label={t('vat_number')}
                    value={form.vat_number}
                    error={errors?.vat_number?.[0]}
                    onChange={(e, { value }) => setForm({ ...form, vat_number: value })}
                />
            </Form.Group>

            <Divider />

            <Header as="h3" content={t('contact_information')} />

            <Form.Field>
                <div>
                    <span
                        style={{
                            color: contacts.length > 0 ? 'var(--dark)' : 'var(--danger)',
                            fontWeight: contacts.length > 0 ? 'normal' : 'bold',
                        }}
                    >
                        {contacts.length > 0 ? (
                            contacts.map((contact, idx) => (
                                <div key={idx}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            itemsAlign: 'start',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div>
                                            {contact.contact_person_name !== '' && (
                                                <strong>
                                                    {contact.contact_person_name} <br />
                                                </strong>
                                            )}
                                            {contact.phone !== '' ? (
                                                <>
                                                    {contact.phone} <br />
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {contact.email !== '' ? <>{contact.email} </> : ''}
                                            {contact.is_invoicing && (
                                                <div
                                                    style={{
                                                        opacity: 0.8,
                                                        fontSize: '0.9rem',
                                                        fontWeight: 'bold',
                                                        marginTop: '0.2rem',
                                                    }}
                                                >
                                                    {t('used_for_invoicing')}
                                                </div>
                                            )}
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            <span
                                                style={{
                                                    fontWeight: 'bold',
                                                    opacity: 0.5,
                                                    marginRight: '1rem',
                                                    position: 'relative',
                                                    top: '-0.1rem',
                                                }}
                                            >
                                                #{idx + 1}.
                                            </span>
                                            <SuperDuperModal
                                                centered={false}
                                                header={t('update_contact')}
                                                trigger={
                                                    <Icon
                                                        name="pencil"
                                                        style={{ marginRight: '1rem', cursor: 'pointer' }}
                                                    />
                                                }
                                                content={
                                                    <ContactForm
                                                        setContacts={setContacts}
                                                        contact={contact}
                                                        index={idx}
                                                    />
                                                }
                                            />
                                            <CanView permissions={['contacts.c_delete_contacts']}>
                                                <ConfirmModal
                                                    description={t('are_you_sure_that_you_want_to_remove_this_contact')}
                                                    onConfirm={() => handleContactRemove(contact, idx)}
                                                    button={
                                                        <Icon
                                                            name="close"
                                                            style={{ cursor: 'pointer', color: 'var(--danger)' }}
                                                        />
                                                    }
                                                />
                                            </CanView>
                                        </div>
                                    </div>
                                    <Divider />
                                </div>
                            ))
                        ) : (
                            <>
                                {t('no_active_contact')} <br />
                            </>
                        )}
                    </span>
                    <SuperDuperModal
                        centered={false}
                        header={t('add_contact')}
                        trigger={
                            <strong className="ref-link" style={{ color: 'var(--primary)' }}>
                                {t('add_contact')}
                            </strong>
                        }
                        content={<ContactForm setContacts={setContacts} />}
                    />
                </div>
            </Form.Field>

            {/*<Form.Group widths="equal">
                <PhoneField
                    hideType
                    placeholder={t('enter_number')}
                    value={form.phone}
                    error={errors?.phone?.[0]}
                    setValue={(e, { value }) =>
                        setForm({
                            ...form,
                            phone: value.replaceAll(' ', ''),
                        })
                    }
                />
                <EmailField
                    label={t('email')}
                    placeholder={t('enter_email')}
                    value={form.email}
                    error={errors?.email?.[0]}
                    setValue={(e, { value }) =>
                        setForm({
                            ...form,
                            email: value,
                        })
                    }
                />
            </Form.Group>*/}

            <Form.Group widths="equal">
                <SuperField
                    as="input"
                    label={t('url')}
                    value={form.url}
                    error={errors?.url?.[0]}
                    onChange={(e, { value }) => setForm({ ...form, url: value })}
                />

                <SuperField
                    as="choice"
                    search
                    allowAdditions={canManageSources}
                    label={t('source')}
                    disabled={sourceTypes.isLoading}
                    loading={sourceTypes.isLoading}
                    customOptions={sourceTypes.options}
                    onAddItem={(e, { value }) => handleAddItem(value)}
                    value={form.source}
                    onChange={(e, { value }) =>
                        setForm({
                            ...form,
                            source: value,
                        })
                    }
                />
            </Form.Group>

            <SuperField
                as="textarea"
                label={t('description')}
                value={form.description}
                error={errors?.description?.[0]}
                onChange={(e, { value }) => setForm({ ...form, description: value })}
            />

            <Divider />

            <Header as="h3" content={t('track_your_custom_metric')} style={{ marginBottom: 0 }} />
            <p style={{ marginTop: '0.25rem' }}>{t('track_your_custom_metric_hint')}</p>

            <Form.Group widths="equal">
                <SuperField
                    as="input"
                    list="metric_values"
                    label={t('metric_title')}
                    value={form.count_number_value}
                    help={t('double_click_to_show_options_that_you_used_previous')}
                    error={errors?.count_number_value?.[0]}
                    onChange={(e, { value }) => setForm({ ...form, count_number_value: value })}
                />
                <datalist id="metric_values">
                    {metricValues.map((item, idx) => (
                        <option key={idx} value={item}>
                            {item}
                        </option>
                    ))}
                </datalist>
                <SuperField
                    as="input"
                    label={t('metric_value')}
                    value={form.count_number}
                    error={errors?.count_number?.[0]}
                    onChange={(e, { value }) => setForm({ ...form, count_number: value.replaceAll(',', '.') })}
                />
            </Form.Group>

            <Divider />

            <Form.Group widths="equal">
                {!isOpportunity ? (
                    <SuperField
                        search
                        key={isOpportunity}
                        as="choice"
                        label={t('state')}
                        type="lead_state_choices"
                        value={form.state || ''}
                        error={errors?.stage?.[0]}
                        onChange={(e, { value }) =>
                            setForm({
                                ...form,
                                state: value,
                            })
                        }
                    />
                ) : (
                    <SuperField
                        search
                        key={isOpportunity}
                        as="choice"
                        label={t('stage')}
                        type="opportunity_stage_choices"
                        error={errors?.stage?.[0]}
                        value={form.stage || ''}
                        onChange={(e, { value }) =>
                            setForm({
                                ...form,
                                stage: value,
                            })
                        }
                    />
                )}

                <SuperField
                    as="choice"
                    search
                    value={form.unit}
                    endpoint={API.UNITS + '?query={id, name}&only_from_active_company=true&only_basic_info=true'}
                    text="name"
                    label={t('unit')}
                    error={errors?.stage?.[0]}
                    onChange={(e, { value }) =>
                        setForm({
                            ...form,
                            unit: value,
                        })
                    }
                />
            </Form.Group>

            <SuperField
                as="choice"
                search
                multiple
                label={t('assigned_to')}
                endpoint={API.EMPLOYEES + '?only_basic_info=true&is_active=true'}
                text="fullname"
                error={errors?.assigned_to?.[0]}
                value={form.assigned_to}
                onChange={(e, { value }) =>
                    setForm({
                        ...form,
                        assigned_to: value,
                    })
                }
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit text={t('confirm')} disabled={isProcessing || !isFormValid()} loading={isProcessing} />
            </Form.Field>
        </Form>
    )
}

const ContactForm = ({ contact, setContacts, index, onClose }) => {
    const { t } = useTranslation()

    const [form, setForm] = useState({
        id: contact?.id || 0,
        phone: contact?.phone || '',
        email: contact?.email || '',
        is_primary: contact?.is_primary || false,
        is_invoicing: contact?.is_invoicing || false,
        contact_person_name: contact?.contact_person_name || '',
    })

    const handleSubmit = async () => {
        if (index === undefined) {
            setContacts((prev) => [...prev, form])
        } else {
            setContacts((prev) =>
                prev.map((item, idx) => {
                    if (idx === index) {
                        item = form
                    }
                    return item
                })
            )
        }
        onClose()
    }

    return (
        <Form>
            <Message
                info
                visible
                content={
                    <strong>
                        <Icon
                            name="information-circle"
                            style={{ marginRight: '0.5rem', fontSize: '1.2rem', position: 'relative', top: '0.2rem' }}
                        />
                        {t('contact_form_hint')}.
                    </strong>
                }
            />
            <PhoneField
                hideType
                placeholder={t('phone')}
                value={form.phone}
                setValue={(e, { value }) => setForm((prev) => ({ ...prev, phone: value }))}
            />

            <EmailField
                label={t('email')}
                placeholder={t('email')}
                value={form.email}
                setValue={(e, { value }) => setForm((prev) => ({ ...prev, email: value }))}
            />
            <SuperField
                as="input"
                label={t('contact_person_name')}
                value={form.contact_person_name}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, contact_person_name: value }))}
            />

            <SuperField
                as="checkbox"
                label={t('used_for_invoicing')}
                checked={form.is_invoicing}
                onChange={() => setForm((prev) => ({ ...prev, is_invoicing: !form.is_invoicing }))}
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <Button
                    primary
                    type="button"
                    onClick={() => handleSubmit()}
                    disabled={
                        (form.phone === '' && form.email === '') ||
                        (!isEmailValid(form.email) && form.email !== '') ||
                        (!isPhoneNumberValid(form.phone) && form.phone !== '')
                    }
                >
                    {t('confirm')}
                </Button>
            </Form.Field>
        </Form>
    )
}

export default OpportunityForm
