import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import SuperField from '@components/forms/SuperField';
import { Divider, Form, Button } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';

const DeleteStageAction = ({ stage, setData, setTotal, onClose }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [removeOrder, setRemoveOrder] = useState(false)
    const [unableToRemoveOrder, setUnableToRemoveOrder] = useState(false)

    const onDelete = async () => {
        setProcessing(true)
        setUnableToRemoveOrder(false)
        const stage_id = stage.id
        const order_id = stage.order

        // is order checked to be removed
        let orderDeleted = false
        if (removeOrder === true) {
            const request = await requests.del(API.ORDERS + order_id + "/")
            if (request.status === 204) {
                orderDeleted = true
            }
        }

        if (!orderDeleted && removeOrder) {
            setUnableToRemoveOrder(true)
        } 

        if (!removeOrder) {
            const request = await requests.del(API.ORDERS + "sale_stages/" + stage_id + "/")
            if (request.status === 204) {
                setData(prev => prev.filter(item => item.id !== stage_id))
                setTotal(prev => prev - 1)
                onClose()
            }
        } else {
            if (orderDeleted) {
                const request = await requests.del(API.ORDERS + "sale_stages/" + stage_id + "/")
                if (request.status === 204) {
                    setData(prev => prev.filter(item => item.id !== stage_id))
                    setTotal(prev => prev - 1)
                    onClose()
                }
            }
        }
        setProcessing(false)
    }

    return (
        <div>
            <p style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                { t('confirm_your_decision_and_choose_actions_to_perform') }:
            </p>
            <SuperField as="checkbox"
                disabled
                checked={true}
                label={t('remove_process_operation')}
            />

            { stage?.order_display?.is_deleted !== true && 
                <SuperField as="checkbox"
                    style={{ marginTop: "1rem" }}
                    checked={removeOrder}
                    onChange={() => setRemoveOrder(!removeOrder)}
                    error={unableToRemoveOrder ? t('unable_to_remove_order_from_evidence') : false}
                    label={t('remove_attached_order_from_evidence')}
                />
            }

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <Button 
                    primary
                    type="button"
                    loading={processing}
                    disabled={processing}
                    content={t('confirm')}
                    onClick={() => onDelete(stage)}
                />
            </Form.Field>
        </div>
    )
}

export default DeleteStageAction;