import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import '@store/languages'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { thousandsSeparators } from '@helpers/functions'
// components
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts'
import { Segment, Grid, Header, Card } from 'semantic-ui-react'

const AbroadStats = ({ listData, params }) => {
    const { t } = useTranslation()
    const countries = useSelector((state) => state.choices.countries)

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})

    const [activeIndex1, setActiveIndex1] = useState(0)
    const [activeIndex2, setActiveIndex2] = useState(0)
    const [activeIndex3, setActiveIndex3] = useState(0)

    const [countriesStats, setCountriesStats] = useState([])
    const [employersStats, setEmployersStats] = useState([])
    const [contractStats, setContractStats] = useState([])

    const renderActiveShape = (props) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props

        return (
            <g>
                <text x={cx} y={cy - 10} dy={8} textAnchor="middle">
                    {thousandsSeparators(value || 0)}
                </text>
                <text x={cx} y={cy + 10} dy={8} textAnchor="middle">
                    {`(${t('rate_value')} ${(percent * 100).toFixed(2)}%)`}
                </text>
                <text x={cx} y={cy + 105} dy={8} textAnchor="middle">
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
            </g>
        )
    }

    useEffect(() => {
        async function fetchData() {
            setLoading(true)

            const request = await requests.get(API.ABROADS_STATS + '?query={*}' + params)
            if (request.status === 200) {
                setData(request.response)

                let pieCountries = []
                for (let i in request.response.countries) {
                    const country = request.response.countries[i]
                    if (country.value > 0 && country.country) {
                        pieCountries.push({
                            id: country.country,
                            name: countries?.[country?.country],
                            value: country.value,
                            fill: getColorById(countries?.[country?.country]),
                        })
                    }
                }
                setCountriesStats(pieCountries)

                let pieEmployers = []
                for (let i in request.response.abroad_by_employer) {
                    const employer = request.response.abroad_by_employer[i]
                    if (employer.value > 0) {
                        pieEmployers.push({
                            id: employer.employer_id,
                            name: employer.employer_name,
                            value: employer.value,
                            fill: getColorById(employer.employer_name),
                        })
                    }
                }
                setEmployersStats(pieEmployers)

                let pieContract = []
                for (let i in request.response.abroad_by_contract) {
                    const contract = request.response.abroad_by_contract[i]
                    if (contract.value > 0) {
                        pieContract.push({
                            id: contract.contract_type_id,
                            name: contract.contract_type_title,
                            value: contract.value,
                            fill: getColorById(contract.contract_type_title),
                        })
                    }
                }
                setContractStats(pieContract)
            }

            setLoading(false)
        }

        fetchData()
        // eslint-disable-next-line
    }, [listData])

    const onPieEnter1 = (_, index) => {
        setActiveIndex1(index)
    }
    const onPieEnter2 = (_, index) => {
        setActiveIndex2(index)
    }
    const onPieEnter3 = (_, index) => {
        setActiveIndex3(index)
    }

    const getColorById = (id) => {
        const letters = '0123456789ABCDEF'
        let color = '#'
        for (let i = 0; i < 6; i++) {
            const charIndex = id.charCodeAt(i % id.length) % 16
            color += letters[charIndex]
        }
        return color
    }

    return (
        <Segment
            loading={loading}
            style={{
                padding: 0,
                marginTop: 0,
                background: 'transparent',
                boxShadow: 'none',
                border: 'none',
            }}
        >
            {loading && (
                <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '6rem' }}>
                    {t('loading_statistics')}
                </p>
            )}
            {!loading && (
                <Grid centered stackable>
                    <Grid.Row columns={4} style={{ paddingTop: 0 }}>
                        <Grid.Column tablet={8} computer={3} style={{ paddingTop: '3rem' }}>
                            <Card style={{ textAlign: 'center' }}>
                                <Card.Content>
                                    <div style={{ textAlign: 'center' }}>{t('total_abroad')}</div>
                                    <div style={{ fontWeight: 'bold', fontSize: '2rem', paddingTop: '0.5rem' }}>
                                        {data.total_overall}
                                    </div>
                                </Card.Content>
                            </Card>
                            <Card style={{ textAlign: 'center' }}>
                                <Card.Content>
                                    <div style={{ textAlign: 'center' }}>{t('total_abroad_atm')}</div>
                                    <div style={{ fontWeight: 'bold', fontSize: '2rem', paddingTop: '0.5rem' }}>
                                        {data.total_abroad_atm}
                                    </div>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column tablet={6} computer={4} style={{ textAlign: 'center' }}>
                            <ResponsiveContainer width="100%" height={250}>
                                <PieChart>
                                    <Pie
                                        activeIndex={activeIndex1}
                                        activeShape={renderActiveShape}
                                        data={countriesStats}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                        overflow={'visible'}
                                        onMouseEnter={onPieEnter1}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                            <Header as={'h5'} style={{ margin: 0, marginBottom: '1rem' }}>
                                {t('abroad_country')}
                            </Header>
                        </Grid.Column>
                        <Grid.Column tablet={6} computer={4} style={{ textAlign: 'center' }}>
                            <ResponsiveContainer width="100%" height={250}>
                                <PieChart>
                                    <Pie
                                        activeIndex={activeIndex2}
                                        activeShape={renderActiveShape}
                                        data={employersStats}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={80}
                                        fill="black"
                                        dataKey="value"
                                        onMouseEnter={onPieEnter2}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                            <Header as={'h5'} style={{ margin: 0, marginBottom: '1rem' }}>
                                {t('abroad_employer')}
                            </Header>
                        </Grid.Column>
                        <Grid.Column tablet={6} computer={4} style={{ textAlign: 'center' }}>
                            <ResponsiveContainer width="100%" height={250}>
                                <PieChart>
                                    <Pie
                                        activeIndex={activeIndex3}
                                        activeShape={renderActiveShape}
                                        data={contractStats}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={80}
                                        fill="black"
                                        dataKey="value"
                                        onMouseEnter={onPieEnter3}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                            <Header as={'h5'} style={{ margin: 0, marginBottom: '1rem' }}>
                                {t('contract_type')}
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </Segment>
    )
}

export default AbroadStats
