import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { /*useDispatch,*/ useSelector } from "react-redux";
import moment from "moment";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// import { addInvoiceCurrency } from '@store/actions';
import { thousandsSeparators } from '@helpers/functions';
// components
import Icon from '@components/Icon';
import { Card, Container } from 'semantic-ui-react';

const ListDetailView = ({ filters, data, total, params }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)
    const [loading, setLoading] = useState(true)
    const [cc, setCC] = useState(null)

    // eslint-disable-next-line
    params = params || ""
    const originalCurrency = filters.currency

    async function fetchCCStats() {
        setLoading(true)
        const request = await requests.get(API.COST_CENTER_RATIO_STATS + `?query=`+ params)
        if (request.status === 200){
            setCC(request.response)
        }

        setLoading(false)
    }

    useEffect(() => {
        fetchCCStats()
        // eslint-disable-next-line
    }, [data])

    const calculateDays = () => {
        if (filters.date_from !== "" && filters.date_to !== ""){
            const moment_date_from = moment(filters.date_from)
            const moment_date_to = moment(filters.date_to)
            const days = moment.duration(moment_date_to.diff(moment_date_from, 'days'))
            return days + 1;
        }

        return t('all')
    }

    const renderIncome = () => {
        if (!loading && cc !== null) {
            if (originalCurrency !== "") return parseFloat(cc.total_converted_incomes).toFixed(2) + " " + originalCurrency
            if( cc.total_incomes.length === 0 || cc.total_incomes === 0 ) return 0

            return cc.total_incomes.map(income => (
                <> { thousandsSeparators(parseFloat(income.sum).toFixed(2)) + " " + income.currency } <br/> </> 
            ))
        }
    }

    const renderExpense = () => {
        if (!loading && cc !== null) {
            if (originalCurrency !== "") return parseFloat(cc.total_converted_expenses).toFixed(2) + " " + originalCurrency
            if( cc.total_expenses.length === 0 || cc.total_expenses === 0 ) return 0
    
            return cc.total_expenses.map(expense => (
                <> { thousandsSeparators(parseFloat(expense.sum).toFixed(2)) + " " + expense.currency } <br/> </> 
            ))
        }
    }
    
    const renderDifference = () => {
        if (!loading && cc !== null) {
            if (originalCurrency !== "") return parseFloat(cc.total_converted_incomes - cc.total_converted_expenses).toFixed(2) + " " + originalCurrency
            if( cc.total_expenses.length === 0 && cc.total_incomes.length === 0 ) return 0
    
            const income_currencies = cc.total_incomes === 0 ? [] : cc.total_incomes.map(cc => cc.currency)
            const expense_currencies = cc.total_expenses === 0 ? [] : cc.total_expenses.map(cc => cc.currency)
            const merged_arrays = (income_currencies.concat(expense_currencies))
            const currencies = merged_arrays.filter((item, pos) => merged_arrays.indexOf(item) === pos)
    
            return currencies.map(currency => {
                let difference = 0
                // let the magic happens here - return undefind if not found or founded object
                const income = cc.total_incomes === 0 ? undefined : cc.total_incomes.find(item => item.currency === currency)
                const expense = cc.total_incomes === 0 ? undefined : cc.total_expenses.find(item => item.currency === currency)
             
                difference = parseFloat((income !== undefined ? income.sum : 0) - (expense !== undefined ? expense.sum : 0)).toFixed(2)
             
                return <span style={{ color: difference > 0 ? "var(--success)" : "var(--danger)" }}> { thousandsSeparators(difference) + " " + currency } <br/></span> 
            })
        }
    }

    return (
        <Container fluid style={{ marginBottom: "0.5rem" }}>
            <Card.Group stackable doubling itemsPerRow={5}>
                <Card style={{ borderRadius: 0 }}>
                    <Card.Content>
                        <div style={{ display: "flex", marginBottom: "2rem" }}>
                            <div style={{ textAlign: "left", width: "30%" }}>
                                <Icon name="calendar-outline" style={{ fontSize: "3rem", fontWeight: "bold", textAlign: "left" }}/>
                            </div>
                            <div style={{ textAlign: "right", fontWeight: "bold", width: "70%", fontSize: "1.25rem" }}>
                                { calculateDays() }
                            </div>
                        </div>

                        <div style={{ position: "absolute", width: "90%", display: "flex", bottom: "1rem", marginTop: "1rem", textTransform: "uppercase", color: "var(--dark)", opacity:"0.8" }}>
                            <div style={{ width: "50%" }}>{ t("total_days") }</div>
                            <div style={{ width: "50%", textAlign: "right" }}>
                                { (filters.date_from !== "" && filters.date_to !== "") && 
                                    <small>{ moment(filters.date_from).format(dateFormat) } - { moment(filters.date_to).format(dateFormat) }</small>
                                }
                            </div>
                        </div>
                    </Card.Content>
                </Card>
                <Card style={{ borderRadius: 0 }}>
                    <Card.Content>
                        <div style={{ display: "flex", marginBottom: "2rem" }}>
                            <div style={{ textAlign: "left", width: "30%" }}>
                                <Icon name="list-outline" style={{ fontSize: "3rem", fontWeight: "bold", textAlign: "left" }}/>
                            </div>
                            <div style={{ textAlign: "right", fontWeight: "bold", width: "70%", fontSize: "1.25rem" }}>
                                { data?.count || 0 }
                            </div>
                        </div>

                        <div style={{ position: "absolute", bottom: "1rem", marginTop: "1rem", textTransform: "uppercase", color: "var(--dark)", opacity:"0.8" }}>
                            { t("total_records") }
                        </div>
                    </Card.Content>
                </Card>
                <Card style={{ borderRadius: 0 }}>
                    <Card.Content>
                        <div style={{ display: "flex", marginBottom: "2rem" }}>
                            <div style={{ textAlign: "left", width: "30%" }}>
                                <Icon name="trending-up-outline" style={{ fontSize: "3rem", fontWeight: "bold", textAlign: "left" }}/>
                            </div>
                            <div style={{ textAlign: "right", fontWeight: "bold", width: "70%", fontSize: "1.25rem" }}>
                                { renderIncome() }
                            </div>
                        </div>

                        <div style={{ position: "absolute", bottom: "1rem", marginTop: "1rem", textTransform: "uppercase", color: "var(--dark)", opacity:"0.8" }}>
                            { t("total_incomes") }
                        </div>
                    </Card.Content>
                </Card>
                <Card style={{ borderRadius: 0 }}>
                    <Card.Content>
                        <div style={{ display: "flex", marginBottom: "2rem" }}>
                            <div style={{ textAlign: "left", width: "30%" }}>
                                <Icon name="trending-down-outline" style={{ fontSize: "3rem", fontWeight: "bold", textAlign: "left" }}/>
                            </div>
                            <div style={{ textAlign: "right", fontWeight: "bold", width: "70%", fontSize: "1.25rem" }}>
                                { renderExpense() }
                            </div>
                        </div>

                        <div style={{ position: "absolute", bottom: "1rem", marginTop: "1rem", textTransform: "uppercase", color: "var(--dark)", opacity:"0.8" }}>
                            { t("total_expenses") }
                        </div>
                    </Card.Content>
                </Card>
                <Card style={{ borderRadius: 0 }}>
                    <Card.Content>
                        <div style={{ display: "flex", marginBottom: "2rem" }}>
                            <div style={{ textAlign: "left", width: "30%" }}>
                                <Icon name="wallet-outline" style={{ fontSize: "3rem", fontWeight: "bold", textAlign: "left" }}/>
                            </div>
                            <div style={{ textAlign: "right", fontWeight: "bold", width: "70%", fontSize: "1.25rem" }}>
                                { renderDifference() }
                            </div>
                        </div>

                        <div style={{ position: "absolute", bottom: "1rem", marginTop: "1rem", textTransform: "uppercase", color: "var(--dark)", opacity:"0.8" }}>
                            { t("total") }
                        </div>
                    </Card.Content>
                </Card>
            </Card.Group>
        </Container>
    );
};

export default ListDetailView;