import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { isEmpty } from '@helpers/validation';
import { renderAddress } from '@helpers/functions';
import { patchRequest } from '@services/ServiceCommon';
// components
import InfoRow from '@components/InfoRow';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import AddressFillForm from '@components/forms/AddressFillForm';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Divider, Header, Form } from 'semantic-ui-react';

const BusinessDetailBankForm = ({ isDirect, onClose, endpoint, updateState, data }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)

    // eslint-disable-next-line
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        id: data?.id || 0,
        name: data?.name || "",
        iban: data?.iban || "",
        swift_code: data?.swift_code || "",
        alias: data?.alias || "",
        bank_detail: data?.bank_detail || "",
        currency: data?.currency || "EUR",
        default: data?.default !== undefined ? data.default : false,
        bank_address: data?.bank_address || null
    })

    const handleSubmit = async () => {
        setErrors(null)
        setIsProcessing(true)

        let data = null
        if (isDirect) {
            data = {
                bank_accounts: form.id  === 0 ? {
                    create: [form]
                } : {
                    update: {[form.id]: form}
                }
            }
        } else {
            data = {
                business_detail: {
                    bank_accounts: form.id  === 0 ? {
                        create: [form]
                    } : {
                        update: {[form.id]: form}
                    }
                }
            }
        }
        
        const request = await patchRequest(endpoint, data)
        if( request.status === 200 ){
            if (isDirect) {
                updateState(prev => ({
                    ...prev,
                    bank_accounts: request.response.bank_accounts
                }))
            } else {
                updateState(prev => ({
                    ...prev,
                    business_detail: {
                        ...prev.business_detail,
                        bank_accounts: request.response.business_detail.bank_accounts
                    }
                }))
            }
            onClose()
        } else if( request.status === 400 ) {
            setErrors(request.response)
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="input"
                autoFocus
                required
                label={t('name')}
                placeholder={t('enter_bank_name')}
                value={form.name}
                onChange={(e, { value }) => setForm({ ...form, name: value })}
            />
                
            <Form.Group widths="equal">
                <SuperField as="input"
                    required
                    label='IBAN'
                    placeholder={t('enter_iban')}
                    value={form.iban}
                    onChange={(e, { value }) => setForm({ ...form, iban: value })}
                />
                <SuperField as="input"
                    label='Swift'
                    required
                    placeholder={t('enter_swift')}
                    value={form.swift_code}
                    onChange={(e, { value }) => setForm({ ...form, swift_code: value })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="input"
                    label='Alias'
                    placeholder={t('enter_bank_alias')}
                    value={form.alias}
                    onChange={(e, { value }) => setForm({ ...form, alias: value })}
                />
                <SuperField as="choice"
                    search
                    type="currency_codes"
                    label={t('currency')}
                    value={form.currency}
                    onChange={(e, { value }) => setForm({ ...form, currency: value })}
                />
            </Form.Group>

            <SuperField as="checkbox"
                label={t('set_as_default')}
                checked={form.default}
                onChange={() => setForm({ ...form, default: !form.default })}
            />

            <Divider/>
            <Header as="h3" content={t('bank_address')}/>
            <div style={{ paddingBottom: "1rem" }}>
                { form.bank_address === null 
                    ? 
                    <SuperDuperModal 
                        trigger={
                            <span className="ref-link" style={{ color: "var(--primary)", fontWeight: "bold", marginLeft: "0.5rem" }}>
                                {t('add_bank_address')}
                            </span>
                        }
                        content={
                            <AddressFillForm
                                address={form.bank_address}
                                addressType="bank_address"
                                addressTypeDisplay={t("bank_address")}
                                updateState={setForm}
                            />
                        }
                    />
                    : 
                    <InfoRow 
                        allowEdit
                        isVisible={true}
                        text={t('bank_address')}
                        content={renderAddress(form?.bank_address)}
                        modal={
                            <AddressFillForm
                                address={form.bank_address}
                                addressType="bank_address"
                                addressTypeDisplay={t("bank_address")}
                                updateState={setForm}
                            />
                        }
                    /> 
                }
            </div>

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    disabled={isProcessing || isEmpty(form.name) || isEmpty(form.iban) || isEmpty(form.swift_code)}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    )
}

export default BusinessDetailBankForm;