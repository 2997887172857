import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { requests } from '@helpers/requests'
import { API } from '@store/config'
// components
import { Divider, Form } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const AccommodationBulk = ({ selected, setSelected, setData, onClose }) => {
    const { t } = useTranslation()
    
    const [isProcessing, setIsProcessing] = useState(false)
    const [form, setForm] = useState({
        accommodation: "",
        accommodation_valid_from: "",
        accommodation_valid_to: ""
    })

    const onSubmit = async () => {
        setIsProcessing(true)
        let updated = []

        for(let i = 0; i < selected.length; i++){
            const request = await requests.patch(API.FOREIGNERS + "processes/" + selected[i].id + "/", {
                accommodation: form.accommodation ? form.accommodation : null,
                accommodation_valid_from: form.accommodation_valid_from ? form.accommodation_valid_from : null,
                accommodation_valid_to: form.accommodation_valid_to ? form.accommodation_valid_to : null
            })
            if (request.status === 200) {
                updated.push(request.response) 
            }
        }

        setData(prev => prev.filter(item => {
            const itemToUpdate = updated.find(updatedItem => updatedItem.id === item.id)
            if (item.id === itemToUpdate?.id) {
                item.accommodation = itemToUpdate.accommodation
                item.accommodation_valid_from = itemToUpdate.accommodation_valid_from
                item.accommodation_valid_to = itemToUpdate.accommodation_valid_to
            }
            return item
        }))

        onClose()
        setSelected([])
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={onSubmit}>
            <SuperField as="choice-select"
                search
                text="name"
                label={t('accommodation')}
                value={form.accommodation}
                endpoint={API.ACCOMMODATIONS}
                onChange={(e, { value }) => setForm(prev => ({ ...prev, accommodation: value }))}
            />
            <Form.Group widths="equal">
                <SuperField
                    search
                    as="datepicker"
                    label={t('valid_from')}
                    value={form.accommodation_valid_from}
                    onChange={(e, { value }) => { setForm(prev => ({ ...prev, accommodation_valid_from: value })) }}
                />
                <SuperField
                    search
                    as="datepicker"
                    label={t('valid_to')}
                    value={form.accommodation_valid_to}
                    onChange={(e, { value }) => { setForm(prev => ({ ...prev, accommodation_valid_to: value })) }}
                />
            </Form.Group>
            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    disabled={isProcessing}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    )
}

export default AccommodationBulk