import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { isEmpty } from "@helpers/validation";
// components
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const InspectionsForm = ({ onClose, vehicle, record, setData }) => {
    const { t } = useTranslation()
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        valid_from: record?.valid_from || "",
        date_of_inspection: record?.date_of_inspection || "",
        valid_to: record?.valid_to || "",
        passed: record?.passed || false,
        type: record?.type || null,
        vehicle: vehicle.id,
    })

    const handleSubmit = async () => {
        setErrors(null)
        setProcessing(true)
        
        let request = undefined
        let data = form
        if (data.date_of_inspection === "") data.date_of_inspection = null
        if (data.valid_from === "") data.valid_from = null
        if (data.valid_to === "") data.valid_to = null
        if (data.type === "") data.type = null
        if (record?.id === undefined) { // create logic
            request = await requests.post(API.VEHICLES + "inspections/", data)
        } else { // update logic
            request = await requests.patch(API.VEHICLES + "inspections/" + record?.id + "/", data)
        }

        if (request !== undefined){
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201){
                setData(prev => [request.response, ...prev])
                onClose()
            }
            if (request.status === 200) {
                setData(prev => prev.map(item => {
                    if (item.id === record?.id) {
                        item = request.response
                    }

                    return item
                }))
                onClose()
            }
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            

            <Form.Group widths="equal">
                <SuperField as="choice"
                    required
                    type="vehicle_inspections"
                    label={t('inspection_type')}
                    value={form.type?.toString() || ""}
                    error={errors?.type?.[0] || false}
                    onChange={(e, { value }) => setForm({ ...form, type: value })}
                />
                <SuperField as="datepicker"
                    required
                    label={t('date_of_inspection')}
                    value={form.date_of_inspection}
                    error={errors?.date_of_inspection?.[0] || false}
                    onChange={(e, { value }) => setForm({ ...form, date_of_inspection: value })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    label={t('valid_from')}
                    value={form.valid_from}
                    error={errors?.valid_from?.[0] || false}
                    onChange={(e, { value }) => setForm({ ...form, valid_from: value })}
                />
                <SuperField as="datepicker"
                    label={t('valid_to')}
                    value={form.valid_to}
                    error={errors?.valid_to?.[0] || false}
                    onChange={(e, { value }) => setForm({ ...form, valid_to: value })}
                />
            </Form.Group>
            <SuperField as="checkbox"
                label={t('successfully_passed')}
                checked={form.passed}
                error={errors?.passed?.[0] || false}
                onChange={() => setForm({ ...form, passed: !form.passed })}
            />
            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    disabled={processing || isEmpty(form.type) || isEmpty(form.date_of_inspection)}
                    loading={processing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default InspectionsForm;