import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { thousandsSeparators } from '@helpers/functions'
// components
import { Slider } from 'react-semantic-ui-range'
import { Form, Label } from 'semantic-ui-react'
import SuperField from './SuperField'

const SliderField = ({ isRange, value, max, step, color, inputValues, required, label, onChange }) => {
    const { t } = useTranslation()

    const [maxValue] = useState(max || 100000)
    const [values, setValues] = useState({
        value1: value ? (isRange ? (value[0] ? value[0] : '') : value) : '',
        value2: value ? (value[1] ? value[1] : '') : maxValue ? maxValue : max || 100000,
    })

    const settings = {
        min: 0,
        max: maxValue,
        step: step || 100,
        onChange: (value) => {
            setValues({
                value1: isRange ? value[0] : value ? value : '',
                value2: isRange ? value[1] : '',
            })
        },
    }

    useEffect(() => {
        if (!value || (isRange && !value[0] && !value[1])) {
            setValues({
                value1: '',
                value2: maxValue,
            })
        }
        // eslint-disable-next-line
    }, [value])

    useEffect(() => {
        onChange([values.value1, values.value2])

        // eslint-disable-next-line
    }, [values])

    const handleValueChange = (e, index) => {
        let value = parseInt(e.target.value)
        if (!value) {
            value = ''
        }
        if (value <= maxValue) {
            if (index === 1) {
                setValues({ ...values, value1: value })
            }
            if (index === 2) {
                setValues({ ...values, value2: value })
            }
        }
    }

    return (
        <div>
            <Label
                size="medium"
                style={{ background: 'none', color: 'black', paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
            >
                {label}
            </Label>
            {required && <strong style={{ color: 'var(--danger)' }}>*</strong>}
            <Slider
                value={isRange ? [values.value1, values.value2] : values.value1}
                multiple={isRange}
                color={color || 'black'}
                settings={settings}
            />
            <Form.Group widths={'equal'}>
                {inputValues && (
                    <SuperField
                        as="input"
                        type="number"
                        placeholder={t(isRange ? 'price_from' : 'price')}
                        min="0"
                        value={values.value1}
                        error={values.value1 > maxValue ? 'Max ' + maxValue : null}
                        onChange={(e) => handleValueChange(e, 1)}
                        style={{ paddingTop: '0.5rem' }}
                    />
                )}
                {inputValues && isRange && (
                    <>
                        <SuperField
                            as="input"
                            type="number"
                            placeholder={t('price_to')}
                            min="0"
                            value={values.value2}
                            error={values.value2 > maxValue ? 'Max ' + maxValue : null}
                            onChange={(e) => handleValueChange(e, 2)}
                            style={{ paddingTop: '0.5rem' }}
                        />
                    </>
                )}
            </Form.Group>

            {!inputValues && (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                        <Label size="medium" style={{ background: 'none', color: 'grey', paddingLeft: 0 }}>
                            {thousandsSeparators(values.value1) || 0}
                        </Label>
                    </div>
                    <div>
                        {isRange && (
                            <Label size="medium" style={{ background: 'none', color: 'grey', paddingLeft: 0 }}>
                                {thousandsSeparators(values.value2) || 0}
                            </Label>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default SliderField
