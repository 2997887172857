import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import CanView from '@components/perms/CanView';
import { Divider, Header, Grid } from 'semantic-ui-react';
// custom
import VignetteView from './VignetteView';
import InspectionsView from './InspectionsView';
import ResponsiblePersonsView from './ResponsiblePersonsView';
import VehicleServiceRecordsView from './VehicleServiceRecordsView';


const VehicleDetailView = ({ onClose, vehicle, contracts }) => {
    const { t } = useTranslation()

    return (
        <>
            <Header as="h2" content={vehicle.title} style={{ marginBottom: "0.2rem", textAlign: "center" }}/>
            <Divider/>
            <Grid stackable style={{ padding: 0 }}>
                <Grid.Row verticalAlign="middle" columns="4" textAlign="center" style={{ padding: 0 }}>
                    <Grid.Column>{t('car_plate_number')}: <br/> <strong>{vehicle.car_plate_number}</strong></Grid.Column>
                    <Grid.Column>{t('vin')}:  <br/><strong>{vehicle.vin}</strong></Grid.Column>
                    <Grid.Column>{t('fuel_type')}: <br/> <strong>{ vehicle?.fuel_type ? vehicle.fuel_type_display : t('non_specified') }</strong></Grid.Column>
                    <Grid.Column>{t('vehicle_type')}: <br/> <strong>{ vehicle?.type ? vehicle.type?.title : t('non_specified')}</strong></Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider/>   
            <Header as="h4" content={t('description')}/>
            <p>
                { vehicle.description !== "" ? vehicle.description : t('no_description') }
            </p>
            <Divider/>

            <ResponsiblePersonsView vehicle={vehicle} contracts={contracts}/>
            <VignetteView vehicle={vehicle}/>
            <InspectionsView vehicle={vehicle}/>

            <CanView permissions={['services.c_view_all_service_records']}>
                <VehicleServiceRecordsView vehicle={vehicle}/>
            </CanView>
        </>
    );
};

export default VehicleDetailView;