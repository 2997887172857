import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
import { useSelector } from 'react-redux';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { useHasPermission } from '@helpers/hooks';
// components
import { Form, Divider, Header, Grid, Table } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const CCRecordForm = ({ record, setData, costCenter, onClose, setSize }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector(state => state.date_format)
    const canManageCC = useHasPermission('cost_centers.c_manage_cost_centers')

    const [loading, setLoading] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [costCenters, setCostCenters] = useState([])
    const [categories, setCategories] = useState([])
    const [activities, setActivities] = useState([])
    const [form, setForm] = useState({
        date: moment().format("YYYY-MM-DD"),
        cost_center: costCenter?.id || "",
        fulfillment: record.id,
        owner: record.profile.id,
    })

    useEffect(() => {
        async function fetchCostCenters() {
            setLoading(true)
            const request = await requests.get(API.COST_CENTERS + "?query={id, title}")
            if (request.status === 200) setCostCenters(request.response)
            setLoading(false)
        }

        async function fetchCategories(){
            const request = await requests.get(API.COST_CENTERS_RECORD_CATEGORIES + "?type=2")
            if( request.status === 200 ) setCategories(request.response)
        }

        setActivities(record.timesheet_records.filter(item => {
            item.cost_center_category = item.tariff?.cost_center_category?.id || ""
            return item
        }))

        fetchCategories()
        fetchCostCenters()
        setSize("small")

        // eslint-disable-next-line
    }, [])

    const isValid = () => {
        let valid = true
        for (let i = 0; i < activities.length; i++) {
            if (activities[i].cost_center_category === "" ) return false
        }

        return valid
    } 

    const handleCategoryOptions = () => {
        let options = []

        options = categories.map(category => {
            let textValue = category.title + " (" + category.type_display + ")"
            
            if( category.code !== "" && category.code !== null){
                textValue = category.code + " - " + category.title + " (" + category.type_display + ")"
            }

            return {
                id: category.id,
                value: category.id,
                text: textValue
            }
        })

        return options
    }

    const categoriesOptions = handleCategoryOptions()

    const handleSubmit = async () => {
        setProcessing(true)

        // loop over activities and group them by category - calculate totals and assign to cost center per category
        let categories = []
        let records = [] 
        for (let x = 0; x < activities.length; x++) { // find all groups and create a list
            categories.push(activities[x].tariff.cost_center_category.id)
        }

        categories = [...new Set(categories)] // remove duplicated from the list and override original array

        // loop over all categories and calculate totals
        for (let i = 0; i < categories.length; i++) {
            let activitiesForCategory = activities.filter(item => item.cost_center_category === categories[i])
            if (activitiesForCategory.length > 0) {
                let sum = activitiesForCategory.reduce((total, item) => total + parseFloat(item.total_fee), 0)
                sum = sum.toFixed(2)

                // create record for this category
                const record = {
                    date: form.date,
                    category: categories[i], 
                    cost_center: form.cost_center,
                    owner: form.owner,
                    amount: {
                        sum: sum,
                        currency: "EUR" // !! to be changed
                    },
                    fulfillment: form.fulfillment
                }   
                
                // create 
                records.push(record)
                await requests.post(API.COST_CENTERS_RECORDS, record)
            }
        }

        // fetch fulfillment to get latest data
        const requestFulfillment = await requests.get(API.ORDERS + "fulfillments/" + record.id + "/")
        if (requestFulfillment.status === 200) {
            setData(prev => prev.filter(item => {
                if (item.id === record.id) {
                    item.cost_center_records = requestFulfillment.response.cost_center_records
                }

                return item
            }))

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('assigned_to_cost_center'),
                animation: 'pulse',
                time: 2000,
            })
            onClose()
        }

        setProcessing(false)
    }

    const handleAddCostCenter = async (value) => {
        setLoading(true)
        const request = await requests.post(API.COST_CENTERS, {
            title: value,
            unit: form.owner?.id || null
        })

        if (request.status === 201) {
            setForm(prev => ({...prev, cost_center: request.response.id}))
            setCostCenters(prev => [...prev, request.response])
        }
        setLoading(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Header as="h3" content={t('assign_to_cost_center')}/>
            <Divider/>

            {/* form fields */}
            <Grid stackable divided>
                <Grid.Row verticalAlign="middle" textAlign="center" columns="2" style={{ padding: 0 }}>
                    <Grid.Column>
                        <span>{t('worker')}</span> <br/>
                        <strong>{ record.profile.fullname }</strong>
                    </Grid.Column>
                    <Grid.Column>
                        <span>{t('fee')}</span> <br/>
                        <strong>{ record.total_fee }</strong>
                        
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider/>

            <SuperField as="choice"
                search
                loading={loading}
                disabled={loading}
                label={t('cost_center')}
                value={form.cost_center}
                allowAdditions={canManageCC}
                onAddItem={(e, { value }) => handleAddCostCenter(value)}
                onChange={(e, { value }) => setForm(prev => ({...prev, cost_center: value}))}
                customOptions={costCenters.map(item => ({ key: item.id, value: item.id, text: item.title }))}
            />

            <Header as="h4" content={t("included_activities")}/>
            <Divider/>
            <Table striped selectable unstackable className="table" style={{ fontSize: "0.9rem" }}>
                <Table.Header>
                    <Table.Row verticalAlign="middle">
                        <Table.HeaderCell>{t('date')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('activity')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('category')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('hours')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('total')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    { activities.length > 0 
                    ?
                        <>
                            { activities.map(item => (
                                <Table.Row verticalAlign="middle" key={item.id}>
                                    <Table.Cell width="2" style={{ paddingTop: "1.3rem", paddingBottom: "1.3rem" }} >{ moment(item.date).format(dateFormat) }</Table.Cell>
                                    <Table.Cell style={{ paddingTop: "1.3rem", paddingBottom: "1.3rem" }} >
                                        <strong>{ item.category.title }</strong>
                                        { item.activity !== "" && 
                                            <>
                                                <br/>
                                                <small> { item.activity } </small>
                                            </> 
                                        }
                                    </Table.Cell>
                                    <Table.Cell width="5" style={{ paddingTop: "1.3rem", paddingBottom: "1.3rem" }}>
                                        <SuperField as="choice"
                                            search
                                            customOptions={categoriesOptions}
                                            value={item.cost_center_category}
                                            onChange={(e, { value }) => {
                                                setActivities(prev => prev.filter(activity => {
                                                    if (activity.id === item.id) {
                                                        activity.cost_center_category = value
                                                    }
    
                                                    return activity
                                                }))
                                            }}
                                        />
                                    </Table.Cell>
                                    <Table.Cell width="1" style={{ paddingTop: "1.3rem", paddingBottom: "1.3rem" }} >{ item.hours }</Table.Cell>
                                    <Table.Cell width="2" style={{ paddingTop: "1.3rem", paddingBottom: "1.3rem", fontWeight: "bold" }}> 
                                        { item.total_fee }
                                    </Table.Cell>
                                </Table.Row>
                            )) }
                        </>
                    : 
                    <Table.Row verticalAlign="middle">
                        <Table.Cell colspan={5} textAlign="center" style={{ fontWeight: 'bold' }}>
                            {t('no_data')}
                        </Table.Cell>
                    </Table.Row>
                    }
                    <Table.Row>
                        <Table.Cell colspan={3}><strong>{t("total")}</strong></Table.Cell>
                        <Table.Cell><strong>{record.total_hours}</strong></Table.Cell>
                        <Table.Cell><strong>{record.total_fee}</strong></Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

            <Divider/>
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    text={t('confirm')}
                    loading={processing}
                    disabled={processing || form.cost_center === "" || !isValid()}
                />
            </Form.Field>
        </Form>
    )
}

export default CCRecordForm;