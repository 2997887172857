import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Pie, HorizontalBar } from "react-chartjs-2";
import { Grid } from 'semantic-ui-react'

import { Divider, Loader, Header } from 'semantic-ui-react';

const IssueStatistics = ({ stats, isLoading }) => {
    const { t } = useTranslation()
    const colors = useSelector(state => state.global_pref.colors)
    const pie_data = {
        labels: [t('estimated_time'), t('real_time')],
        datasets: [
            {
                data: [stats.total_estimated_time, stats.total_spent_time],
                backgroundColor: [
                    colors.primary,
                    colors.warning,
                ],
            },
        ],
    };
    const horizontal_labels = stats.results.map(({ name }) => name)

    const horizontal_bar = {
        labels: horizontal_labels,
        datasets: [
            {
                label: t('estimated_time'),
                data: stats.results.map(({ estimated_time }) => (estimated_time ? estimated_time : 0)),
                backgroundColor: colors.primary,
            },
            {
                label: t('real_time'),
                data: stats.results.map(({ time_spent }) => (time_spent ? time_spent : 0)),
                backgroundColor: colors.warning,
            },
        ],
    };
    return (
        < >
            {isLoading ?
                <Loader active={isLoading} />
                :
                <>
                    <Header content={t('overall')} as="h2" />
                    <Divider />
                    {(stats.total_estimated_time !== null || stats.total_spent_time !== null) ?
                        <Grid style={{ width: '100%', padding: 0 }}>
                            <Grid.Column style={{ padding: 0 }} width={8}>
                                <HorizontalBar data={horizontal_bar} />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 0 }} width={8}>
                                <Pie data={pie_data} />
                            </Grid.Column>
                        </Grid>
                        :
                        <Header content={t('no_data')} as="h2" />
                    }
                </>
            }
        </>
    );
};

export default IssueStatistics;