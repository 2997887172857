import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { useFetchData } from '@helpers/hooks';
// components
import Loading from '@components/general/Loading';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Segment, Divider } from 'semantic-ui-react';

const ComponentChoices = ({ selected, setSelected }) => {
    const { t } = useTranslation();

    // eslint-disable-next-line
    const [result, setResult] = useFetchData(API.APPLICATION + "choices/")
    const [properties, setProperties] = useState([])

    useEffect(() => {
        const propertyList = []
        for( let property in result.data?.application_form_choices){
            propertyList.push({ key: property, name: result.data.application_form_choices[property] })
        }

        setProperties(propertyList)
    }, [result])

    const handleSelection = ( choice ) => {
        if ( selected.includes(choice) ){
            setSelected(prev => prev.filter( item => item !== choice))
        } else {
            setSelected([...selected, choice])
        }
    }

    // eslint-disable-next-line
    const handleFullnameSelection = () => {
        if ( selected.includes("firstname") || selected.includes("lastname") ){
            setSelected(prev => prev.filter(item => (item !== "firstname" && item !== "lastname")))
        } else {
            setSelected(prev => ([...prev, "firstname", "lastname"]))
        }
    }

    const toExclude = [
        'firstname',
        'lastname',
        'driver_licences',
        'health_insurance',
        'family',
        'handicap_level',
        'business_detail',
        'shirt_size',
        'trousers_size',
        'shoe_size',
        'jacket_size',
        'number_of_children',
        'id_card_number',
    ]

    return (
        <>
            { result.isLoading ? <Loading/> :
                <div style={{ display: "flex", flexDirection: "column"}}>
                    <FlexRow padding="0">
                        <FlexItem>
                            <h3> {t('data_sources')} </h3>
                        </FlexItem>
                    </FlexRow>
                    <Divider/>
                    <Segment
                        // onClick={() => handleFullnameSelection() }
                        clearing
                        className={ selected.includes('firstname') && selected.includes('lastname') ? "segment-hover active" : "segment-hover"}
                        style={{
                            marginTop: "0",
                            marginBottom: "0.2rem",
                            cursor: "pointer",
                            fontWeight: "bold",
                            display: "block"
                    }}>
                        { t('fullname') } <sup style={{ color: "var(--danger)" }}>*</sup>
                    </Segment>
                    { properties.map( (property, index) => (
                        <Segment
                            onClick={ () => handleSelection(property.key)}
                            key={index} clearing
                            className={ selected.includes(property.key) ? "segment-hover active" : "segment-hover"}
                            style={{
                                marginTop: "0",
                                marginBottom: "0.2rem",
                                cursor: "pointer",
                                fontWeight: "bold",
                                display: (toExclude.includes(property.key)) ? "none" : "block"
                        }}>
                            { property.name }
                        </Segment>
                    ))}
                </div>
            }
        </>
    );
};

export default ComponentChoices;