import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { useHasPermission } from '@helpers/hooks'
import { setDotSeparator } from '@helpers/functions'
// components
import { Form, Button, Divider, Header, Loader } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import CanView from '@components/perms/CanView'

const RecordForm = ({ setData, setTotal, onClose, record }) => {
    const { t } = useTranslation()
    const params = useParams()

    const [categories, setCategories] = useState([])
    const [isProcessing, setIsProcessing] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [scannedFile, setScannedFile] = useState(null)
    const [uploading, setUploading] = useState(false)
    const [uploadError, setUploadError] = useState(false)

    const canManageIncomes = useHasPermission('cost_centers.c_view_incomes')
    const canManageExpenses = useHasPermission('cost_centers.c_view_expenses')

    const canManageBoth = () => {
        let filterType = ''

        if (canManageIncomes === true && canManageExpenses === false) {
            filterType = '?type=1'
        } else if (canManageIncomes === false && canManageExpenses === true) {
            filterType = '?type=2'
        }

        return filterType
    }

    const [form, setForm] = useState({
        id: record?.id || null,
        title: record?.title || '',
        owner: record?.owner?.id || '',
        cost_type: record?.cost_type || '',
        business_detail: record?.business_detail?.id || '',
        category: record?.category?.id || null,
        amount: record?.amount ? parseFloat(record?.amount?.sum).toFixed(2) : '',
        currency: record?.amount?.currency || 'EUR',
        date: record?.date || moment().format('YYYY-MM-DD'),
    })

    useEffect(() => {
        async function loadCategories() {
            setIsLoading(true)
            const filter = canManageBoth()
            const request = await requests.get(API.COST_CENTERS_RECORD_CATEGORIES + filter)

            if (request.status === 200) {
                setCategories(request.response)
            }
            setIsLoading(false)
        }

        loadCategories()
        // eslint-disable-next-line
    }, [])

    const isAmountValid = (amount) => {
        // check if its number
        if (isNaN(parseFloat(amount)) && amount !== '') return false

        if (amount !== '' && parseFloat(amount) <= 0) return false

        return true
    }

    const handleCategoryOptions = () => {
        let options = []

        options = categories.map((category) => {
            let textValue = category.title + ' (' + category.type_display + ')'

            if (category.code !== '' && category.code !== null) {
                textValue = category.code + ' - ' + category.title + ' (' + category.type_display + ')'
            }

            return {
                id: category.id,
                value: category.id,
                text: textValue,
            }
        })

        return options
    }

    const handleScanUpload = async (record) => {
        if (scannedFile !== null && scannedFile !== undefined) {
            setUploading(true)
            setUploadError(false)
            const formData = new FormData()
            formData.append('file', scannedFile)

            const requestFileUpload = await requests.patch(API.COST_CENTERS_RECORDS + record.id + '/file/', formData)
            if (requestFileUpload.status === 200) {
                setData((prev) =>
                    prev.filter((item) => {
                        if (item.id === record.id) {
                            item.file = requestFileUpload.response.file
                        }

                        return item
                    })
                )
            }
            if (requestFileUpload.status === 400) {
                setUploadError(requestFileUpload.response)
            }
            setUploading(false)
            setUploadError(false)

            return requestFileUpload.status
        }

        return null
    }

    const handleSubmit = async () => {
        setIsProcessing(true)

        const dataset = {
            title: form.title,
            amount: {
                sum: parseFloat(form.amount).toFixed(2),
                currency: form.currency ? form.currency : 'EUR',
            },
            category: form.category ? form.category : '',
            cost_center: params.id,
            cost_type: form.cost_type !== '' ? form.cost_type : null,
            date: form.date,
            owner: form.owner !== '' ? form.owner : null,
            business_detail: form.business_detail !== '' ? form.business_detail : null,
        }

        if (record?.id === undefined) {
            const request = await requests.post(API.COST_CENTERS_RECORDS, dataset)
            if (request.status === 201) {
                setData((prev) => [request.response, ...prev])
                setTotal((prev) => prev + 1)

                const uploadFile = await handleScanUpload(request.response)
                if (uploadFile === 200 || scannedFile === null) {
                    onClose()
                }
            }
        } else {
            const request = await requests.put(API.COST_CENTERS_RECORDS + record.id + '/', dataset)
            if (request.status === 200) {
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === record.id) return request.response
                        return item
                    })
                )

                const uploadFile = await handleScanUpload(request.response)
                if (uploadFile === 200 || scannedFile === null) {
                    onClose()
                }
            }
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group widths={'equal'}>
                <SuperField
                    as="datepicker"
                    required
                    label={t('date')}
                    value={form.date}
                    onChange={(e, { value }) => setForm({ ...form, date: value })}
                />
                <SuperField
                    as="choice"
                    search
                    required
                    label={t('category')}
                    settings="types/cost-centers-record-categories"
                    loading={isLoading}
                    disabled={isLoading}
                    customOptions={handleCategoryOptions()}
                    value={form.category}
                    onChange={(e, { value }) => setForm({ ...form, category: value })}
                />
            </Form.Group>

            <Form.Group widths={'equal'}>
                <SuperField
                    required
                    as="input"
                    label={t('sum')}
                    error={!isAmountValid(form.amount) ? t('invalid_input') : false}
                    value={form.amount}
                    onChange={(e, { value }) => setForm({ ...form, amount: setDotSeparator(value) })}
                />

                <SuperField
                    as="choice"
                    search
                    type="currency_codes"
                    label={t('currency')}
                    value={form.currency}
                    onChange={(e, { value }) => setForm({ ...form, currency: value })}
                />
            </Form.Group>

            <div style={{ fontWeight: 'bold', marginBottom: '0.3rem' }}>{t('cost_type')}</div>
            <Button.Group basic size="tiny" style={{ marginBottom: '1rem' }}>
                <Button
                    type="button"
                    active={form.cost_type === 1}
                    onClick={() => setForm((prev) => ({ ...prev, cost_type: 1 }))}
                >
                    {t('direct')}
                </Button>
                <Button
                    type="button"
                    active={form.cost_type === 2}
                    onClick={() => setForm((prev) => ({ ...prev, cost_type: 2 }))}
                >
                    {t('indirect')}
                </Button>
                <Button
                    type="button"
                    active={form.cost_type === ''}
                    onClick={() => setForm((prev) => ({ ...prev, cost_type: '' }))}
                >
                    {t('unclassified')}
                </Button>
            </Button.Group>

            <SuperField
                as="textarea"
                label={t('note')}
                value={form.title}
                onChange={(e, { value }) => setForm({ ...form, title: value })}
            />

            <Header as="h3" content={t('additional_information')} style={{ marginBottom: '0.5rem' }} />
            <p style={{ color: 'var(--danger)', marginTop: '0', fontWeight: 'bold' }}>
                {' '}
                {t('choose_at_least_one_of_the_following')}{' '}
            </p>
            <Divider />

            <SuperField
                as="choice"
                search
                label={t('assign_to_employee')}
                endpoint={API.EMPLOYEES + '?only_basic_info=true&is_active=true'}
                text="fullname"
                value={form.owner}
                onChange={(e, { value }) => setForm({ ...form, owner: value })}
            />

            <CanView permissions={['accounts.c_view_all_accounts']}>
                <SuperField
                    as="choice"
                    search
                    label={t('assign_to_account')}
                    endpoint={API.ACCOUNTS + 'business_details/?query={id, name}&is_active=true&exclude_unit=true'}
                    text="name"
                    value={form.business_detail}
                    onChange={(e, { value }) => setForm({ ...form, business_detail: value })}
                />
            </CanView>

            <Header as="h3" content={t('document')} style={{ marginBottom: '0.5rem' }} />
            <Divider />
            <SuperField
                as="input"
                type="file"
                width={12}
                help="Allowed files: PDF"
                label={t('upload_scanned_file')}
                onChange={(event) => {
                    if (event.target.files.length > 0) {
                        setScannedFile(event.target.files[0])
                    }
                }}
            />
            {uploading && (
                <div>
                    <Loader size="small" className="dark-loader" active inline /> {t('uploading')}...{' '}
                </div>
            )}
            {uploadError && <p style={{ color: 'var(--danger)', fontWeight: 'bold' }}> {uploadError?.file} </p>}
            {record?.file && (
                <a href={record.file} target="_blank" rel="noopener noreferrer">
                    {record.file}
                </a>
            )}

            <Divider />
            <Form.Group style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={onClose}>{t('cancel')}</Button>
                <Button
                    primary
                    loading={isProcessing}
                    disabled={
                        isProcessing ||
                        !isAmountValid(form.amount) ||
                        form.amount === '' ||
                        form.date === '' ||
                        form.category === '' ||
                        form.category === null ||
                        (form.owner === '' && form.account === '')
                    }
                >
                    {t('add_record')}
                </Button>
            </Form.Group>
        </Form>
    )
}

export default RecordForm
