import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// components
import Icon from '@components/Icon';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Grid, Header, Segment, Divider } from 'semantic-ui-react';
// module specific components
import CreateAccountForm from './forms/CreateAccountForm';

const ConnectByCreatingUser = ({ setView }) => {
    const { t } = useTranslation()
    const [verifyEmail, setVerifyEmail] = useState(false)
    const [email, setEmail] = useState("")

    return (
        <Grid centered>
            <Grid.Row>
                <Grid.Column computer={16} table={16} mobile={16}>
                    <Segment style={{ padding: "2rem" }}>
                    { !verifyEmail 
                        ? 
                        <>
                            <FlexRow background="transparent" padding="0">
                                <FlexItem basis="25%">
                                    <Icon className="ref-link" onClick={() => setView(null)} name="arrow-back-outline"/>
                                </FlexItem>
                                <FlexItem textAlign="right">
                                    <Header as="h4"
                                        style={{ fontSize: "1.3rem" }}
                                        content={ t('connect_by_creating_new') }
                                    />
                                </FlexItem>
                            </FlexRow>
                            <Divider/>
                            <CreateAccountForm 
                                setVerifyEmail={setVerifyEmail}
                                setEmail={setEmail}
                            />
                        </>
                        : 
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <Header as="h3" content={t('email_verification_is_required')}/>
                            <p> { t('we_sent_verification_to_your_email') } <strong>{ email }</strong>. { t('please_confirm_your_email_address') }.</p>
                        </div>
                    }
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};

export default ConnectByCreatingUser;