import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { createRequest, updateRequest } from '@services/ServiceCommon';
// components
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

/* TODO: Save & Add another feature */
const CategoryForm = ({ setResponse, onClose, category, setCategories  }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [formData, setFormData] = useState({
        title: category?.title ? category.title : "",
        parent: category?.parent ? category?.parent : null
    })

    const [parentError, setParentError] = useState(null)

    const handleSubmit = async () => {
        setIsProcessing(true)
        if( category?.id ){ // update
            const request = await updateRequest(API.ASSET_CATEGORIES + category.id + "/", formData)

            if( request.status === 200 ){
                setCategories(prevState => {
                    return {
                        ...prevState,
                        data: prevState.data.filter(filteredCategory => {
                            if( filteredCategory.id === category.id ){
                                filteredCategory.title = request.response.title;
                                filteredCategory.parent = request.response.parent;
                            }
                            
                            return filteredCategory;
                        })
                    }
                })
                onClose()
            } else {
                setParentError(request.response.parent[0])
            }
        } else { // create
            const request = await createRequest(API.ASSET_CATEGORIES, formData)

            if(request.status === 201){
                setResponse({
                    type: "category_create",
                    result: request.response
                })

                onClose()
            } else {
                setParentError(request.response.parent[0])
            }
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="input"
                required
                autoFocus
                label={t('title')}
                value={formData.title}
                onChange={ (e, { value }) => setFormData({
                    ...formData,
                    title: value
                }) }
            />

            <SuperField as="choice"
                label={t('parent')}
                endpoint={API.ASSET_CATEGORIES + "?query={id, title}"}
                text="title"
                exclude={ category?.id ? [category.id] : [] }
                value={formData.parent}
                error={parentError}
                onChange={ (e, { value }) => setFormData({
                    ...formData,
                    parent: value
                }) }
            />

            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || formData.title === ""}
                    text={t('save')} />
            </Form.Field>
        </Form>
    );
};

export default CategoryForm;