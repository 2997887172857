import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests, fileDownload } from '@helpers/requests';
// components
import { Form, Divider, Header, Segment } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const AllowedUserDocumentsForm = ({ onClose, record }) => {
    const { t } = useTranslation()
    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(true)
    const [templates, setTemplates] = useState([])

    async function fetchTemplates(){
        setLoading(true)
        const request = await requests.get(API.TEMPLATING + "?source=vehicles.VehicleAllowedUser&query={id, title}&is_archived=false")
        if (request.status === 200) {
            let template_list = request.response.map(item => ({ checked: false, ...item }))
            if (template_list.length === 1) {
                template_list[0].checked = true
            }
            setTemplates(template_list)
        }
        setLoading(false)
    }

    async function generate(e){
        e.preventDefault();
        setProcessing(true)
        const response = record
        for (let i = 0; i < templates.length; i++) {
            if(templates[i].checked === true){
                const request = await requests.get(API.TEMPLATING + `${templates[i].id}/fill/vehicles.VehicleAllowedUser/${response.id}`)
                if( request.status === 200 ){
                    await fileDownload("POST", API.EXPORTS_HTML_TO_PDF, "application/pdf", `${templates[i].title}-${(i + 1)}.pdf`, {
                        content: request.response.html
                    })
                }
            }
        }
        setProcessing(false)
        onClose()
    }

    useEffect(() => {
        fetchTemplates()
    }, [])

    return (
        <Form onSubmit={generate}>
            <Header as="h4">
                { t('choose_documents') }:
            </Header>

            <Segment loading={loading} style={{ padding: 0, margin: 0, backgroundColor: "transparent", minHeight: "5rem", border: "none", boxShadow: "none" }}>
                { loading && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "8rem" }}> { t('loading_templates') } </p> }
                { !loading && 
                    <>
                        { templates.map(template => (
                            <SuperField as="checkbox" key={template.id}
                                label={t('template') + " - " + template.title}
                                checked={template.checked}
                                onChange={() => setTemplates(prev => prev.filter(item => {
                                    if (item.id === template.id) {
                                        item.checked = !template.checked
                                    }

                                    return item
                                }))}
                            />
                        )) }
                    </>
                }
            </Segment>

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    disabled={processing || templates.length === 0}
                    loading={processing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default AllowedUserDocumentsForm;