import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { requests } from "@helpers/requests";
// components
import { Form, Divider } from "semantic-ui-react";
import SuperField from "@components/forms/SuperField";
import ModalCancel from "@components/buttons/ModalCancel";
import ModalSubmit from "@components/buttons/ModalSubmit";

const AddDateOfPayment = ({ data, endpoint, setData, onClose }) => {
    const { t } = useTranslation();

    const [date, setDate] = useState({
        date_of_payment: null,
    });

    const [isProcessing, setIsProcessing] = useState(false);

    const onSubmit = async () => {
        setIsProcessing(true);

        const request = await requests.patch(API.PAYROLLS + endpoint + data.id + "/", date);

        if (request.status === 200) {
            setData(prev => prev.map(item => {
                if (item.id === data.id) return request.response
    
                return item
            }))
        }

        onClose();
        setIsProcessing(false);
    };

    return (
        <Form onSubmit={onSubmit}>
            <SuperField
                as='datepicker'
                label={t("date_of_payment")}
                value={date.date_of_payment}
                onChange={(e, { value }) => setDate({ ...date, date_of_payment: value })}
            />

            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing}
                    text={t("save")}
                />
            </Form.Field>
        </Form>
    );
};

export default AddDateOfPayment;