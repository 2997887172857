import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { thousandsSeparators } from '@helpers/functions';
import { useIsMount } from '@helpers/hooks';
// components
import Icon from '@components/Icon';
import Paginator from '@components/Paginator';
import EmptyRow from '@components/tables/EmptyRow';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import DatePresets from '@components/DatePresets';
import { FlexTable, FlexRow, FlexItem, FlexHeader } from '@components/tables/FlexTable';
import { Header, Divider, Form, Segment, Icon as SemanticIcon,  Popup, Button, Sidebar, Menu, Ref, List } from 'semantic-ui-react';

const ReceivedInvoices = ({ bankAccount, connection, transactions, setTransactions, onClose }) => {
    const { t } = useTranslation()
    const segmentRef = useRef()
    const isMount = useIsMount()

    const [loading, setLoading] = useState(true)
    const [noAccess, setNoAccess] = useState(false)
    const [noJournalMatched, setNoJournalMatched] = useState(false)
    // eslint-disable-next-line
    const [selectedInvoices, setSelectedInvoices] = useState(transactions)
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [invoices, setInvoices] = useState([])
    // eslint-disable-next-line
    const [total, setTotal] = useState(0)

    const [visible, setVisible] = useState(false) 
    const initialFilters = {
        date_from: "",
        date_to: "",
        date_type: 1,
        number: "",
        name: "",
        registration_id: "",
        tax_id: "",
        vat_id: "",
        ordering: {
            direction: "asc",
            field: "date_due"
        }
    }

    const [filters, setFilters] = useState(initialFilters)
  

    const fetchInvoices = async (params, isFiltered) => {
        params = params || ""
        params += handleFilterOptions()
        setLoading(true)

        // which journal?
        let matchedJournal = null
        const journals = connection.accounts
        for (let i = 0; i < journals.length; i++) {
            if (matchedJournal === null) {
                const requestIbans = await requests.get(API.COMMON + "ibans/?id_number=" + journals[i].id_number)
                if (requestIbans.status === 200){
                    if ( requestIbans.response.find(response => response.iban.replaceAll(" ", "") === bankAccount.accountReference.iban) ){
                        matchedJournal = journals[i]
                    }
                }
            }
        }

        if (matchedJournal !== null) {
            if (params !== undefined){
                const queryParams = params.split("&")
                const pageParam = queryParams.find(item => item.includes('page'))
                if (pageParam) {
                    const splitArray = pageParam.split("=")
                    setPage(splitArray[1])
                }
            }

            const requestInvoices = await requests.get(API.INVOICES + "?connection=" + connection.connection_id + "&account_ids=" + matchedJournal.id + "&paginate=true&approval=1&is_issued=false&status=1" + params)
            if (requestInvoices.status === 200) {
                setInvoices(requestInvoices.response.results)
                setTotal(requestInvoices.response.count)
            } else {
                setNoAccess(true)
            }
        } else {
            setNoJournalMatched(true)
        }

        setLoading(false)
    } 

    useEffect(() => {
        fetchInvoices()
        // eslint-disable-next-line
    }, [])

    useEffect( () => {
        async function onOrderChange(){
            if (!isMount && !loading) {
                setPage(1)
                await fetchInvoices("", true)
            }
        }

        onOrderChange()
        // eslint-disable-next-line
    }, [filters.ordering])

    const handleFilter = async () => {
        setPage(1)
        setVisible(false)
        await fetchInvoices("", true)
    }

    const normalizeValue = (value) => {
        if (value === null) return ""
        if (value === "") return ""
        if (value === undefined) return ""

        value = value.normalize("NFD").replace(/\p{Diacritic}/gu, "") || ""
        value = value.replace(".", "") || ""
        value = value.replace("-", "") || ""
        value = value.replace(";", "") || ""
        value = value.replace("/", "") || ""
        value = value.replace("=", "") || ""
        value = value.replace("%", "") || ""
        value = value.replace("{", "") || ""
        value = value.replace("}", "") || ""
        value = value.replace("@", "") || ""
        value = value.replace("`", "") || ""
        value = value.replace("~", "") || ""

        return value
    }

    const handleFilterOptions = () => {
        let filterBaseParams = ""

        if( filters.date_from !== "" && filters.date_from !== null ){
            if(filters.date_type === 1) filterBaseParams += "&date_issue_after="+filters.date_from
            if(filters.date_type === 2) filterBaseParams += "&date_supply_after="+filters.date_from
            if(filters.date_type === 3) filterBaseParams += "&date_due_after="+filters.date_from
            if(filters.date_type === 4) filterBaseParams += "&date_approved_after="+filters.date_from
        }

        if( filters.date_to !== "" && filters.date_to !== null ){
            if(filters.date_type === 1) filterBaseParams += "&date_issue_before="+filters.date_to
            if(filters.date_type === 2) filterBaseParams += "&date_supply_before="+filters.date_to
            if(filters.date_type === 3) filterBaseParams += "&date_due_before="+filters.date_to
            if(filters.date_type === 4) filterBaseParams += "&date_approved_before="+filters.date_to
        }

        if( filters.number !== "" ){
            filterBaseParams += "&number="+filters.number
        }

        if( filters.name !== "" ){
            filterBaseParams += "&supplier_name="+filters.name
        }

        if( filters.registration_id !== "" ){
            filterBaseParams += "&supplier_registration_id="+filters.registration_id
        }

        if( filters.tax_id !== "" ){
            filterBaseParams += "&supplier_tax_id="+filters.tax_id
        }

        if( filters.vat_id !== "" ){
            filterBaseParams += "&supplier_vat_id="+filters.vat_id
        }

        if (filters.ordering){
            if (filters.ordering.direction === "asc" ){
                filterBaseParams += "&ordering=" + filters.ordering.field
            } else {
                filterBaseParams += "&ordering=-" + filters.ordering.field
            }
        }

        return filterBaseParams
    }

    const handleExisting = (prev) => {
        return prev.filter(transactions => transactions.invoice_reference === selectedInvoices.find(item => item.invoice_reference === transactions.invoice_reference)?.invoice_reference)
    }
    const handleSelected = (prev) => {
        return selectedInvoices.filter(invoice => invoice.invoice_reference !== prev.find(item => item.invoice_reference === invoice.invoice_reference)?.invoice_reference)
    }

    const confirmSelection = () => {
        setTransactions(prev => [...handleExisting(prev), ...handleSelected(prev)])
        onClose()
    }

    const onSelectAll = () => {
        const invoiceIds = invoices.map(invoice => invoice.id)
        if (invoices.filter(invoice => invoice.id === selectedInvoices.find(item => item.invoice_reference === invoice.id)?.invoice_reference).length === invoices.length){
            // all selected
            setSelectedInvoices(prev => prev.filter(selected => !invoiceIds.includes(selected.invoice_reference)))
        } else {
            const newSelected = invoices.filter(invoice => invoice.id !== selectedInvoices.filter(item => item.invoice_reference === invoice.id)?.invoice_reference)
            setSelectedInvoices(prev => [...prev, ...newSelected.map(selected => convertInvoiceToTransaction(selected))])
        }
    }

    const convertInvoiceToTransaction = (invoice) => {
        const reference = "/VS" + (invoice.variable_symbol || "") + "/SS" + (invoice.specific_symbol || "") + "/KS" +  (invoice.constant_symbol || "")

        return {
            id: 0,
            end_to_end_reference: reference,
            currency: bankAccount.accountReference.currency,
            amount: calculate_diff(invoice),
            receiver_bic: invoice.bank_swift_bic,
            receiver_name: normalizeValue(invoice.supplier_name),
            receiver_iban: invoice.bank_iban?.replaceAll(" ", "") || "",
            receiver_note: "",
            invoice_reference: invoice.id,
            variable_symbol: invoice.variable_symbol || "",
            constant_symbol:  invoice.constant_symbol || "",
            specific_symbol:  invoice.specific_symbol || ""
        }
    }

    const onInvoiceSelection = (invoice) => {
        if (selectedInvoices.find(selectedInvoice => selectedInvoice.invoice_reference === invoice.id )){
            // remove
            setSelectedInvoices(prev => prev.filter(selectedInvoice => selectedInvoice.invoice_reference !== invoice.id));
        } else {
            
            setSelectedInvoices(prev => [...prev, convertInvoiceToTransaction(invoice)])
        }
    }

    const isUsedInRequest = (invoice) => {
        if (invoice.payments.length > 0) {
            if (invoice.payments.filter(payment => ['DRAFT', 'PDNG', 'ACTC', 'ACSP'].includes(payment.status)).length > 0) {
                return true
            }
        }

        return false
    }

    const existingTransactions = (invoice) => {
        let existingTransactions = []
        existingTransactions = invoice.payments.filter(payment => ['DRAFT', 'PDNG', 'ACTC', 'ACSP'].includes(payment.status))

        return existingTransactions
    }

    const calculate_diff = (invoice) => {
        const transactions = existingTransactions(invoice)
        let price_to_pay = parseFloat(invoice.price_to_pay)

        for (let i = 0; i < transactions.length; i++) {
            price_to_pay -= parseFloat(transactions[i].amount)
        } 

        return parseFloat(price_to_pay).toFixed(2)
    }

    const IconMark = ({ icon, color, title }) => {
        return (
            <Popup content={title} position='top center' trigger={
                <Icon name={icon} style={{ color: color, cursor: "pointer", marginLeft: "0.5rem", fontSize: "1.2rem"}}/>
            }/>
        )
    }

    return (
        <Sidebar.Pushable as={Segment} style={{ border: "none", background: "transparent", boxShadow: "none", padding: 0, margin: 0, minHeight: "10rem" }}>
            <Sidebar  
                direction="right"
                style={{ width: "40rem" }}
                as={Menu}
                animation='overlay'
                icon='labeled'
                vertical
                visible={visible}
                onHide={() => setVisible(false)}
                target={segmentRef}
            >
                <Form onSubmit={(e) => e.preventDefault()} style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "left" }}>
                    <List divided verticalAlign='middle'>
                        <List.Item>
                            <List.Content>
                                <Header as="h2" content={ t('filters') } style={{ marginTop: "1rem", paddingLeft: "1rem", textAlign: "left" }}/>
                            </List.Content>
                        </List.Item>
                    </List>
                    <Divider/>
                    <Form.Group widths="equal">
                        <SuperField as="datepicker"
                            clearable
                            label={ t('date_from') }
                            value={ filters.date_from } 
                            onChange={ (e, { value }) => { 
                                if( value === "" ) {
                                    setFilters({ ...filters, date_from: null })
                                } else {
                                    setFilters({ ...filters, date_from: value })
                                }

                            }}
                        />
                        <SuperField as="datepicker"
                            clearable
                            label={ t('date_to') }
                            value={ filters.date_to } 
                            onChange={ (e, { value }) => setFilters({ ...filters, date_to: value })}
                        />
                    </Form.Group>
                    <DatePresets from={filters.date_from} to={filters.date_to} onSelection={ (firstDay, lastDay) => setFilters(prev => ({
                        ...prev, 
                        date_from: firstDay,
                        date_to: lastDay
                    }))}/>

                    <p style={{ fontWeight: 'bold', fontSize: "1.2rem" }}>{ t('select_date_to_be_used_in_filter') }:</p>
                    <Form.Group widths="equal">
                        <SuperField as="radio"
                            label={t('date_issue')}
                            checked={ filters.date_type === 1 }
                            onChange={ () => setFilters({ ...filters, date_type: 1 }) }
                        />
                        <SuperField as="radio"
                            label={t('date_supply')}
                            checked={ filters.date_type === 2 }
                            onChange={ () => setFilters({ ...filters, date_type: 2 }) }
                        />
                        <SuperField as="radio"
                            label={t('date_due')}
                            checked={ filters.date_type === 3 }
                            onChange={ () => setFilters({ ...filters, date_type: 3 }) }
                        />
                        <SuperField as="radio"
                            label={t('approval_date')}
                            checked={ filters.date_type === 4 }
                            onChange={ () => setFilters({ ...filters, date_type: 4 }) }
                        />
                    </Form.Group>

                    <Divider/>

                    <SuperField as="input"
                        label={ t('supplier_name') }
                        value={ filters.name }
                        onChange={ (e, { value }) => setFilters({...filters, name: value}) }
                    />
                    <Form.Group widths="equal">
                        <SuperField as="input"
                            label={ t('invoice_number') }
                            value={ filters.number }
                            onChange={ (e, { value }) => setFilters({...filters, number: value}) }
                        />
                       <SuperField as="input"
                            label={ t('registration_id') }
                            value={ filters.registration_id }
                            onChange={ (e, { value }) => setFilters({...filters, registration_id: value}) }
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField as="input"
                            label={ t('tax_id') }
                            value={ filters.tax_id }
                            onChange={ (e, { value }) => setFilters({...filters, tax_id: value}) }
                        />
                        <SuperField as="input"
                            label={ t('vat_id') }
                            value={ filters.vat_id }
                            onChange={ (e, { value }) => setFilters({...filters, vat_id: value}) }
                        />
                    </Form.Group>

                    <Button type="button" size="small" primary onClick={handleFilter}> {t('apply')} </Button>
                </Form>
            </Sidebar>
            <Sidebar.Pusher>
                <Ref innerRef={segmentRef}>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <Header as="h3" content={t('select_received_invoices_to_pay')}/>
                        <Divider/>

                        <Segment 
                            loading={loading}
                            style={{ 
                                padding: 0, 
                                background: "transparent", 
                                boxShadow: "none", 
                                border: "none",
                                marginBottom: "1rem",
                                marginTop: "1rem",
                            }}
                        >
                            { loading && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "6rem" }}> { t('loading_invoices') } </p> }
                            { !loading && 
                                <>
                                    { (noJournalMatched || noAccess) && (
                                        <div style={{ fontSize: "1.3rem", fontWeight: "bold", textAlign: "center" }}>
                                            <Icon name="alert-circle-outline" style={{ color: "var(--danger)", fontSize: "3rem" }}/> <br/>
                                            { noJournalMatched && 
                                                <p>
                                                    {t('no_match_between_bank_and_journal_error_message')} <br/>
                                                    {t('no_match_between_bank_and_journal_hint_message')}
                                                </p>
                                            }
                                            { noAccess && 
                                                <p> { t('you_dont_have_permission_to_see_this_journal') } </p>
                                            }
                                        </div>
                                    )}

                                    { (!noJournalMatched && !noAccess) && 
                                        <>
                                            <FlexRow>
                                                <FlexItem textAlign="right">
                                                    <Button.Group size="small">
                                                        <Form onSubmit={(e) => e.preventDefault()}>
                                                            <SuperField as="choice" size="small"
                                                                style={{ width: "150px" }}
                                                                value={filters.ordering.field}
                                                                clearable={false}
                                                                onChange={(e, { value }) => setFilters(prev => ({...prev, ordering: {
                                                                    ...prev.ordering,
                                                                    field: value
                                                                }}))}
                                                                customOptions={[
                                                                    { key: 1, value: "created_at", text: t('created_at')},
                                                                    { key: 2, value: "date_approved", text: t('date_of_approval')},
                                                                    { key: 3, value: "date_issue", text: t('date_of_issue')},
                                                                    { key: 4, value: "date_supply", text: t('date_of_supply')},
                                                                    { key: 5, value: "date_due", text: t('date_due')},
                                                                    { key: 7, value: "number", text: t('invoice_number')},
                                                                ]}
                                                            />
                                                        </Form>
                                                        <Button
                                                            type="button"
                                                            style={{ background: "var(--dark)", color: "var(--white)" }}
                                                            title={filters.ordering.direction === "asc" ? t('ascending') : t('descending')}
                                                            onClick={ () => setFilters(prev => ({...prev, ordering: {
                                                                ...prev.ordering,
                                                                direction: filters.ordering.direction === "asc" ? "desc" : "asc"
                                                            }})) }

                                                            icon={
                                                                <SemanticIcon name={filters.ordering.direction === "asc" ? "sort amount up" : "sort amount down"}/>
                                                            }
                                                        />
                                                        <Button type="button"
                                                            icon={<Icon name="funnel-outline"/>}
                                                            style={{ marginLeft: "1rem", fontSize: "1.1rem", background: "transparent" }}
                                                            onClick={() => setVisible(!visible)}
                                                        />
                                                    </Button.Group>
                                                </FlexItem>
                                            </FlexRow>
                                            <FlexTable>
                                                <FlexRow fontSize="0.9rem">
                                                    <FlexHeader basis="20%" content={
                                                        <SuperField as="checkbox" 
                                                            checked={ invoices.filter(invoice => invoice.id === selectedInvoices.find(item => item.invoice_reference === invoice.id)?.invoice_reference).length === invoices.length }
                                                            onClick={ () => onSelectAll() }
                                                        />
                                                    }/>
                                                    <FlexHeader basis="60%" content={t('invoice_number')}/>
                                                    <FlexHeader content={t('supplier')}/>
                                                    <FlexHeader content={t('iban') + "/" + t('bic')}/>
                                                    <FlexHeader content={t('dates')}/>
                                                    <FlexHeader content={t('reference')}/>
                                                    <FlexHeader basis="80%" content={t('price_to_pay')}/>
                                                </FlexRow>
                                                <EmptyRow length={invoices.length}/>
                                                { invoices.map(invoice => (
                                                    <FlexRow background="transparent" fontSize="0.9rem" borders>
                                                        <FlexItem basis="20%" content={
                                                            <SuperField as="checkbox" 
                                                                checked={selectedInvoices.find(selectedInvoice => selectedInvoice.invoice_reference === invoice.id) === undefined ? false : true }
                                                                onChange={() => onInvoiceSelection(invoice)}
                                                            />
                                                        }/>
                                                        <FlexItem basis="60%">
                                                            { invoice.number } <IconMark icon="checkmark-circle" color="var(--success)" title={t('approved') + (invoice.date_approved !== null && " - " + moment(invoice.date_approved).format("DD.MM.YYYY")) }/>
                                                        </FlexItem>
                                                        <FlexItem>
                                                            { invoice.supplier_name } <br/>
                                                            <strong style={{ fontSize: "1.2rem" }}>{ invoice.supplier_registration_id }</strong>
                                                        </FlexItem>
                                                        <FlexItem>
                                                            <strong>{ invoice.bank_iban || "" }</strong> <br/> 
                                                            { invoice.bank_swift_bic || ""}
                                                        </FlexItem>
                                                        <FlexItem>
                                                            <span>{t('issue_invoice_date')}:</span> <strong> { invoice.date_issue !== null ? moment(invoice.date_issue).format("DD.MM.YYYY") : "--" }   </strong> <br/>
                                                            <span>{t('supply_invoice_date')}:</span> <strong> { invoice.date_supply !== null ? moment(invoice.date_supply).format("DD.MM.YYYY") : "--" } </strong> <br/>
                                                            <span>{t('due_to_invoice_date')}:</span> <strong> { invoice.date_due !== null ? moment(invoice.date_due).format("DD.MM.YYYY") : "--" }  </strong>
                                                        </FlexItem>
                                                        <FlexItem>
                                                            { "VS: " + (invoice.variable_symbol || "") } <br/>
                                                            { "SS: " + (invoice.specific_symbol || "") } <br/>
                                                            { "KS: " + (invoice.constant_symbol || "") }
                                                        </FlexItem>
                                                        <FlexItem bold basis="80%">
                                                            <div style={{ fontSize: "1.2rem" }}>
                                                                <span style={{ paddingRight: "1rem" }}>{ thousandsSeparators(calculate_diff(invoice)) } { invoice.currency }</span>
                                                                { isUsedInRequest(invoice) === true && <IconMark icon="information-circle" color="var(--dark)" title={
                                                                    <div>
                                                                        <strong style={{ fontSize: "1.2rem" }}>{ t('original_sum') }: { thousandsSeparators(invoice.price_to_pay) } {invoice.currency}</strong>
                                                                        <Divider/>
                                                                        <div>
                                                                            <strong>{ t('existing_transactions') }:</strong><br/>
                                                                            { existingTransactions(invoice).map(transaction => (
                                                                                <>
                                                                                    <span> 
                                                                                        { moment(transaction.date_for_payment).format("DD.MM.YYYY") }  - <strong>{ transaction.amount } { invoice.currency }</strong> - {" "}
                                                                                        { transaction.status === "DRAFT" && t('draft') }
                                                                                        { transaction.status === "ACSC" && t('completed') }
                                                                                        { ['PDNG', 'ACTC', 'ACSP'].includes(transaction.status) && t('processing_payment') }
                                                                                    </span><br/>
                                                                                </>
                                                                            )) }
                                                                        </div>
                                                                    </div>
                                                                }/> }
                                                            </div>
                                                        </FlexItem>
                                                    </FlexRow>
                                                ))}
                                            </FlexTable>
                                            <Paginator
                                                forcePage={page || 1}
                                                forceReload={false}
                                                limit={limit}
                                                setLimit={setLimit}
                                                length={total || 0}
                                                onChange={(params) => fetchInvoices(params)}
                                            />
                                        </>
                                    }
                                    
                                </>
                            }
                        </Segment>

                        <Divider/>
                        <Form.Field style={{ textAlign: 'right' }}>
                            <ModalCancel onClose={onClose}/>
                            <ModalSubmit type="button" onClick={() => confirmSelection()} text={t('confirm')}/>
                        </Form.Field>
                    </Form>
                </Ref>
            </Sidebar.Pusher>
        </Sidebar.Pushable>
    );
};

export default ReceivedInvoices;