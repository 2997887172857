import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Divider, Form } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalSubmit from '@components/buttons/ModalSubmit';
import ModalCancel from '@components/buttons/ModalCancel';

const RecordForm = ({ record, setData, onClose, setTotal, employees }) => {
    const { t } = useTranslation();

    const [form, setForm] = useState({
        course: record ? record.course.id : null,
        person: record ? record.person.id : null,
        made_on: record ? record.made_on : '',
        valid_from: record ? record.valid_from : '',
        valid_until: record ? record.valid_until : '',
        failed: record ? record.failed : false,
    });

    const [isFailed, setIsFailed] = useState(form.failed);
    const [isProcessing, setIsProcessing] = useState(false);
    const [invalidDateRange, setInvalidDateRange] = useState(false);
    const [courseIsMandatory, setCourseIsMandatory] = useState(false);

    // Validate Form
    useEffect(() => {
        if (form.valid_until !== '' && form.valid_from) {
            if (moment(form.valid_from).isAfter(form.valid_until)) {
                setInvalidDateRange(t('invalid_date_should_be_higher'));
            } else {
                setInvalidDateRange(false);
            }
        }
        // eslint-disable-next-line
    }, [form]);

    const fetchCourse = async (id) => {
        if (id !== null && id > 0) {
            // avoid calling API when no course is being selected
            const request = await requests.get(API.COURSES + id + '/?query={id, is_mandatory}');

            if (request.status === 200) {
                setCourseIsMandatory(request.response.is_mandatory);
            }
        }
    };

    useEffect(() => {
        fetchCourse(form.course);
    }, [form.course]);

    const onSubmit = async () => {
        setIsProcessing(true);

        const formForSubmittion = {
            ...form,
            made_on: form.made_on !== '' ? form.made_on : null,
            valid_from: form.valid_from !== '' ? form.valid_from : null,
            valid_until: form.valid_until !== '' ? form.valid_until : null,
        };

        if (record?.id) {
            const request = await requests.put(API.COURSES_RECORDS + record.id + '/', formForSubmittion);
            if (request.status === 200) {
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === record.id) {
                            item = request.response;
                        }

                        return item;
                    })
                );
            }
        } else {
            const request = await requests.post(API.COURSES_RECORDS, formForSubmittion);
            if (request.status === 201) {
                setData((prev) => [request.response, ...prev]);
                setTotal((prev) => prev + 1);
            }
        }

        onClose();
        setIsProcessing(false);
    };

    return (
        <Form onSubmit={onSubmit}>
            <SuperField
                search
                as="choice"
                required
                label={t('courses')}
                endpoint={API.COURSES + '?query={id, title}'}
                value={form.course}
                text="title"
                onChange={(e, { value }) => setForm({ ...form, course: value })}
            />
            <Form.Group widths="equal">
                <SuperField
                    as="choice"
                    search
                    required
                    label={t('employee')}
                    value={form.person}
                    customOptions={employees}
                    onChange={(e, { value }) => setForm({ ...form, person: value })}
                />
                <SuperField
                    as="datepicker"
                    required
                    label={t('made_on')}
                    value={form.made_on}
                    onChange={(e, { value }) => setForm({ ...form, made_on: value })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="datepicker"
                    required={courseIsMandatory}
                    label={t('valid_from')}
                    value={form.valid_from}
                    onChange={(e, { value }) => setForm({ ...form, valid_from: value })}
                />
                <SuperField
                    as="datepicker"
                    required={courseIsMandatory}
                    label={t('valid_until')}
                    error={invalidDateRange}
                    value={form.valid_until}
                    onChange={(e, { value }) => setForm({ ...form, valid_until: value })}
                />
            </Form.Group>

            <SuperField
                as="checkbox"
                label={t('failed')}
                checked={form.failed}
                onChange={(e) => {
                    setIsFailed(!isFailed);
                    setForm({ ...form, failed: !isFailed });
                }}
            />

            <Divider />

            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} text={t('cancel')} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || form.course === '' || form.participants === 0}
                    text={t('save')}
                />
            </Form.Field>
        </Form>
    );
};

export default RecordForm;
