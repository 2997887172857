import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { isEmailValid } from '@services/helpers/validation';
// components
import { Form, Divider, Message } from 'semantic-ui-react'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import EmailField from '@components/forms/common/EmailField'
import SuperField from '@components/forms/SuperField'

const UserDemoForm = ({ setResult, onClose }) => {
    const { t } = useTranslation()

    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [allowToChangeUsername, setAllowToChangeUsername] = useState(false)
    const [form, setForm] = useState({
        email: '',
        username: '',
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        setErrors(null)
        const request = await requests.post(API.USERS + 'demo/', {
            email: form.email,
            username: form.username,
            first_name: 'Demo',
            last_name: 'user',
            phone: '09999999999',
            gender: 3,
        })

        if (request.status === 400) setErrors(request.response)
        if (request.status === 201) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: 'Pozvánka odoslaná',
                description: 'Po potvrdení bude vytvorený prístup.',
                animation: 'bounce',
                time: 5000,
            })
            onClose()
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            {errors !== null && (
                <Message visible error>
                    <strong>{t('error_occured')}</strong>
                    <br />
                    {errors?.detail && <div>- {errors?.detail}</div>}
                    {errors?.non_field_errors?.[0] && <div>- {errors?.non_field_errors?.[0]}</div>}
                    {errors?.username?.[0] && <div>- {errors?.username?.[0]}</div>}
                    {errors?.email?.[0] && <div>- {errors?.email?.[0]}</div>}
                </Message>
            )}
            <Form.Group widths="equal">
                <EmailField
                    autoFocus
                    required
                    label={t('email')}
                    value={form.email}
                    setValue={(e, { value }) =>
                        setForm({
                            ...form,
                            username: value.split('@')[0],
                            email: value,
                        })
                    }
                />
            </Form.Group>

            <Form.Field>
                { t("username") }: <strong>{form.username === "" ? t('not_specified').toLowerCase() : form.username}</strong>
            </Form.Field>

            <SuperField as="checkbox"
                label={t('allow_to_set_username_manually')}
                checked={allowToChangeUsername}
                onChange={(e, { value }) => setAllowToChangeUsername(!allowToChangeUsername)}
            />

            { allowToChangeUsername && 
                <SuperField as="input"
                    label={t('username')}
                    value={form.username}
                    onChange={(e, { value }) => setForm(prev => ({...prev, username: value}))}
                />
            }

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit disabled={isProcessing || form.email === "" || form.username === "" || !isEmailValid(form.email)} loading={isProcessing} text={t('confirm')} />
            </Form.Field>
        </Form>
    )
}

export default UserDemoForm
