import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
import { routes } from '@routes';
// Components
import Icon from '@components/Icon';
import EmptyRow from '@components/tables/EmptyRow';
import { Grid, Label, Header, Segment, Popup } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';

const BudgetDetailView = ({ budget }) => {
    const { t } = useTranslation()
    const [forecast, setForecast] = useState({})

    let totalIncomes = 0
    let totalExpenses = 0

    const costCenters = budget.cost_centers
    for (let i = 0; i < costCenters.length; i++) {
        totalIncomes += parseFloat(costCenters[i].total_incomes.find(item => item.currency === "EUR")?.sum || 0);
        totalExpenses += parseFloat(costCenters[i].total_expenses.find(item => item.currency === "EUR")?.sum || 0);
    }

    useEffect(() => {
        async function fetchForecast(){
            const request = await getRequest(API.BUDGETS_FORECAST + budget.id + "/?currency=EUR")

            if( request.status === 200 ){
                setForecast(request.response)
            }
        }

        fetchForecast()
        // eslint-disable-next-line
    }, [])

    const ForecastStatus = ({ forecast }) => {
        let used = parseFloat(budget.amount.sum);

        if( forecast?.over_budget !== null ){
            used += parseFloat(Math.abs(forecast.over_budget))
        } else {
            used -= parseFloat(forecast.remaining_amount)
        }

        const usedPercent = (parseFloat(used)/parseFloat(budget.amount.sum)) * 100
        const remainingPercent = (parseFloat(forecast.remaining_amount)/parseFloat(budget.amount.sum)) * 100

        let budgetAmount = parseFloat(budget.amount.sum)
        let overBudget = parseFloat(Math.abs(forecast?.over_budget))
        const baseBudgetPercent = (parseFloat(budgetAmount)/parseFloat(budgetAmount+overBudget)) * 100
        const overBudgetPercent = (parseFloat(overBudget)/parseFloat(budgetAmount+overBudget)) * 100

        const BarLine = ({ width, lineColor, textColor, text }) => {
            return (
                <div style={{ float: "left", textAlign: "center", fontWeight: "bold", width: width, height: "2rem", paddingTop: "0.2rem", color: textColor, backgroundColor: lineColor }}>
                    <small style={{ padding: "1rem" }}>
                        { text }
                    </small>
                </div>
            )
        }

        return (
            <Popup
                position='bottom center'
                content={
                    <>
                    { forecast?.over_budget === null 
                        ?
                            <>
                                { t('used') }: <strong>{ parseFloat(used).toFixed(2) + " EUR" }</strong> <br/>
                                { t('remaining') }: <strong>{ parseFloat(forecast?.remaining_amount).toFixed(2) + " EUR" }</strong> <br/>
                            </>
                        :
                        <>
                            { t('budget') }: <strong>{ parseFloat(budget?.amount?.sum).toFixed(2) + " EUR" }</strong> <br/>
                            { t('over_budget') }: <strong>{ parseFloat(overBudget).toFixed(2) + " EUR" }</strong> <br/>
                        </>
                    }
                    </>
                }
                trigger={
                    <Segment loading={forecast?.remaining_amount === undefined} style={{ display: "inline-block", width: "100%", padding: 0 }}>
                        { forecast?.over_budget === null 
                            ? 
                            <>
                                <BarLine text={ t('used') } lineColor="var(--success)" textColor="var(--black)" width={usedPercent.toFixed(2).toString() + "%"} />
                                <BarLine text={ t('remaining') } lineColor="var(--light-grey)" textColor="var(--black)" width={remainingPercent.toFixed(2).toString() + "%"} />
                            </>
                            : 
                            <>
                                <BarLine text={ t('budget') } lineColor="var(--success)" textColor="var(--white)" width={baseBudgetPercent.toFixed(2).toString() + "%"} />
                                <BarLine text={ t('over_budget') } lineColor="var(--danger)" textColor="var(--black)" width={overBudgetPercent.toFixed(2).toString() + "%"} />
                            </>
                        }
                    </Segment>
                }
            />
        )
    }

    return (
        <Grid>
            <Grid.Row style={{ borderBottom: "1px solid var(--light-grey)" }}>
                <Grid.Column>
                    <Header as="h3" content={ budget.name }/>
                    <Label basic style={{ fontSize: "1.3rem" }}>
                        <Icon name="timer-outline"/> { " " }
                        <strong>
                            { budget?.date_from && moment(budget?.date_from).format("DD.MM.YYYY") }
                            { budget?.date_from && budget?.date_to ? " - " : "" }
                            { budget?.date_to && moment(budget?.date_to).format("DD.MM.YYYY") }
                        </strong>
                    </Label> 
                    <Label basic style={{ fontSize: "1.3rem", fontWeight: "bold"}}>
                        { parseFloat(budget?.amount?.sum).toFixed(2) + " " + budget?.amount?.currency }
                    </Label>
                    <Label basic style={{ fontSize: "1.3rem" }}> 
                        <Icon name="business-outline"/> { budget?.cost_centers.length }
                    </Label>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row style={{ marginTop: "1rem" }}>
                <Grid.Column computer={16} tablet={16} mobile={16}>
                    <FlexRow background="transparent" padding="0">
                        <FlexItem>
                            <Header as="h3" content={ t('current_state') } style={{ marginBottom: 0 }}/>
                        </FlexItem>
                        <FlexItem textAlign="right">
                            { (totalIncomes !== 0 || totalExpenses !== 0) && 
                                <>
                                    <strong>{ t('over_budget') }:</strong> { forecast?.over_budget !== null ? t('yes'): t('no') } 
                                    <span style={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }}> | </span> 
                                    <strong>{ t('remaining_days') }:</strong> { forecast?.remaining_days }
                                </>
                            }
                        </FlexItem>
                    </FlexRow>
                    { totalIncomes === 0 && totalExpenses === 0 
                        ? <div style={{ textAlign: "center", fontWeight: "bold" }}>{ t('no_incomes_expenses') }</div>
                        : <ForecastStatus forecast={forecast}/>
                    }

                    <div style={{ fontSize: "1.3rem" }}>
                        <strong>{ t('forecast_status') }: </strong>
                        <Label basic style={{ 
                            background: forecast.is_over_budget ? "var(--danger)" : "var(--success)", 
                            color: forecast.is_over_budget ? "var(--white)" : "var(--black)" 
                        }}>
                            { forecast.is_over_budget ? t('expecting_over_budget') : "OK" }
                        </Label>
                    </div>
                </Grid.Column>
                <Grid.Column style={{ marginTop: "2rem" }} computer={16} tablet={16} mobile={16}>
                    <FlexTable>
                        <FlexRow borders>
                            <FlexHeader content={t('title')}/>
                            <FlexHeader textAlign="right">
                                { t('incomes') } <small>(EUR)</small>
                            </FlexHeader>
                            <FlexHeader textAlign="right">
                                { t('expenses') } <small>(EUR)</small>
                            </FlexHeader>
                        </FlexRow>
                        <EmptyRow length={budget.cost_centers?.length}/>
                        { budget.cost_centers.map(costCenter => (
                            <FlexRow fontSize="1rem" borders key={costCenter.id}>
                                <FlexItem>
                                    <Link 
                                        target="_blank"
                                        className="ref-link"
                                        to={routes.COST_CENTERS + "detail/" + costCenter.id} 
                                    >
                                        { costCenter.title }
                                    </Link>
                                </FlexItem>
                                <FlexItem textAlign="right">
                                    <span style={{ color: "var(--success)" }}>
                                        + { parseFloat(costCenter.total_incomes.find(item => item.currency === "EUR")?.sum || 0).toFixed(2) }
                                    </span>
                                </FlexItem>
                                <FlexItem textAlign="right">
                                    <span style={{ color: "var(--danger)" }}>
                                        - { parseFloat(costCenter.total_expenses.find(item => item.currency === "EUR")?.sum || 0).toFixed(2) }
                                    </span>
                                </FlexItem>
                            </FlexRow>
                        )) }

                        <FlexRow fontSize="1rem" borders>
                            <FlexItem bold content={t('total')}/>
                            <FlexItem bold textAlign="right">
                                { parseFloat(totalIncomes).toFixed(2) }
                            </FlexItem>
                            <FlexItem bold  textAlign="right">
                                { parseFloat(totalExpenses).toFixed(2) }
                            </FlexItem>
                        </FlexRow>
                    </FlexTable>
                    <p style={{ marginLeft: "1.1rem", marginTop: "1rem"  }}><strong>{ t('total_records') }:</strong> { budget.cost_centers.length }</p>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default BudgetDetailView;