import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import { Popup, Icon as SemanticIcon } from 'semantic-ui-react';

const ApprovalStatusIndicator = ({ invoice, connection }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector(state => state.date_format)

    const [isFetching, setIsFetching] = useState(false)
    const [status, setStatus] = useState({
        approval: invoice?.approval || null,
        date_approved: invoice?.date_approved || null
    })

    const syncStatus = async (status, date) => {
        const request = await requests.patch(API.INVOICES + invoice.id + "/?connection=" + connection.connection_id, {
            approval: status,
            date_approved: date || null
        })

        if (request.status === 200) {
            setStatus({
                approval: status,
                date_approved: date || null
            })
        }
    }

    useEffect( () => {
        async function fetchApprovals(){
            setIsFetching(true)
            const request = await requests.get(API.APPROVALS + "invoices/?invoice_reference=" + (invoice.id + ";" + invoice.number))
            if ( request.status === 200 ){
                if ( request.response.length > 0) {
                    if ( request.response[0].datetime_approval !== null && invoice.date_approved === null && request.response[0].is_approved === true ){
                        await syncStatus(1, moment(request.response[0].approval_datetime).format("YYYY-MM-DD")) // approved
                    }

                    // Sync statuses with invoices
                    if ( request.response[0].is_approved === true && invoice.approval !== 1 ) {
                        await syncStatus(1, moment(request.response[0].approval_datetime).format("YYYY-MM-DD")) // approved
                    } else if (request.response[0].is_approved === false && invoice.approval !== 3){
                        await syncStatus(3) // denied
                    } else if (request.response[0].is_approved === null && invoice.approval !== 2){
                        await syncStatus(2) // awaiting approval
                    }
                }
            }

            setIsFetching(false)
        }

        if (invoice?.id !== undefined && [1, 3].includes(invoice.approval) === false) {
            fetchApprovals()
        }
        // eslint-disable-next-line
    }, [])

    const IconMark = ({ icon, color, title }) => {
        return (
            <Popup content={title} position='top center' trigger={
                <Icon name={icon} style={{ color: color, cursor: "pointer", fontSize: "1.2rem", position: "relative", top: "0.3rem" }}/>
            }/>
        )
    }

    return (
        <span style={{ marginLeft: "0.5rem" }}>
            { isFetching
                ? <SemanticIcon name="spinner" loading={isFetching}/>
                :
                <>
                    { status?.approval === 2 && <IconMark icon="help-circle" color="orange" title={t('awaiting_approval')}/> }
                    { status?.approval === 1 && <IconMark icon="checkmark-circle" color="var(--success)" title={t('approved') + (status.date_approved !== null && " - " + moment(status.date_approved).format(dateFormat)) }/> }
                    { status?.approval === 3 && <IconMark icon="warning" color="var(--danger)" title={t('denied')}/> }
                </>
            }
        </span>
    )
}

export default ApprovalStatusIndicator;