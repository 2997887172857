import React from 'react';
import { useTranslation } from "react-i18next";
// components
import Icon from '@components/Icon';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Grid, Header, Segment, Divider } from 'semantic-ui-react';
// module specific components
import ConnectionLoginForm from './forms/ConnectionLoginForm';

const ConnectByExistingUser = ({ setView, connected, setRefetch }) => {
    const { t } = useTranslation()

    return (
        <Grid centered>
            <Grid.Row>
                <Grid.Column computer={12} table={16} mobile={16}>
                    { connected && 
                        <p style={{ fontWeight: "bold", fontSize: "1.2rem", textAlign: "center"}}>
                            <div style={{ color: "var(--blue)", textTransform: "uppercase", fontSize: "1.5rem", width: "100%", paddingBottom: "0.8rem" }}> { t('verification_was_successfull') }!</div>
                            <div style={{ fontSize: "1.3rem", width: "100%" }}>
                                { t('please_sign_in_to_finish_your_connection_process') }.
                            </div>
                        </p>
                    }

                    <Segment style={{ padding: "2rem" }}>
                        { connected === true ? "" : 
                            <>
                                <FlexRow background="transparent" padding="0">
                                    <FlexItem basis="25%">
                                        <Icon className="ref-link" onClick={() => setView(null)} name="arrow-back-outline"/>
                                    </FlexItem>   
                                    <FlexItem textAlign={ connected ? "center" : "right" }>
                                        <Header as="h4"
                                            style={{ fontSize: "1.3rem" }}
                                            content={ t('connect_with_existing') }
                                        />
                                    </FlexItem>
                                </FlexRow>
                                <Divider/>
                            </>
                        }
                        <ConnectionLoginForm setRefetch={setRefetch}/>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};

export default ConnectByExistingUser;