import React from 'react';

import { useTranslation } from 'react-i18next';

import { Divider, Loader, Header } from 'semantic-ui-react';
import MilestonesStats from './stats/MilestonesStats';
import MembersStats from './stats/MembersStats';
import Overall from './stats/Overall';

const ProjectStatistics = ({ stats }) => {
    const { t } = useTranslation()
    return (
        < >
            {stats?.isLoading ?
                <Loader active={stats?.isLoading} />
                :
                <>
                    <Header content={t('overall')} as="h2" />
                    <Divider />
                    <Overall {...stats?.data} />
                    <Header content={t('milestones')} as="h2" />
                    <Divider />
                    <MilestonesStats {...stats?.data} />
                    <Header content={t('members')} as="h2" />
                    <Divider />
                    <MembersStats {...stats?.data} />
                </>
            }
        </>
    );
};
export default ProjectStatistics;