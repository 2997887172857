import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Pie } from "react-chartjs-2";
import { Grid } from 'semantic-ui-react'
import { FlexRow, FlexItem } from '@components/tables/FlexTable'
import SuperField from '@components/forms/SuperField'

import { dates } from '@helpers/dates'

const Overall = (props) => {

    const { from_date,
        end_date,
        estimated_project_time,
        real_project_time,
        overtime,
        people_on_project_count,
        teams_on_project_count,
        milestones_count
    } = props


    const colors = useSelector(state => state.global_pref.colors)
    const { t } = useTranslation()
    const [type, setType] = useState('hours')

    function handleTimeFormat(time) {
        if (type === "hours") return time / 60
        if (type === "minutes") return time
    }

    const pie_data = {
        labels: [t('estimated_time'), t('real_time'), t('overtime')],
        datasets: [
            {
                data: [handleTimeFormat(estimated_project_time), handleTimeFormat(real_project_time), handleTimeFormat(overtime)],
                backgroundColor: [
                    colors.primary,
                    colors.warning,
                    colors.danger,
                ],
            },
        ],
    };
    const TextStatRow = ({ name, value }) => (
        <FlexRow padding='0'>
            <FlexItem>{name}</FlexItem>
            <FlexItem><b>{value}</b></FlexItem>
        </FlexRow>
    )
    return (
        <Grid style={{ width: '100%', padding: 0 }}>
            <Grid.Column style={{
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
            }} width={8}>
                <TextStatRow name={t('date_start')} value={from_date} />
                {end_date &&
                    <>
                        <TextStatRow name={t('date_end')} value={end_date} />
                        <TextStatRow name={t('days_left')} value={dates.diffDays(end_date, new Date())} />
                    </>}
                <TextStatRow name={t('number_of_milestones')} value={milestones_count} />
                <TextStatRow name={t('number_of_people')} value={people_on_project_count} />
                <TextStatRow name={t('number_of_teams')} value={teams_on_project_count} />
            </Grid.Column>
            <Grid.Column style={{ padding: 0 }} width={8}>
                <Pie data={pie_data} />
                <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem 0' }}>
                    <SuperField
                        style={{ marginRight: '1rem' }}
                        as="radio"
                        label={t('hours')}
                        checked={type === "hours"}
                        onChange={() => setType("hours")}
                    />
                    <SuperField
                        as="radio"
                        label={t('minutes')}
                        checked={type === "minutes"}
                        onChange={() => setType("minutes")}
                    />
                </div>
            </Grid.Column>
        </Grid>
    )
};

export default Overall;