import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
// store
import { addPreferrableWidget } from '@actions';
import { useHasPermission, useHasPermissions } from '@helpers/hooks';
// components
import Icon from '@components/Icon';
import TimesheetViewGraph from './TimesheetViewGraph';
import { Card, Grid, Select, Divider, Popup } from 'semantic-ui-react';
import AttendanceMonthlyViewGraph from './AttendanceMonthlyViewGraph';
import FluctuationYearlyViewGraph from './FluctuationYearlyViewGraph';

const MultipleDashboardWidgets = ({ today, user, dateFormat }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const prefWidget = useSelector(state => state.widget)

    const canViewDashboardReports = useHasPermission('reports.c_view_dashboard_reports')
    const canViewTimesheetReports = useHasPermissions(['reports.c_view_timesheets_reports', 'timesheets.c_view_user_records'])
    
    const [widget, setWidget] = useState(prefWidget || "attendance")
    const [preferredWidget, setPreferredWidget] = useState(prefWidget || null)

    const firstDayOfMonth = moment().startOf('month')
    const lastDayOfMonth = moment().endOf('month')
    const currentYear = moment().format("YYYY")

    let widgets = [
        { key: 1, value: "attendance", text: t('attendance') }
    ]

    canViewTimesheetReports && widgets.push({ key: 2, value: "timesheet", text: t('timesheet') })
    canViewDashboardReports && widgets.push({ key: 3, value: "fluctuation", text: t('fluctuation') + " - " + t("employees") })

    const savePreferredWidget = (widget) => {
        if (preferredWidget === widget) {
            setPreferredWidget(null)
            dispatch(addPreferrableWidget(null))
            
        } else {
            dispatch(addPreferrableWidget(widget))
            setPreferredWidget(widget)

        }
    }

    return (
        <Card fluid style={{ borderRadius: "2px" }}>
            <Card.Content>
                <Grid stackable>
                    <Grid.Row style={{ padding: 0 }} columns={2}>
                        <Grid.Column style={{ paddingLeft: 0, display: "flex" }}>
                            <Select 
                                search
                                value={widget}
                                options={widgets}
                                style={{ width: "200px" }}
                                onChange={(e, { value }) => setWidget(value)}
                            />
                            <Popup
                                position="right center"
                                trigger={
                                    <Icon
                                        onClick={() => savePreferredWidget(widget)}
                                        name={widget === preferredWidget ? "star" : "star-outline"}
                                        style={{ cursor: "pointer", color: "var(--dark)", fontSize: "1.2rem", marginLeft: "1rem", marginTop: "0.7rem" }}/>
                                }
                                content={t('mark_as_preferred_view')}
                            />
                        </Grid.Column>
                        <Grid.Column style={{ textAlign: "right", marginTop: "0.5rem" }}>
                            <Icon name="calendar-outline" style={{ marginRight: "0.5rem", fontSize: "1.2rem" }}/> 
                            <span style={{ position: "relative", top: "-0.2rem" }}>
                                { widget !== "fluctuation" &&  `${firstDayOfMonth.format(dateFormat)} - ${lastDayOfMonth.format(dateFormat)}`}
                                {/* { widget === "fluctuation" &&  `${currentYear} vs. ${parseInt(currentYear) - 1}`} */}
                                { widget === "fluctuation" &&  `${currentYear}`}
                            </span>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider/>

                { widget === "attendance" && user.profile?.id !== undefined && <AttendanceMonthlyViewGraph employee={user.profile} dateFrom={firstDayOfMonth} dateTo={lastDayOfMonth}/> }
                { widget === "timesheet" && user.profile?.id !== undefined && <TimesheetViewGraph employee={user.profile} dateFrom={firstDayOfMonth} dateTo={lastDayOfMonth}/> }
                { widget === "fluctuation" && user.profile?.id !== undefined && <FluctuationYearlyViewGraph year={currentYear}/> }

            </Card.Content>
        </Card>
    );
};

export default MultipleDashboardWidgets;