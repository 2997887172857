import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
// components
import { Segment, Header } from 'semantic-ui-react';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import BarGraph from "@components/graphs/BarGraph"

const RecruiterStats = () => {
    const { t } = useTranslation()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function fetchRecruitingStats() {

            const request = await getRequest(API.RECRUITER_STATS)

            if (request.status === 200) {
                const graphData = []
                let labels = []
                let data = []
                for (let i = 0; i < request.response.length; i++) {
                    let recruiting = request.response[i]
                    labels = [...labels, recruiting.fullname]
                    data = [...data, recruiting.hired_candidate_count]
                }
                graphData.push({
                    labels: labels,
                    datasets: [{
                        label: t('num_of_recruits'),
                        data: data,
                        backgroundColor: "#31C6E8"
                    }]
                })
                setData(graphData[0])
            }
            setLoading(false)

        }

        fetchRecruitingStats()
    }, [t])

    return (
        <>
            <FlexRow padding="0.5rem">
                <FlexItem>
                    <Header as="h2" textAlign="left" content={t('rectruiters_stats')} />
                </FlexItem>
            </FlexRow>

            <Segment basic loading={loading}>
                {!loading &&
                    <>
                        <BarGraph data={data} />
                    </>
                }
            </Segment>
        </>
    );
};

export default RecruiterStats;