import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import SuperField from '@components/forms/SuperField';
import SpinnerSegment from '@components/SpinnerSegment';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Form, Divider, Segment, Radio, Message } from 'semantic-ui-react';
// specific
import OrderStageForm from './OrderStageForm';
// order components
import OrderDetail from '../../orders/shared/OrderDetail';

const DuplicateOrder = ({ record, setData, setTotal, onClose, setHeader, setSize }) => {
    const { t } = useTranslation();
    const allowedOrderTypes = useSelector((state) => state.allowed_order_types);

    const [view, setView] = useState(1);
    const [fetching, setFetching] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isProcessingStageCreate, setIsProcessingStageCreate] = useState(false);
    const [createType, setCreateType] = useState(false);

    const [type, setType] = useState('');
    const [title, setTitle] = useState('');
    const [processType, setProcessType] = useState('');
    const [processTypes, setProcessTypes] = useState([]);
    const [operationType, setOperationType] = useState('sell');

    useEffect(() => {
        setHeader(
            <div>
                {t('duplicate_order') + ': '}
                <SuperDuperModal
                    size="large"
                    trigger={
                        <span className="ref-link" style={{ color: 'var(--primary' }}>
                            {record.number}
                        </span>
                    }
                    content={<OrderDetail id={record?.order_display?.id} />}
                />
            </div>
        );

        async function fetchProcessTypes() {
            setFetching(true);
            const request = await requests.get(API.ORDERS + 'sale_process_types/');
            if (request.status === 200) {
                setProcessTypes(request.response);
                if (request.response.length === 0) setCreateType(true);
            }
            setFetching(false);
        }

        fetchProcessTypes();
        // eslint-disable-next-line
    }, []);

    const customTypeList = [
        // sell
        allowedOrderTypes['sale_order'] === true && {
            key: 1,
            is_purchase: false,
            value: 'sale',
            text: t('sale_of_goods_or_services_to_customers'),
        },
        // { key: 2, is_purchase: false, value: "employment", text: t("temporary_assignment_of_an_employee") },
        // { key: 3, is_purchase: false, value: "service", text: t("provision_of_service_services") },
        allowedOrderTypes['transport_order'] === true && {
            key: 4,
            is_purchase: false,
            value: 'transport',
            text: t('transport_of_objects'),
        },
        allowedOrderTypes['hr_order'] === true && {
            key: 5,
            is_purchase: false,
            value: 'hr',
            text: t('recruitment_of_employees'),
        },

        // buy
        allowedOrderTypes['sale_order'] === true && {
            key: 6,
            is_purchase: true,
            value: 'sale',
            text: t('purchase_of_goods_or_services_from_suppliers'),
        },
        allowedOrderTypes['transport_order'] === true && {
            key: 7,
            is_purchase: true,
            value: 'transport',
            text: t('transport_of_objects'),
        },
    ];

    const isValidForm = () => {
        if (createType === false && processType !== '') return true;
        if (createType === true && title !== '' && type !== '' && operationType !== '') return true;

        return false;
    };

    const getListByType = (type) => {
        if (type === '') return [];

        if (type === 'sell') return customTypeList.filter((item) => item.is_purchase === false);
        if (type === 'buy') return customTypeList.filter((item) => item.is_purchase === true);
    };

    const determinateOrder = () => {
        let isPurchase = false;
        let resource_type = '';

        const process = processTypes.find((item) => item.id === processType);
        if (process) {
            if (process.order_type === 'sale') resource_type = 'saleorder';
            if (process.order_type === 'employment') resource_type = 'employmentorder';
            if (process.order_type === 'service') resource_type = 'serviceorder';
            if (process.order_type === 'transport') resource_type = 'transportorder';
            if (process.order_type === 'hr') resource_type = 'hrorder';

            if (process.is_purchase_order_type) {
                isPurchase = true;
            } else {
                isPurchase = false;
            }
        }

        return { isPurchase: isPurchase, resourceType: resource_type };
    };

    const handleSubmit = async () => {
        setIsProcessing(true);
        if (!createType) {
            setView(2); // order create form
            setSize('small');
            setHeader(t('add_order'));
        } else {
            // create new operation
            let data = {
                title: title,
                is_purchase_order_type: operationType === 'buy' ? true : false,
                order_type: type,
            };

            const request = await requests.post(API.ORDERS + 'sale_process_types/', data);
            if (request.status === 201) {
                setProcessType(request.response.id);
                setProcessTypes((prev) => [...prev, request.response]);
                setView(2);
                setSize('small');
                setHeader(t('add_order'));
            }
        }
        setIsProcessing(false);
    };

    const determinateDate = (order) => {
        let date = moment().format('YYYY-MM-DD');
        if (order?.resourcetype === 'TransportOrder') {
            date = order.loading_date;
        } else if (order?.resourcetype === 'HROrder') {
            date = order.start_date;
        }
        // else if (response.resourcetype === "SaleOrder") {
        // is there any option other then today??
        // }

        return date;
    };

    const handleConfirm = async (newOrder, processGroup) => {
        setIsProcessingStageCreate(true);

        const request = await requests.post(API.ORDERS + 'sale_stages/', {
            type: processType,
            process: processGroup || null,
            order: newOrder.id,
            date: determinateDate(newOrder),
        });

        if (request.status === 201) {
            if (newOrder.order_number === newOrder.original_order_number) {
                // not filled custom order number... assign based on stage number
                const updateOrder = await requests.patch(API.ORDERS + newOrder.id + '/', {
                    order_number: request.response.number,
                    resourcetype: newOrder.resourcetype,
                });

                if (updateOrder.status === 200) {
                    request.response.order_display = updateOrder.response;
                }
            }

            setData((prev) => [...prev, request.response]);
            setTotal((prev) => prev + 1);
            onClose();
        }

        setIsProcessingStageCreate(false);
    };

    return (
        <SpinnerSegment
            loading={fetching || isProcessingStageCreate}
            loadingMessage={isProcessingStageCreate ? t('processing_request') + '...' : t('loading')}
        >
            {view === 1 && (
                <Form onSubmit={handleSubmit}>
                    {!createType && (
                        <SuperField
                            as="choice"
                            search
                            required
                            loading={fetching}
                            disabled={fetching}
                            value={processType}
                            label={t('select_from_existing_operations')}
                            onChange={(e, { value }) => setProcessType(value)}
                            customOptions={processTypes.map((item) => ({
                                key: item.id,
                                value: item.id,
                                text: `${item.is_purchase_order_type ? t('buy') : t('sell')}: ${item.title}`,
                            }))}
                        />
                    )}

                    <SuperField
                        as="checkbox"
                        label={t('create_new_operation')}
                        checked={createType}
                        onChange={() => {
                            setProcessType('');
                            setCreateType(!createType);
                        }}
                    />

                    {createType && (
                        <>
                            <Divider />
                            <SuperField
                                as="input"
                                required
                                autoFocus
                                label={t('title')}
                                onChange={(e, { value }) => setTitle(value)}
                            />

                            <Form.Field>
                                <strong>
                                    {t('type')} <sup style={{ color: 'red' }}>*</sup>
                                </strong>
                                <Form.Group style={{ marginTop: '0.5rem' }}>
                                    <SuperField
                                        as="radio"
                                        label={t('sell')}
                                        checked={operationType === 'sell'}
                                        onChange={() => {
                                            if (operationType === 'sell') {
                                                setOperationType('sell');
                                            } else {
                                                setOperationType('sell');
                                                setType('');
                                            }
                                        }}
                                    />
                                    <SuperField
                                        as="radio"
                                        label={t('buy')}
                                        checked={operationType === 'buy'}
                                        onChange={() => {
                                            if (operationType === 'buy') {
                                                setOperationType('buy');
                                            } else {
                                                setOperationType('buy');
                                                setType('');
                                            }
                                        }}
                                    />
                                </Form.Group>
                                {getListByType(operationType).map((item) => (
                                    <Segment
                                        key={item.key}
                                        style={{
                                            display: 'flex',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => setType(item.value)}
                                        className={`sale-process-type${item.value === type ? ' selected' : ''}`}
                                    >
                                        <Radio checked={item.value === type} />
                                        <div style={{ marginLeft: '1rem', fontWeight: 'bold' }}>{item.text}</div>
                                    </Segment>
                                ))}
                            </Form.Field>
                        </>
                    )}

                    <Divider />
                    <Form.Field style={{ textAlign: 'right' }}>
                        <ModalCancel onClose={onClose} />
                        <ModalSubmit
                            text={t('confirm')}
                            disabled={isProcessing || !isValidForm()}
                            loading={isProcessing}
                        />
                    </Form.Field>
                </Form>
            )}
            {view === 2 && (
                <>
                    <Message
                        info
                        visible
                        content={
                            <strong>
                                <Icon
                                    name="information-circle"
                                    style={{
                                        marginRight: '0.5rem',
                                        fontSize: '1.2rem',
                                        position: 'relative',
                                        top: '0.2rem',
                                    }}
                                />
                                {t('autofilled_data_from_order_details')}:{' '}
                                <strong>
                                    <SuperDuperModal
                                        size="large"
                                        trigger={
                                            <span className="ref-link" style={{ color: 'var(--primary' }}>
                                                {record.number}
                                            </span>
                                        }
                                        content={<OrderDetail id={record?.order_display?.id} />}
                                    />
                                </strong>
                                <div>
                                    <ul style={{ marginBottom: 0, marginTop: '0.5rem', paddingLeft: '1.3rem' }}>
                                        <li style={{ fontWeight: 'normal', color: 'var(--danger)' }}>
                                            {t('supplier_and_account_needs_to_be_filled_manually_for_new_order')}!
                                        </li>
                                        <li style={{ fontWeight: 'normal', color: 'var(--danger)' }}>
                                            {t(
                                                'if_the_order_is_filled_from_an_order_that_has_a_different_type_some_data_may_not_be_completed'
                                            )}
                                            !
                                        </li>
                                    </ul>
                                </div>
                            </strong>
                        }
                    />
                    <Divider />

                    <OrderStageForm
                        isDuplicate
                        stage={record}
                        onClose={onClose}
                        stageTypeTitle={processTypes.find((item) => item.id === processType)?.title || ''}
                        isPurchase={determinateOrder().isPurchase}
                        resourceType={determinateOrder().resourceType}
                        handleConfirm={handleConfirm}
                    />
                </>
            )}
        </SpinnerSegment>
    );
};

export default DuplicateOrder;
