import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import Address from '@components/forms/Address'
import SuperField from '@components/forms/SuperField'
import NonFieldErrors from '@components/NonFieldErrors'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import EmailField from '@components/forms/common/EmailField'
import PhoneField from '@components/forms/common/PhoneField'
import SuperDuperModal from '@components/modals/SuperDuperModal'
// eslint-disable-next-line
import { Form, Divider, Header, Message, Button } from 'semantic-ui-react'
import {
    BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    // BtnItalic,
    BtnLink,
    BtnNumberedList,
    BtnRedo,
    // BtnStrikeThrough,
    BtnUnderline,
    BtnUndo,
    HtmlButton,
    Separator,
    Toolbar,
    EditorProvider,
    Editor,
} from 'react-simple-wysiwyg'

const JobOfferForm = ({ onClose, isDuplicate, record, setData, setTotal, setHeader }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)

    const [showManageOfferSettings, setShowManageOfferSettings] = useState(false)
    const [showPositionDesctiption, setShowPositionDesctiption] = useState(false)
    const [showManageRequirements, setShowManageRequirements] = useState(false)
    const [showManagePositionContractDetails, setShowManagePositionContractDetails] = useState(false)

    // eslint-disable-next-line
    const [position, setPosition] = useState(null)

    const [form, setForm] = useState({
        title: record?.title || '',
        in_language: record?.in_language || '3',
        number_of_openings: record?.number_of_openings || 1,
        recruitment: record?.recruitment || '',
        description: record?.description || '',
        eligibilities: record?.eligibilities ? record.eligibilities.map((item) => item.toString()) : [] || [],
        citizenships: record?.citizenships || [],
        drivers_licences: record?.drivers_licences ? record.drivers_licences.map((item) => item.toString()) : [],
        practice_years: record?.practice_years || 0,
        specialization: record?.specialization || '',
        educations: record?.educations ? record.educations.map((item) => item.toString()) : [],
        gender: record?.gender || '',
        age_higher: record?.age_higher || '',
        age_lower: record?.age_lower || '',
        min: record?.min || '',
        max: record?.max || '',
        currency: record?.currency || 'EUR',
        work_level: record?.work_level || 1,
        salary_type: record?.salary_type || 1,
        variable_remuneration_ratio: record?.variable_remuneration_ratio || '',
        variable_remuneration_min_value: record?.variable_remuneration_min_value || '',
        variable_remuneration_max_value: record?.variable_remuneration_max_value || '',
        job_valid_until: record?.job_valid_until || '',
        contract_type: record?.contract_type || '',
        seniority_level: record?.seniority_level || '',
        work_schedule: record?.work_schedule || '',
        work_shift: record?.work_shift || '',
        note_for_candidate: record?.note_for_candidate || '',
        boarding_when: record?.boarding_when || '',
        contact_person: record?.contact_person || '',
        contact_email: record?.contact_email || '',
        contact_phone: record?.contact_phone || '',
        workplace_type: record?.workplace_type || '',
        contract_length: record?.contract_length || '',
        is_seasonal_work: record?.is_seasonal_work === undefined ? false : record?.is_seasonal_work,
        include_night_shifts: record?.include_night_shifts === undefined ? false : record?.include_night_shifts,
        valid_for_foreigners: record?.valid_for_foreigners === undefined ? false : record?.valid_for_foreigners,
        valid_for_disabled_person:
            record?.valid_for_disabled_person === undefined ? false : record?.valid_for_disabled_person,
        valid_for_student: record?.valid_for_student === undefined ? false : record?.valid_for_student,
        valid_for_graduate: record?.valid_for_graduate === undefined ? false : record?.valid_for_graduate,
        is_cover_letter_required:
            record?.is_cover_letter_required === undefined ? false : record?.is_cover_letter_required,
        is_cv_required: record?.is_cv_required === undefined ? false : record?.is_cv_required,
    })

    const [workplace, setWorkplace] = useState({
        street: record?.address?.street || '',
        city: record?.address?.city || '',
        postcode: record?.address?.postcode || '',
        country: record?.address?.country || 'SK',
        address_line: record?.address?.address_line || '',
        state: record?.address?.state || '',
        number: record?.address?.number || '',
        orientation_number: record?.address?.orientation_number || '',
    })

    useEffect(() => {
        if (record?.id) setHeader(t('update_job_offer'))
        // eslint-disable-next-line
    }, [record])

    useEffect(() => {
        if (position !== null) {
            setForm((prev) => ({
                ...prev,
                title: position.title || '',
                description: position.description || '',
                eligibilities: position?.eligibilities
                    ? position.eligibilities.map((item) => item.toString())
                    : [] || [],
                citizenships: position?.citizenships || [],
                drivers_licences: position?.drivers_licences
                    ? position.drivers_licences.map((item) => item.toString())
                    : [],
                practice_years: position?.practice_years || 0,
                specialization: position?.specialization || '',
                educations: position?.educations ? position.educations.map((item) => item.toString()) : [],
                gender: position?.gender || '',
                age_higher: position?.age_higher || '',
                age_lower: position?.age_lower || '',
                min: position?.min || '',
                max: position?.max || '',
                currency: position?.currency || 'EUR',
                work_level: position?.work_level || 1,
                salary_type: position?.salary_type || 1,
                variable_remuneration_ratio: position?.variable_remuneration_ratio || '',
                variable_remuneration_min_value: position?.variable_remuneration_min_value || '',
                variable_remuneration_max_value: position?.variable_remuneration_max_value || '',
            }))
        }
    }, [position])

    const isFormValid = () => {
        // check some fields if needed
        if (form.title === '') return false

        return true
    }

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)

        // handle form processing
        let data = {
            ...form,
            age_lower: form.age_lower || null,
            age_higher: form.age_higher || null,
            min: form.min || null,
            max: form.max || null,
            variable_remuneration_ratio: form.variable_remuneration_ratio || null,
            variable_remuneration_max_value: form.variable_remuneration_max_value || null,
            variable_remuneration_min_value: form.variable_remuneration_min_value || null,
            job_valid_until: form.job_valid_until || null,
            work_schedule: form.work_schedule || null,
            work_shift: form.work_shift || null,
            seniority_level: form.seniority_level || null,
            contract_type: form.contract_type || null,
            workplace_type: form.workplace_type || null,
            contract_length: form.contract_length || null,
            address: workplace,
        }

        if (record === undefined || isDuplicate) {
            const request = await requests.post(API.JOB_OFFERS, data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                setTotal((prev) => prev + 1)
                setData((prev) => [request.response, ...prev])
                onClose()
            }
        } else {
            const request = await requests.patch(API.JOB_OFFERS + record.id + '/', data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === record.id) {
                            item = request.response
                        }
                        return item
                    })
                )
                onClose()
            }
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <NonFieldErrors errors={errors} />

            {record === undefined && (
                <>
                    <Message
                        info
                        visible
                        style={{ marginTop: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                    >
                        <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                            {t('autofill_data_based_on_selected_position')}
                        </span>
                        <SuperDuperModal
                            size="tiny"
                            header={t('autofill_data_based_on_selected_position')}
                            trigger={
                                <Button type="button" primary size="small">
                                    {t('select_position')}
                                </Button>
                            }
                            content={<SelectPositionForm setPosition={setPosition} />}
                        />
                    </Message>
                    <Divider />
                </>
            )}

            <Form.Group widths="equals">
                <SuperField
                    as="input"
                    required
                    autoFocus
                    width="12"
                    label={t('title')}
                    value={form.title}
                    error={errors ? errors.title?.[0] : false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, title: value }))}
                />
                <SuperField
                    as="choice"
                    required
                    clearable={false}
                    width="4"
                    type="language_choices"
                    label={t('language')}
                    value={form.in_language?.toString?.()}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, in_language: value }))}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="choice-select"
                    search
                    text="name"
                    label={t('hiring_room')}
                    endpoint={API.HIRING_ROOM}
                    value={form.recruitment}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({
                            ...prev,
                            recruitment: value,
                        }))
                    }
                    initialOptions={{
                        attribute: 'name',
                        source: record?.recruitment,
                    }}
                />
                <SuperField
                    as="input"
                    type="number"
                    min="0"
                    label={t('open_position')}
                    value={form.number_of_openings}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, number_of_openings: value }))}
                />
            </Form.Group>

            <Divider />
            <Header
                as="h5"
                style={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                    cursor: 'pointer',
                    opacity: '0.8',
                    color: 'var(--primary)',
                }}
                onClick={() => setShowPositionDesctiption(!showPositionDesctiption)}
            >
                <Icon
                    name={`chevron-${showPositionDesctiption ? 'down' : 'forward'}-outline`}
                    style={{ marginRight: '0.5rem', position: 'relative', top: '0.2rem' }}
                />
                <span>{t('manage_position_description')}</span>
            </Header>

            {showPositionDesctiption && (
                <>
                    <Divider />
                    <Form.Field>
                        <EditorProvider>
                            <Editor
                                containerProps={{
                                    style: {
                                        resize: 'vertical',
                                        width: '100%',
                                        background: 'white',
                                        minHeight: '350px',
                                    },
                                }}
                                placeholder={t('add_position_description')}
                                value={form.description}
                                onChange={(e) =>
                                    setForm((prev) => ({
                                        ...prev,
                                        description: e.target.value,
                                    }))
                                }
                            >
                                <Toolbar>
                                    <BtnUndo />
                                    <BtnRedo />
                                    <Separator />
                                    <BtnBold />
                                    {/* <BtnItalic /> */}
                                    <BtnUnderline />
                                    {/* <BtnStrikeThrough /> */}
                                    <Separator />
                                    <BtnNumberedList />
                                    <BtnBulletList />
                                    <Separator />
                                    <BtnLink />
                                    <Separator />
                                    <BtnClearFormatting />
                                    <HtmlButton />
                                </Toolbar>
                            </Editor>
                        </EditorProvider>
                    </Form.Field>
                </>
            )}

            <Divider />
            <Header
                as="h5"
                style={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                    cursor: 'pointer',
                    opacity: '0.8',
                    color: 'var(--primary)',
                }}
                onClick={() => setShowManageRequirements(!showManageRequirements)}
            >
                <Icon
                    name={`chevron-${showManageRequirements ? 'down' : 'forward'}-outline`}
                    style={{ marginRight: '0.5rem', position: 'relative', top: '0.2rem' }}
                />
                <span>{t('manage_position_requirements')}</span>
            </Header>

            {showManageRequirements && (
                <>
                    <Divider />
                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            type="number"
                            label={t('min_age')}
                            value={form.age_lower}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, age_lower: value }))}
                            error={
                                form.age_lower === ''
                                    ? false
                                    : form.age_lower < 0
                                    ? t('value_higher_then_zero')
                                    : parseFloat(form.age_lower) < 15
                                    ? t('min_age_required_is_15')
                                    : false
                            }
                        />
                        <SuperField
                            as="input"
                            type="number"
                            label={t('max_age')}
                            value={form.age_higher}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, age_higher: value }))}
                            error={
                                form.age_higher === ''
                                    ? false
                                    : form.age_higher < 0
                                    ? t('value_higher_then_zero')
                                    : parseFloat(form.age_higher) < 15
                                    ? t('min_age_required_is_15')
                                    : false
                            }
                        />
                        <SuperField
                            as="choice"
                            value={form.gender}
                            type="job_gender_choices"
                            label={t('gender_preference')}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, gender: value }))}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField
                            as="choice"
                            search
                            multiple
                            label={t('education')}
                            value={form.educations}
                            type="education_type_choices"
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, educations: value }))}
                        />
                        <SuperField
                            as="choice"
                            multiple
                            search
                            label={t('driver_licences')}
                            type="driving_licence_choices"
                            value={form.drivers_licences}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, drivers_licences: value }))}
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            type="number"
                            label={t('practice_years')}
                            value={form.practice_years}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, practice_years: value }))}
                        />
                        <SuperField
                            as="choice"
                            search
                            multiple
                            label={t('citizenship')}
                            type="citizenship"
                            value={form.citizenships}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, citizenships: value }))}
                        />
                    </Form.Group>

                    <SuperField
                        as="choice"
                        search
                        multiple
                        label={t('eligibility')}
                        type="eligibility_choices"
                        value={form.eligibilities}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, eligibilities: value }))}
                    />

                    <SuperField
                        as="input"
                        label={t('specialization')}
                        value={form.specialization}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, specialization: value }))}
                    />
                </>
            )}

            <Divider />
            <Header
                as="h5"
                style={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                    cursor: 'pointer',
                    opacity: '0.8',
                    color: 'var(--primary)',
                }}
                onClick={() => setShowManagePositionContractDetails(!showManagePositionContractDetails)}
            >
                <Icon
                    name={`chevron-${showManagePositionContractDetails ? 'down' : 'forward'}-outline`}
                    style={{ marginRight: '0.5rem', position: 'relative', top: '0.2rem' }}
                />
                <span>{t('manage_position_contract_details')}</span>
            </Header>

            {showManagePositionContractDetails && (
                <>
                    <Divider />
                    <Form.Group widths="equal">
                        <SuperField
                            as="choice"
                            label={t('contract_type')}
                            type="contract_type_choices"
                            value={form.contract_type?.toString?.()}
                            onChange={(e, data) =>
                                setForm((prev) => ({
                                    ...prev,
                                    contract_type: data.value,
                                }))
                            }
                        />
                        <SuperField
                            as="choice"
                            label={t('seniority_level')}
                            type="seniority_level_types"
                            value={form.seniority_level?.toString?.()}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, seniority_level: value }))}
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <SuperField
                            as="choice"
                            search
                            type="workplace_type"
                            label={t('workplace_type')}
                            value={form.workplace_type?.toString?.()}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, workplace_type: value }))}
                        />
                        <SuperField
                            as="choice"
                            search
                            type="contract_length"
                            label={t('contract_length')}
                            value={form.contract_length?.toString?.()}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, contract_length: value }))}
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <SuperField
                            as="choice"
                            type="work_schedule"
                            label={t('work_schedule')}
                            value={form.work_schedule?.toString?.()}
                            onChange={(e, data) =>
                                setForm((prev) => ({
                                    ...prev,
                                    work_schedule: data.value,
                                }))
                            }
                        />
                        <SuperField
                            as="choice"
                            type="work_shift"
                            label={t('work_shifts')}
                            value={form.work_shift?.toString?.()}
                            onChange={(e, data) =>
                                setForm((prev) => ({
                                    ...prev,
                                    work_shift: data.value,
                                }))
                            }
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <SuperField
                            as="choice"
                            type="contracts_work_levels"
                            label={t('work_level')}
                            value={form.work_level?.toString?.()}
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    work_level: value,
                                }))
                            }
                        />
                        <SuperField
                            as="choice"
                            search
                            type="currency_codes"
                            label={t('currency')}
                            value={form.currency}
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    currency: value,
                                }))
                            }
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            label={t('min_salary')}
                            placeholder={t('min_salary')}
                            value={form.min}
                            error={
                                form.min === ''
                                    ? false
                                    : form.min < 0
                                    ? t('value_higher_then_zero')
                                    : parseFloat(form.min) >= parseFloat(form.max)
                                    ? t('min_lower_then_max')
                                    : false
                            }
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    min: value.replace(/,/g, '.'),
                                }))
                            }
                        />
                        <SuperField
                            as="input"
                            label={t('max_salary')}
                            placeholder={t('max_salary')}
                            value={form.max}
                            error={
                                form.max === ''
                                    ? false
                                    : form.max < 0
                                    ? t('value_higher_then_zero')
                                    : parseFloat(form.max) <= parseFloat(form.min)
                                    ? t('max_higher_then_min')
                                    : false
                            }
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    max: value.replace(/,/g, '.'),
                                }))
                            }
                        />

                        <SuperField
                            as="choice"
                            label={t('salary_type')}
                            type="job_salary_type"
                            value={form.salary_type?.toString?.()}
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    salary_type: value,
                                }))
                            }
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            label={t('variable_remuneration_ratio')}
                            value={form.variable_remuneration_ratio}
                            error={
                                form.variable_remuneration_ratio === ''
                                    ? false
                                    : form.variable_remuneration_ratio < 0
                                    ? t('value_higher_then_zero')
                                    : false
                            }
                            onChange={(e, { value }) =>
                                setForm((prev) => ({ ...prev, variable_remuneration_ratio: value.replace(/,/g, '.') }))
                            }
                        />
                        <SuperField
                            as="input"
                            label={t('variable_remuneration_min_value')}
                            value={form.variable_remuneration_min_value}
                            error={
                                form.variable_remuneration_min_value === ''
                                    ? false
                                    : form.variable_remuneration_min_value < 0
                                    ? t('value_higher_then_zero')
                                    : parseFloat(form.variable_remuneration_min_value) >
                                      parseFloat(form.variable_remuneration_max_value)
                                    ? t('min_lower_then_max')
                                    : false
                            }
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    variable_remuneration_min_value: value.replace(/,/g, '.'),
                                }))
                            }
                        />
                        <SuperField
                            as="input"
                            label={t('variable_remuneration_max_value')}
                            value={form.variable_remuneration_max_value}
                            error={
                                form.variable_remuneration_max_value === ''
                                    ? false
                                    : form.variable_remuneration_max_value < 0
                                    ? t('value_higher_then_zero')
                                    : parseFloat(form.variable_remuneration_min_value) >
                                      parseFloat(form.variable_remuneration_max_value)
                                    ? t('max_higher_then_min')
                                    : false
                            }
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    variable_remuneration_max_value: value.replace(/,/g, '.'),
                                }))
                            }
                        />
                    </Form.Group>
                    <Divider />

                    <Header as="h4" content={t('workplace')} />
                    <Address isForm address={workplace} setAddress={setWorkplace} />
                </>
            )}

            <Divider />
            <Header
                as="h5"
                style={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                    cursor: 'pointer',
                    opacity: '0.8',
                    color: 'var(--primary)',
                }}
                onClick={() => setShowManageOfferSettings(!showManageOfferSettings)}
            >
                <Icon
                    name={`chevron-${showManageOfferSettings ? 'down' : 'forward'}-outline`}
                    style={{ marginRight: '0.5rem', position: 'relative', top: '0.2rem' }}
                />
                <span>{t('manage_joboffer_settings')}</span>
            </Header>

            {showManageOfferSettings && (
                <>
                    <Divider />
                    <Form.Group widths="equal">
                        <SuperField
                            as="checkbox"
                            label={t('is_cv_required')}
                            checked={form.is_cv_required}
                            onChange={() =>
                                setForm((prev) => ({
                                    ...prev,
                                    is_cv_required: !prev.is_cv_required,
                                }))
                            }
                        />
                        <SuperField
                            as="checkbox"
                            label={t('valid_for_graduate')}
                            checked={form.valid_for_graduate}
                            onChange={() =>
                                setForm((prev) => ({
                                    ...prev,
                                    valid_for_graduate: !prev.valid_for_graduate,
                                }))
                            }
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField
                            as="checkbox"
                            label={t('is_cover_letter_required')}
                            checked={form.is_cover_letter_required}
                            onChange={() =>
                                setForm((prev) => ({
                                    ...prev,
                                    is_cover_letter_required: !prev.is_cover_letter_required,
                                }))
                            }
                        />

                        <SuperField
                            as="checkbox"
                            label={t('valid_for_student')}
                            checked={form.valid_for_student}
                            onChange={() =>
                                setForm((prev) => ({
                                    ...prev,
                                    valid_for_student: !prev.valid_for_student,
                                }))
                            }
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField
                            as="checkbox"
                            label={t('include_night_shifts')}
                            checked={form.include_night_shifts}
                            onChange={() =>
                                setForm((prev) => ({
                                    ...prev,
                                    include_night_shifts: !prev.include_night_shifts,
                                }))
                            }
                        />

                        <SuperField
                            as="checkbox"
                            label={t('valid_for_foreigners')}
                            checked={form.valid_for_foreigners}
                            onChange={() =>
                                setForm((prev) => ({
                                    ...prev,
                                    valid_for_foreigners: !prev.valid_for_foreigners,
                                }))
                            }
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField
                            as="checkbox"
                            label={t('is_seasonal_work')}
                            checked={form.is_seasonal_work}
                            onChange={() =>
                                setForm((prev) => ({
                                    ...prev,
                                    is_seasonal_work: !prev.is_seasonal_work,
                                }))
                            }
                        />
                        <SuperField
                            as="checkbox"
                            label={t('valid_for_disabled_person')}
                            checked={form.valid_for_disabled_person}
                            onChange={() =>
                                setForm((prev) => ({
                                    ...prev,
                                    valid_for_disabled_person: !prev.valid_for_disabled_person,
                                }))
                            }
                        />
                    </Form.Group>

                    <Divider />

                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            label={t('boarding_when')}
                            placeholder={t('boarding_when_placeholder')}
                            value={form.boarding_when}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, boarding_when: value }))}
                        />
                        <SuperField
                            as="datepicker"
                            label={t('offer_valid_until')}
                            value={form.job_valid_until}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, job_valid_until: value }))}
                        />
                    </Form.Group>

                    <SuperField
                        as="input"
                        label={t('contact_person_name')}
                        placeholder="ex. John Doe"
                        value={form.contact_person}
                        onChange={(e, { value }) =>
                            setForm((prev) => ({
                                ...prev,
                                contact_person: value,
                            }))
                        }
                    />
                    <Form.Group widths="equal">
                        <EmailField
                            placeholder={t('enter_valid_email_address')}
                            value={form.contact_email}
                            setValue={(e, { value }) =>
                                setForm((prev) => ({
                                    ...form,
                                    contact_email: value,
                                }))
                            }
                        />
                        <PhoneField
                            hideType
                            placeholder={t('enter_valid_phone')}
                            value={form.contact_phone}
                            setValue={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    contact_phone: value,
                                }))
                            }
                        />
                    </Form.Group>

                    <SuperField
                        as="input"
                        label={t('note_for_candidate')}
                        value={form.note_for_candidate}
                        placeholder={t('note_for_candidate_placeholder')}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, note_for_candidate: value }))}
                    />
                </>
            )}

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit loading={processing} disabled={processing || !isFormValid()} />
            </Form.Field>
        </Form>
    )
}

const SelectPositionForm = ({ onClose, setPosition }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [selectedPosition, setSelectedPosition] = useState('')

    const handleSubmit = async () => {
        setProcessing(true)
        const request = await requests.get(API.JOB_POSITIONS + selectedPosition + '/')
        if (request.status === 200) {
            setPosition(request.response)
            onClose()
        }
        setProcessing(false)
    }

    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            <SuperField
                as="choice-select"
                search
                text="title"
                label={t('position')}
                value={selectedPosition}
                endpoint={API.JOB_POSITIONS}
                additionalFilters="&query={id, title}"
                onChange={(e, { value }) => setSelectedPosition(value)}
            />
            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    type="button"
                    loading={processing}
                    disabled={processing || selectedPosition === ''}
                    onClick={() => handleSubmit()}
                />
            </Form.Field>
        </Form>
    )
}

export default JobOfferForm
