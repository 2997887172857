import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
// store
import { API } from '@store/config'
import { icons } from '@store/icons'
import { requests } from '@helpers/requests'
// components
import { Card, Container, Segment } from 'semantic-ui-react'
import Icon from '@components/Icon'

const StatCard = ({ color, borderColor, isCustomColor, number, description, icon, onClick }) => {
    return (
        <Card
            style={
                isCustomColor
                    ? {
                          borderRadius: 0,
                          boxShadow: `0 0 0 1px #d4d4d5,0 2px 0 0 ${borderColor},0 1px 3px 0 #d4d4d5`,
                          background: color,
                      }
                    : { borderRadius: 0 }
            }
            color={isCustomColor ? undefined : color}
            onClick={onClick}
        >
            <Card.Content>
                <div style={{ display: 'flex', marginBottom: '2rem' }}>
                    <div
                        style={{
                            textAlign: 'left',
                            fontWeight: 'bold',
                            width: '70%',
                            fontSize: '1.15rem',
                            paddingTop: '0.25rem',
                            color: 'var(--dark)',
                        }}
                    >
                        {number}
                    </div>
                    <div style={{ textAlign: 'right', width: '30%' }}>
                        <Icon
                            name={icon}
                            style={{ fontSize: '2rem', fontWeight: 'bold', textAlign: 'left', color: 'var(--dark)' }}
                        />
                    </div>
                </div>

                <div
                    style={{
                        position: 'absolute',
                        bottom: '1rem',
                        marginTop: '1rem',
                        textTransform: 'uppercase',
                        color: 'var(--dark)',
                        opacity: '0.8',
                    }}
                >
                    {description}
                </div>
            </Card.Content>
        </Card>
    )
}

const LeadListStats = ({ data, params, fetchData, setFilters }) => {
    const { t } = useTranslation()

    const colors = useSelector((state) => state.global_pref.colors)

    // eslint-disable-next-line
    const [loading, setLoading] = useState(false)
    const [stats, setStats] = useState({
        total: 0,
        new: 0,
        assigned: 0,
        in_process: 0,
        recycled: 0,
        converted: 0,
        dead: 0,
    })

    useEffect(() => {
        async function fetchStats() {
            if (!loading) {
                setLoading(true)
                const request = await requests.get(API.REPORTS + 'leads/basic_stats/?query={*}' + params)

                if (request.status === 200) {
                    let response = request.response
                    setStats({
                        total: response?.total_leads || 0,
                        new: response?.total_new_leads || 0,
                        assigned: response?.total_assigned_leads || 0,
                        in_process: response?.total_in_process_leads || 0,
                        recycled: response?.total_recycled_leads || 0,
                        converted: response?.total_converted_leads || 0,
                        dead: response?.total_dead_leads || 0,
                    })
                }
                setLoading(false)
            }
        }

        fetchStats()
        // eslint-disable-next-line
    }, [data])

    const renderOnClick = async (state) => {
        setFilters((prev) => ({ ...prev, state: state }))
        await fetchData('&state=' + state, true)
    }

    return (
        <Container fluid>
            <Segment
                loading={loading}
                style={{
                    padding: 0,
                    margin: 0,
                    backgroundColor: 'transparent',
                    minHeight: '5rem',
                    border: 'none',
                    boxShadow: 'none',
                    marginBottom: '1rem',
                }}
            >
                <Card.Group stackable doubling itemsPerRow={7}>
                    <StatCard
                        isCustomColor
                        color={colors.dark + '26'}
                        borderColor={colors.dark}
                        number={stats.total}
                        icon={icons.ORDERS}
                        description={t('all')}
                        onClick={() => renderOnClick('')}
                    />
                    <StatCard
                        isCustomColor
                        color={colors.info + '33'}
                        borderColor={colors.info}
                        number={stats.new}
                        icon={icons.ORDERS}
                        description={t('new')}
                        onClick={() => renderOnClick(1)}
                    />
                    <StatCard
                        isCustomColor
                        color={colors.primary + '33'}
                        borderColor={colors.primary}
                        number={stats.assigned}
                        icon={icons.ORDERS}
                        description={t('assigned')}
                        onClick={() => renderOnClick(2)}
                    />
                    <StatCard
                        isCustomColor
                        color={colors.warning + '33'}
                        borderColor={colors.warning}
                        number={stats.in_process}
                        icon={icons.ORDERS}
                        description={t('in_process')}
                        onClick={() => renderOnClick(3)}
                    />
                    <StatCard
                        isCustomColor
                        color={colors.blue + '33'}
                        borderColor={colors.blue}
                        number={stats.recycled}
                        icon={icons.ORDERS}
                        description={t('recycled')}
                        onClick={() => renderOnClick(4)}
                    />
                    <StatCard
                        isCustomColor
                        color={colors.success + '33'}
                        borderColor={colors.success}
                        number={stats.converted}
                        icon={icons.ORDERS}
                        description={t('converted_lead')}
                        onClick={() => renderOnClick(5)}
                    />
                    <StatCard
                        isCustomColor
                        color={colors.danger + '33'}
                        borderColor={colors.danger}
                        number={stats.dead}
                        icon={icons.ORDERS}
                        description={t('dead')}
                        onClick={() => renderOnClick(6)}
                    />
                </Card.Group>
            </Segment>
        </Container>
    )
}

export default LeadListStats
