import React, { useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { hiringService } from '@services/ServiceHiring'
import { isEmpty } from '@helpers/validation'
// components
import { Form, Button, Message, Divider } from 'semantic-ui-react'

const CommentForm = (props) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false) // true, false
    const [errorList, setErrorList] = useState([]) // empty array
    const [note, setNote] = useState('')
    const [archived_recruitment_stage /*, setArchived_recruitment_stage*/] = useState(props.stage ? props.stage : 0)
    const [recruitment /*, setRecruitment*/] = useState(props.recruitmentId ? props.recruitmentId : 0)

    const handleSubmit = async () => {
        setIsProcessing(true)

        const data = {
            note: note,
            recruitment: recruitment,
            archived_recruitment_stage: archived_recruitment_stage,
        }
        const result = await hiringService.createComment(props.id, data)

        if (result.status === 201) {
            props.onConfirm(result.response)
            setNote('')
        } else {
            const error_message = result.response.name
            if (result.response.non_field_errors) {
                errorList.push(result.response.non_field_errors)
            }
            setErrorList([...errorList, error_message])
        }

        setIsProcessing(false)
    }

    const ErrorMessages = () => {
        return (
            <Fragment>
                {errorList.length > 0 ? (
                    <Message negative>
                        {errorList.map((error, index) => (
                            <p key={index}>{error}</p>
                        ))}
                    </Message>
                ) : (
                    ''
                )}
            </Fragment>
        )
    }

    return (
        <>
            {!props.hideForm && (
                <Form onSubmit={handleSubmit} style={{ marginBottom: '1rem' }}>
                    {errorList.length > 0 ? <ErrorMessages /> : ''}
                    <Form.TextArea
                        autoFocus
                        fluid
                        rows={2}
                        value={note}
                        placeholder={t('enter_note')}
                        onChange={(e, data) => setNote(data.value)}
                    />
                    <Button
                        disabled={isProcessing || isEmpty(note)}
                        loading={isProcessing}
                        style={{ marginTop: '1rem' }}
                        content={t('add_comment')}
                        primary
                    />
                    <Divider />
                </Form>
            )}
        </>
    )
}

export default CommentForm
