import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import { Header, Divider, Grid, Label } from 'semantic-ui-react'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import SkillForm from '@components/forms/SkillForm'
import CanView from '@components/perms/CanView'
import { FlexItem, FlexRow } from '@components/tables/FlexTable'

const SkillSection = ({ isManageable, result, setResult, patchEndpoint, canManage }) => {
    const { t } = useTranslation()

    const [skills, setSkills] = useState(result?.skills || [])

    const onConfirm = async (newSkill) => {
        const data = {
            skills: {
                add: [newSkill.id],
            },
        }

        const request = await requests.patch(patchEndpoint + result?.id + '/', data)

        if (request.status === 200) {
            setSkills([...skills, newSkill])
            setResult({
                ...result,
                data: {
                    ...result.data,
                    skills: [...skills, newSkill],
                },
            })
        }
    }

    const onDelete = async (id) => {
        const request = await requests.patch(API.EMPLOYEES + result?.id + '/', {
            skills: {
                remove: [id],
            },
        })
        if (request.status === 200) {
            setSkills((prev) => prev.filter((item) => item.id !== id))
            setResult({
                ...result,
                data: {
                    ...result.data,
                    skills: request.response.skills,
                },
            })
        }
    }

    return (
        <Fragment>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column
                        only="computer"
                        computer="3"
                        stretched
                        style={{ borderRadius: '4px', paddingTop: '1rem', backgroundColor: 'var(--light)' }}
                    >
                        <FlexRow background="transparent" padding="0">
                            <FlexItem textAlign="center">
                                <Header icon>
                                    <Icon name="newspaper-outline" style={{ fontSize: '5rem' }} />
                                    <h3>{t('skills')}</h3>
                                </Header>
                            </FlexItem>
                        </FlexRow>
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="16" mobile="16">
                        <Grid columns="2" style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <Grid.Column width="10" style={{ paddingTop: '0.8rem', paddingBottom: 0 }}>
                                <Header floated="left" as="h3">
                                    <Icon name="newspaper-outline" style={{ marginRight: '0.5rem' }} />
                                    <span style={{ position: 'relative', top: '-0.2rem' }}>{t('skills')}</span>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width="6" textAlign="right" style={{ padding: 0 }}>
                                {canManage && (
                                    <SuperDuperModal
                                        header={t('add_skill')}
                                        trigger={
                                            <Header
                                                style={{ padding: 0, cursor: 'pointer', paddingTop: '1rem' }}
                                                floated="right"
                                                as="h4"
                                            >
                                                <Icon
                                                    name="add-outline"
                                                    style={{ fontSize: '1rem', marginRight: '0.5rem' }}
                                                />
                                                <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                    {t('add_skill')}
                                                </span>
                                            </Header>
                                        }
                                        content={
                                            <SkillForm
                                                skill={{}}
                                                id={result?.id}
                                                onConfirm={(newSkill) => onConfirm(newSkill)}
                                                patchEndpoint={patchEndpoint}
                                            />
                                        }
                                    />
                                )}
                            </Grid.Column>
                        </Grid>

                        <Divider />
                        <Grid style={{ padding: 0 }}>
                            <Grid.Row style={{ padding: 0 }}>
                                {skills.length > 0 ? (
                                    <Grid.Column style={{ padding: 0 }} computer="10" tablet="16" mobile="16">
                                        {skills.map((skill, index) => (
                                            <Label
                                                style={{
                                                    fontSize: '0.9rem',
                                                    padding: '0.4rem',
                                                    marginTop: '3px',
                                                    color: 'white',
                                                    backgroundColor:
                                                        skill.seniority_level === 1
                                                            ? 'var(--info)'
                                                            : skill.seniority_level === 2
                                                            ? 'var(--success)'
                                                            : skill.seniority_level === 3
                                                            ? 'var(--warning)'
                                                            : skill.seniority_level === null && '#4ec260',
                                                }}
                                                key={skill.id}
                                            >
                                                <span style={{ position: 'relative', top: '-0.1rem' }}>
                                                    {skill.name}{' '}
                                                    {skill.seniority_level && ' - ' + skill.seniority_level_display}
                                                </span>
                                                <CanView
                                                    permissions={[
                                                        'common.c_manage_all_employees',
                                                        'common.c_manage_all_candidates',
                                                        'common.c_manage_user_profile',
                                                    ]}
                                                >
                                                    <Icon
                                                        name="close-outline"
                                                        style={{
                                                            marginLeft: '0.5rem',
                                                            color: 'var(--danger)',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => onDelete(skill.id)}
                                                    />
                                                </CanView>
                                            </Label>
                                        ))}
                                    </Grid.Column>
                                ) : (
                                    <Grid.Column textAlign="center" computer="16" tablet="16" mobile="16">
                                        <Header as="h4">{t('nothing')}</Header>
                                    </Grid.Column>
                                )}
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Fragment>
    )
}

export default SkillSection
