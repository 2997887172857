import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router'

import { Input, Label, Icon, Button } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField'

import { useFetchData } from '@helpers/hooks'
import { API } from '@store/config'


const UltimateIssueFilter = ({ setFiltered }) => {
    const params = useParams()
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true)
    const [employees] = useFetchData(API.EMPLOYEES + '?query={id, fullname}')
    const [labels] = useFetchData(API.PROJECT_LABELS)
    const [milestones] = useFetchData(API.PROJECT_MILESTONES + '?query={id, name}&project=' + params.id)
    const [stages] = useFetchData(API.PROJECT_STAGES)
    const [groups] = useFetchData(API.PROJECT_ISSUES_GROUPS)
    const [parent_issues] = useFetchData(API.PROJECT_ISSUES + '?query={id,name,sub_issues}&project=' + params.id)

    let filters = [
        { id: 1, name: t('assigned'), query_name: "assigned", options: refactorEmployeesToOptions() },
        { id: 2, name: t('backlog'), query_name: "is_backlog", options: [{ text: t('true'), value: 1 + 'is_backlog' }, { text: t('false'), value: 2 + 'is_backlog' }] },
        { id: 3, name: t('template'), query_name: "is_template", options: [{ text: t('true'), value: 1 + 'is_template' }, { text: t('false'), value: 2 + 'is_template' }] },
        { id: 4, name: t('current_user'), query_name: "only_current_user", options: [{ text: t('true'), value: 1 + 'only_current_user' }, { text: t('false'), value: 2 + 'only_current_user' }] },
        { id: 5, name: t('labels'), query_name: "labels", options: refactorLabelsToOptions() },
        { id: 6, name: t('milestones'), query_name: "milestone", options: refactorMilestonesToOptions() },//milestones
        { id: 7, name: t('parent_issue'), query_name: "parent", options: refactorParentIssuesToOptions() },//issues
        { id: 8, name: t('group'), query_name: "group", options: refactorGroupsToOptions() },//groups
        { id: 9, name: t('stage'), query_name: "stage", options: refactorStagesToOptions() },//stages
    ]

    const [current, setCurrent] = useState(null)
    const [search, setSearch] = useState('')
    const [dates, setDates] = useState({
        from: '',
        to: '',
    })
    const [workingArray, setWorkingArray] = useState(filters)
    const [open, setOpen] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState([])

    useEffect(() => {
        if (employees.isLoading || labels.isLoading || milestones.isLoading || stages.isLoading || groups.isLoading || parent_issues.isLoading) {
            setLoading(true)
        } else {
            setLoading(false)
        }
        if (current) {
            setWorkingArray([current])
        } else {
            setWorkingArray(filters)
        }
        // eslint-disable-next-line
    }, [current, employees.isLoading, labels.isLoading, milestones.isLoading, stages.isLoading, groups.isLoading, parent_issues.isLoading])

    //GETTING EXACT FILTER OPTION
    function getFilteredOption(value, text_value, input_current) {
        setSelectedOptions(selectedOptions.concat([{ filter: input_current.query_name, filter_text: input_current.name, value: value, text_value: text_value, id: input_current.id }]))
        setCurrent(null)
        setOpen(false)
    }
    function filteredOptions() {
        let array = workingArray.filter((item) => {
            if (item.query_name === 'assigned') return item
            if (item.query_name === 'labels') return item
            if (selectedOptions.find(x => x.id === item.id)) return false
            return item
        })
        return array.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()))
    }
    function filterAssignees() {
        let array = current.options.filter(item => {
            if (selectedOptions.find(x => x.value === item.value)) return false
            return item
        })
        return array.filter(({ text }) => text.toLowerCase().includes(search.toLowerCase()))
    }

    //FORMATING FUNCTIONS
    function refactorEmployeesToOptions() {
        if (employees.isLoading) return []
        return employees.data.map(({ id, fullname }) => ({ value: id, text: fullname, key: id + fullname }))
    }
    function refactorLabelsToOptions() {
        if (labels.isLoading) return []
        return labels.data.map(({ id, name, color }) => ({ value: id, text: name, key: id + name, color: color }))
    }
    function refactorMilestonesToOptions() {
        if (milestones.isLoading) return []
        return milestones.data.map(({ id, name }) => ({ value: id, text: name, key: id + name }))
    }
    function refactorStagesToOptions() {
        if (stages.isLoading) return []
        return stages.data.map(({ id, title }) => ({ value: id, text: title, key: id + title }))
    }
    function refactorGroupsToOptions() {
        if (groups.isLoading) return []
        return groups.data.map(({ id, title }) => ({ value: id, text: title, key: id + title }))
    }
    function refactorParentIssuesToOptions() {
        let array = []
        if (parent_issues.isLoading) return []
        for (let { sub_issues, id, name } of parent_issues.data) {
            if (!array.find(x => x.id === id) && sub_issues.length !== 0) {
                array.push({ value: id, text: name, key: id + name })
            }
        }
        return array
    }
    const renderSelectedOptionsIntoInput = () => {
        let array_of_filters = []
        for (let item of selectedOptions) {
            if (array_of_filters.find(x => x.name === item.filter_text)) {
                array_of_filters.filter(y => {
                    if (y.name === item.filter_text) y.list.push(item)
                    return y
                })
            } else {
                array_of_filters.push({ name: item.filter_text, list: [item] })
            }
        }
        return array_of_filters.map(({ name, list }, key) => (
            <div key={key} style={{ margin: '0.1rem 0', display: 'flex', alignItems: 'center' }}>
                <div style={{ paddingRight: '0.2rem', width: '6rem' }}>{name} :</div>
                {list.map(({ value, text_value, id }, key_x) => (
                    <Label key={key_x}>{text_value}<Icon name="close" onClick={() => deleteChoice(id, value)} /></Label>
                ))}
            </div>
        ))
    }
    function deleteChoice(search_id, search_value) {
        setSelectedOptions(selectedOptions.filter(({ id, value }) => {
            if (id === search_id && value === search_value) return false
            return true
        }))
    }
    function sendQuery() {
        let query = `?project=${params.id}${search && `&search=${search}`}${dates.from && `&start_date=${dates.from}`}${dates.to && `&end_date=${dates.to}`}`
        for (let { filter, value, id, text_value } of selectedOptions) {
            if (id > 1 && id <= 4) {
                query += `&${filter}=${text_value}`
            } else {
                query += `&${filter}=${value}`
            }
        }
        setFiltered({ process: true, query: query })
    }
    function refresh() {
        setSelectedOptions([])
        setSearch('')
        setDates({
            from: '',
            to: '',
        })
        setFiltered({ process: false, query: `?project=${params.id}` })
    }

    return (
        <>
            <div
                style={{ display: 'flex', padding: '1rem 0', flexWrap: 'wrap', width: "100%" }}>
                <div style={{ width: "50%" }}>
                    <Input
                        style={{ display: 'flex' }}
                        action={{ icon: open ? 'angle up' : 'angle down', onClick: () => setOpen(!open) }}
                        disabled={loading}
                        loading={loading}
                        placeholder={t('search_or_filter')}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}

                    />
                    {open && !loading &&
                        <div style={{ background: "var(--white)", maxHeight: "20rem", overflowY: "auto", position: 'absolute', zIndex: 999, border: '1px solid var(--border)' }}>
                            {!current && filteredOptions().map((item, key) => (
                                <div
                                    key={key}
                                    onClick={() => {
                                        setSearch('')
                                        setCurrent(item)
                                    }}
                                    className={current ? "ultimate-project-issues-menu-item" : "ultimate-project-issues-item"}>
                                    {!current && <>{item.name}</>}
                                </div>
                            ))}
                            {current && <div
                                style={{ padding: '1rem' }}>
                                <Icon name="angle left" onClick={() => setCurrent(null)} style={{ cursor: 'pointer' }} />
                                <span style={{ textDecoration: 'underline', }}>
                                    {current.name}
                                </span>
                            </div>}
                            {current && filterAssignees().map(({ text, value }, x_key) => (
                                <div
                                    key={x_key}
                                    onClick={() => getFilteredOption(value, text, current)}
                                    className="ultimate-project-issues-item">
                                    {text}
                                </div>
                            ))}
                        </div>
                    }
                </div>
                <SuperField
                    style={{ margin: '0 0.2rem' }}
                    as="datepicker"
                    value={dates.from}
                    onChange={(e, { value }) => setDates({ ...dates, from: value })}
                    placeholder={t('date_from')}
                />
                <SuperField
                    style={{ margin: '0 0.2rem' }}
                    as="datepicker"
                    value={dates.to}
                    onChange={(e, { value }) => setDates({ ...dates, to: value })}
                    placeholder={t('date_to')}
                />
                <div>
                    <Button content={t('filter')} primary onClick={() => sendQuery()} />
                </div>
                <div>
                    <Button content={t('refresh')} primary onClick={() => refresh()} />
                </div>
            </div>
            <div
                style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
                {renderSelectedOptionsIntoInput()}
            </div>
        </>
    );
};


export default UltimateIssueFilter;